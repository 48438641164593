import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';


import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { actionCreators } from 'store/Auth';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';


const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
});

class ColumnsSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
        };
    }
    componentDidMount() {
        this.setState({columns: this.props.reactGrid.state.columns });
    }
    handleToggle = (column,reactGrid) => () => {
        var columns = reactGrid.state.columns;
        var checked = false;
        var count = 0;
        columns.map((col) => {
            if (col.key === column.key) {
                count++;
            }
            return col;
        });
        if (count > 0) {
            checked = true;
        }
        if (!checked) {
            columns.push(column);
        }
        else {
            columns.splice(columns.findIndex(x => x.key === column.key), 1);
        }

        reactGrid.setState(() => { return { columns: columns }; }, () => {
            reactGrid.saveColumsConfiguration();
            this.setState({ columns });
        });
    };
    handleClose = () => {
        //this.props.onClose(this.props.selectedValue);
        const {  reactGrid } = this.props;
        reactGrid.setState(() => {
            return { data: [], lastPage: false, loadedPage: 0, page: 0, showColumnsSelector: false };
        }, () => reactGrid.loadRows(0, reactGrid.state.itemsPerPage))
    };

    render() {
        const { classes, reactGrid } = this.props;
        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={reactGrid.state.showColumnsSelector}>
                <DialogTitle id="simple-dialog-title">{getTranslation(5,"GC_COLUMNSELECTION",this.props.translations, "Seleziona colonne")}</DialogTitle>
                {this.props.userID === -1990 && <p>
                    {(new URLSearchParams(`?${reactGrid.props.dataRoute.split("?")[1].split("§")[0]}`)).get("grid")}
                </p>}
                <div>
                    <List className={classes.root}>
                        {reactGrid.state.allColumns.sort((o1, o2) => (o1.name > o2.name) ? 1 : -1).map((column, index) => {
                            var checked = false;
                            var columns = this.state.columns;//reactGrid.state.columns;
                            var count = 0;
                            columns.map((col) => {
                                if (col.key === column.key) {
                                    count++;
                                }
                                return col;
                            });
                            if (count > 0) {
                                checked = true;
                            }
                            return (
                                <ListItem key={index} role={undefined} dense button onClick={this.handleToggle(column,reactGrid)}>
                                    <Checkbox
                                        checked={checked}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                    <ListItemText primary={column.name} />
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            </Dialog>
        );

    }
}

ColumnsSelector.propTypes = {
    classes: PropTypes.object.isRequired,
    reactGrid: PropTypes.object.isRequired,
};

//export default withStyles(styles)(ColumnsSelector);



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(ColumnsSelector);
// export default withStyles(toolbarStyles)(EnhancedTableToolbar);