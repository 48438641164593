import React, { useState, useEffect, useRef } from 'react';
import { createStyles, makeStyles, Theme, styled } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom'
import Controls from './components/Controls/Controls';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import CircularProgress from '@material-ui/core/CircularProgress';

import { IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBox from '@material-ui/icons/AccountBox';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { useAppState } from './state';
import useVideoContext from './hooks/useVideoContext/useVideoContext';
import FlipCameraButton from './components/MenuBar/FlipCameraButton/FlipCameraButton';
import { DeviceSelector } from './components/MenuBar//DeviceSelector/DeviceSelector';
import useLocalAudioToggle from './hooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from './hooks/useLocalVideoToggle/useLocalVideoToggle';
import useScreenShareToggle from './hooks/useScreenShareToggle/useScreenShareToggle';
import useScreenShareParticipant from './hooks/useScreenShareParticipant/useScreenShareParticipant';
import ParticipantSidebar from '../views/Videochat/ParticipantSidebar';
import UsersSidebar from '../views/Videochat/UsersSidebar';
import { Tooltip, Typography } from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Header from 'components/Header/Header';
import VideochatHeader from 'views/Videochat/VideochatHeader';
import VideochatSidebar from 'views/Videochat/VideochatSidebar';
import XExpansionPanel from 'components/XExpansionPanel';
import VideochatActions from 'views/Videochat/VideochatActions';

import AudioInputList from 'videochat/components/MenuBar/DeviceSelector/AudioInputList/AudioInputList';
import AudioOutputList from 'videochat/components/MenuBar/DeviceSelector/AudioOutputList/AudioOutputList';
import VideoInputList from 'videochat/components/MenuBar/DeviceSelector/VideoInputList/VideoInputList';
import { usePrevious } from 'utils';
import { Microphone, VolumeHigh } from 'mdi-material-ui';
import { Videocam } from '@material-ui/icons';
const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  height: '100%',
  width: '100%',
});

const Main = styled('main')({
  overflow: 'hidden',
});

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flex: 3,
      zIndex: 1
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    sidebar: {
      flex: 1,
      maxWidth: 300,
      zIndex: 2
    },
    sideBars: {
      width: '0',
      transition: 'width 1s',
      overflow: 'hidden',
      position: 'absolute',
      left: '0',
      zIndex: 7,
      background: 'white',
      height: '100%',
      overflowY: 'auto',
    },
    tags: {
      zIndex: 6,
      display: 'flex',
      flexDirection: "column",
      width: '50px',
    },
    tag: {
      width: 'fit-content',
      background: '#e2b231',
      border: '1px solid #383b3d',
      borderRadius: '6px',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
    },
    loadingSpinner: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    hidden: {
      display: 'none'
    },
    visible: {

    },
    topButtonChat: {
      width: 'fit-content',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)'
    },
    flexContainer: {
      display: 'flex',
    }
  })
);
export default function App(props) {
  const classes = useStyles();
  const roomState = useRoomState();
  const { user, isFetching } = useAppState();
  const { room, localTracks, isConnecting, connect } = useVideoContext();
  const [redirect, setRedirect] = useState(false);
  const [tryingConnect, setTryingConnect] = useState(true);

  const [disableAudio, setDisableAudio] = useState(false);
  const [disableVideo, setDisableVideo] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openParticipants, setOpenParticipants] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [openLeftSidebar, setOpenLeftSidebar] = useState(true);
  const [openRightSidebar, setOpenRightSidebar] = useState(true);
  const [canStartConnection, setCanStartConnection] = useState(false);

  const settingsBarRef = useRef();
  const participantBarRef = useRef();
  const usersBarRef = useRef();

  const height = useHeight();

  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const [isScreenShared, toggleScreenShare] = useScreenShareToggle();
  const screenShareParticipant = useScreenShareParticipant();
  const disableScreenShareButton = screenShareParticipant && screenShareParticipant !== room.localParticipant;
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isScreenDisabled = disableVideo || disableScreenShareButton || !isScreenShareSupported;


  const prevRoomState = usePrevious(roomState);
  const prevLocalTracks = usePrevious(localTracks);
  const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const audioVideoBlip = async () => {
    await timeout(300);
    shutAudioDown();
    shutVideoDown();
    await timeout(300);
    activeAudio();
    activeVideo();
  }
  const connectIfNoAudioVideoInput = async () => {
    const devices = (await navigator.mediaDevices.enumerateDevices()).filter(d => d.kind === "videoinput" || d.kind === "audioinput");
    if (devices.length === 0) {
      connectToRoom();
      setCanStartConnection(false);
    }
  }
  useEffect(() => {
    if (props.token) {
      setCanStartConnection(true);
      connectIfNoAudioVideoInput();
      //connectToRoom();
    }
    return () => {
      cleanup();
    }
  }, []);
  const connectToRoom = () => {
    setCanStartConnection(false);
    connect(props.token).then(() => {
      setTryingConnect(false);
      props.callJoined(props.roomName);
    })
  }
  useEffect(() => {
    if (localTracks.length > 0 && canStartConnection) {

      connectToRoom();
    }
  }, [localTracks]);

  useEffect(() => {
    if (props.leaveRoom !== undefined) {
      cleanup();
      setRedirect(true);
    }
  }, [props.leaveRoom]);


  useEffect(() => {
    if (props.activeScreenShare && !isScreenShared) {
      toggleScreenShare();
      props.startScreenShareDone();
    }
  }, [props.activeScreenShare]);

  useEffect(() => {//si entra qui quando si mette giù una chiamata
    if (!tryingConnect
      && roomState === 'disconnected'
      && !(isConnecting || isFetching)) {
      cleanup();
      setRedirect(true);
    }
  }, [isConnecting, isFetching, roomState]);
  useEffect(() => {
    if (room.localParticipant) {
      //audioVideoBlip();
    }
  }, [room.localParticipant])
  // const cleanup = () => {
  //   shutAudioDown();
  //   shutVideoDown();
  //   props.callLeft(props.roomName);
  //   if (room && roomState !== 'disconnected')
  //     room.disconnect()
  // }
  const cleanup = () => {
    localTracks.forEach(track => track.stop());
    props.callLeft(props.roomName);
    if (room && roomState !== 'disconnected')
      room.disconnect()
  }

  useEffect(() => {
    onFuncionalityChanged();
    props.resetFunctionalityChanged();
  }, [props.functionalityChanged])

  const shutAudioDown = () => {
    if (isAudioEnabled) {
      console.log("CALL: Shutting down audio");
      toggleAudioEnabled()
    }
  }
  const activeAudio = () => {
    if (!isAudioEnabled) {
      console.log("CALL: Activating audio");
      toggleAudioEnabled()
    }
  }
  const shutVideoDown = () => {
    if (isVideoEnabled) {
      console.log("CALL: Shutting down video");
      toggleVideoEnabled();
    }
    if (isScreenShared) {
      console.log("CALL: Shutting down screen share");
      toggleScreenShare();
    }
  }
  const activeVideo = () => {
    if (!isVideoEnabled) {
      console.log("CALL: Activating video");
      toggleVideoEnabled();
    }
  }

  const askConfirm = (functionality, handleAccept) => {
    confirmAlert({
      title: 'The owner wants to activate your ' + functionality,
      message: 'This action needs your confirmation.',
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Accept',
          onClick: () => handleAccept()
        },
        {
          label: 'Refuse',
          onClick: () => { }
        }
      ]
    });
  }

  const onFuncionalityChanged = () => {
    switch (props.functionalityChanged) {
      case 0://FUNCTIONALITY_MIC_DISABLED
        setDisableAudio(true);
      case 2://FUNCTIONALITY_MIC_MUTE
        shutAudioDown();
        break;
      case 1://FUNCTIONALITY_MIC_ENABLED
        setDisableAudio(false);
        break;
      case 3://FUNCTIONALITY_MIC_UNMUTE
        askConfirm('Microphone', activeAudio);
        break;
      case 4://FUNCTIONALITY_VIDEO_DISABLED 
        setDisableVideo(true);
      case 6://FUNCTIONALITY_VIDEO_MUTED
        shutVideoDown();
        break;
      case 5://FUNCTIONALITY_VIDEO_ENABLED
        setDisableVideo(false);
        break;
      case 7://FUNCTIONALITY_VIDEO_UNMUTED
        askConfirm('Camera', activeVideo);
        break;
    }
  }

  const onLeftSidebarChange = () => {
    setOpenLeftSidebar(!openLeftSidebar);
  }
  const onRightSidebarChange = () => {
    setOpenRightSidebar(!openRightSidebar);
  }
  const enableRequest = () => {
    props.enableRequest(props.roomName)
  }

  const togglesettingsBar = () => {
    settingsBarRef.current.style.width = openSettings ? '0px' : 'fit-content';
    settingsBarRef.current.style.minWidth = openSettings ? '0px' : '300px';
    setOpenSettings(!openSettings)
  }
  const toggleparticipantBar = () => {
    participantBarRef.current.style.width = openParticipants ? '0px' : 'fit-content';
    participantBarRef.current.style.minWidth = openParticipants ? '0px' : '300px';
    setOpenParticipants(!openParticipants)
  }
  const toggleusersBar = () => {
    usersBarRef.current.style.width = openUsers ? '0px' : 'fit-content';
    usersBarRef.current.style.minWidth = openUsers ? '0px' : '300px';
    setOpenUsers(!openUsers)
  }
  const renderPanelActions = () => {
    return <VideochatActions
      users={props.users}
      roomParticipants={props.roomParticipants}
      handleEnableMic={props.handleEnableMic}
      handleDisableMic={props.handleDisableMic}
      handleEnableVideo={props.handleEnableVideo}
      handleDisableVideo={props.handleDisableVideo}
      handleMuteMic={props.handleMuteMic}
      handleUnmuteMic={props.handleUnmuteMic}
      handleMuteVideo={props.handleMuteVideo}
      handleUnmuteVideo={props.handleUnmuteVideo}
      handleForceQuit={props.handleForceQuit}
      forcePinChange={props.forcePinChange}
      forcePin={props.forcePin}
    />
  }
  const renderPanelUsers = () => {
    return <ParticipantSidebar
      visible={openParticipants}
      onClose={toggleparticipantBar}
      showControlButton={props.iAmSuperUser}
      users={props.users}
      roomParticipants={props.roomParticipants}
      handleEnableMic={props.handleEnableMic}
      handleDisableMic={props.handleDisableMic}
      handleEnableVideo={props.handleEnableVideo}
      handleDisableVideo={props.handleDisableVideo}
      handleMuteMic={props.handleMuteMic}
      handleUnmuteMic={props.handleUnmuteMic}
      handleMuteVideo={props.handleMuteVideo}
      handleUnmuteVideo={props.handleUnmuteVideo}
      usersWithRequest={props.usersWithRequest}
      handleForceQuit={props.handleForceQuit}
      openMessageChat={props.openMessageChat}
      forcePin={props.forcePin}
      forcePinChange={props.forcePinChange}
    />;
  }
  const renderPanelOnlineUsers = () => {
    return <UsersSidebar
      onClose={toggleusersBar}
      users={props.users}
      roomParticipants={props.roomParticipants}
      callUser={props.callUser}
      notifyBusyUser={props.notifyBusyUser}
      openMessageChat={props.openMessageChat}
      showAll={true}
    />;
  }
  const renderPanelMic = () => {
    return <AudioInputList handleDisableDevice={toggleAudioEnabled} disableAudio={disableAudio} />;

  }
  const renderPanelAudioOutput = () => {
    return <AudioOutputList />;

  }
  const renderPanelCam = () => {
    return <VideoInputList handleDisableDevice={toggleVideoEnabled} disableVideo={disableVideo} forcePin={props.forcePin} forcePinChange={props.forcePinChange} iAmSuperUser={props.iAmSuperUser} />;
  }
  const renderPanelsLeftSidebar = () => {
    var panels = [
      {
        icon: <SupervisorAccountIcon />,
        label: 'Mixer utenti',
        content: renderPanelActions()
      },
      {
        icon: <SupervisorAccountIcon />,
        label: 'Utenti attivi',
        content: renderPanelUsers()
      },
      {
        icon: <SupervisorAccountIcon />,
        label: 'Utenti online',
        content: renderPanelOnlineUsers()
      }
    ];
    return panels;
  }
  const renderPanelsRightSidebar = () => {
    var panels = [
      {
        icon: <Videocam />,
        label: 'Videocamere',
        content: renderPanelCam()
      },
      {
        icon: <Microphone />,
        label: 'Microfoni',
        content: renderPanelMic()
      },
      {
        icon: <VolumeHigh />,
        label: 'Audio output',
        content: renderPanelAudioOutput()
      }
    ];
    return panels;
  }

  if (redirect) {
    return <Redirect to='/home' />;
  }
  else {

    var content = undefined;
    if ((isConnecting || isFetching || tryingConnect) && 1 === 2) {
      content = <CircularProgress className={classes.loadingSpinner} />;
    }
    else {
      content = (
        <div>
          <VideochatHeader onLeftSidebarChange={onLeftSidebarChange} onRightSidebarChange={onRightSidebarChange} iAmSuperUser={props.iAmSuperUser} />
          <div className={classes.flexContainer}>
            {props.iAmSuperUser && <VideochatSidebar className={classes.sidebar} open={openLeftSidebar} left>
              <XExpansionPanel panels={renderPanelsLeftSidebar()} noUnmount />
            </VideochatSidebar>}

            <div className={classes.container}>
              <Container>
                <Main>
                  {roomState !== 'disconnected' ?
                    <Room users={props.users} /> : <div />
                  }
                  <Controls
                    isAudioEnabled={isAudioEnabled}
                    disableAudio={disableAudio}
                    toggleAudio={toggleAudioEnabled}
                    isVideoEnabled={isVideoEnabled}
                    disableVideo={disableVideo}
                    toggleVideo={toggleVideoEnabled}
                    isScreenEnabled={isScreenShared}
                    toggleScreen={toggleScreenShare}
                    disableScreen={isScreenDisabled}
                    enableRequest={enableRequest}
                    onCleanup={cleanup}
                  />
                </Main>
                <ReconnectingNotification />
              </Container>
            </div>
            <VideochatSidebar className={classes.sidebar} open={openRightSidebar} right>
              <XExpansionPanel panels={renderPanelsRightSidebar()} noUnmount />
            </VideochatSidebar>
          </div>
        </div>
      );
    }


    return (
      <div>
        <div className={props.collapsed ? classes.hidden : classes.visible}>
          {content}
        </div>
      </div>
    );
  }
}
