import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import ReactToPrint from "react-to-print";
import XOXLoader from 'components/XOXLoader';
import XMessageBox from 'components/XMessageBox';
import XNavigation from 'components/XNavigation';
import XPDFViewer from 'components/XPDFViewer';
import XGrid from "components/XGrid";
import XSelect from 'components/XSelect';
import XField from 'components/XField';
import Tabs from 'components/CustomTabs/CustomTabs';
import ProductionOperator from "./ProductionOperator";
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { UnfoldLessTwoTone, UnfoldMoreTwoTone, History, Update } from '@material-ui/icons';
import { ArrowLeft, ArrowRight, Check, TextBox, Package, Printer, Sync, TrashCan, Delete, FormatListChecks } from 'mdi-material-ui';
import { Ring } from 'components/Icons';
import TabContainer from 'components/CustomTabs/TabContainer';
import { getXSelect } from 'utils';
import ProductionComponentsSelector from 'views/Production/ProductionComponentsSelector';
import ProductionStart from './ProductionStart';

const fullHeight = (window.innerHeight - 72.5 - 64);
const styles = theme => ({
	fullHeight: {
		maxHeight: fullHeight,
		minHeight: fullHeight,
		overflow: 'hidden',
		padding: '0',
	},
	root: {
		flexGrow: 1,
		backgroundColor: "#fff",
		fontSize: '12px',
	},
	contentToPrint: {
		height: 0,
		width: 0,
		position: "absolute"
	}
})
const bopProdExitFields = [
	{
		Field: "FIDPosition",
		Visibility: true,
		Required: 2,
	}
];
const bopProdFields = [
	{
		Field: "FIDPosition",
		Visibility: true,
		Required: 2,
	},
	{
		Field: "FIDDepartment",
		Visibility: true,
		Required: 2,
	},
	{
		Field: "FIDPhase",
		Visibility: true,
		Required: 1,
	},
	{
		Field: "FIDOperator",
		Visibility: true,
		Required: 1,
	},
];

var expandGridForMergeBOPS = undefined;

class ProductionExpandOrderRow extends Component {
	constructor(props) {
		super(props);
		this.innerGrid = React.createRef();
	}
	static contextTypes = {
		router: PropTypes.object
	}
	componentDidMount = () => {
		var { gridRef } = this.props;
		if (gridRef && gridRef.current) {
			gridRef.current.expandedRowGrid = this.innerGrid;
		}
	}
	onCheckedRowsChange = (row, selected) => {
		var { data, gridRef } = this.props;
		if (gridRef && gridRef.current) {

			if (!selected || selected.length <= 1) {
				gridRef.current.clearSelection();
			}
			else {
				gridRef.current.setState({
					selected: [data.ID],
				});
			}
		}
	}
	render() {
		var { width, data, gridRef } = this.props;
		var direction = "right";
		if (["xs"].includes(width)) {
			direction = "down";
		}

		var gridName = "grdProductionSubOrderRowCodesFromOrderRow";
		var dataRoute = `/Production/GetSubOrderRowCodes/?orderRowCode=${data.OrderRowCode}&grid=${gridName}`;

		return <XGrid
			key={gridName}
			containerID={`gridExpandContainer_${data.ID}`}
			innerRef={this.innerGrid}
			gridRef={gridRef}
			dataRoute={dataRoute}
			dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
			dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
			dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
			showColumnsSelector={false}
			showFilterActivator={false}
			rowsVisible={3}
			hideDefaultToolbar
			onRowChecked={this.onCheckedRowsChange}
			onRowUnchecked={this.onCheckedRowsChange}
			preventSelectAll={true}
			onGridUnmounted={(expandedGrid) => {
				expandGridForMergeBOPS = expandedGrid;
			}}
		/>;
	}
}

class Production extends Component {
	constructor(props) {
		super(props);
		this.MessageBox = React.createRef();
		this.grdOrderRows = React.createRef();
		this.grdBOPG = React.createRef();
		this.grdBOPS = React.createRef();
		this.grdProductionBOPS = React.createRef();
		this.grdFactory = React.createRef();
		this.tabs = React.createRef();
		this.embedPDFRef = React.createRef(); 
		this.logoutTO = null;

		this.state = {
			activeTab: 0,
			isLoading: false,
			showProductionPositionSelector: false,
			productionBOPEditors: [],
			bopEditIndex: -1,
			fields: bopProdFields,
			data: {
				FIDPosition: null,
				FIDDepartment: null,
				FIDPhase: null,
				FIDOperator: null,
				selectedQty: 0,
				maxQty: 0
			},
			viewRows: "ORDERROWS",
			printBOPS: false,
			divideProductionBOPS: false,
			unifyProductionBOPS: false,
			prodBOPSExit: false
		}
	}
	activateView = (activeTab) => {
		var viewRows = ""
		if (activeTab === 0) {
			viewRows = "ORDERROWS"
		}
		if (activeTab === 1) {
			viewRows = "PRODUCTIONBOPS"
		}
		this.setState({
			showProductionPositionSelector: false,
			productionBOPEditors: [],
			bopEditIndex: -1,
			fields: bopProdFields,
			data: {
				FIDPosition: null,
				FIDDepartment: null,
				FIDPhase: null,
				FIDOperator: null,
				selectedQty: 0,
				maxQty: 0
			},
			viewRows,
			printBOPS: false,
			divideProductionBOPS: false,
			unifyProductionBOPS: false,
			prodBOPSExit: false
		})
	}
	onChangeBop = (index) => ({ Qty1Edit, Qty2Edit, components }) => {
		var { selectedRows } = this.state;
		var rowData = selectedRows[index];

		rowData.Qty1Edit = Qty1Edit;
		rowData.Qty2Edit = Qty2Edit;
		rowData.components = components;
		this.setState({ selectedRows });
	}
	addProductionBOPs = () => {
		this.MessageBox.current.showMessageYesNo("CONFERMA MESSA IN PRODUZIONE", "Si desidera confermare la messa in produzione delle commesse selezionate?", () => {
			var { viewRows, selectedRows } = this.state;
			this.setState({
				isLoading: true
			});
			var bopsToadd = [];
			var grid = this.grdOrderRows;

			if (!viewRows)
				viewRows = "ORDERROWS";

			if (viewRows === "BOPG")
				grid = this.grdBOPG;

			if (viewRows === "BOPS")
				grid = this.grdBOPS;

			selectedRows.map(function (rowData) {
				var { FIDPosition, FIDDepartment, FIDPhase, FIDOperator, selectedQty, selectedQty2 } = this.state.data;
				var bopAdd = {};

				if (viewRows === "ORDERROWS") {
					bopAdd = {
						FIDOrderRow: rowData.ID,
						OrderRowCode: rowData.OrderRowCode,
						FIDBOP: rowData.FIDBOP,
						BopDescription: rowData.Description,
						FIDPosition: FIDPosition,
						FIDDepartment: FIDDepartment,
						FIDPhase: FIDPhase,
						FIDOperator: FIDOperator,
						Qty: rowData.Qty1Edit,
						Qty2: rowData.Qty2Edit,
						TotalBopQty: rowData.Qty1,
						TotalBopQty2: rowData.Qty2,
						BOPComponents: rowData.components
					};
				} else if (viewRows === "BOPS") {
					bopAdd = {
						FIDBOP: rowData.ID,
						FIDBOPPosition: rowData.FIDBOPPosition,
						OrderRowCode: rowData.OrderRowCode,
						Barcode: rowData.Barcode,
						SerialNumber: rowData.SerialNumber,
						FIDPosition: FIDPosition,
						FIDDepartment: FIDDepartment,
						FIDPhase: FIDPhase,
						FIDOperator: FIDOperator,
						Qty: rowData.Qty1Edit,
						Qty2: rowData.Qty2Edit,
						TotalBopQty: rowData.Qty1,
						TotalBopQty2: rowData.Qty2,
						BOPComponents: rowData.components
					}
				}
				else if (viewRows === "BOPG") {
					bopAdd = {
						FIDBOP: rowData.ID,
						FIDPosition: FIDPosition,
						FIDDepartment: FIDDepartment,
						FIDPhase: FIDPhase,
						FIDOperator: FIDOperator,
						Qty: rowData.Qty1Edit,
						Qty2: rowData.Qty2Edit,
						BOPComponents: rowData.components
					}
				}
				bopsToadd.push(bopAdd);
			}, this);
			fetch("Production/CreateBOPProductions", {
				method: "POST",
				body: JSON.stringify(bopsToadd)
			}).then(res => res.json())
				.then(data => {
					if (data.success) {
						grid.current && grid.current.clearSelection();
						this.setState({
							isLoading: false,
							selectedRows: [],
							showProductionPositionSelector: false,
							showProductionBOPEditor: false,
							productionBOPEditors: [],
							bopEditIndex: -1,
							activeTab: 1,
							viewRows: "PRODUCTIONBOPS",
							printRows: data.productions,
							printBOPS: true,
						});
					} else {
						this.setState({
							isLoading: false,
							selectedRows: [],
							showProductionPositionSelector: false,
							showProductionBOPEditor: false
						});
					}
				});
		});
	}
	handleBOPSMoveAssignment = () => {
		this.MessageBox.current.showMessageYesNo("CONFERMA SPOSTAMENTO", "Si desidera confermare lo spostamento delle commesse selezionate?", () => {
			var { selectedRows } = this.state;
			this.setState({
				isLoading: true
			});
			var grid = this.grdProductionBOPS;
			var bopsToMove = [];

			selectedRows.map(function (rowData) {
				var { FIDPosition, FIDDepartment, FIDPhase, FIDOperator } = this.state.data;
				var bopMove = {
					FIDBOP: rowData.FIDBOP,
					FIDBOPPosition: rowData.FIDBOPPosition,
					FIDPosition: FIDPosition,
					FIDDepartment: FIDDepartment,
					FIDPhase: FIDPhase,
					FIDOperator: FIDOperator,
				}
				bopsToMove.push(bopMove);
			}, this);

			fetch("Production/MoveBOPProductions", {
				method: "POST",
				body: JSON.stringify(bopsToMove)
			}).then(res => res.json())
				.then(data => {
					if (data.success) {
						grid.current && grid.current.clearSelection();
						this.setState({
							isLoading: false,
							selectedRows: [],
							showProductionPositionSelector: false,
							showProductionBOPEditor: false,
							productionBOPEditors: [],
							bopEditIndex: -1,
							activeTab: 1,
							viewRows: "PRODUCTIONBOPS",
							moveAssign: false,
						});
					} else {
						this.setState({
							isLoading: false,
							selectedRows: [],
							showProductionPositionSelector: false,
							showProductionBOPEditor: false,
							moveAssign: false,
						});
					}
				});
		});
	}
	splitProductionBOPs = () => {
		this.MessageBox.current.showMessageYesNo("CONFERMA SUDDIVISIONE", "Si desidera confermare la suddivisione delle commesse selezionate?", () => {
			var { selectedRows } = this.state;
			this.setState({
				isLoading: true
			});
			var grid = this.grdProductionBOPS;
			var bopsToSplit = [];

			selectedRows.map(function (rowData) {
				var { FIDPosition, FIDDepartment, FIDPhase, FIDOperator } = this.state.data;
				var bopSplit = {
					FIDBOP: rowData.FIDBOP,
					FIDBOPPosition: rowData.FIDBOPPosition,
					OrderRowCode: rowData.OrderRowCode,
					Barcode: rowData.Barcode,
					SerialNumber: rowData.SerialNumber,
					FIDPosition: FIDPosition,
					FIDDepartment: FIDDepartment,
					FIDPhase: FIDPhase,
					FIDOperator: FIDOperator,
					Qty: rowData.Qty1Edit,
					Qty2: rowData.Qty2Edit,
					BOPComponents: rowData.components
				};
				bopsToSplit.push(bopSplit);
			}, this);

			fetch("Production/SplitBOPProductions", {
				method: "POST",
				body: JSON.stringify(bopsToSplit)
			}).then(res => res.json())
				.then(data => {
					if (data.success) {
						grid.current && grid.current.clearSelection();
						this.setState({
							isLoading: false,
							selectedRows: [],
							showProductionPositionSelector: false,
							showProductionBOPEditor: false,
							productionBOPEditors: [],
							bopEditIndex: -1,
							activeTab: 1,
							viewRows: "PRODUCTIONBOPS"
						});
					} else {
						this.setState({
							isLoading: false,
							selectedRows: [],
							showProductionPositionSelector: false,
							showProductionBOPEditor: false
						});
					}
				});
		});
	}
	mergeProductionBOPS = () => {
		this.MessageBox.current.showMessageYesNo("CONFERMA UNIFICAZIONE", "Si desidera confermare l'unificazione delle sottocommesse selezionate?", () => {
			var grid = this.grdProductionBOPS;
			var selectedSubOrderBOPS = expandGridForMergeBOPS.getSelectedData();
			var rows = selectedSubOrderBOPS.map(id => {
				var rowData = expandGridForMergeBOPS.getRowData(id);
				var { FIDPosition, FIDDepartment, FIDPhase, FIDOperator } = this.state.data;
				var bopUnify = {
					FIDBOP: rowData.FIDBOP,
					FIDBOPPosition: rowData.ID,
					OrderRowCode: rowData.OrderRowCode,
					Barcode: rowData.Barcode,
					SerialNumber: rowData.SerialNumber,
					FIDPosition: FIDPosition,
					FIDDepartment: FIDDepartment,
					FIDPhase: FIDPhase,
					FIDOperator: FIDOperator,
					Qty: rowData.Qty1,
					Qty2: rowData.Qty2,
					BOPComponents: rowData.components
				};
				return bopUnify;
			})
			debugger;
			
			fetch("Production/UnifyBOPProduction", {
				method: "POST",
				body: JSON.stringify(rows)
			}).then(res => res.json())
				.then(data => {
					if (data.success) {
						grid.current && grid.current.clearSelection();
						this.setState({
							isLoading: false,
							selectedRows: [],
							showProductionPositionSelector: false,
							showProductionBOPEditor: false,
							productionBOPEditors: [],
							bopEditIndex: -1,
							activeTab: 1,
							viewRows: "PRODUCTIONBOPS"
						});
					} else {
						this.setState({
							isLoading: false,
							selectedRows: [],
							showProductionPositionSelector: false,
							showProductionBOPEditor: false
						});
					}
				});
		});
	}
	filterBOPProdFields = (data, db, key) => {
		var positions = data && data.FIDPositions ? data.FIDPositions.split(",") : [];
		var departments = data && data.FIDDepartments ? data.FIDDepartments.split(",") : [];
		var phases = data && data.FIDPhases ? data.FIDPhases.split(",") : [];
		var operators = data && data.FIDOperators ? data.FIDOperators.split(",") : [];
		//var emptyPhase = phases.indexOf("-1");
		if (key === "FIDOperator") {

			//while (emptyPhase >= 0) {
			//	phases.splice(emptyPhase, 1);
			//	emptyPhase = phases.indexOf("-1");
			//}

			if (positions.length === 1)
				db["FIDPosition"] = parseInt(positions[0]);

			if (departments.length === 1)
				db["FIDDepartment"] = parseInt(departments[0]);

			if (phases.length === 1) {
				db["FIDPhase"] = parseInt(phases[0]);
			}
		}

		if (["FIDPhase", "FIDDepartment"].includes(key) && data && data.FIDPosition > 0) {
			db["FIDPosition"] = data.FIDPosition;
		}
		if (["FIDPhase"].includes(key) && data && data.FIDDepartment > 0) {
			db["FIDDepartment"] = data.FIDDepartment;
		}

		if (["FIDPhase", "FIDDepartment"].includes(key)) {
			if (db["FIDOperator"] > 0) {

				if (operators && !operators.includes(db["FIDOperator"].toString()))
					db["FIDOperator"] = undefined;
			} else {
				if (operators && operators.length === 1)
					db["FIDOperator"] = parseInt(operators[0]);
			}

			if (data && data.FIDDepartment)
				db["FIDDepartment"] = data.FIDDepartment;
		}
		if (["FIDDepartment"].includes(key)) {
			if (db["FIDPhase"] > 0) {
				if (phases && !phases.includes(db["FIDPhase"].toString()))
					db["FIDPhase"] = undefined;
			} else {
				if (phases && phases.length === 1)
					db["FIDPhase"] = parseInt(phases[0]);
			}

			if (db["FIDOperator"] > 0) {
				if (operators && !operators.includes(db["FIDOperator"].toString()))
					db["FIDOperator"] = undefined;
			} else {
				if (operators && operators.length === 1)
					db["FIDOperator"] = parseInt(operators[0]);
			}
		}

		return db;
	}
	handleBOPProdFieldChange = (key) => (event, data) => {
		this.setState((state, props) => {

			state.data[key] = data.length > 0 ? data[0].ID : null;

			state.data = this.filterBOPProdFields(data[0], state.data, key);
			return state;
		});
	}
	handleExitPositionChange = (key) => (event, data) => {
		this.setState((state, props) => {
			state.data[key] = data.length > 0 ? data[0].ID : null;
			return state;
		});
	}
	handleProdQtyChange = (key) => (event, data) => {
		if (data) {
			//this.setState({ contactLabel: data[0].Denomination });
			this.setState((state) => {
				var newData = state.data;
				if (data && data.length > 0 && parseInt(data) <= state.maxQty) {
					newData.selectedQty = parseInt(data);
					return { data: newData };
				}
			})
		}
	}
	handlePrintBOPS = () => {
		var printRows = this.grdProductionBOPS.current.getSelectedData();
		this.setState({
			printRows,
			printBOPS: true
		},this.grdProductionBOPS.current.clearSelection);
	}
	sendToPrinter = () => {
		this.setState({
			sendToPrinter: true
		});
	}
	cancelPrint = () => {
		this.setState({
			printBOPS: false
		})
	}
	handleBOPSProdDivide = () => {
		this.togglePositionSelector(true);
	}
	handleBOPSActivateComponents = () => {
		var {showProductionPositionSelector} = this.state;
		var grid = this.grdProductionBOPS;
		var selectedRows = grid.current.getSelectedData();
		var rows = selectedRows.map(function (index) {
			var rowData = grid.current.getRowData(index);

			if (showProductionPositionSelector) {
				rowData.Qty1Edit = rowData.Qty1Remaining;
				rowData.Qty2Edit = rowData.Qty2Remaining;
				rowData.components = [];
			}

			return rowData;
		});
		
		this.setState({
			showComponentActivation: true,
			divideProductionBOPS: false,
			unifyProductionBOPS: false,
			data: {
				FIDPosition: null,
			},
			selectedRows: rows
		},() => {
			this.toggleProductionBOPEditor(false, false, true);
		})
	}
	cancelBOPSProdDivide = () => {
		this.setState({
			showProductionPositionSelector: false
		})
	}
	handleBOPSProdUnify = () => {
		this.togglePositionSelector(false, true);
	}
	handleExitFromProductionBOPS = () => {
		this.setState({
			prodBOPSExit: true,
			fields: bopProdExitFields
		})
	}
	cancelProductionBOPSExit = () => {
		this.setState({
			prodBOPSExit: false,
			fields: bopProdFields
		})
	}
	exitBOPSFromProduction = () => {
		var bopsToExit = [];
		var gridRef = this.grdProductionBOPS;

		if (!this.state.data.FIDPosition) {
			var buttons = [
				{
					label: "OK",
					action: () => {
						this.MessageBox.current.hideDialog();
					}
				}
			];
			this.MessageBox.current.showDialog(buttons, "", "Selezionare una posizione");
			return;
		}

		this.setState({
			isLoading: true
		});

		var selectedRows = gridRef.current.getSelectedData();

		selectedRows.map(function (index) {
			var rowData = gridRef.current.getRowData(index);
			var { FIDPosition } = this.state.data;
			var bopExit = {};

			bopExit = {
				FIDBOP: rowData.ID,
				FIDBOPPosition: rowData.FIDBOPPosition,
				FIDPosition: FIDPosition
			}
			bopsToExit.push(bopExit);
		}, this);
		fetch("Production/ExitBOPS", {
			method: "POST",
			body: JSON.stringify(bopsToExit)
		}).then(res => res.json())
			.then(data => {
				if (data.success) {
					this.setState({
						isLoading: false,
						prodBOPSExit: false,
						fields: bopProdFields
					});
				}
			});
	}
	handleCancelProductionBOPS = () => {
		this.MessageBox.current.showMessageYesNo("CONFERMA CANCELLAZIONE", "Si desidera cancellare le sottocommesse selezionate?", this.cancelProductionBOPS);
	}
	cancelProductionBOPS = () => {
		var grid = this.grdProductionBOPS;
		this.setState({
			isLoading: true
		});

		var bopsToCancel = grid.current.getSelectedData();

		fetch("Production/CancelBOPProductions", {
			method: "POST",
			body: JSON.stringify(bopsToCancel)
		}).then(res => res.json())
			.then(data => {
				if (data.success) {
					grid.current && grid.current.clearSelection();
					this.setState({
						isLoading: false,
						selectedRows: [],
						showProductionPositionSelector: false,
						showProductionBOPEditor: false,
						productionBOPEditors: [],
						bopEditIndex: -1,
						activeTab: 1,
						viewRows: "PRODUCTIONBOPS"
					});
				} else {
					this.setState({
						isLoading: false,
						activeTab: 1,
						viewRows: "PRODUCTIONBOPS"
					});
				}
			});
	} 
	togglePositionSelector = (divideProdBOPS = false, unifyProdBOPS = false) => {
		var { viewRows, productionBOPEditors, showProductionPositionSelector } = this.state;
		var grid = this.grdOrderRows;

		if (viewRows === "BOPG")
			grid = this.grdBOPG;

		if (viewRows === "BOPS")
			grid = this.grdBOPS;

		if (viewRows === "PRODUCTIONBOPS" || viewRows === "PRODBOPS_UNIFY")
			grid = this.grdProductionBOPS;


		var selectedRows = grid.current.getSelectedData();
		var rows = selectedRows.map(function (index) {
			var rowData = grid.current.getRowData(index);

			if (showProductionPositionSelector) {
				rowData.Qty1Edit = rowData.Qty1Remaining;
				rowData.Qty2Edit = rowData.Qty2Remaining;
				rowData.components = [];
			}

			return rowData;
		})

		if (showProductionPositionSelector) {
			productionBOPEditors = [];
		}

		this.setState({
			showProductionPositionSelector: !showProductionPositionSelector,
			divideProductionBOPS: divideProdBOPS,
			unifyProductionBOPS: unifyProdBOPS,
			productionBOPEditors,
			data: {
				FIDPosition: null,
			},
			selectedRows: rows
		})

		var expandedRow = grid.current && grid.current.state.expandedRow;
		//var selectedMain = grid.current && grid.current.state.selected;
		var selectedFromExpansion = expandGridForMergeBOPS ? expandGridForMergeBOPS.state.selected : [];
		//grid.current && grid.current.refresh();
		if (showProductionPositionSelector && viewRows === "PRODBOPS_UNIFY") {
			grid.current.expandedRowGrid.current = expandGridForMergeBOPS;
			(expandedRow != undefined && expandedRow > -1) && grid.current && grid.current.onRowExpand(expandedRow, selectedFromExpansion);
			grid.current && grid.current.setState({
				expandedRow,
				expandedRowHeight: 190,
				//selected: selectedMain
			});
			//expandGridForMergeBOPS = undefined;
			//if (grid.current.expandedRowGrid.current) {
			//	if (selectedFromExpansion && selectedFromExpansion.length > 0) {
			//		grid.current.expandedRowGrid.current.setState({
			//			selected: selectedFromExpansion
			//		})
			//	}
			//}
		}
	}
	showBOPG = () => {
		this.setState({
			viewRows: "BOPG"
		})
	}
	showBOPS = () => {
		this.setState({
			viewRows: "BOPS"
		})
	}
	showOrderRows = () => {
		this.setState({
			viewRows: "ORDERROWS"
		})
	}
	showProductionBOPS = () => {
		this.setState({
			viewRows: "PRODUCTIONBOPS"
		})
		this.grdProductionBOPS.current && this.grdProductionBOPS.current.refresh();
		this.grdProductionBOPS.current && this.grdProductionBOPS.current.setState({
			expandedRow: undefined,
			expandedRowHeight: 0
		})
	}
	showProductionBOPSMoveAssign = () => {
		this.setState({
			viewRows: "PRODUCTIONBOPS_MOVE_ASSIGN"
		})
		this.grdProductionBOPS.current && this.grdProductionBOPS.current.refresh();
		this.grdProductionBOPS.current && this.grdProductionBOPS.current.setState({
			expandedRow: undefined,
			expandedRowHeight: 0
		})
	}
	showProductionBOPSDivide = () => {
		this.setState({
			viewRows: "PRODBOPS_DIVIDE"
		})
		this.grdProductionBOPS.current && this.grdProductionBOPS.current.refresh();
		this.grdProductionBOPS.current && this.grdProductionBOPS.current.setState({
			expandedRow: undefined,
			expandedRowHeight: 0
		})
	}
	showProductionBOPSUnify = () => {
		this.setState({
			viewRows: "PRODBOPS_UNIFY"
		})
		this.grdProductionBOPS.current && this.grdProductionBOPS.current.refresh();
		this.grdProductionBOPS.current && this.grdProductionBOPS.current.setState({
			expandedRow: undefined,
			expandedRowHeight: 190
		})
	}

	showCompletedWorkProds = () => {
		this.setState({
			viewRows: "PRODS_COMPLETED"
		})
		this.grdFactory.current && this.grdFactory.current.refresh();
	}

	showRunningWorkProds = () => {
		this.setState({
			viewRows: "PRODS_RUNNING"
		})
		this.grdFactory.current && this.grdFactory.current.refresh();
	}

	toggleProductionBOPEditor = (split = false, checkPositionDeparment = true, activationMode = false) => {

		if ( checkPositionDeparment && (!this.state.data.FIDPosition || !this.state.data.FIDDepartment) ) {
			var buttons = [
				{
					label: "OK",
					action: () => {
						this.MessageBox.current.hideDialog();
					}
				}
			];
			this.MessageBox.current.showDialog(buttons, "PRODUZIONE", "Selezionare una posizione e un reparto");
			return;
		}
		var { showProductionPositionSelector,showComponentActivation, productionBOPEditors, selectedRows, viewRows } = this.state;
		var bopEditIndex = -1;
		if (showProductionPositionSelector) {
			bopEditIndex = 0;
			if (activationMode || viewRows === "ORDERROWS" || viewRows === "BOPG")
				productionBOPEditors.push(<ProductionComponentsSelector bop={selectedRows[0]} mode="activation" onChange={this.onChangeBop(0)} />);
			else
				productionBOPEditors.push(<ProductionComponentsSelector bopPosition={selectedRows[0]} mode={split ? "split" : "activation"} onChange={this.onChangeBop(0)} />);
		}
		else
			productionBOPEditors = [];
		
		if(showComponentActivation){
			showProductionPositionSelector = true;
		}
		this.setState({
			showProductionPositionSelector: !this.state.showProductionPositionSelector,
			productionBOPEditors,
			bopEditIndex,
			divideProductionBOPS: split
		});
	}
	increaseEditIndex = (split = false) => {
		var { viewRows, productionBOPEditors, selectedRows, bopEditIndex } = this.state;
		bopEditIndex++;

		if (!productionBOPEditors[bopEditIndex]) {
			if (viewRows === "ORDERROWS" || viewRows === "BOPG")
				productionBOPEditors.push(<ProductionComponentsSelector bop={selectedRows[bopEditIndex]} mode="activation" onChange={this.onChangeBop(bopEditIndex)} />);
			else
				productionBOPEditors.push(<ProductionComponentsSelector bopPosition={selectedRows[bopEditIndex]} mode={split ? "split" : "activation"} onChange={this.onChangeBop(bopEditIndex)} />);
		} 

		productionBOPEditors[bopEditIndex-1] = viewRows === "ORDERROWS" || viewRows === "BOPG" ? <ProductionComponentsSelector bop={selectedRows[bopEditIndex-1]} mode="activation" onChange={this.onChangeBop(bopEditIndex-1)} /> :
			<ProductionComponentsSelector bopPosition={selectedRows[bopEditIndex - 1]} mode={split ? "split" : "activation"} onChange={this.onChangeBop(bopEditIndex-1)} />;
		

		this.setState({
			productionBOPEditors,
			bopEditIndex,
		})
	}
	decreaseEditIndex = (split = false) => {
		var { viewRows, selectedRows, productionBOPEditors, bopEditIndex } = this.state;

		productionBOPEditors[bopEditIndex] = viewRows === "ORDERROWS" || viewRows === "BOPG" ? <ProductionComponentsSelector bop={selectedRows[bopEditIndex]} mode="activation" onChange={this.onChangeBop(bopEditIndex)} /> : 
			<ProductionComponentsSelector bopPosition={selectedRows[bopEditIndex]} mode={split ? "split" : "activation"} onChange={this.onChangeBop(bopEditIndex)} />;
		bopEditIndex--;

		this.setState({
			bopEditIndex,
			productionBOPEditors
		})
	}
	clearLogoutTimeout = () => {
		if (this.logoutTO)
			window.clearTimeout(this.logoutTO);
	}
	logoutAfterOperation = () => {
		var { logout } = this.props;
		if(this.props.params && this.props.params.ConfigProduction && this.props.params.ConfigProduction.Autologout) {
			this.logoutTO = setTimeout(() => {
				logout && logout();
			}, 1000 * (this.props.params.ConfigProduction.AutologoutTime || 5));
		}
	}
	rowExpandFormat = (data) => {
		var gridRef = this.grdProductionBOPS;
		return <ProductionExpandOrderRow data={data} gridRef={gridRef} />;
	}
	renderTabOrderRowBops = () => {
		var { classes } = this.props;
		var { showProductionPositionSelector, productionBOPEditors, bopEditIndex, data, fields, viewRows, selectedRows } = this.state;

		if (!viewRows)
			viewRows = "ORDERROWS";

		var gridRef = this.grdOrderRows;
		var gridName = "grdProductionOrderRows";
		var getDataURI = "/Production/GetProductionOrderRows";
		if (viewRows === "BOPG") {
			gridRef = this.grdBOPG;
			gridName = "grdProductionBOPG";
			getDataURI = "/Production/GetProductionBOPG";
		}
		if (viewRows === "BOPS") {
			gridRef = this.grdBOPS;
			gridName = "grdProductionBOPStock";
			getDataURI = "/BOP/GetBopsStock";
		}

		var grdDataRoute = `${getDataURI}?grid=${gridName}`;
		var grdDataRouteColumns = `/Base/GetColumns?grid=${gridName}`;
		var grdDataRouteAvailCols = `/Base/GetAvailableColumns?grid=${gridName}`;
		var grdDataRouteUpdateCols = `/Base/UpdateColumns?grid=${gridName}`;

		var customContent = undefined;
		var customActions = [];
		customActions.push(
			//<Tooltip title="Metti in produzione">
			//	<IconButton aria-label="Metti in produzione" onClick={this.togglePositionSelector}>
			//		<ArrowRight />
			//	</IconButton>
			//</Tooltip>
			{
				group: 'actions',
				name: 'Metti in produzione',
				icon: <ArrowRight />,
				action: this.togglePositionSelector,
				condition: r => r.length > 0
			}
		);

		//var customActions = [];
		var label = "Righe ordini";
		var addLabel = "";
		if (showProductionPositionSelector) {
			var divStyle = { width: 'calc(100% - 16px)', padding: 16 };
			//selectedActions = [];
			var operatorFilters = [];
			if (data.FIDDepartment)
				operatorFilters.push({ key: "FIDDepartments", value: data.FIDDepartment, type: "array" });
			if (data.FIDPhase)
				operatorFilters.push({ key: "FIDPhases", value: data.FIDPhase, type: "array" });
			customContent = <div style={divStyle}><Grid item container spacing={2} direction="row" sx={12}>
				<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDPosition", "Posizione di produzione", this.handleBOPProdFieldChange, "/Production/GetProductionPositions", "", { readOnly: false, filters: [], sm: 6, valueKey: "Position", /*linkedFields: [ "FIDDepartment", "FIDPhase", "FIDOperator" ]*/ })}</Grid>
					< Grid item xs = { 12} sm = { 6} md = { 3} > { getXSelect(data, fields, 12, 12, "FIDDepartment", "Reparto", this.handleBOPProdFieldChange, "/Production/GetProductionDepartments", "", { readOnly: false, filters: [{ key: "FIDPosition", value: data.FIDPosition }], sm: 6, valueKey: "Department", /*linkedFields: ["FIDPhase", "FIDOperator"]*/ })}</Grid>
				<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDPhase", "Fase di lavorazione", this.handleBOPProdFieldChange, "/Production/GetProductionPhases", "", { readOnly: false, filters: [{ key: "FIDPosition", value: data.FIDPosition }, { key: "FIDDepartment", value: data.FIDDepartment }], sm: 6, valueKey: "Phase", /*linkedFields: [ "FIDOperator" ]*/ })}</Grid>
				<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDOperator", "Operatore", this.handleBOPProdFieldChange, "/Production/GetEmployees", "", { readOnly: false, filters: operatorFilters, sm: 6, valueKey: "Operator" })}</Grid>
			</Grid></div>;
			customActions = [];
			customActions.push(
				{
					group: 'actions',
					name: 'Q.ta e componenti',
					icon: <ArrowRight />,
					action: () => { this.toggleProductionBOPEditor() }
				}
			);
			customActions.push(
				{
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.togglePositionSelector
				}
			);
			addLabel = "Seleziona posizione, reparto, fase e operatore";
		}

		if (productionBOPEditors.length > 0) {
			var ttLabel = "Metti in produzione";

			if (bopEditIndex < (selectedRows.length - 1))
				ttLabel = "Avanti";

			addLabel = "" + (bopEditIndex + 1) + " / " + selectedRows.length;
			customActions = [];
			customActions.push({
				group: 'actions',
				name: ttLabel,
				icon: bopEditIndex === (selectedRows.length - 1) ? <Check /> : <ArrowRight />,
				action: bopEditIndex === (selectedRows.length - 1) ? this.addProductionBOPs : this.increaseEditIndex
			});
			customActions.push({
				group: 'actions',
				name: 'Indietro',
				icon: <ArrowLeft />,
				action: bopEditIndex === 0 ? this.toggleProductionBOPEditor : this.decreaseEditIndex
			});
			customContent = productionBOPEditors[bopEditIndex];
			//var editorContent = selectedRows.map((rowData, index) => {
				//var rowData = gridRef.current.getRowData(index);
			//if (viewRows === "ORDERROWS" || viewRows === "BOPG")
			//	customContent = <ProductionComponentsSelector bop={productionBOPEditors[bopEditIndex]} mode="activation" onChange={this.onChangeBop(bopEditIndex) } />;
			//else 
			//	customContent = <ProductionComponentsSelector bopPosition={productionBOPEditors[bopEditIndex]} mode="activation" onChange={this.onChangeBop(bopEditIndex)} />;
			//})
			//customContent = <Fragment>{editorContent}</Fragment>;
		}

		var direction = "right";
		var navActions = [];
		navActions.push({ name: "Ordini", icon: <TextBox />, action: this.showOrderRows, selected: viewRows === "ORDERROWS" });
		navActions.push({ name: "Modelli", icon: <Ring />, action: this.showBOPG, selected: viewRows === "BOPG" });
		navActions.push({ name: "Matricole", icon: <Package />, action: this.showBOPS, selected: viewRows === "BOPS" })
		label = <span><XNavigation actions={navActions} direction={direction} showLabel /></span>

		return (<Grid container>	
				<XGrid
					key={viewRows}
					containerID="OrderRowsManagement"
					label={label}
					additionalLabel={addLabel}
					innerRef={gridRef}
					usePrimaryColor={false}
					useSecondaryColor={false}
					dataRoute={grdDataRoute}
					dataRouteColumns={grdDataRouteColumns}
					dataRouteAvailableColumns={grdDataRouteAvailCols}
					dataRouteUpdateColumns={grdDataRouteUpdateCols}
					customContent={customContent}
					actions={customActions}
					showLoader
					showChangeView
					cardWidth={3}
					cardHeight={3.5}
					showImgColumn={true}
				/>
		</Grid>
		);
	}
	notAssignedCondition = (r) => {
		var gridRef = this.grdProductionBOPS;
		if(r.length > 0){
			for(var i in r){
				var data = gridRef.current.getRowData(r[i]);
				if(data.FIDOperator){
					return false;
				}
			}
			return true;	
		}
		return false;
	}
	handleMoveAssign = () =>{
		this.setState({ moveAssign: true},this.togglePositionSelector);
	}
	renderTabManageProductionBOPS = () => {
		var { classes } = this.props;

		var gridRef = this.grdProductionBOPS;
		var gridName = "grdBOPSProduction";
		var getDataURI = "GetProductionBOPS";

		var { data, fields, showProductionPositionSelector, productionBOPEditors, bopEditIndex, selectedRows, viewRows, printBOPS, 
			divideProductionBOPS, unifyProductionBOPS,prodBOPSExit, moveAssign,showComponentActivation,printRows, sendToPrinter } = this.state;
		var notAssignedOnly = 0;
		// if (viewRows === "PRODBOPS_DIVIDE") {
		// 	getDataURI = "GetProductionSplittableBOPS";
		// 	notAssignedOnly = 1;
		// }

		if (viewRows === "PRODBOPS_UNIFY") {
			gridName = "grdProductionOrderRowsBOPS"
			getDataURI = "GetProductionSplittedBOPS";
			notAssignedOnly = 1;
		}

		// if (viewRows === "PRODUCTIONBOPS_MOVE_ASSIGN") {
		// 	notAssignedOnly = 0;
		// }

		var grdDataRoute = `/Production/${getDataURI}?notassigned=${notAssignedOnly}&grid=${gridName}`;
		var grdDataRouteColumns = `/Base/GetColumns?grid=${gridName}`;
		var grdDataRouteAvailCols = `/Base/GetAvailableColumns?grid=${gridName}`;
		var grdDataRouteUpdateCols = `/Base/UpdateColumns?grid=${gridName}`;

		var customActions = [];
		if (viewRows === "PRODUCTIONBOPS") {
			customActions = [
				{
					group: 'actions',
					name: 'Elimina',
					icon: <Delete />,
					action: this.handleCancelProductionBOPS,
					condition: r => r.length > 0
				},
				{
					group: 'actions',
					name: 'Stampa',
					icon: <Printer />,
					action: this.handlePrintBOPS,
					condition: r => r.length > 0
				},
				{
					group: 'actions',
					name: 'Spostamento',
					icon: <ArrowRight />,
					action: this.handleMoveAssign,
					condition: r => r.length > 0
				},
				{
					group: 'actions',
					name: 'Dividi commesse',
					icon: <UnfoldMoreTwoTone />,
					action: this.handleBOPSProdDivide,
					condition: this.notAssignedCondition
				},
				// {
				// 	group: 'actions',
				// 	name: 'Attiva componenti',
				// 	icon: <FormatListChecks />,
				// 	action: this.handleBOPSActivateComponents,
				// 	condition: this.notAssignedCondition
				// },
				{
					group: 'actions',
					name: 'Uscita prod.',
					icon: <Check />,
					action: this.handleExitFromProductionBOPS,
					condition: this.notAssignedCondition
				}
			];
		} else if (viewRows === "PRODBOPS_UNIFY") {
			customActions = [
				{
					group: 'actions',
					name: 'Unisci commesse',
					icon: <UnfoldLessTwoTone />,
					action: this.handleBOPSProdUnify,
					condition: r => r.length > 0
				}
			];
		}

		

		var direction = "right";
		var navActions = [];
		navActions.push({ name: "Analitico", icon: <Package />, action: this.showProductionBOPS, selected: viewRows === "PRODUCTIONBOPS" });
		//navActions.push({ name: "Spostamenti", icon: <Sync />, action: this.showProductionBOPSMoveAssign, selected: viewRows === "PRODUCTIONBOPS_MOVE_ASSIGN" });
		//navActions.push({ name: "Suddivisione", icon: <UnfoldMoreTwoTone />, action: this.showProductionBOPSDivide, selected: viewRows === "PRODBOPS_DIVIDE" });
		navActions.push({ name: "Raggruppato", icon: <UnfoldLessTwoTone />, action: this.showProductionBOPSUnify, selected: viewRows === "PRODBOPS_UNIFY" });
		var label = <span><XNavigation actions={navActions} direction={direction} showLabel /></span>

		var addLabel = "";
		var customContent = undefined;

		//if (sendToPrinter) {
		//	//var embedRef = <embed ref={el => (this.embedPDFRef = el)} src={url} type="application/pdf" width="100%" height={document.body.offsetHeight - 259} />;
		//	customContent = <ReactToPrint trigger={() => <Printer />} content={() => this.embedPDFRef} />
		//}
		//else
		if (printBOPS) {
			var s = "";
			for (var i = 0; i < printRows.length; i++) {
				if (s.length === 0)
					s = `bops=${printRows[i]}`;
				else
				s += `&bops=${printRows[i]}`;
			}
			var url = `/Production/PrintProductionBOPS/?${s}`;
			customContent = <XPDFViewer key="print_prod_bops" url={url} width="100%" height={document.body.offsetHeight - 259} />;
			customActions = [
				{
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.cancelPrint
				},
				//{
				//	group: 'actions',
				//	name: 'Stampa',
				//	icon: <Printer />,
				//	action: this.sendToPrinter
				//}
			];
			label = undefined;
		} else if (showProductionPositionSelector) {
			var divStyle = { width: 'calc(100% - 16px)', padding: 16 };
			var operatorFilters = [];
			if (data.FIDDepartment)
				operatorFilters.push({ key: "FIDDepartments", value: data.FIDDepartment, type: "array" });
			if (data.FIDPhase)
				operatorFilters.push({ key: "FIDPhases", value: data.FIDPhase, type: "array" });
			customContent = <div style={divStyle}><Grid item container spacing={2} direction="row" sx={12}>
				<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDPosition", "Posizione di produzione", this.handleBOPProdFieldChange, "/Production/GetProductionPositions", "", { readOnly: false, filters: [], sm: 6, valueKey: "Position", /*linkedFields: [ "FIDDepartment", "FIDPhase", "FIDOperator" ]*/ })}</Grid>
				< Grid item xs={12} sm={6} md={3} > {getXSelect(data, fields, 12, 12, "FIDDepartment", "Reparto", this.handleBOPProdFieldChange, "/prod/depart/GetSelect", "", { readOnly: false, filters: [{ key: "FIDPosition", value: data.FIDPosition }], sm: 6, valueKey: "Department", /*linkedFields: ["FIDPhase", "FIDOperator"]*/ })}</Grid>
				<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDPhase", "Fase di lavorazione", this.handleBOPProdFieldChange, "/prod/phase/GetSelect", "", { readOnly: false, filters: [{ key: "FIDPosition", value: data.FIDPosition }, { key: "FIDDepartment", value: data.FIDDepartment }], sm: 6, valueKey: "Phase", /*linkedFields: [ "FIDOperator" ]*/ })}</Grid>
				<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDOperator", "Operatore", this.handleBOPProdFieldChange, "/Production/GetEmployees", "", { readOnly: false, filters: operatorFilters, sm: 6, valueKey: "Operator" })}</Grid>
			</Grid></div>;

			var backActionCB = this.togglePositionSelector;

			if (viewRows === "PRODBOPS_DIVIDE") {
				backActionCB = () => { this.togglePositionSelector(true) };
			} else if (viewRows === "PRODBOPS_UNIFY") {
				backActionCB = () => { this.togglePositionSelector(false, true) };
			}
			customActions = [];

			if (moveAssign) {
				customActions.push(
					{
						group: 'actions',
						name: 'Conferma',
						icon: <Check />,
						action: this.handleBOPSMoveAssignment
					}
				);
			}
			else if (viewRows === "PRODBOPS_UNIFY") {
				customActions.push(
					{
						group: 'actions',
						name: 'Conferma2',
						icon: <Check />,
						action: this.mergeProductionBOPS
					}
				);
			}
			else {
				customActions.push(
					{
						group: 'actions',
						name: "Q.ta e componenti",
						icon: <ArrowRight />,
						action: () => { this.toggleProductionBOPEditor(true) }
					}
				);
			}
			
			customActions.push({
				group: 'actions',
				name: 'Indietro',
				icon: <ArrowLeft />,
				action: backActionCB
			});

			addLabel = "Seleziona posizione, reparto, fase e operatore";
			label = undefined;
		} else if (divideProductionBOPS && productionBOPEditors.length > 0) {
				var ttLabel = "Suddivisione commesse";

				if (bopEditIndex < (selectedRows.length - 1))
					ttLabel = "Avanti";

				addLabel = "" + (bopEditIndex + 1) + " / " + selectedRows.length;

				customActions = [
					{
						group: 'actions',
						name: ttLabel,
						icon: bopEditIndex === (selectedRows.length - 1) ? <Check /> : <ArrowRight /> ,
						action: bopEditIndex === (selectedRows.length - 1) ? this.splitProductionBOPs : () => { this.increaseEditIndex(true) }
					},
					{
						group: 'actions',
						name: 'Indietro',
						icon: <ArrowLeft />,
						action: bopEditIndex === 0 ? this.toggleProductionBOPEditor : () => { this.decreaseEditIndex(true) }
					},
				];
				customContent = productionBOPEditors[bopEditIndex];
				//var editorContent = selectedRows.map((rowData, index) => {
				//var rowData = gridRef.current.getRowData(index);
				//if (viewRows === "ORDERROWS" || viewRows === "BOPG")
				//	customContent = <ProductionComponentsSelector bop={productionBOPEditors[bopEditIndex]} mode="activation" onChange={this.onChangeBop(bopEditIndex) } />;
				//else 
				//	customContent = <ProductionComponentsSelector bopPosition={productionBOPEditors[bopEditIndex]} mode="activation" onChange={this.onChangeBop(bopEditIndex)} />;
				//})
				//customContent = <Fragment>{editorContent}</Fragment>;
			label = undefined;
		}
		else if (prodBOPSExit) {
			var divStyle = { width: 'calc(100% - 16px)', padding: 16 };
			customContent = <div style={divStyle}><Grid item container spacing={2} direction="row" sx={12}>
				<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDPosition", "Posizione", this.handleExitPositionChange, "/mag/position/GetSelect?productionexclude=0", "", { readOnly: false, filters: [], sm: 6, valueKey: "Denomination" })}</Grid>
			</Grid></div>
			customActions = [];
			customActions.push({
				group: 'actions',
				name: 'Uscita prod.',
				icon: <Check />,
				action: this.exitBOPSFromProduction
			});
			customActions.push({
				group: 'actions',
				name: 'Indietro',
				icon: <ArrowLeft />,
				action: this.cancelProductionBOPSExit
			});
			label = undefined;
		}
		else if(showComponentActivation && productionBOPEditors.length > 0) {
			customContent = productionBOPEditors[bopEditIndex];
			label = undefined;
		}

		return (<Grid container>
			<XGrid
				key={viewRows}
				containerID="ProductionBOPSManagement"
				label={label}
				additionalLabel={addLabel}
				innerRef={gridRef}
				usePrimaryColor={false}
				useSecondaryColor={false}
				dataRoute={grdDataRoute}
				dataRouteColumns={grdDataRouteColumns}
				dataRouteAvailableColumns={grdDataRouteAvailCols}
				dataRouteUpdateColumns={grdDataRouteUpdateCols}
				customContent={customContent}
				actions={customActions}
				showLoader
				showChangeView
				cardWidth={3}
				cardHeight={3.5}
				rowExpandFormat={viewRows === "PRODBOPS_UNIFY" ? this.rowExpandFormat : undefined}
				rowSelectableOnlyIfExpanded={viewRows === "PRODBOPS_UNIFY" ? true : false}
				expandedRowHeight={190}
				preventSelectAll={viewRows === "PRODBOPS_UNIFY" ? true : false}
				onRowChecked={viewRows === "PRODBOPS_UNIFY" ? (dataRow, selectedRows) => {
					gridRef.current && gridRef.current.expandedRowGrid.current && gridRef.current.expandedRowGrid.current.setState(state => ({ selected: state.data.map(n => n.ID), selectAll: true }))
				} : undefined}
				onRowUnchecked={viewRows === "PRODBOPS_UNIFY" ? (dataRow, selectedRows) => {
					gridRef.current && gridRef.current.expandedRowGrid.current && gridRef.current.expandedRowGrid.current.setState(state => ({ selected: [], selectAll: false }))
				} : undefined}
			/>
		</Grid>
		);
	}

	onRowChecked = (selectedRow) => {
		if (selectedRow.FIDActivityType === 1)
			selectedRow.preventChangeFields = ["OrderRowCode"];
	}
	onRowChange = async (data, index, key, value) => {
		if (key !== "FIDBOPProduction")
			return data;
		var dbData = await fetch("Production/GetProductionOrderRowData?bopID=" + value).then(res => res.json());

		for (let keyName in dbData.orderRowData) {
			data[index][keyName] = dbData.orderRowData[keyName] ? dbData.orderRowData[keyName] : "";
		}

		return data;
	}

	renderTabFactorySituation = () => {

		var { viewRows } = this.state;

		if (viewRows !== "PRODS_COMPLETED" && viewRows !== "PRODS_RUNNING")
			viewRows = "PRODS_COMPLETED";

		var direction = "right";
		var navActions = [];
		navActions.push({ name: "Storico", icon: <History />, action: this.showCompletedWorkProds, selected: viewRows === "PRODS_COMPLETED" });
		//navActions.push({ name: "Spostamenti", icon: <Sync />, action: this.showProductionBOPSMoveAssign, selected: viewRows === "PRODUCTIONBOPS_MOVE_ASSIGN" });
		//navActions.push({ name: "Suddivisione", icon: <UnfoldMoreTwoTone />, action: this.showProductionBOPSDivide, selected: viewRows === "PRODBOPS_DIVIDE" });
		navActions.push({ name: "Corrente", icon: <Update />, action: this.showRunningWorkProds, selected: viewRows === "PRODS_RUNNING" });
		var label = <span><XNavigation actions={navActions} direction={direction} showLabel /></span>

		var onlyRunning = viewRows === "PRODS_RUNNING" ? true : false;

		var gridRef = this.grdFactory;
		var gridFactoryName = "grdProductionWorkActivities"
		var gridFactoryUrl = `/Production/GetWorkActivities?running=${onlyRunning}&grid=${gridFactoryName}`;
		var gridFactoryDataRouteColumns = `/Base/GetColumns?grid=${gridFactoryName}`;
		var gridFactoryDataRouteAvailCols = `/Base/GetAvailableColumns?grid=${gridFactoryName}`;
		var gridFactoryDataRouteUpdateCols = `/Base/UpdateColumns?grid=${gridFactoryName}`;

		return (<Grid container>
				<XGrid
					containerID="FactorySituation"
					usePrimaryColor={false}
					useSecondaryColor={false}
					innerRef={gridRef}
					//editable={true}
					//preventSelectAll={true}
					label={label}
					dataRoute={gridFactoryUrl}
					//dataRouteUpdateRows="/Production/UpdateWorkActivities"
					//dataRouteDelete="/Production/DeleteWorkActivities"
					dataRouteColumns={gridFactoryDataRouteColumns}
					dataRouteAvailableColumns={gridFactoryDataRouteAvailCols}
					dataRouteUpdateColumns={gridFactoryDataRouteUpdateCols}
					//onRowChecked={this.onRowChecked}
					//onRowChange={this.onRowChange}
				/>
		</Grid>);
	}

	renderTabProductionBOPSExit = () => {
		var { prodBOPSExit, data, fields } = this.state;
		var gridRef = this.grdProductionBOPS;
		var gridName = "grdBOPSExitProduction";
		var getDataURI = "GetProductionBOPS";

		var grdDataRoute = `/Production/${getDataURI}?notassigned=1&splitted=0&grid=${gridName}`;
		var grdDataRouteColumns = `/Base/GetColumns?grid=${gridName}`;
		var grdDataRouteAvailCols = `/Base/GetAvailableColumns?grid=${gridName}`;
		var grdDataRouteUpdateCols = `/Base/UpdateColumns?grid=${gridName}`;

		var customActions = [
			{
				group: 'actions',
				name: 'Uscita prod.',
				icon: <Check />,
				action: this.handleExitFromProductionBOPS,
				condition: r => r.length > 0
			}
		];

		var customContent = undefined;
		if (prodBOPSExit) {
			var divStyle = { width: 'calc(100% - 16px)', padding: 16 };
			customContent = <div style={divStyle}><Grid item container spacing={2} direction="row" sx={12}>
				<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDPosition", "Posizione", this.handleExitPositionChange, "/mag/position/GetSelect?productionexclude=0", "", { readOnly: false, filters: [], sm: 6, valueKey: "Denomination" })}</Grid>
			</Grid></div>
			customActions = [];
			customActions.push({
				group: 'actions',
				name: 'Indietro',
				icon: <ArrowLeft />,
				action: this.cancelProductionBOPSExit
			});
			customActions.push({
				group: 'actions',
				name: 'Uscita prod.',
				icon: <Check />,
				action: this.exitBOPSFromProduction
			});
		}

		return (<Grid container>
			<XGrid
				containerID="ProductionBOPSExit"
				innerRef={gridRef}
				usePrimaryColor={false}
				useSecondaryColor={false}
				//selectedActions={selectedActions}
				dataRoute={grdDataRoute}
				dataRouteColumns={grdDataRouteColumns}
				dataRouteAvailableColumns={grdDataRouteAvailCols}
				dataRouteUpdateColumns={grdDataRouteUpdateCols}
				customContent={customContent}
				actions={customActions}
			/>
		</Grid>
		);
	}

	render = () => {
		const { classes } = this.props;		
		var { isLoading, activeTab } = this.state;

		if (isLoading) {
			return (<XOXLoader />);
		}

		if (this.props.isProductionOperator)
			return (<ProductionOperator user={this.props.username} onLoaded={this.removeLoader} onOperationStart={this.clearLogoutTimeout} onOperationCompleted={this.logoutAfterOperation} />);

		var tabs = [
			{
				tabName: "Inizio",
				tabContent: (
					<TabContainer id="OrderRowsManagement" padding={0} style={{ height: "calc(100vh - 145px)" }}>
						{this.renderTabOrderRowBops()}
						{/* <ProductionStart fields={this.state.fields} /> */}
					</TabContainer>
				)
			},
			{
				tabName: "Gestione",
				tabContent: (
					<TabContainer id="ProductionBOPSManagement" padding={0} style={{ height: "calc(100vh - 145px)" }}>
						{this.renderTabManageProductionBOPS()}
					</TabContainer>
				)
			},
			// {
			// 	tabName: "Uscita produzione",
			// 	tabContent: (
			// 		<TabContainer id="ProductionBOPSExit" padding={0} style={{ height: "calc(100vh - 200.5px)" }}>
			// 			{this.renderTabProductionBOPSExit()}
			// 		</TabContainer>
			// 	)
			// },
			{
				tabName: "Tempi",
				tabContent: (
					<TabContainer id="FactorySituation" padding={0} style={{ height: "calc(100vh - 145px)" }}>
						{this.renderTabFactorySituation()}
					</TabContainer>
				)
			}
		]

		return (
			<div className={classes.root}>
				<Tabs
					headerColor="primary"
					plainTabs={true}
					tabs={tabs}
					initialTab={activeTab}
					onChange={this.activateView}
				/>
				<XMessageBox innerRef={this.MessageBox} />
			</div>
		);
	}
}

const enhance = compose(
	connect(
		state => state.auth,
		dispatch => bindActionCreators(actionCreators, dispatch)
	),
	connect(
		state => state.global,
		dispatch => bindActionCreators(globalCreators, dispatch)
	),
	withStyles(styles)
);

export default enhance(Production);