import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import XOXLoader from 'components/XOXLoader';
import { withProps } from "recompose";
import { withWidth } from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import markerIcon from "assets/img/xOnly.svg";
//import GoogleMap from "google-map-react";
//import {
//  withScriptjs,
//  withGoogleMap,
//  GoogleMap,
//  Marker
//} from "react-google-maps";
//const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
//const styles = {
//	map: {
//		height: "40vh"
//	},
//	marker: {
//		"& .x svg": {
//            
//            width: "50px",
//            height: "5px",
//            //display: props => (props.onlyO ? "none" : "block"),
//            display: "block",
//            transition: "transform 0.2s linear",
//        },
//        "& .x svg:nth-child(1)": {
//            transform: "translate(-1px,20px) rotate(45deg)",
//        },
//        "& .x svg:nth-child(2)": {
//            transform: "translate(-1px,15px) rotate(-45deg)",
//        },
//	}
//};
//
//  
//const K_WIDTH = 50;
//const K_HEIGHT = 50;
//
//const markerStyle = {
//  // initially any map object has left top corner at lat lng coordinates
//  // it's on you to set object origin to 0,0 coordinates
//  position: 'absolute',
//  width: K_WIDTH,
//  height: K_HEIGHT,
//  left: -K_WIDTH / 2,
//  top: -K_HEIGHT / 2,
//};
//
//const Marker = () => <div style={markerStyle}>
//        <svg style={{
//					width: "50px",
//					height: "5px",
//					//display: props => (props.onlyO ? "none" : "block"),
//					display: "block",
//					transition: "transform 0.2s linear",	
//					transform: "translate(-1px,20px) rotate(45deg) scale(0.5)"
//				}}>
//            <polygon points="0,2.5 2.5,0 47.5,0 50,2.5 47.5,5 2.5,5 0,2.5" />
//        </svg>
//        <svg style={{
//					width: "50px",
//					height: "5px",
//					//display: props => (props.onlyO ? "none" : "block"),
//					display: "block",
//					transition: "transform 0.2s linear",	
//					transform: "translate(-1px,15px) rotate(-45deg) scale(0.5)"
//				}}>
//					<polygon points="0,2.5 2.5,0 45,0 42.5,2.5 45,5 2.5,5 0,2.5" />
//					<circle cx="47.5" cy="2.5" r="2.5" />
//		</svg>
//    </div>;
//
//class XGMap extends Component {
//	render() {
//		return (
//		<GoogleMap
//			bootstrapURLKeys={{ key: "" }}
//			defaultCenter={[this.props.coords.lat, this.props.coords.lng]}
//			defaultZoom={6}
//			yesIWantToUseGoogleMapApiInternals
//			overlayViewDivStyle={{transform: "translateX(-50%)"}}
//			//onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
//		>
//			<Marker className={this.props.classes.marker}
//				lat={this.props.coords.lat}
//				lng={this.props.coords.lat}
//				text="Test"
//			/>
//		</GoogleMap>
//		);
//	}
//}

//class MarkerIcon extends Component {
//	render () {
//		var { classes } = this.props.classes;
//	
//	
//		return (
//			<div className={classes.marker}>
//				<svg className='bar'>
//					<polygon points="0,2.5 2.5,0 47.5,0 50,2.5 47.5,5 2.5,5 0,2.5" />
//				</svg>
//				<svg className='bar'>
//					<polygon points="0,2.5 2.5,0 45,0 42.5,2.5 45,5 2.5,5 0,2.5" />
//					<circle cx="47.5" cy="2.5" r="2.5" />
//				</svg>
//			</div>
//		);
//	}
//}
//
//const markerIcon = withStyles(styles)(MarkerIcon);

//const XGMap = compose(
//  withStyles(styles),
//  withProps({
//    googleMapURL:
//      "https://maps.googleapis.com/maps/api/js?key=&v=3.exp&libraries=geometry,drawing,places",
//    loadingElement: <XOXLoader transparent />,
//    containerElement: <div style={{ height: '40vh', width: "100%" }} />,
//    mapElement: <div style={{ height: '100%' }} />
//  }),
//  withScriptjs,
//  withGoogleMap
//)(props => (
//  <GoogleMap defaultZoom={4} defaultCenter={{ lat: props.coords.lat, lng: props.coords.lng }}>
//	  <Marker position={{ lat: props.coords.lat, lng: props.coords.lat }} />
//		{/*<svg style={{
//			width: "50px",
//			height: "5px",
//			//display: props => (props.onlyO ? "none" : "block"),
//			display: "block",
//			transition: "transform 0.2s linear",	
//			transform: "translate(-1px,20px) rotate(45deg) scale(0.5)"
//		}}>
//			<polygon points="0,2.5 2.5,0 47.5,0 50,2.5 47.5,5 2.5,5 0,2.5" />
//		</svg>
//		<svg style={{
//			width: "50px",
//			height: "5px",
//			//display: props => (props.onlyO ? "none" : "block"),
//			display: "block",
//			transition: "transform 0.2s linear",	
//			transform: "translate(-1px,15px) rotate(-45deg) scale(0.5)"
//		}}>
//			<polygon points="0,2.5 2.5,0 45,0 42.5,2.5 45,5 2.5,5 0,2.5" />
//			<circle cx="47.5" cy="2.5" r="2.5" />
//		</svg>
//		</Marker>*/}
//  </GoogleMap>
//));

import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

const mapStyle = 
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dceafa'
      }
    ]
  }

export class XGMap extends Component {
  constructor(props) {
    super(props);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
	  markerURL: markerIcon,
	  isLoading: true
    };
  }
  componentDidMount = async() => {
	 let markerURL = await fetch('/Base/GetParam?param=GoogleMapsMarkerURL').then(res => res.json());
	 
	 if (markerURL)
		 this.setState({ markerURL });
	 
	 this.setState({ isLoading: false });
  }
  onMarkerClick(props, marker, e) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }
  render() {
    if (!this.props.google || this.state.isLoading) {
      return <XOXLoader transparent />;
    }

    return (
      <div
        style={{
          position: "relative",
          height: "40vh"
        }}
      >
        <Map style={mapStyle} google={this.props.google} zoom={10} initialCenter={this.props.coords}>
          <Marker position={{lat: this.props.coords.lat, lng: this.props.coords.lng}}
            onClick={this.onMarkerClick}
            icon={{
			  url: this.state.markerURL,
              draggable: false,
              anchor: new this.props.google.maps.Point(16, 16),
              scaledSize: new this.props.google.maps.Size(32, 32)
            }}
            name={this.props.location||""}
          />
          <InfoWindow
            marker={this.state.activeMarker}
            visible={(!!this.props.location)}
          >
            <div>
              <p>{this.props.location}</p>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyCIEOa1VD8JN2NhIQI6e-HC1rr9yxFkSVE",
  v: "3.30"
})(XGMap);