import {HubConnectionBuilder} from "@microsoft/signalr";
import {HubConnectionState} from "@microsoft/signalr";

const START_CONNECTION_COMPLETE = "START_CONNECTION_COMPLETE";
const STOP_CONNECTION_COMPLETE = "STOP_CONNECTION_COMPLETE";
const ACCEPT_CALL = "ACCEPT_CALL";

const EVENT_ONCLIENTSTART = "ONCLIENTSTART";
const EVENT_ADD_USER = "ADDUSER"
const EVENT_ERASE_USER = "ERASEUSER"
const EVENT_ADD_CALL = "ADDCALL"
const EVENT_ENTER_CALL = "ENTERCALL"
const REDIRECT_DONE = "REDIRECTDONE"
const CLEAR_NOTIFICATION = "CLEARNOTIFICATION"
const CLEAR_BUSY_NOTIFICATION = "CLEARBUSYNOTIFICATION"
const EVENT_UPDATE_USER = "UPDATEUSER";
const EVENT_CLOSE_ROOM = "CLOSEROOM";
const EVENT_CLOSE_ROOM_CLEAR = "CLOSEROOMCLEAR";
const CALL_JOINED = "CALLJOINED";
const CALL_LEFT = "CALLLEFT";
const EVENT_BUSY_CALL = "BUSYCALL";
const EVENT_FUNCTION_CHANGE = "FUNCTIONSTATECHANGE";
const EVENT_FUNCTION_CHANGE_DONE = "FUNCTIONSTATECHANGEDONE";
const USER_JOINED_ROOM = "USERJOINED";
const USER_LEFT_ROOM = "USERLEFT";
const USER_ENABLE_REQUEST = "ENABLEREQUEST";
const CLEAR_USER_ENABLE_REQUEST = "CLEARENABLEREQUEST";
const EVENT_FORCE_PIN = "EVENT_FORCE_PIN";
const EVENT_FORCE_PIN_STOP = "EVENT_FORCE_PIN_STOP";
const EVENT_DIRECT_MESSAGE = "DM";
const EVENT_ROOM_MESSAGE = "RM";
const EVENT_CLOSE_MESSAGE_CHAT = "CMC";
const EVENT_OPEN_MESSAGE_CHAT = "OMC";
const EVENT_SCREEN_SHARE = "ESR";
const EVENT_SCREEN_SHARE_DONE = "ESRD";
const PAYMENT_STATUS_UPDATE = "PSU";

export const FUNCTIONALITY_MIC = 0 
export const FUNCTIONALITY_VIDEO = 1

export const FUNCTIONALITY_DISABLED = 0 
export const FUNCTIONALITY_ENABLED = 1
export const FUNCTIONALITY_MUTED = 2 
export const FUNCTIONALITY_UNMUTED = 3
const FUNCTIONALITY_STATE_COUNT = 4


const initialState = {
    myUserId: undefined,
    connection: undefined,
    onlineUsers: [],
    roomName: undefined,
    roomToken: undefined,
    usersInMyRoom: [],
    redirect: undefined,
    showNotification: undefined,
    showBusyNotification: undefined,
    leaveRoom: undefined,
    functionStateChange: undefined,
    usersWithRequest: [],
    forcePin: undefined,
    directMessages: {},
    roomMessages: {},
    showMessageChats: [],
    activeScreenShare: false,
    paymentStatus: {active: false, error: ""},
};

const bindCallbacks = (connection, dispatch, logoutCallback) => {
    connection.on("OnClientStart", onClientStart(dispatch));
    connection.on("OnIncomingCall", onIncomingCall(dispatch));
    connection.on("OnNewUser", onNewUser(dispatch));
    connection.on("UpdateUser", updateUser(dispatch));
    connection.on("OnDisconnectedUser", onDisconnectedUser(dispatch));
    connection.on("OnEnterCall", onEnterCall(dispatch));
    connection.on("OnClosingRoom", onClosingRoom(dispatch));
    connection.on("OnBusyCall", onBusyCall(dispatch));
    connection.on("OnManageFunction", onManageFunction(dispatch));
    connection.on("OnUserJoinRoom", onUserJoinRoom(dispatch));
    connection.on("OnUserLeftRoom", onUserLeftRoom(dispatch));
    connection.on("OnEnableRequest", onEnableRequest(dispatch));
    connection.on("OnForcePin", onForcePin(dispatch));
    connection.on("OnStopForcePin", onStopForcePin(dispatch));
    connection.on("OnDirectMessage", onDirectMessage(dispatch));
    connection.on("OnRoomMessage", onRoomMessage(dispatch));
    connection.on("LogOut", onLogOut(dispatch, logoutCallback));
}

var onClientStart = (dispatch) => (users) => {
    console.log("client start:"+users);
    dispatch({ type: EVENT_ONCLIENTSTART, users });
}
var updateUser = (dispatch) => (user) => {
    console.log('updateUser:'+user);
    dispatch({ type: EVENT_UPDATE_USER, user });
}
var onNewUser = (dispatch) =>  (user) => {
    console.log('onNewUser:['+user.id+' - '+user.username+']');
    dispatch({ type: EVENT_ADD_USER, user });
}
var onDisconnectedUser = (dispatch) => (userId) => {
    console.log('onDisconnectedUser:'+userId);
    dispatch({ type: EVENT_ERASE_USER, userId });
}
var onIncomingCall = (dispatch) => (room) => {
    console.log('onIncomingCall:'+room);
    dispatch({ type: EVENT_ADD_CALL, room });
}
var onEnterCall = (dispatch) => (roomName, roomToken) => {
    console.log("enter room:"+roomName);
    console.log("with token:"+roomToken);
    const urlParams = `roomName=${roomName}&token=${roomToken}`
    dispatch({ type: EVENT_ENTER_CALL, urlParams, roomName, roomToken });
}
var onClosingRoom = (dispatch) => (reason) => {
    console.log('onClosingRoom:'+reason)
    dispatch({ type: EVENT_CLOSE_ROOM, reason})
}
var onBusyCall = (dispatch) => (user) => {
    dispatch({ type: EVENT_BUSY_CALL, user})
}
var getFunctionState = (functionality, state) => {
    return (functionality*FUNCTIONALITY_STATE_COUNT)+state;
}
var onManageFunction = (dispatch) => (functionality, state) => {
    console.log('onManageFunction - functionality:'+functionality+' - state:'+state)
    const functionState = getFunctionState(functionality, state);
    dispatch({ type: EVENT_FUNCTION_CHANGE, functionState})
}
var onUserJoinRoom = (dispatch) => (userId) => {
    console.log('onUserJoinRoom:'+userId)
    dispatch({ type: USER_JOINED_ROOM, userId})
}
var onUserLeftRoom = (dispatch) => (userId) => {
    console.log('onUserLeftRoom:'+userId)
    dispatch({ type: USER_LEFT_ROOM, userId})
}
var onEnableRequest = (dispatch) => (user) => {
    console.log('onEnableRequest:'+user)
    dispatch({ type: USER_ENABLE_REQUEST, user})
}
var onForcePin = (dispatch) => (user) => {
    console.log('onForcePin:'+user.username)
    dispatch({ type: EVENT_FORCE_PIN, user})
}
var onStopForcePin = (dispatch) => (user) => {
    console.log('onStopForcePin:'+user.username)
    dispatch({ type: EVENT_FORCE_PIN_STOP})
}
var onDirectMessage = (dispatch) => (user, message) => {
    console.log('onDirectMessage:'+user)
    console.log('onDirectMessage:'+message)
    dispatch({ type: EVENT_DIRECT_MESSAGE, sent:false, userId:user.id, message});
    dispatch({ type: EVENT_OPEN_MESSAGE_CHAT, user});
}
var onRoomMessage = (dispatch) => (roomMessage) => {
    console.log('onRoomMessage:'+roomMessage)
}
var onLogOut = (dispatch,logoutCallback) => () => {
    logoutCallback && logoutCallback();
}

var timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

var getCheckedConnection = (getState) => {
    var connection = getState().userSignaler.connection;
    if(connection && connection.state === HubConnectionState.Connected)
        return connection;
    return undefined;
}

var connectAndWait = async () => {
    try
    {
        var connection = new HubConnectionBuilder().withUrl("/videohub").build();
        await connection.start();
        while(!connection || connection.state !== HubConnectionState.Connected) 
            await timeout(100);
        return connection;
    }
    catch(err)
    {
        return undefined;
    }
}

var tryReconnect = (dispatch, getState, logoutCallback) => async () => {
    console.log('connection closed, trying reconect...');
    var result = false;
    while(!result)
    {
        console.log('reconnection attempt...');
        result = await reconnect(dispatch, getState, logoutCallback)();
        if(!result)//retry
        {
            console.log('failed...');
            await timeout(3000);
        }
    }
}
var reconnect =  (dispatch, getState, logoutCallback) => async () => {
    console.log('reconecting.....');
    if(!document.cookie.includes('Username'))
    {
        console.log('logged out.....');
        return true;
    }
    var connection = undefined;
    try{
        connection = await connectAndWait();
    }
    catch(err){
    }
    finally{
        if(connection === undefined)
            return false;
    }
    console.log('reconnected...');
    const userId = getState().userSignaler.myUserId;
    dispatch({ type: START_CONNECTION_COMPLETE, connection, userId });
    bindCallbacks(connection, dispatch, logoutCallback);
    try {
        connection.invoke("StartClient", userId);
        var roomName = getState().userSignaler.roomName;
        if(roomName !== undefined)
        {
            connection.invoke("CallJoined", roomName);
        }
        connection.onclose(tryReconnect(dispatch, getState, logoutCallback));
        return true;
    }
    catch(err){
        return false;
    }
}

export const actionCreators = {
    startConnection: (userId, logoutCallback) => async (dispatch, getState) => {
        var connection = getState().userSignaler.connection;
        if(connection === undefined)
        {
            connection = await connectAndWait();
            connection.onclose(tryReconnect(dispatch, getState, logoutCallback));
            dispatch({ type: START_CONNECTION_COMPLETE, connection, userId });
            bindCallbacks(connection, dispatch, logoutCallback);
            connection.invoke("StartClient", userId);
        }
    },
    setPaymentsStatus: (active, errorDetail) => async (dispatch, getState) => {
        dispatch({ type: PAYMENT_STATUS_UPDATE, active, errorDetail });
    },
    stopConnection: () => async (dispatch, getState) => {
         var connection = getCheckedConnection(getState);
         connection && await connection.stop();
         dispatch({ type: STOP_CONNECTION_COMPLETE });
    },
    clearNotification: () => async (dispatch, getState) => {
        dispatch({type: CLEAR_NOTIFICATION});
    },
    clearBusyNotification: () => async (dispatch, getState) => {
        dispatch({type: CLEAR_BUSY_NOTIFICATION});
    },
    callUsers: (callingList, roomName) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("CallUsers", callingList, roomName);
    },
    acceptCall: (roomName) => async (dispatch, getState) => {
        dispatch({ type: ACCEPT_CALL});
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("AcceptCall", roomName);
    },
    rejectCall: (roomName) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("RejectCall", roomName);
    },
    redirectDone: () => async (dispatch, getState) => {
        dispatch({ type: REDIRECT_DONE});
    },
    callJoined: (roomName) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("CallJoined", roomName);
        dispatch({ type: CALL_JOINED, roomName});
    },
    callLeft: (roomName) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("CallLeft", roomName);
        dispatch({ type: CALL_LEFT});
    },
    notifyBusyUser: (user) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("NotifyBusyUser", user);
    },
    controlUserFunctionality: (userId, functionality, state) => async (dispatch, getState) => {
        console.log('controlUserFunctionality - '+userId+' - '+functionality+' - '+state);
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("ManageUserFunctionalities", userId, functionality, state);
    },
    userFunctionalityUpdated: () => async (dispatch, getState) => {
        dispatch({ type: EVENT_FUNCTION_CHANGE_DONE});
    },
    enableRequest: (roomName) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("NotifyEnableRequest", roomName);
    },
    clearEnableRequest: (userId) => async (dispatch, getState) => {
        dispatch({ type: CLEAR_USER_ENABLE_REQUEST, userId});
    },
    forceQuit: (userId) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("ForceQuit", userId);
    },
    startForcePin: (roomName) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("ForceParticipantsToPin", roomName);
    },
    stopForcePin: (roomName) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        connection && connection.invoke("StopForceParticipantsToPin", roomName);
    },
    setupMeeting: (name, description, date, startTime, endTime, invitedUsers) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        if(connection)
        {
            await connection.invoke("SetUpMeeting", name, description, date, startTime, endTime, invitedUsers);
            return true;
        }
        return false;
    },
    sendDirectMessage: (userId, message) => async (dispatch, getState) => {
        var connection = getCheckedConnection(getState);
        if(connection)
        {
            dispatch({ type: EVENT_DIRECT_MESSAGE, sent:true, userId, message});
            await connection.invoke("SendDirectMessage", userId, message);
            return true;
        }
        return false;            
    },
    openMessageChat: (user) => async (dispatch, getState) => {
        dispatch({ type: EVENT_OPEN_MESSAGE_CHAT, user});
    },
    closeMessageChat: (userId) => async (dispatch, getState) => {
        dispatch({ type: EVENT_CLOSE_MESSAGE_CHAT, userId});
    },
    clearLeaveRoom: () => async (dispatch, getState) => {
        dispatch({ type: EVENT_CLOSE_ROOM_CLEAR});
    },
    leaveCurrentRoom: () => async (dispatch, getState) => {
        dispatch({ type: EVENT_CLOSE_ROOM, reason:""});
    },
    startScreenShare: () => async (dispatch, getState) => {
        dispatch({ type:EVENT_SCREEN_SHARE});
    },
    startScreenShareDone: () => async (dispatch, getState) => {
        dispatch({ type:EVENT_SCREEN_SHARE_DONE});
    },
}

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === START_CONNECTION_COMPLETE) {
        return { ...state, 
                connection: action.connection, 
                myUserId: action.userId };
    }
    if (action.type === STOP_CONNECTION_COMPLETE) {
        return { initialState };
    }
    if(action.type === EVENT_ONCLIENTSTART) {
        return { ...state, onlineUsers: action.users};
    }
    if(action.type === EVENT_ADD_USER) {
        if(state.onlineUsers.findIndex(user => user.id === action.user.id) === -1)//non aggiungo duplicati.
            return { ...state, 
                onlineUsers: [...state.onlineUsers, action.user]}
        return {...state};
    }
    if(action.type === EVENT_ERASE_USER) {
        return { ...state, 
            onlineUsers: state.onlineUsers && state.onlineUsers.filter(user => { return action.userId !== user.id}),
            usersInMyRoom: state.usersInMyRoom && state.usersInMyRoom.filter(userId => { return action.userId !== userId})
        }
    }
    if(action.type === EVENT_ADD_CALL) {
        return { ...state, showNotification: action.room}
    }
    if(action.type === EVENT_BUSY_CALL) {
        return { ...state, showBusyNotification: action.user}
    }
    if(action.type === EVENT_ENTER_CALL) {
        return {...state, 
            redirect: action.urlParams,
            leaveRoom: undefined,
            roomName: action.roomName,
            roomToken: action.roomToken,
        }
    }
    if(action.type === REDIRECT_DONE) {
        return { ...state, redirect : undefined}
    }
    if(action.type === ACCEPT_CALL
        || action.type === CALL_LEFT){
        return { ...state, 
            usersInMyRoom: [],
            forcePin: undefined,
            roomName: undefined,
            roomToken: undefined,
        };
    }
    if(action.type === CALL_JOINED){
        return { ...state, 
            usersInMyRoom: [],
            forcePin: undefined,
        };
    }
    if(action.type === CLEAR_NOTIFICATION){
        return { ...state, 
            showNotification: undefined
        };
    }
    if(action.type === CLEAR_BUSY_NOTIFICATION){
        return { ...state, 
            showBusyNotification: undefined
        };
    }
    if(action.type === EVENT_UPDATE_USER){
        const usersClone = [...state.onlineUsers];
        const userIndex = usersClone.findIndex((user) => user.id === action.user.id);
        if(userIndex >= 0){
            usersClone[userIndex] = action.user;
        }
        return {...state, 
                onlineUsers: usersClone
        };
    }
    if(action.type === EVENT_CLOSE_ROOM){
        return { ...state, 
            leaveRoom: action.reason
        };
    }
    if(action.type === EVENT_CLOSE_ROOM_CLEAR){
        return { ...state, 
            leaveRoom: undefined
        };
    }
    if(action.type === EVENT_FUNCTION_CHANGE){
        return { ...state, 
            functionStateChange: action.functionState
        };
    }
    if(action.type === EVENT_FUNCTION_CHANGE_DONE){
        return { ...state, 
            functionStateChange: undefined
        };
    }
    if(action.type === USER_JOINED_ROOM){
        return { ...state, 
            usersInMyRoom: [...state.usersInMyRoom, action.userId]
        };
    }
    if(action.type === USER_LEFT_ROOM){
        return { ...state, 
            usersInMyRoom: state.usersInMyRoom.filter(userId => { return action.userId !== userId})
        };
    }
    if(action.type === USER_ENABLE_REQUEST){
        return { ...state, 
            usersWithRequest: state.usersWithRequest ? [...state.usersWithRequest, action.user] : [action.user]
        };
    }
    if(action.type === CLEAR_USER_ENABLE_REQUEST){
        return { ...state, 
            usersWithRequest: state.usersWithRequest.filter(user => { return action.userId !== user.id})
        };
    } 
    if(action.type === EVENT_FORCE_PIN){
        return { ...state, 
                forcePin: action.user
        };
    }    
    if(action.type === EVENT_FORCE_PIN_STOP){
        return { ...state, 
                forcePin: undefined
        };
    }    
    if(action.type === EVENT_DIRECT_MESSAGE){
        var directMessages = state.directMessages ? { ...state.directMessages } : {};
        if(directMessages[action.userId]===undefined)
            directMessages[action.userId] = []
        const newMessageUser = [...directMessages[action.userId]];
        newMessageUser.push({sent:action.sent, message:action.message});
        directMessages[action.userId] = newMessageUser;
        return { ...state,
            directMessages: { ...directMessages }
        };
    }
    if(action.type === EVENT_ROOM_MESSAGE){
        var roomMessages = { ...state.roomMessages };
        roomMessages[action.roomName] = action.message;
        return { ...state,
            roomMessages: { ...roomMessages }
        };
    }       
    if(action.type === EVENT_OPEN_MESSAGE_CHAT){
        const newShowMessageChats = state.showMessageChats ? [...state.showMessageChats] : []
        const index = newShowMessageChats.findIndex(user => user.id===action.user.id);
        if(index === -1)
            newShowMessageChats.push(action.user);
        return {...state,
            showMessageChats: newShowMessageChats
        };
    }
    if(action.type === EVENT_CLOSE_MESSAGE_CHAT){
        return {...state,
            showMessageChats: state.showMessageChats.filter(user => user.id!==action.userId)
        };
    }
    if(action.type === EVENT_SCREEN_SHARE
        || action.type === EVENT_SCREEN_SHARE_DONE){
        return {...state,
                activeScreenShare: action.type === EVENT_SCREEN_SHARE
        };
    }
    if(action.type === PAYMENT_STATUS_UPDATE){
        return {...state,
            paymentStatus: {active: action.active, 
                            error: action.errorDetail}};
    }
    
    return state;
}