import React, { Component, Fragment } from 'react';

import XSelect from 'components/XSelect';
import XSwitch from 'components/XSwitch';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { withStyles } from '@material-ui/core';
import XExpansionPanel from 'components/XExpansionPanel';
import XField from './XField';
import moment from 'moment';

const styles = theme => ({

});

class XPrintOptionSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			FIDPrintOption: 1,
			FIDLanguage: 2,
			showAmounts: true,
			hideDiscounts: false,
			showTaxableAmounts: false,
			showVat: false,
			showBarcode: false,
			showBarcodesupplier: false,
			Title: undefined,
			PrintTypology: undefined,
			CatalogPrint: undefined,
			showLine: true,
			showSeries: true,
			showExDescription: true,
			showBrand: true,
			showCommercialCode: false,
			showSupplierCode: false,
			showSupplierCommercialCode: false,
			FIDCausal: props.ShowCausalsChooser ? props.params.DOCPresentationCausalId || 10 : null,
			currentDocID: undefined
		}
		//this.XMessage = React.createRef();
	}

	componentDidMount = () => {
		this.loadData();
	}
	loadData = async () => {
		let { id } = this.props;
		
		if (id > 0) {
		
			let data = await fetch('/DOC/GetDOC/' + id).then(res => res.json());
			let documentDate = moment(new Date(data.DocumentDate)).format("DD-MM-YYYY");
			this.setState(state => {
				state.PrintTypology = data.PrintTypology;
				state.CatalogPrint = data.PrintCatalogLayout;
				state.FIDLanguage = data.PrintFIDLanguage;
				state.showAmounts = data.PrintShowAmounts  === null ? true : data.PrintShowAmounts;
				state.showVat = data.PrintShowVat === null ? true : data.PrintShowVat;
				state.hideDiscounts = data.PrintHideDiscounts=== null ? true : data.PrintHideDiscounts;
				state.Title = `${data.CausalStr} - ${data.Number} - ${documentDate}`;
				state.showBarcode = data.PrintShowBarcode === null ? true : data.PrintShowBarcode;
				state.showSeries = data.PrintShowSeries === null ? true : data.PrintShowSeries;
				state.showExDescription = data.PrintShowExDescription === null ? true : data.PrintShowExDescription;
				state.showBrand = data.PrintShowBrand === null ? true : data.PrintShowBrand;
				state.showCode = data.PrintShowCode === null ? true : data.PrintShowCode;
				state.showLine = data.PrintShowLine === null ? true : data.PrintShowLine;
				state.showBarcodesupplier = data.PrintShowBarcodeSupplier === null ? true : data.PrintShowBarcodeSupplier;
				state.showCommercialCode = data.PrintShowCommercialCode === null ? true : data.PrintShowCommercialCode;
				state.showSupplierCode = data.PrintShowSupplierCode === null ? true : data.PrintShowSupplierCode;
				state.showSupplierCommercialCode = data.PrintShowSupplierCommercialCode === null ? true : data.PrintShowSupplierCommercialCode;
				state.PrintOption = data.PrintOption;
				state.currentDocID = data.ID;
				return state;
			});
		}
	}

	handleChange = (id) => (event, value) => {
		this.setState(state => {
			if (value && value.length > 0) {
				state[id] = value[0].ID;
			}
			else {
				state[id] = undefined;
			}

			return state;
		},() => {
			if (id === "FIDLanguage")
			{
				this.updateTitleLanguage();
			}
		});
		
	}

	updateTitleLanguage = async () => {
		var { currentDocID, FIDLanguage } = this.state;
		
		if (!currentDocID) return;
		
		var data = await fetch(`/DOC/GetDOC/${currentDocID}?FIDLanguage=${FIDLanguage}`).then(res => res.json());
		var documentDate = moment(new Date(data.DocumentDate)).format("DD-MM-YYYY");
		this.setState(state => {
			state.Title = `${data.CausalStr} - ${data.Number} - ${documentDate}`;
			return state;
		});
	}



	handleFieldChange = (id) => (event, value) => {
		this.setState(state => {
			state[id] = value;
			return state;
		});
	}

	validate = () => {
		if (!this.state.FIDPrintOption)
			return false;

		if (!this.state.FIDLanguage)
			return false;

		return true;
	}

	saveData = async () => {
		let { id } = this.props;
		
		if (!id) return;
		
		var {PrintTypology,CatalogPrint,FIDLanguage,showAmounts,showVat,hideDiscounts,Title,
			showBarcode,showSeries,showExDescription,showBrand,showCode,showLine,showBarcodesupplier,
			showCommercialCode,showSupplierCode,showSupplierCommercialCode,FIDPrintOption} = this.state;
		const requestOptions = {
			method: 'POST',
			body: JSON.stringify({ 
				PrintTypology: PrintTypology,
				PrintCatalogLayout: CatalogPrint,
				PrintFIDLanguage: FIDLanguage,
				PrintShowAmounts: showAmounts,
				PrintShowVat: showVat,
				PrintHideDiscounts: hideDiscounts,
				PrintTitle: Title,
				PrintShowBarcode: showBarcode,
				PrintShowSeries: showSeries,
				PrintShowExDescription: showExDescription,
				PrintShowBrand: showBrand,
				PrintShowCode: showCode,
				PrintShowLine: showLine,
				PrintShowBarcodeSupplier: showBarcodesupplier,
				PrintShowCommercialCode: showCommercialCode,
				PrintShowSupplierCode: showSupplierCode,
				PrintShowSupplierCommercialCode: showSupplierCommercialCode,
				PrintOption: FIDPrintOption
			})
		}
		const response = await fetch(`/DOC/SaveDOCPrintDetails/${id}`, requestOptions);
		const apiResponse = await response.json();
	}

	renderPrintTypology = () => {
		var { data } = this.props;
		var { showAmounts, hideDiscounts, FIDLanguage, showVat, PrintTypology, showLine, showSeries } = this.state;
		return (
			<Grid item xs={12} container spacing={2}>
				<Grid item xs={6}>
					<XSelect field={{ Required: 1 }}
						clearable
						label="Tipologia Di Stampa"
						id="PrintTypology"
						urlData="enums/printtypology/get"
						idKey="ID"
						value={PrintTypology}
						valueKey="Denomination"
						abbreviationKey="Abbreviation"
						onChange={this.handleChange}>
					</XSelect>
				</Grid>
				<Grid item xs={6}><XSelect field={{ Required: 2 }} clearable label="Lingua" id="FIDLanguage" urlData="/GC/Languages" value={FIDLanguage} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>
				
				<Grid item xs={2}><XSwitch labelPlacement="right" label="Mostra importi" color="default" id="showAmounts" value={showAmounts} onChange={this.handleFieldChange}></XSwitch></Grid>
				{showAmounts && <Grid item xs={2}>
					<XSwitch labelPlacement="right" label="Ivato" color="default" id="showVat" value={showVat} onChange={this.handleFieldChange}></XSwitch>
				</Grid>
				}
				{showAmounts && <Grid item xs={2}>
					<XSwitch labelPlacement="right" label="Nascondi sconti" color="default" id="hideDiscounts" value={hideDiscounts} onChange={this.handleFieldChange}>
					</XSwitch>
				</Grid>
				}
			</Grid>
		)
	}

	validate = () => {
		//check sui dati
		//show messagebox
		return true;
	}

	renderPrintOptions = () => {
		let { ShowCausalsChooser, causals } = this.props;
		let { FIDPrintOption, FIDLanguage, FIDCausal, showAmounts, hideDiscounts, showTaxableAmounts, Title, showBarcode,
			showBarcodesupplier, PrintTypology, CatalogPrint, showLine, showSeries, showExDescription, showBrand, showCode,
			showCommercialCode, showSupplierCode, showSupplierCommercialCode } = this.state;
		

		// if (!Title) {
		// 	//this.setState({ Title: data.Description + " - " + data.Number + " - " + documentDate });
		// 	Title = data.Description + " - " + data.Number + " - " + documentDate;
		// }
		let causalFilters = [];
		if (causals && causals.length > 0)
			causalFilters = causals.map((cauID) => { return { key: "ID", value: cauID } });

		var customContent = undefined;

		// In theory this should update the interface based on ShowCausalsChooser && causals && causals.length > 0, "IT SHOULD"
		var causalXS = 6;
		if (ShowCausalsChooser && causals && causalXS !== 6) {
			causalXS = 6;
		}
		else if (causalXS !== 12 && !ShowCausalsChooser && !causals) {
			causalXS = 12;
		}

		if (PrintTypology == 1) {
			customContent = (
				<Grid item xs={12} container spacing={2}>
					<Grid item xs={causalXS}>
						<XSelect field={{ Required: 2 }} clearable label="Opzione Stampa" id="FIDPrintOption" urlData="/GC/PrintOptions" value={FIDPrintOption} idKey="ID" valueKey="Description" onChange={this.handleChange} />
					</Grid>
					{ShowCausalsChooser && causals && causals.length > 0 &&
						<Grid item xs={6}>
							<XSelect field={{ Required: 1 }} clearable label="Causale" id="FIDCausal" urlData="/doc/causal" filtersOr={causalFilters} value={FIDCausal} idKey="ID" valueKey="Denomination" onChange={this.handleChange} />
						</Grid>
					}
				</Grid>
			)
		}

		if (PrintTypology == 2) {
			customContent = ( 
				<Grid item xs={12} container spacing={2}>
					{/* Top Line */}
					<Grid item xs={6}><XField id="Title" label={"Titolo"} value={Title} onChange={this.handleFieldChange}></XField></Grid>
					<Grid item xs={6}><XSelect field={{ Required: 2 }}
						clearable
						label="Foto per pagina"
						id="CatalogPrint"
						urlData="enums/catalogprint/get"
						idKey="ID"
						value={CatalogPrint}
						valueKey="Denomination"
						abbreviationKey="Abbreviation"
						onChange={this.handleChange}>
					</XSelect>
					</Grid>

					<Grid item xs={2}>
						<XSwitch labelPlacement="right" label="Matricola" color="default" id="showBarcode" value={showBarcode} onChange={this.handleFieldChange}></XSwitch>
					</Grid>
					<Grid item xs={2}>
						<XSwitch labelPlacement="right" label="Matricola Fornitore" color="default" id="showBarcodesupplier" value={showBarcodesupplier} onChange={this.handleFieldChange}></XSwitch>
					</Grid>
					<Grid item xs={2}>
						<XSwitch labelPlacement="right" label="Descrizione Estesa" color="default" id="showExDescription" value={showExDescription} onChange={this.handleFieldChange}></XSwitch>
					</Grid>

					<Grid item xs={2}>
						<XSwitch labelPlacement="right" label="Brand" color="default" id="showBrand" value={showBrand} onChange={this.handleFieldChange}></XSwitch>
						</Grid>
					<Grid item xs={2}>
						<XSwitch labelPlacement="right" label="Line" color="default" id="showLine" value={showLine} onChange={this.handleFieldChange}></XSwitch>
						</Grid>
					<Grid item xs={2}>
						<XSwitch labelPlacement="right" label="Series" color="default" id="showSeries" value={showSeries} onChange={this.handleFieldChange}></XSwitch>
					</Grid>

					<Grid item xs={2}>
						<XSwitch labelPlacement="right" label="Codice" color="default" id="showCode" value={showCode} onChange={this.handleFieldChange}></XSwitch>
						</Grid>
					<Grid item xs={2}>
						<XSwitch labelPlacement="right" label="Codice Com." color="default" id="showCommercialCode" value={showCommercialCode} onChange={this.handleFieldChange}></XSwitch>
						</Grid>
					<Grid item xs={2}>
						<XSwitch labelPlacement="right" label="Codice For." color="default" id="showSupplierCode" value={showSupplierCode} onChange={this.handleFieldChange}></XSwitch>
					</Grid>
					<Grid item xs={2}>
					<XSwitch labelPlacement="right" label="Codice Com. Fornitore" color="default" id="showSupplierCommercialCode" value={showSupplierCommercialCode} onChange={this.handleFieldChange}></XSwitch>
					</Grid>

				</Grid>
					
			)
		}


		return customContent;
	}

	render() {

		var panels = [
			{
				icon: 'code',
				label: 'Tipologia Di Stampa',
				content: this.renderPrintTypology()
			},
			{
				icon: 'code',
				label: 'Opzioni Di Stampa',
				content: this.renderPrintOptions()
			}
		];

		return (
			<Fragment>
				<XExpansionPanel panels={panels}></XExpansionPanel>
				
			</Fragment>
		)
	}
}

const enhance = compose(
	connect(
		state => state.global,
		dispatch => bindActionCreators(globalCreators, dispatch),
		null,
		{ withRef: true }
	),
	withStyles(styles),
);
export default enhance(XPrintOptionSelector);