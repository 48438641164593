import React, { useEffect } from 'react';
import App from '../../videochat/App';
import { VideoProvider } from '../../videochat/components/VideoProvider';
import AppStateProvider, { useAppState } from '../../videochat/state';
import ErrorDialog from '../../videochat/components/ErrorDialog/ErrorDialog';
import { actionCreators as userSignalCreators, FUNCTIONALITY_VIDEO, FUNCTIONALITY_MIC, 
	FUNCTIONALITY_DISABLED, FUNCTIONALITY_ENABLED, FUNCTIONALITY_MUTED, FUNCTIONALITY_UNMUTED  } from './UserSignaler';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as userAuthCreators } from '../../store/Auth';

const connectionOptions = {
	// Bandwidth Profile, Dominant Speaker, and Network Quality
	// features are only available in Small Group or Group Rooms.
	// Please set "Room Type" to "Group" or "Small Group" in your
	// Twilio Console: https://www.twilio.com/console/video/configure
	bandwidthProfile: {
		video: {
			mode: 'collaboration',
			//mode: 'grid',
			//mode: 'presentation',
			dominantSpeakerPriority: 'standard',
			renderDimensions: {
				high: { height: 1080, width: 1920 },
				standard: { height: 720, width: 1280 },
				low: { height: 480, width: 640 },
			},
		},
	},
	dominantSpeaker: true,
	networkQuality: { local: 1, remote: 1 },

	// Comment this line if you are playing music.
	maxAudioBitrate: 16000,
	video: { height: 720, width: 1280, frameRate: 24 },
	// VP8 simulcast enables the media server in a Small Group or Group Room
	// to adapt your encoded video quality for each RemoteParticipant based on
	// their individual bandwidth constraints. This has no effect if you are
	// using Peer-to-Peer Rooms.
	preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
	region: 'gll',
};


class Videochat extends React.Component {
	constructor(props)
	{
		super(props);
		this.state = {
			error: null
		}
	}
	forcePinChange = (roomName) => (value) => {
		if (value)
			this.props.startForcePin(roomName);
		else
			this.props.stopForcePin(roomName);
	}

	callUser = (roomName) => (callingList) => {
		this.props.callUsers(callingList, roomName);
	}
	render() {
		//const roomName = new URLSearchParams(this.props.location.search).get("roomName")
		//const token = new URLSearchParams(this.props.location.search).get("token")
		
		const roomName = this.props.roomName;
		const token = this.props.roomToken;
		return (<AppStateProvider>
				<VideoProvider options={connectionOptions} onError={(e) => {this.setState({error:e})}}>
					<ErrorDialog dismissError={() => this.setState({error:null})} error={this.state.error} />
					<App token={token}
						roomName={roomName}
						callJoined={this.props.callJoined}
						callLeft={this.props.callLeft}
						leaveRoom={this.props.leaveRoom}
						functionalityChanged={this.props.functionStateChange}
						resetFunctionalityChanged={this.props.userFunctionalityUpdated}
						enableRequest={this.props.enableRequest}
						forcePin={this.props.forcePin}
						iAmSuperUser = {this.props.isLicensee}
						forcePinChange={this.forcePinChange(roomName)}
						users={this.props.onlineUsers}
						callUser={this.callUser(roomName)}
						notifyBusyUser={this.props.notifyBusyUser}
						roomParticipants={this.props.usersInMyRoom}
						handleDisableMic={(user) => this.props.controlUserFunctionality(user.id, FUNCTIONALITY_MIC, FUNCTIONALITY_DISABLED)}
						handleEnableMic={(user) => this.props.controlUserFunctionality(user.id, FUNCTIONALITY_MIC, FUNCTIONALITY_ENABLED)}
						handleMuteMic={(user) => this.props.controlUserFunctionality(user.id, FUNCTIONALITY_MIC, FUNCTIONALITY_MUTED)}
						handleUnmuteMic={(user) => this.props.controlUserFunctionality(user.id, FUNCTIONALITY_MIC, FUNCTIONALITY_UNMUTED)}
						handleEnableVideo={(user) => this.props.controlUserFunctionality(user.id, FUNCTIONALITY_VIDEO, FUNCTIONALITY_ENABLED)}
						handleDisableVideo={(user) => this.props.controlUserFunctionality(user.id, FUNCTIONALITY_VIDEO, FUNCTIONALITY_DISABLED)}
						handleMuteVideo={(user) => this.props.controlUserFunctionality(user.id, FUNCTIONALITY_VIDEO, FUNCTIONALITY_MUTED)}
						handleUnmuteVideo={(user) => this.props.controlUserFunctionality(user.id, FUNCTIONALITY_VIDEO, FUNCTIONALITY_UNMUTED)}
						usersWithRequest={this.props.usersWithRequest}
						handleForceQuit={(user) => this.props.forceQuit(user.id)}
						openMessageChat={this.props.openMessageChat}
						collapsed = {this.props.collapsed}
						activeScreenShare = {this.props.activeScreenShare}
						startScreenShareDone = {this.props.startScreenShareDone}
					/>
				</VideoProvider>
			</AppStateProvider>)
	};
}


const enhance = compose(
	connect(
		state => state.userSignaler,
		dispatch => bindActionCreators(userSignalCreators, dispatch)
	),
	connect(
		state => state.auth,
		dispatch => bindActionCreators(userAuthCreators, dispatch)
	)
);
export default enhance(Videochat);