import React, { Fragment } from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import CustomAppBar from '../CustomAppBar/CutomAppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Icon from '@material-ui/core/Icon';
import XNavigation from "../XNavigation";
import { HexagonOutline, Plus, ContentSave, Delete, ExitToApp, ContentCopy } from 'mdi-material-ui';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    tab: {
        width: "100%",
    },
    toolbar: {
        minHeight: 85,
    }
});

class CustomTabs extends React.Component {
    state = {
        value: 0
    };
    componentDidMount() {
        if (this.props.initialTab) {
            this.setState({ value: this.props.initialTab },() => {
                this.props.onTabChange && this.props.onTabChange(this.state.value);
            });
        }
    }
    componentDidUpdate(props) {
        if (this.props.initialTab && this.props.initialTab !== props.initialTab) {
            this.setState({ value: this.props.initialTab }, () => {
                this.props.onTabChange && this.props.onTabChange(this.state.value);
            });
        }
    }

    handleChange = (event, value) => {
    var { onTabChange } = this.props;
        var isValid = true;
        if (this.props.validateTab) {
            isValid = this.props.validateTab(this.state.value, value, () => {
                this.setState({ value });
        onTabChange && onTabChange(value);
            });
        }
        if (isValid) {
            this.setState({ value }, this.props.onChange && this.props.onChange(value));
      onTabChange && onTabChange(value);
        }
    };
    getCurrentTab = () => {
        return this.state.value;
    }
    goToTab = (tab) => {
        var { tabs } = this.props;
        if (tab >= tabs.length) {
            return false;
        }
        this.setState({ value: tab });
        return true;
    }
    render() {
        const {
            classes,
            tabs,
            headerColor,
            isSecondary,
        } = this.props;
        
        var xNavActions=[];
        if(this.props.onNewClick) xNavActions.push({ name: getTranslation(5,"GC_NEW", this.props.translations, "Nuovo"), icon: <Plus />, action: this.props.onNewClick, selected: false  });
        if (this.props.onCopyClick) xNavActions.push({ name: getTranslation(3,"BOP_DUPLICATE", this.props.translations, "Duplica distinta"), icon: <ContentCopy />, action: this.props.onCopyClick, selected: false });
		if (this.props.onCopyAltClick) xNavActions.push({ name: getTranslation(3,"BOP_DUPLICATE_VARIANT", this.props.translations, "Duplica variante"), icon: <ContentCopy />, action: this.props.onCopyAltClick, selected: false });
        if(this.props.onSaveClick) xNavActions.push({ name: getTranslation(5,"GC_SAVE", this.props.translations, "Salva"), icon: <ContentSave />, action: this.props.onSaveClick, selected: false  });
        if(this.props.onDeleteClick) xNavActions.push({ name: getTranslation(5,"GC_DELETE", this.props.translations,"Elimina"), icon: <Delete />, action: this.props.onDeleteClick, selected: false  });
        if(this.props.onExitClick) xNavActions.push({ name: getTranslation(5,"GC_EXIT", this.props.translations,"Esci"), icon: <ExitToApp />, action: this.props.onExitClick, selected: false  });
        xNavActions.reverse();

        if(tabs[this.state.value].actions){
            for(var i in tabs[this.state.value].actions){
                var action = tabs[this.state.value].actions[i];
                if(action && action.group && (!action.condition  || action.condition())) {
                    switch(action.group){
                        case "actions":
                                xNavActions.push(action);
                            break;
                        // case "filters":
                        //         xNavFilters.push(action);
                        //     break;
                    }
                }
            }
        }
        var actions =[];
        if(tabs[this.state.value].actions) {
            actions = tabs[this.state.value].actions.filter(item => item && !item.group).map(item => {
                if (item && item.component) {
                    if (item.condition()) {
                        return item.component;
                    }
                    return false;
                }
                else {
                    return item;
                }
            }).filter(item => item !== false);
        }



        var xNavActionButton = <span><XNavigation isHidden={true} menuLabel={getTranslation(5,"GC_ACTIONS", this.props.translations,"Azioni")} actions={xNavActions} direction={"left"} forceDirection={true} maxItemsForce={1} icon={<HexagonOutline />} /></span>;


        var uiAppBar = <CustomAppBar headerColor={headerColor} position="sticky" isSecondary={isSecondary}>
            <Toolbar className={classes.toolbar}>
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    className={classes.tab}
                >
                    {tabs.map((prop, key) => {
                        return (
                            <Tab
                                key={key}
                                label={prop.tabName}
                            />
                        );
                    })}
                </Tabs>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                    {actions}
                    {xNavActionButton}
                    {/* {this.props.onNewClick && (<IconButton color="inherit" onClick={this.props.onNewClick}>
                        <Icon>add</Icon>
                    </IconButton>)}
                    {this.props.onSaveClick && (<IconButton color="inherit" onClick={this.props.onSaveClick}>
                        <Icon>save</Icon>
                    </IconButton>)}
                    {this.props.onDeleteClick && (<IconButton color="inherit" onClick={this.props.onDeleteClick}>
                        <Icon>delete</Icon>
                    </IconButton>)}
                    {this.props.onExitClick && (<IconButton color="inherit" onClick={this.props.onExitClick}>
                        <Icon>exit_to_app</Icon>
                    </IconButton>)} */}
                </div>
            </Toolbar>
        </CustomAppBar>;
        if (isSecondary) {
            uiAppBar = false;
        }
        return (
            <Fragment>
                {uiAppBar}
                {tabs.map((prop, key) => {
                    if (key === this.state.value) {
                        return <div key={key}>{prop.tabContent}</div>;
                    }
                    return null;
                })}
            </Fragment>
        );
    }
}

CustomTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    headerColor: PropTypes.oneOf([
        "warning",
        "success",
        "danger",
        "info",
        "primary",
        "rose"
    ]),
    title: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            tabName: PropTypes.string.isRequired,
            tabIcon: PropTypes.func,
            tabContent: PropTypes.node.isRequired
        })
    ),
    rtlActive: PropTypes.bool,
    plainTabs: PropTypes.bool
};

CustomTabs.defaultProps = {
    topMargin: "72.5px",
}

const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(CustomTabs);
