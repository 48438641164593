import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import XGrid from "../components/XGrid"
import XSwitch from "../components/XSwitch"
import XNavigation from "../components/XNavigation"
import XMessageBox from 'components/XMessageBox';
import "../components/XReactDataGrid.css";
import XPrintOptionSelector from "../components/XPrintOptionSelector"
import { Tooltip, IconButton,Grid } from '@material-ui/core';
import { AccountBadgeOutline, RedoVariant, ArrowLeft, Printer, RayEndArrow, RayStartArrow, TextBox } from 'mdi-material-ui';

import { Typography, Drawer } from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';
import DocumentExpandRow from '../components/DocumentExpandRow';
import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators } from '../store/Auth';
import XPDFViewer from 'components/XPDFViewer';
import XSelect from '../components/XSelect';
import { MaterialIcon } from 'utils';


// class DocumentExpandRow extends Component {
//     constructor(props){
//         super(props);
//         this.state = {
//             view : "previous",
//             showAmounts: true,
//         }
//     }
//     static contextTypes = {
//         router: PropTypes.object
//     }

//     handleGridDoubleClick = (event, data) => {
//         var { view } = this.state;
//         this.context.router.history.push(`/doc/edit/${data.ID}?${view}=true`);
//     }

//     showDocumentRows = () => {
//         this.setState({view: "documentRows"})
//     }

//     showPrevious = () => {
//         this.setState({view: "previous"});
//     }
//     showNext = () => {
//         this.setState({view: "next"});
//     }
//     render () {
//         var { width,data } = this.props;
//         var { view } = this.state;
//         var label = undefined;
//         var actions = [];
//         actions.push({ name: "Righe Documento", icon: <TextBox />, action: this.showDocumentRows, selected: view == "documentRows" })        
//         actions.push({ name: "Precedenti", icon: <RayEndArrow />, action: this.showPrevious, selected: view === "previous" });
//         actions.push({ name: "Successivi", icon: <RayStartArrow />, action: this.showNext, selected: view === "next" });
//         var direction = "right";
//         if (["xs"].includes(width)) {
//             direction = "down";
//         }
//         var label = <XNavigation actions={actions} direction={direction} showLabel={true} />;

//         var gridName = "tblDOCProcessed";
//         var dataRoute = `/DOC/GetProcessedDocs/${data.ID}?grid=${gridName}`;
//         var key = `${gridName}_${view}`;
//         if(view ==="next") {
//              dataRoute = `/DOC/GetProcessedDocsExt/${data.ID}?grid=${gridName}`;
//         }

//         if(view === "documentRows") {
//             gridName = "grdDOCRows";
//             dataRoute = `/DOC/GetDOCRows/${data.ID}?grid=${gridName}&FIDCycle=2`
//         }
        
//         return <XGrid
//             key={key}
//             label={label}
//             containerID={`gridExpandContainer_${data.ID}`}
//             dataRoute={dataRoute}
//             dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
//             dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
//             dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
//             onDoubleClick={this.handleGridDoubleClick}
//             onEditButton={this.handleGridDoubleClick}
//             showColumnsSelector={true}
//             showFilterActivator={true}
//         />;
//     }
// }
class Documents extends Component {
    constructor(props) {
        super(props);
        this.grdDocuments = React.createRef();
		this.embedPDFRef = React.createRef(); 
        this.MessageBox = React.createRef();
        this.PrintOptionSelector = React.createRef();
		this.state = {
            printDoc: undefined,
            showAmounts: true,
			hideDiscounts: false,
            Data: {},
            documents: [],
            FIDPrintOption: undefined,
            FIDLanguage: undefined,
            print: false,
            showPrint: false
		}
    }
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidMount = () => {
        this.loadOrigins();
    }

    loadOrigins = async () => {
        var origins = await fetch(`/doc/origin/?onlyUsed=true`).then(res => res.json());
        this.setState({origins});
    }
    
    rowExpandFormat = (data) => {
        return <DocumentExpandRow data={data} view="documentRows" />;
    }

    handleGridDoubleClick = (event, data) => {
        //var checkArray = [this.props.params.DOCCartCausalId, this.props.params.DOCPresetationCausalId];
        //checkArray = checkArray.concat(this.props.params.DOCCatalogCausals);
        //if (checkArray.includes(data.FIDCausal)) {
        if(data.FIDCausal === 16 || data.FIDCausal === 38 || data.FIDCausal === 43){// cassa
            this.context.router.history.push('/documents/issue/cash/' + data.ID);
        }
        else if(data.FIDDocumentType === 4) {
            if(data.FIDCycle === 3){
                this.context.router.history.push('/documents/receive/repair/' + data.ID);
            }
            else {
                this.context.router.history.push('/documents/issue/repair/' + data.ID);
            }
        }
        else if(data.HasFile && (this.props.userID != -1990 && (this.props.FIDProfile !== (this.props.params.UserTypologyId||7)))) {
            this.doShowPresentation(data);
        }
        else {
            this.context.router.history.push('/doc/edit/' + data.ID);
        }
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/documents');
    }
    handleShowPresentation = (event) => {
        var selectedRows = this.grdDocuments.current.getSelectedData();
        var data = this.grdDocuments.current.getRowData(selectedRows[0]);
        this.doShowPresentation(data);
    }
    doShowPresentation = (data) => {
        this.props.setCatalogCausal(data.FIDCausal);
        this.props.setCatalogIdentity(data.FIDIdentity);
        if (data.FIDCausal === this.props.params.DOCPresentationCausalId) {
            this.context.router.history.push('/presentation/' + data.ID);
        }
        else {
            this.context.router.history.push('/catalogue/' + data.ID);
        }
    }
    handleReturnDocuments = (event) => {
        var selectedRows = this.grdDocuments.current.getSelectedData();
        fetch('/DOC/ReturnDocumentFromDocument/', {
            method: 'POST',
            body: JSON.stringify(selectedRows)
        }).then(res => res.json()).then(data => {
            if (!data) {
                //nessun reso generato
            }
            this.grdDocuments.current.refresh();
        });
    }
	cancelPrint = () => {
		this.setState({
			printDoc: undefined, showPrint: false
		});
	}
	printDocument = () => {
		var printDoc = this.grdDocuments.current.getSelectedData()[0];
		this.grdDocuments.current.clearSelection();
		if (printDoc) {
			this.setState({
				printDoc
			});
		}
    }

    cancelConfirmPrint = () => {
        this.setState({
            showPrint: false,
        });
    }

    
    confirmPrint = async () => {
        let countPrintErrors = 0;
        var { showPrint, data } = this.state;
        var { FIDPrintOption, FIDLanguage, CatalogPrint, Title, PrintTypology, showBarcode,
            showLine, showSeries, showExDescription, showBrand, showCode, showBarcodesupplier,
			showCommercialCode, showSupplierCode, showSupplierCommercialCode, showAmounts, showVat, hideDiscounts
        } = this.PrintOptionSelector.current && this.PrintOptionSelector.current.state;


        if (PrintTypology === 2 && CatalogPrint == undefined) {
            this.MessageBox.current.getWrappedInstance().showMessageOk("Stampa",
                <Typography variant="subtitle1" gutterBottom>Seleziona il layout di stampa nella sezione Opzioni di stampa</Typography>
            );
            countPrintErrors += 1;
            return;
        };

        if(PrintTypology > 2)
        {
            this.MessageBox.current.getWrappedInstance().showMessageOk("Stampa",
            <Typography variant="subtitle1" gutterBottom>La tipologia di stampa selezionata non e ancora disponibile.</Typography>);
            countPrintErrors += 1;
            return;
        }
        if(!this.PrintOptionSelector.current.validate()) {
            return;
        }
        
        if (FIDPrintOption != undefined && FIDLanguage != undefined && PrintTypology != undefined && countPrintErrors == 0) {
            await this.PrintOptionSelector.current.saveData()
            this.setState({
                showPrint: true,
                FIDPrintOption: FIDPrintOption,
                FIDLanguage: FIDLanguage,
                CatalogPrint: CatalogPrint,
                Title: Title,
                showBarcode: showBarcode,
                showSeries: showSeries,
                showExDescription: showExDescription,
                showBrand: showBrand,
                showCode: showCode,
                showLine: showLine,
                showBarcodesupplier: showBarcodesupplier,
                showCommercialCode: showCommercialCode,
                showSupplierCode: showSupplierCode,
                showSupplierCommercialCode: showSupplierCommercialCode,
                PrintOption: FIDPrintOption,
                PrintTypology: PrintTypology
            });

        }
        else {
            if (showPrint) {
                this.setState({ showPrint: false });
            }
        }
    }

    onUpdateShowAmounts = (id) => (event,value) => {
        this.setState({showAmounts: value});
    }
	onUpdateHideDiscounts = (id) => (event, value) => {
		this.setState({ hideDiscounts: value});
	}
    printOption = (id, FIDPrintOption) => (event, value) => {
        this.setState(state => {
          if(value.length > 0){
            state.FIDPrintOption = value[0].ID;
          }
          else {
            state.FIDPrintOption = undefined;
          }
          return state;
        });
      }

    languageOption = (id, FIDLanguage) => (event, value) => {
    this.setState(state => {
        if(value.length > 0){
        state.FIDLanguage = value[0].ID;
        }
        else {
        state.FIDLanguage = undefined;
        }
        return state;
    });
    }
    setOrigin = (FIDDOCOrigin) => () => {
        this.setState({FIDDOCOrigin});
    }
    editDocs = () => {
        this.setState({showEditDocs:true});
    }
    cancelEditDocs = () => {
        this.setState({showEditDocs: false, FIDDOCWorkStatus: undefined});
    }
  
    onDOCWorkStatusChange = (id) => (event,value) => {
        if(value.length > 0){
            this.setState({FIDDOCWorkStatus: value[0].ID})
        }
        else {
            this.setState({FIDDOCWorkStatus: undefined})
        }
    }
    confirmEditDocs = () => {
        var rows = this.grdDocuments.current.getSelectedData();

        var data = {
            FIDDOCWorkStatus: this.state.FIDDOCWorkStatus
        };

        var url = `/DOC/UpdateDOCData/?${rows.map(r => `FIDDocuments=${r}`).join("&")}`;
        fetch(url,{
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => {
            this.setState({FIDDOCWorkStatus: undefined, showEditDocs: false},() => {
                this.grdDocuments.current.clearSelection();
                this.grdDocuments.current.refresh();
            })
        })
    }
    render() {
        var { FIDDocumentType, FIDDocumentSubtype, width } = this.props;
	    var { printDoc, showAmounts, hideDiscounts, FIDPrintOption, FIDLanguage, showPrint, FIDDOCOrigin, showEditDocs } = this.state;
        var selectedActions = [];

        var handleGridNew = undefined;//this.handleGridNew;
        var handleGridEdit = this.handleGridDoubleClick;
        var gridDOubleClick = this.handleGridDoubleClick;
        var deleteRoute = '/DOC/DeleteDocument/';
        if (!this.props.menus.includes("docedit")) {
            //handleGridEdit = undefined;
        }
        if (!this.props.menus.includes("docnew")) {
            handleGridNew = undefined;
        }
        if (!this.props.menus.includes("docdelete")) {
            deleteRoute = undefined;
        }

        var url = "/DOC/GetDocuments?grid=tblDocuments";
        if(FIDDocumentType){
            url = `${url}&FIDDocumentType=${FIDDocumentType}`;
        }
        if(FIDDocumentSubtype){
            url = `${url}&FIDDocumentSubtype=${FIDDocumentSubtype}`;
        }

		var customContent = undefined;
        var actions = [];
        var label = undefined;
		if (printDoc) {
            customContent = <>
                <XPrintOptionSelector innerRef={this.PrintOptionSelector} id={printDoc} />
                <XMessageBox ref={this.MessageBox} />
            </>;
            actions = [
				{
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.cancelPrint
				},
                {
					group: 'actions',
					name: 'Conferma Stampa',
					icon: <Printer />,
					action: this.confirmPrint
				}
            ];
		}
		else {
			actions.push(
				{
					group: 'actions',
					name: 'Stampa',
					icon: <Printer />,
					action: this.printDocument,
					condition: r => r.length === 1 && this.grdDocuments.current.getRowData(r[0]).FIDStatus === 3
				}
            );
            actions.push(
                {
                    group: 'actions',
                    name: 'Modifica dati',
                    icon: <MaterialIcon icon="Pen" />,
                    action: this.editDocs,
                    condition: r => {
                        let items = r.map((id) => this.grdDocuments.current.getRowData(id).FIDCausal).filter(item => ![4,34].includes(item));
                        return r.length > 0 && items.length === 0;
                    }
                }
            )
			if(this.props.FIDProfile !== this.props.params.CustomerTypologyId){
				actions.push({
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.handleGridNew
				});
			}
        }
        
        if(showPrint){
            
            var {showPrint, FIDPrintOption, FIDLanguage} = this.state;
            var { showAmounts, hideDiscounts } = this.props;
            var key = `ID=${printDoc}&showAmounts=${showAmounts}`;
            var printUrl = `/DOC/PrintDocument/?ID=${printDoc}&showAmounts=${showAmounts}&hideDiscounts=${hideDiscounts}&languageID=${FIDLanguage}&printOptionID=${FIDPrintOption}`;
            //console.log(printDoc, showAmounts, FIDLanguage, FIDPrintOption)
            var actions = [];
            actions = 
            [
                {
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.cancelConfirmPrint
                },
            ];
            var customContent = undefined;
            customContent = 
            <Fragment>
                { showPrint ? <XPDFViewer key={key} url={printUrl} width="100%" height={document.body.offsetHeight - 151} /> : undefined };
            </Fragment>  
        }
        else if(showEditDocs){
            handleGridEdit = undefined;
            deleteRoute = undefined;
            actions = 
            [
                {
                    group: 'actions',
                    name: 'Conferma',
                    icon: <MaterialIcon icon="Check" />,
                    action: this.confirmEditDocs
                },
                {
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.cancelEditDocs
                },
            ];
            let urlStato = `/doc/workstatus/`;
            if(FIDDocumentType === this.props.params.DOCPreliminaryTypeId) {//ordine
                urlStato += `?order=true`
            }
            else if(FIDDocumentType === 4) {//riparazione
                urlStato += `?repair=true`
            }
            customContent = <Grid container spacing={2} style={{width: '100%', margin: 0}}>
                <Grid item xs={12} md={3}>
                <XSelect label="Stato lavorazione" id="FIDDOCWorkStatus" urlData={urlStato} value={this.state.FIDDOCWorkStatus} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onDOCWorkStatusChange} />
                </Grid>
            </Grid>;
        }

        if(FIDDocumentType && FIDDocumentType === this.props.params.DOCPreliminaryTypeId) {
            //XNavigation
            var direction = "right";
            if (["xs"].includes(width)) {
                direction = "down";
            }
            // if(!FIDDOCOrigin){
            //     FIDDOCOrigin = this.state.origins && this.state.origins[0].ID;
            // }

            let navActions = (this.state.origins && this.state.origins.map((item) => ({name: item.Denomination, icon: <MaterialIcon icon={`Alpha${item.Abbreviation.toUpperCase()[0]}circleOutline`} />, action: this.setOrigin(item.ID), selected: FIDDOCOrigin === item.ID }))) || [];
            navActions.push({
                name: "Tutto",
                icon: <MaterialIcon icon={`AlphaTcircleOutline`} />,
                action: this.setOrigin(undefined),
                selected: !FIDDOCOrigin
            });

            //actions.push({ name: "Campi custom", icon: <CardBulletedOutline />, action: this.showCustomFields, selected: productInfoView === "customfields" });

            label = <span><XNavigation actions={navActions} direction={direction} showLabel={true} /></span>;

            if(FIDDOCOrigin){
                url += `&FIDDOCOrigin=${FIDDOCOrigin}`
            }
        }


        return (
            <XGrid
                key={url}
                showLoader
                label={label}
                usePrimaryColor={true}
                innerRef={this.grdDocuments}
                dataRoute={url}
                dataRouteColumns="/Base/GetColumns?grid=tblDocuments"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblDocuments"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblDocuments"
                dataRouteDelete={deleteRoute}
                onDoubleClick={gridDOubleClick}
                onEditButton={handleGridEdit}
                onNewButton={handleGridNew}
                selectedActions={selectedActions}
                rowExpandFormat={this.rowExpandFormat}
                actions={actions}
				customContent={customContent}
            />
        );
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    )
);

export default enhance(Documents);