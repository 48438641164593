import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Select, { components }  from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';


import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { List } from 'react-virtualized';

import { withTheme } from '@material-ui/styles';

import { compose } from 'redux';
import classNames from 'classnames';

import {
    primaryColor,
    primaryBoxShadow,
    dangerColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        margin: '16.5px 1px 10px 0px !important',
        fontSize: '0.75rem !important',
        position: 'relative',
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'initial',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1101,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
    selectFormControl: {
        "& > div": {
            "&:before": {
                borderBottomWidth: "1px !important",
                borderBottomColor: "#D2D2D2 !important"
            },
            "&:after": {
                borderBottomColor: primaryColor + ""
            }
        },
        width: "100%",
    },
    selectFormControlError: {
        "& > div": {
            "&:before": {
                borderBottomWidth: "1px !important",
                borderBottomColor: "#D2D2D2 !important"
            },
            "&:after": {
                scale: 1,
                borderBottomColor: primaryColor + "!important"
            }
        },
        width: "100%",
    },
    selectLabel: {
        color: "#3C4858!important",
        fontSize: "12px",
    },
    selectMenu: {
        "& > div > ul": {
            border: "0",
            padding: "5px 0",
            margin: "0",
            boxShadow: "none",
            minWidth: "100%",
            borderRadius: "4px",
            boxSizing: "border-box",
            display: "block",
            fontSize: "14px",
            textAlign: "left",
            listStyle: "none",
            backgroundColor: "#fff",
            backgroundClip: "padding-box"
        },
        //"& $selectPaper $selectMenuItemSelectedMultiple": {
        //    backgroundColor: "inherit"
        //},
        "& > div + div": {
            maxHeight: "266px !important"
        }
    },
    selectMenuItem: {
        fontSize: "13px",
        padding: "10px 20px",
        margin: "0 5px",
        borderRadius: "2px",
        transition: "all 150ms linear",
        display: "block",
        clear: "both",
        fontWeight: "400",
        lineHeight: "2",
        whiteSpace: "nowrap",
        color: "#333",
        paddingRight: "30px",
        "&:hover": {
            backgroundColor: primaryColor,
            color: "#FFFFFF",
            ...primaryBoxShadow
        }
    },
    selectMenuItemSelected: {
        backgroundColor: primaryColor + "!important",
        color: "#FFFFFF"
    },
    selectMenuItemSubtitled: {
        padding: "5px 20px",
    },
    selectMenuItemSelectedMultiple: {
        backgroundColor: "transparent !important",
        "&:hover": {
            backgroundColor: primaryColor + "!important",
            color: "#FFFFFF",
            ...primaryBoxShadow,
            "&:after": {
                color: "#FFFFFF"
            }
        },
        "&:after": {
            top: "16px",
            right: "12px",
            width: "12px",
            height: "5px",
            borderLeft: "2px solid currentColor",
            transform: "rotate(-45deg)",
            opacity: "1",
            color: "#3c4858",
            position: "absolute",
            content: "''",
            borderBottom: "2px solid currentColor",
            transition: "opacity 90ms cubic-bezier(0,0,.2,.1)"
        }
    },
    error: {
        "&:after": {
            borderBottomColor: dangerColor + "!important"
        }
    },
    errorOptional: {
        "&:after": {
            borderBottomColor: primaryColor + "!important"
        }
    },
});

function SingleValue({ children, ...props }) {
    return <components.SingleValue {...props}>
        {children}
        {props.data.subtitle && ` - ${props.data.subtitle}`}
    </components.SingleValue>;
}
function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}
function Control(props) {
    return (
        <TextField
            fullWidth
            className={props.selectProps.classes.selectFormControl}
            error={props.selectProps.isError}
            InputProps={{
                classes: {
                    error: props.selectProps.errorClass
                },
                inputComponent,
                inputProps: {
                    style:{display: "flex"},
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

class Option extends PureComponent {
  constructor(props) {
    super(props);
    this.optRef = React.createRef();
  }

  componentDidUpdate() {
	if (window.event instanceof MouseEvent)
		return;
	  
    if (this.optRef.current && this.props.isFocused) {
      this.optRef.current.scrollIntoView(false);
    }
  }

  render() {
	var props = this.props;
    return (
        <MenuItem
			ref={this.optRef}
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            classes={{
                root: classNames(props.selectProps.classes.selectMenuItem, { [props.selectProps.classes.selectMenuItemSubtitled]: !!props.data.subtitle }),
                selected: props.selectProps.classes.selectMenuItemSelected,
            }}
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
            {props.data.subtitle && <span style={{ display: "block", marginTop: -8 }}>{props.data.subtitle}</span>}
        </MenuItem>
    );
  }
}

//function Option(props) {
//    return (
//        <MenuItem
//            buttonRef={props.innerRef}
//            selected={props.isFocused}
//            component="div"
//            classes={{
//                root: classNames(props.selectProps.classes.selectMenuItem, { [props.selectProps.classes.selectMenuItemSubtitled]: !!props.data.subtitle }),
//                selected: props.selectProps.classes.selectMenuItemSelected,
//            }}
//            style={{
//                fontWeight: props.isSelected ? 500 : 400,
//            }}
//            {...props.innerProps}
//        >
//            {props.children}
//            {props.data.subtitle && <span style={{ display: "block", marginTop: -8 }}>{props.data.subtitle}</span>}
//        </MenuItem>
//    );
//}

function MenuList(props) {
    var height = 50 * props.children.length;
    if (height > props.maxHeight) {
        height = props.maxHeight;
    }
    return <List
        style={{ minWidth: '100%', maxWidth: '100%' }}
        width={1920}
        height={height}
        rowCount={props.children.length}
        rowHeight={50}
        rowRenderer={({ key, index, isScrolling, isVisible, style }) => {
            if (index >= 0)
                return (<div key={key} style={style}>{props.children[index]}</div>);
            else
                return(<div></div>);
        }}
    />;
}
function MenuPortal(props) {
    return <div {...props} style={{width: 500}}></div>;
}

const customComponents = {
    Control,
    MenuList,
    NoOptionsMessage,
    //MenuPortal,
    Option,
    SingleValue,
};

class XSelect extends Component {
	constructor(props) {
		super(props);
		this.listRef = undefined;
		this.state = {
			data: [],
			filteredData:[],
		};
	}

    componentDidMount() {
        var { customData } = this.props;
        if (customData) {
            this.setState({ filteredData: this.getFilteredData(customData) }, this.setValue);
        }
        else {
            this.loadData(() => {
                if (this.props.onDataChange) {
                    this.props.onDataChange(this.getSelectedData());
                }
            });
        }
    }
    componentDidUpdate(prevProps) {	
        if (!this.props.customData && JSON.stringify(prevProps.urlData) !== JSON.stringify(this.props.urlData)) {
            this.loadData(() => {
                if (this.props.onDataChange) {
                    this.props.onDataChange(this.getSelectedData());
                }
            });
        }
        if (this.props.customData && JSON.stringify(prevProps.customData) !== JSON.stringify(this.props.customData)) {
            this.setState({ filteredData: this.getFilteredData(this.props.customData) });
        }
        if (JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters) || JSON.stringify(prevProps.filtersOr) !== JSON.stringify(this.props.filtersOr) || JSON.stringify(prevProps.filtersExclude) !== JSON.stringify(this.props.filtersExclude)) {
            var { data } = this.state;
            var suggestions = this.getFilteredData(data);
            this.setState({ filteredData: suggestions }, this.setValue);
        }
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            setTimeout(this.setValue,100);
            //this.setValue();
        }
    }
    setValue = () => {
        var value = this.getValue();
        this.setState({ value });
    }
    getFilteredData = (data) => {
        var { filters, filtersOr, filtersExclude, customData, idKey, valueKey,subtitleKey } = this.props;

        if (customData) {
            data = customData;
        }
        if ((filters && filters.map(m => m.value).filter(f => f).length > 0) || !!filtersOr || !!filtersExclude) {
            data = data.filter(this.handleFilter);
        }
        var suggestions = data.map((item) => ({
            value: item[idKey],
            label: item[valueKey],
            subtitle: item[subtitleKey],
        }));
        return suggestions;
    }
    getValue = () => {
        var { filteredData } = this.state;
        var { value } = this.props;
        if (!value && filteredData.length === 1) {
            //value = data[0][idKey];
            //this.handleChange({ value: value }, {});
        }
        if (value === undefined) {
            value = "";
        }
        if (filteredData.length > 0 && !(value === null || value === undefined /*|| isNaN(value)*/)) {
            value = filteredData.filter((item) => item.value === value);
            //if (singleValue) {
            //    value = value[0];
            //}
        }
        return value;
    }
    loadData = (callback) => {
        var { urlData, onDataLoaded } = this.props;
        var oldData = this.state.data;
        
        if (urlData) {
            fetch(urlData)
                .then(res => res.json())
                .then(data => {
                    if(JSON.stringify(this.state.data) === JSON.stringify(oldData)) {
                        onDataLoaded && onDataLoaded(data);
                        this.setState(state => {
                            state.data = data;
                            var suggestions = this.getFilteredData(data);
                            state.filteredData = suggestions;
                            state.value = this.getValue();
                            return state;
                        }, callback);
                    }
                });
        }
    }
	onMenuClose = () => {
		var { id } = this.props;
		if (window.event instanceof KeyboardEvent && window.event.code === 'Tab' && id) {
			// var inputRef = this.listRef.select.select.inputRef;
			//inputRef && inputRef.focus();
			// var currEl = document.getElementById(id);
			// var currInput = currEl.getElementsByTagName('input')[0];
			
			// if (currInput && currInput.length > 0)
			// 	currInput = currInput[0];
			
			// setTimeout(() => {
            //     var { id } = this.props;
            //     var currEl = document.getElementById(id);
			//     var currInput = currEl.getElementsByTagName('input')[0];
			// 	currInput && currInput.focus();
			// 	//inputRef && inputRef.focus();
			// 	//this.listRef.focus();
			// 	console.log("focus changed");
			// }, 500);
		}
	}
    handleChange = (child, event) => {
        var { id, onChange, idKey, customData, linkedFields } = this.props;
        var { data } = this.state;
        if (customData) {
            data = customData;
        }
        if (onChange) {
            if (child) {
				onChange(id)(event, data.filter((item) => item[idKey] === child.value));
				//if (linkedFields) {
				//	linkedFields.every(function (item) {
				//		onChange(item)(event, data);
				//	})
				//}
            }
            else {
                onChange(id)(event, []);
            }
        }
        
        
        setTimeout(() => {
            var { id } = this.props;
            var currEl = document.getElementById(id);
            if(currEl){
                var currInput = currEl.getElementsByTagName('input')[0];
                currInput && currInput.focus();
            }
        }, 200);
            
		// if (window.event instanceof KeyboardEvent && window.event.code === 'Tab' && id) {
		// 	var inputRef = this.listRef.select.select.inputRef;
		// 	inputRef && inputRef.focus();
		// 	var currEl = document.getElementById(id);
		// 	var currInput = currEl.getElementsByTagName('input');
			
		// 	if (currInput && currInput.length > 0)
		// 		currInput = currInput[0];
			
		// 	setTimeout(() => {
		// 		inputRef && inputRef.focus();
		// 		currInput && currInput.focus();
		// 		//this.listRef.focus();
		// 		console.log("focus changed");
		// 	}, 350);
		// }
    }
    handleFilter = (item) => {
        var result = true;
        var { filters, filtersOr, filtersExclude, idKey } = this.props;
        var hasFilters = (filters && filters.map(m => m.value).filter(f => f).length > 0) || !!filtersOr || !!filtersExclude;
        if (!hasFilters) {
            return result;
        }

        var filter = undefined;
        for (var i1 in filters) {

            filter = filters[i1];
            if (filter.value) {
                if (filter.type && filter.type === "array") {
                    var itemValue = item[filter.key];
                    if (itemValue) {
                    if (typeof itemValue === "string") {
                        itemValue = itemValue.split(",").map(Number);
                    }
                    var hasValue = itemValue.includes(filter.value);
                    result = result && (hasValue || item[idKey] < 0);
                }
                }
                else {
                    result = result && (item[filter.key] === filter.value || item[idKey] < 0);
                }
            }
        }

        filter = undefined;
        var resultOr = undefined;
        for (var i2 in filtersOr) {
            filter = filtersOr[i2];
            if (filter.value) {
                if (resultOr === undefined) {
                    resultOr = (item[filter.key] === filter.value || item[idKey] < 0);
                }
                else {
                    resultOr = resultOr || (item[filter.key] === filter.value || item[idKey] < 0);
                }
            }
        }
        filter = undefined;
        for (var i3 in filtersExclude) {
            filter = filtersExclude[i3];
            if (filter.value) {
                result = result && (item[filter.key] !== filter.value || item[idKey] < 0);
            }
        }
        if (resultOr === undefined) {
            resultOr = true;
        }
        result = result && resultOr;

        return result;
    };

    handleCreateOption = (inputValue) => {
        var { urlCreate, filters } = this.props;
        if (urlCreate) {
            fetch(urlCreate, {
                method: 'POST',
                body: JSON.stringify({ Name: inputValue, Abbreviation: '', filters: filters })
            }).then((res) => res.json()).then((data) => {
                if (data.Exists) {
                    this.handleChange({ value: data.ID }, {});
                }
                else {
                    this.loadData(() => {
                        this.handleChange({ value: data.item.ID }, {});
                    });
                }
            });
        }
    };
    getSelectedData = () => {
        var { data } = this.state;
        var { idKey, value } = this.props;
        return data.filter(item => item[idKey] === value)[0];
    }
    rowRenderer = ({ key, index, isScrolling, isVisible, style }) => {
        var { classes, idKey, valueKey, abbreviationKey } = this.props;
        var { data } = this.state;
        var value = data[index];
        let desc = value[valueKey];
        if (abbreviationKey && value[abbreviationKey]) {
            desc = value[abbreviationKey] + ' - ' + desc;
        }
        return (
            <MenuItem
                key={key}
                classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                }}
                style={style}
                value={value[idKey]}
            >{desc}</MenuItem>
            );
    };
    isValidNewOption = (inputValue, selectValue, selectOptions) => {
        inputValue = inputValue.toLowerCase();
        return !!inputValue && selectOptions.map(s => (s.label).toLowerCase() === inputValue).filter(w => w === true).length == 0;
    }
    render() {
        var { filteredData, value } = this.state;
        var { classes, urlCreate, label, field, style, noPortal, disabled, searchable, id } = this.props;
       
        var portalTarget = document.body;
        if (noPortal) {
            portalTarget = undefined;
        }
        if(searchable === undefined){
            searchable = true;
        }
        var suggestions = filteredData; 
        var suggestionsWithLength = suggestions.map(i => ({value: i.label, len: (i.label && i.label.length) || 0})).sort(( a, b ) => {
            if ( a.len < b.len ){
              return 1;
            }
            if ( a.len > b.len ){
              return -1;
            }
            return 0;
        });
        var maxValue = "";
        if(suggestionsWithLength.length > 0){
            maxValue = suggestionsWithLength[0].value;
        }

        var fontSize = 12;
        var test = document.createElement("span");
        document.body.append(test);
        test.innerHTML = maxValue;
        test.style.fontSize = fontSize;
        var width = (test.offsetWidth + 40);
        test.remove();

        const selectStyles = {
            input: base => ({
                ...base,
                color: this.props.theme.primaryColor,
                fontSize: '0.75rem',
                '& input': {
                    font: 'inherit',
                    fontSize: '0.75rem',
                },
            }),
            container: base => ({
                ...base,
                marginTop: '5px',
                fontSize: '0.75rem',
            }),
            valueContainer: base => ({
                ...base,
                fontSize: '0.75rem',
                padding: '0',
            }),
            clearIndicator: base => ({
                ...base,
                padding: '5px',
                cursor: 'pointer'
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: '5px',
                cursor: 'pointer'
            }),
            menuPortal: base=> ({
                ...base,
                zIndex: 2000,
                minWidth: width
            }),
        };
        var error = false;
        var errorClass = classes.error;
        var clearable = this.props.clearable;
        if (field) {
            if (clearable === undefined)
                clearable = field.Required !== 2;
            if ((field.Required === 2 || (field.Required === 1)) && (!value || value === "" || value.length === 0)) {
                error = true;
                if (field.Required === 1) {
                    errorClass = classes.errorOptional;
                }
            }
        }

        return (
            <div className={classes.root} style={style}>
                {urlCreate ?
                    (
                        <CreatableSelect
							//autoFocus={true}
							id={id}
							ref={ref => {
								this.listRef = ref;
							}}
                            value={value}
							onMenuClose={this.onMenuClose}
                            onChange={this.handleChange}
                            textFieldProps={{
                                label: label,
                                InputLabelProps: {
                                    shrink: true,
                                    className: classes.selectLabel
                                },
                               
                            }}
                            filterOption={({label},str) => (label || "").toLowerCase().includes(str.toLowerCase())}
                            isValidNewOption={this.isValidNewOption}
                            isError={error}
                            errorClass={errorClass}
                            onCreateOption={this.handleCreateOption}
                            classes={classes}
                            styles={selectStyles}
                            options={suggestions}
                            components={customComponents}
                            isClearable={clearable}
                            isDisabled={disabled}
                            isSearchable={searchable}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuPortalTarget={portalTarget}
                            noPortal={false}
                            menuShouldBlockScroll={true}
                            formatCreateLabel={(inputValue) => "Inserisci '" + inputValue + "'"}
                        />
                    ) : (
                        <Select
							//autoFocus={true}
							id={id}
							ref={ref => {
								this.listRef = ref;
							}}
                            value={value}
							onMenuClose={this.onMenuClose}
                            onChange={this.handleChange}
                            textFieldProps={{
                                label: label,
                                InputLabelProps: {
                                    shrink: true,
                                    className: classes.selectLabel,
									style: this.props.labelStyle
                                }
                            }}
                            filterOption={({label},str) => (label || "").toLowerCase().includes(str.toLowerCase())}
                            isError={error}
                            errorClass={errorClass}
                            classes={classes}
                            styles={selectStyles}
                            options={suggestions}
                            components={customComponents}
                            isClearable={clearable}
                            isDisabled={disabled}
                            isSearchable={searchable}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuPortalTarget={portalTarget}
                            noPortal={false}
                            menuShouldBlockScroll={true}
                        />
                    )}
            </div>
        );
    }
}
XSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    idKey: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    abbreviationKey: PropTypes.string,
    filters: PropTypes.array,
    label: PropTypes.string.isRequired,
};



const enhance = compose(
    withTheme,
    withStyles(styles)
);

export default enhance(XSelect);

