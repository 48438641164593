import React, { Component, Fragment } from 'react';
import { withStyles, withWidth, Grid, Button, Drawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid"
import XDateField from "components/XDateField";
import XButton from "components/XButton";
import { bindActionCreators, compose } from 'redux';
import { Legend, XAxis, YAxis, BarChart, CartesianGrid, Tooltip, Bar, PieChart, Pie, Sector, Cell, } from 'recharts';
import XMessageBox from 'components/XMessageBox';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from "perfect-scrollbar";
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

import XNavigation from '../../components/XNavigation';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { AutoSizer } from 'react-virtualized';
import XSelect from '../../components/XSelect';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';

import ReactHtmlParser from 'react-html-parser';
import XExpansionPanel from 'components/XExpansionPanel';
import { connect } from 'react-redux';
import { actionCreators as authCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';
import  ResizeSenzor from 'css-element-queries/src/ResizeSensor';

//Icons
import { Plus, ChartPie } from 'mdi-material-ui';
// NEW ICONS 
import InsertChartIcon from '@material-ui/icons/InsertChart';
import CakeIcon from '@material-ui/icons/Cake';
import SubjectIcon from '@material-ui/icons/Subject';
//import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity';
import VisibilityIcon from '@material-ui/icons/Visibility';
import classNames from 'classnames';
import XOXLoader from 'components/XOXLoader';

var ps;
const drawerWidth = 240;


// Controlers Global Variables, used to abort different fetches in order to speed up

// Tab Index = 0
let controllerTop10CatalogCodes;
let controllerTop10Products;
let controllerPercentageForLine;
let controllerPercentageForSeries;
let controllerPercentageForCategories;
let controllerTop10Identity;
let controllerPercentageForNazione;
let controllerPercentageForRegions;
let controllerPercentageForProvince;
let controllerPercentageForCity;

// Tab Index = 1
let controllerTop10News;
let controllerTop10NewsTime;
let controllerTop10NewsUserTime;

// Tab Index = 2
let controllerTop10Adv;
let controllerTop10AdvTime;
let controllerTop10AdvUserTime;

// Tab Index = 3
let controllerTop10Logins;

// Tab Index = 4 
let controllerBuyTop10CatalogCodes;
let controllerBuyTop10Products;
let controllerBuyPercentageForLine;
let controllerBuyPercentageForSeries;
let controllerBuyPercentageForCategories;
let controllerBuyTop10Identity;
let controllerBuyPercentageForNazione;
let controllerBuyPercentageForRegions;
let controllerBuyPercentageForProvince;
let controllerBuyPercentageForCity;

// Tab Index = 5
let controllerSellTop10CatalogCodes;
let controllerSellTop10Products;
let controllerSellPercentageForLine;
let controllerSellPercentageForSeries;
let controllerSellPercentageForCategories;
let controllerSellTop10Identity;
let controllerSellPercentageForNazione;
let controllerSellPercentageForRegions;
let controllerSellPercentageForProvince;
let controllerSellPercentageForCity;



const styles = theme => ({
    root: {
        textAlign: 'center',
    },

    card: {
        textAlign: 'center',
        borderRadius: 1,
    },

    card2: {
        textAlign: 'center',
        borderRadius: 0,
        padding: 20,
    },

    cardCategory: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#000',
        marginTop: 10
    },

    gridStyle: {
        margin: '0',
    },

    loadButton: {
        "& > button": {
            position: 'sticky',
            left: '50%',
            transform: 'translate(-50%)'
        },
    },

    gridLoadButton: {
        position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            background: '#fff',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
            textAlign: 'center',
    },

    tortaMain: {
        right: '50%',
        overflow: 'hidden',
    },

    torta: {
        position: 'relative',
        // transform: 'scale(1.5)',
        // left: '40%',
        bottom: '10%',
        fontSize: "x-small",
    },


    container: {
        width: '100%',
    },

    tooltipRoot: {
        backgroundColor: 'white',
        borderRadius: 1,
        borderWidth: 20,
        borderColor: '#fff',
        width: 'auto',
        height: 'auto',
        border: '25px',
        textAlign: 'center',
    },

    DenominationValue: {
        fontWeight: 'bold',
        color: '#141823',
        display: 'inline-block',
    },

    ViewsValue: {
        fontWeight: 'bold',
        color: '#F4B50D',
        textAlign: 'center',
    },

    // Drawer 
    tabContainer: {
        // display: 'flex',
    },
    // appBar: {

    //     transition: theme.transitions.create(['margin', 'width'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //     }),
    // },
    // appBarShift: {

    //     width: `calc(100% - ${drawerWidth}px)`,
    //     transition: theme.transitions.create(['margin', 'width'], {
    //         easing: theme.transitions.easing.easeOut,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    //     marginRight: drawerWidth,
    // },
    // title: {
    //     flexGrow: 1,
    // },
    // hide: {
    //     display: 'none',
    // },
    drawer: {

        width: drawerWidth,
        minWidth: drawerWidth,
        maxWidth: drawerWidth,
        flexShrink: 0,
    },

    // drawerOpen: {
    //     flexShrink: 0,
    //   },

    drawerPaper: {
        width: drawerWidth,
        minWidth: drawerWidth,
        maxWidth: drawerWidth,
        top: '144px',
        height: 'calc(100% - 144px)',
        padding: 0,
        right: '16px',
        zIndex: 1,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    tabContent: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: "100%",
        transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    tabContentOpen: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    contentShift: {

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    
    sidebarWrapper: {
        position: "relative",
        height: "calc(100vh - 75px)",
        overflow: "auto",
        // width: "260px",
        zIndex: "4",
        overflowScrolling: "touch",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        color: "inherit",
        //paddingBottom: "30px"
    },
    sidebarWrapperWithPerfectScrollbar: {
        overflow: "hidden !important"
    },

    noResult: {
        textAlign: "center",
        paddingTop: '6%',
        paddingBottom: '10%',
    },

});

const legendStyle = {
    //fontFamily: "Times New Roman",
    columns: 'auto 1',
    fontSize: '10px',
    display: 'inline-block',
    left: 'initial',
    right: '20%',
};



const format = (number, a, b) => {
    if (number) {

        return `${(number).toLocaleString('it-IT', { minimumFractionDigits: 2 })}`
    }

    return "";
}

// const PercentageFormat = (number) => {
//     if(number){
//         return `${(number).toLocaleString('it-IT', {minimumFractionDigits: 2})}%`
//     }
//     return "";
// }

class SidebarWrapper extends React.Component {
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}
	render() {
		const { className, children } = this.props;
		return (
			<div className={className} ref="sidebarWrapper">
				{children}
			</div>
		);
	}
}

const CustomTooltip = withStyles(styles)((props) => {
    
    var { active, payload, label, classes } = props;
    if (active) {

        var denomination = payload && payload[0].payload.Denomination;
        return (
            <div className={classes.tooltipRoot}>
                <p className={classes.DenominationValue}>{`${label} - ${denomination} `}</p>
                <p className={classes.ViewsValue}>{payload && payload[0].value} Views</p>
            </div>
        );
    }

    return null;
});



class CatalogStats extends Component {
    
    constructor(props) {
        super(props);

        this.grdTOP10BOP = React.createRef();
        this.grdCATSTAT_CategoryP = React.createRef();
        this.grdCATSTAT_CodeV = React.createRef();
        this.grdCATSTAT_CountryP = React.createRef();
        this.grdCATSTAT_LineP = React.createRef();
        this.grdCATSTAT_SerieP = React.createRef();
        this.grdCATSTAT_RegionsP = React.createRef();
        this.grdCATSTAT_ProvincesP = React.createRef();
        this.grdCATSTAT_CitiesP = React.createRef();
        this.grdCATSTAT_Top10Catalogue = React.createRef();

        this.grdBINews = React.createRef();
        this.grdBIAdv = React.createRef();

        this.grdBINewsTime = React.createRef();
        this.grdBIAdvTime = React.createRef();

        this.grdBIUserTimeNews = React.createRef();
        this.grdBIUserTimeAdv = React.createRef();

        this.grdBILogin = React.createRef();

        // Buy & Sell Grids Reference (in the code is used to refresh the data from grid)
        this.grdBIBuySellTOPCATALOGCODE = React.createRef();
        this.grdBIBuySellTOPCODE = React.createRef();
        this.grdBIBuySellTOPIdentity = React.createRef();
        this.grdBIBuySellTOPLine = React.createRef();
        this.grdBIBuySellTOPSeries = React.createRef();
        this.grdBIBuySellTOPCategories = React.createRef();
        this.grdBIBuySellTOPCountry = React.createRef();
        this.grdBIBuySellTOPRegions = React.createRef();
        this.grdBIBuySellTOPProvince = React.createRef();
        this.grdBIBuySellTOPCity = React.createRef();


        var d = new Date();
        d.setDate(d.getDate() - 30);
        
        this.state = {
            pieSector: undefined,
            width: 10,
            user: undefined,

            top10: undefined,
            top10Anagrafiche: undefined,

            Lines: undefined,
            Series: undefined,
            Categories: undefined,
            PerNazione: undefined,
            Regions: undefined,
            Province: undefined,
            Cities: undefined,
            CodiciCatalogo: undefined,

            //News e ADV
            News: undefined,
            Adv: undefined,
            TimeNews: undefined,
            TimeAdv: undefined,
            UsersNews: undefined,
            UsersAdv: undefined,

            //Accessi
            usrLogin: undefined,
            usrLogout: undefined,

            dateFrom: d,
            dateTo: new Date(),

            FIDLine: undefined,
            FIDSeries: undefined,
            FIDCountry: undefined,
            FIDIdentity: undefined,
            FIDCategory: undefined,
            FIDTipology: undefined,

            FIDRegion: undefined,
            FIDProvince: undefined,
            FIDCity: undefined,
            FIDDistribution: undefined,

            FIDBrand: undefined,
            FIDDocumentSubType: undefined,
            FIDOwner: undefined,

            viewTypeTOP10: "chart",
            viewTypeSeries: "torta",
            viewTypeNazione: "torta",
            viewTypeLines: "torta",
            viewTypeCategories: "torta",
            viewTypeAnagrafiche: "chart",
            viewTypeTOP10Catalogo: "chart",

            viewTypeRegions: "torta",
            viewTypeProvince: "torta",
            viewTypeCities: "torta",

            //News e ADV
            viewTypeNews: "chart",
            viewTypeAdv: "chart",
            viewTypeTimeNews: "chart",
            viewTypeTimeAdv: "chart",
            viewTypeUsersTimeNews: "chart",
            viewTypeUsersTimeAdv: "chart",

            //Accessi
            viewTypeLogin: "chart",

            actionsNav: undefined,
            direction: undefined,

            //drawers
            catalogoSetOpen: false,
            crmSetOpen: false,
            crmAdvSetOpen: false,
            loginSetOpen: false,

            mainContentWidth: 0,


            
            //Buy - Data
            buyCatalogCodeDOC: undefined,
            buyCodeDOC: undefined,
            buyIdentityDOC: undefined,
            buyPercentageLineDOC: undefined,
            buyPercentageSeriesDOC: undefined,
            buyPercentageCategoriesDOC: undefined,
            buyPercentageCountryDOC: undefined,
            buyPercentageRegionsDOC: undefined,
            buyPercentageProvinceDOC: undefined,
            buyPercentageCitiesDOC: undefined,

            //Buy - Views
            viewTypebuyCatalogCodeDOC: "chart",
            viewTypebuyCodeDOC: "chart",
            viewTypebuyIdentityDOC: "chart",
            viewTypebuyPercentageLineDOC: "torta",
            viewTypebuyPercentageSeriesDOC: "torta",
            viewTypebuyPercentageCategoriesDOC: "torta",
            viewTypebuyPercentageCountryDOC: "torta",
            viewTypebuyPercentageRegionsDOC: "torta",
            viewTypebuyPercentageProvinceDOC: "torta",
            viewTypebuyPercentageCitiesDOC: "torta",

            // Sell - Data
            sellCatalogCodeDOC: undefined,
            sellCodeDOC: undefined,
            sellIdentityDOC: undefined,
            sellPercentageLineDOC: undefined,
            sellPercentageSeriesDOC: undefined,
            sellPercentageCategoriesDOC: undefined,
            sellPercentageCountryDOC: undefined,
            sellPercentageRegionsDOC: undefined,
            sellPercentageProvinceDOC: undefined,
            sellPercentageCitiesDOC: undefined,

            //Sell - Views
            viewTypesellCatalogCodeDOC: "chart",
            viewTypesellCodeDOC: "chart",
            viewTypesellIdentityDOC: "chart",
            viewTypesellPercentageLineDOC: "torta",
            viewTypesellPercentageSeriesDOC: "torta",
            viewTypesellPercentageCategoriesDOC: "torta",
            viewTypesellPercentageCountryDOC: "torta",
            viewTypesellPercentageRegionsDOC: "torta",
            viewTypesellPercentageProvinceDOC: "torta",
            viewTypesellPercentageCitiesDOC: "torta",
            tabIndex: 0,

            // Loader
            //Catalogo
            isLoadingCatalogCodes: true,
            isLoadingTOP10Products: true,
            isLoadingPercentagePerLine: true,
            isLoadingPercentagePerSeries: true,
            isLoadingPercentagePerCategories: true,
            isLoadingPercentagePerTOP10Master: true,
            isLoadingPercentagePerCountry: true,
            isLoadingPercentagePerRegions: true,
            isLoadingPercentagePerProvince: true,
            isLoadingPercentagePerCity: true,


            //News
            isLoadingNews: true,
            isLoadingNewsRemain: true,
            isLoadingUsersRemainNews: true,

            // Adv
            isLoadingAdv: true,
            isLoadingAdvRemain: true,
            isLoadingUsersRemainAdv: true,


            //Logins 
            isLoadingLogins: true,

            // Buy
            isLoadingBuyCatalogCodes: true,
            isLoadingBuyTOP10Products: true,
            isLoadingBuyPercentagePerLine: true,
            isLoadingBuyPercentagePerSeries: true,
            isLoadingBuyPercentagePerCategories: true,
            isLoadingBuyPercentagePerTOP10Master: true,
            isLoadingBuyPercentagePerCountry: true,
            isLoadingBuyPercentagePerRegions: true,
            isLoadingBuyPercentagePerProvince: true,
            isLoadingBuyPercentagePerCity: true,

            // Sell
            isLoadingSellCatalogCodes: true,
            isLoadingSellTOP10Products: true,
            isLoadingSellPercentagePerLine: true,
            isLoadingSellPercentagePerSeries: true,
            isLoadingSellPercentagePerCategories: true,
            isLoadingSellPercentagePerTOP10Master: true,
            isLoadingSellPercentagePerCountry: true,
            isLoadingSellPercentagePerRegions: true,
            isLoadingSellPercentagePerProvince: true,
            isLoadingSellPercentagePerCity: true,

            
        }
        this.XMessage = React.createRef();

        this.ResizeSenzor = undefined;
    }

    static contextTypes = {
        router: PropTypes.object
    }
    

    componentDidMount = () => {

        this.loadData();
        this.getbuyPercentageLineDOC();
        // this.loadDataNews();
        // this.loadDataAdv();
        // this.loadDataUserAccess();
        // this.loadDataBuy();

        var mainContent = document.getElementById("mainContent");
        this.ResizeSenzor = new ResizeSenzor(mainContent,this.onMainContentResize);

        // Sensor for responsive BI
        this.onMainContentResize();



    }
    componentWillUnmount = () => {
        if(this.ResizeSenzor) {
            //dispose sau close sau cancel
            this.ResizeSenzor = undefined;
        }
    }
    onMainContentResize = () => {
        var mainContentWidth = this.getWidth();
        this.setState({ mainContentWidth });
    }

    loadData = () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, catalogoSetOpen, isLoading, tabIndex } = this.state;
        if (dateFrom > dateTo) {
            this.XMessage.current.showMessageOk(getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE", this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE_ERROR", this.props.translations, " Range di date non valido")} </Typography>);
        }
        else {
            if(tabIndex == 0 ) {
                this.dataGetTOP10View();
                this.GetPercentageLines();
                this.GetPercentageSeries();
                this.GetPercentageCategories();
                this.GetTOP10Anagrafiche();
                this.GetPercentualeNazione();
                this.GetPercentageRegions();
                this.GetPercentageProvince();
                this.GetPercentageCities();
                this.GetTOP10ViewCodiciCatalogo();

                //this.grdTOP10BOP.current?.refresh(); -- sample
                this.grdTOP10BOP.current && this.grdTOP10BOP.current.refresh();
                this.grdCATSTAT_CategoryP.current && this.grdCATSTAT_CategoryP.current.refresh();
                this.grdCATSTAT_CodeV.current && this.grdCATSTAT_CodeV.current.refresh();
                this.grdCATSTAT_CountryP.current && this.grdCATSTAT_CountryP.current.refresh();
                this.grdCATSTAT_LineP.current && this.grdCATSTAT_LineP.current.refresh();
                this.grdCATSTAT_SerieP.current && this.grdCATSTAT_SerieP.current.refresh();
                this.grdCATSTAT_RegionsP.current && this.grdCATSTAT_RegionsP.current.refresh();
                this.grdCATSTAT_ProvincesP.current && this.grdCATSTAT_ProvincesP.current.refresh();
                this.grdCATSTAT_CitiesP.current && this.grdCATSTAT_CitiesP.current.refresh();
                this.grdCATSTAT_Top10Catalogue.current && this.grdCATSTAT_Top10Catalogue.current.refresh();
            }

            if(tabIndex == 4) {
                this.abortFetchCatalogo();
                this.loadDataBuy();
                //abort fetches that aren't needed in this index
                
            }
    
            if(tabIndex == 5) {
                this.abortFetchCatalogo();
                this.loadDataSell();
                //abort fetches that aren't needed in this index
                
            }


        }
    }

    // News E Adv Load Data
    loadDataNews = () => {
        var { dateFrom, dateTo } = this.state;

        if (dateFrom > dateTo) {
            this.XMessage.current.showMessageOk(getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE", this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE_ERROR", this.props.translations, " Range di date non valido")} </Typography>);
        }
        else {


            this.GetTOP10News();

            this.GetTOP10NewsTime();

            this.GetTOP10UsersTimeNews();

            this.grdBINews.current && this.grdBINews.current.refresh();

            this.grdBINewsTime.current && this.grdBINewsTime.current.refresh();

            this.grdBIUserTimeNews.current && this.grdBIUserTimeNews.current.refresh();
        }
    }

    loadDataAdv = () => {
        var { dateFrom, dateTo } = this.state;

        if (dateFrom > dateTo) {
            this.XMessage.current.showMessageOk(getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE", this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE_ERROR", this.props.translations, " Range di date non valido")} </Typography>);
        }
        else {

            this.GetTOP10Adv();

            this.GetTOP10AdvTime();

            this.GetTOP10UsersTimeAdv();

            this.grdBIAdv.current && this.grdBIAdv.current.refresh();

   
            this.grdBIAdvTime.current && this.grdBIAdvTime.current.refresh();

            this.grdBIUserTimeAdv.current && this.grdBIUserTimeAdv.current.refresh();
        }
    }

    // Top 10 Accesses
    loadDataUserAccess = () => {
        var { dateFrom, dateTo } = this.state;

        if (dateFrom > dateTo) {
            this.XMessage.current.showMessageOk(getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE", this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE_ERROR", this.props.translations, " Range di date non valido")} </Typography>);
        }
        else {

            this.GetTOP10Logins();

            this.grdBILogin.current && this.grdBILogin.current.refresh();
        }
    }

    // Buy
    loadDataBuy = () => {
        var { dateFrom, dateTo } = this.state;

        if (dateFrom > dateTo) {
            this.XMessage.current.showMessageOk(getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE", this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE_ERROR", this.props.translations, " Range di date non valido")} </Typography>);
        }
        else {
            this.getbuyCatalogCodeDOC();

            this.getbuyCodeDOC();

            this.getbuyIdentityDOC();

            this.getbuyPercentageCategoriesDOC();

            this.getbuyPercentageCountryDOC();

            this.getbuyPercentageLineDOC();

            this.getbuyPercentageProvinceDOC();

            this.getbuyPercentageRegionsDOC();

            this.getbuyPercentageSeriesDOC();

            this.getbuyPercentageCitiesDOC();

            // this will refresh both grids the one from buy and the one from sell
            this.grdBIBuySellTOPCATALOGCODE.current && this.grdBIBuySellTOPCATALOGCODE.current.refresh();
            this.grdBIBuySellTOPCODE.current && this.grdBIBuySellTOPCODE.current.refresh();
            this.grdBIBuySellTOPCategories.current && this.grdBIBuySellTOPCategories.current.refresh();
            this.grdBIBuySellTOPCity.current && this.grdBIBuySellTOPCity.current.refresh();
            this.grdBIBuySellTOPCountry.current && this.grdBIBuySellTOPCountry.current.refresh();
            this.grdBIBuySellTOPIdentity.current && this.grdBIBuySellTOPIdentity.current.refresh();
            this.grdBIBuySellTOPLine.current && this.grdBIBuySellTOPLine.current.refresh();
            this.grdBIBuySellTOPProvince.current && this.grdBIBuySellTOPProvince.current.refresh();
            this.grdBIBuySellTOPRegions.current && this.grdBIBuySellTOPRegions.current.refresh();
            this.grdBIBuySellTOPSeries.current && this.grdBIBuySellTOPSeries.current.refresh();
        }
    }// Sell
    loadDataSell = () => {
        var { dateFrom, dateTo } = this.state;

        if (dateFrom > dateTo) {
            this.XMessage.current.showMessageOk(getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE", this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE_ERROR", this.props.translations, " Range di date non valido")} </Typography>);
        }
        else {
            this.getsellCatalogCodeDOC();

            this.getsellCodeDOC();

            this.getsellIdentityDOC();

            this.getsellPercentageCategoriesDOC();

            this.getsellPercentageCountryDOC();

            this.getsellPercentageLineDOC();

            this.getsellPercentageProvinceDOC();

            this.getsellPercentageRegionsDOC();

            this.getsellPercentageSeriesDOC();

            this.getsellPercentageCitiesDOC();

            // this will refresh both grids the one from buy and the one from sell
            this.grdBIBuySellTOPCATALOGCODE.current && this.grdBIBuySellTOPCATALOGCODE.current.refresh();
            this.grdBIBuySellTOPCODE.current && this.grdBIBuySellTOPCODE.current.refresh();
            this.grdBIBuySellTOPCategories.current && this.grdBIBuySellTOPCategories.current.refresh();
            this.grdBIBuySellTOPCity.current && this.grdBIBuySellTOPCity.current.refresh();
            this.grdBIBuySellTOPCountry.current && this.grdBIBuySellTOPCountry.current.refresh();
            this.grdBIBuySellTOPIdentity.current && this.grdBIBuySellTOPIdentity.current.refresh();
            this.grdBIBuySellTOPLine.current && this.grdBIBuySellTOPLine.current.refresh();
            this.grdBIBuySellTOPProvince.current && this.grdBIBuySellTOPProvince.current.refresh();
            this.grdBIBuySellTOPRegions.current && this.grdBIBuySellTOPRegions.current.refresh();
            this.grdBIBuySellTOPSeries.current && this.grdBIBuySellTOPSeries.current.refresh();
        }
    }

    onUserDataChange = (key) => (data) => {
        this.setState(state => {
            if (data) {
                state[key] = data;
            }
            else {
                state[key] = undefined;
            }
            return state;
        });
    }

    handleSelectChange = (key) => (event, value) => {

        this.setState(state => {
            if (value && value.length > 0) {
                state[key] = value[0].ID;
            }
            else {
                state[key] = undefined;
            }

            return state;
        });

    }

    // Top 10 Prodotti
    dataGetTOP10View = async () => {

        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingTOP10Products } = this.state;
        this.setState({isLoadingTOP10Products: true});
        var url = `/api/BI/GetTOP10View?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerTop10Products = new AbortController();
        var signal = controllerTop10Products.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ top10: result, isLoadingTOP10Products: false });
    }

    // Percentuale Linee
    GetPercentageLines = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingPercentagePerLine } = this.state;
        this.setState({isLoadingPercentagePerLine: true});
        var url = `/api/BI/GetPercentageLines?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerPercentageForLine = new AbortController();
        var signal = controllerPercentageForLine.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ Lines: result, isLoadingPercentagePerLine: false });
    }

    // Percentuale Serie
    GetPercentageSeries = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingPercentagePerSeries } = this.state;
        this.setState({isLoadingPercentagePerSeries: true});
        var url = `/api/BI/GetPercentageSeries?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }

        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        
        controllerPercentageForSeries = new AbortController();
        var signal = controllerPercentageForSeries.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ Series: result, isLoadingPercentagePerSeries: false });
    }

    // Percentuale Categorie
    GetPercentageCategories = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingPercentagePerCategories } = this.state;
        this.setState({isLoadingPercentagePerCategories: true});
        var url = `/api/BI/GetPercentageCategories?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        
        controllerPercentageForCategories = new AbortController();
        var signal = controllerPercentageForCategories.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })

        this.setState({ Categories: result, isLoadingPercentagePerCategories: false });
    }

    // Top 10 Identity
    GetTOP10Anagrafiche = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingPercentagePerTOP10Master } = this.state;
        this.setState({isLoadingPercentagePerTOP10Master: true});
        var url = `/api/BI/GetTOP10Anagrafiche?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerTop10Identity = new AbortController();
        var signal = controllerTop10Identity.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ top10Anagrafiche: result, isLoadingPercentagePerTOP10Master: false });
    }

    // Percent Nazione
    GetPercentualeNazione = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingPercentagePerCountry } = this.state;
        this.setState({isLoadingPercentagePerCountry: true});
        var url = `/api/BI/GetPercentualeNazione?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }


        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerPercentageForNazione = new AbortController();
        var signal = controllerPercentageForNazione.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })

        this.setState({ PerNazione: result, isLoadingPercentagePerCountry: false });
    }

    // Codici Catalogo
    GetTOP10ViewCodiciCatalogo = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingCatalogCodes } = this.state;
        this.setState({isLoadingCatalogCodes: true});
        var url = `/api/BI/GetTOP10CodiciCatalogo?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }


        
        controllerTop10CatalogCodes = new AbortController();
        var signal = controllerTop10CatalogCodes.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })


        this.setState({ CodiciCatalogo: result, isLoadingCatalogCodes: false });
    }


    getWidth = () => {
        return document.getElementById("mainContent").clientWidth * 0.9;
    }

    //GetPercentageRegions
    GetPercentageRegions = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingPercentagePerRegions } = this.state;
        this.setState({isLoadingPercentagePerRegions: true});
        var url = `/api/BI/GetPercentageRegions?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

                
        controllerPercentageForRegions = new AbortController();
        var signal = controllerPercentageForRegions.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ Regions: result, isLoadingPercentagePerRegions: false });
    }

    //GetPercentangeProvince
    GetPercentageProvince = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingPercentagePerProvince } = this.state;
        this.setState({isLoadingPercentagePerProvince: true});
        var url = `/api/BI/GetPercentageProvince?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }

        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerPercentageForProvince = new AbortController();
        var signal = controllerPercentageForProvince.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ Province: result, isLoadingPercentagePerProvince: false });
    }

    // GetPercentangeCities
    GetPercentageCities = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingPercentagePerCity } = this.state;
        this.setState({isLoadingPercentagePerCity: true});
        var url = `/api/BI/GetPercentageCities?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerPercentageForCity = new AbortController();
        var signal = controllerPercentageForCity.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ Cities: result, isLoadingPercentagePerCity: false });
    }

    getWidth = () => {
        return document.getElementById("mainContent").clientWidth * 0.9;
    }

    renderPanelParameters = () => {
        var { classes } = this.props;
        var { dateFrom, dateTo } = this.state;
        //filters={[ {key: "FIDSeries", value: this.state.FIDSeries} ]
        var paramsForLastIndex = undefined;
        
        if(this.state.tabIndex === 5) {
            paramsForLastIndex = (
                <>
                <Grid item xs={12}><XSelect clearable label="Marca" id="FIDBrand" urlData="/BOP/Brand" value={this.state.FIDBrand} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label="Sottotipo" id="FIDDocumentSubType" urlData="/doc/documentsubtype" value={this.state.FIDDocumentSubType} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label="Emissione Anagrafica" id="FIDOwner" urlData="/IR/GetIRLicenseesSelect" value={this.state.FIDOwner} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                
                </>
            );
        }
        else {
            paramsForLastIndex = undefined;
        }

        return (

            <Grid  style={{margin: 0}} container xs={12} spacing={3}>
                <Grid item xs={12}><XDateField clearable={false} label={getTranslation(7, "BI_TITLE_PARAMS_FROMDATA", this.props.translations, "Da data")} type="date" id="dateFrom" maxDate={dateTo} value={dateFrom} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XDateField clearable={false} label={getTranslation(7, "BI_TITLE_PARAMS_TODATA", this.props.translations, "A data")} type="date" id="dateTo" minDate={dateFrom} value={dateTo} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_COLECTION", this.props.translations, "Collezione")} id="FIDLine" urlData="/BOP/Line" value={this.state.FIDLine} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_SERIES", this.props.translations, "Serie")} id="FIDSeries" urlData="/BOP/Series" value={this.state.FIDSeries} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_CATEGORIES", this.props.translations, "Categoria")} id="FIDCategory" urlData="/BOP/Category" value={this.state.FIDCategory} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_IDENTITY", this.props.translations, "Anagrafiche")} id="FIDIdentity" urlData="/IR/GetIRSelect" value={this.state.FIDIdentity} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" subtitleKey="Address" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_TIPOLOGIES", this.props.translations, "Tipologia")} id="FIDTipology" urlData="/Base/GetIRTypologies" value={this.state.FIDTipology} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_DISTRUBUSION", this.props.translations, "Distribuzioni")} id="FIDDistribution" urlData="/IR/DistributionList" value={this.state.FIDDistribution} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_COUNTRY", this.props.translations, "Nazione")} id="FIDCountry" urlData="/Base/GetIRCountries" value={this.state.FIDCountry} onChange={this.handleSelectChange} idKey="ID" valueKey="Country" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_REGIONS", this.props.translations, "Regioni")} id="FIDRegion" urlData="/Base/GetIRRegions" value={this.state.FIDRegion} onChange={this.handleSelectChange} idKey="ID" valueKey="Region" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMAS_PROVINCE", this.props.translations, "Provincia")} id="FIDProvince" urlData="/Base/GetIRProvinces" value={this.state.FIDProvince} onChange={this.handleSelectChange} idKey="ID" valueKey="Province" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_CITIES", this.props.translations, "Citta")} id="FIDCity" urlData="/Base/GetIRCities" value={this.state.FIDCity} onChange={this.handleSelectChange} idKey="ID" valueKey="City" /></Grid>             
                {paramsForLastIndex}
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={this.loadData}>{getTranslation(7, "BI_BUTTON_LOADDATA", this.props.translations, "Carica Dati")} </XButton></Grid>
            </Grid>
        );
    }

    //Update Events renderName
    renderTop10 = () => {
        var { classes } = this.props;
        var { top10, viewTypeTOP10, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingTOP10Products  } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        if(catalogoSetOpen){
            mainContentWidth -= drawerWidth;
        }

        var content = undefined;
        if (top10 && top10.length > 0) {
            if (viewTypeTOP10 === "chart") {
            
                    content = (
                        <Fragment>
                            <BarChart width={mainContentWidth} height={250} data={top10} style={{ display: 'inline-block' }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Code" />
                                <YAxis />
                                <Tooltip formatter={format} />
                                <Legend />
                                <Bar dataKey="Views" fill="#ffbf80" onClick={this.onClickTest} >
                                    {
                                        top10 && top10.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000} />
                                        ))
                                    }
                                </Bar>
                            </BarChart>
                        </Fragment>
                    );
            }
        } else {
                content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        var actions = [];

        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeTOP10", "chart"), selected: viewTypeTOP10 === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeTOP10", "grid"), selected: viewTypeTOP10 === "grid" });


        if(isLoadingTOP10Products) {
            content = <XOXLoader height={'250px'}/>
        }

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_CodePerViews";
        var dataRoute = `/api/bi/GetTOP10ViewGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelTOP10View" className={classes.container}>
                <XGrid
                    innerRef={this.grdTOP10BOP}
                    containerID="panelTOP10View"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }


    renderTOP10CodiciCatalogo = () => {
        var { classes } = this.props;
        var { CodiciCatalogo, viewTypeTOP10Catalogo, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, 
            FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingCatalogCodes } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        //var width = this.getWidth();

        if(catalogoSetOpen){
            mainContentWidth -= drawerWidth;
        }

       


        var content = undefined;
        if(CodiciCatalogo && CodiciCatalogo.length > 0 ) {
            if (viewTypeTOP10Catalogo === "chart") {
                content = (
                    <BarChart width={mainContentWidth} height={250} data={CodiciCatalogo} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="CatalogCode" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Views" fill="#D3D61E">
                            {
                                CodiciCatalogo && CodiciCatalogo.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        var actions = [];

        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeTOP10Catalogo", "chart"), selected: viewTypeTOP10Catalogo === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeTOP10Catalogo", "grid"), selected: viewTypeTOP10Catalogo === "grid" });
        if(isLoadingCatalogCodes) {
            content = <XOXLoader height={'250px'}/>
        }

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_Top10CodiciCatalogo";
        var dataRoute = `/api/bi/GetTOP10CodiciCatalogoGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelTOP10Codici" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_Top10Catalogue}
                    containerID="panelTOP10Codici"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    // Buy Renders Start
    renderbuyCatalogCodeDOC = () => {
        var { classes } = this.props;
        var { buyCatalogCodeDOC, viewTypebuyCatalogCodeDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, 
            FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingBuyCatalogCodes } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        //var width = this.getWidth();

        if(catalogoSetOpen){
            mainContentWidth -= drawerWidth;
        }



        var content = undefined;
        if(buyCatalogCodeDOC && buyCatalogCodeDOC.length > 0 ) {
            if (viewTypebuyCatalogCodeDOC === "chart") {
                content = (
                    <BarChart width={mainContentWidth} height={250} data={buyCatalogCodeDOC} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Codice catalogo" />
                        <YAxis yAxisId="left" orientation="left" stroke={colors[0]} />
                        <YAxis yAxisId="right" orientation="right" stroke={colors[1]} />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="Quantità" fill={colors[0]}></Bar>
                        <Bar yAxisId="right" dataKey="Totale imponibile" fill={colors[1]}>
                        </Bar>
                    </BarChart>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        var actions = [];

        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypebuyCatalogCodeDOC", "chart"), selected: viewTypebuyCatalogCodeDOC === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypebuyCatalogCodeDOC", "grid"), selected: viewTypebuyCatalogCodeDOC === "grid" });


        if(isLoadingBuyCatalogCodes) {
            content = <XOXLoader height={'250px'}/>
        }

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPCATALOGCODE";
        var dataRoute = `/api/BIDocs/GetTop10CatalogCodeGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelBuySellCatalogCode" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPCATALOGCODE}
                    containerID="panelBuySellCatalogCode"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderbuyCodeDOC = () => {
        var { classes } = this.props;
        var { buyCodeDOC, viewTypebuyCodeDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, 
            FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingBuyTOP10Products } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        //var width = this.getWidth();

        if(catalogoSetOpen){
            mainContentWidth -= drawerWidth;
        }



        var content = undefined;
        if(buyCodeDOC && buyCodeDOC.length > 0 ) {
            if (viewTypebuyCodeDOC === "chart") {
                content = (
                    <BarChart width={mainContentWidth} height={250} data={buyCodeDOC} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Codice" />
                        <YAxis yAxisId="left" orientation="left" stroke={colors[0]} />
                        <YAxis yAxisId="right" orientation="right" stroke={colors[1]} />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="Quantità" fill={colors[0]}></Bar>
                        <Bar yAxisId="right" dataKey="Totale imponibile" fill={colors[1]}>
                        </Bar>
                    </BarChart>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        
        var actions = [];


        if(isLoadingBuyTOP10Products) {
            content = <XOXLoader height={'250px'}/>
        }

        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypebuyCodeDOC", "chart"), selected: viewTypebuyCodeDOC === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypebuyCodeDOC", "grid"), selected: viewTypebuyCodeDOC === "grid" });


        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPCODE";
        var dataRoute = `/api/BIDocs/GetTop10CodeGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelBuySellCode" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPCODE}
                    containerID="panelBuySellCode"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderbuyIdentityDOC = () => {
        var { classes } = this.props;
        var { buyIdentityDOC, viewTypebuyIdentityDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, 
            FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingBuyTOP10Products } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        //var width = this.getWidth();

        if(catalogoSetOpen){
            mainContentWidth -= drawerWidth;
        }

        var content = undefined;
        if(buyIdentityDOC && buyIdentityDOC.length > 0 ) {
            if (viewTypebuyIdentityDOC === "chart") {
                content = (
                    <BarChart width={mainContentWidth} height={250} data={buyIdentityDOC} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Codice" />
                        <YAxis yAxisId="left" orientation="left" stroke={colors[0]} />
                        <YAxis yAxisId="right" orientation="right" stroke={colors[1]} />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="Quantità" fill={colors[0]}></Bar>
                        <Bar yAxisId="right" dataKey="Totale imponibile" fill={colors[1]}>
                        </Bar>
                    </BarChart>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        if(isLoadingBuyTOP10Products) {
            content = <XOXLoader height={'250px'}/>
        }

        var actions = [];

        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypebuyIdentityDOC", "chart"), selected: viewTypebuyIdentityDOC === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypebuyIdentityDOC", "grid"), selected: viewTypebuyIdentityDOC === "grid" });


        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPIdentity";
        var dataRoute = `/api/BIDocs/GetTop10IdentityGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelBuySellIdentity" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPIdentity}
                    containerID="panelBuySellIdentity"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderbuyPercentageLines = () => {
        var { classes } = this.props;
        var { buyPercentageLineDOC, viewTypebuyPercentageLineDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingBuyPercentagePerLine  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;

        
        if(buyPercentageLineDOC && buyPercentageLineDOC.length > 0 ) 
        {
            if (viewTypebuyPercentageLineDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Line
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Line || getTranslation(7, "BI_RESULT_NOLINE", this.props.translations, "Senza Linea")} ${value + "%"}`}</text>

                    </g>
                );
            };
            


                const renderLegend = (id, value, ) => {

                    return <Fragment><span>{value.payload.Line || getTranslation(7, "BI_RESULT_NOLINE", this.props.translations, "Senza Linea")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage + "%"}</span></Fragment>;

                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={buyPercentageLineDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexbuyPercentageLineDOC}
                                    activeShape={renderActiveShape}
                                    data={buyPercentageLineDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("buyPercentageLineDOC")}
                                    onClick={this.onClickUpdateTortaFIDLine}>
                                    {
                                        buyPercentageLineDOC && buyPercentageLineDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingBuyPercentagePerLine) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypebuyPercentageLineDOC", "torta"), selected: viewTypebuyPercentageLineDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypebuyPercentageLineDOC", "grid"), selected: viewTypebuyPercentageLineDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPLine";
        var dataRoute = `/api/BIDocs/GetPercentageLineGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelLinesgrdBIBuySellTOPLine" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPLine}
                    containerID="panelLinesgrdBIBuySellTOPLine"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    renderbuyPercentageSeries = () => {
        var { classes } = this.props;
        var { buyPercentageSeriesDOC, viewTypebuyPercentageSeriesDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingBuyPercentagePerSeries  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(buyPercentageSeriesDOC && buyPercentageSeriesDOC.length > 0 ) 
        {
            if (viewTypebuyPercentageSeriesDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Series
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{` ${Series || getTranslation(7, "BI_RESULT_NOSERIES", this.props.translations, "Senza Serie")} ${value + "%"}`}</text>

                    </g>
                );
            };
            


                const renderLegend = (id, value, ) => {
                    return <Fragment> <span>{value.payload.Series || getTranslation(7, "BI_RESULT_NOSERIES", this.props.translations, "Senza Serie")} </span><span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage + "%"}</span></Fragment>;

                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={buyPercentageSeriesDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexbuyPercentageSeriesDOC}
                                    activeShape={renderActiveShape}
                                    data={buyPercentageSeriesDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("buyPercentageSeriesDOC")}
                                    onClick={this.onClickUpdateTortaFIDLine}>
                                    {
                                        buyPercentageSeriesDOC && buyPercentageSeriesDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingBuyPercentagePerSeries) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypebuyPercentageSeriesDOC", "torta"), selected: viewTypebuyPercentageSeriesDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypebuyPercentageSeriesDOC", "grid"), selected: viewTypebuyPercentageSeriesDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPSeries";
        var dataRoute = `/api/BIDocs/GetPercentageSeriesGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelLinesgrdBIBuySellTOPSeries" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPSeries}
                    containerID="panelLinesgrdBIBuySellTOPSeries"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    renderbuyPercentageCategories= () => {
        var { classes } = this.props;
        var { buyPercentageCategoriesDOC, viewTypebuyPercentageCategoriesDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingBuyPercentagePerCategories  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(buyPercentageCategoriesDOC && buyPercentageCategoriesDOC.length > 0 ) 
        {
            if (viewTypebuyPercentageCategoriesDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Category
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Category || getTranslation(7, "BI_RESULT_NOCATEGORIES", this.props.translations, "Senza Categoria")} ${value + "%"}`}</text>

                    </g>
                );
            };
            


                const renderLegend = (id, value, ) => {
                    return <Fragment><span>{value.payload.Category || getTranslation(7, "BI_RESULT_NOCATEGORIES", this.props.translations, "Senza Categoria")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage + "%"}</span></Fragment>;

                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={buyPercentageCategoriesDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexbuyPercentageCategoriesDOC}
                                    activeShape={renderActiveShape}
                                    data={buyPercentageCategoriesDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("buyPercentageCategoriesDOC")}
                                    onClick={this.onClickUpdateTortaFIDCategories}>
                                    {
                                        buyPercentageCategoriesDOC && buyPercentageCategoriesDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingBuyPercentagePerCategories) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypebuyPercentageCategoriesDOC", "torta"), selected: viewTypebuyPercentageCategoriesDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypebuyPercentageCategoriesDOC", "grid"), selected: viewTypebuyPercentageCategoriesDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPCategories";
        var dataRoute = `/api/BIDocs/GetPercentageCategoriesGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelLinesgrdBIBuySellTOPCategories" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPCategories}
                    containerID="panelLinesgrdBIBuySellTOPCategories"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    renderbuyPercentageCountry = () => {
        var { classes } = this.props;
        var { buyPercentageCountryDOC, viewTypebuyPercentageCountryDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingBuyPercentagePerCountry  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(buyPercentageCountryDOC && buyPercentageCountryDOC.length > 0 ) 
        {
            if (viewTypebuyPercentageCountryDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Country
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Country || getTranslation(7, "BI_RESULT_NONATION", this.props.translations, "Senza Nazione")} ${value + "%"}`}</text>

                    </g>
                );
            };
            


                const renderLegend = (id, value, ) => {
                    return <Fragment> <span>{value.payload.Country || getTranslation(7, "BI_RESULT_NONATION", this.props.translations, "Senza Nazione")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;

                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={buyPercentageCountryDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexbuyPercentageCountryDOC}
                                    activeShape={renderActiveShape}
                                    data={buyPercentageCountryDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("buyPercentageCountryDOC")}
                                    onClick={this.onClickUpdateTortaFIDCountry}>
                                    {
                                        buyPercentageCountryDOC && buyPercentageCountryDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingBuyPercentagePerCountry) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypebuyPercentageCountryDOC", "torta"), selected: viewTypebuyPercentageCountryDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypebuyPercentageCountryDOC", "grid"), selected: viewTypebuyPercentageCountryDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPCountry";
        var dataRoute = `/api/BIDocs/GetPercentageCountryGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelLinesgrdBIBuySellTOPCountry" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPCountry}
                    containerID="panelLinesgrdBIBuySellTOPCountry"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    renderbuyPercentageProvince = () => {
        var { classes } = this.props;
        var { buyPercentageProvinceDOC, viewTypebuyPercentageProvinceDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingBuyPercentagePerCountry  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(buyPercentageProvinceDOC && buyPercentageProvinceDOC.length > 0 ) 
        {
            if (viewTypebuyPercentageProvinceDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Province
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Province || getTranslation(7, "BI_RESULT_NOPROVINCE", this.props.translations, "Senza Provincia")} ${value + "%"}`}</text>

                    </g>
                );
            };
            
                const renderLegend = (id, value, ) => {
                    return <Fragment> <span>{value.payload.Province || getTranslation(7, "BI_RESULT_NOPROVINCE", this.props.translations, "Senza Provincia")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={buyPercentageProvinceDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexbuyPercentageProvinceDOC}
                                    activeShape={renderActiveShape}
                                    data={buyPercentageProvinceDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("buyPercentageProvinceDOC")}
                                    onClick={this.onClickUpdateTortaFIDProvince}>
                                    {
                                        buyPercentageProvinceDOC && buyPercentageProvinceDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingBuyPercentagePerCountry) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypebuyPercentageProvinceDOC", "torta"), selected: viewTypebuyPercentageProvinceDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypebuyPercentageProvinceDOC", "grid"), selected: viewTypebuyPercentageProvinceDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPProvince";
        var dataRoute = `/api/BIDocs/GetPercentageProvinceGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelLinesgrdBIBuySellTOPProvince" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPProvince}
                    containerID="panelLinesgrdBIBuySellTOPProvince"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    renderbuyPercentageRegions = () => {
        var { classes } = this.props;
        var { buyPercentageRegionsDOC, viewTypebuyPercentageRegionsDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingBuyPercentagePerRegions  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(buyPercentageRegionsDOC && buyPercentageRegionsDOC.length > 0 ) 
        {
            if (viewTypebuyPercentageRegionsDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Region
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Region || getTranslation(7, "BI_RESULT_NOREGION", this.props.translations, "Senza Regione")} ${value + "%"}`}</text>

                    </g>
                );
            };
            
                const renderLegend = (id, value, ) => {
                    return <Fragment> <span>{value.payload.Region || getTranslation(7, "BI_RESULT_NOREGION", this.props.translations, "Senza Regione")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;


                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={buyPercentageRegionsDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexbuyPercentageRegionsDOC}
                                    activeShape={renderActiveShape}
                                    data={buyPercentageRegionsDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("buyPercentageRegionsDOC")}
                                    onClick={this.onClickUpdateTortaFIDRegion}>
                                    {
                                        buyPercentageRegionsDOC && buyPercentageRegionsDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingBuyPercentagePerRegions) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypebuyPercentageRegionsDOC", "torta"), selected: viewTypebuyPercentageRegionsDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypebuyPercentageRegionsDOC", "grid"), selected: viewTypebuyPercentageRegionsDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPRegions";
        var dataRoute = `/api/BIDocs/GetPercentageRegionsGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelLinesgrdBIBuySellTOPRegions" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPRegions}
                    containerID="panelLinesgrdBIBuySellTOPRegions"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }
    renderbuyPercentageCity = () => {
        var { classes } = this.props;
        var { buyPercentageCitiesDOC, viewTypebuyPercentageCitiesDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingBuyPercentagePerCity  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(buyPercentageCitiesDOC && buyPercentageCitiesDOC.length > 0 ) 
        {
            if (viewTypebuyPercentageCitiesDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, City
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{City || getTranslation(7, "BI_RESULT_NOCITY", this.props.translations, "Senza Citta")} ${value + "%"}</text>

                    </g>
                );
            };
            
                const renderLegend = (id, value, ) => {
                    return <Fragment> <span>{value.payload.City || getTranslation(7, "BI_RESULT_NOCITY", this.props.translations, "Senza Citta")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={buyPercentageCitiesDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexbuyPercentageCitiesDOC}
                                    activeShape={renderActiveShape}
                                    data={buyPercentageCitiesDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("buyPercentageCitiesDOC")}
                                    onClick={this.onClickUpdateTortaFIDCity}>
                                    {
                                        buyPercentageCitiesDOC && buyPercentageCitiesDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingBuyPercentagePerCity) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypebuyPercentageCitiesDOC", "torta"), selected: viewTypebuyPercentageCitiesDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypebuyPercentageCitiesDOC", "grid"), selected: viewTypebuyPercentageCitiesDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPCity";
        var dataRoute = `/api/BIDocs/GetPercentageCitiesGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelLinesgrdBIBuySellTOPProvince" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPProvince}
                    containerID="panelLinesgrdBIBuySellTOPProvince"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    // Buy Renders End 

    // sell Renders Start
    rendersellCatalogCodeDOC = () => {
        var { classes } = this.props;
        var { sellCatalogCodeDOC, viewTypesellCatalogCodeDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, 
            FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingsellCatalogCodes } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        //var width = this.getWidth();

        if(catalogoSetOpen){
            mainContentWidth -= drawerWidth;
        }



        var content = undefined;
        if(sellCatalogCodeDOC && sellCatalogCodeDOC.length > 0 ) {
            if (viewTypesellCatalogCodeDOC === "chart") {
                content = (
                    <BarChart width={mainContentWidth} height={250} data={sellCatalogCodeDOC} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Codice catalogo" />
                        <YAxis yAxisId="left" orientation="left" stroke={colors[0]} />
                        <YAxis yAxisId="right" orientation="right" stroke={colors[1]} />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="Quantità" fill={colors[0]}></Bar>
                        <Bar yAxisId="right" dataKey="Totale imponibile" fill={colors[1]}>
                        </Bar>
                    </BarChart>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        var actions = [];

        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypesellCatalogCodeDOC", "chart"), selected: viewTypesellCatalogCodeDOC === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypesellCatalogCodeDOC", "grid"), selected: viewTypesellCatalogCodeDOC === "grid" });


        if(isLoadingsellCatalogCodes) {
            content = <XOXLoader height={'250px'}/>
        }

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPCATALOGCODE";
        var dataRoute = `/api/BIDocsSell/GetTop10CatalogCodeGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            dataRoute += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            dataRoute += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            dataRoute += `&FIDOwner=${FIDOwner}`;
        }


        return (
            <div id="panelSellCatalogCode" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPCATALOGCODE}
                    containerID="panelSellCatalogCode"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    rendersellCodeDOC = () => {
        var { classes } = this.props;
        var { sellCodeDOC, viewTypesellCodeDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, 
            FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingsellTOP10Products } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        //var width = this.getWidth();

        if(catalogoSetOpen){
            mainContentWidth -= drawerWidth;
        }



        var content = undefined;
        if(sellCodeDOC && sellCodeDOC.length > 0 ) {
            if (viewTypesellCodeDOC === "chart") {
                content = (
                    <BarChart width={mainContentWidth} height={250} data={sellCodeDOC} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Codice" />
                        <YAxis yAxisId="left" orientation="left" stroke={colors[0]} />
                        <YAxis yAxisId="right" orientation="right" stroke={colors[1]} />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="Quantità" fill={colors[0]}></Bar>
                        <Bar yAxisId="right" dataKey="Totale imponibile" fill={colors[1]}>
                        </Bar>
                    </BarChart>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        
        var actions = [];


        if(isLoadingsellTOP10Products) {
            content = <XOXLoader height={'250px'}/>
        }

        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypesellCodeDOC", "chart"), selected: viewTypesellCodeDOC === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypesellCodeDOC", "grid"), selected: viewTypesellCodeDOC === "grid" });


        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPCODE";
        var dataRoute = `/api/BIDocsSell/GetTop10CodeGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            dataRoute += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            dataRoute += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            dataRoute += `&FIDOwner=${FIDOwner}`;
        }


        return (
            <div id="panelSellCode" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPCODE}
                    containerID="panelSellCode"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    rendersellIdentityDOC = () => {
        var { classes } = this.props;
        var { sellIdentityDOC, viewTypesellIdentityDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, 
            FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingsellTOP10Products } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        //var width = this.getWidth();

        if(catalogoSetOpen){
            mainContentWidth -= drawerWidth;
        }

        var content = undefined;
        if(sellIdentityDOC && sellIdentityDOC.length > 0 ) {
            if (viewTypesellIdentityDOC === "chart") {
                content = (
                    <BarChart width={mainContentWidth} height={250} data={sellIdentityDOC} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Code" />
                        <YAxis yAxisId="left" orientation="left" stroke={colors[0]} />
                        <YAxis yAxisId="right" orientation="right" stroke={colors[1]} />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="Quantità" fill={colors[0]}></Bar>
                        <Bar yAxisId="right" dataKey="Totale imponibile" fill={colors[1]}>
                        </Bar>
                    </BarChart>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        if(isLoadingsellTOP10Products) {
            content = <XOXLoader height={'250px'}/>
        }

        var actions = [];

        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypesellIdentityDOC", "chart"), selected: viewTypesellIdentityDOC === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypesellIdentityDOC", "grid"), selected: viewTypesellIdentityDOC === "grid" });


        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPIdentity";
        var dataRoute = `/api/BIDocsSell/GetTop10IdentityGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            dataRoute += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            dataRoute += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            dataRoute += `&FIDOwner=${FIDOwner}`;
        }


        return (
            <div id="panelSellIdentity" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPIdentity}
                    containerID="panelSellIdentity"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    rendersellPercentageLines = () => {
        var { classes } = this.props;
        var { sellPercentageLineDOC, viewTypesellPercentageLineDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingsellPercentagePerLine  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;

        
        if(sellPercentageLineDOC && sellPercentageLineDOC.length > 0 ) 
        {
            if (viewTypesellPercentageLineDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Line
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Line || getTranslation(7, "BI_RESULT_NOLINE", this.props.translations, "Senza Linea")} ${value + "%"}`}</text>

                    </g>
                );
            };
            


                const renderLegend = (id, value, ) => {

                    return <Fragment><span>{value.payload.Line || getTranslation(7, "BI_RESULT_NOLINE", this.props.translations, "Senza Linea")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage + "%"}</span></Fragment>;

                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={sellPercentageLineDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexsellPercentageLineDOC}
                                    activeShape={renderActiveShape}
                                    data={sellPercentageLineDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("sellPercentageLineDOC")}
                                    onClick={this.onClickUpdateTortaFIDLine}>
                                    {
                                        sellPercentageLineDOC && sellPercentageLineDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingsellPercentagePerLine) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypesellPercentageLineDOC", "torta"), selected: viewTypesellPercentageLineDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypesellPercentageLineDOC", "grid"), selected: viewTypesellPercentageLineDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPLine";
        var dataRoute = `/api/BIDocsSell/GetPercentageLineGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            dataRoute += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            dataRoute += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            dataRoute += `&FIDOwner=${FIDOwner}`;
        }

        return (
            <div id="panelLinesgrdBIBuySellTOPLine" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPLine}
                    containerID="panelLinesgrdBIBuySellTOPLine"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    rendersellPercentageSeries = () => {
        var { classes } = this.props;
        var { sellPercentageSeriesDOC, viewTypesellPercentageSeriesDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingsellPercentagePerSeries  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(sellPercentageSeriesDOC && sellPercentageSeriesDOC.length > 0 ) 
        {
            if (viewTypesellPercentageSeriesDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Series
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{` ${Series || getTranslation(7, "BI_RESULT_NOSERIES", this.props.translations, "Senza Serie")} ${value + "%"}`}</text>

                    </g>
                );
            };
            


                const renderLegend = (id, value, ) => {
                    return <Fragment> <span>{value.payload.Series || getTranslation(7, "BI_RESULT_NOSERIES", this.props.translations, "Senza Serie")} </span><span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage + "%"}</span></Fragment>;

                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={sellPercentageSeriesDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexsellPercentageSeriesDOC}
                                    activeShape={renderActiveShape}
                                    data={sellPercentageSeriesDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("sellPercentageSeriesDOC")}
                                    onClick={this.onClickUpdateTortaFIDLine}>
                                    {
                                        sellPercentageSeriesDOC && sellPercentageSeriesDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingsellPercentagePerSeries) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypesellPercentageSeriesDOC", "torta"), selected: viewTypesellPercentageSeriesDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypesellPercentageSeriesDOC", "grid"), selected: viewTypesellPercentageSeriesDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPSeries";
        var dataRoute = `/api/BIDocsSell/GetPercentageSeriesGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            dataRoute += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            dataRoute += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            dataRoute += `&FIDOwner=${FIDOwner}`;
        }

        return (
            <div id="panelLinesgrdBIBuySellTOPSeries" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPSeries}
                    containerID="panelLinesgrdBIBuySellTOPSeries"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    rendersellPercentageCategories= () => {
        var { classes } = this.props;
        var { sellPercentageCategoriesDOC, viewTypesellPercentageCategoriesDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingsellPercentagePerCategories  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(sellPercentageCategoriesDOC && sellPercentageCategoriesDOC.length > 0 ) 
        {
            if (viewTypesellPercentageCategoriesDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Category
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Category || getTranslation(7, "BI_RESULT_NOCATEGORIES", this.props.translations, "Senza Categoria")} ${value + "%"}`}</text>

                    </g>
                );
            };
            


                const renderLegend = (id, value, ) => {
                    return <Fragment><span>{value.payload.Category || getTranslation(7, "BI_RESULT_NOCATEGORIES", this.props.translations, "Senza Categoria")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage + "%"}</span></Fragment>;

                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={sellPercentageCategoriesDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexsellPercentageCategoriesDOC}
                                    activeShape={renderActiveShape}
                                    data={sellPercentageCategoriesDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("sellPercentageCategoriesDOC")}
                                    onClick={this.onClickUpdateTortaFIDCategories}>
                                    {
                                        sellPercentageCategoriesDOC && sellPercentageCategoriesDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingsellPercentagePerCategories) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypesellPercentageCategoriesDOC", "torta"), selected: viewTypesellPercentageCategoriesDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypesellPercentageCategoriesDOC", "grid"), selected: viewTypesellPercentageCategoriesDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPCategories";
        var dataRoute = `/api/BIDocsSell/GetPercentageCategoriesGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            dataRoute += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            dataRoute += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            dataRoute += `&FIDOwner=${FIDOwner}`;
        }

        return (
            <div id="panelLinesgrdBIBuySellTOPCategories" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPCategories}
                    containerID="panelLinesgrdBIBuySellTOPCategories"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    rendersellPercentageCountry = () => {
        var { classes } = this.props;
        var { sellPercentageCountryDOC, viewTypesellPercentageCountryDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingsellPercentagePerCountry  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(sellPercentageCountryDOC && sellPercentageCountryDOC.length > 0 ) 
        {
            if (viewTypesellPercentageCountryDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Country
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Country || getTranslation(7, "BI_RESULT_NONATION", this.props.translations, "Senza Nazione")} ${value + "%"}`}</text>

                    </g>
                );
            };
            


                const renderLegend = (id, value, ) => {
                    return <Fragment> <span>{value.payload.Country || getTranslation(7, "BI_RESULT_NONATION", this.props.translations, "Senza Nazione")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;

                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={sellPercentageCountryDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexsellPercentageCountryDOC}
                                    activeShape={renderActiveShape}
                                    data={sellPercentageCountryDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("sellPercentageCountryDOC")}
                                    onClick={this.onClickUpdateTortaFIDCountry}>
                                    {
                                        sellPercentageCountryDOC && sellPercentageCountryDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingsellPercentagePerCountry) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypesellPercentageCountryDOC", "torta"), selected: viewTypesellPercentageCountryDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypesellPercentageCountryDOC", "grid"), selected: viewTypesellPercentageCountryDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPCountry";
        var dataRoute = `/api/BIDocsSell/GetPercentageCountryGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            dataRoute += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            dataRoute += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            dataRoute += `&FIDOwner=${FIDOwner}`;
        }

        return (
            <div id="panelLinesgrdBIBuySellTOPCountry" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPCountry}
                    containerID="panelLinesgrdBIBuySellTOPCountry"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    rendersellPercentageProvince = () => {
        var { classes } = this.props;
        var { sellPercentageProvinceDOC, viewTypesellPercentageProvinceDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingsellPercentagePerCountry  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(sellPercentageProvinceDOC && sellPercentageProvinceDOC.length > 0 ) 
        {
            if (viewTypesellPercentageProvinceDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Province
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Province || getTranslation(7, "BI_RESULT_NOPROVINCE", this.props.translations, "Senza Provincia")} ${value + "%"}`}</text>

                    </g>
                );
            };
            
                const renderLegend = (id, value, ) => {
                    return <Fragment> <span>{value.payload.Province || getTranslation(7, "BI_RESULT_NOPROVINCE", this.props.translations, "Senza Provincia")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={sellPercentageProvinceDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexsellPercentageProvinceDOC}
                                    activeShape={renderActiveShape}
                                    data={sellPercentageProvinceDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("sellPercentageProvinceDOC")}
                                    onClick={this.onClickUpdateTortaFIDProvince}>
                                    {
                                        sellPercentageProvinceDOC && sellPercentageProvinceDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingsellPercentagePerCountry) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypesellPercentageProvinceDOC", "torta"), selected: viewTypesellPercentageProvinceDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypesellPercentageProvinceDOC", "grid"), selected: viewTypesellPercentageProvinceDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPProvince";
        var dataRoute = `/api/BIDocsSell/GetPercentageProvinceGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            dataRoute += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            dataRoute += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            dataRoute += `&FIDOwner=${FIDOwner}`;
        }
        return (
            <div id="panelLinesgrdBIBuySellTOPProvince" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPProvince}
                    containerID="panelLinesgrdBIBuySellTOPProvince"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    rendersellPercentageRegions = () => {
        var { classes } = this.props;
        var { sellPercentageRegionsDOC, viewTypesellPercentageRegionsDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingsellPercentagePerRegions  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(sellPercentageRegionsDOC && sellPercentageRegionsDOC.length > 0 ) 
        {
            if (viewTypesellPercentageRegionsDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, Region
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Region || getTranslation(7, "BI_RESULT_NOREGION", this.props.translations, "Senza Regione")} ${value + "%"}`}</text>

                    </g>
                );
            };
            
                const renderLegend = (id, value, ) => {
                    return <Fragment> <span>{value.payload.Region || getTranslation(7, "BI_RESULT_NOREGION", this.props.translations, "Senza Regione")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;


                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={sellPercentageRegionsDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexsellPercentageRegionsDOC}
                                    activeShape={renderActiveShape}
                                    data={sellPercentageRegionsDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("sellPercentageRegionsDOC")}
                                    onClick={this.onClickUpdateTortaFIDRegion}>
                                    {
                                        sellPercentageRegionsDOC && sellPercentageRegionsDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingsellPercentagePerRegions) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypesellPercentageRegionsDOC", "torta"), selected: viewTypesellPercentageRegionsDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypesellPercentageRegionsDOC", "grid"), selected: viewTypesellPercentageRegionsDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPRegions";
        var dataRoute = `/api/BIDocsSell/GetPercentageRegionsGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            dataRoute += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            dataRoute += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            dataRoute += `&FIDOwner=${FIDOwner}`;
        }

        return (
            <div id="panelLinesgrdBIBuySellTOPRegions" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPRegions}
                    containerID="panelLinesgrdBIBuySellTOPRegions"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }
    rendersellPercentageCity = () => {
        var { classes } = this.props;
        var { sellPercentageCitiesDOC, viewTypesellPercentageCitiesDOC, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingsellPercentagePerCity  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(sellPercentageCitiesDOC && sellPercentageCitiesDOC.length > 0 ) 
        {
            if (viewTypesellPercentageCitiesDOC == "torta") 
            {
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Percetange, City
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{City || getTranslation(7, "BI_RESULT_NOCITY", this.props.translations, "Senza Citta")} ${value + "%"}</text>

                    </g>
                );
            };
            
                const renderLegend = (id, value, ) => {
                    return <Fragment> <span>{value.payload.City || getTranslation(7, "BI_RESULT_NOCITY", this.props.translations, "Senza Citta")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
                }

                var optimalWidth = 1500;
                var optimalHeight = 400;
                var aspectRatio = optimalWidth / optimalHeight;

                var useWidth = mainContentWidth;
                if(catalogoSetOpen){
                    useWidth -= drawerWidth;
                }
                
                var multiplier = optimalWidth / useWidth;
                //var useHeight = useWidth / aspectRatio;
                var useHeight = optimalHeight / multiplier;
        
                var legentWidth = useWidth * 0.3;

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            <PieChart width={useWidth} height={useHeight} data={sellPercentageCitiesDOC}>
                                <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                                <Pie
                                    activeIndex={this.state.activeIndexsellPercentageCitiesDOC}
                                    activeShape={renderActiveShape}
                                    data={sellPercentageCitiesDOC}
                                    cx={600 / multiplier}
                                    cy={200 / multiplier}
                                    innerRadius={100 / multiplier}
                                    outerRadius={130 / multiplier}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter= {this.onPieEnter("sellPercentageCitiesDOC")}
                                    onClick={this.onClickUpdateTortaFIDCity}>
                                    {
                                        sellPercentageCitiesDOC && sellPercentageCitiesDOC.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>

                            </PieChart>

                        </div>
                    </div>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingsellPercentagePerCity) {
            content = <XOXLoader height={'250px'}/>
        }


        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypesellPercentageCitiesDOC", "torta"), selected: viewTypesellPercentageCitiesDOC === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypesellPercentageCitiesDOC", "grid"), selected: viewTypesellPercentageCitiesDOC === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBuySellTOPCity";
        var dataRoute = `/api/BIDocsSell/GetPercentageCitiesGridDOC?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            dataRoute += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            dataRoute += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            dataRoute += `&FIDOwner=${FIDOwner}`;
        }
        return (
            <div id="panelLinesgrdBIBuySellTOPProvince" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBuySellTOPProvince}
                    containerID="panelLinesgrdBIBuySellTOPProvince"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }



    // sell Renders End 

    // Buy Get Data Start
    getbuyCatalogCodeDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, isLoadingBuyCatalogCodes, FIDBrand, FIDDocumentSubType, FIDOwner,} = this.state;
        var url = `/api/BIDocs/GetTop10CatalogCodeDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingBuyCatalogCodes: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
                
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerBuyTop10CatalogCodes = new AbortController();
        var signal = controllerBuyTop10CatalogCodes.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ buyCatalogCodeDOC: result, isLoadingBuyCatalogCodes: false });
    }


    getbuyCodeDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingBuyTOP10Products } = this.state;
        var url = `/api/BIDocs/GetTop10CodeDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingBuyTOP10Products: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }
        
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerBuyTop10Products = new AbortController();
        var signal = controllerBuyTop10Products.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ buyCodeDOC: result, isLoadingBuyTOP10Products: false });
    }

    getbuyIdentityDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingBuyPercentagePerTOP10Master } = this.state;
        var url = `/api/BIDocs/GetTop10IdentityDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingBuyPercentagePerTOP10Master: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerBuyTop10Identity = new AbortController();
        var signal = controllerBuyTop10Identity.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ buyIdentityDOC: result, isLoadingBuyPercentagePerTOP10Master: false });
    }

    getbuyPercentageLineDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingBuyPercentagePerLine } = this.state;
        var url = `/api/BIDocs/GetPercentageLineDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingBuyPercentagePerLine: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerBuyPercentageForLine = new AbortController();
        var signal = controllerBuyPercentageForLine.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ buyPercentageLineDOC: result, isLoadingBuyPercentagePerLine: false });
    }

    getbuyPercentageSeriesDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingBuyPercentagePerSeries } = this.state;
        var url = `/api/BIDocs/GetPercentageSeriesDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingBuyPercentagePerSeries: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerBuyPercentageForSeries = new AbortController();
        var signal = controllerBuyPercentageForSeries.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ buyPercentageSeriesDOC: result, isLoadingBuyPercentagePerSeries: false });
    }

    getbuyPercentageCategoriesDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingBuyPercentagePerCategories } = this.state;
        var url = `/api/BIDocs/GetPercentageCategoriesDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingBuyPercentagePerCategories: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }


        controllerBuyPercentageForCategories = new AbortController();
        var signal = controllerBuyPercentageForCategories.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ buyPercentageCategoriesDOC: result, isLoadingBuyPercentagePerCategories: false });
    }

    getbuyPercentageCountryDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingBuyPercentagePerCountry } = this.state;
        var url = `/api/BIDocs/GetPercentageCountryDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingBuyPercentagePerCountry: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerBuyPercentageForNazione = new AbortController();
        var signal = controllerBuyPercentageForNazione.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ buyPercentageCountryDOC: result, isLoadingBuyPercentagePerCountry: false });
    }

    
    getbuyPercentageRegionsDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingBuyPercentagePerRegions } = this.state;
        var url = `/api/BIDocs/GetPercentageRegionsDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingBuyPercentagePerRegions: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerBuyPercentageForRegions = new AbortController();
        var signal = controllerBuyPercentageForRegions.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ buyPercentageRegionsDOC: result, isLoadingBuyPercentagePerRegions: false });
    }

    getbuyPercentageProvinceDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingBuyPercentagePerProvince } = this.state;
        var url = `/api/BIDocs/GetPercentageProvinceDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingBuyPercentagePerProvince: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }
        controllerBuyPercentageForProvince = new AbortController();
        var signal = controllerBuyPercentageForProvince.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ buyPercentageProvinceDOC: result, isLoadingBuyPercentagePerProvince: false });
    }

    getbuyPercentageCitiesDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingBuyPercentagePerCity } = this.state;
        var url = `/api/BIDocs/GetPercentageCitiesDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingBuyPercentagePerCity: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }
        controllerBuyPercentageForCity = new AbortController();
        var signal = controllerBuyPercentageForCity.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
             }
         })
        this.setState({ buyPercentageCitiesDOC: result, isLoadingBuyPercentagePerCity: false });
    }
    // Buy Get Data End

    // Sell Get Data Start
    getsellCatalogCodeDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingSellCatalogCodes } = this.state;
        var url = `/api/BIDocsSell/GetTop10CatalogCodeDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingSellCatalogCodes: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        var result = await fetch(url).then(res => res.json());
        this.setState({ sellCatalogCodeDOC: result, isLoadingSellCatalogCodes: false });
    }

    getsellCodeDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingSellTOP10Products } = this.state;
        var url = `/api/BIDocsSell/GetTop10CodeDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingSellTOP10Products: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        var result = await fetch(url).then(res => res.json());
        this.setState({ sellCodeDOC: result, isLoadingSellTOP10Products: false });
    }

    getsellIdentityDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingSellPercentagePerTOP10Master } = this.state;
        var url = `/api/BIDocsSell/GetTop10IdentityDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingSellPercentagePerTOP10Master: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        var result = await fetch(url).then(res => res.json());
        this.setState({ sellIdentityDOC: result, isLoadingSellPercentagePerTOP10Master: false });
    }

    getsellPercentageLineDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingSellPercentagePerLine } = this.state;
        var url = `/api/BIDocsSell/GetPercentageLineDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingSellPercentagePerLine: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        var result = await fetch(url).then(res => res.json());
        this.setState({ sellPercentageLineDOC: result, isLoadingSellPercentagePerLine: false });
    }

    getsellPercentageSeriesDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingSellPercentagePerSeries } = this.state;
        var url = `/api/BIDocsSell/GetPercentageSeriesDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingSellPercentagePerSeries: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        var result = await fetch(url).then(res => res.json());
        this.setState({ sellPercentageSeriesDOC: result, isLoadingSellPercentagePerSeries: false });
    }

    getsellPercentageCategoriesDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingSellPercentagePerCategories } = this.state;
        var url = `/api/BIDocsSell/GetPercentageCategoriesDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingSellPercentagePerCategories: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        var result = await fetch(url).then(res => res.json());
        this.setState({ sellPercentageCategoriesDOC: result, isLoadingSellPercentagePerCategories: false });
    }

    getsellPercentageCountryDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingSellPercentagePerCountry } = this.state;
        var url = `/api/BIDocsSell/GetPercentageCountryDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingSellPercentagePerCountry: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        var result = await fetch(url).then(res => res.json());
        this.setState({ sellPercentageCountryDOC: result, isLoadingSellPercentagePerCountry: false });
    }
    
    getsellPercentageRegionsDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingSellPercentagePerRegions } = this.state;
        var url = `/api/BIDocsSell/GetPercentageRegionsDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingSellPercentagePerRegions: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        var result = await fetch(url).then(res => res.json());
        this.setState({ sellPercentageRegionsDOC: result, isLoadingSellPercentagePerRegions: false });
    }

    getsellPercentageProvinceDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingSellPercentagePerProvince } = this.state;
        var url = `/api/BIDocsSell/GetPercentageProvinceDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingSellPercentagePerProvince: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        var result = await fetch(url).then(res => res.json());
        this.setState({ sellPercentageProvinceDOC: result, isLoadingSellPercentagePerProvince: false });
    }

    getsellPercentageCitiesDOC = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingSellPercentagePerCity } = this.state;
        var url = `/api/BIDocsSell/GetPercentageCitiesDOC?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        this.setState({isLoadingSellPercentagePerCity: true});
        if (FIDLine) {
            url += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            url += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            url += `&FIDCountry=${FIDCountry}`;
        }

        if (FIDCategory) {
            url += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        var result = await fetch(url).then(res => res.json());
        this.setState({ sellPercentageCitiesDOC: result, isLoadingSellPercentagePerCity: false });
    }
    // Sell Get Data End

    loadTabData = () => {
        var { tabIndex } = this.state;
        switch (tabIndex) {
            case 0:
                this.loadData();
                break;
            case 1:
                this.loadDataNews();
                break;
            case 2:
                this.loadDataAdv();
                break;
            case 3:
                this.loadDataUserAccess();
                break;
            case 4:
                this.loadDataBuy();
                break;
            case 5:
                this.loadDataSell();
                break;
        }
    }
    
    // Update Parameters
    onClickUpdateTortaFIDLine = (value) => {
        var { FIDLine } = value;

        this.setState(state => {
            if (state.FIDLine === FIDLine) {
                state.FIDLine = undefined;
            }
            else {
                state.FIDLine = FIDLine;
            }
            return state;
        }, this.loadTabData);
        

        // if()
        // console.log("This bitch was triggered but she does not want to work");
    }

    onClickUpdateTortaFIDSeries = (value) => {
        var { FIDSeries } = value;

        this.setState(state => {
            if (state.FIDSeries === FIDSeries) {
                state.FIDSeries = undefined;
            }
            else {
                state.FIDSeries = FIDSeries;
            }
            return state;
        }, this.loadTabData);

    }


    onClickUpdateTortaFIDCategories = (value) => {
        var { FIDCategory } = value;

        this.setState(state => {
            if (state.FIDCategory === FIDCategory) {
                state.FIDCategory = undefined;
            }
            else {
                state.FIDCategory = FIDCategory;
            }
            return state;
        }, this.loadTabData);
    }

    onClickUpdateTortaFIDCountry = (value) => {
        var { FIDCountry } = value;

        this.setState(state => {
            if (state.FIDCountry === FIDCountry) {
                state.FIDCountry = undefined;
            }
            else {
                state.FIDCountry = FIDCountry;
            }
            return state;
        }, this.loadTabData);

    }


    onClickUpdateTortaFIDCountry = (value) => {
        var { FIDCountry } = value;

        this.setState(state => {
            if (state.FIDCountry === FIDCountry) {
                state.FIDCountry = undefined;
            }
            else {
                state.FIDCountry = FIDCountry;
            }
            return state;
        }, this.loadTabData);
    }

    onClickUpdateTortaFIDRegion = (value) => {
        var { FIDRegion } = value;

        this.setState(state => {
            if (state.FIDRegion === FIDRegion) {
                state.FIDRegion = undefined;
            }
            else {
                state.FIDRegion = FIDRegion;
            }
            return state;
        }, this.loadTabData);
    }


    onClickUpdateTortaFIDProvince = (value) => {
        var { FIDProvince } = value;

        this.setState(state => {
            if (state.FIDProvince === FIDProvince) {
                state.FIDProvince = undefined;
            }
            else {
                state.FIDProvince = FIDProvince;
            }
            return state;
        }, this.loadTabData);
    }

    onClickUpdateTortaFIDCity = (value) => {
        var { FIDCity } = value;

        this.setState(state => {
            if (state.FIDCity === FIDCity) {
                state.FIDCity = undefined;
            }
            else {
                state.FIDCity = FIDCity;
            }
            return state;
        }, this.loadTabData);
    }

    onClickUpdateChartFIDIdentity = (value) => {
        var { FIDIdentity } = this.state;

        this.setState(state => {
            if (state.FIDIdentity === value.ID) {
                state.FIDIdentity = undefined;
            }
            else {
                state.FIDIdentity = value.ID;
            }
            return state;
        }, this.loadTabData);
    }


    renderPercentageLines = () => {
        var { classes } = this.props;
        var { Lines, viewTypeLines, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingPercentagePerLine  } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(Lines && Lines.length > 0 ) {
        if (viewTypeLines == "torta") {
            const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Line, Percetange
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 3}
                            outerRadius={outerRadius + 6}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Line || getTranslation(7, "BI_RESULT_NOLINE", this.props.translations, "Senza Linea")} ${value + "%"}`}</text>

                    </g>
                );
            };
            


            const renderLegend = (id, value, ) => {

                return <Fragment><span>{value.payload.Line || getTranslation(7, "BI_RESULT_NOLINE", this.props.translations, "Senza Linea")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage + "%"}</span>

                </Fragment>;

            }

            var optimalWidth = 1500;
            var optimalHeight = 400;
            var aspectRatio = optimalWidth / optimalHeight;

            var useWidth = mainContentWidth;
            if(catalogoSetOpen){
                useWidth -= drawerWidth;
            }
            
            var multiplier = optimalWidth / useWidth;
            //var useHeight = useWidth / aspectRatio;
            var useHeight = optimalHeight / multiplier;
    
            var legentWidth = useWidth * 0.3;

            content = (
                <div className={classes.tortaMain}>
                    <div className={classes.torta}>
                        <PieChart width={useWidth} height={useHeight} data={Lines}>
                            <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                            <Pie
                                activeIndex={this.state.activeIndexLines}
                                activeShape={renderActiveShape}
                                data={Lines}
                                cx={600 / multiplier}
                                cy={200 / multiplier}
                                innerRadius={100 / multiplier}
                                outerRadius={130 / multiplier}
                                fill="#8884d8"
                                dataKey="Percentage"
                                onMouseEnter= {this.onPieEnter("Lines")}
                                onClick={this.onClickUpdateTortaFIDLine}
                            >
                                {
                                    Lines && Lines.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)

                                }
                            </Pie>

                        </PieChart>

                    </div>
                </div>
            );
        }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingPercentagePerLine) {
            content = <XOXLoader height={'250px'}/>
        }
        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeLines", "torta"), selected: viewTypeLines === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeLines", "grid"), selected: viewTypeLines === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_LinePercentage";
        var dataRoute = `/api/bi/GetPercentageLinesGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelLines" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_LineP}
                    containerID="panelLines"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    onPieEnter = (key) => (data, index) => {
        this.setState(state => {
            state[`activeIndex${key}`] = index;
            return state;
        });
    };

    renderPercentageSeries = () => {
        var { classes } = this.props;
        var { Series, viewTypeSeries, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingPercentagePerSeries } = this.state;

        const COLORS = scaleOrdinal(schemeCategory10).range(); //scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        var content = undefined;



        var optimalWidth = 1500;
        var optimalHeight = 400;
        var aspectRatio = optimalWidth / optimalHeight;

        var useWidth = mainContentWidth;
        if(catalogoSetOpen){
            useWidth -= drawerWidth;
        }
        
        var multiplier = optimalWidth / useWidth;
        //var useHeight = useWidth / aspectRatio;
        var useHeight = optimalHeight / multiplier;

        var legentWidth = useWidth * 0.2;

        if(Series && Series.length > 0 ) { 
        if (viewTypeSeries == "torta") {
            const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Series
                } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';

                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{` ${Series || getTranslation(7, "BI_RESULT_NOSERIES", this.props.translations, "Senza Serie")} ${value + "%"}`}</text>

                    </g>
                );
            };


            const renderLegend = (id, value, c) => {

                return <Fragment> <span>{value.payload.Series || getTranslation(7, "BI_RESULT_NOSERIES", this.props.translations, "Senza Serie")} </span><span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage + "%"}</span></Fragment>;
            }



            content = (
                <div className={classes.tortaMain}>
                    <div className={classes.torta}>

                        <PieChart width={useWidth} height={useHeight}>
                            <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                            <Pie
                                activeIndex={this.state.activeIndexSeries}
                                activeShape={renderActiveShape}
                                data={Series}
                                cx={600 / multiplier}
                                cy={200 / multiplier}
                                innerRadius={100 / multiplier}
                                outerRadius={130 / multiplier}
                                fill="#8884d8"
                                dataKey="Percentage"
                                onMouseEnter={this.onPieEnter("Series")}
                                onClick={this.onClickUpdateTortaFIDSeries}
                            >
                                {
                                    Series && Series.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>
                        </PieChart>
                    </div>
                </div>
            );
        }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }
        var actions = [];

        if(isLoadingPercentagePerSeries) {
            content = <XOXLoader height={'250px'}/>
        }
        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeSeries","chart"), selected: viewTypeSeries === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeSeries", "torta"), selected: viewTypeSeries === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeSeries", "grid"), selected: viewTypeSeries === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_SeriesPercetange";
        var dataRoute = `/api/bi/GetPercentageSeriesGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }


        return (
            <div id="panelSeries" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_SerieP}
                    containerID="panelSeries"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        )
    }

    renderPercentageCategories = () => {
        var { classes } = this.props;
        var { Categories, viewTypeCategories, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingPercentagePerCategories } = this.state;
        var width = this.getWidth();

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();

        var content = undefined;

        // if ( viewTypeCategories === "chart") {
        //     content = (
        //     <BarChart width={mainContentWidth} height={250} data={Categories} style={{display:'inline-block'}} onClick={this.onClickTest}>
        //         <CartesianGrid strokeDasharray="3 3" />
        //         <XAxis dataKey="Category" />
        //         <YAxis />
        //         <Tooltip formatter={PercentageFormat} />
        //         <Legend />
        //         <Bar dataKey="Percentage" fill="#33DDFF" onClick={this.onClickTest}>
        //         {
        //             Categories && Categories.map((entry, index) => (
        //             <Cell  onClick={this.onClickTest} key={`cell-${index}`} fill={colors[index % 20]} />
        //             ))
        //         }
        //         </Bar>
        //     </BarChart>
        //     );
        // }

        // Torta
        if(Categories && Categories.length > 0 ) { 
        if (viewTypeCategories == "torta") {
            const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Category
                } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';

                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Category || getTranslation(7, "BI_RESULT_NOCATEGORIES", this.props.translations, "Senza Categoria")} ${value + "%"}`}</text>

                    </g>
                );
            };


            const style = {
                fontFamily: "Times New Roman",
                fontSize: '12px',
                display: 'inline-block',
                left: 'initial',
                right: '20%',
                //left: 0
            };



            var optimalWidth = 1500;
            var optimalHeight = 400;
            var aspectRatio = optimalWidth / optimalHeight;

            var useWidth = mainContentWidth;
            if(catalogoSetOpen){
                useWidth -= drawerWidth;
            }
            
            var multiplier = optimalWidth / useWidth;
            //var useHeight = useWidth / aspectRatio;
            var useHeight = optimalHeight / multiplier;
    
            var legentWidth = useWidth * 0.2;

            const renderLegend = (id, value) => {

                return <Fragment><span>{value.payload.Category || getTranslation(7, "BI_RESULT_NOCATEGORIES", this.props.translations, "Senza Categoria")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage + "%"}</span></Fragment>;
            }

            content = (
                <div className={classes.tortaMain}>
                    <div className={classes.torta}>

                        <PieChart width={useWidth} height={useHeight}>
                            <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                            <Pie
                                activeIndex={this.state.activeIndexCategories}
                                activeShape={renderActiveShape}
                                data={Categories}
                                cx={600 / multiplier}
                                cy={200 / multiplier}
                                innerRadius={100 / multiplier}
                                outerRadius={130 / multiplier}
                                fill="#8884d8"
                                dataKey="Percentage"
                                onMouseEnter={this.onPieEnter("Categories")}
                                onClick={this.onClickUpdateTortaFIDCategories}
                            >
                                {
                                    Categories && Categories.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>
                        </PieChart>
                    </div>
                </div>
            );
        }

        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        var actions = [];

        if(isLoadingPercentagePerCategories) {
            content = <XOXLoader height={'250px'}/>
        }

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeCategories","chart"), selected: viewTypeCategories === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeCategories", "torta"), selected: viewTypeCategories === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeCategories", "grid"), selected: viewTypeCategories === "grid" });


        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_CategoryPercentage";
        var dataRoute = `/api/bi/GetPercentageCategoriesGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        return (
            <div id="panelCategories" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_CategoryP}
                    containerID="panelCategories"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    rendertop10Anagrafiche = () => {
        var { classes } = this.props;
        var { top10Anagrafiche, viewTypeAnagrafiche, dateFrom, dateTo, FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingPercentagePerTOP10Master } = this.state;
        //var width = this.getWidth();
        const colors = scaleOrdinal(schemeCategory10).range();


        if(catalogoSetOpen){
            mainContentWidth -= drawerWidth;
        }


        var content = undefined;
        if( top10Anagrafiche && top10Anagrafiche.length > 0 ) {
            if (viewTypeAnagrafiche === "chart") {
                content = (
                    <BarChart width={mainContentWidth} height={250} data={top10Anagrafiche} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Code" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar dataKey="Views" fill="#4d63b3" onClick={this.onClickUpdateChartFIDIdentity} >
                            {
                                top10Anagrafiche && top10Anagrafiche.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        var actions = [];

        if(isLoadingPercentagePerTOP10Master){
            content = <XOXLoader height={'250px'}/>
        }


        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeAnagrafiche", "chart"), selected: viewTypeAnagrafiche === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeAnagrafiche", "grid"), selected: viewTypeAnagrafiche === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_CoderPerViewsAnagrafiche";
        var dataRoute = `/api/bi/GetTOP10RegistriesGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        return (
            <div id="panelAnagrafiche" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_CodeV}
                    containerID="panelAnagrafiche"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderPercentageCountry = () => {
        var { classes } = this.props;
        var { PerNazione, viewTypeNazione, dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingPercentagePerCountry } = this.state;
        var width = this.getWidth();

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();

        var content = undefined;

        // if( viewTypeNazione === "chart"){
        //     content = (
        //         <BarChart width={mainContentWidth} height={250} data={PerNazione} style={{display:'inline-block'}}>
        //             <CartesianGrid strokeDasharray="3 3" />
        //             <XAxis dataKey="Country" />
        //             <YAxis />
        //             <Tooltip formatter={PercentageFormat} />
        //             <Legend />
        //             <Bar dataKey="Percentage" fill="#c74ea5" >
        //             {
        //                 PerNazione && PerNazione.map((entry, index) => (
        //                 <Cell key={`cell-${index}`} fill={colors[index % 20]} />
        //                 ))
        //             }
        //             </Bar>
        //         </BarChart>
        //     );
        // } 

        // Torta
        if ( PerNazione && PerNazione.length > 0 ) {
        if (viewTypeNazione == "torta") {
            // return (
            //     <PieChart width={730} height={250}>
            //         <Pie data={PerNazione} dataKey="Percentage" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
            //         <Pie data={PerNazione} dataKey="Percentage" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label />
            //     </PieChart>
            // );

            const renderActiveShape = (props) => {
                var { FIDCountrySelector } = this.state;
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, value, percent, Country
                } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';

                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Country || getTranslation(7, "BI_RESULT_NONATION", this.props.translations, "Senza Nazione")} ${value + "%"}`}</text>

                    </g>
                );
            };


            const style = {
                fontFamily: "Times New Roman",
                fontSize: '12px',
                display: 'inline-block',
                left: 'initial',
                right: '20%',
                //left: 0
            };



            var optimalWidth = 1500;
            var optimalHeight = 400;
            var aspectRatio = optimalWidth / optimalHeight;

            var useWidth = mainContentWidth;
            if(catalogoSetOpen){
                useWidth -= drawerWidth;
            }
            
            var multiplier = optimalWidth / useWidth;
            //var useHeight = useWidth / aspectRatio;
            var useHeight = optimalHeight / multiplier;
    
            var legentWidth = useWidth * 0.2;

            const renderLegend = (id, value, ) => {

                return <Fragment> <span>{value.payload.Country || getTranslation(7, "BI_RESULT_NONATION", this.props.translations, "Senza Nazione")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
            }
            content = (
                <div className={classes.tortaMain}>
                    <div className={classes.torta}>

                        <PieChart width={useWidth} height={useHeight}>
                            <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                            <Pie
                                activeIndex={this.state.activeIndexPerNazione}
                                activeShape={renderActiveShape}
                                data={PerNazione}
                                cx={600 / multiplier}
                                cy={200 / multiplier}
                                innerRadius={100 / multiplier}
                                outerRadius={130 / multiplier}
                                fill="#8884d8"
                                dataKey="Percentage"
                                onMouseEnter={this.onPieEnter("PerNazione")}
                                onClick={this.onClickUpdateTortaFIDCountry}
                                fontSize="5px"
                            >
                                {
                                    PerNazione && PerNazione.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>
                        </PieChart>
                    </div>
                </div>
            );
        }

        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }
        var actions = [];

        if(isLoadingPercentagePerCountry) {
            content = <XOXLoader height={'250px'}/>
        }
        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeNazione", "torta"), selected: viewTypeNazione === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeNazione", "grid"), selected: viewTypeNazione === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_PERCCOUNTRY";
        var dataRoute = `/api/bi/GetCountryPercentageGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        // Dati
        return (
            <div id="panelNations" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_CountryP}
                    containerID="panelNations"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderPercentageRegions = () => {
        var { classes } = this.props;
        var { Regions, viewTypeRegions, dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingPercentagePerRegions } = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        // Torta
        if( Regions && Regions.length > 0 ) {
        if (viewTypeRegions == "torta") {

            const renderActiveShape = (props) => {
                var { FIDCountrySelector } = this.state;
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, value, percent, Region, Country
                } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';

                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Region || getTranslation(7, "BI_RESULT_NOREGION", this.props.translations, "Senza Regione")} ${value + "%"}`}</text>

                    </g>
                );
            };

            const style = {
                fontFamily: "Times New Roman",
                fontSize: '12px',
                display: 'inline-block',
                left: 'initial',
                right: '20%',
                //left: 0
            };



            var optimalWidth = 1500;
            var optimalHeight = 400;
            var aspectRatio = optimalWidth / optimalHeight;

            var useWidth = mainContentWidth;
            if(catalogoSetOpen){
                useWidth -= drawerWidth;
            }
            
            var multiplier = optimalWidth / useWidth;
            //var useHeight = useWidth / aspectRatio;
            var useHeight = optimalHeight / multiplier;
    
            var legentWidth = useWidth * 0.2;

            const renderLegend = (id, value, ) => {

                return <Fragment> <span>{value.payload.Region || getTranslation(7, "BI_RESULT_NOREGION", this.props.translations, "Senza Regione")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
            }

            content = (
                <div className={classes.tortaMain}>
                    <div className={classes.torta}>

                        <PieChart width={useWidth} height={useHeight}>
                            <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                            <Pie
                                activeIndex={this.state.activeIndexRegions}
                                activeShape={renderActiveShape}
                                data={Regions}
                                cx={600 / multiplier}
                                cy={200 / multiplier}
                                innerRadius={100 / multiplier}
                                outerRadius={130 / multiplier}
                                fill="#8884d8"
                                dataKey="Percentage"
                                onMouseEnter={this.onPieEnter("Regions")}
                                onClick={this.onClickUpdateTortaFIDRegion}
                                fontSize="5px"
                            >
                                {
                                    Regions && Regions.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>
                        </PieChart>
                    </div>
                </div>
            );
        }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        if(isLoadingPercentagePerRegions){
            content = <XOXLoader height={'250px'}/>
        }

        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeRegions", "torta"), selected: viewTypeRegions === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeRegions", "grid"), selected: viewTypeRegions === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_RegionsPercentange";
        var dataRoute = `/api/bi/GetPercentageRegionsGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        // Dati
        return (
            <div id="panelRegions" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_RegionsP}
                    containerID="panelRegions"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderPercentageProvince = () => {
        var { classes } = this.props;
        var { Province, viewTypeProvince, dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingPercentagePerProvince  } = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        // Torta
        if( Province && Province.length > 0 ) {
        if (viewTypeProvince == "torta") {

            const renderActiveShape = (props) => {
                var { FIDCountrySelector } = this.state;
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, value, percent, Province
                } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';

                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Province || getTranslation(7, "BI_RESULT_NOPROVINCE", this.props.translations, "Senza Provincia")} ${value + "%"}`}</text>

                    </g>
                );
            };



            const style = {
                fontFamily: "Times New Roman",
                fontSize: '12px',
                display: 'inline-block',
                left: 'initial',
                right: '20%',
                //left: 0
            };



            var optimalWidth = 1500;
            var optimalHeight = 400;
            var aspectRatio = optimalWidth / optimalHeight;

            var useWidth = mainContentWidth;
            if(catalogoSetOpen){
                useWidth -= drawerWidth;
            }
            
            var multiplier = optimalWidth / useWidth;
            //var useHeight = useWidth / aspectRatio;
            var useHeight = optimalHeight / multiplier;
    
            var legentWidth = useWidth * 0.2;

            const renderLegend = (id, value, ) => {

                return <Fragment> <span>{value.payload.Province || getTranslation(7, "BI_RESULT_NOPROVINCE", this.props.translations, "Senza Provincia")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
            }


            content = (
                <div className={classes.tortaMain}>
                    <div className={classes.torta}>
                        <PieChart width={useWidth} height={useHeight}>
                            <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                            <Pie
                                activeIndex={this.state.activeIndexProvince}
                                activeShape={renderActiveShape}
                                data={Province}
                                cx={600 / multiplier}
                                cy={200 / multiplier}
                                innerRadius={100 / multiplier}
                                outerRadius={130 / multiplier}
                                fill="#8884d8"
                                dataKey="Percentage"
                                onMouseEnter={this.onPieEnter("Province")}
                                onClick={this.onClickUpdateTortaFIDProvince}
                                fontSize="5px"
                            >
                                {
                                    Province && Province.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>
                        </PieChart>
                    </div>
                </div>
            );
        }

        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }


        if(isLoadingPercentagePerProvince){
            content = <XOXLoader height={'250px'}/>
        }

        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeProvince", "torta"), selected: viewTypeProvince === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeProvince", "grid"), selected: viewTypeProvince === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_ProvincesPercentange";
        var dataRoute = `/api/bi/GetPercentageProvinceGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        // Dati
        return (
            <div id="panelRegions" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_RegionsP}
                    containerID="panelRegions"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderPercentageCities = () => {
        var { classes } = this.props;
        var { Cities, viewTypeCities, dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, mainContentWidth, FIDBrand, FIDDocumentSubType, FIDOwner,catalogoSetOpen, isLoadingPercentagePerCity  } = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        // Torta
        if (Cities && Cities.length > 0 ) {
        if (viewTypeCities == "torta") {

            const renderActiveShape = (props) => {
                var { FIDCountrySelector } = this.state;
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, value, percent, City
                } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';

                return (
                    <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{City || getTranslation(7, "BI_RESULT_NOCITY", this.props.translations, "Senza Citta")} ${value + "%"}</text>

                    </g>
                );
            };




            var optimalWidth = 1500;
            var optimalHeight = 400;
            var aspectRatio = optimalWidth / optimalHeight;

            var useWidth = mainContentWidth;
            if(catalogoSetOpen){
                useWidth -= drawerWidth;
            }
            
            var multiplier = optimalWidth / useWidth;
            //var useHeight = useWidth / aspectRatio;
            var useHeight = optimalHeight / multiplier;
    
            var legentWidth = useWidth * 0.2;

            const renderLegend = (id, value, ) => {

                return <Fragment> <span>{value.payload.City || getTranslation(7, "BI_RESULT_NOCITY", this.props.translations, "Senza Citta")} </span> <span style={{ fontFamily: "Arial", fontSize: '10px' }}>{value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
            }

            content = (
                <div className={classes.tortaMain}>
                    <div className={classes.torta}>

                        <PieChart width={useWidth} height={useHeight}>
                            <Legend iconSize={10} style={{columns: 2}} iconType="diamond" width={legentWidth} height={useHeight} layout="vertical" verticalAlign="middle" wrapperStyle={legendStyle} formatter={renderLegend} />
                            <Pie
                                activeIndex={this.state.activeIndexCities}
                                activeShape={renderActiveShape}
                                data={Cities}
                                cx={600 / multiplier}
                                cy={200 / multiplier}
                                innerRadius={100 / multiplier}
                                outerRadius={130 / multiplier}
                                fill="#8884d8"
                                dataKey="Percentage"
                                onMouseEnter={this.onPieEnter("Cities")}
                                onClick={this.onClickUpdateTortaFIDCity}
                                fontSize="5px"
                            >
                                {
                                    Cities && Cities.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>
                        </PieChart>
                    </div>
                </div>
            );
        }

        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        if(isLoadingPercentagePerCity) {
            content = <XOXLoader height={'250px'}/>
        }

        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_PIE", this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeCities", "torta"), selected: viewTypeCities === "torta" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeCities", "grid"), selected: viewTypeCities === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_CitiesPercentage";
        var dataRoute = `/api/bi/GetPercentageCitiesGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if (FIDLine) {
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if (FIDSeries) {
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if (FIDCountry) {
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if (FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        // Dati
        return (
            <div id="panelCities" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_CitiesP}
                    containerID="panelCities"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    SetView = (key, value) => () => {
        this.setState(state => {
            state[key] = value;
            return state;
        });
    }


    // News Panel
    renderPanelNews = () => {
        var { classes } = this.props;
        var { dateFrom, dateTo } = this.state;
        return (

            <Grid style={{ margin: 0 }} container xs={12} spacing={3}>
                <Grid item xs={12} ><XDateField clearable={false} label={getTranslation(7, "BI_TITLE_PARAMS_FROMDATA", this.props.translations, "Da data")} type="date" id="dateFrom" maxDate={dateTo} value={dateTo} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XDateField clearable={false} label={getTranslation(7, "BI_TITLE_PARAMS_TODATA", this.props.translations, "A data")} type="date" id="dateTo" minDate={dateFrom} value={dateTo} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_IDENTITY", this.props.translations, "Anagrafiche")} id="FIDIdentity" urlData="/IR/GetIRSelect" value={this.state.FIDIdentity} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" subtitleKey="Address" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_TIPOLOGIES", this.props.translations, "Tipologia")} id="FIDTipology" urlData="/Base/GetIRTypologies" value={this.state.FIDTipology} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_DISTRUBUSION", this.props.translations, "Distribuzioni")} id="FIDDistribution" urlData="/IR/DistributionList" value={this.state.FIDDistribution} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_COUNTRY", this.props.translations, "Nazione")} id="FIDCountry" urlData="/Base/GetIRCountries" value={this.state.FIDCountry} onChange={this.handleSelectChange} idKey="ID" valueKey="Country" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_REGIONS", this.props.translations, "Regioni")} id="FIDRegion" urlData="/Base/GetIRRegions" value={this.state.FIDRegion} onChange={this.handleSelectChange} idKey="ID" valueKey="Region" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMAS_PROVINCE", this.props.translations, "Provincia")} id="FIDProvince" urlData="/Base/GetIRProvinces" value={this.state.FIDProvince} onChange={this.handleSelectChange} idKey="ID" valueKey="Province" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_CITIES", this.props.translations, "Citta")} id="FIDCity" urlData="/Base/GetIRCities" value={this.state.FIDCity} onChange={this.handleSelectChange} idKey="ID" valueKey="City" /></Grid>
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={this.loadDataNews}>{getTranslation(7, "BI_BUTTON_LOADDATA", this.props.translations, "Carica Dati")} </XButton></Grid>
            </Grid>
        );
    }

    // Adv Panel
    renderPanelAdv = () => {
        var { classes } = this.props;
        var { dateFrom, dateTo } = this.state;
        return (

            <Grid style={{ margin: 0 }} container xs={12} spacing={3}>
                <Grid item xs={12} ><XDateField clearable={false} label={getTranslation(7, "BI_TITLE_PARAMS_FROMDATA", this.props.translations, "Da data")} type="date" id="dateFrom" maxDate={dateTo} value={dateFrom} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XDateField clearable={false} label={getTranslation(7, "BI_TITLE_PARAMS_TODATA", this.props.translations, "A data")} type="date" id="dateTo" minDate={dateFrom} value={dateTo} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_IDENTITY", this.props.translations, "Anagrafiche")} id="FIDIdentity" urlData="/IR/GetIRSelect" value={this.state.FIDIdentity} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" subtitleKey="Address" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_TIPOLOGIES", this.props.translations, "Tipologia")} id="FIDTipology" urlData="/Base/GetIRTypologies" value={this.state.FIDTipology} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_DISTRUBUSION", this.props.translations, "Distribuzioni")} id="FIDDistribution" urlData="/IR/DistributionList" value={this.state.FIDDistribution} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_COUNTRY", this.props.translations, "Nazione")} id="FIDCountry" urlData="/Base/GetIRCountries" value={this.state.FIDCountry} onChange={this.handleSelectChange} idKey="ID" valueKey="Country" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_REGIONS", this.props.translations, "Regioni")} id="FIDRegion" urlData="/Base/GetIRRegions" value={this.state.FIDRegion} onChange={this.handleSelectChange} idKey="ID" valueKey="Region" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMAS_PROVINCE", this.props.translations, "Provincia")} id="FIDProvince" urlData="/Base/GetIRProvinces" value={this.state.FIDProvince} onChange={this.handleSelectChange} idKey="ID" valueKey="Province" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_CITIES", this.props.translations, "Citta")} id="FIDCity" urlData="/Base/GetIRCities" value={this.state.FIDCity} onChange={this.handleSelectChange} idKey="ID" valueKey="City" /></Grid>
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={this.loadDataAdv}>{getTranslation(7, "BI_BUTTON_LOADDATA", this.props.translations, "Carica Dati")} </XButton></Grid>
            </Grid>
        );
    }

    // Accessi 
    renderPanelAccess = () => {
        var { classes } = this.props;
        var { dateFrom, dateTo } = this.state;
        //filters={[ {key: "FIDSeries", value: this.state.FIDSeries} ]
        return (

            <Grid style={{margin: 0}}container xs={12} spacing={3}>
              <Grid item xs={12}><XDateField clearable={false} label={getTranslation(7, "BI_TITLE_PARAMS_FROMDATA", this.props.translations, "Da data")} type="date" id="dateFrom" maxDate={dateTo} value={dateFrom} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XDateField clearable={false} label={getTranslation(7, "BI_TITLE_PARAMS_TODATA", this.props.translations, "A data")} type="date" id="dateTo" minDate={dateFrom} value={dateTo} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_IDENTITY", this.props.translations, "Anagrafiche")} id="FIDIdentity" urlData="/IR/GetIRSelect" value={this.state.FIDIdentity} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" subtitleKey="Address" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_TIPOLOGIES", this.props.translations, "Tipologia")} id="FIDTipology" urlData="/Base/GetIRTypologies" value={this.state.FIDTipology} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_DISTRUBUSION", this.props.translations, "Distribuzioni")} id="FIDDistribution" urlData="/IR/DistributionList" value={this.state.FIDDistribution} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_COUNTRY", this.props.translations, "Nazione")} id="FIDCountry" urlData="/Base/GetIRCountries" value={this.state.FIDCountry} onChange={this.handleSelectChange} idKey="ID" valueKey="Country" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_REGIONS", this.props.translations, "Regioni")} id="FIDRegion" urlData="/Base/GetIRRegions" value={this.state.FIDRegion} onChange={this.handleSelectChange} idKey="ID" valueKey="Region" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMAS_PROVINCE", this.props.translations, "Provincia")} id="FIDProvince" urlData="/Base/GetIRProvinces" value={this.state.FIDProvince} onChange={this.handleSelectChange} idKey="ID" valueKey="Province" /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_CITIES", this.props.translations, "Citta")} id="FIDCity" urlData="/Base/GetIRCities" value={this.state.FIDCity} onChange={this.handleSelectChange} idKey="ID" valueKey="City" /></Grid>
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={this.loadDataUserAccess}>{getTranslation(7, "BI_BUTTON_LOADDATA", this.props.translations, "Carica Dati")} </XButton></Grid>
            </Grid>
        );
    }



    renderTOP10News = () => {
        var { classes } = this.props;
        var { News, viewTypeNews, dateFrom, dateTo, FIDCountry, FIDIdentity, FIDRegion, FIDProvince, FIDDistribution, FIDCity, mainContentWidth, crmSetOpen, isLoadingNews } = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        
        if(crmSetOpen){
            mainContentWidth -= drawerWidth;
        }

        if(News && News.length > 0 ) {
            // Torta
            if (viewTypeNews == "chart") {
                content = (
                    <Fragment>
                        <BarChart width={mainContentWidth} height={250} data={News} style={{ display: 'inline-block' }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="Title" />
                            <YAxis />
                            <Tooltip formatter={format} />
                            <Legend />
                            <Bar dataKey="Views" fill="#ffbf80" nameKey="Vizzualizationi" >
                                {
                                    News && News.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </Fragment>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
            }

        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <ChartPie />, action: this.SetView("viewTypeNews", "chart"), selected: viewTypeNews === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeNews", "grid"), selected: viewTypeNews === "grid" });

        var label = <XNavigation actions={actions} />;


        if(isLoadingNews) {
            content = <XOXLoader height={'250px'}/>
        }

        var gridName = "grdBINews";
        var dataRoute = `/api/bi/GetTOP10NewsGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        // Dati
        return (
            <div id="panelNews" className={classes.container}>
                <XGrid
                    innerRef={this.grdBINews}
                    containerID="panelNews"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderTOP10Adv = () => {
        var { classes } = this.props;
        var { Adv, viewTypeAdv, dateFrom, dateTo, FIDCountry, FIDIdentity, FIDRegion, FIDProvince, FIDDistribution, FIDCity , mainContentWidth, crmAdvSetOpen, isLoadingAdv} = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(crmAdvSetOpen){
            mainContentWidth -= drawerWidth;
        }

        if(Adv && Adv.length > 0 ) { 
        // Torta
                if (viewTypeAdv == "chart") {
                    content = (
                        <Fragment>
                            <BarChart width={mainContentWidth} height={250} data={Adv} style={{ display: 'inline-block' }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Title" />
                                <YAxis />
                                <Tooltip formatter={format} />
                                <Legend />
                                <Bar dataKey="Views" fill="#ffbf80" onClick={this.onClickTest} >
                                    {
                                        Adv && Adv.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000} />
                                        ))
                                    }
                                </Bar>
                            </BarChart>
                        </Fragment>
                    );
                }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
            }

        var actions = [];

        if(isLoadingAdv) {
            content = <XOXLoader height={'250px'}/>
        }

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <ChartPie />, action: this.SetView("viewTypeAdv", "chart"), selected: viewTypeAdv === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeAdv", "grid"), selected: viewTypeAdv === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIAdv";
        var dataRoute = `/api/bi/GetTOP10AdvGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        // Dati
        return (
            <div id="panelAdv" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIAdv}
                    containerID="panelAdv"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderTOP10NewsTime = () => {
        var { classes } = this.props;
        var { NewsTime, viewTypeTimeNews, dateFrom, dateTo, FIDCountry, FIDIdentity, FIDRegion, FIDProvince, FIDDistribution, FIDCity, mainContentWidth, crmSetOpen, isLoadingNewsRemain } = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        
        if(crmSetOpen){
            mainContentWidth -= drawerWidth;
        }

        if(NewsTime && NewsTime.length > 0 )  {        
        // Torta
            if (viewTypeTimeNews == "chart") {
                content = (
                    <Fragment>
                        <BarChart width={mainContentWidth} height={250} data={NewsTime} style={{ display: 'inline-block' }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="Title" />
                            <YAxis />
                            <Tooltip formatter={format} />
                            <Legend />
                            <Bar dataKey="ReadTime" fill="#ffbf80" >
                                {
                                    NewsTime && NewsTime.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </Fragment>
                );
            }
        }
        else {
        content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }
        var actions = [];
        if(isLoadingNewsRemain) {
            content = <XOXLoader height={'250px'}/>
        }
        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <ChartPie />, action: this.SetView("viewTypeTimeNews", "chart"), selected: viewTypeTimeNews === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeTimeNews", "grid"), selected: viewTypeTimeNews === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBINewsTime";
        var dataRoute = `/api/bi/GetTop10ReadedGridNews?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        // Dati
        return (
            <div id="panelNewsTime" className={classes.container}>
                <XGrid
                    innerRef={this.grdBINewsTime}
                    containerID="panelNewsTime"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderTOP10AdvTime = () => {
        var { classes } = this.props;
        var { AdvTime, viewTypeTimeAdv, dateFrom, dateTo, FIDCountry, FIDIdentity, FIDRegion, FIDProvince, FIDDistribution, FIDCity, mainContentWidth, crmAdvSetOpen, isLoadingAdvRemain } = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        
        if(crmAdvSetOpen){
            mainContentWidth -= drawerWidth;
        }

        if(AdvTime && AdvTime.length > 0 ) { 
            // Torta
            if (viewTypeTimeAdv == "chart") {
                content = (
                    <Fragment>
                        <BarChart width={mainContentWidth} height={250} data={AdvTime} style={{ display: 'inline-block' }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="Denomination" />
                            <YAxis />
                            <Tooltip formatter={format} />
                            <Legend />
                            <Bar dataKey="ReadTime" fill="#ffbf80" >
                                {
                                    AdvTime && AdvTime.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </Fragment>
                );
            }
        }else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
            }
        // Needs else 
        var actions = [];

        if(isLoadingAdvRemain) { 
            content = <XOXLoader height={'250px'}/>
        }

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <ChartPie />, action: this.SetView("viewTypeTimeAdv", "chart"), selected: viewTypeTimeAdv === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeTimeAdv", "grid"), selected: viewTypeTimeAdv === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIAdvTime";
        var dataRoute = `/api/bi/GetTop10ReadedGridAdv?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        // Dati
        return (
            <div id="panelAdvTime" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIAdvTime}
                    containerID="panelAdvTime"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderTOP10UsersTimeNews = () => {
        var { classes } = this.props;
        var { UserNews, viewTypeUsersTimeNews, dateFrom, dateTo, FIDCountry, FIDIdentity, FIDRegion, FIDProvince, FIDDistribution, FIDCity , mainContentWidth, crmSetOpen, isLoadingUsersRemainNews } = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(crmSetOpen){
            mainContentWidth -= drawerWidth;
        }
        
        if( UserNews && UserNews.length > 0 ) { 
            // Torta
            if (viewTypeUsersTimeNews == "chart") {
                content = (
                    <Fragment>
                        <BarChart width={mainContentWidth} height={250} data={UserNews} style={{ display: 'inline-block' }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="Denomination" />
                            <YAxis />
                            <Tooltip formatter={format} />
                            <Legend />
                            <Bar dataKey="ReadTime" fill="#ffbf80" >
                                {
                                    UserNews && UserNews.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </Fragment>
                );
            }
        }else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
            }

        var actions = [];

        if(isLoadingUsersRemainNews) { 
            content = <XOXLoader height={'250px'}/>
        }


        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <ChartPie />, action: this.SetView("viewTypeUsersTimeNews", "chart"), selected: viewTypeUsersTimeNews === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeUsersTimeNews", "grid"), selected: viewTypeUsersTimeNews === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIUserTimeNews";
        var dataRoute = `/api/bi/GetTop10UserTimeNewsGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        // Dati
        return (
            <div id="panelUserAdv" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIUserTimeNews}
                    containerID="panelUserAdv"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderTOP10UsersTimeAdv = () => {
        var { classes } = this.props;
        var { UserAdv, viewTypeUsersTimeAdv, dateFrom, dateTo, FIDCountry, FIDIdentity, FIDRegion, FIDProvince, FIDDistribution, FIDCity, mainContentWidth, crmAdvSetOpen, isLoadingUsersRemainAdv } = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(crmAdvSetOpen){
            mainContentWidth -= drawerWidth;
        }

        if(UserAdv && UserAdv.length > 0 ) {
            // Torta
            if (viewTypeUsersTimeAdv == "chart") {
                content = (
                    <Fragment>
                        <BarChart width={mainContentWidth} height={250} data={UserAdv} style={{ display: 'inline-block' }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="Denomination" />
                            <YAxis />
                            <Tooltip formatter={format} />
                            <Legend />
                            <Bar dataKey="ReadTime" fill="#ffbf80" >
                                {
                                    UserAdv && UserAdv.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </Fragment>
                );
            }
        } else {
                content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }
        var actions = [];

        if(isLoadingUsersRemainAdv) {
            content = <XOXLoader height={'250px'}/>
        }

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <ChartPie />, action: this.SetView("viewTypeUsersTimeAdv", "chart"), selected: viewTypeUsersTimeAdv === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeUsersTimeAdv", "grid"), selected: viewTypeUsersTimeAdv === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIUserTimeAdv";
        var dataRoute = `/api/bi/GetTop10UserTimeAdvGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        // Dati
        return (
            <div id="panelUserTimeAdv" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIUserTimeAdv}
                    containerID="panelUserTimeAdv"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderTOP10usrLogins = () => {
        var { classes } = this.props;
        var { usrLogin, viewTypeLogin, dateFrom, dateTo, FIDCountry, FIDIdentity, FIDRegion, FIDProvince, FIDDistribution, FIDCity, mainContentWidth, loginSetOpen, isLoadingLogins } = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        if(loginSetOpen){
            mainContentWidth -= drawerWidth;
        }

        if(usrLogin && usrLogin.length > 0 ) {
        // Torta
            if (viewTypeLogin == "chart") {
                content = (
                    <Fragment>
                        <BarChart width={mainContentWidth} height={250} data={usrLogin} style={{ display: 'inline-block' }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="Denomination" />
                            <YAxis />
                            <Tooltip formatter={format} />
                            <Legend />
                            <Bar dataKey="Login" fill="#ffbf80" onClick={this.onClickTest} >
                                {
                                    usrLogin && usrLogin.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </Fragment>
                );
            }
        } else {
        content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }
        var actions = [];

        //actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <ChartPie />, action: this.SetView("viewTypeLogin", "chart"), selected: viewTypeLogin === "chart" });
        actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeLogin", "grid"), selected: viewTypeLogin === "grid" });
        
        if(isLoadingLogins) {
            content = <XOXLoader height={'250px'}/>
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBILogin";
        var dataRoute = `/api/bi/GetTop10LoginsGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            dataRoute += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            dataRoute += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            dataRoute += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            dataRoute += `&FIDCity=${FIDCity}`;
        }

        // Dati
        return (
            <div id="panelLogin" className={classes.container}>
                <XGrid
                    innerRef={this.grdBILogin}
                    containerID="panelLogin"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderTabCatalogue() {
        //var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { top10, Lines, Series, Categories, top10Anagrafiche, PerNazione } = this.state;
        var { classes } = this.props;
        var country = "";
        

        var panels = [];

        // panels.push({
        //     icon: 'data_usage',
        //     label: getTranslation(7,"BI_TITLE_PARAMS",this.props.translations, "Parametri"),
        //     content: this.renderPanelParameters()
        // });

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7, "BI_TITLE_TOP10Catalogo", this.props.translations, "Top 10 Codici Catalogo"),
            content: this.renderTOP10CodiciCatalogo()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_TOP10PROD", this.props.translations, "Top 10 Prodotti"),
            content: this.renderTop10()
        });

        panels.push({
            icon: 'line_weight',
            label: getTranslation(7, "BI_TITLE_PERCENTLINE", this.props.translations, "Percentuale per Linea"),
            content: this.renderPercentageLines()
        });

        panels.push({
            icon: 'code',
            label: getTranslation(7, "BI_TITLE_PERCENTSERIES", this.props.translations, "Percentuale per Serie"),
            content: this.renderPercentageSeries()
        });

        panels.push({
            icon: 'category_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTCATEGORIES", this.props.translations, "Percentuale per Categorie"),
            content: this.renderPercentageCategories()
        });

        panels.push({
            icon: 'storage_icon',
            label: getTranslation(7, "BI_TITLE_IDENTITY", this.props.translations, "TOP 10 Anagrafiche"),
            content: this.rendertop10Anagrafiche()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTCOUNTRY", this.props.translations, "Percentuale per Nazione"),
            content: this.renderPercentageCountry()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTAGEREGIONS", this.props.translations, "Percentuale Regioni"),
            content: this.renderPercentageRegions()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTAGEPROVINCE", this.props.translations, "Percentuale Provincia"),
            content: this.renderPercentageProvince()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTAGECITY", this.props.translations, "Percentuale Citta"),
            content: this.renderPercentageCities()
        });
        
        return <TabContainer>
            <div className={classes.tabContainer}>
                <div className={classNames(classes.tabContent,{[classes.tabContentOpen]: this.state.catalogoSetOpen})}>
                    <XExpansionPanel panels={panels} />
                </div>
                
                <Drawer
                    className={classNames(classes.drawer,{[classes.drawerOpen]: this.state.catalogoSetOpen})}
                    variant="persistent"
                    anchor="right"
                    open={this.state.catalogoSetOpen}
                    classes={{ paper: classes.drawerPaper, }}>
                        <SidebarWrapper className={classes.sidebarWrapper}>
                        {this.renderPanelParameters()}
                        </SidebarWrapper>
                    
                </Drawer>
            </div>
        </TabContainer>;
    }

    // Buy 
    renderTabBuy() {
        //var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { buyCatalogCodeDOC, Lines, Series, Categories, top10Anagrafiche, PerNazione } = this.state;
        var { classes } = this.props;
        var country = "";
        

        var panels = [];

        // panels.push({
        //     icon: 'data_usage',
        //     label: getTranslation(7,"BI_TITLE_PARAMS",this.props.translations, "Parametri"),
        //     content: this.renderPanelParameters()
        // });

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7, "BI_TITLE_TOP10Catalogo", this.props.translations, "Top 10 Codici Catalogo"),
            content: this.renderbuyCatalogCodeDOC()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_TOP10PROD", this.props.translations, "Top 10 Prodotti"),
            content: this.renderbuyCodeDOC()
        });

        panels.push({
            icon: 'line_weight',
            label: getTranslation(7, "BI_TITLE_PERCENTLINE", this.props.translations, "Percentuale per Linea"),
            content: this.renderbuyPercentageLines()
        });

        panels.push({
            icon: 'code',
            label: getTranslation(7, "BI_TITLE_PERCENTSERIES", this.props.translations, "Percentuale per Serie"),
            content: this.renderbuyPercentageSeries()
        });

        panels.push({
            icon: 'category_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTCATEGORIES", this.props.translations, "Percentuale per Categorie"),
            content: this.renderbuyPercentageCategories()
        });

        panels.push({
            icon: 'storage_icon',
            label: getTranslation(7, "BI_TITLE_IDENTITY", this.props.translations, "TOP 10 Anagrafiche"),
            content: this.renderbuyIdentityDOC()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTCOUNTRY", this.props.translations, "Percentuale per Nazione"),
            content: this.renderbuyPercentageCountry()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTAGEREGIONS", this.props.translations, "Percentuale Regioni"),
            content: this.renderbuyPercentageRegions()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTAGEPROVINCE", this.props.translations, "Percentuale Provincia"),
            content: this.renderbuyPercentageProvince()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTAGECITY", this.props.translations, "Percentuale Citta"),
            content: this.renderbuyPercentageCity()
        });
        
        return <TabContainer>
            <div className={classes.tabContainer}>
                <div className={classNames(classes.tabContent,{[classes.tabContentOpen]: this.state.catalogoSetOpen})}>
                    <XExpansionPanel panels={panels} />
                </div>
                
                <Drawer
                    className={classNames(classes.drawer,{[classes.drawerOpen]: this.state.catalogoSetOpen})}
                    variant="persistent"
                    anchor="right"
                    open={this.state.catalogoSetOpen}
                    classes={{ paper: classes.drawerPaper, }}>
                        <SidebarWrapper className={classes.sidebarWrapper}>
                        {this.renderPanelParameters()}
                        </SidebarWrapper>
                    
                </Drawer>
            </div>
        </TabContainer>;
    }

    // Sell - NOT DONE
    renderTabSell() {
        //var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { top10, Lines, Series, Categories, top10Anagrafiche, PerNazione } = this.state;
        var { classes } = this.props;
        var country = "";
        

        var panels = [];

        // panels.push({
        //     icon: 'data_usage',
        //     label: getTranslation(7,"BI_TITLE_PARAMS",this.props.translations, "Parametri"),
        //     content: this.renderPanelParameters()
        // });

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7, "BI_TITLE_TOP10Catalogo", this.props.translations, "Top 10 Codici Catalogo"),
            content: this.rendersellCatalogCodeDOC()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_TOP10PROD", this.props.translations, "Top 10 Prodotti"),
            content: this.rendersellCodeDOC()
        });

        panels.push({
            icon: 'line_weight',
            label: getTranslation(7, "BI_TITLE_PERCENTLINE", this.props.translations, "Percentuale per Linea"),
            content: this.rendersellPercentageLines()
        });

        panels.push({
            icon: 'code',
            label: getTranslation(7, "BI_TITLE_PERCENTSERIES", this.props.translations, "Percentuale per Serie"),
            content: this.rendersellPercentageSeries()
        });

        panels.push({
            icon: 'category_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTCATEGORIES", this.props.translations, "Percentuale per Categorie"),
            content: this.rendersellPercentageCategories()
        });

        panels.push({
            icon: 'storage_icon',
            label: getTranslation(7, "BI_TITLE_IDENTITY", this.props.translations, "TOP 10 Anagrafiche"),
            content: this.rendersellIdentityDOC()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTCOUNTRY", this.props.translations, "Percentuale per Nazione"),
            content: this.rendersellPercentageCountry()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTAGEREGIONS", this.props.translations, "Percentuale Regioni"),
            content: this.rendersellPercentageRegions()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTAGEPROVINCE", this.props.translations, "Percentuale Provincia"),
            content: this.rendersellPercentageProvince()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7, "BI_TITLE_PERCENTAGECITY", this.props.translations, "Percentuale Citta"),
            content: this.rendersellPercentageCity()
        });
        
        return <TabContainer>
            <div className={classes.tabContainer}>
                <div className={classNames(classes.tabContent,{[classes.tabContentOpen]: this.state.catalogoSetOpen})}>
                    <XExpansionPanel panels={panels} />
                </div>
                
                <Drawer
                    className={classNames(classes.drawer,{[classes.drawerOpen]: this.state.catalogoSetOpen})}
                    variant="persistent"
                    anchor="right"
                    open={this.state.catalogoSetOpen}
                    classes={{ paper: classes.drawerPaper, }}>
                        <SidebarWrapper className={classes.sidebarWrapper}>
                        {this.renderPanelParameters()}
                        </SidebarWrapper>
                    
                </Drawer>
            </div>
        </TabContainer>;
    }


    // GetTOP10News = async () => {
    //     var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution } = this.state;

    //     var url = `/api/BI/GetTOP10News?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

    //     if (FIDProvince) {
    //         url += `&FIDProvince=${FIDProvince}`;
    //     }

    //     if (FIDIdentity) {
    //         url += `&FIDIdentity=${FIDIdentity}`;
    //     }

    //     if (FIDRegion) {
    //         url += `&FIDRegion=${FIDRegion}`;
    //     }

    //     if (FIDDistribution) {
    //         url += `&FIDDistribution=${FIDDistribution}`;
    //     }

    //     if (FIDCity) {
    //         url += `&FIDCity=${FIDCity}`;
    //     }

    //     if (FIDTipology) {
    //         url += `&FIDTipology=${FIDTipology}`;
    //     }
        

    //     if (FIDDistribution) {
    //         url += `&FIDDistribution=${FIDDistribution}`;
    //     }

    //     var result = await fetch(url).then(res => res.json());
    //     this.setState({ News: result });
    // }


    GetTOP10News = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingNews } = this.state;
        this.setState({isLoadingNews: true});
        var url = `/api/BI/GetTOP10News?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        
        controllerTop10News = new AbortController();
        var signal = controllerTop10News.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ News: result, isLoadingNews: false });
    }

    GetTOP10NewsTime = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingNewsRemain } = this.state;
        this.setState({isLoadingNewsRemain: true});
        var url = `/api/BI/GetTop10ReadedNews?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerTop10NewsTime = new AbortController();
        var signal = controllerTop10NewsTime.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ NewsTime: result, isLoadingNewsRemain: false });
    }

    GetTOP10AdvTime = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingAdvRemain } = this.state;
        this.setState({isLoadingAdvRemain: true});
        var url = `/api/BI/GetTop10ReadedAdv?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }
        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerTop10AdvTime = new AbortController();
        var signal = controllerTop10AdvTime.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ AdvTime: result, isLoadingAdvRemain: false });
    }

    GetTOP10UsersTimeNews = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingUsersRemainNews } = this.state;
        this.setState({isLoadingUsersRemainNews: true});
        var url = `/api/BI/GetTop10UserTimeNews?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }
        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }
        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerTop10NewsUserTime = new AbortController();
        var signal = controllerTop10NewsUserTime.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ UserNews: result, isLoadingUsersRemainNews: false });
    }

    GetTOP10UsersTimeAdv = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingUsersRemainAdv } = this.state;
        this.setState({isLoadingUsersRemainAdv: true});
        var url = `/api/BI/GetTop10UserTimeAdv?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }
        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerTop10AdvUserTime = new AbortController();
        var signal = controllerTop10AdvUserTime.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ UserAdv: result, isLoadingUsersRemainAdv: false });
    }


    GetTOP10Adv = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingAdv } = this.state;
        this.setState({isLoadingAdv: true});
        var url = `/api/BI/GetTop10Adv?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }
        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }
        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerTop10Adv = new AbortController();
        var signal = controllerTop10Adv.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ Adv: result, isLoadingAdv: false });
    }

    GetTOP10Logins = async () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity, FIDDistribution, FIDBrand, FIDDocumentSubType, FIDOwner, isLoadingLogins } = this.state;
        this.setState({isLoadingLogins: true});
        var url = `/api/BI/GetTop10Logins?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if (FIDProvince) {
            url += `&FIDProvince=${FIDProvince}`;
        }
        if (FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if (FIDIdentity) {
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if (FIDRegion) {
            url += `&FIDRegion=${FIDRegion}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDCity) {
            url += `&FIDCity=${FIDCity}`;
        }

        if (FIDDistribution) {
            url += `&FIDDistribution=${FIDDistribution}`;
        }

        if (FIDBrand) {
            url += `&FIDBrand=${FIDBrand}`;
        }

        if (FIDDocumentSubType){
            url += `&FIDDocumentSubType=${FIDDocumentSubType}`;
        }

        if (FIDOwner){
            url += `&FIDOwner=${FIDOwner}`;
        }

        controllerTop10Logins = new AbortController();
        var signal = controllerTop10Logins.signal;

        var result = await fetch(url, { signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){
                
            }
        })
        this.setState({ usrLogin: result, isLoadingLogins: false });
    }
    // News Render
    renderTabCrm() {
        //var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { News } = this.state;
        var { classes } = this.props;

        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7, "BI_TITLE_NEWS", this.props.translations, "Top 10 News"),
            content: this.renderTOP10News()
        });

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7, "BI_TITLE_NEWS_REM", this.props.translations, "Top 10 News rimanenza media"),
            content: this.renderTOP10NewsTime()
        });

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7, "BI_TITLE_USERS_REM_NEWS", this.props.translations, "Top 10 Utenti rimanenza media su news"),
            content: this.renderTOP10UsersTimeNews()
        });


        return <TabContainer>
            <div className={classes.tabContainer}>
                <div className={classNames(classes.tabContent,{[classes.tabContentOpen]: this.state.crmSetOpen})}>
                    <XExpansionPanel panels={panels} />
                </div>
                
                <Drawer
                    className={classNames(classes.drawer,{[classes.drawerOpen]: this.state.crmSetOpen})}
                    variant="persistent"
                    anchor="right"
                    open={this.state.crmSetOpen}
                    classes={{ paper: classes.drawerPaper, }}>
                        <SidebarWrapper className={classes.sidebarWrapper}>
                        {this.renderPanelNews()}
                        </SidebarWrapper>
                </Drawer>
            </div>
        </TabContainer>;
    }
    // Adv Render
    renderTabAdvCrm() {
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { News, Adv } = this.state;
        var { classes } = this.props;
        var country = "";

        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7, "BI_TITLE_ADV", this.props.translations, "Top 10 Adv"),
            content: this.renderTOP10Adv()
        });



        panels.push({
            icon: 'data_usage',
            label: getTranslation(7, "BI_TITLE_ADV_REM", this.props.translations, "Top 10 Adv rimanenza media"),
            content: this.renderTOP10AdvTime()
        });


        panels.push({
            icon: 'data_usage',
            label: getTranslation(7, "BI_TITLE_USERS_REM_ADV", this.props.translations, "Top 10 Utenti rimanenza media su adv"),
            content: this.renderTOP10UsersTimeAdv()
        });

        return <TabContainer>
            <div className={classes.tabContainer}>
                <div className={classNames(classes.tabContent,{[classes.tabContentOpen]: this.state.crmAdvSetOpen})}>
                    <XExpansionPanel panels={panels} />
                </div>
                
                <Drawer
                    className={classNames(classes.drawer,{[classes.drawerOpen]: this.state.crmAdvSetOpen})}
                    variant="persistent"
                    anchor="right"
                    open={this.state.crmAdvSetOpen}
                    classes={{ paper: classes.drawerPaper, }}>
                        <SidebarWrapper className={classes.sidebarWrapper}>
                        {this.renderPanelAdv()}
                        </SidebarWrapper>
                </Drawer>
            </div>
        </TabContainer>;
    }
    renderTabAccess() {
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { usrLogin } = this.state;
        var { classes } = this.props;
        var country = "";

        var panels = [];

        // panels.push({
        //     icon: 'data_usage',
        //     label: getTranslation(7, "BI_TITLE_PARAMS", this.props.translations, "Parametri"),
        //     content: this.renderPanelAccess()
        // });

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7, "BI_ACCESS", this.props.translations, "Top 10 Accessi"),
            content: this.renderTOP10usrLogins()
        });
        return<TabContainer>
        <div className={classes.tabContainer}>
            <div className={classNames(classes.tabContent,{[classes.tabContentOpen]: this.state.loginSetOpen})}>
                <XExpansionPanel panels={panels} />
            </div>
            
            <Drawer
                className={classNames(classes.drawer,{[classes.drawerOpen]: this.state.loginSetOpen})}
                variant="persistent"
                anchor="right"
                open={this.state.loginSetOpen}
                classes={{ paper: classes.drawerPaper, }}>
                     <SidebarWrapper className={classes.sidebarWrapper}>
                        {this.renderPanelAccess()}
                </SidebarWrapper>
            </Drawer>
        </div>
    </TabContainer>;
    }


    // This filters are used for Catalogue, Buy, Sell Tabs
    onActivateCatalogueFilters = () => {
        var { catalogoSetOpen } = this.state;
        this.setState({ catalogoSetOpen: !catalogoSetOpen });
    }

    onActivateCRMFilters = () => {
        var { crmSetOpen } = this.state;
        this.setState({ crmSetOpen: !crmSetOpen });
    }


    onActivateCRMAdvFilters = () => {
        var { crmAdvSetOpen } = this.state;
        this.setState({ crmAdvSetOpen: !crmAdvSetOpen });
    }

    onActivateAccessFilters = () => {
        var { loginSetOpen } = this.state;
        this.setState({ loginSetOpen: !loginSetOpen });
    }

    // This will try to abort all fetches made in Tab Index 0 - Catalogo
    abortFetchCatalogo = () => {
        controllerTop10Products && controllerTop10Products.abort && controllerTop10Products.abort();
        controllerTop10CatalogCodes && controllerTop10CatalogCodes.abort && controllerTop10CatalogCodes.abort();
        controllerTop10Identity && controllerTop10Identity.abort && controllerTop10Identity.abort();
        controllerPercentageForLine && controllerPercentageForLine.abort && controllerPercentageForLine.abort();
        controllerPercentageForSeries && controllerPercentageForSeries.abort && controllerPercentageForSeries.abort();
        controllerPercentageForCategories && controllerPercentageForCategories.abort && controllerPercentageForCategories.abort();
        controllerPercentageForNazione && controllerPercentageForNazione.abort && controllerPercentageForNazione.abort();
        controllerPercentageForRegions && controllerPercentageForRegions.abort && controllerPercentageForRegions.abort();
        controllerPercentageForProvince && controllerPercentageForProvince.abort && controllerPercentageForProvince.abort();
        controllerPercentageForCity && controllerPercentageForCity.abort && controllerPercentageForCity.abort();
    }
    // This will try to abort all fetches made in Tab Index 1 - News
    abortFetchNews = () => {
        controllerTop10News &&  controllerTop10News.abort &&  controllerTop10News.abort();
        controllerTop10NewsTime && controllerTop10News.abort &&  controllerTop10News.abort();
        controllerTop10NewsUserTime && controllerTop10NewsUserTime.abort && controllerTop10NewsUserTime.abort();
    }
    // This will try to abort all fetches made in Tab Index 2 - Adv
    abortFetchAdv = () => {
        controllerTop10Adv && controllerTop10Adv.abort && controllerTop10Adv.abort();
        controllerTop10AdvTime && controllerTop10AdvTime && controllerTop10AdvTime.abort(); 
        controllerTop10AdvUserTime && controllerTop10AdvUserTime.abort && controllerTop10AdvUserTime.abort();
    }
    // This will try to abort all fetches made in Tab Index 3 - Logins
    abortFetchAccessi = () => {
        controllerTop10Logins && controllerTop10Logins.abortt && controllerTop10Logins.abort();
    }

    abortFetchBuy = () => {
        controllerBuyTop10Products && controllerBuyTop10Products.abort && controllerTop10Products.abort();
        controllerBuyTop10CatalogCodes && controllerBuyTop10CatalogCodes.abort && controllerTop10CatalogCodes.abort();
        controllerBuyTop10Identity && controllerBuyTop10Identity.abort && controllerTop10Identity.abort();
        controllerBuyPercentageForLine && controllerBuyPercentageForLine.abort && controllerPercentageForLine.abort();
        controllerBuyPercentageForSeries && controllerBuyPercentageForSeries.abort && controllerPercentageForSeries.abort();
        controllerBuyPercentageForCategories && controllerBuyPercentageForCategories.abort && controllerPercentageForCategories.abort();
        controllerBuyPercentageForNazione && controllerBuyPercentageForNazione.abort && controllerPercentageForNazione.abort();
        controllerBuyPercentageForRegions && controllerBuyPercentageForRegions.abort && controllerPercentageForRegions.abort();
        controllerBuyPercentageForProvince && controllerBuyPercentageForProvince.abort && controllerPercentageForProvince.abort();
        controllerBuyPercentageForCity && controllerBuyPercentageForCity.abort && controllerPercentageForCity.abort();
    }

    abortFetchSell = () => {
        controllerSellTop10Products && controllerSellTop10Products.abort && controllerSellTop10Products.abort();
        controllerSellTop10CatalogCodes && controllerSellTop10CatalogCodes.abort && controllerSellTop10CatalogCodes.abort();
        controllerSellTop10Identity && controllerSellTop10Identity.abort && controllerSellTop10Identity.abort();
        controllerSellPercentageForLine && controllerSellPercentageForLine.abort && controllerSellPercentageForLine.abort();
        controllerSellPercentageForSeries && controllerSellPercentageForSeries.abort && controllerSellPercentageForSeries.abort();
        controllerSellPercentageForCategories && controllerSellPercentageForCategories.abort && controllerSellPercentageForCategories.abort();
        controllerSellPercentageForNazione && controllerSellPercentageForNazione.abort && controllerSellPercentageForNazione.abort();
        controllerSellPercentageForRegions && controllerSellPercentageForRegions.abort && controllerSellPercentageForRegions.abort();
        controllerSellPercentageForProvince && controllerSellPercentageForProvince.abort && controllerSellPercentageForProvince.abort();
        controllerSellPercentageForCity && controllerSellPercentageForCity.abort && controllerSellPercentageForCity.abort();
    }

    onTabChanged = (index) => {
        this.setState({tabIndex: index});
        
        //abort all controllers


        // Load Catalogo
        if(index === 0) {
            this.loadData();
            //abort fetches that aren't needed in this index

        }
        // Load News
        if(index === 1) {
            this.loadDataNews();
            //abort fetches that aren't needed in this index
            this.abortFetchCatalogo();

        }
        // Load Adv
        if(index === 2) {
            this.loadDataAdv();
            //abort fetches that aren't needed in this index
            this.abortFetchCatalogo();
        }
        // Load Access
        if(index === 3) {
            this.loadDataUserAccess();
            //abort fetches that aren't needed in this index
            this.abortFetchCatalogo();
        }

        if(index == 4) {
            this.loadDataBuy();
            //abort fetches that aren't needed in this index
            this.abortFetchCatalogo();
        }

        if(index == 5) {
            this.loadDataSell();
            //abort fetches that aren't needed in this index
            this.abortFetchCatalogo();
        }
    }





    render() {
        var locTabs = [
            {
                tabName: getTranslation(7, "BI_CATALOGUE", this.props.translations, "Catalogo"),
                tabContent: this.renderTabCatalogue(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.onActivateCatalogueFilters
                    }
                ]
            },


            {
                tabName: getTranslation(7, "BI_NEWS", this.props.translations, "News"),
                tabContent: this.renderTabCrm(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.onActivateCRMFilters
                    }
                ]
            },

            {
                tabName: getTranslation(7, "BI_ADV", this.props.translations, "Adv"),
                tabContent: this.renderTabAdvCrm(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.onActivateCRMAdvFilters
                    }
                ]
            },

            {
                tabName: getTranslation(7, "BI_ACCESS", this.props.translations, "Accessi"),
                tabContent: this.renderTabAccess(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.onActivateAccessFilters
                    }
                ]
            },

            {
                tabName: getTranslation(7, "BI_BUY", this.props.translations, "Aquisti"),
                tabContent: this.renderTabBuy(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.onActivateCatalogueFilters
                    }
                ]
            },

            {
                tabName: getTranslation(7, "BI_SELL", this.props.translations, "Vendite"),
                tabContent: this.renderTabSell(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.onActivateCatalogueFilters
                    }
                ]
            },
        ];


        return <Fragment>
            <Tabs
                headerColor="primary"
                plainTabs={true}
                tabs={locTabs}
                onTabChange={this.onTabChanged}
            />
            <XMessageBox innerRef={this.XMessage} />
        </Fragment>;
    }
}


const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(authCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(CatalogStats);