import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import XExpansionPanel from '../components/XExpansionPanel';
import Typography from '@material-ui/core/Typography';


import XTag from '../components/XTag';
import XSelect from '../components/XSelect';
//import XSwitch from '../components/XSwitch';
import XField from '../components/XField';
//import XButton from '../components/XButton';
import Grid from '@material-ui/core/Grid';
import XSwitch from '../components/XSwitch';
import XGrid from '../components/XGrid';
import XButton from '../components/XButton';

import { Done, Close } from '@material-ui/icons';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

class CustomField extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    state = {
        data: {},
        isValueEditing: false,
        currentValue: {},
		snackbar: {
			open: false,
			message: undefined,
		},
    };
    componentDidMount() {
        //this.clearSession();
        this.loadData();
    }
    loadData = () => {
        if (this.props.match.params.id && parseInt(this.props.match.params.id,10) > 0) {
            fetch('/Base/GetCustomField/' + this.props.match.params.id)
                .then(res => res.json())
                .then(data => {
                    this.setState({ data });
                });
        }
    }
    handleSelectFieldChange = (key) => (event, value) => {
        //this.props.onChange(event, value, key);
        this.setState((state, props) => {
            if (value.length > 0) {
                state.data[key] = value[0].ID;
            }
            else {
                state.data[key] = undefined;
            }
            return state;
        });
    }
    handleFieldChange = (key) => (event, value) => {
        this.setState((state, props) => {
            if (value !== undefined || value !== null || !isNaN(value)) {
                state.data[key] = value;
            }
            else {
                state.data[key] = undefined;
            }
            return state;
        });
    }
    renderTab1 = () => {
        var { data } = this.state;
        var panel1Fields = !data.FIDExtra && (<Fragment>
            <Grid item xs={12} sm={6} md={3}><XSelect clearable label="Tab" id="FIDExtraTab" urlData="/Base/GetExtraTabs" value={data.FIDExtraTab} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
            <Grid item xs={12} sm={6} md={3}><XSelect clearable label="Gruppo" id="FIDExtraGroup" urlData="/Base/GetExtraGroups" value={data.FIDExtraGroup} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
        </Fragment>);
        var panels = [
            {
                icon: "people",
                label: "Dati generali",
                content: (<Grid container spacing={2}>
                    <Grid item container xs={12} direction="row" spacing={2}>
                        <Grid item xs={12} sm={6} md={3}><XField label="Sigla" id="AbbreviationStr" value={data.AbbreviationStr} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XField label="Descrizione" id="DescriptionStr" value={data.DescriptionStr} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XSelect clearable label="Campo" id="FIDExtra" urlData="/Base/GetTableExtras" value={data.FIDExtra} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
                        {panel1Fields}
                        <Grid item xs={12} sm={6} md={3}><XSwitch label="Commerciale" id="IsCommercial" value={data.IsCommercial} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
                    </Grid>
                </Grid>),
            }];
        if (!data.FIDExtra) {
            panels.push(
                {
                    icon: "people",
                    label: "Visibilità distinte",
                    content: (<Grid container spacing={2}>
                        <Grid item container xs={12} direction="row" spacing={2}>
                            <Grid item xs={12} sm={6} md={3}><XTag label="Gruppo" id="GroupIds" urlData="/Base/GetBOPGroups" value={data.GroupIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>
                            <Grid item xs={12} sm={6} md={3}><XTag label="Tipologia" id="TypologyIds" urlData="/Base/GetBOPTypologies" value={data.TypologyIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>
                            <Grid item xs={12} sm={6} md={3}><XTag label="Categoria" id="CategoryIds" urlData="/Base/GetBOPCategories" value={data.CategoryIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>
                        </Grid>
                    </Grid>),
                });
            panels.push(
                {
                    icon: "people",
                    label: "Visibilità anagrafiche",
                    content: (<Grid container spacing={2}>
                        <Grid item container xs={12} direction="row" spacing={2}>
                            <Grid item xs={12} sm={6} md={3}><XTag label="Gruppo" id="IRGroupIds" urlData="/Base/GetIRGroups" value={data.IRGroupIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>
                            <Grid item xs={12} sm={6} md={3}><XTag label="Tipologia" id="IRTypologyIds" urlData="/Base/GetIRTypologies" value={data.IRTypologyIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>
                            <Grid item xs={12} sm={6} md={3}><XTag label="Categoria" id="IRCategoryIds" urlData="/Base/GetIRCategories" value={data.IRCategoryIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>
                        </Grid>
                    </Grid>),
                });
        }
        var panelOptions = (data.Type === 5 || data.Type === 6) && (
            <Fragment>
                <Grid item xs={12} sm={6} md={3}><XSelect label="Tipo ordinamento" id="OrderMode" urlData="/Base/GetEnumValues/ExtraOrderMode" value={data.OrderMode} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
                <Grid item xs={12} sm={6} md={3}><XSwitch label="Nuovi valore" id="AllowNewValue" value={data.AllowNewValue} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
            </Fragment>
        );
        panels.push(
            {
                icon: "people",
                label: "Opzioni",
                content: (<Grid container spacing={2}>
                    <Grid item container xs={12} direction="row" spacing={2}>
                        <Grid item xs={12} sm={6} md={3}><XField label="Ordinamento" id="Order" value={data.Order} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XSelect label="Richiesto" id="Required" urlData="/Base/GetEnumValues/RequiredType" value={data.Required} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XSelect label="Tipo" id="Type" urlData="/Base/GetEnumValues/ExtraTypes" value={data.Type} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XSelect label="Tipo ricerca" id="SearchType" urlData="/Base/GetEnumValues/ExtraSearchTypes" value={data.SearchType} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
                        {panelOptions}
                        
                    </Grid>
                </Grid>),
            });
        var panelVariations = data.IsVariation && (<Fragment>
            <Grid item xs={12} sm={6} md={3}><XField label="Colonna raggruppamento" id="GroupColumn" value={data.GroupColumn} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
            <Grid item xs={12} sm={6} md={3}><XSelect label="Tipo campo variation" id="VariationViewType" urlData="/Base/GetEnumValues/ExtraVariationViewType" value={data.VariationViewType} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
            <Grid item xs={12} sm={6} md={3}><XSelect label="Tipo riepilogo" id="SummaryType" urlData="/Base/GetEnumValues/ExtraComponentSummaryType" value={data.SummaryType} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
            <Grid item xs={12} sm={6} md={3}><XField label="Colonna valore" id="ValueColumn" value={data.ValueColumn} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
            <Grid item xs={12} sm={6} md={3}><XSelect label="UM Principale" id="FIDMeasureUnit" urlData="/Base/GetGCMeasureUnits" value={data.FIDMeasureUnit} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
            <Grid item xs={12} sm={6} md={3}><XSelect label="Campo custom per raggruppamento" id="FIDVariationExtra" urlData="/Base/GetMAGExtras" value={data.FIDVariationExtra} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleSelectFieldChange} /></Grid>
        </Fragment>);
		
		if(data.Type === 5) {
			panels.push(
            {
                icon: "people",
                label: "Dati varianti",
                content: (<Grid container spacing={2}>
                    <Grid item container xs={12} direction="row" spacing={2}>
                        <Grid item xs={12} sm={6} md={3}><XSwitch label="Variazione" id="IsVariation" value={data.IsVariation} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
                        {panelVariations}
                    </Grid>
                </Grid>),
            });
		}
        return (<TabContainer><XExpansionPanel panels={panels} /></TabContainer>);
    }
    handleGridNew = () => {
        this.setState({ isValueEditing: true, currentValue: {} });
    }
    handleGridDoubleClick = (event, data) => {
        this.setState({ isValueEditing: true, currentValue: data});
    }

    handleValueFieldChange = (key) => (event, value) => {
        this.setState((state, props) => {
            state.currentValue[key] = value;
            return state;
        });
    }
    handleCancelValue = () => {
        this.setState({ isValueEditing: false, currentValue: {} });
    }
    handleSaveValue = () => {

        var { currentValue } = this.state;
        currentValue.FIDExtra = this.props.match.params.id;
        if (!currentValue.ID) {
            currentValue.ID = 0;
        }
        fetch('/Base/SaveCustomFieldValue/' + currentValue.ID, {
            body: JSON.stringify(currentValue),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                var props = Object.getOwnPropertyNames(currentValue);
                for (var i = 0; i < props.length; i++) {
                    if (typeof currentValue[props[i]] === "string")
                        currentValue[props[i]] = "";
                    else
                        currentValue[props[i]] = undefined;
                }
                this.setState({ isValueEditing: false, currentValue: currentValue });
                //this.grid.current.getWrappedInstance().refresh();
            });



        this.setState({ isValueEditing: false, currentValue: {} });
    }
    renderTab2 = () => {
        var { isValueEditing, currentValue } = this.state;
        var gridName = "tblCustomFieldValues";
        var content = isValueEditing ?
            (
                <Grid item container spacing={2} direction="row" sx={12}>
                    <Grid item xs={12} sm={6} md={3}><XField label="Posizione" id="Position" type="number" value={currentValue.Position} onChange={this.handleValueFieldChange} /></Grid>
                    <Grid item xs={12} sm={6} md={3}><XField label="Sigla" id="Abbreviation" value={currentValue.Abbreviation} onChange={this.handleValueFieldChange} /></Grid>
                    <Grid item xs={12} sm={6} md={3}><XField label="Valore" id="Value" value={currentValue.Value} onChange={this.handleValueFieldChange} /></Grid>
                    <Grid item container direction="row" xs={8} sm={4} md={2} justify="flex-end" alignItems="baseline">
                        <XButton variant="contained" color="primary" justIcon round size="sm" onClick={this.handleSaveValue}>
                            <Done />
                        </XButton>
                        <XButton variant="contained" color="primary" justIcon round size="sm" onClick={this.handleCancelValue}>
                            <Close />
                        </XButton>
                    </Grid>
                </Grid>
            ) :
            (
                <Grid item xs={12}>
                    <XGrid
                        dataRoute={"/Base/GetCustomFieldValues?FIDCustomField=" + this.props.match.params.id + "&grid=" + gridName}
                        dataRouteColumns={"/Base/GetColumns?grid=" + gridName}
                        dataRouteAvailableColumns={"/Base/GetAvailableColumns?grid=" + gridName}
                        dataRouteUpdateColumns={"/Base/UpdateColumns?grid=" + gridName}
                        onDoubleClick={this.handleGridDoubleClick}
                        onNewButton={this.handleGridNew}
                    />
                </Grid>
            );


        var panels = [
            {
                icon: "people",
                label: "Dati generali",
                content: (content),
            }
        ];
        return (<TabContainer><XExpansionPanel panels={panels} /></TabContainer>);
    }

    handleExit = (event) => {
        //fetch('/IR/ClearSession', {
        //    method: 'POST'
        //}).then(res => {
            this.context.router.history.push('/CFFields/');
        //});
    }
    handleNewData = (event, caller) => {
        if (this.context.router.route.location.pathname === '/CFNew') {
            //fetch('/IR/ClearSession', {
            //    method: 'POST'
            //}).then(res => {
                this.setState({ data: {} });
            //});
        }
        else {
            //this.clearSession(() => {
                this.context.router.history.push('/CFNew');
            //});
        }
    }

    handleCancelData = (event, caller) => {
        var FIDCustomField = this.props.match.params.id;
        if (!FIDCustomField) {
            FIDCustomField = 0;
        }
        fetch('/Base/DeleteCustomField', {
            method: 'POST',
            body: JSON.stringify([FIDCustomField])
        }).then(res => {
			this.setState({ snackbar: { open: true, message: "Cancellazione effettuata correttamente" } }, () => {
				setTimeout(() => { this.context.router.history.push('/CFFields'); }, 1500);
			});
        }).catch(error => {
            this.setState({ isLoading: false, snackbar: { open: true, message: "Impossibile cancellare il campo custom" } });
        });
    }
    handleSaveData = (event, caller) => {
        this.setState({ isLoading: true }, () => {

            var { data } = this.state;
            var FIDCustomField = this.props.match.params.id;
            if (!FIDCustomField) {
                FIDCustomField = 0;
            }
            if (typeof data.IRGroupIds === "string") {
                data.IRGroupIds = data.IRGroupIds.split(',').map(Number);
            }
            if (typeof data.IRTypologyIds === "string") {
                data.IRTypologyIds = data.IRTypologyIds.split(',').map(Number);
            }
            if (typeof data.IRCategoryIds === "string") {
                data.IRCategoryIds = data.IRCategoryIds.split(',').map(Number);
            }
            if (typeof data.GroupIds === "string") {
                data.GroupIds = data.GroupIds.split(',').map(Number);
            }
            if (typeof data.TypologyIds === "string") {
                data.TypologyIds = data.TypologyIds.split(',').map(Number);
            }
            if (typeof data.CategoryIds === "string") {
                data.CategoryIds = data.CategoryIds.split(',').map(Number);
            }

            fetch('/Base/SaveCustomField/' + FIDCustomField, {
                body: JSON.stringify(data),
                method: 'POST'
            }).then(res => res.json()).then((data) => {
                if (this.props.match.params.id === undefined || parseInt(this.props.match.params.id, 10) === 0) {
					this.setState({ snackbar: { open: true, message: "Salvataggio effettuato correttamente" } }, () => {
						setTimeout(() => { this.context.router.history.push('/CFEdit/' + data.id); }, 1500);
					});
                } else {
					this.setState({ snackbar: { open: true, message: "Salvataggio effettuato correttamente" } });
				}
                this.loadData();
                this.setState({ isLoading: false });
            }).catch(error => {
                this.setState({ isLoading: false, snackbar: { open: true, message: "Salvataggio fallito" } });
            });
        });
    }
	
	renderSnackbar() {
		var { snackbar } = this.state;
		return <Snackbar
				open={snackbar.open}
				onClose={this.snackbarClose}
				TransitionComponent={props => <Slide {...props} direction="up" />}
				ContentProps={{
						'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">{snackbar.message}</span>}
				autoHideDuration={1500}
		/>;
	}

    render() {
        var { data } = this.state;
        var { classes } = this.props;
        var loctabs = [
            {
                tabName: "Generale",
                tabContent: this.renderTab1(),

            },
        ];
        if (data.Type === 5 || data.Type === 6) {
            loctabs.push({
                tabName: "Valori",
                tabContent: this.renderTab2(),
            })
        }
        return (
            <div className={classes.root}>
                <Tabs
                    headerColor="primary"
                    plainTabs={true}
                    onExitClick={this.handleExit}
                    onSaveClick={this.handleSaveData}
                    onDeleteClick={this.handleCancelData}
                    onNewClick={this.handleNewData}
                    tabs={loctabs}
                />
				{this.renderSnackbar()}
            </div>
        );
    }
}




const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(CustomField);