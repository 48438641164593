import React, { Fragment } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import Swipe from 'react-easy-swipe';
// creates a beautiful scrollbar
//import PerfectScrollbar from "perfect-scrollbar";
//import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "routes/dashboard.jsx";
import dashboardCustomer from "routes/dashboard_customer.jsx";
import dashboardProduction from "routes/dashboard_production.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-3.jpg";
import logo from "assets/img/logo-white.svg";
import logoLicensee from "assets/img/logo-licensee.png"
import Login from 'views/Pages/LoginPage';

import { parseParam } from 'utils';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from '../store/Auth';
import { actionCreators as globalCreators } from '../store/Global';
import { actionCreators as localizationCreators } from '../store/Localization';
import { matchPath } from 'react-router';

import IdleTimer from 'react-idle-timer';
import VideoChatManager from '../views/Videochat/VideoChatManager';
import { SnackbarProvider } from 'notistack';
import { actionCreators as userSignalCreators } from '../views/Videochat/UserSignaler';
import Videochat from '../views/Videochat/Videochat';


function getPosition(event, touch=1) {
    if('touches' in event){
        if(event.touches.length >= touch){
            const { pageX, pageY } = event.touches[touch-1];
            return { x: pageX, y: pageY };
        }
    }
}
//var ps;

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileOpen: false,
			miniActive: false,
			menus: [],
			//isProductionOperator: false,
		};
        this.idleTimer = React.createRef();
		this.resizeFunction = this.resizeFunction.bind(this);
		this.logincheck = undefined;
	}

	static contextTypes = {
		router: PropTypes.object
	}
	//componentWillMount = async() => {
    //    if(!this.props.isLoggedIn){
    //        this.props.checkLogin();
    //    }
    //
    //    this.props.setMenus(this.state.menus);
	//	await fetch('/Base/GetMenus').then(res => res.json()).then(data => {
	//		this.setState({ menus: data.menus, miniActive: data.isProductionOperator });
	//		this.props.setMenus(data.menus);
	//	});
    //
    //    this.props.getTranslations();
	//}
	componentDidMount = async() => {
		if(!this.props.isLoggedIn){
            this.props.checkLogin();
        }

        this.props.setMenus(this.state.menus);
		await fetch('/Base/GetMenus').then(res => res.json()).then(data => {
			this.setState({ menus: data.menus, miniActive: data.isProductionOperator });
			this.props.setMenus(data.menus);
		});

        this.props.getTranslations();
		if (navigator.platform.indexOf("Win") > -1) {
			//ps = new PerfectScrollbar(this.refs.mainPanel, {
			//    suppressScrollX: true,
			//    suppressScrollY: false
			//});
			document.body.style.overflow = "auto";
		}
		window.addEventListener("resize", this.resizeFunction);
		this.loadParams();

		this.loginCheck = setInterval(() => {
			var currCookie = document.cookie;
			if (!currCookie.includes("_Username")) {
				this.clearLoginCheck();
				
				this.props.isLoggedIn && this.props.logout(); 
			}
		}, 1000)
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			//ps.destroy();
		}
		window.removeEventListener("resize", this.resizeFunction);
	}
	componentDidUpdate(e) {
		if (e.history.location.pathname !== e.location.pathname) {
			if(this.refs && this.refs.mainPanel)
			{
				this.refs.mainPanel.scrollTop = 0;
				if (this.state.mobileOpen) {
					this.setState({ mobileOpen: false });
				}
			}
		}
	}	clearLoginCheck = () => {
		clearInterval(this.loginCheck);
	}
	loadParams = () => {

		fetch('/GC/params').then(res => res.json()).then(data => {
			//Object.keys(data).map(function (key, index) { data[key] = JSON.parse(data[key]) })
			var keys = Object.keys(data);
			for(var i in keys){
				var key = keys[i];
				var val =data[key];
				if(typeof val === "string"){
					try {
						val = JSON.parse(val);
					}
					catch(err) {
						console.error(err);
					}
				}
				data[key]= val;
			}
			this.props.setParams(data);
		});
		// fetch('/Base/IsProductionOperator').then(res => res.json()).then(data => {
		// 	this.props.setProductionOperator(data);
		// 	//this.setState({ isProductionOperator: data })
		// })
		fetch('/Base/GetCurrentFIDIdentity').then(res => res.json()).then(data => {
			this.props.setIdentity(data);
		});
		fetch('/Base/GetCatalogueButtons').then(res => res.json()).then(data => {
			this.props.setCatalogueButtons(data);
		});
		fetch('/Base/GetLabels').then(res => res.json()).then(data => {
			this.props.setLabels(data);
		});
	}
	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};
	sidebarMinimize() {
		this.setState({ miniActive: !this.state.miniActive });
	}
	resizeFunction() {
		if (window.innerWidth >= 960) {
			this.setState({ mobileOpen: false });
		}
	}
	loggedIn = () => {
		//this.props.checkLogin();
        // var username = getCookie("username");
        // if (username) {
        //     return true;
        // }
		return this.props.isLoggedIn;
	};
    switchRoutes = (isLoggedIn, isProductionOperator) => {
		var routes =  dashboardRoutes;
		console.log(isLoggedIn,isProductionOperator);
		if(this.props.FIDProfile === this.props.params.CustomerTypologyId) {
			routes = dashboardCustomer;
		}
		else if (this.props.isAgent) {
			routes = dashboardCustomer;
		}
		else if(isProductionOperator) {
			routes = dashboardProduction;
		}
        var homePathRedirect = routes[routes.length-1].pathTo;
        if(!this.props.menus.includes(homePathRedirect.substring(1))) {
            var route = routes.filter(r => this.props.menus.includes(r.id) && !r.hidden)[0];
            if(route){
                homePathRedirect = route.path;
            }
        };


        return (
            <Switch>
                {routes.map((prop, key) => {
                    if (prop.id && !this.props.menus.includes(prop.id)) {
                        return <Redirect from={prop.path} to={homePathRedirect} key={key} />;
                    }
                    if (prop.redirect)
                        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                    if (prop.collapse) {
                        return prop.views.map((prop, key) => {
                            var collapseComponent = ((prop.requireLogin && isLoggedIn) || !prop.requireLogin) ? prop.component : Login;
                            if ((prop.requireLogin && isLoggedIn) || !prop.requireLogin || !prop.hidden) {
                                return (
                                    <Route path={prop.path} component={collapseComponent} key={key} />
                                );
                            }
                            else {
                                return <Redirect from={prop.path} to="/auth/login" key={key} />;
                            }
                        });
                    }
                    var component = ((prop.requireLogin && isLoggedIn) || !prop.requireLogin) ? prop.component : Login;
                    if ((prop.requireLogin && isLoggedIn) || !prop.requireLogin) {
                        return <Route path={prop.path} component={component} key={key} />;
                    }
                    else {
                        return <Redirect from={prop.path} to="/auth/login" key={key} />;
                    }
                })}
            </Switch>
        );
    }
	renderProfile = () => {
		var { originalUser } = this.props;
		if (!originalUser) {
			return false;
		}
		else {
			return <Fragment>
				<div style={{ position: 'absolute', zIndex: 999999, top: 0, width: '100vw', height: 5, background: 'red' }} />
				<div style={{ position: 'absolute', zIndex: 999999, bottom: 0, width: '100vw', height: 5, background: 'red' }} />
				<div style={{ position: 'absolute', zIndex: 999999, left: 0, width: 5, height: '100vh', background: 'red' }} />
				<div style={{ position: 'absolute', zIndex: 999999, right: 0, width: 5, height: '100vh', background: 'red' }} />
			</Fragment>;
		}
	}
	swipeLeft = (_, event) => {
		if (!event.altKey) {
			return;
		}
        var swipeRoutes = (this.props.FIDProfile === this.props.params.CustomerTypologyId ? dashboardCustomer : dashboardRoutes).filter(r => r.canSwipe).map(r => {
			r.exact = true;
			return r;
		});
		var currentIndex = -2;
		for (var i = 0; i < swipeRoutes.length; i++) {
			if (matchPath(this.props.location.pathname, swipeRoutes[i])) {
				currentIndex = i;
				break;
			}
		}

		currentIndex++;
		if (currentIndex !== -1 && currentIndex < swipeRoutes.length) {
			this.context.router.history.push(swipeRoutes[currentIndex].path);
		}
	}
	swipeRight = (_, event) => {
		if (!event.altKey) {
			return;
		}
        var swipeRoutes = (this.props.FIDProfile === this.props.params.CustomerTypologyId ? dashboardCustomer : dashboardRoutes).filter(r => r.canSwipe).map(r => {
			r.exact = true;
			return r;
		});
		var currentIndex = -2;
		for (var i = 0; i < swipeRoutes.length; i++) {
			if (matchPath(this.props.location.pathname, swipeRoutes[i])) {
				currentIndex = i;
				break;
			}
		}
		currentIndex--;
		if (currentIndex !== -1 && currentIndex >= 0) {
			this.context.router.history.push(swipeRoutes[currentIndex].path);
		}
	}
	onTouchStart = (ev) => {
		if (ev.touches && ev.touches.length === 2) {
			this.moveStart = getPosition(ev);
			this.moveStart2 = getPosition(ev, 2);
		}
	}
	onTouchEnd = (ev) => {
		ev.altKey = true;
		const tolerance = 10;

		if (this.moving && this.movePosition) {
			if (this.movePosition.x < -tolerance) {
				this.swipeLeft(1, ev);
			} else if (this.movePosition.x > tolerance) {
				this.swipeRight(1, ev);
			}
			// if (this.movePosition.y < -tolerance) {
			//   this.onSwipeUp(1, ev);
			// } else if (this.movePosition.y > tolerance) {
			//   this.onSwipeDown(1, ev);
			// }
		}

		this.moveStart = null;
		this.moving = false;
		this.movePosition = null;

		this.moveStart2 = null;

	}
	onTouchMove = (ev) => {
		if (!this.moveStart) {
			return;
		}
		const touch1 = getPosition(ev);
		const touch2 = getPosition(ev, 2);
		const deltaX = touch1.x - this.moveStart.x;
		const deltaY = touch1.y - this.moveStart.y;
		const deltaX2 = touch2.x - this.moveStart2.x;
		const deltay2 = touch2.y - this.moveStart2.y;
		if ((deltaX2 > 0 && deltaX < 0) || (deltaX2 < 0 && deltaX > 0)) {
			this.moving = false;
			this.movePosition = null;
		}
		else {
			this.moving = true;
			this.movePosition = { x: deltaX, y: deltaY };
		}

	}
    onIdle = (e) => {
        if(this.props.params.Autologout){
            this.props.logout(); 
        }
    }
	render() {
		const { classes, isProductionOperator, ...rest } = this.props;
		var isLoggedIn = this.loggedIn();
		
		if (!isLoggedIn) {
			this.context.router.history.push("/auth/login");
		}
		const mainPanel =
			classes.mainPanel +
			" " +
			cx({
				[classes.mainPanelSidebarMini]: this.state.miniActive,
				[classes.mainPanelWithPerfectScrollbar]:
					navigator.platform.indexOf("Win") > -1
			});
        //image = undefined;
        var image = undefined;

		var videoChatKey = "VIDEOCHAT";
		const iAmInRoom = this.props.location.pathname === "/VideoChat";
		const buildRoom = this.props.roomName && this.props.roomToken;
		var videochatCollapsed = !iAmInRoom;
		var videochatComponent = (<Videochat key={videoChatKey}
										collapsed={videochatCollapsed}/>)
		
		return (
			<div>
				<SnackbarProvider maxSnack={10}>
					<VideoChatManager key="VIDEOCHATMANAGER" collapsedChat={videochatCollapsed}/>
				</SnackbarProvider>
				{buildRoom && videochatComponent}
				{(!iAmInRoom) && (			
					<div className={classes.wrapper}>
						<IdleTimer 
							ref={this.idleTimer}
							element={document}
							onIdle={this.onIdle}
							debounce={250}
							timeout={1000 * 60 * (this.props.params.AutologoutTime || 10)}
						/>
						<Sidebar
							menus={this.state.menus}
							routes={(this.props.FIDProfile === this.props.params.CustomerTypologyId ? dashboardCustomer : dashboardRoutes)}
							logoText={window.logoText}
							logo={logoLicensee}
							image={image}
							handleDrawerToggle={this.handleDrawerToggle}
							open={this.state.mobileOpen}
							color="primary"
							bgColor="black"
							miniActive={this.state.miniActive}
							username={this.props.username}
							logout={this.props.logout}
							{...rest}
						/>
						<div id="mainPanel" className={mainPanel} ref="mainPanel" onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} onTouchMove={this.onTouchMove}>
							<Header
								menus={this.state.menus}
								sidebarMinimize={this.sidebarMinimize.bind(this)}
								miniActive={this.state.miniActive}
								routes={(this.props.FIDProfile === this.props.params.CustomerTypologyId ? dashboardCustomer : dashboardRoutes)}
								handleDrawerToggle={this.handleDrawerToggle}
								title={this.props.title}
								{...rest}
							/>

							<Swipe
								allowMouseEvents={true}
								onSwipeLeft={this.swipeLeft}
								onSwipeRight={this.swipeRight}
							>
								<div id="mainContent" className={classes.content}>
									<div className={classes.container}>{this.switchRoutes(isLoggedIn, isProductionOperator)}</div>
								</div>
							</Swipe>
						</div>
						{this.renderProfile()}
					</div>
				)}
			</div>		
		);
	}
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
	connect(
		state => state.userSignaler,
		dispatch => bindActionCreators(userSignalCreators, dispatch)
	),    withStyles(appStyle)
);

//export default withStyles(appStyle)(Dashboard);
export default enhance(Dashboard);
