import React, { Component,Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import XDialog from './XDialog';
import XCard from './XCard';
import XGridRow from './XGridRow';
import XGridEditableRow from './XGridEditableRow';
import XOXLoader from 'components/XOXLoader';
import PerfectScrollbar from "perfect-scrollbar";
import { Collection, List } from 'react-virtualized';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { Check } from 'mdi-material-ui';
import { getScrollbarWidth } from 'utils';
import { withWidth } from '@material-ui/core';
import ReactExport from "react-export-excel";
import { Typography } from '@material-ui/core';


import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = theme => ({
	root: {
		width: '100%',
		//marginTop: theme.spacing(3),
		fontSize: '1em',
		direction: 'ltr',
	},
	rootNoShadow: {
		direction: 'ltr',
		width: '100%',
		//marginTop: theme.spacing(3),
		fontSize: '1em',
		boxShadow: '0 0 0',
	},
	table: {
		minWidth: "100%",
		fontSize: '1em',
	},
	tableWrapper: {
		overflowX: 'auto',
		overflowY: 'hidden',
		fontSize: '14px',
	},
	tableCell: {
		fontSize: '0.8em',
		padding: "0 10px 0",
	},
	searchField: {
		margin: '5px',
	},


	sidebarWrapper: {
        position: "relative",
        height: "calc(100vh - 75px)",
        overflow: "auto",
        // width: "260px",
        zIndex: "4",
        overflowScrolling: "touch",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        color: "inherit",
        //paddingBottom: "30px"
    },
    sidebarWrapperWithPerfectScrollbar: {
        overflow: "hidden !important"
    },
});

const EXPANSION_SIZE = 300;
const TOOLBAR_HEIGHT = 85;


class XGrid extends Component {
	constructor(props) {
		super(props);
		this.rowsContainer = React.createRef();
		this.Collection = React.createRef();
		this.InnerList = React.createRef();
		this.expandedRowGrid = React.createRef();
		this.state = {
			order: 'asc',
			orderBy: 'ID',
			selected: [],
			data: [],
			count: 0,
			totalCount: 0,
			loadedPage: 0,
			itemsPerPage: 1000000,
			lastPage: false,
			finishLoading: true,
			showColumnsSelector: false,
			showConfigureLayouts: false,
			columns: [],
			allColumns: [],
			page: 0,
			rowsPerPage: 5,
			sortColumn: "",
			sortDirection: "",
			lastFilterType: 0,
			showFilters: false,
			filters: {},
			selectAll: false,
			isMounted: true,
			firstLoad: true,
			dialog: {},
			viewType: props.defaultViewType||'list',
			scrollTop: false,
			contentWidth: 100,
			contentHeight: 100,
			rowsMaxHeight: {},
			firstLoad: true,
			exporting: false,
			expandedRow: undefined,
			expandedRowHeight: EXPANSION_SIZE,
			height: undefined,
			documentExported: undefined,
			style: ""
		};
	}

	componentDidMount() {
		var { containerID } = this.props;
		if (!containerID) {
			containerID = 'mainContent';
		}
		if (window.ResizeObserver !== undefined) {

			this.resizeObserver = new window.ResizeObserver((entries) => {
				this.setState({
					contentWidth: entries[0].contentRect.width,
					contentHeight: entries[0].contentRect.height,
					lastWidthChange: Date.now()
				}, () => {
					setTimeout(() => {
						if (this.Collection.current && (Date.now() - this.state.lastWidthChange > 100)) {
							this.Collection.current.recomputeCellSizesAndPositions();
						}

					}, 100);
				});
			});
			this.resizeObserver.observe(document.getElementById(containerID));
		}
		else {
			this.setState({ contentWidth: document.getElementById(containerID).clientWidth });
			window.addEventListener('resize', this.mainContentResize);
		}

		this.getColumns();
		if (this.props.dataRouteAvailableColumns) {
			fetch(this.props.dataRouteAvailableColumns)
				.then(res => res.json())
				.then(columns => this.setState(() => {
					return { allColumns: columns, isMounted: true };
				}));
		}
		
		if(!!this.props.viewType && this.props.viewType !== this.state.viewType) {
			this.setState({viewType: this.props.viewType});
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.dataRouteColumns !== this.props.dataRouteColumns) {
			this.getColumns();
		}
		if (prevProps.dataRouteAvailableColumns !== this.props.dataRouteAvailableColumns) {
			if (this.props.dataRouteAvailableColumns) {
				fetch(this.props.dataRouteAvailableColumns)
					.then(res => res.json())
					.then(columns => this.setState(() => {
						return { allColumns: columns, isMounted: true };
					}));
			}
		}
		if(!!this.props.viewType && prevProps.viewType !== this.props.viewType && this.props.viewType !== this.state.viewType) {
			this.setState({viewType: this.props.viewType});
		}
	}
	componentWillUnmount() {
		var { onGridUnmounted } = this.props;
		this.setState({ isMounted: false });
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
		window.removeEventListener('resize', this.mainContentResize);

		onGridUnmounted && onGridUnmounted(this);
	}
	mainContentResize = () => {
		var { containerID } = this.props;
		if (!containerID) {
			containerID = 'mainContent';
		}
		var element = document.getElementById(containerID);

		this.setState({
			contentWidth: element.clientWidth,
			contentHeight: element.clientHeight,
			lastWidthChange: Date.now()
		}, () => {
			setTimeout(() => {
				if (this.Collection.current && (Date.now() - this.state.lastWidthChange > 100)) {
					this.Collection.current.recomputeCellSizesAndPositions();
				}
			}, 100);
		});
	}
	saveColumsConfiguration() {
		var { columns } = this.state;
		fetch(this.props.dataRouteUpdateColumns, {
			body: JSON.stringify(columns),
			method: 'POST'
		});
	}
	getColumns() {
		if (this.props.dataRouteColumns) {
			fetch(this.props.dataRouteColumns)
				.then(res => res.json())
				.then(config => this.setState((prev, props) => {
					var filters = {};
					if (config.filters !== "") {
						JSON.parse(config.filters).map((item) => {
							if (item && ((typeof item.term === "string" && item.term !== "") || typeof item.term !== "string")) {
								filters[item.key] = item;
							}
							return item;
						});
					}
					var sortColumn = config.sortColumn;
					var sortDirection = config.sortDirection;

					if (sortColumn === null) {
						sortColumn = "";
					}
					if (sortDirection === null) {
						sortDirection = "";
					}
					var showFilters = prev.showFilters;
					if (prev.firstLoad && Object.keys(filters).length > 0) {
						showFilters = true;
					}
					return { columns: JSON.parse(config.columns), filters: filters, sortColumn: sortColumn, sortDirection: sortDirection, showFilters: showFilters, firstLoad: false };
				})).then(() => {

					let skip = this.state.loadedPage * this.state.itemsPerPage;
					let items = this.state.itemsPerPage;
					this.loadRows(skip, items);
				});
		}
	}
	loadRows(skip, items, callback) {
		if (!this.state.isMounted || !this.props.dataRoute) {
			return;
		}
		skip = 0;


		this.setState((prev, props) => {
			return { finishLoading: false };
		}, () => {
			let tempFilters = this.state.filters;
			let filters = encodeURIComponent(JSON.stringify(Object.keys(tempFilters).map(function (s) { return tempFilters[s]; }).filter(f => f.term !== "")));
			fetch(this.props.dataRoute + "&skip=0&items=9999999&sortColumn=" + this.state.sortColumn + "&sortDirection=" + this.state.sortDirection + "&filters=" + filters)
				.then(res => res.json())
				.then(json => this.setState((prev, props) => {
					var { selected } = this.state;
					var { selectedRows } = this.props;

					if (!selected)
						selected = [];

					if (!json.data || json.data.length === 0)
						return { data: [], selected: selected, count: 0, loadedPage: this.state.loadedPage + 1, lastPage: lastPage, finishLoading: true, export: json.canExport };

					var newBops = json.data;
					//var empty = new Array(json.count - newBops.length);
					//empty.fill({});
					//var rows = newBops.concat(empty);
					var lastPage = json.data.length < this.state.itemsPerPage;
					//var selected = this.state.selectAll ? newBops.map(n => n.ID) : this.state.selected;

					return { data: newBops, selected: selected, count: json.count, totalCount: json.totalCount, export: json.canExport, loadedPage: this.state.loadedPage + 1, lastPage: lastPage, finishLoading: true };
				}, () => {
					callback && callback();
					this.props.onFinishLoad && this.props.onFinishLoad(json);
					this.props.onRowChangeSelection && this.props.onRowChangeSelection(this.state.selected, undefined);
				}));
		});
	}
	handleScroll = (event) => {
		if (!this.state.finishLoading) {
			return;
		}

		//let skip = this.state.loadedPage * this.state.itemsPerPage;
		//let items = this.state.itemsPerPage;
		//this.loadRows(skip, items);
	}
	handleFilterButton = (event) => {
		if (!this.state.isMounted) {
			return;
		}
		this.setState({ showFilters: !this.state.showFilters });
	}
	handleColumnsButton = (event) => {
		if (!this.state.isMounted) {
			return;
		}
		if (this.state.anchorColumns) {
			this.setState({ anchorColumns: null });
		}
		else {
			this.setState({ showColumnsSelector: true });
		}
	}

	onLayoutButton = (event) => {
		console.log("ok");
		if (!this.state.isMounted) {
			return;
		}
		if (this.state.anchorColumns) {
			this.setState({ anchorColumns: null });
		}
		else {
			this.setState({ showConfigureLayouts: true });
		}
		//this.setState({showConfigureLayouts : !showConfigureLayouts})
	}

	showDialog = (buttons, title, message) => {
		var dialog = {
			message,
			title,
			buttons,
			show: true
		};
		this.setState({ dialog });
	}
	doDelete = (params) => {
		var { selected } = this.state;
		var { dataRouteDelete, selectedRows, beforeDelete, afterDelete, deleteField, deleteFieldValue } = this.props;
		if (selectedRows) {
			selected = selectedRows;
		}

		var queryString = "";
		if (deleteField) {
			queryString += "?" +deleteField+'='+deleteFieldValue;

			if (params && params.length > 0) {
				params.forEach((param) => {
					if (param.key && param.value)
						queryString += "&" + param.key + "=" + param.value;
				});
			}
		}
		
		//if (beforeDelete) {
		//	selected = await beforeDelete(selected);
		//}

		if (selected.length > 0) {		
			fetch(dataRouteDelete+queryString, {
				body: JSON.stringify(selected),
				method: 'POST'
			}).then(res => res.json()).then(data => {
				if (data && !data.item1 && data.item2) {
					var buttons = [
						{
							label: "OK",
							action: () => {
								this.setState({ dialog: {} });
							}
						},
					];
					this.showDialog(buttons, "Cancellazione", data.item2);
				}
				this.setState({ selected: [] });
				this.refresh();
				afterDelete && afterDelete();
			});
		}
	}
	handleDeleteButton = async(event) => {
		if (!this.state.isMounted) {
			return;
		}

		if (this.props.handleDeleteCallback) {
			this.props.handleDeleteCallback();
			return;
		}

		var buttons = [
			{
				label: "SI",
				action: () => {
					this.setState({ dialog: {} }, () => {
						this.doDelete();
					})
				}
			},
			{
				label: "NO",
				action: () => {
					this.setState({ dialog: {} });
				}
			},
		];

		this.showDialog(buttons, "Cancellazione", "Confermi la cancellazione dei record selezionati?");

	}
	handleRequestSort = (event, property) => {
		if (!this.state.isMounted) {
			return;
		}
		const { sortDirection, sortColumn } = this.state;
		const orderBy = property;
		let order = 'desc';

		if (sortColumn === property && sortDirection === 'desc') {
			order = 'asc';
		}

		//this.setState({ order, orderBy });

		this.setState((prev, props) => {
			return { data: [], sortColumn: orderBy, sortDirection: order, lastPage: false, loadedPage: 0, page: 0 };
		}, () => {
			this.loadRows(0, this.state.itemsPerPage);
		});
	};
	handleFilterChange = (event, property) => {
		if (!this.state.isMounted) {
			return;
		}
		var timestamp = new Date().getTime();
		var grid = this;
		var value = event.target.value;
		this.setState((prev, props) => {
			return { lastFilterType: timestamp };
		}, () => {
			setTimeout(function () {
				if (grid.state.lastFilterType === timestamp) {
					var filters = grid.state.filters;
					filters[property] = { key: property, term: value };
					grid.setState((prev, props) => {
						return { data: [], lastPage: false, loadedPage: 0, filters: filters, page: 0, finishLoading: false };
					}, () => {
						grid.loadRows(0, grid.state.itemsPerPage);
					});
				}
			}, 500);
		});
	}
	clearFilters = () => {
		this.setState({ data: [], lastPage: false, loadedPage: 0, filters: {}, page: 0 }, () => {
			this.loadRows(0, this.state.itemsPerPage);
		});
	}

	handleRowCheckboxChange = id => event => {
		if (!this.state.isMounted) {
			return;
		}
		var { selected, data } = this.state;
		var { selectedRows, onRowChecked, onRowUnchecked,onlyOneSelection } = this.props;
		if (selectedRows) {
			selected = selectedRows;
		}

		var selectedIndex = selected.indexOf(id);
		var selectedData = data.find((dr) => { return dr.ID === id });
		let newSelected = [];
		if(!onlyOneSelection){
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		}
		else {
			if(selectedIndex === -1){
				newSelected = [id];
			}
		}
	

		if (newSelected.length > 0) {
			if (newSelected.includes(id)) {
				selectedIndex = data.findIndex((item) => { return item.ID === id });

				onRowChecked && onRowChecked(selectedData, newSelected);
				//data[selectedIndex] = selectedData;
			} else if (selectedIndex > -1) {
				onRowUnchecked && onRowUnchecked(selectedData, newSelected);
			}
		}
		else if (selectedIndex > -1) {
			onRowUnchecked && onRowUnchecked(selectedData, newSelected);
		}
		var selectAll = newSelected && newSelected.length === data.length ? true : false;
		this.setState({ selected: newSelected, data, selectAll }, this.onRowChangeSelection(newSelected, id));
	}
	handleClick = (event, id) => {

		if (!event.target.id.startsWith("sel") || event.target.id === "sel_" + id || event.target.id === "") {
			return;
		}
		var { selected } = this.state;
		var { selectedRows,onlyOneSelection } = this.props;
		if (selectedRows) {
			selected = selectedRows;
		}
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];
		if(!onlyOneSelection){
		if (event.shiftKey || event.ctrlKey || selected.length > 0) {
			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, id);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1),
				);
			}
		}
		else {
			//newSelected = [id];
			newSelected = selected;
		}
		}
		else {
			if(selectedIndex === -1){
				newSelected = [id];
			}
		}

		if (event.target.id === "exp_" + id)
			newSelected = [];

		this.setState({ selected: newSelected }, this.onRowChangeSelection(newSelected, id));
	};
	onRowChangeSelection = (data, id) => () => {
		this.props.onRowChangeSelection && this.props.onRowChangeSelection(data, id);
		if(this.props.onlyOneSelection && data.length === 1 && this.props.rowExpandFormat){

			var result = this.state.data.filter(item => item.ID === id)[0];
			var index = this.state.data.indexOf(result);
			this.onRowExpand(index,[]);
		}
		else if(this.props.onlyOneSelection && this.props.rowExpandFormat){
			this.onRowExpand(this.state.expandedRow,[]);
		}
	}
	handleSelectAllClick = event => {
		if (event.target.checked) {
			var newSelected = this.state.data.map(n => n.ID);
			this.setState(state => ({ selected: state.data.map(n => n.ID), selectAll: true }), this.onRowChangeSelection(newSelected, newSelected));
			return;
		}
		this.setState({ selected: [], selectAll: false }, this.onRowChangeSelection([], undefined));
	};
	handleChangePage = (event, page) => {
		this.setState({ page });

		if (!this.state.finishLoading) {
			return;
		}

		//let skip = this.state.loadedPage * this.state.itemsPerPage;
		//let items = this.state.itemsPerPage;
		//this.loadRows(skip, items);
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });

		if (!this.state.finishLoading) {
			return;
		}

		//let skip = this.state.loadedPage * this.state.itemsPerPage;
		//let items = this.state.itemsPerPage;
		//this.loadRows(skip, items);
	};
	handleDoubleClick = data => event => {
		if (this.props.onDoubleClick) {
			this.props.onDoubleClick(event, data);
		}
	};
	handleEditButton = event => {

		var { selected, data } = this.state;
		var { selectedRows } = this.props;
		if (selectedRows) {
			selected = selectedRows;
		}
		if (selected.length !== 1) {
			return;
		}
		var item = data.filter(s => s.ID === selected[0]);
		if (item.length > 0) {
			this.props.onEditButton(event, item[0]);
		}
	};
	handleUpdateData = () => {
		var { onUpdateRows, onRowsUpdated } = this.props;

		onUpdateRows && onUpdateRows();

		this.doUpdateDataRows(onRowsUpdated);
	}

	doUpdateDataRows = (onRowsUpdated) => {
		var { data } = this.state;
		var { dataRouteUpdateRows } = this.props;

		var editedRows = [];

		data.map((dataRow) => {
			dataRow.isEdited && editedRows.push(dataRow);
		})

		fetch(dataRouteUpdateRows, {
			body: JSON.stringify(editedRows),
			method: 'POST'
		}).then(res => {
			this.setState({ selected: [] });
			this.refresh();

			onRowsUpdated && onRowsUpdated();
		});
	}

	showGridView = () => {
		this.changeView("grid");
	}
	showListView = () => {
		this.changeView("list");
	}
	showCardView = () => {
		this.changeView("card");
	}
	changeView = (selected) => () => {
		var { onViewChanged } = this.props;
		if (this.state.viewType === selected)
			return;
		this.setState({ viewType: selected }, () => { onViewChanged && onViewChanged(selected) });
	}
	search = (key, value, callback) => {
		var filters = this.state.filters;
		filters = {};
		filters[key] = { key: key, term: value };
		this.setState((prev, props) => {
			return { data: [], lastPage: false, loadedPage: 0, filters: filters, page: 0 };
		}, () => {
			this.loadRows(0, this.state.itemsPerPage, callback);
		});
	}
	refresh = (filters, callback = undefined) => {
		this.setState((prev, props) => {
			var keepFilters = {};
			if ((!prev.filters || Object.keys(prev.filters).length == 0) && filters && Object.keys(filters).length > 0)
				keepFilters = filters;
			else
				keepFilters = prev.filters;

			return { data: [], filters: keepFilters, lastPage: false, loadedPage: 0, page: 0 };
		}, () => {
			this.loadRows(0, this.state.itemsPerPage, callback);
		});
	};
	countRows = () => {
		return this.state.count;
	}
	getSelectedData = () => {
		var { selected } = this.state;
		var { selectedRows } = this.props;
		if (selectedRows) {
			selected = selectedRows;
		}
		return selected;
	}
	getRowData = (id) => {
		const { data } = this.state;
		var result = data.filter(item => item.ID === id);
		if (result.length > 0) {
			return result[0];
		}
		else {
			return undefined;
		}
	}
	getRowTop = (row, cols, index) => {
		var { data } = this.state;
		//574.23
		var sum = 0;

		var { height } = this.getCardSize();
		var maxRow = height;
		var lastIndex = (index - (index % cols));
		for (var i = 0; i < lastIndex; i++) {

			if (i % cols === 0) {
				sum += maxRow;
				maxRow = height;
			}
		}
		sum += maxRow - height;

		return sum;
	}
	getNumberOfCols = () => {
		var { width, cols, colsXl, colsLg, colsMd, colsXs } = this.props;
		var useWidth = this.props.width;
		if (width) {
			useWidth = width;
		}
		//var cols = 4;
		switch (useWidth) {
			case "xl":
				cols = colsXl;
				break;
			case "lg":
				cols = colsLg;
				break;
			case "md":
			case "sm":
				cols = colsMd;
				break;
			case "xs":
				cols = colsXs;
				break;
			default:
				break;
		}
		return cols;
	}
	getCardSize = () => {
		var { cardWidth, cardHeight } = this.props;
		var { contentWidth } = this.state;
		var cols = this.getNumberOfCols();
		var tempWidth = cardWidth;
		var tempHeight = cardHeight;
		var width = (contentWidth - getScrollbarWidth()) / cols;

		var height = width * tempHeight / tempWidth;

		return { width, height };
	}
	clearSelection = () => {
		this.setState({ selected: [] }, this.onRowChangeSelection([], undefined));
	}
	isSelected = id => {
		var { selected } = this.state;
		var { selectedRows } = this.props;
		if (selectedRows) {
			selected = selectedRows;
		}
		return selected.indexOf(id) !== -1;
	}
	

	//Download Function
	getCookie( name ) {
		var parts = document.cookie.split(name + "=");
		if (parts.length == 2) return parts.pop().split(";").shift();
	  }

	download(url) {
		var {documentExported} = this.state;
		

		//delete cookie
		
		this.setState({showLoader: true, finishLoading: false});
		var a = document.createElement('a');
		a.href = url;
		a.download = `EXPORT.xlsx`;
		document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
		a.click();
		a.remove();	


		var interval = setInterval(() => {
			if(this.getCookie("export_excel_finish") === "true") {
				this.setState({showLoader: false, finishLoading: true});
				clearInterval(interval);
				document.cookie = "export_excel_finish=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
			}
		}, 1000);
	}
	handleExcelExport = () => {
		if(this.state.export) 
		{
			this.setState({showLoader: true, finishLoading: false});
			var {viewType} = this.state;
			let tempFilters = this.state.filters;
			let filters = encodeURIComponent(JSON.stringify(Object.keys(tempFilters).map(function (s) { return tempFilters[s]; }).filter(f => f.term !== "")));
			let url = `${this.props.dataRoute}&skip=0&items=9999999&sortColumn=${this.state.sortColumn}&sortDirection=${this.state.sortDirection}&filters=${filters}&export=true`
			
			if(viewType !== "grid") {
				url += `&includeImage=true`;
			}

			// var a = document.createElement('a');
			// a.href = url;
			// a.download = `EXPORT.xlsx`;
			// document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			// a.click();    
			// a.remove();

			this.download(url);
		}
		else {
			this.setState({ exporting: true }, () => {
				setTimeout(() => {
					this.setState({ exporting: false });
				}, 1000);
			});	
		}
		
	
	}
	getRowSize = ({ index }) => {
		var { rowHeight, editable, hideDefaultToolbar, selectedActions, customContent, actions } = this.props;
		var { viewType, expandedRow, expandedRowHeight } = this.state;
		if (viewType === "list" || editable) {
			rowHeight *= 2;
		}
		if (expandedRow === index) {
			rowHeight += expandedRowHeight;

			var selectedRowsCount = 0;
			var selRows = this.getSelectedData();

			if (selRows && selRows.length > 0)
				selectedRowsCount = selRows.length;

			if (!((!hideDefaultToolbar && (selectedRowsCount === 0 || (selectedActions && selectedActions.length > 0 && selectedRowsCount > 0))) || (customContent && actions && actions.length > 0))) {
				//rowHeight -= 64;
			}
		}

		return rowHeight;
	}
	
	getData = () => {
		var { data } = this.state;
		return data.map(i => i.ID);
	}
	onRowExpand = (index, selectedExpanded) => {
		var oldIndex = this.state.expandedRow;
		this.setState(state => {
			if(!this.props.onlyOneSelection){
				state.selected = [];
			}
			if (state.expandedRow === index) {
				state.expandedRow = undefined;
			}
			else {
				state.expandedRow = index;
			}
			return state;
		}, () => {
			var min = index;
			if (oldIndex < min) {
				min = oldIndex;
			}

			this.InnerList.current && this.InnerList.current.recomputeRowHeights(min);

			if (selectedExpanded && selectedExpanded.length > 0 && this.expandedRowGrid.current) {
				this.expandedRowGrid.current.setState({
					selected: selectedExpanded
				})
			}

			this.props.onRowExpand && this.props.onRowExpand(index, selectedExpanded);
		});
	}
	onRowChange = (index) => async (key, value) => {
		var dataUpdated = await this.props.onRowChange(this.state.data, index, key, value);
		this.setState(state => {
			state.data = dataUpdated;
			state.data[index][key] = value;
			state.data[index].isEdited = true;

			return state;
		});
	}
	render() {
		const { classes, dataRouteUpdateRows, dataRouteDelete, selectedActions, onEditButton, customContent, label, showColumnsSelector, showChangeView, defaultViewType,
			customCells, rowExpandFormat, containerNoShadow, hideDefaultToolbar, displayTotalCount,onlyOneSelection,hideExpandButton, expansionLeftOffset, expansionMaxWidth } = this.props;
		var { preventSelectAll,actions, editable, rowsVisible, usePrimaryColor, useSecondaryColor, tabs, selectedTab, onTabChange, rowHeight, showFilterActivator, selectedRows, customStyleFunction, additionalLabel, rowSelectableOnlyIfExpanded, actionsInverted, showLoader, changeViewHideCard, showImgColumn,expansionWidth } = this.props;
		var { data, sortDirection, sortColumn, selected, count, totalCount, columns, filters, showFilters, selectAll, finishLoading, viewType, contentWidth, contentHeight, exporting, expandedRow } = this.state;
		var { containerID, customHeight } = this.props;
		if (!containerID) {
			containerID = 'mainContent';
		}
		if(onlyOneSelection){
			preventSelectAll = true;
		}

		var handleExport = this.handleExcelExport;
		var handleEditBtn = onEditButton && this.handleEditButton;
		if (selectedRows) {
			selected = selectedRows;
		}
		var colwidth = "100%";
		var topMargin = 42;

		if (viewType === 'card')
			topMargin *= 2;

		//editable = columns.reduce((a, b) => a || b.editable);

		if (viewType === "list" || editable) {
			rowHeight *= 2;
		}

		var editedRows = false;
		if (editable && dataRouteUpdateRows) {
			editedRows = this.state.data && this.state.data.length > 0 ? this.state.data.find((dataRow) => { return dataRow.isEdited }) : false;

			if (editedRows) {
				if (!actions)
					actions = [];
				actions.push(
					<Tooltip title="Salva">
						<IconButton aria-label="Salva" onClick={this.handleUpdateData}>
							<Check />
						</IconButton>
					</Tooltip>
				);
			}
		}

		if (columns.map(c => c.width).length > 0) {
			colwidth = columns.map(c => c.width).reduce((a, b) => a + b) + 42;
			if (viewType === "list") {
				colwidth += 84;
			}
		}
		//var rowsVisible = 10;
		if (rowsVisible === -1) {
			rowsVisible = data.length;
		}
		var height = undefined;
		var deltaH = expandedRow !== undefined ? this.state.expandedRowHeight : 0;

		if (expandedRow || expandedRow === 0) {
			var selectedRowsCount = 0;
			var selRows = this.getSelectedData();

			if (selRows && selRows.length > 0)
				selectedRowsCount = selRows.length;

			if (!((!hideDefaultToolbar && (selectedRowsCount === 0 || (selectedActions && selectedActions.length > 0 && selectedRowsCount > 0))) || (customContent && actions && actions.length > 0))) {
				//deltaH += 64;
			}
		}

		var mainContent = document.getElementById(containerID);
		var tableWrapperHeight = contentHeight - TOOLBAR_HEIGHT;
		var containerHeight = undefined;
		if (mainContent) {
			containerHeight = mainContent.clientHeight;
			if (this.props.preGridContentID) {
				var preGridElement = document.getElementById(this.props.preGridContentID);
				if (preGridElement) {
					var preHeight = preGridElement.clientHeight;
					containerHeight -= preHeight;
				}
			}
			height = containerHeight - TOOLBAR_HEIGHT - (viewType !== "card" ? 42 : 0) - (showFilters ? topMargin : 0);
			tableWrapperHeight = containerHeight - TOOLBAR_HEIGHT
		}
		else {
			tableWrapperHeight = height - 64
		}
		if (height === undefined) {
			rowsVisible = 10;
		}
		if (viewType === "list") {
			rowsVisible /= 2;
		}
		if (viewType !== "card") {
			if (rowsVisible && rowsVisible > 0) {
				height = rowHeight * rowsVisible;
				tableWrapperHeight = height + 42 + (showFilters ? topMargin : 0);
			}
			if (data.length * rowHeight + deltaH < height && !customContent) {
				height = data.length * rowHeight + deltaH;
				tableWrapperHeight = height + 42 + (showFilters ? topMargin : 0);
			}
			if (contentWidth < colwidth) {
				//

				if (data.length * rowHeight > height) {
					height -= getScrollbarWidth();
				}
				else {
					tableWrapperHeight += getScrollbarWidth();
				}
			}
		}
		else {
			if (rowsVisible && rowsVisible > 0) {
				height = rowHeight * rowsVisible;
				tableWrapperHeight = height;
				height = tableWrapperHeight - (viewType !== "card" ? 42 : 0) - (showFilters ? topMargin : 0);
			}
		}
		var hasCustomContent = false;
		if (customContent) {
			//tableWrapperHeight = contentHeight - TOOLBAR_HEIGHT;
			tableWrapperHeight = !customHeight ? "100%" : tableWrapperHeight - 8;
			handleExport = undefined;
			hasCustomContent = true;
		}
		//height -= 17;
		var changeViewEvent = this.changeView;
		if (!showChangeView) {
			changeViewEvent = undefined;
		}


		var jFilters = JSON.stringify(Object.keys(filters).map(s => { return filters[s]; }).filter(f => f.term !== ""));
		var filterClear = jFilters !== JSON.stringify([]) && this.clearFilters;
		var viewContent = undefined;

		var tempWidth = colwidth;
		//contentWidth = contentWidth - getScrollbarWidth();
		if (contentWidth > colwidth) {
			tempWidth = contentWidth;
		}

		//expansionWidth = contentWidth;

		if (customContent !== undefined) {
			showFilterActivator = false;
			viewContent = customContent;
		} else if (viewType == 'card') {
			viewContent =
				(
					<Fragment>
						{showFilters && <EnhancedTableHead
							numSelected={selected.length}
							order={sortDirection}
							orderBy={sortColumn}
							rowCount={data.length}
							columns={columns}
							onRequestSort={this.handleRequestSort}
							onFilterChange={this.handleFilterChange}
							onSelectAllClick={this.handleSelectAllClick}
							showFilters={showFilters}
							filters={filters}
							new={true}
							grid={this}
						/>}
						<Collection
							onScroll={event => {
								//this.setState({ scrollTop: event.scrollTop });
							}}
							scrollToCell={this.state.currentIndex}
							ref={this.Collection}
							cellCount={data.length}
							cellRenderer={({ index, key, style }) => {

								var item = data[index];
								key = item.ID;
								if (!key) {
									key = index;
								}
								var tempItem = {};
								if (!this.state.isLoadingPage) {
									tempItem = item;
								}
								style.margin = 8;
								//style.height = undefined;
								//debugger;
								return (
									<XCard
										isSelected={this.isSelected(tempItem.ID)}
										style={style}
										key={key}
										isLoading={this.state.isLoadingPage}
										data={tempItem}
										columns={columns}
										selectionView={this.props.cardSelectionView}
										onCardClick={this.handleDoubleClick(tempItem)}
									/>
								);
							}}
							cellSizeAndPositionGetter={({ index }) => {
								var cols = this.getNumberOfCols();
								var { width, height } = this.getCardSize();
								var top = this.getRowTop((index - (index % cols)), cols, index);
								return {
									height: height - 16,
									width: width - 16,
									x: (index % cols) * width,
									y: top,
								};
							}}
							width={contentWidth}
							height={height}
						/>
					</Fragment>
				);
		} else {
			var gridWidth = this.rowsContainer.current ? this.rowsContainer.current.clientWidth : tempWidth;

			if (editable) {
				gridWidth += 42;
				tempWidth += 42;
			}
			
			if (gridWidth > tempWidth)
				tempWidth = gridWidth;
			
			viewContent =
				(
					<Fragment>
						<EnhancedTableHead
							preventSelectAll={preventSelectAll}
							numSelected={selected.length}
							order={sortDirection}
							orderBy={sortColumn}
							rowCount={data.length}
							columns={columns}
							onRequestSort={this.handleRequestSort}
							onFilterChange={this.handleFilterChange}
							onSelectAllClick={this.handleSelectAllClick}
							showFilters={showFilters}
							filters={filters}
							new={true}
							grid={this}
							viewType={viewType}
							emptyColumnEdit={editable}
							showImgColumn={showImgColumn}
						/>
						<div ref={this.rowsContainer} style={{ minWidth: "100%", width: tempWidth }}>
							{!(!finishLoading && showLoader) && <List
								key={viewType}
								ref={this.InnerList}
								style={{ minWidth: '100%', maxWidth: '100%', direction: "rtl" }}
								rowCount={data.length}
								rowHeight={this.getRowSize}
								height={height}
								width={tempWidth}
								rowRenderer={({ index, isScrolling, isVisible, key, parent, style }) => {
									var nStyle = Object.assign({}, style);
									nStyle.direction = "ltr";
									if (editable)
										return (
											<XGridEditableRow
												rowIndex={index}
												onChange={this.onRowChange(index)}
												style={nStyle}
												key={key}
												width={gridWidth}
												data={data[index]} columns={columns}
												isSelected={this.isSelected(data[index].ID)}
												onDoubleClick={this.handleDoubleClick}
												onSelectAll={this.handleRowCheckboxChange}
												customCells={customCells}
												rowHeight={this.getRowSize({ index })}
												viewType={viewType}
												customStyleFunction={customStyleFunction}
												emptyColumnEdit={editable}
												rowSelectableOnlyIfExpanded={rowSelectableOnlyIfExpanded}
											//expandFormat={rowExpandFormat}
											//expanded={expandedRow === index}
											//onRowExpand={this.onRowExpand}
											//expansionSize={EXPANSION_SIZE}
											/>
										);
									else
										return (
											<XGridRow
												rowIndex={index}
												style={nStyle}
												key={key}
												width={tempWidth}
												data={data[index]} columns={columns}
												isSelected={this.isSelected(data[index].ID)}
												onClick={this.handleClick}
												onDoubleClick={this.handleDoubleClick}
												onSelectAll={this.handleRowCheckboxChange}
												customCells={customCells}
												rowHeight={this.getRowSize({ index })}
												viewType={viewType}
												customStyleFunction={customStyleFunction}
												expandFormat={rowExpandFormat}
												previousExpanded={expandedRow > -1 && expandedRow === (index - 1)}
												expanded={expandedRow === index}
												onRowExpand={this.onRowExpand}
												hideExpandButton={hideExpandButton}
												expansionSize={deltaH}
												expansionWidth={expansionWidth}
												expansionMaxWidth={expansionMaxWidth}
												expansionLeftOffset={expansionLeftOffset}
												rowSelectableOnlyIfExpanded={rowSelectableOnlyIfExpanded}
												showImgColumn={showImgColumn}
											/>
										);
								}}
							/>}
							{!finishLoading && showLoader && <XOXLoader onlyO={false} height={height + "px"} />}
						</div>
					</Fragment>
				);
		}
		if(!customContent && viewType != 'card' && displayTotalCount && totalCount > 0){
			console.log(tableWrapperHeight,containerHeight);
			if(containerHeight && tableWrapperHeight > containerHeight){
				tableWrapperHeight -= 48;
			}
			console.log(tableWrapperHeight,containerHeight);
		}
		var tableWrap = <Fragment><div className={classes.tableWrapper} style={{ height: tableWrapperHeight }}>
			{viewContent}
		</div>{!customContent && viewType != 'card' && displayTotalCount && totalCount > 0 && <div width={tempWidth} height={this.getRowSize(0)} style={{ paddingLeft: "50px" }}><Typography style={{ lineHeight: 3 }}>Totale righe: {totalCount}</Typography></div>}</Fragment>;

		//if (doubleHeight) {
		//    var tableWrapHeight = (height / 2);

		//    if (viewType == 'list')
		//        tableWrapHeight += 42;

		//    if (showFilters) tableWrapHeight += 42;

		//    tableWrap = <div key={2} className={classes.tableWrapper} style={{ height: tableWrapHeight }}>
		//        {viewContent}
		//    </div>;
		//}
		var selectedRowsCount = 0;
		var selRows = this.getSelectedData();

		if (selRows && selRows.length > 0)
			selectedRowsCount = selRows.length;

		//if (!finishLoading && showLoader) 
		//	return <XOXLoader onlyO={false} height={height + "px"} />;
		
		return (
			<Paper className={containerNoShadow && customContent ? classes.rootNoShadow : classes.root} style={this.props.style}>
				{
					//((!hideDefaultToolbar && (selectedRowsCount === 0 || (selectedActions && selectedActions.length > 0 && selectedRowsCount > 0))) || (customContent && actions && actions.length > 0)) &&
					((!hideDefaultToolbar ) || (customContent && actions && actions.length > 0)) &&
					<EnhancedTableToolbar
						onRefresh={this.refresh}
						onFilterButton={this.handleFilterButton}
						onColumnsButton={!customContent && showColumnsSelector && this.handleColumnsButton}
						onLayoutButton={!customContent && showColumnsSelector && this.onLayoutButton}
						onDeleteButton={dataRouteDelete && this.handleDeleteButton}
						onEditButton={handleEditBtn}
						onNewButton={this.props.onNewButton}
						newBtnCaption={this.props.newBtnCaption}
						onExcelExportButton={handleExport}
						numSelected={selectedRowsCount}
						selectedAll={selectAll}
						count={count}
						grid={this}
						showDeleteButton={dataRouteDelete !== undefined}
						actions={actions}
						selectedActions={selectedActions}
						label={label}
						additionalLabel={additionalLabel}
						showFilterActivator={showFilterActivator}
						showColumnsSelector={!customContent && this.state.showColumnsSelector}
						showConfigureLayouts={!customContent && this.state.showConfigureLayouts}
						usePrimaryColor={usePrimaryColor}
						useSecondaryColor={useSecondaryColor}
						tabs={tabs}
						selectedTab={selectedTab}
						onTabChange={onTabChange}
						onClearFilterButton={filterClear}
						showChangeViewButton={showChangeView}
						onChangeView={changeViewEvent}
						changeViewHideCard={changeViewHideCard}
						viewType={viewType}
						actionsInverted={actionsInverted}
						hasCustomContent={hasCustomContent}
					/>
				}
				{this.props.preGridContent && this.props.preGridContent()}
				{tableWrap}

				<XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
				{exporting && (
					<ExcelFile hideElement={true}>
						<ExcelSheet data={data} name="Dati">
							{columns.map((column, index) => {
								return <ExcelColumn label={column.name} value={column.key} />
							})}
						</ExcelSheet>
					</ExcelFile>
				)}
			</Paper>
		);
	}
}
XGrid.propTypes = {
    //classes: PropTypes.object.isRequired,
    dataRoute: PropTypes.string,
    dataRouteColumns: PropTypes.string,
    dataRouteAvailableColumns: PropTypes.string,
    onDoubleClick: PropTypes.func,
    dataRouteUpdateColumns: PropTypes.string,
	dataRouteDelete: PropTypes.string,
	hideExpandButton: PropTypes.bool,
};
XGrid.defaultProps = {
  rowHeight: 42,
	showColumnsSelector: true,
	showConfigureLayouts: true,
	hideDefaultToolbar : false,
	cols: 4,
	colsXl: 4,
	colsLg: 3,
	colsMd: 3,
	colsSm: 2,
	hideExpandButton: false,
}

const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch),
        null,
        { withRef: true }
    ),
    withStyles(styles),
    //withWidth(),
);
export default enhance(XGrid);