import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { IconButton, Tooltip } from "@material-ui/core";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import logo from "assets/img/logo-white.svg";
import logoLicenseePayOff from "assets/img/logo-licensee-payoff.png";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import QrReader from 'react-qr-reader'


import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Auth';
import { FormTextboxPassword, LockReset, BarcodeScan, QrcodeScan } from "mdi-material-ui";
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
import { actionCreators as globalCreators } from 'store/Global';

import { setCookie, getCookie } from 'utils';

const initialState = {
    cardAnimaton: "cardHidden",
    username: "",
    password: "",
    offices: [],
    profiles: [],
    selectOffice: false,
    acceptances:[],
    acceptancesLoaded: false,
	isProductionEmployee: false,
	loginPIN: false,
	loginBarcode: false,
	loginQRCode: false
};
const COOKIE_LOGIN_TYPE = "xox_login_type";
class LoginPage extends Component {
    constructor(props) {
        super(props);
		this.state = Object.assign({}, initialState);
		var lastView = getCookie(COOKIE_LOGIN_TYPE);
		switch(lastView){
			case "up":
				break;
			case "pin":
				this.state.loginPIN = true;
				break;
			case "bc":
				this.state.loginBarcode = true;
				break;
			case "qr":
				this.state.loginQRCode = true;
				break;
		}
		this.QrReader = React.createRef();
	}
	componentDidMount() {
        this.startAnimation();
		if (this.props.originalUser !== undefined) {
			this.setState({ selectProfile: true }, () => {
				fetch('/Base/GetAvailableProfiles').then(res => res.json()).then(data => {
					this.setState({ profiles: data });
				});
			});
		}
		this.loadParams();
	}
	loadParams = () => {
		fetch('/GC/params').then(res => res.json()).then(data => {
			//Object.keys(data).map(function (key, index) { data[key] = JSON.parse(data[key]) })
			var keys = Object.keys(data);
			for(var i in keys){
				var key = keys[i];
				var val =data[key];
				if(typeof val === "string"){
					try {
						val = JSON.parse(val);
					}
					catch(err) {
						console.error(err);
					}
				}
				data[key]= val;
			}
			this.props.setParams(data);
		});
	}
    loadGdpr = () => {
        fetch('/base/GetGdprToAccept').then(res => res.json()).then(data => {
            this.setState({acceptances:data,acceptancesLoaded: true},() => {
                if(this.state.acceptancesLoaded && this.state.acceptances.length === 0){
					var stateData = this.state.data;
                    this.props.loginSuccess(stateData);
                }
            });
        });
    }
    startAnimation = () => {
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }
	componentDidUpdate = (prevProps, prevState) => {
		var { loginBarcode } = this.state;

		if (loginBarcode && document.getElementById("barcode"))
			document.getElementById("barcode").focus();
	}
	componentWillUnmount() {
		clearTimeout(this.timeOutFunction);
		this.timeOutFunction = null;
	}
	onUsernameChange = (event) => {
		this.setState({ username: event.target.value });
	}
	onPasswordChange = (event) => {
		this.setState({ password: event.target.value });
	}
	onBarcodeChange = (event) => {
		this.setState({ barcode: event.target.value });
	}
	onLogin = (event) => {
		var { loginPIN, loginBarcode, loginQRCode, username, password, barcode } = this.state;
		if (this.props.login) {
			if (loginPIN) {
				var actionType = Date.now();
				fetch(`/Base/LoginPIN/?ActionType=${actionType}`, {
					method: 'POST',
					body: JSON.stringify({ Username: username, PIN: password })
				}).then(res =>
						res.json()).then(data => {
					if (data.success) {
						data.actionType = actionType;
						this.onLoginSucces(data, password);
					}
					else {
						this.props.loginFailure('', password, '', data);
					}
				});
			}
			else if (loginBarcode) {
				var actionType = Date.now();
				fetch(`/Base/LoginBarcode/?ActionType=${actionType}`, {
					method: 'POST',
					body: JSON.stringify({ barcode: password })
				}).then(res =>
					res.json()).then(data => {
						console.log(event);
						console.log(data);
					if (data.success) {
						data.actionType=actionType;
						this.onLoginSucces(data, password);
					}
					else {
						this.props.loginFailure('', '', password, data);
					}
				});
			}
			else if(loginQRCode) {
				this.QrReader.current.openImageDialog();
			}
			else {
				var actionType = Date.now();
				fetch(`/Base/Login/?ActionType=${actionType}`, {
					method: 'POST',
					body: JSON.stringify({ Username: username, Password: password })
				}).then(res => res.json()).then(data => {
					if (data.success) {
						data.actionType = actionType;
						this.onLoginSucces(data, password);
					}
					else {
						this.props.loginFailure(username, password, '', data);
					}
				});
			}
		}
	}

	onLoginSucces = (data, password) => {
		if (data.fidOffice) {
            this.loadGdpr();
            if(this.state.acceptancesLoaded && this.state.acceptances.length === 0){
                this.props.loginSuccess(data);
            }
            else {
								this.props.getTranslations();
                this.setState({ data: data, user: data.username, fidOffice: data.fidOffice, offices: data.offices, selectOffice: false, selectProfile: false, isResponsible: data.isResponsible, showGdpr: true,barcode: data.barcode, isProductionOperator: data.isProductionOperator });
            }
        }
        else {
            this.setState({ data: data, user: data.username, fidOffice: data.fidOffice, offices: data.offices, selectOffice: (data.offices && data.offices.length > 0), selectProfile: false, isResponsible: data.isResponsible, barcode: data.barcode, isProductionOperator: data.isProductionOperator });
        }
    }
	onKeyDown = (event) => {
		if (event.keyCode === 13) {
            setTimeout(() => {
                this.onLogin(event);
            },600)
		}
	}
	onLoginTypeStd = (event) => {
		this.setState({ loginPIN: false, loginBarcode: false, loginQRCode: false },()=>{
			setCookie(COOKIE_LOGIN_TYPE,"up");
		});
	}
	onLoginTypePIN = (event) => {
		this.setState({ loginPIN: true, loginBarcode: false, loginQRCode: false },()=>{
			setCookie(COOKIE_LOGIN_TYPE,"pin");
		});
	}
	onLoginTypeBarcode = (event) => {
		this.setState({ loginPIN: false, loginBarcode: true, loginQRCode: false },()=>{
			setCookie(COOKIE_LOGIN_TYPE,"bc");
		});
	}
	onLoginTypeQRCode = (event) => {
		this.setState({ loginPIN: false, loginBarcode: false, loginQRCode: true },()=>{
			setCookie(COOKIE_LOGIN_TYPE,"qr");
		});
	}
	onOfficeSelect = (FIDOffice) => () => {
		fetch('/Base/OfficeSelect', {
			method: 'POST',
			body: JSON.stringify(FIDOffice)
		}).then(res => res.json()).then(data => {
			if (data.success) {
                var stateData = this.state.data;
				stateData.destination = data.destination;
                this.props.loginSuccess(stateData, FIDOffice);
			}
		});
	}
	onProfileSelect = (FIDProfile) => () => {
		fetch('/Base/LoginWithProfile/' + FIDProfile, {
			method: 'POST'
		}).then(res => res.json()).then(data => {
            // data.user = this.props.originalUser;
            data.isResponsible = this.props.IsResponsible;
			this.onLoginSucces(data);
		});
	}
	onProfileReset = () => {
		fetch('/Base/ResetOriginalProfile/', {
			method: 'POST'
		}).then(res => res.json()).then(data => {
			data.user = this.props.originalUser;
            data.isResponsible = this.props.IsResponsible;
			this.onLoginSucces(data);
			this.props.resetProfile();
		});
	}
    onSubmit = (ev) => {
        ev.preventDefault();
    }
    onAccept = () =>{
        var { acceptances, data } = this.state;
        for(var i in acceptances){
            var ac = acceptances[i];
            fetch(`/base/AcceptGdpr/${ac.id}?Acceptance=true`);
		}
        this.props.loginSuccess(data);
    }
    onDeny = () =>{
        var { acceptances } = this.state;
        for(var i in acceptances){
            var ac = acceptances[i];
            fetch(`/base/AcceptGdpr/${ac.id}?Acceptance=false`);
        }
        //this.props.logout();
        this.props.logout();
        this.setState({
            username: "",
            password: "",
            offices: [],
            profiles: [],
            selectOffice: false,
            showGdpr: false,
        }, this.startAnimation);
	}
	handleScan = (data) => {
		console.log(data);
		if(data) {
			var actionType = Date.now();
			fetch(`/Base/LoginQRCode/?ActionType=${actionType}`, {
				method: 'POST',
				body: JSON.stringify({ QRCodeGuid: data })
			}).then(res =>
					res.json()).then(jData => {
				if (jData.success) {
					jData.actionType = actionType;
					this.onLoginSucces(jData, data);
				}
				else {
					this.props.loginFailure('', data, '', jData);
				}
			});
		}
	}
	handleError  = (data) => {
		console.log(data);
	}
	render() {
		const { classes } = this.props;
        const { loginPIN, offices,selectOffice, profiles,selectProfile, showGdpr,acceptancesLoaded, acceptances,loginBarcode,loginQRCode } = this.state;


		var errMsg = this.props.error && (<p>{this.props.error}</p>);
		var pwdLabel = "Password";
		var onBarcodeBlur = undefined;
		if(loginPIN){
			pwdLabel = "PIN";
		}
		else if(loginBarcode){
			pwdLabel = "Barcode";
			onBarcodeBlur = this.onBarcodeBlur
		}
		var ui = (
			<Fragment>
				<CardBody>
					{!loginQRCode && !loginPIN && !loginBarcode &&
						<CustomInput
							labelText="Username"
							id={"email" + (new Date()).getTime()}
							formControlProps={{
								fullWidth: true
							}}
							value={this.state.username}
							onChange={this.onUsernameChange}
							inputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Face className={classes.inputAdornmentIcon} />
									</InputAdornment>
								),
								autoComplete: "off",
								onKeyDown: this.onKeyDown
							}}
						/>}
					{!loginQRCode && <CustomInput
						labelText={pwdLabel}
						id={"password" + (new Date()).getTime()}
						formControlProps={{
							fullWidth: true
						}}
						value={this.state.password}
						onChange={this.onPasswordChange}
						onKeyDown={this.onKeyDown}
						inputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon className={classes.inputAdornmentIcon}>
										lock_outline
							</Icon>
								</InputAdornment>
							),
							type: 'password',
							autoComplete: "off",
							onKeyDown: this.onKeyDown,
							onBlur: onBarcodeBlur
						}}
					/>}
					{loginQRCode && <QrReader
						ref={this.QrReader}
						facingMode="user"
						legacyMode={false}
						onError={this.handleError}
						onScan={this.handleScan}
					 />}
				</CardBody>
				{!loginQRCode && <CardFooter className={classes.justifyContentCenter}>
					<Button color="primary" size="lg" block onClick={this.onLogin}>Login</Button>
				</CardFooter>}
				<CardFooter className={classes.justifyContentCenter}>
					{(loginPIN || loginBarcode || loginQRCode) && <Tooltip title="Login con password">
						<IconButton onClick={this.onLoginTypeStd}>
							<FormTextboxPassword />
						</IconButton>
					</Tooltip>}
					{!loginBarcode && <Tooltip title="Login con barcode">
						<IconButton onClick={this.onLoginTypeBarcode}>
							<BarcodeScan />
						</IconButton>
					</Tooltip>}
					{!loginPIN && <Tooltip title="Login con PIN">
							<IconButton onClick={this.onLoginTypePIN}>
								<LockReset />
							</IconButton>
						</Tooltip>}
					{!loginQRCode && <Tooltip title="Login con QRCode">
						<IconButton onClick={this.onLoginTypeQRCode}>
							<QrcodeScan />
						</IconButton>
					</Tooltip>}
				</CardFooter>

			</Fragment>
		);

		if (selectOffice) {
			ui = (
				<Fragment>
					<CardBody>
						<List>
							{offices.map((item, index) => (
								<ListItem key={index} button onClick={this.onOfficeSelect(item.id)}>
									<ListItemText key={item.id}
										primary={item.alias || item.denomination}
										secondary={item.address}
									/>
								</ListItem>
							))}
						</List>
					</CardBody>
				</Fragment>
			);
		}
		if (selectProfile) {
			ui = (
				<Fragment>
					<CardBody className={classes.body}>
						<List>
							<ListItem button onClick={this.onProfileReset}>
								<ListItemText
									primary={this.props.originalUser}
									secondary="Profilo originale"
								/>
							</ListItem>
							{profiles.map((item, index) => (
								<ListItem key={index} button onClick={this.onProfileSelect(item.id)}>
									<ListItemText key={item.id}
										primary={item.username}
										secondary={item.denomination}
									/>
								</ListItem>
							))}
						</List>
					</CardBody>
				</Fragment>
			);
		}
        var md = 4;
        var sm = 6;
        var logoClass = classes.logoContainer;
        if(showGdpr && acceptancesLoaded && !this.props.translationsLoading){
            md = 12;
            sm = 12;
            logoClass = classes.logoContainerBig;
            ui = (
                <Fragment>
                    <CardBody className={classes.body}>
                        <List>
                            {acceptances.map((item) => (
                                <ListItem key={item.id}>
                                    <ListItemText key={item.id}
                                        primary={item.description.split("\r\n").map((d, index) => <p key={index}>{d}</p>)}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                        <Button color="primary" size="lg" block onClick={this.onAccept}> {getTranslation(5,'GC_ACCEPT',this.props.translations,"Accept")}</Button>
                        <Button color="secondary" size="lg" block onClick={this.onDeny}>{getTranslation(5,'GC_DENY',this.props.translations, "Deny")}</Button>
                    </CardFooter>
                </Fragment>
            );
        }


		return (
			<div className={classes.container}>
				<GridContainer justify="center">
                    <GridItem xs={12} sm={sm} md={md}>
                        <form onSubmit={this.onSubmit}>
						<Card login className={classes[this.state.cardAnimaton]}>
							<CardHeader
								className={`${classes.cardHeader} ${classes.textCenter}`}
								color="gray"
							>
                                <div className={logoClass}>
                                    <img src={logoLicenseePayOff} alt="logo" style={{ width: "67%" }} />
                                </div>
							</CardHeader>
							{ui}

							{this.props.params.InMaintenance && <CardFooter className={classes.justifyContentCenter}>
								Piattaforma in manutenzione
							</CardFooter>}
							<CardFooter className={classes.justifyContentCenter}>
								{errMsg}
							</CardFooter>

							<CardFooter color="gray" className={classes.justifyContentRight}>
								<img src={logo} alt="logo" style={{ height: 40 }} />
							</CardFooter>
						</Card>
					</form>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};


const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(loginPageStyle)
);

export default enhance(LoginPage);
