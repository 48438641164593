import React, { Component, Fragment, useRef, useState } from 'react';
import XOXLoader from 'components/XOXLoader';
import XNavigation from 'components/XNavigation';
import XSelect from 'components/XSelect';
import XGrid from "components/XGrid";
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as authCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';
import { withStyles, withWidth, Grid, Button, Drawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MapMarker, AccountGroup, Plus, ChartPie, Cash100, ArrowLeft, Check, Filter, CashPlus, Cash, CashMultiple, Pen, Pencil, Undo, TextBox } from 'mdi-material-ui';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import XDateField from "components/XDateField";
import XButton from "components/XButton";
import PerfectScrollbar from "perfect-scrollbar";
import classNames from 'classnames';
import DOCDeadLineManage from './DOCDeadLineManage';
import DOCDeadLineEdit from './DOCDeadLineEdit';
import XStepper from 'components/XStepper';

var ps;
const drawerWidth = 240;
const styles = theme => ({
    root: {
        textAlign: 'center',
    },

    card: {
        textAlign: 'center',
        borderRadius: 1,
    },

    card2: {
        textAlign: 'center',
        borderRadius: 0,
        padding: 20,
    },

    cardCategory: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#000',
        marginTop: 10
    },

    gridStyle: {
        margin: '0',
    },

    loadButton: {
        "& > button": {
            position: 'sticky',
            left: '50%',
            transform: 'translate(-50%)'
        },
    },

    gridLoadButton: {
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#fff',
        boxShadow: '0px -8px 9px rgba(0,0,0,0.2)',
        textAlign: 'center',
    },


    container: {
        width: '100%',
    },

    containerFilters: {
        width: 'calc(100% - 240px)',
    },

    tooltipRoot: {
        backgroundColor: 'white',
        borderRadius: 1,
        borderWidth: 20,
        borderColor: '#fff',
        width: 'auto',
        height: 'auto',
        border: '25px',
        textAlign: 'center',
    },

    DenominationValue: {
        fontWeight: 'bold',
        color: '#141823',
        display: 'inline-block',
    },

    ViewsValue: {
        fontWeight: 'bold',
        color: '#F4B50D',
        textAlign: 'center',
    },

    // Drawer 
    tabContainer: {

    },
    drawer: {

        width: drawerWidth,
        minWidth: drawerWidth,
        maxWidth: drawerWidth,
        flexShrink: 0,
    },

    drawerPaper: {
        width: drawerWidth,
        minWidth: drawerWidth,
        maxWidth: drawerWidth,
        top: '64px',
        height: 'calc(100% - 64px)',
        padding: 0,
        right: 0,
        zIndex: 1,
        boxShadow: '0px 0px 10px rgba(0,0,0,0.5)'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    tabContent: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: "100%",
        transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    tabContentOpen: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    contentShift: {

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },

    sidebarWrapper: {
        position: "relative",
        height: "calc(100vh - 75px)",
        overflow: "auto",
        zIndex: "4",
        overflowScrolling: "touch",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        color: "inherit",
    },
    sidebarWrapperWithPerfectScrollbar: {
        overflow: "hidden !important"
    },

    noResult: {
        textAlign: "center",
        paddingTop: '6%',
        paddingBottom: '10%',
    },

});

class SidebarWrapper extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }
    render() {
        const { className, children } = this.props;
        return (
            <div className={className} ref="sidebarWrapper">
                {children}
            </div>
        );
    }
}


const DeadLineExpandRow = ({ width, data, FIDCycle, FIDIdentity, onPaymentDelete }) => {
    const grdDOCPayments = useRef(null);
    const [view, setView] = useState("documentRows");
    //const onUserDataChange = (setter) => (key) => (data) => {
    
    var actions = [];

    const handleViewChange = (setter) => (key) => (event, value) =>  
    {
        if(value === "documentRows") 
        {
            setter(value);
        }
    
        if(value === "paymentCollections") 
        {
            setter(value);
        }
    }    

    var gridName = "grdDOCPayments";
    var dataRoute = `/DOCDeadLine/GetPaymentsGrid/?grid=${gridName}&FIDDueRegisterRow=${data.ID}`;
    var deleteUrl = `/DOCDeadLine/DeletePayment/`;

    actions.push({ 
        name: "Righe Documento",
        icon: <TextBox />,
        action: () => {  handleViewChange(setView)(undefined)(undefined, "documentRows") },
        //onClick: handleViewChange(setView),
        selected: view === "documentRows" 
    })

    actions.push({ 
        name: "Incassi / Pagamenti",
        icon: <TextBox />, 
        action: () => { handleViewChange(setView)(undefined)(undefined, "paymentCollections")},
        //onClick: handleViewChange(setView),
        selected: view === "paymentCollections" 
    });

    var direction = "right";
    if (["xs"].includes(width)) {
        direction = "down";
    }

    if(view === "documentRows")
    {
        gridName = "grdDOCRows";
        dataRoute = `/DOC/GetDOCRows/${data.FIDDocumentHeader}?grid=${gridName}&FIDCycle=2`
        deleteUrl = "";
    }

    if(view === "paymentCollections")
    {
        gridName = "grdDOCPayments";
        dataRoute = `/DOCDeadLine/GetPaymentsGrid/?grid=${gridName}&FIDDueRegisterRow=${data.ID}`;
        deleteUrl = `/DOCDeadLine/DeletePayment/`;
    }

    var label = <XNavigation 
        actions={actions}
        direction={direction}
        showLabel={true} 
    />;


    return <XGrid
        key={gridName}
        label={label}
        innerRef={grdDOCPayments}
        containerID={`gridExpandContainer_${data.ID}`}
        dataRoute={dataRoute}
        dataRouteDelete={deleteUrl}
        dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
        dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
        dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
        showColumnsSelector={true}
        showFilterActivator={true}
        afterDelete={onPaymentDelete}
    />;
}

const DOCDeadLine = (props) => {
    const grdDueRegisters = useRef(null)
    const DeadlineManage = useRef(null)
    const DeadlineEdit = useRef(null);

    const { classes, width } = props;

    let toDate = new Date();
    toDate.setFullYear(toDate.getFullYear() + 1);
    let fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 1);

    const [fromDocumentDate, setFromDocumentDate] = useState(fromDate);
    const [fromDeadLineDate, setFromDeadLineDate] = useState(fromDate);
    const [toDocumentDate, setToDocumentDate] = useState(toDate);
    const [toDeadLineDate, setToDeadLineDate] = useState(toDate);

    const [FIDIdentity, setFIDIdentity] = useState(undefined);
    const [FIDCycle, setFIDCycle] = useState(undefined);
    const [dueFilters, setDueFilters] = useState(false);

    const [managedDeadline, setManagedDeadline] = useState(false);
    const [editDeadline, setEditDeadline] = useState(false);
    const [deadline, setDeadline] = useState(undefined);

    const [navigationView, setNavigationView] = useState("TO_MANAGE");
    const [showAllStep, setShowAllStep] = useState(0);

    const onUserDataChange = (setter) => (key) => (data) => {
        setter(data);
    }
    const handleSelectChange = (setter) => (key) => (event, value) => {
        var setValue = undefined;
        if (value && value.length > 0) {
            setValue = value[0].ID;
        }
        setter(setValue);
    }
    const loadData = () => {
        grdDueRegisters.current && grdDueRegisters.current.refresh();
    }

    const confirmDeadlineManage = () => {
        if (DeadlineManage.current.confirm()) {
            setManagedDeadline(false);
            setDeadline(undefined);
            grdDueRegisters.current.clearSelection();
            grdDueRegisters.current.refresh();
        }
    }
    const onDeadlineManageBack = () => {
        if (!DeadlineManage.current.back()) {
            setManagedDeadline(false);
            setDeadline(undefined);
        }
    }
    
    const confirmDeadlineEdit = async () => {
        if (await DeadlineEdit.current.confirm()) {
            setEditDeadline(false);
            setDeadline(undefined);
            grdDueRegisters.current.clearSelection();
            grdDueRegisters.current.refresh();
        }
    }
    const onDeadlineEditBack = () => {
        if (!DeadlineEdit.current.back()) {
            setEditDeadline(false);
            setDeadline(undefined);
        }
    }
    
    const restoreDeadlineManage = async () => {
        if (await DeadlineEdit.current.restore()) {
            setEditDeadline(false);
            setDeadline(undefined);
            grdDueRegisters.current.clearSelection();
            grdDueRegisters.current.refresh();
        }
    }

    const updateFilters = () => {
        setDueFilters(!dueFilters);
    }
    const onManageDeadline = () => {
        var arr = grdDueRegisters.current.getSelectedData();
        if (arr.length !== 1) {
            return;
        }
        var data = grdDueRegisters.current.getRowData(arr[0]);
        setDeadline(data);
        setManagedDeadline(true);
    }
    const onSetNavigationView = (view) => () => {
        setNavigationView(view);
        grdDueRegisters.current.refresh();
    }
    const onPaymentDelete = () => {
        grdDueRegisters.current.refresh();
    }
    const onEditDeadline = () => {
        var arr = grdDueRegisters.current.getSelectedData();
        if (arr.length !== 1) {
            return;
        }
        var data = grdDueRegisters.current.getRowData(arr[0]);
        setDeadline(data);
        setEditDeadline(true);
    }
    const onShowAllChange = (step) => {
        setShowAllStep(step);
    }

    const renderFiltersPanel = () => {
        return (
            <Grid style={{ margin: 0 }} container xs={12} spacing={3}>
                <Grid item xs={12}><XDateField clearable label="Da data documento" type="date" id="fromDocumentDate" maxDate={toDocumentDate} value={fromDocumentDate} onChange={onUserDataChange(setFromDocumentDate)} /></Grid>
                <Grid item xs={12}><XDateField clearable={false} label="A Data documento" type="date" id="toDocumentDate" minDate={fromDocumentDate} value={toDocumentDate} onChange={onUserDataChange(setToDocumentDate)} /></Grid>
                <Grid item xs={12}><XDateField clearable={false} label="Da data scadenza" type="date" id="fromDeadLineDate" maxDate={toDeadLineDate} value={fromDeadLineDate} onChange={onUserDataChange(setFromDeadLineDate)} /></Grid>
                <Grid item xs={12}><XDateField clearable={false} label="A Data scadenza" type="date" id="toDeadLineDate" minDate={fromDeadLineDate} value={toDeadLineDate} onChange={onUserDataChange(setToDeadLineDate)} /></Grid>
                <Grid item xs={12}><XSelect clearable label={getTranslation(7, "BI_TITLE_PARAMS_IDENTITY", props.translations, "Anagrafiche")} id="FIDIdentity" urlData="/IR/GetIRSelect" value={FIDIdentity} onChange={handleSelectChange(setFIDIdentity)} idKey="ID" valueKey="Denomination" subtitleKey="Address" /></Grid>
                <Grid item xs={12}><XSelect filtersExclude={[{ key: "ID", value: props.params.InternalCycleId }]} clearable label="Scadenze" id="FIDCycle" urlData="doc/cycle" value={FIDCycle} onChange={handleSelectChange(setFIDCycle)} idKey="ID" valueKey="Denomination" subtitleKey="Address" /></Grid>
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={loadData}>{getTranslation(7, "BI_BUTTON_LOADDATA", props.translations, "Carica Dati")} </XButton></Grid>-
            </Grid>
        )
    }
    const rowExpandFormat = (data) => {
        return <DeadLineExpandRow data={data} translations={props.translations} onPaymentDelete={onPaymentDelete} />;
    }

    const gridName = "grdDueRegisters";
    let content = undefined;
    var actions = [
        {
            group: 'filters',
            name: "Filtri avanzati",
            icon: <Filter />,
            action: updateFilters
        },
        {
            group: 'actions',
            name: "Modifica",
            icon: <Pencil />,
            action: onEditDeadline,
            condition: rows => {
                if(rows.length === 1){
                    let data = grdDueRegisters.current.getRowData(rows[0]);
                    return Math.abs(data.RemainingAmount) > 0 && !data.IsPrevisional;
                }
                return false;
            }
        },
        {
            group: 'actions',
            name: "Incasso/pagamento",
            icon: <Cash100 />,
            action: onManageDeadline,
            condition: rows => {
                if(rows.length === 1){
                    let data = grdDueRegisters.current.getRowData(rows[0]);
                    return Math.abs(data.RemainingAmount) > 0 && !data.IsPrevisional;
                }
                return false;
            }
        }
    ];
    var navigationActions = [];
    navigationActions.push({ name: "Da gestire", icon: <Cash />, action: onSetNavigationView("TO_MANAGE"), selected: navigationView === "TO_MANAGE" });
    navigationActions.push({ name: "Gestite", icon: <CashPlus />, action: onSetNavigationView("MANAGED"), selected: navigationView === "MANAGED" });
    navigationActions.push({ name: "Tutte", icon: <CashMultiple />, action: onSetNavigationView("ALL"), selected: navigationView === "ALL" });
    var direction = "right";
    if (["xs"].includes(width)) {
        direction = "down";
    }
    var fieldShowAll = <XStepper label={getTranslation(4,"DOC_DEADLINES",props.translations, "Scadenze")} step={showAllStep} steps={[{label:getTranslation(4,"DOC_PAY_REAL",props.translations, "Effettive")},{label:getTranslation(4,"DOC_PAY_PREVISIONAL",props.translations, "Previsionali")},{label:getTranslation(4,"DOC_PAY_ALL",props.translations, "Tutte")}]} onStepClick={onShowAllChange} />;
        
    var label = <span>
        <span style={{ marginTop: 8, display: "inline-block" }}>
            <XNavigation actions={navigationActions} direction={direction} showLabel />
            </span>
        <span style={{ display: "inline-block", position: "relative", bottom: 8 }}>{fieldShowAll} </span>
    </span>;



    let dataRoute = `/DOCDeadLine/GetDocumentsGrid?grid=${gridName}&FIDCycle=${FIDCycle}&FIDIdentity=${FIDIdentity}&fromDocumentDate=${fromDocumentDate.toISOString()}&toDocumentDate=${toDocumentDate.toISOString()}&fromDeadLineDate=${fromDeadLineDate.toISOString()}&toDeadLineDate=${toDeadLineDate.toISOString()}&view=${navigationView}&showAll=${showAllStep}`;
    

    if (managedDeadline) {
        content = <DOCDeadLineManage innerRef={DeadlineManage} data={deadline} />;
        actions = [
            {
                group: 'actions',
                name: 'Conferma',
                icon: <Check />,
                action: confirmDeadlineManage
            },
            {
                group: 'actions',
                name: 'Indietro',
                icon: <ArrowLeft />,
                action: onDeadlineManageBack
            }
        ];
    }
    if(editDeadline){
        content = <DOCDeadLineEdit innerRef={DeadlineEdit} data={deadline} />;
        actions = [
            {
                group: 'actions',
                name: 'Conferma',
                icon: <Check />,
                action: confirmDeadlineEdit
            },
            {
                group: 'actions',
                name: 'Ripristina versione',
                icon: <Undo />,
                action: restoreDeadlineManage,
                condition: (rows) => {
                    return rows.length === 1 && !!grdDueRegisters.current.getRowData(rows[0]).FIDDueRegisterOLD;
                    //
                    //return DeadlineEdit.current && DeadlineEdit.current.canRestore();
                }

            },
            {
                group: 'actions',
                name: 'Indietro',
                icon: <ArrowLeft />,
                action: onDeadlineEditBack
            }
        ];
    }

    return (
        <Fragment>
            <div id="panelgrdDueRegisters" className={dueFilters ? classes.containerFilters : classes.container} style={{height: 'calc(100vh - 60px)'}}>
                <XGrid
                    key={dataRoute}
                    innerRef={grdDueRegisters}
                    containerID="panelgrdDueRegisters"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    actions={actions}
                    //rowsVisible={10}
                    label={label}
                    showChangeView={true}
                    changeViewHideCard={true}
                    baseUrlView
                    usePrimaryColor={true}
                    rowExpandFormat={rowExpandFormat}
                    cardWidth={4}
                    cardHeight={4.5}
                    showLoader
                />
            </div>
            {!managedDeadline && <Drawer
                className={classNames(classes.drawer, { [classes.drawerOpen]: dueFilters })}
                variant="persistent"
                anchor="right"
                open={dueFilters}
                classes={{ paper: classes.drawerPaper, }}>
                <SidebarWrapper className={classes.sidebarWrapper}>
                    {renderFiltersPanel()}
                </SidebarWrapper>
            </Drawer>}

        </Fragment>
    )
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(authCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(DOCDeadLine);