import React, { Component, Fragment } from 'react';

import AppBar from '@material-ui/core/AppBar';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import SaveIcon from '@material-ui/icons/Save';
import CachedIcon from '@material-ui/icons/Cached';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import XExpansionPanel from 'components/XExpansionPanel';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CodeIcon from '@material-ui/icons/Code';
//import AddIcon from '@material-ui/icons/Add';

// #region ImportsForUI
import XTag from 'components/XTag';
import XField from 'components/XField';
import XSwitch from 'components/XSwitch';
import XSelect from 'components/XSelect';
import XOXLoader from 'components/XOXLoader';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

//#endregion ImportsForUI
// #region ImportActionBindCreators
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
// #endregion ImportActionBindCreators

import NumberFormat from 'react-number-format';
import { AndroidDebugBridge } from 'mdi-material-ui';
import { IconButton } from '@material-ui/core';

const styles = theme => ({

});

const somerandomarr = ['Armor','Oak','LLLL','BBBB', 'ppppppp', 'ooooooooooo', 'lllll', 'uuuuuuuu', 'aaaaaaaaa', 'ccccc', 'BAI BOSS STAI MA BOSS', 'DDDDD', 'GGGGGG', 'MMMMMM', 'NNNNNN', 'QQQQQQQ', 'WWWWWWW', 'EEEEEE', 'RRRRRRR', 'SSSSSSS', 'TTTTT', 'YYYYYYYY', 'KKKKKK', 'JJJJJJJJ', 'FFFFFF'];

class XOXParams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            params:[],
            showLoader: true,
            data:{},
            fields: [],
            open: false,
            vertical: 'top',
            horizontal: 'center',
        };
    }
    
    componentDidMount() {
       this.getXOXParams();
       this.getXOXData(); // Get Data utils.GetParams();
    }

    

    saveXOXParams = async() => {
        this.setState({showLoader: true});
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({Data: this.state.data})
        }
        const response = await fetch('/XOXParams/SaveXOXData', requestOptions);
        const apiResponse = await response.json();
        this.setState({showLoader: apiResponse.showLoader}, () => {
			fetch('/GC/params').then(res => res.json()).then(data => {
				//Object.keys(data).map(function (key, index) { data[key] = JSON.parse(data[key]) })
				var keys = Object.keys(data);
				for(var i in keys){
					var key = keys[i];
					var val =data[key];
					if(typeof val === "string"){
						try {
							val = JSON.parse(val);
						}
						catch(err) {
							console.error(err);
						}
					}
					data[key]= val;
				}
				this.props.setParams(data);
			});
		});
    }

    getXOXParams = async() => {
        var { params, showLoader } = this.state; 
        this.setState({showLoader: true}, async() => {
            const response = await fetch('/XOXParams/GetParamFields');
            const params = await response.json();
            this.setState({params}, async()=>{
                this.setState({showLoader: false})
            });
        });
    }

    getXOXData = async() => {
        this.setState({showLoader: true}, async() => {
            const response = await fetch('/XOXParams/GetXOXData');
            const data = await response.json();
            this.setState({data}, async()=>{
                this.setState({showLoader: false})
            });
        });
    }
    

    reloadData = async() => {
        await this.getXOXData();
        await this.getXOXParams();
    }


    handlexSwitchOnChange = (dataKey) =>(id) => async (event,value) => { 
        //var { data } = this.state;
        this.setState(state => {
            if(dataKey && dataKey != null) 
            {
                if(!this.state.data[dataKey]) 
                {
                    this.state.data[dataKey] = {};
                };
                this.state.data[dataKey][id] = value;
            }
            else 
            {
            state.data[id] = value;
            }
            return state;
        });

    }

    handleSelectChange = (dataKey, index) => (key) => (event, value) => {

        this.setState(state => {
            if (value && value.length > 0) {
                if(dataKey && dataKey != null)
                {
                    if(!this.state.data[dataKey]) {
                        this.state.data[dataKey] = {};
                    }
                    if(index === undefined) {
                        this.state.data[dataKey][key] = value[0].ID;
                    }
                    else {
                        if(!this.state.data[dataKey][index]) {
                            this.state.data[dataKey][index] = {};
                        }
                        
                        this.state.data[dataKey][index][key] = value[0].ID;
                    }
                } else if (key) 
                {
                    state.data[key] = value[0].ID;
                }
            }
            else if(key) {
                state.data[key] = undefined;
            }

            return state;
        });
    }

    // #region XField
    onXFieldFilterChange = (dataKey, index = undefined) => (key) => (event, value) => {
        this.setState(state => {
            if(dataKey && this.state.data){
                if(dataKey && dataKey != null)
                {
                    if(!this.state.data[dataKey]) {
                        this.state.data[dataKey] = {};
                    }
                    if(index === undefined) {
                        this.state.data[dataKey][key] = value;
                    }
                    else {
                        if(!this.state.data[dataKey][index]) {
                            this.state.data[dataKey][index] = {};
                        }
                        
                        this.state.data[dataKey][index][key] = value;
                    }
                }
                else {
                    state.data[key] = value;
                }
            }
            else if (key)
            {
                state.data[key] = value;
            }
            return state;
        }, () => {
        });
    }
    // #endregion XField

    // #region XTagGet/Handle
    getField = (fields, field) => {
        var filtered = fields.filter((item) => item.Field === field);
        if (filtered.length > 0) {
            return filtered[0];
        }
        return undefined;
    }

    handleFieldChange = (dataKey) => (key) => (event, value) => {
        this.setState(state => {
            if(dataKey && dataKey != null)
            {
                if(!this.state.data[dataKey]) 
                {
                    this.state.data[dataKey] = {}; // Create new obj
                }
                state.data[dataKey][key] = value;
            } 
            else 
            {
            state.data[key] = value;
            }
            return state;
        });
    }
     // #endregion XTagGet/Handle
    renderPanel = (tabName, sectionName) => {
        
        var {params} = this.state;

        var filteredParams = params.filter((item) => item.TabName === tabName && item.SectionName === sectionName);

        return (
            <Fragment>
                <Grid container xs={12}>
                {/* <LettersNavigation dataArray={somerandomarr} vertical/> */}
                    <Grid container xs={12} direction="column"> {/* xs = 10 for LetterNavigation*/}
                        <Grid container spacing={3}>
                            {filteredParams.map(this.renderFields(this.state.data))}
                </Grid>
            </Grid>
        </Grid>
        </Fragment>);
    }

    renderFields = (data, dataKey, index) => (item) => {
        var { vertical, horizontal, open} = this.state;
        if(index !== undefined && index !== null){

        }
        switch (item.Type) {
            case "ND":
                return (<Grid item xs={3}>
                    <p>Parametro `{item.Name}` da configurare</p>
                </Grid>);
            case 'XField':
                return (
                    <Grid item xs={3}>
                        <XField
                            disabled={item.Fieldreadonly} // Text Field
                            readOnly={item.Fieldreadonly} // Numeric Input
                            Type={NumberFormat}
                            label={item.Label}
                            onChange={this.onXFieldFilterChange(dataKey, index)}
                            id={item.Name}
                            
                            value={data[item.Name] ?? data[item.Name]}
                        />
                    </Grid>
                );
            case 'XSwitch':
                return (
                    <Grid item xs={3}>
                        <XSwitch
                            disabled={item.Fieldreadonly}
                            id={item.Name}
                            label={item.Label}
                            onChange={this.handlexSwitchOnChange(dataKey, index)}
                            value={data[item.Name] ?? data[item.Name]}
                            caller={this}
                        />
                    </Grid>
                );
                break;
            case 'XSelect':
                return (
                    <Grid item xs={3}>
                        <XSelect
                            clearable
                            disabled={item.Fieldreadonly}
                            label={item.Label}
                            id={item.Name}
                            urlData={item.DataUrl}
                            value={data[item.Name] ?? data[item.Name]} //this.state.data["CompanyGroupId"]
                            onChange={this.handleSelectChange(dataKey, index)}
                            idKey={item.IdKey}
                            valueKey={item.ValueKey}
                            abbreviationKey={item.AbbreviationKey}
                        />
                    </Grid>
                );
                break;
            case 'XTag':
                return (
                    <Grid item xs={3}>
                        <XTag
                            disabled={item.Fieldreadonly}
							keepInsertOrder
                            //field={this.getField(this.state.data[item.Field], this.state.data[item.Name])}
                            label={item.Label}
                            id={item.Name}
                            urlData={item.DataUrl}
                            value={data[item.Name] ?? data[item.Name]}
                            idKey={item.IdKey}
                            valueKey={item.ValueKey}
                            abbreviationKey={item.AbbreviationKey}
                            onChange={this.handleFieldChange(dataKey, index)} />
                    </Grid>
                );
                break;
            case "Panel":
                return (
                    <Grid container spacing={2} style={{border: 'solid', margin: '2px -8px'}}>
                        <Grid item xs={12}>{item.Label}</Grid>
                        {item.Fields.map(this.renderFields( (data && data[item.Name]) || {} , item.Name))}
                    </Grid>
                );
                break;
            case "List":
                return (
                    
                    <Grid container spacing={2} style={{border: 'solid', margin: '2px -8px'}}>
                        <Grid item xs={12}>
                            {item.Label} 
                                <IconButton 
                                    onClick={this.AddRecordToList(item.Name)}> 
                                        <AddIcon /> 
                                </IconButton>
                        </Grid>
                        {data && data[item.Name] && data[item.Name].map((recordData, index) => (
                            <Grid container spacing={2}>
                                {item.Fields.map(this.renderFields(recordData, item.Name, index))}
                                <Grid item xs={12} sm={4} md={3}>
                                    <IconButton onClick={this.DeleteItemFromList(item.Name, index)}> <DeleteIcon /> </IconButton>
                                </Grid>
                            </Grid>
                        ))} 
                            
                    </Grid>
                );
                break;
            default:
                break;
        }
}

    AddRecordToList = (key) => () =>
    {
        this.setState(state =>{
            if(!state.data[key]){
                state.data[key] = [];
            }
            state.data[key].push({});
            return state;
        });

        this.openPopUp();
    }
    
    DeleteItemFromList = (recordData, index) => () =>
    {
        this.setState(state =>{
            if(index > -1)
            {
               state.data[recordData].splice(index, 1);
            }
            return state;
        });
    }

    // #region  NotImplemented
    openPopUp = (newState) => 
    {  
        this.setState({ open: true, ...newState });

        setTimeout(() => {
            this.setState({open: false, ...newState});
        }, 2000);
    }
    // #endregion NotImplemented

    renderTab = (tabName) => {
        var { params } = this.state;
        var paramsOfTab = params.filter(item => item.TabName === tabName);
        var panelsArray = paramsOfTab.map(item => item.SectionName);
        var distinctPanels = panelsArray.filter((value,index,self)=> self.indexOf(value) === index);
        distinctPanels.sort();
        var panels = distinctPanels.map(sectionName => ({
                                    icon: <CodeIcon />,
                                    label: sectionName,
                                    content: this.renderPanel(tabName,sectionName)
                                }));

            
        
        return <XExpansionPanel panels={panels} />
    }
    

    render() {
        var { params, showLoader, Fields } = this.state;
        
        if(!showLoader) {
            
        var actions = [
            {
                group: 'actions',
                name: "Save", 
                icon: <SaveIcon />,
                action: this.saveXOXParams,
            },
            {
                group: 'actions',
                name: "Reload Data", 
                icon: <CachedIcon />,
                action: this.reloadData,
            },
        ];

        var tabsArray = params.map(item => item.TabName);
        var distinctTabs = tabsArray.filter((value,index,self)=> self.indexOf(value) === index);
        distinctTabs.sort();
       
        var tabs = distinctTabs.map(tabName => ({
            tabName: tabName,
            tabContent: this.renderTab(tabName),
            actions: actions
            
        })) || [];
            var viewContent = (
                <Fragment>
                    <Tabs
                        headerColor="primary"
                        plainTabs={true}
                        tabs={tabs}
                        onTabChange={this.onTabChanged}
                    />
                </Fragment>
                
            );
        } else if(showLoader){
            var viewContent = (<XOXLoader></XOXLoader>);
        }
        return viewContent;
    }
}


// #region Enhance
const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
	connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    withStyles(styles)
);
// #endregion Enhance
export default enhance(XOXParams);