import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import CashRow from './CashRow';
import CashCell from './CashCell';
import XButton from '../../../components/XButton';

const styles = theme => ({
    root: {

    },
    cashSelector: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#fff',
        zIndex: 1,
        margin: 8,
    },
    button: {
        width: '100%',
    }
});


class CashSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            showCashSelector: false,
        }
    }

    componentDidMount() {
        this.loadData();
    }
    loadData = async () => {
        var { loadDataUrl, filterData } = this.props;
        var registers = await fetch(loadDataUrl).then(res => res.json());
        
        var data = !!filterData ? registers.filter(item => filterData(item)) : registers;
        this.setState({data});
    }
    onCashRegisterClick = () => {
        this.setState({showCashSelector: true});
    }
    onCancelClick = () => {
        this.setState({showCashSelector: false});
    }
    onCashRegisterSelection = (id) => () => {
        var { onChange } = this.props;
        onChange && onChange(id);
        this.setState({showCashSelector: false});
    }
    render() {
        var { FIDItem, classes, confirmed, valueKey, label } = this.props;
        var { data, showCashSelector } = this.state;

        var selectedItem = data.filter(item => item.ID === FIDItem)[0];
        if(showCashSelector){
            return (
                <div className={classes.cashSelector}>
                    {data && data.map(item => <CashRow key={item.ID} onClick={this.onCashRegisterSelection(item.ID)}><CashCell>{`${item[valueKey]}`}</CashCell></CashRow>)}
                    <CashRow>
                        <CashCell width={"100%"} totalWidth={"100%"}>
                            <XButton className={classes.button} onClick={this.onCancelClick}>CHIUDI</XButton>
                        </CashCell>
                    </CashRow>
                </div>
            );
        }
        return <CashRow>
            <CashCell label={label} width="100%" key={selectedItem && selectedItem.ID || 0} onClick={!confirmed && this.onCashRegisterClick}>{selectedItem && `${selectedItem[valueKey]}` || '...'}</CashCell>
        </CashRow>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashSelector);