const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";
const CHECK_LOGIN = "CHECK_LOGIN";
const CHECK_LOGIN_COMPLETE = "CHECK_LOGIN_COMPLETE";
const CHANGE_PROFILE = "CHANGE_PROFILE";
const RESET_PROFILE = "RESET_PROFILE";
const initialState = { 
    isLoggedIn: false, 
    isFetching: false,
    username: undefined, 
    error: undefined, 
    originalUser: undefined, 
    FIDLicensee: undefined, 
    FIDLicenseeDestination: undefined, 
    Destination: undefined, 
    FIDProfile: undefined, 
    IsResponsible: false, 
    isProductionOperator: false, 
    isAgent: false, 
    actionType: null, 
    FIDDOCOrigin: null,
    FIDUserType: undefined,
};

export const actionCreators = {
    login: (username, password) => {
        return { type: LOGIN, username: username, password: password }
    },
    logout: () => ({ type: LOGOUT }),
    loginSuccess: (data, FIDOffice) => {
        if (!FIDOffice) {
            FIDOffice = data.fidOffice || data.fidLicensee;
				}
        return { type: LOGIN_SUCCESS, userID: data.userID, username: data.username, FIDLicensee: data.fidLicensee, FIDLicenseeDestination: FIDOffice, Destination: data.destination, FIDProfile: data.profileId, FIDIRPricelist: data.fidirPricelist, IsResponsible: data.isResponsible, isProductionOperator: data.isProductionOperator, data }
    },
    loginFailure: (username, password, barcode, json) => {
        return { type: LOGIN_FAILURE, username, password, barcode, data: json }
    },
    checkLogin: () => async (dispatch, getState) => {
        dispatch({ type: CHECK_LOGIN });
        const data = await fetch('/Base/CheckLogin/').then(res => res.json());
        dispatch({ type: CHECK_LOGIN_COMPLETE, data });
    },
    changeProfile: () => ({ type: CHANGE_PROFILE }),
    resetProfile: () => ({ type: RESET_PROFILE }),
}

export const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = document.cookie;//decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const setCookie = (cname, cvalue) => {
    var d = new Date();
    d.setTime(d.getTime() + (12 * 60 * 60 * 1000));
    document.cookie = cname + "=" + cvalue + ";expires=" + d.toUTCString() + ";path=/";
}

export const deleteCookie = (cname) => {
    var d = new Date();
    d.setTime(d.getTime() - (1000 * 24 * 60 * 60 * 1000));
    document.cookie = cname + "=;expires=" + d.toUTCString() + ";path=/";
}
// const doLogin = async (action) => {

//     var result = await fetch(`/Base/Login/?ActionType=${ActionType}`, {
//         method: 'POST',
//         body: JSON.stringify({ Username: action.username, Password: action.password })
//     }).then(res => res.json())
//     return result;
// }
export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === LOGIN) {
        if ((action.username.toLowerCase() === "test" && action.password === "test")) {//non più utilizzato
            setCookie("username", action.username);
            return { ...state, isLoggedIn: true, username: action.username, error: undefined };
        }
        else {
            return { ...state, isLoggedIn: false, error: 'Username o password errati' };
        }
    }
    if (action.type === LOGOUT) {
        //var ActionType = Date.now();

        fetch(`/Base/Logout/?ActionType=${state.actionType}`,{
            method:'POST'
        });
        deleteCookie("username");
		deleteCookie("_UserId");
		deleteCookie("_Username");
		deleteCookie("_UserOfficeId");
        return { ...state, isLoggedIn: false, userID: undefined, username: undefined, originalUser: undefined, FIDLicensee: undefined, FIDLicenseeDestination: undefined, FIDProfile: undefined,FIDUserType: undefined, FIDIRPricelist: undefined, IsResponsible: false, isProductionOperator: false };
    }
    if (action.type === LOGIN_FAILURE) {
				return { ...state, isLoggedIn: false, isProductionOperator: false, error: action.data.errorMessage };
    }
    if (action.type === LOGIN_SUCCESS) {
		var FIDIRPricelist = action.FIDIRPricelist;
		
		if (!FIDIRPricelist) {
			FIDIRPricelist = action.data && action.data.length > 0 ? action.data.fidirPricelist : undefined;
		}
		
        setCookie("username", action.username);
        return { ...state, isLoggedIn: true, userID: action.userID, username: action.username, FIDLicensee: action.FIDLicensee, FIDLicenseeDestination: action.FIDLicenseeDestination, error: undefined, Destination: action.Destination, FIDProfile: action.FIDProfile,FIDUserType: action.data.userTypeId, FIDIRPricelist: FIDIRPricelist, IsResponsible: action.IsResponsible, isProductionOperator: action.isProductionOperator, isAgent: action.data.isAgent, isLicensee: action.data.isLicensee, actionType: action.data.actionType, FIDDOCOrigin: action.data.fiddocOrigin };
    }
    
    if (action.type === CHECK_LOGIN) {
        //var username = getCookie('username');
        //if (username) {
        //    setCookie("username", username);
        //    return { ...state, isLoggedIn: true, username: username, error: undefined };
        //}
    }
    if (action.type === CHECK_LOGIN_COMPLETE) {
        if(!state.isLoggedIn){
            if (action.data.success) {
				
				var FIDIRPricelist = action.fidirPricelist;
		
				if (!FIDIRPricelist) {
					FIDIRPricelist = action.data ? action.data.fidirPricelist : undefined;
				}
				
                return { ...state, isLoggedIn: true, userID: action.data.userID, username: action.data.username, FIDLicensee: action.data.fidLicensee, FIDLicenseeDestination: action.data.fidOffice, error: undefined, Destination: action.data.destination, FIDProfile: action.data.profileId,FIDUserType: action.data.userTypeId, IsResponsible: action.data.isResponsible, isProductionOperator: action.data.isProductionOperator, isAgent: action.data.isAgent, FIDIRPricelist: FIDIRPricelist, isLicensee: action.data.isLicensee, FIDDOCOrigin: action.data.fiddocOrigin };
            }
            return { ...state, isLoggedIn: false, userID: undefined, username: undefined, originalUser: undefined, FIDLicensee: undefined, FIDLicenseeDestination: undefined, Destination: undefined, FIDProfile: undefined,FIDUserType: undefined, IsResponsible: false, isProductionOperator: false, isAgent: false };
        }
    }

    if (action.type === RESET_PROFILE) {
        return { ...state, originalUser: undefined };
    }
    if (action.type === CHANGE_PROFILE) {
        deleteCookie("username");
        return { ...state, isLoggedIn: false, userID: undefined, username: undefined, originalUser: state.originalUser || state.username, isProductionOperator: false };
    }

    return state;
}
