import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import XGrid from "../components/XGrid"
import XSwitch from "../components/XSwitch"
import XNavigation from "../components/XNavigation"
import XMessageBox from 'components/XMessageBox';
import "../components/XReactDataGrid.css";
import XPrintOptionSelector from "../components/XPrintOptionSelector"
import { Tooltip, IconButton,Grid } from '@material-ui/core';
import { AccountBadgeOutline, RedoVariant, ArrowLeft, Printer, RayEndArrow, RayStartArrow, TextBox } from 'mdi-material-ui';

import { Typography, Drawer } from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators } from '../store/Auth';
import XPDFViewer from 'components/XPDFViewer';
import XSelect from '../components/XSelect';
import { MaterialIcon } from 'utils';

class DocumentExpandRow extends Component {
    constructor(props){
        super(props);
        this.state = {
            view : this.props.view,
            showAmounts: true,
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }

    handleGridDoubleClick = (event, data) => {
        var { view } = this.state;
        this.context.router.history.push(`/doc/edit/${data.ID}?${view}=true`);
    }

    showDocumentRows = () => {
        this.setState({view: "documentRows"})
    }

    showPrevious = () => {
        this.setState({view: "previous"});
    }

    showNext = () => {
        this.setState({view: "next"});
    }

    render () {
        var { width,data } = this.props;
        var { view } = this.state;
        var label = undefined;
        var actions = [];

        actions.push({ name: "Righe Documento", icon: <TextBox />, action: this.showDocumentRows, selected: view == "documentRows" })        
        
        actions.push({ name: "Precedenti", icon: <RayEndArrow />, action: this.showPrevious, selected: view === "previous" });
        
        actions.push({ name: "Successivi", icon: <RayStartArrow />, action: this.showNext, selected: view === "next" });
        
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var label = <XNavigation actions={actions} direction={direction} showLabel={true} />;

        var gridName = "tblDOCProcessed";
        var dataRoute = `/DOC/GetProcessedDocs/${data.ID}?grid=${gridName}`;
        var key = `${gridName}_${view}`;
        if(view ==="next") {
             dataRoute = `/DOC/GetProcessedDocsExt/${data.ID}?grid=${gridName}`;
        }

        if(view === "documentRows") {
            gridName = "grdDOCRows";
            dataRoute = `/DOC/GetDOCRows/${data.ID}?grid=${gridName}&FIDCycle=2`
        }
        
        return <XGrid
            key={key}
            label={label}
            containerID={`gridExpandContainer_${data.ID}`}
            dataRoute={dataRoute}
            dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
            dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
            dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
            onDoubleClick={this.handleGridDoubleClick}
            onEditButton={this.handleGridDoubleClick}
            rowVisible={10}
            showColumnsSelector={true}
            showFilterActivator={true}
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    )
);

export default enhance(DocumentExpandRow);