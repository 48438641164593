import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withWidth } from '@material-ui/core';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { isJObjectEmpty } from 'utils';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import XOXLoader from 'components/XOXLoader';
import XMessageBox from 'components/XMessageBox';
import XGMap from 'components/XGMap';

import TabContainer from 'components/CustomTabs/TabContainer';
import XExpansionPanel from 'components/XExpansionPanel';
import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XGallery from 'components/XGallery';


import { CustomField } from 'components/CustomField';

import logoLicenseePayOff from "assets/img/logo-licensee-payoff.png";
import logo from "assets/img/logo-white.svg";
import {
  container,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
    root: {
		...container,
		maxHeight: "100vh",
		overflowY: "auto"
    },
	cardTitle: {
        ...cardTitle,
        color: "#FFFFFF"
    },
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        justifyContent: "center !important"
    },
    justifyContentRight: {
        justifyContent: "flex-end !important",
        padding: "0 15px",
        margin: "10px 0 0",
    },
    customButtonClass: {
        "&,&:focus,&:hover": {
            color: "#FFFFFF"
        },
        marginLeft: "5px",
        marginRight: "5px"
    },
    inputAdornment: {
        marginRight: "18px"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)"
    },
    cardHeader: {
        marginBottom: "20px"
    },
    socialLine: {
        padding: "0.9375rem 0"
    },
    body: {
        maxHeight: "74vh",
        overflowY: "auto",
    },
    logoContainer: {
        width: '100%',
        margin: 'auto',
    },
    logoContainerBig: {
        width: '33.33%',
        margin: 'auto',
    },
	brandTitle: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    codeTitle: {
        fontSize: "16px",
        marginBottom: "30px",
        fontWeight: 'bold',
    },
    descriptionTitle: {
        fontSize: "14px",
        marginBottom: "30px",
        whiteSpace: "pre-line",
    },
    attrLabel: {
        fontWeight: 'bold',
    },
    marginTop: {
        marginTop: 20,
        marginBottom: 20,
    },
    brandLogo: {
        maxWidth: "300px",
        maxHeight: "100px",
    },
    center: {
        left: "50%",
        transform: "translateX(-50%)",
    },
    centerText: {
        textAlign: "center",
		margin: '0 auto',
    },
});

const extrasToShow = [ 4, 5, 6, 7, 8, 23 ]
const extrasNewline = [1,23,4]
const extraFW = [];
// Carat Weight, CROWN , PAVILLION, Lot Number, TREATMENTS
// NOTE - NOTE / CURIOSITA
class BOPView extends Component {
	constructor(props) {
        super(props);
		this.MessageBox = React.createRef();
		this.state = {
			bopData: {},
			extras: [],
			bopExtras: [],
			bopFiles: [],
			visibleMap: true,
			error:false,
			warrantyFields:[],
			newlineFields: [],
			fullWidthFields: []
		}
	}
	
	//componentWillMount = async() => {
	//	await fetch('/BOP/GetBOPExtras').then(res => res.json()).then(extras => {
	//		this.setState({ extras });
	//	});
	//}
	static contextTypes = {
        router: PropTypes.object
    }
	componentDidMount = async() => {
		await Promise.all([
			fetch('/Base/GetParam?param=WarrantyCustomFields'),
			fetch('/Base/GetParam?param=WarrantyCustomFieldsNewLine'),
			fetch('/Base/GetParam?param=WarrantyCustomFieldsFullWidth'),
		]).then(([warrantyFields, newlineFields, fullWidthFields]) => {
			return Promise.all([warrantyFields.json(), newlineFields.json(), fullWidthFields.json()]) 
		}).then(([warrantyFields, newlineFields, fullWidthFields]) => {
			this.setState({ warrantyFields, newlineFields, fullWidthFields });
		});
		
		//let warrantyFields = await fetch('/Base/GetParam?param=WarrantyCustomFields').then(res => res.json());
		//let newlineFields = await fetch('/Base/GetParam?param=WarrantyCustomFieldsNewLine').then(res => res.json());
		
		await fetch(`/BOP/GetBOPExtraGroups?skipFilters=${true}`).then(res => res.json()).then( async (extragroups) => {
			await fetch(`/BOP/GetBOPExtras?skipFilters=${true}`).then(res => res.json()).then(extras => {
				this.setState({ extragroups, extras });
		});
		});
		if (this.props.match && this.props.match.params.QRCodeGuid) {
			var QRCodeGuid = this.props.match.params.QRCodeGuid;
			var res = await fetch('/BOP/ViewBOPWarranty?QRCodeGuid='+QRCodeGuid).then(res => res.json());
			if (res.bopData) {
				var bopData = res.bopData;
				var bopExtras = res.bopExtras;
				
				var bopFiles = res.bopFiles && res.bopFiles.length > 0 ? res.bopFiles.split(",") : [];
				
				//if ((!bopData.geoLocationCoords || isJObjectEmpty(bopData.geoLocationCoords)) && (bopData.geoLocationOrigin || bopData.geoLocationAddress)) {
				//	var encodedLocation = encodeURI(bopData.geoLocationOrigin || bopData.geoLocationAddress);
				//	debugger;
				//	var mapData = await fetch("https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input="+encodedLocation+"&inputtype=textquery&fields=geometry&key=AIzaSyCIEOa1VD8JN2NhIQI6e-HC1rr9yxFkSVE", {
				//		mode: 'no-cors'
				//	}).then(res => res.json());
				//	
				//	if (mapData && !isJObjectEmpty(mapData) && mapData.status === "OK") {
				//		bopData.geoLocationCoords = mapData.candidates[0].geometry.location;
				//	} else {
				//		this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("VISUALIZZAZIONE GARANZIA", "Impossibile trovare le coordinate geografiche di provenienza della distinta, la mappa sarà nascosta", () => {
				//			this.setState({ bopData, visibleMap: false });
				//		});
				//		return;
				//	}
				//}
				
				if (!bopData.geoLocationCoords || isJObjectEmpty(bopData.geoLocationCoords)) {
					//this.MessageBox.current && this.MessageBox.current.showMessageOk("VISUALIZZAZIONE GARANZIA", "Impossibile trovare le coordinate geografiche di provenienza della distinta, la mappa sara'�nascosta", () => {
					//		this.setState({ bopData, bopExtras, bopFiles, visibleMap: false });
					//});
					//
					//if (!this.MessageBox.current)
					this.setState({ bopData, bopExtras, bopFiles, visibleMap: false });					
					return;
				}
				
				this.setState({ bopData, bopExtras, bopFiles });
			} else {
				this.setState({ error: true }, () => {
					this.MessageBox.current && this.MessageBox.current.showMessageOk("VISUALIZZAZIONE GARANZIA", "Distinta non trovata", () => {
						this.context.router.history.push("/auth/login");
					});
				});
			}
		} else {
			this.setState({ error: true }, () => {
				this.MessageBox.current && this.MessageBox.current.showMessageOk("VISUALIZZAZIONE GARANZIA", "Distinta non trovata", () => {
					this.context.router.history.push("/auth/login");
				});
			});
		}
	}


	renderExtraPanels = () => {
		var { extragroups, extras } = this.state;
		
		if(!extras || extras.length === 0 || !extragroups || extragroups.length === 0)
			return false;
		
		var panelsToRend = [];
		var FIDGroups = extras.filter(m => m.FIDExtraGroup).map(m => m.FIDExtraGroup).filter((value, index, self) => self.indexOf(value) === index);
        var panels = extragroups.filter(f => FIDGroups.includes(f.ID)).map((item, index) => (
            {
                icon: "code",
                label: item.Description,
                content: this.renderExtraGroup(item),
            }
		));

		if(panels && panels.length > 0) {
			return (<XExpansionPanel panels={panels} allOpen={true} />);
		} else {
			return false;
		}
	};
	

	renderExtraGroup = (group) => {
		var { extras, data, bopData  } = this.state;	
		let addField = undefined;

		if (group.ID === 1) {
			addField = <Grid container xs={12} spacing={2} item>
				<Grid item xs={12} md={4}>
				<XField label="CARAT WEIGHT" readOnly={true} id="weight" value={bopData.secondaryMeasureUnitQty}/>
				</Grid>
			</Grid>
		}
		
		if (group.ID === 3) {
			addField = <Grid container xs={12} spacing={2} item>
				<Grid item xs={12} md={4}>
				<XField label="LOT NUMBER" readOnly={true} id="serialNumber" value={bopData.serialNumber}/>
				</Grid>
			</Grid>
		}
		
		if (!addField && extras.filter(f => group && f.FIDExtraGroup === group.ID).length === 0)
			return false;

        return (
			<Grid container xs={12}>
				{addField}
            	<Grid container xs={12} spacing={2} item>
                {extras && extras.length > 0 && extras.filter(f => ((group && f.FIDExtraGroup === group.ID)) 
				&& this.state.warrantyFields.includes(f.ID)).map((item, index) => {
                    return this.renderExtraField(item, index);
                })}
            	</Grid>
			</Grid>
        );
    }

	
	renderExtraField = (field, index) => {
        var { bopData, bopExtras } = this.state;

		let widthParams = { xs: 12, sm: 6, md: 4 };
		
		if (this.state.fullWidthFields && this.state.fullWidthFields.includes(field.ID))
			widthParams.sm = widthParams.md = 12;
		
        var extra = [];
        if (bopExtras && bopExtras.length > 0) {
            var extraBop = bopExtras.filter(f => f.extraID === field.ID);
			if (extraBop.length > 0) {		
				extra = [{
					StringValue: extraBop[0].stringValue,
					BoolValue: extraBop[0].boolValue,
					IntValue: extraBop[0].intValue,
					ComboValue: extraBop[0].comboValue,
					DecimalValue: extraBop[0].decimalValue,
					DateTimeValue: extraBop[0].dateTimeValue,
					TagValue: extraBop[0].tagValue,
					GroupValue: extraBop[0].groupValue
				}];

				if (this.state.newlineFields && this.state.newlineFields.includes(field.ID))
					return (<Grid item xs={12}><CustomField widthParams={widthParams} fieldProps={{ Required: 3 }} readOnly={true} key={index} field={field} extra={extra} mainId={bopData.id}/></Grid>);

				return (<CustomField widthParams={widthParams} fieldProps={{ Required: 3 }} readOnly={true} key={index} field={field} extra={extra} mainId={bopData.id}/>);
			} else {
				extra = [{
					StringValue: "",
					BoolValue: undefined,
					IntValue: undefined,
					ComboValue: undefined,
					DecimalValue: undefined,
					DateTimeValue: undefined,
					TagValue: undefined,
					GroupValue: undefined
				}];

				if (this.state.newlineFields.includes(field.ID))
					return (<Grid item xs={12}><CustomField widthParams={widthParams} fieldProps={{ Required: 3 }} readOnly={true} key={index} field={field} extra={extra} mainId={bopData.id}/></Grid>);

				return (<CustomField widthParams={widthParams} fieldProps={{ Required: 3 }} readOnly={true} key={index} field={field} extra={extra} mainId={bopData.id}/>);
			}
        }
    }
	
	renderPanelExtras = () => {
		var extras = this.state.extras;
		
		if (!extras || extras.length === 0)
			return false;
		
		return (
            <Grid container spacing={2}>
			{extras.map((item, index) => {
				return this.renderExtraField(item, index);
			})}
			</Grid>
		);
	}
	
	renderPanelMap = () => {
		var { bopData } = this.state;
		
		if (!bopData || isJObjectEmpty(bopData))
			return false;
		
		var locNameOrAddress = bopData.geoLocationOrigin || bopData.geoLocationAddress;
		var gMapLat = bopData.geoLocationCoords.lat;
		var gMapLong = bopData.geoLocationCoords.lng;
		
		return (
			<Grid container spacing={2}>
				<div style={{ height: "50vh", width: "100%" }}><XGMap location={locNameOrAddress} coords={{ lat: gMapLat, lng: gMapLong }} /></div>
			</Grid>
		)
	}
	
	render () {
		var { classes } = this.props;
		var { bopData, bopFiles, visibleMap, extras } = this.state;
		
		let msgBoxUI = <XMessageBox innerRef={this.MessageBox} />;
		
		//if (!bopData || isJObjectEmpty(bopData)) {
		//	return <Fragment><XOXLoader />{msgBoxUI}</Fragment>
		//}
		
		var md = 4;
        var sm = 6;
        var logoClass = classes.logoContainer;
		
		// var extraPanels = extras && extras.length > 0 ? [
		// 	{
		// 		icon: "code",
		// 		label: "Caratteristiche",
		// 		content: this.renderPanelExtras()
		// 	}
		// ] : [];

		let extraPanels = this.renderExtraPanels()
		
		var mapPanels = visibleMap ? [
			{
				icon: "code",
				label: "ORIGIN",
				content: this.renderPanelMap()
			}
		] : [];
		
		return (
			<Fragment>
				{!bopData || isJObjectEmpty(bopData) ? <XOXLoader /> :
				<Card className={classes.root}>
					<CardHeader
						className={`${classes.textCenter}`}>
                            <div className={logoClass}>
                                <img src={logoLicenseePayOff} alt="logo" style={{ width: "40%", marginTop: '5%' }} />
                            </div>
							<div style={{ marginTop: 5, color: 'rgba(46, 49, 49, 1)' }}>
								<h3>WARRANTY CARD</h3>
							</div>
					</CardHeader>
					<CardBody>
						<Grid container xs={12}>
							<Grid item xs={12} md={6} className={classes.centerText} alignItems="center" justify="center">
								{bopFiles && bopFiles.length > 0 && <XGallery key={bopData.id} imageIds={bopFiles} noZoom={false} />}
								<div style={{ marginTop: 30, width: "100%"}}> 
									<Grid item xs={12} className={classes.descriptionTitle}>
										{bopData.description}
									</Grid>                       
								</div>
							</Grid>
						</Grid>
						<TabContainer>{extraPanels}</TabContainer>
						{visibleMap && <TabContainer><XExpansionPanel panels={mapPanels} allOpen={true} /></TabContainer>}
					</CardBody>
					<CardFooter color="gray" className={classes.justifyContentRight}>
						<img src={logo} alt="logo" style={{ height: 40 }} />
					</CardFooter>
				</Card>
				}
				{msgBoxUI}
			</Fragment>
		);
	}
}

const enhance = compose(
    withStyles(styles),
    withWidth(),
	connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
	connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
);
export default enhance(BOPView);