import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import indexRoutes from "routes/index.jsx";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";
import numeral from 'numeral';


import { compose } from 'redux';
import XOXLoader from './components/XOXLoader';
import ReactGA from 'react-ga';
import "moment/locale/it";

const theme = createMuiTheme({
    breakpoints: {
        keys: [
            "xs",
            "sm",
            "md",
            "lg",
            "xl",
        ],
        values: {
            xs: 0,
            sm: 720,
            md: 960,
            lg: 1350,
            xl: 1920
        }
    },
    palette: {
        primary: {
            //main: '#E2B231',
            //main: '#bc3a4d'
            main: window.primaryColor || '#E2B231',
        },
        secondary: {
            //main: '#bc3a4d',
            main: window.secondaryColor || '#383b3d',
        },
        tonalOffset: 0.5,
    },
    typography: {
        useNextVariants: true
    },
    shape: {
        borderRadius: 0,
    }
});

function initializeReactGA() {
    ReactGA.initialize('UA-148221003-1');
    //ReactGA.pageview('/homepage');
}

Number.prototype.round = function (places) {
    return +(Math.round(this + "e+" + places) + "e-" + places);
}
numeral.register('format', 'euro', {
    regexps: {
        format: /(€)/,
        unformat: /(€)/
    },
    format: function (value, format, roundingFunction) {
        var space = numeral._.includes(format, ' €') ? ' ' : '',
            output;

        format = format.replace(/\s?€/, '');

        output = numeral._.numberToFormat(value, format, roundingFunction);

        if (numeral._.includes(output, ')')) {
            output = output.split('');

            output.splice(-1, 0, space + '€');

            output = output.join('');
        } else {
            output = output + space + '€';
        }

        return output;
    },
    unformat: function (string) {
        return numeral._.stringToNumber(string) * 0.01;
    }
});

numeral.register('locale', 'it', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    currency: {
        symbol: '€'
    }
});

numeral.locale('it');
const version = "0.0.3";
const test="TEST4";
class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true
		}
	}
    componentDidMount() {
        console.log(`
███     ███   █████████   ███     ███
 ███   ███   ███     ███   ███   ███ 
  ███ ███    ███     ███    ███ ███  
    ███      ███     ███      ███    
  ███ ███    ███     ███    ███ ███  
 ███   ███   ███     ███   ███   ███ 
███     ███	  █████████   ███     ███

version: ${version}
`);
        fetch('/Base/GetVersion/').then(res => res.json()).then(data => {
            if (version !== data) {
                window.location.reload(true);
            }
            else {
                this.setState({ isLoading: false });
            }
        });
        initializeReactGA();
    }
    render() {
        var ui = <InnerApp />;
        if (this.state.isLoading) {
            ui = <XOXLoader />;
        }
        return (
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale={"it"}>
                    {ui}
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

class InnerApp extends Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
	}
	componentDidMount = () => {
		this._isMounted = true;
    }
    componentWillUnmount = () => {
        this._isMounted = false;
    }
    render() {
		// if (!this._isMounted) {
		// 	return (<div></div>);
		// }
		
        return (<Switch>
            {this.props.isLoggedIn}
            {indexRoutes.map((prop, key) => {
                var { exact } = prop;
                return <Route path={prop.path} component={prop.component} key={key} exact={exact} />;
            })}
        </Switch>);
    }
}

const enhance = compose(
    //connect(
    //    state => state.auth,
    //    dispatch => bindActionCreators(actionCreators, dispatch)
    //),
    //connect(
    //    state => state.global,
    //    dispatch => bindActionCreators(globalCreators, dispatch)
    //),
);

//export default withStyles(appStyle)(Dashboard);
InnerApp = enhance(InnerApp);
export default App;



//export default App;
//export default connect(
//    state => state.auth,
//    dispatch => bindActionCreators(actionCreators, dispatch)
//)(App);