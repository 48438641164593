import React, { Component, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';

import SwipeableViews from 'react-swipeable-views';
import CatalogueCard from 'components/CatalogueCard';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { Pencil, ShareVariant, MessageBulleted, ChevronLeft, ChevronRight } from 'mdi-material-ui';
import { CardBulletedOutline, TextBoxOutline, TextBoxMultipleOutline } from 'mdi-material-ui';
import { FileDocumentBoxMultipleVarOutline } from 'components/Icons';
import { withStyles } from '@material-ui/core/styles';
import groupBy from 'lodash/groupBy';
import withWidth from '@material-ui/core/withWidth';
import numeral from 'numeral';
import { Typography, Badge } from '@material-ui/core';
import XOXLoader from 'components/XOXLoader';
import XGallery from 'components/XGallery';
import XButton from 'components/XButton';
import XField from 'components/XField';
import XDialog from 'components/XDialog';
import CatalogueActions from 'components/CatalogueActions';
import RelatedCard from 'components/RelatedCard';
import XSelect from 'components/XSelect';
import XGrid from 'components/XGrid';
import {FlipCard,Back,Front} from 'components/FlipCard';

import XNavigation from 'components/XNavigation';
import BOPHistory from 'views/BOP/BOPHistory';

import { Check, Star, StarOutline, LockOpenOutline, LockOutline, CartOutline, BellRingOutline, BellOffOutline, StarOff, Handshake, HandRight, Close, CurrencyEur,CurrencyEurOff } from 'mdi-material-ui';
import { LockOffOutline, CartOffOutline } from 'components/Icons';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

import { getXSelect, format, smoothScroll } from 'utils';
import {
    grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";
import XExpansionPanel from './XExpansionPanel';
import CatalogueItemFlipContent from './CatalogueItemFlipContent';

const styles = theme => ({
    card: {
        margin: "8px",
        position: 'relative',
    },
    cardContent: {
        paddingTop: 55,
        minHeight:'calc(100vh - 161px)',
    },
    header: {
        maxHeight: "90px",
        overflow: "hidden",
    },
    media: {
        height: 0,
        paddingTop: 'calc(56.25% / 1.5)', // 16:9
        maxHeight: '80vh',
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)',
        backgroundSize: 'contain',
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: theme.palette.primary,
    },
    price: {
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "right",
    },
    priceText: {
        fontSize: "24px",
        fontWeight: "bold",
        // margin:"25px 0"
    },
    mediaButtonLeft: {
        position: "absolute",
        left: "0",
        top: "50%",
    },
    mediaButtonRight: {
        position: "absolute",
        right: "0",
        top: "50%",
    },
    closeButton: {
        position: "absolute",
        right: 13,
        top: 5,
        zIndex: 1099,
    },
    leftButton: {
        position: "absolute",
        left: 13,
        top: 5,
        zIndex: 1099,
    },
    brandTitle: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    codeTitle: {
        fontSize: "16px",
        /*marginBottom: "30px",*/
        fontWeight: 'bold',
    },
    descriptionTitle: {
        fontSize: "14px",
		marginTop: 30,
        marginBottom: 30,
        whiteSpace: "pre-line",
    },
    attrLabel: {
        fontWeight: 'bold',
    },
    marginTop: {
        marginTop: 20,
        marginBottom: 20,
    },
    brandLogo: {
        maxWidth: "300px",
        maxHeight: "100px",
    },
    center: {
        left: "50%",
        transform: "translateX(-50%)",
    },
    centerText: {
        textAlign: "center",
    },
    catalogueButton: {
        width: 50,
        height: 50,
        display: "inline-block",
        verticalAlign: "top",
        position: "relative",
        background: "#ccc",
        marginLeft: 5,
        transitionDelay: "0s, 0s",
        transitionDuration: "0.2s, 0.2s",
        transitionProperty: "box-shadow, background-color",
        transitionTimingFunction: "cubic-bezier(0.4, 0, 1, 1), cubic-bezier(0.4, 0, 0.2, 1)",
        willChange: "box-shadow, transform",
        "&:hover": {
            boxShadow: "0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)",
        },
        "& > * ": {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
        }
    },
    catalogueButton2: {
        width: 50,
        height: 50,
        padding: 0,
        marginRight: "10px",
        "& > * ": {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
        }
    },
	btnCatQty: {
		paddingLeft: 18,
		paddingRight: 18,
		marginTop: 18,
		letterSpacing: 0,
		marginBottom: 0
	},
	catQtyField: {
		marginTop: 8,
        width: "70%",
		margin: "0 10px",
        "& > div": {
            "&:before": {
                borderBottomWidth: "1px !important",
                borderBottomColor: "#D2D2D2 !important"
            },
            "&:after": {
                borderBottomColor: window.primaryColor + "",
				transform: "scaleX(1) !important"
            }
        },
		"& div > div.MuiInputAdornment-root": {
			fontSize: 15,
			paddingBottom: 7
		},
		"& label": {
			fontSize: 14,
			left: 0
		},
        "& input": {
          borderLeft: "0 !important",
          borderRight: "0 !important",
          borderTop: "0 !important",
          padding: "0 0 7px",
          color: grayColor,
          marginTop: 0,
		  textAlign: "right",
		  fontSize: 15
        },
		"& button.MuiIconButton-root": {
			paddingBottom: 0,
			position: "relative",
			bottom: 3
		}
	},
    ribbon: {
        width: "150px",
        height: "150px",
        overflow: "hidden",
        position: "absolute",
        top: -5,
        left: 2,
        "&:before": {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: "block",
            border: "5px solid #2980b9",
            borderTopColor: "transparent",
            borderLeftColor: "transparent",
            top: 0,
            right: 0,
        },
        "&:after": {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: "block",
            border: "5px solid #2980b9",
            borderTopColor: "transparent",
            borderLeftColor: "transparent",
            bottom: 0,
            left: 0,
        },
        "& span": {
            position: "absolute",
            display: "block",
            width: 225,
            padding: "15px 0",
            backgroundColor: theme.palette.secondary.main,
            boxShadow: "0 5px 10px rgba(0,0,0,.1)",
            color: "#fff",
            font: "700 18px/1 'Lato', sans-serif",
            textShadow: "0 1px 1px rgba(0,0,0,.2)",
            textTransform: "uppercase",
            textAlign: "center",
            right: -25,
            top: 30,
            transform: "rotate(-45deg)",
        },
        stars: {
            fontSize: 30,
        }
    },
    buttonsWrapper: {
        width: "100%",
        "& > *": {
            display: "inline-block",
        }
    },
    popup: {
        position: "fixed",
        maxWidth: 350,
        width: "100%",
        left: "50%",
        top: "50%",
        zIndex: "10000",
        background: "#fff",
        boxShadow: "0px 1px 7px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
        //border: "1px solid",
        padding: "10px",
        transform: "translate(-50%,-50%)",
        
    },
    popupBack: {
        position: "fixed",
        display: "block",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "#999",
        opacity: 0.8,
        zIndex: "9999",
    },
    badgeSmall: {
        "& > span": {
			minWidth: 0,
            width: 10,
            height: 10,
            top: -5,
            right: -5,
        },
    },
    serialNumber: {
        fontWeight: "normal",
		marginTop: 10,
    },
    slider: {
        //maxHeight: 220,
        //minHeight: 220,
        overflow: "hidden !important",
        position: "relative",
        width: "100%",
        cursor: "pointer",
    },
    slide: {
        width: 'calc(100% / 3)',
    },
    btnLeft: {
        position: 'absolute',
        left: 0,
        top: '50%',
        //transform:'translateY(-50%)',
    },
    btnRight: {
        position: 'absolute',
        right: 0,
        top: '50%',
        //transform: 'translateY(-50%)',
    }
});

async function GetParam(param) {
    return await fetch('/Base/GetParam?param=' + param).then(res => res.json());
}

class CatalogueItem extends Component {
    constructor(props) {
        super(props);

        this.btnPres = React.createRef();
        this.btnCart = React.createRef();
        this.grdVariationsBOP = React.createRef();
		
		this.state = {
			HoverStar: undefined,
			btnPresWidth: 100,
			btnCartWidth: 100,
			discount: (props.Discount||0),
			totalPrice: (props.Price||0),
			documents: [],
			IsReserved: false,
			IsBooked: false,
			IsReservedOther: false,
			IsBookedOther: false,
			dialog: {},
			qty: 0,
			relateds: [],
			parures: [],
			relatedPage: 0,
			parurePage: 0,
			productInfoView: 'historyid',
			cardRotated: false,
			resizeGridVariations: 0,
			isMounted: false,
			isLoading: true,
			isFirstLoad: true
		}
    }
    static contextTypes = {
        router: PropTypes.object
    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }

    componentDidMount() {
        if (this.btnPres.current) {
            this.setState({ btnPresWidth: this.btnPres.current.clientWidth });
        }
        if (this.btnCart.current) {
            this.setState({ btnCartWidth: this.btnCart.current.clientWidth });
        }
        this.setState({ data: this.props.data, isMounted: true }, () => {
            this.loadData();
            //this.getItemStatus();
            this.getItemHistory();
            this.getItemRelateds();
            this.setTracking();
        });
    }
	//componentDidUpdate = (prevProps, prevState) => {
	//	if (this.state.isMounted && prevProps !== this.props && prevState !== this.state) {
	//		this.setState({ data: this.props.data }, async() => {
	//			await this.reloadData(this.state.data.ID);
	//			await this.loadData();
	//		});
	//	}
	//}
    loadData = async () => {
        var { showModels, showStock, FIDIRIdentity, FIDIRPricelist, CatalogFIDPricelist } = this.props;
        var { data, isFirstLoad, variations, selectedVariants } = this.state;
		
		if (!showModels && !showStock) {
			if (data.Barcode) {
				showStock = true;
				showModels = false;
			} else {
				showStock = false;
				showModels = true;
			}
		}
		
		var FIDPricelist = (CatalogFIDPricelist||FIDIRPricelist);
		
		//if (this.props.groupType === 4) {
			var bopData = JSON.parse(await fetch('/BOP/GetBOPCodeAndDescription/?ID='+data.ID).then(res => res.json()));
			if (bopData) {
				data.Code = bopData.code;
				data.Description = bopData.desc;
				data.ExtendedDescription = bopData.extdesc;
			}
		//}
		
		if (showStock && data.ID) {
			await fetch('/BOP/GetBOPSerialNumber/?ID='+data.ID+'&code='+data.Code).then(res => res.json())
				.then(serials => {
					data.SerialNumber = serials.SerialNumber;
					data.Barcode = serials.Barcode;
				});
        }
		
		if (data && data.ID && !data.ComponentsString) {
			await fetch('/BOP/GetBOPComponentsString/?ID='+data.ID).then(res => res.json())
				.then(compString => { data.ComponentsString = compString; });
		}
		
		if (!data.SupplierCode || !data.SupplierLabel || !data.SupplierCatalogCode) {
			await Promise.all([
				fetch('/BOP/GetBOPSupplierLabel/?ID='+data.ID+'&code='+data.Code),
				fetch('/BOP/GetBOPSupplierCodes/?ID='+data.ID+'&code='+data.Code),
			]).then(([label, codes]) => {
				return Promise.all([label.json(), codes.json()]) 
			}).then(([label, codes]) => {
				data.SupplierLabel = label;
				data.SupplierCatalogCode = codes.CatalogCode;
				data.SupplierCode = codes.Code;
			});
		}
		
        if (data && data.IsVariation) {
			var queryString = "";
			
			if (data.Barcode) {
				queryString += "&Barcode=" + data.Barcode;
			}
			
			if (FIDPricelist) {
				queryString += "&FIDPricelist=" + FIDPricelist; 
			} else if (FIDIRIdentity) {
				queryString += "&FIDIdentity=" + FIDIRIdentity;
			}
			
			if (!isFirstLoad && variations.length > 0) {
				this.setState({isLoading: true});
			}
			
            var result = await fetch(`/BOP/GetVariationByCatalogCode/${data.ID}?CatalogCode=${data.CatalogCode}&showModels=${showModels}&showStock=${showStock}` + queryString).then(res => res.json());
			
			let selectedExtras = [];
			
			if (!selectedVariants || selectedVariants.length === 0 || result.selectedVariants.length !== selectedVariants.length)
				selectedVariants = result.selectedVariants;
			else {
				result.extras.filter(e => !e.IsPassive).forEach((extra) => {
					let selected = selectedVariants.find(e => e.FIDExtra === extra.ID);
					let activeExtraRes = selected ? result.selectedVariants.find(e => e.FIDExtra === extra.ID && e.FIDValue === selected.FIDValue) : result.selectedVariants.find(e => e.FIDExtra === extra.ID);
					
					if (!activeExtraRes) {
						activeExtraRes = result.selectedVariants.find(e => e.FIDExtra === extra.ID);
						if (!selected) {
							selected = activeExtraRes;
						}
						else if (activeExtraRes && selected.FIDValue !== activeExtraRes.FIDValue) {
							selected.FIDValue = activeExtraRes.FIDValue;
							selected.Value = activeExtraRes.Value;
						}
					}
				});
			}
			

			if (result.extras.length > 0) {
				result.extras.forEach((extra) => {
					var currItem = result.selectedVariants.find(v => v.FIDExtra === extra.ID);
					if (currItem && currItem.Value) {
						selectedExtras.push( { FIDExtra: extra.ID, FIDValue: currItem.FIDValue, Value: currItem.Value } );
					}
				});
			}
			
			
			
            this.setState({ data, variations: result.variations, extras: result.extras, bopAvailVariants: result.bopAvailVariants, selectedVariants: selectedVariants, selectedExtras: selectedExtras, bopDefaultVariant: result.bopDefaultVariant, bopg: result.bopg, isLoading: false, isFirstLoad: false }, this.getItemStatus); 
        } else {
			this.setState({ data }, this.getItemStatus);
        }
    }
    reloadData = async (ID, callback) => {
        if (ID > 0) {
            var { CatalogFIDIdentity, CatalogFIDPricelist, FIDIRPricelist, FIDIRIdentity } = this.props;
			
			var FIDPricelist = CatalogFIDPricelist||null;
			var FIDIdentity = FIDIRIdentity || CatalogFIDIdentity;
			
			if (!FIDPricelist && this.props.params && this.props.FIDProfile !== (this.props.params.CustomerTypologyId||2) && !FIDIdentity)
				FIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;
			
            var result1 = await fetch(`/BOP/GetCatalogueData/${ID}?FIDIdentity=${FIDIdentity}&FIDPricelist=${FIDPricelist}&showB2BPrice=${true}`).then(res => res.json());
            this.setState({ isLoading: false, data: result1, relatedPage: 0/*, relateds:[]*/ }, () => {
                if (callback) {
                    callback();
                }
                this.getItemStatus();
                this.getItemHistory();
                this.getItemRelateds();
            });
        }
    }
    
    setTracking = () => {
        var { data } = this.state;
        if(data){
            fetch('/BOP/CatalogueTracking/' + data.ID);
        }
    }
    getItemStatus = async () => {
        var { data, selectedExtras, extras } = this.state;
		var variantsJson = JSON.stringify(selectedExtras);
        var { FIDIRIdentity, FIDDocument, isCatalog, groupType } = this.props;
        if(data){
            
            var result = await fetch(`/IR/BOPStatus/${data.ID}?Code=${data.Code}&FIDIdentity=${FIDIRIdentity}&FIDDocument=${FIDDocument}&GroupType=${groupType}&jsonExtras=${variantsJson}`).then(res => res.json());
            this.setState(state => {
                state.qty = 0;
                state.discount = 0;
                state.IsBooked = result.IsBooked;
                state.IsReserved = result.IsReserved;
                state.IsBookedOther = result.IsBookedOther;
                state.IsReservedOther = result.IsReservedOther;
                state.documents = result.documents;
                state.stock = result.stock;
                var FIDCausal = this.props.params.DOCCartCausalId;
                if (isCatalog) {
                    FIDCausal = this.props.CatalogFIDCausal;
                }
                if(data.PriceB2B){
                    state.discount = (data.Price - data.PriceB2B) / data.Price * 100;
                }
                var cart = state.documents.filter(f => f.FIDCausal === FIDCausal)[0];
                if (cart) {
                    if (cart.Discount && cart.Discount > 0) {
                        state.discount = cart.Discount;
                        state.showPrices = false;
                    }
                    state.qty = cart.Qty;
                }
                if(!isCatalog && state.documents.length > 0){
                    var cart = state.documents[0]; 
                    if (cart.Discount && cart.Discount > 0) {
                        state.discount = cart.Discount;
                        state.showPrices = false;
                    }
                }
                state.totalPrice = (data.Price * (100 - state.discount) / 100).round(2);
                //if(data.PriceB2B){
                //    state.totalPrice = data.PriceB2B;
                //}
                if (result.documents && result.documents.length > 0) {
                    state.internalRemark = result.documents[0].InternalRemarks;
                    state.publicRemark = result.documents[0].PublicRemarks;
                }
                return state;
            });
        }
    }
    getItemHistory = async () => {
        // if (this.props.showHistory) {
        //     var { data } = this.state;
        //     var { FIDIRIdentity } = this.props;
        //     if(data) {
        //         var result = await fetch('/DOC/GetBOPHistory/' + data.ID + '?FIDIdentity=' + FIDIRIdentity).then(res => res.json());
        //         this.setState({ history: result });
        //     }
        // }
    }
    getItemRelateds = async () => {
        var { data } = this.state;
        if(data){
            var urlRelated = '/BOP/GetCatalogueItemRelateds/' + data.ID;
            var urlParures = '/BOP/GetCatalogueItemParures/' + data.ID;

            //var result = await fetch(url).then(res => res.json());
            await Promise.all([fetch(urlRelated), fetch(urlParures)]).then(([dataRelateds, dataParures]) => { 
                return Promise.all([dataRelateds.json(), dataParures.json()]) 
            }).then(([dataRelateds, dataParures]) => {
                this.setState({
                    relateds: dataRelateds.data,
                    parures: dataParures.data
                });
            });
        }
    }

    onItemEditClick = (data) => {
        var { onEditClick } = this.props;
        if (onEditClick) {
            onEditClick(data);
        }
    }
    StarEnter = (star) => {
        this.setState({ HoverStar: star });
    }
    StarExit = (star) => {
        setTimeout(() => {
            this.setState(state => {
                if (state.HoverStar === star) {
                    state.HoverStar = undefined;
                }
                return state;
            });
        }, 100);
    }
    StarItem = (star) => {
        var { onStarItem } = this.props;
        var { data, internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;
        if (onStarItem) {
            onStarItem(data, star);
        }
    }
    doBuy = () => {
        var { onBuyItem } = this.props;
        var { data, discount, totalPrice } = this.state;
        var { internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;
        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = data.Price;
        }
        data.Discount = discount;
        data.TotalPrice = totalPrice;
        if (onBuyItem) {
            onBuyItem(data);
        }

    }
    BuyItem = () => {
        var { IsReservedOther, IsBookedOther } = this.state;

        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        this.doBuy();
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato " + (IsReservedOther ? "riservato" : "prenotato") + " da un'altro cliente. Procedere con l'operazione?");
        }
        else {
            this.doBuy();
        }
    }
    NoticeItem = () => {
        var { onNoticeItem } = this.props;
        var { data, internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;
        if (onNoticeItem) {
            onNoticeItem(data);
        }
    }
    ReserveItem = () => {
        var { onReserveItem } = this.props;
        var { data, IsReservedOther, IsBookedOther } = this.state;
        var { internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;

        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        if (onReserveItem) {
                            onReserveItem(data);
                        }
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato "+ (IsReservedOther ? "riservato" : "prenotato") +" da un'altro cliente. Procedere con l'operazione?");
        }
        else {

            if (onReserveItem) {
                onReserveItem(data);
            }
        }

    }
    BookItem = () => {
        var { onBookItem } = this.props;
        var { data, IsReservedOther, IsBookedOther } = this.state;
        var { internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;


        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        if (onBookItem) {
                            onBookItem(data);
                        }
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato " + (IsReservedOther ? "riservato" : "prenotato") + " da un'altro cliente. Procedere con l'operazione?");
        }
        else {

            if (onBookItem) {
                onBookItem(data);
            }
        }
    }
    showShare = () => {
        var buttons = [
            {
                label: "OK",
                action: () => {
                    this.setState({ dialog: {} });
                }
            },
        ];

        this.showDialog(buttons, "Condivisione", "Funzionalità non configurata");
    }
    showRemarks = () => {
        var { FIDIRIdentity, onNoteItem, onShowRemarks } = this.props;
        var { data, documents } = this.state
        //if (!FIDIRIdentity || (!documents || documents.length === 0)) {
        //    if (onNoteItem) {
        //        onNoteItem(data, onShowRemarks);
        //
        //    }
        //}
        //else {
        if (onShowRemarks) {
            onShowRemarks();
        }
        //}
        
    }
    RemoveItem = (FIDCausal) => () => {
        var { onRemoveItem } = this.props;
        var { data } = this.state;
        if (onRemoveItem) {
            onRemoveItem(data, FIDCausal,this.getItemStatus);
        }
    }
    showPrices = () => {
        this.setState({ showPrices: true });
    }
    discountChange = (id) => (event, value) => {
        this.setState((state, props) => {

            state.discount = value;
            state.totalPrice = (state.data.Price * (100 - state.discount) / 100).round(2);
            return state;
        });
    }
    remarksChange = (id) => (event, value) => {
        this.setState((state, props) => {

            state[id] = value;
            return state;
        });
    }
    priceChange = (id) => (event, value) => {
        this.setState((state, props) => {
            state.totalPrice = value;
            state.discount = (100 - (state.totalPrice * 100 / state.data.Price)).round(4);
            return state;
        });
    }
    renderEditPrice = (discount, totalPrice) => {
        var { classes } = this.props;
        return (
            <Fragment>
                {this.state.showPrices && <span className={classes.popupBack}></span>}
                {this.state.showPrices &&
                    <div className={classes.popup}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}><XField label="Sc. %" id="discount" value={discount} onChange={this.discountChange} /></Grid>
                            <Grid item xs={12}><XField label="Totale" id="totalPrice" type="number" value={totalPrice} onChange={this.priceChange} /></Grid>
                            <Fragment>
                                <IconButton onClick={() => this.setState({ showPrices: false }, async() => {
									if (!this.props.isCatalog &&this.state.documents && this.state.documents.length > 0) {
										var doc = this.props.data.FIDCausal ? this.state.documents.filter(d => d.FIDCausal === this.props.data.FIDCausal) : undefined;
										
										if (!doc) {
											doc = this.state.documents[0];
										}
										var bopg = this.props.data;
										var { data } = this.state;
										
										var document = {
											FIDBOP: bopg.ID,
											Price: data.Price,
											Discount: data.Discount,
											TotalPrice: data.TotalPrice,
											FIDCausal: this.props.data.FIDCausal||doc.FIDCausal,
											Code: data.Code,
											VariantUnavailable: this.state.variantUnavailable,
											VariantExtras: this.state.selectedVariants,
											InternalRemark: this.state.internalRemark,
											PublicRemark: this.state.publicRemark,
											FIDPricelist: this.props.CatalogFIDPricelist,
											IsTaxable: !bopg.PriceVatIncluded
										};
								
										var rowData = {
											Values: [ { ID: data.ID, Qty: (doc.Qty||data.Qty||this.state.qty) } ],
											Document: document,
										};
										
										var url = `/IR/AddPresentationItem/?FIDIdentity=${this.props.FIDIRIdentity}&updateRow=${true}`;
										
										if (this.props.FIDDocument) {
											url += '&FIDDocument='+ this.props.FIDDocument;
										}
								
								
										await fetch(url,{
											body: JSON.stringify(rowData),
											method: 'POST'
										}).then(res => res.json());
									}
								})}>
                                    <Check />
                                </IconButton>
                                <IconButton onClick={() => this.setState({ showPrices: false, discount: 0, totalPrice: this.state.data.Price }, async() => 
								{
									if (!this.props.isCatalog && this.state.documents && this.state.documents.length > 0) {
										var doc = this.state.documents.filter(d => d.FIDCausal === this.props.data.FIDCausal);
										
										if (!doc)
											doc = this.state.documents[0];
										var bopg = this.props.data;
										var { data } = this.state;
										
										var document = {
											FIDBOP: bopg.ID,
											Price: data.Price,
											Discount: data.Discount,
											TotalPrice: data.TotalPrice,
											FIDCausal: this.props.data.FIDCausal||doc.FIDCausal,
											Code: data.Code,
											VariantUnavailable: this.state.variantUnavailable,
											VariantExtras: this.state.selectedVariants,
											InternalRemark: this.state.internalRemark,
											PublicRemark: this.state.publicRemark,
											FIDPricelist: this.props.CatalogFIDPricelist,
											IsTaxable: !bopg.PriceVatIncluded
										};
								
										var rowData = {
											Values: [ { ID: data.ID, Qty: (doc.Qty||data.Qty1||this.state.qty) } ],
											Document: document,
										};
										
										var url = `/IR/AddPresentationItem/?FIDIdentity=${this.props.FIDIRIdentity}&updateRow=${true}`;
										
										if (this.props.FIDDocument) {
											url += '&FIDDocument='+ this.props.FIDDocument;
										}
								
								
										await fetch(url,{
											body: JSON.stringify(rowData),
											method: 'POST'
										}).then(res => res.json());
									}
								})}>
                                    <CloseIcon />
                                </IconButton>
                            </Fragment>
                        </Grid>
                    </div>}
            </Fragment>
        );
    }
    handleSaveRemarks = () => {
        var { FIDIRIdentity, onNoteItem, onHideRemarks, isCatalog } = this.props;
        var { data, documents, internalRemark, publicRemark } = this.state;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;

        if (FIDIRIdentity) {
            if (documents && documents.length > 0) {
                var docIds = documents.map(d => d.ID);

                var dataUpdate = {
                    documents: docIds,
                    internalRemark,
                    publicRemark
                };

                fetch('/IR/UpdateRemarks', {
                    method: "POST",
                    body: JSON.stringify(dataUpdate)
                }).then(res => {

                });
            }
        }
        else {
            if (!isCatalog && onNoteItem) {
                onNoteItem(data);
            }
        }
        if (onHideRemarks) {
            onHideRemarks();
        }
    }
    renderEditRemarks = () => {
        var { classes } = this.props;
        var { internalRemark, publicRemark } = this.state;
        return (
            <Fragment>
                {this.props.showRemarks && <span className={classes.popupBack}></span>}
                {this.props.showRemarks &&
                    <div className={classes.popup}>
                        <Grid container item xs={12}>
                            {this.props.params.CatalogueShowInternalRemark && <Grid item xs={12}><XField label={getTranslation(3,"BOP_INTERNALREMARK", this.props.translations, "Nota interna")} multiline={true} id="internalRemark" value={internalRemark} onChange={this.remarksChange} /></Grid>}
                            {this.props.params.CatalogueShowPublicRemark && <Grid item xs={12}><XField label={getTranslation(3,"BOP_PUBLICREMARK", this.props.translations, "Nota pubblica")} multiline={true} id="publicRemark" value={publicRemark} onChange={this.remarksChange} /></Grid>}
                            <Fragment>
                                <IconButton onClick={this.handleSaveRemarks}>
                                    <Check />
                                </IconButton>
                                <IconButton onClick={() => this.setState({ showRemarks: false, internalRemark: undefined, publicRemark: undefined }, this.handleSaveRemarks)}>
                                    <CloseIcon />
                                </IconButton>

                            </Fragment>
                        </Grid>
                    </div>}
            </Fragment>
        );
    }
    onItemClose = () => {
        var { onClose } = this.props;
        if (onClose) {
            onClose();
        }
    }
    render() {
        var { data, HoverStar, showPrices, discount, totalPrice, documents,cardRotated, bopVariantUnavailable, isFirstLoad, isLoading } = this.state;
        var { classes, onClose, open, width, isCatalog } = this.props;
		
        if (!data) {
            return (<div></div>);
        }

        var isPresentation = documents.filter(i => i.FIDCausal === this.props.params.DOCPresentationCausalId).length > 0;
        var isCart = documents.filter(i => i.FIDCausal === this.props.params.DOCCartCausalId).length > 0;
        var isNotice = documents.filter(i => i.FIDCausal === this.props.params.DOCNoticeCausalId).length > 0;
        var isReservation = documents.filter(i => i.FIDCausal === this.props.params.DOCReservationCausalId).length > 0;
        var isBooking = documents.filter(i => i.FIDCausal === this.props.params.DOCBookingCausalId).length > 0;

        if(typeof discount === "string"){
            try {
                discount = parseFloat(discount);
				
				if (isNaN(discount))
					discount = "";
            }
            catch{
                discount = 0;
            }
        }
        if (discount === 0) {
            totalPrice = data.Price;
        }

        var subTitle = "";
        if (data.Brand) {
            subTitle = data.Brand;
        }
        if (data.Line) {
            if (subTitle !== "") {
                subTitle += " - ";
            }
            subTitle += data.Line
        }
        var price = "";
        var priceB2B = undefined;
        if (data.Price) {
            price = format(data.Price,data.PriceCurrency); //data.PriceCurrency + " " + numeral(data.Price).format('0,0.00');
        }
        if(data.PriceB2B){
            priceB2B = format(data.PriceB2B,data.PriceCurrencyB2B);
        }
        if(data.SupplierLabel && discount === 0){
            price = data.SupplierLabel;
        }
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        //var files = data.Files ? data.Files.split(',') : [];
        //var actionAreaStyle = {
        //    width: "100%"
        //};
        if (!open) {
            return (<div></div>);
        }
        var color = grayColor;
        var showStars = false;
        //var cartButton = <XButton color="primary" size="small" onClick={this.BuyItem} buttonRef={this.btnCart}>Aggiungi al carrello</XButton>;//<Cart color="primary" style={{ cursor: 'pointer' }} onClick={this.BuyItem} />;
        if (isPresentation) {
            if (!HoverStar) {
                HoverStar = documents.filter(i => i.FIDCausal === this.props.params.DOCPresentationCausalId)[0].Stars;
            }
            showStars = true;
        }
        else if (isCart) {
            //showStars = false;
            //cartButton = <XButton color="primary" size="small" onClick={this.RemoveItem} buttonRef={this.btnCart}>Rimuovi dal carrello</XButton>;//<CartOff color="primary" style={{ cursor: 'pointer' }} onClick={this.RemoveItem} />;
        }


        switch (HoverStar) {
            case 1:
                color = "#E74C3C";
                break;
            case 2:
                color = "#F39C12";
                break;
            case 3:
                color = "#F7DC6F";
                break;
            case 4:
                color = "#82E0AA";
                break;
            case 5:
                color = "#27AE60";
                break;
            default:
                break;
        }
        //var header = (<CardHeader
        //    className={classes.header}
        //    //title={data.Code}
        //    //subheader={subTitle}badgeSmall
        //    action={
        //        <Fragment>
        //            <IconButton onClick={() => { this.onItemEditClick(data) }}>
        //                <Pencil />
        //            </IconButton>
        //            <IconButton onClick={onClose}>
        //                <CloseIcon />
        //            </IconButton>
        //        </Fragment>
        //    }
        ///>);
        var irTypesDiscChangeDisabled = [ (this.props.params.CustomerTypologyId||2),(this.props.params.AgentTypologyId||8) ];
        var flipCardHeight = 430;
        var varCount = this.countVariations();
        var hasDiscount = discount > 0;
        if(width === "xs"){
            flipCardHeight = 230 + (varCount *90);
        }
        else if(width === "sm"){
            flipCardHeight = 440 + (varCount *90) + (hasDiscount ? 80 : 20) + (this.state.stock ? 31 : 0);
        }
        else {
            flipCardHeight += ((varCount + (varCount % 2)) / 2 * 90) + /*(hasDiscount ? 100 : 20)100*/ (this.state.stock ? 31 : 0);
        }

        return (
			<Fragment>{!isFirstLoad && isLoading && <XOXLoader style={{ position: "absolute", width: "100%", height: "70% !important", zIndex: 1000 }} transparent />}
            <Card className={classes.card}>

                {!irTypesDiscChangeDisabled.includes(this.props.FIDProfile) && this.renderEditPrice(discount, totalPrice)}
                {this.renderEditRemarks()}
                <CardContent className={classes.cardContent}>
                    {this.props.params.ShowCatalogueActions && (

                        <div className={classes.leftButton} >
                                                    
                            {/* <IconButton onClick={this.showShare}>
                                <ShareVariant />
                            </IconButton> */}
                            {!this.props.params.CatalogueHideFlipCard && (<IconButton onClick={this.showCustomFields}>
                                <CardBulletedOutline />
                            </IconButton>)}
                            {!this.props.hideRemarks && <IconButton onClick={this.showRemarks}>
                                {(this.state.internalRemark || this.state.publicRemark) ?
                                    <Badge className={classes.badgeSmall} badgeContent="" color="primary">
                                        <MessageBulleted />
                                    </Badge>
                                    : <MessageBulleted />
                                }
                            </IconButton>}
                        </div>
                    )}
                    <div className={classes.closeButton} >
                        {this.props.onEditClick && <IconButton onClick={() => { this.onItemEditClick(data) }}>
                            <Pencil />
                        </IconButton>}
                        <IconButton onClick={this.onItemClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Grid container spacing={16}>
                        <Grid container xs={12}>
                            <Grid container xs={12} md={6} className={classes.centerText} alignItems="flex-start" justify="center">
                                <div style={{ width: "100%", paddingRight: 16}}>
                                    <Grid item xs={12} className={classes.brandTitle}>
                                        <object className={classes.brandLogo} data={"/BOP/BrandDefaultImage/" + data.FIDBrand} type="image/jpg">
                                            {data.Brand}
                                        </object>
                                    </Grid>
                                    <Grid item xs={12} className={classes.codeTitle}>
                                        <div>Ref. {this.props.params.CatalogueCodeProp ? data[this.props.params.CatalogueCodeProp] : data.Code}</div>
											{(data.SupplierCode || data.SupplierCatalogCode) && <div className={classes.serialNumber}>Ref. fornitore: {data.SupplierCode || data.SupplierCatalogCode}</div>}
                                        <div className={classes.serialNumber}>{data.SerialNumber}</div>										
                                    </Grid>
									{!this.props.params.CatalogueHideLine && data.Line && (<Fragment><Grid item xs={12} className={classes.attrLabel}>{getTranslation(3,"BOP_LINE",this.props.translations, this.props.labels.line)}</Grid> <Grid item xs={12}>{data.Line}</Grid></Fragment>)}
                                            {!this.props.params.CatalogueHideSeries && data.Series && (<Fragment><Grid item xs={12} className={classes.attrLabel}>{getTranslation(3,"BOP_SERIES",this.props.translations, this.props.labels.series)}</Grid> <Grid item xs={12}>{data.Series}</Grid></Fragment>)}
                                    <FlipCard rotated={cardRotated} height={flipCardHeight}>
                                        <Front>
                                            <Grid item xs={12} className={classes.descriptionTitle}>
                                                {data.ExtendedDescription || data.Description}                                               
                                            </Grid>
											{!this.props.params.CatalogueHideComponentsString && data.ComponentsString && 
												<Grid item xs={12} style={{ marginBottom: 30 }}>{data.ComponentsString}</Grid>
											}
                                            {
                                                //IsReserved && <div class={classes.ribbon}><span>RISERVATO</span></div>
                                            }
                                            {
                                                //IsBooked && <div class={classes.ribbon}><span>PRENOTATO</span></div>
                                            }

                                            {/*!this.props.params.CatalogueHideBrand && data.Brand && this.props.labels.brand && (<Fragment><Grid item xs={12} className={classes.attrLabel}>{getTranslation(3,"BOP_BRAND",this.props.translations, this.props.labels.brand)}</Grid> <Grid item xs={12}>{data.Brand}</Grid></Fragment>)*/}
                                            
                                            {this.renderVariations()}
                                            {width !== "xs" && 
                                                this.renderPriceButtons(classes, price, showPrices, discount, totalPrice, isPresentation, isNotice, isReservation, isBooking, isCart, showStars, HoverStar, color, documents, priceB2B, bopVariantUnavailable)
                                            }
                                            {this.state.stock && this.state.stock.map((item,index) => {
                                            return <p key={index}>{`${item.Qty1} ${item.UM} presso ${item.Position}`}</p>
                                            })}
                                        </Front>
                                        <Back>
                                            {this.renderProductCustomFields(flipCardHeight)}
                                        </Back>
                                    </FlipCard>
                                    
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <XGallery key={data.ID} imageIds={files} noZoom={false} />
								{files && files.length > 0 && <Grid item xs={12} style={{ fontSize:12, textAlign: "center", marginTop:20 }}>{getTranslation(1,"CAT_REPRESENTATIVE_IMGS_NOTICE",this.props.translations, "Le immagini dei prodotti presentati sono puramente indicative e hanno il solo scopo di rappresentare l'articolo")}</Grid>}
                                {/* <div style={{marginTop: 30}}>{this.renderVariations()}</div> */}
                            </Grid>
                            {width === "xs" &&
                                this.renderPriceButtons(classes, price, showPrices, discount, totalPrice, isPresentation, isNotice, isReservation, isBooking, isCart, showStars, HoverStar, color, documents, priceB2B, bopVariantUnavailable)
                            }
                            {/*this.renderComponents()*/}
                            {!this.props.params.HideVariationsGrid && this.renderVariationsGrid()}
                            {this.renderParures()}
                            {this.renderRelateds()}
                            {/* CARATTERISTICHE E STORICO */}
                            {this.renderProductInfo()}
                            {/* FINE CARATTERISTICHE E STORICO */}
                        </Grid>
                    </Grid>
                </CardContent>

                <XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
            </Card></Fragment>
        );
    }
    showCustomFields = () => this.setState(state => {
        state.cardRotated = !state.cardRotated;
        return state;
    });
    showHistoryID = () => this.setState({productInfoView: 'historyid'});
    showHistoryCode = () => this.setState({productInfoView: 'historycode'});
    showHistoryCatalogCode = () => this.setState({productInfoView: 'historycatalogcode'});
    onDoubleClickProductInfoDoc = (_,data) => {
        this.context.router.history.push('/doc/edit/' + data.ID);
    }
    renderProductCustomFields = (flipCardHeight) => {
        var { data } = this.state;
        var { FIDIRIdentity } = this.props;
        return (
            <CatalogueItemFlipContent data={data} flipCardHeight={flipCardHeight} />
            
        );
    }
    renderProductInfo = () =>{
        if (!this.props.showHistory) {
            return false;
        }


        var { data,productInfoView } = this.state;
        var { width,FIDIRIdentity } = this.props;

        return <BOPHistory isStock={!!data.Barcode} data={data} width={width} FIDIRIdentity={FIDIRIdentity} />

        var actions = [];
        // if(!productInfoView){
        //     productInfoView = "historyid";
        // }
        
        //actions.push({ name: "Campi custom", icon: <CardBulletedOutline />, action: this.showCustomFields, selected: productInfoView === "customfields" });
        actions.push({ name: "Storico su matricola", icon: <TextBoxOutline />, action: this.showHistoryID, selected: productInfoView === "historyid" });
        actions.push({ name: "Storico su codice", icon: <TextBoxMultipleOutline />, action: this.showHistoryCode, selected: productInfoView === "historycode" });
        actions.push({ name: "Storico su codice catalogo", icon: <FileDocumentBoxMultipleVarOutline />, action: this.showHistoryCatalogCode, selected: productInfoView === "historycatalogcode" });
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var label = <span><XNavigation actions={actions} direction={direction} showLabel={true} /></span>;

        var key = "tblBOPHistoryID";
        var gridName= 'tblBOPHistory1';
        var url = `/DOC/GetBOPHistory/${data.ID}/?grid=${gridName}&FIDIdentity=${FIDIRIdentity}`;
        var onDoubleClickEdit = this.onDoubleClickProductInfoDoc;

        switch(productInfoView){
            case "historycode":
                key = "tblBOPHistoryCode";
                gridName= 'tblBOPHistory2';
                url = `/DOC/GetBOPHistory/${data.ID}/?grid=${gridName}&FIDIdentity=${FIDIRIdentity}&filterID=false`;
                onDoubleClickEdit = this.onDoubleClickProductInfoDoc;
                break;
            case "historycatalogcode":
                key = "tblBOPHistoryCatalogCode";
                gridName= 'tblBOPHistory3';
                url = `/DOC/GetBOPHistory/${data.ID}/?grid=${gridName}&FIDIdentity=${FIDIRIdentity}&filterID=false&filterCode=false`;
                onDoubleClickEdit = this.onDoubleClickProductInfoDoc;
                break;
        }

        return (
            <div style={{textAlign: 'initial', width: '100%', marginTop: 10}} id="gridProductInfoContainer" >
                <XGrid 
                    key={key}
                    label={label}
                    containerID="gridProductInfoContainer"
                    dataRoute={url}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    rowsVisible={-1}
                    onDoubleClick={onDoubleClickEdit}
                    onEditButton={onDoubleClickEdit}
					showImgColumn={true}
                    //cardWidth={4}
                    //cardHeight={4.5}
                    //showChangeView={true}
                    //width={this.props.width}
                />
            </div>
        );
    }
    renderHistory = () => {
        if (!this.props.showHistory) {
            return false;
        }

        var { history } = this.state;
        return <div>
            {history && history.map((item, index) => (<div key={index}><span>{item.DocumentDate}</span><span>{item.Pricelist}</span><span>{item.Discount}</span><span>{item.SellPrice}</span></div>))}
        </div>;
    }
    onVariationBOPClick = (event, item) => {	
		this.setState({ isLoading: true }, () => {
			smoothScroll(0, "mainContent");
			this.reloadData(item.ID, () => {			
				this.grdVariationsBOP && this.grdVariationsBOP.current && this.grdVariationsBOP.current.getWrappedInstance() && this.grdVariationsBOP.current.getWrappedInstance().refresh();				
			});
		});
    }
    onRelatedClick = (item) => async () => {
        var result1 = await fetch('/BOP/GetCatalogueData/' + item.ID).then(res => res.json());
        this.setState({ data: result1, relatedPage: 0, parurePage: 0,/*, relateds:[] */ }, () => {
            this.loadData();
            this.getItemStatus();
            this.getItemHistory();
            this.getItemRelateds();
        });
    }
    onRelatedLeft = () => {
        this.setState(state => {
            var items = 4;
            switch (this.props.width) {
                case "md":
                    items = 3;
                    break;
                case "sm":
                    items = 2;
                    break;
                case "xs":
                    items = 2;
                    break;
            }
            if (state.relatedPage >= Math.ceil(state.relateds.length / items)) {
                state.relatedPage = Math.ceil(state.relateds.length / items) - 1;
            }
            state.relatedPage--;
            if (state.relatedPage < 0) {
                state.relatedPage = 0;
            }
            return state;
        });
    }
    onRelatedRight= () => {
        this.setState(state => {
            state.relatedPage++;

            var items = 4;
            switch (this.props.width) {
                case "md":
                    items = 3;
                    break;
                case "sm":
                    items = 2;
                    break;
                case "xs":
                    items = 2;
                    break;
            }
            if (state.relatedPage >= Math.ceil(state.relateds.length / items)) {
                state.relatedPage = Math.ceil(state.relateds.length / items) - 1;
            }
            return state;
        });
    }
    onParureLeft = () => {
        this.setState(state => {
            var items = 4;
            switch (this.props.width) {
                case "md":
                    items = 3;
                    break;
                case "sm":
                    items = 2;
                    break;
                case "xs":
                    items = 2;
                    break;
            }
            if (state.parurePage >= Math.ceil(state.parures.length / items)) {
                state.parurePage = Math.ceil(state.parures.length / items) - 1;
            }
            state.parurePage--;
            if (state.parurePage < 0) {
                state.parurePage = 0;
            }
            return state;
        });
    }
    onParureRight= () => {
        this.setState(state => {
            state.parurePage++;

            var items = 4;
            switch (this.props.width) {
                case "md":
                    items = 3;
                    break;
                case "sm":
                    items = 2;
                    break;
                case "xs":
                    items = 2;
                    break;
            }
            if (state.parurePage >= Math.ceil(state.parures.length / items)) {
                state.parurePage = Math.ceil(state.parures.length / items) - 1;
            }
            return state;
        });
    }
    renderRelateds = () => {
        var { classes } = this.props;
        var { relateds, relatedPage } = this.state;
        if (!relateds || relateds.length === 0) {
            return false;
        }
        var md = 6;
        //if ((!variations || variations.length === 0 || !extras || extras.length === 0)) {
            md = 12;
        //}



        //var collection = (
        //    <Grid xs={12} container>
        //        {
        //            relateds.map((item, index) => {
        //                var key = item.ID;
        //                if (!key) {
        //                    key = index;
        //                }
        //                return (
        //                    <RelatedCard data={item} />
        //                );
        //            })
        //        }
        //    </Grid>
        //);

        var items = 4;
        switch (this.props.width) {
            case "md":
                items = 3;
                break;
            case "sm":
                items = 2;
                break;
            case "xs":
                items = 2;
                break;
        }
        //if (this.props.width === "xs") {
        //    items = 1;
        //}
        if (relatedPage >= Math.ceil(relateds.length / items)) {
            relatedPage = Math.ceil(relateds.length / items) - 1;
        }
        var lastPage = 0;
        var collection = (

            <Grid xs={12} spacing={2} container style={{ padding: '0 20px', minHeight: 151 }}>
                {

                    relateds.slice(relatedPage * items, (relatedPage * items) + items).map((item, index) => {
                        var key = item.ID;
                        if (!key) {
                            key = index;
                        }
                        return (
                            <Grid xs={6} sm={6} md={4} lg={3}>
                                <RelatedCard data={item} onItemClick={this.onRelatedClick(item)} />
                            </Grid>
                        );
                    })
                }
                {relateds.length > items &&
                    <Fragment>
                        {relatedPage > 0 && <IconButton className={classes.btnLeft} onClick={this.onRelatedLeft}><ChevronLeft /></IconButton>}
                        {relatedPage < (Math.ceil(relateds.length / items) - 1) && <IconButton className={classes.btnRight} onClick={this.onRelatedRight}><ChevronRight /></IconButton>}
                    </Fragment>
                }
            </Grid>
        );

        var panels = [
            {
                icon: "code",
                label: getTranslation(1,"CAT_RELATEDBOPS",this.props.translations, "Prodotti correlati"),
                content: collection
            }
        ];

        return <Grid style={{ margin: 0, padding: 8 }} container xs={12} md={md} spacing={2}><XExpansionPanel panels={panels} /></Grid>;
    }
    renderParures = () => {
        var { classes } = this.props;
        var { parures, parurePage } = this.state;
        if (!parures || parures.length === 0) {
            return false;
        }
        var md = 6;
        //if ((!variations || variations.length === 0 || !extras || extras.length === 0)) {
            md = 12;
        //}

        var items = 4;
        switch (this.props.width) {
            case "md":
                items = 3;
                break;
            case "sm":
                items = 2;
                break;
            case "xs":
                items = 2;
                break;
        }
        //if (this.props.width === "xs") {
        //    items = 1;
        //}
        if (parurePage >= Math.ceil(parures.length / items)) {
            parurePage = Math.ceil(parures.length / items) - 1;
        }
        var lastPage = 0;
        var collection = (

            <Grid xs={12} spacing={2} container style={{ padding: '0 20px', minHeight: 151 }}>
                {

                    parures.slice(parurePage * items, (parurePage * items) + items).map((item, index) => {
                        var key = item.ID;
                        if (!key) {
                            key = index;
                        }
                        return (
                            <Grid xs={6} sm={6} md={4} lg={3}>
                                <RelatedCard data={item} onItemClick={this.onRelatedClick(item)} />
                            </Grid>
                        );
                    })
                }
                {parures.length > items &&
                    <Fragment>
                        {parurePage > 0 && <IconButton className={classes.btnLeft} onClick={this.onParureLeft}><ChevronLeft /></IconButton>}
                        {parurePage < (Math.ceil(parures.length / items) - 1) && <IconButton className={classes.btnRight} onClick={this.onParureRight}><ChevronRight /></IconButton>}
                    </Fragment>
                }
            </Grid>
        );

        var panels = [
            {
                icon: "code",
                label: getTranslation(1,"CAT_PARUREBOPS",this.props.translations, "Parure"),
                content: collection
            }
        ];

        return <Grid style={{ margin: 0, padding: 8 }} container xs={12} md={md} spacing={2}><XExpansionPanel panels={panels} /></Grid>;
    }
    countVariations = () => {
        var {classes} = this.props;
        var { data, variations, extras, relateds } = this.state;
        if (!variations || variations.length === 0 || !extras || extras.length === 0) {
            return 0;
        }
        var count = 0;
        for(var i in extras){
            var item = extras[i];
            var values = variations.filter(f => f.FIDExtra === item.ID && !!f.Value).map(i => {
                return {
                    ID: i.Value,
                    Abbreviation: "",
                    Denomination: i.Value
                };
            }).map(i => JSON.stringify(i)).filter((value, index, self) => self.indexOf(value) === index).map(i => JSON.parse(i));
            if (!values || values.length <= 1) {
                continue;
            }
            count++;
        }

        return count;
    }
	renderComponents = () => {
		var { data } = this.state;
		var newClick = undefined;
        var customContent = undefined;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];

        var panels = [
            {
                icon: "code",
                label: getTranslation(1,"BOP_COMPONENTS",this.props.translations, "Componenti"),
                content:  (
					<div id="bopCompsContainer" style={{ height: "50vh", width: "100%", margin: "0 auto" }}>
						<XGrid
							containerID="bopCompsContainer"
							ref={this.grdComponents}
							dataRoute={"/BOP/GetBOPComponents/" + data.ID + "?grid=tblBOPComponents"}
							dataRouteColumns="/Base/GetColumns?grid=tblBOPComponents"
							dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblBOPComponents"
							dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblBOPComponents"
							onNewButton={newClick}
							customContent={customContent}
							showColumnsSelector={showColumnSelector}
							showFilterActivator={showFilterActivator}
							actions={customActions}
							showImgColumn={false}
						/>
					</div>
				)
			}
		];
		return <Grid style={{ margin: 0, padding: 8, marginTop: 30 }} container xs={12} md={12} spacing={2}><XExpansionPanel panels={panels} /></Grid>;
	}
	
    resizeVariationGrid = () => {
        var { resizeGridVariations } = this.state;
        resizeGridVariations++;

        this.setState({
            resizeGridVariations
        });
    }
    renderVariationsGrid = () => {
		var { FIDIRIdentity, CatalogFIDPricelist } = this.props;
        var { data } = this.state;

        if (!data || !data.ID || !data.IsVariation)
            return;
		
		var queryString = "";
			
		if (CatalogFIDPricelist) {
			queryString = "&FIDPricelist=" + CatalogFIDPricelist; 
		} else if (FIDIRIdentity) {
			queryString = "&FIDIdentity=" + FIDIRIdentity;
		}
        var ID = data.ID;
        var CatalogCode = data.CatalogCode;

        var height = "40vh";

        if (this.grdVariationsBOP.current && this.grdVariationsBOP.current.getWrappedInstance() && this.grdVariationsBOP.current.getWrappedInstance().state) {
            var gridViewType = this.grdVariationsBOP.current.getWrappedInstance().state.viewType;
            
            if (gridViewType === "list")
                height= "55vh";

            if (gridViewType === 'card')
                height = "70vh";
        }

        var panels = [
            {
                icon: "code",
                label: getTranslation(1,"CAT_VARIATIONS",this.props.translations, "Varianti associate"),
                content: (
                    <div id="bopVariationsContainer" style={{ height: height, width: "100%", margin: "0 auto" }}>
                    <XGrid 
                        containerID="bopVariationsContainer"
                        containerNoShadow
                        ref={this.grdVariationsBOP}
                        dataRoute={'/BOP/GetBOPVariations/'+ ID + '?catalogCode=' + CatalogCode + '&grid=grdBOPVariations' + queryString}
                        dataRouteColumns="/Base/GetColumns?grid=grdBOPVariations"
                        dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdBOPVariations"
                        dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdBOPVariations"
                        showColumnsSelector={true}
                        showFilterActivator={true}
                        showLoader
                        showChangeView
						showImgColumn
						defaultViewType="list"
                        onDoubleClick={this.onVariationBOPClick}
                        onViewChanged={this.resizeVariationGrid}
                        cardWidth={4}
                        cardHeight={4.5}
                        cols={2}
                        colsXl={2}
                        colsLg={2}
                        colsMd={1}
                        colsXs={1}
                    />
                    </div>
                )
            }
        ];

        return <Grid style={{ margin: 0, padding: 8, marginTop: 30 }} container xs={12} md={12} spacing={2}><XExpansionPanel panels={panels} /></Grid>;
    }
    renderVariations = () => {
        var {classes} = this.props;
        var { data, variations, extras, relateds, bopAvailVariants, selectedVariants, isFirstLoad, isLoading } = this.state;
        if (!variations || variations.length === 0 || !extras || extras.length === 0) {
            return false;
        }
        else {
            var allExtras = extras.map(item => {
                var values = variations.filter(f => f.FIDExtra === item.ID).map(i => {
                    return {
                        ID: i.FIDValue,
                        Abbreviation: "",
                        Denomination: i.Value
                    };
                }).map(i => JSON.stringify(i)).filter((value, index, self) => self.indexOf(value) === index).map(i => JSON.parse(i));
                if (!values || values.length <= 0) {
                    return false;
                }
                var readOnly = false;
				
				if (values.length > 1) {
					values.sort((a,b) => { return a.Denomination - b.Denomination });
				}
				
                if(values.length === 1 && variations.filter(f => f.FIDExtra === item.ID && !f.Value).length === 0){
                    readOnly = true;
                }
                var val = undefined;
                var currItem = selectedVariants.find(v => v.FIDExtra === item.ID && !!v.Value);
                if (currItem) {
                    val = currItem.FIDValue;
                }

                var ui = undefined;
                switch (item.VariationViewType) {
                    case 0:
                        ui = <XSelect
                            key={`${data.ID}_${item.ID}`}
                            //label={`${getTranslation(1,"CAT_SELECT",this.props.translations, "Seleziona")} ${item.Label}`.toUpperCase()}
                            label={`${item.Label}`.toUpperCase()}
                            id={item.ID}
                            customData={values}
                            value={val}
                            disabled={readOnly}
                            idKey="ID"
                            searchable={false}
                            valueKey="Denomination"
                            abbreviationKey="Abbreviation"
                            onChange={this.onVariationChange}
                        />;
                        break;
                    case 1:
                        ui = <div>
                            {//values.map
                            }
                        </div>;
                        break;
                    default:
                        break;
                }

                // var fieldmd = 3;
                // if (relateds && relateds.length > 0) {
                //     fieldmd = 6;
                // }
                var fieldmd = 6;

                return (
                    <Grid key={item.ID} item xs={12} md={fieldmd}>
                        {ui}
                    </Grid>
                )
            }).filter(f => f !== false);
            if (allExtras.length === 0) {

                return false;
            }
            // var ui = <Grid container xs={12} spacing={2} style={{ minHeight: 151 }}>
            //     {allExtras}
            // </Grid>;
            var ui = <Grid container xs={12} spacing={2} className={classes.marginTop}>
				{(isFirstLoad || isLoading) && <Grid item xs={12}><Typography style={{  fontSize: 11 }}>{getTranslation(1,"CAT_LOADING_VARIANTS", this.props.translations, "Elaborazione varianti in corso...")}</Typography></Grid>}
                {allExtras}
            </Grid>;
            var panels = [
                {
                    icon: "code",
                    label: "Varianti",
                    content: ui
                }
            ];
            var md = 12;
            var style = {};
            if (relateds && relateds.length > 0) {
                md = 6;
                style = {
                    margin: 0,
                    padding: 8,
                };
            }
            
			//if (this.state.isLoading)
			//	this.setState({ isFirstLoad: false, isLoading: false });
            //return <Grid style={style} container xs={12} md={md} spacing={2}><XExpansionPanel panels={panels} /></Grid>;
            return ui;
        }
    }
    onVariationChange = (key) => (event, value) => {
        if (value.length === 0) {
            return;
        }
		
        var { variations, data, extras, bopAvailVariants, selectedVariants, bopDefaultVariant, bopg, isLoading } = this.state;
		
		var changedExtra = extras.find(e => e.ID === key);
        var changedIndex = selectedVariants.findIndex(s => s.FIDExtra === key);
        var changed = selectedVariants.find(s => s.FIDExtra === key);
		var catVar = variations.find(v => v.FIDExtra === key && v.FIDValue === value[0].ID);
		
		if (changed) {
			changed.FIDValue = value[0].ID;
			changed.Value = value[0].Denomination;
		} else {
			changed = catVar;
			selectedVariants.push(changed);
		}

        //var unChanged = selectedVariants.filter(s => s.FIDExtra !== key);
		//var availBops = bopAvailVariants.filter(s => s.FIDExtra === key && s.Value === value[0].Denomination);
		
		var availCvIDs = variations.filter(s => s.FIDExtra === key && s.FIDValue === value[0].ID).map(b => b.FIDCatalogVariation);
		//selectedVariants = 
        //var extraCount = extras.length;
        //var extraCount = _(bopAvailVariants).groupBy("FIDExtra").map((item,FIDExtra) => FIDExtra).value().length;
        //
        //unChanged.forEach((item, index) => {
        //    availBops = availBops.concat(bopAvailVariants.filter(s => s.FIDExtra === item.FIDExtra && s.Value === item.Value));
        //});
		let cvSameExtrasMap = [];
		variations.filter(b => !!b.FIDCatalogVariation && (availCvIDs.includes(b.FIDCatalogVariation))).forEach(cvExtra => {
			if (cvExtra.FIDBOP != data.ID /*&& extras.length > 1*/) {
				if (cvSameExtrasMap.length === 0 || cvSameExtrasMap.filter(s => s.FIDCatalogVariation === cvExtra.FIDCatalogVariation).length === 0)
				{
					cvSameExtrasMap.push({
						FIDCatalogVariation: cvExtra.FIDCatalogVariation,
						FIDBOP: cvExtra.FIDBOP,
						sameExtras: 0
					});
				}
				let currVal = selectedVariants.find(s => s.FIDExtra === cvExtra.FIDExtra);
				if (currVal && cvExtra.FIDValue === currVal.FIDValue) { 
					let extrasMapItem = cvSameExtrasMap.find(s => s.FIDCatalogVariation === cvExtra.FIDCatalogVariation);
					if (extrasMapItem)
						extrasMapItem.sameExtras++;
				} /*else {
					currVal.FIDBOP = bopExtra.FIDBOP; 
					currVal.FIDValue = bopExtra.FIDValue; 
					currVal.Value = bopExtra.Value;
				}*/
			} 
			//else {
			//	bopSameExtrasMap.push({
			//		FIDBop: bopExtra.FIDBop,
			//		sameExtras: 1
			//	});
			//}
		});
        //
        //var foundBop = _(availBops).groupBy('FIDBop').map((item, FIDBop) => {
        //    return { FIDBop: FIDBop, Extras: _.map(item, 'FIDExtra') }   
        //    }).value().filter(b => b.Extras.length === extraCount);
        //
        //var selBop = undefined;
        //if (foundBop && foundBop.length > 0) {
        //    selBop = parseInt(foundBop[0].FIDBop);
        //}
        
        if (changed) {
			
			let availBopExtra = cvSameExtrasMap.length > 0 ? cvSameExtrasMap.reduce((prev, curr) => {
				return (prev.sameExtras < curr.sameExtras) ? curr : prev
			}) : {
				FIDCatalogVariation: catVar.FIDCatalogVariation,
				FIDBOP: null,
				sameExtras: 0
			};
			
			if (cvSameExtrasMap.length === 0 && selectedVariants && selectedVariants.length > 0) {
				if (selectedVariants[0].FIDBOP) {
					availBopExtra.FIDBOP = selectedVariants[0].FIDBOP;
					availBopExtra.sameExtras = selectedVariants.length;
				}
			}
			
			//availBopExtra = cvSameExtrasMap.length > 0 ? cvSameExtrasMap.reduce((prev, curr) => {
			//	return (prev.sameExtras < curr.sameExtras) ? curr : prev
			//}) : undefined;
			//if (!availBopExtra || availBopExtra.length === 0 || availBopExtra.sameExtras === 0) {
			//	availBopExtra = bopAvailVariants.filter(s => s.FIDExtra === key && s.FIDValue === value[0].ID)[0]
			//}
            //selectedVariants[changedIndex] = changed;
			
			if (availBopExtra) {
				//selectedVariants = [
				//	availBopExtra
				//];
				//
				//selectedVariants.concat(bopAvailVariants.filter(s => s.FIDBOP === availBopExtra.FIDBOP && s.FIDExtra !== key));
				//bopAvailVariants.filter(s => s.FIDBop === availBopExtra.FIDBop && s.FIDExtra !== key).forEach(v => {
				//	selectedVariants.push(v);
				//});
				let selectedExtras = extras && extras.length > 0 ? [ { FIDExtra: key, FIDValue: value[0].ID, Value: value[0].Denomination } ] : [];

				if (selectedExtras.length > 0) {
					extras.filter(e => e.ID !== key).forEach((extra) => {
						var currItem = selectedVariants.find(v => v.FIDExtra === extra.ID && !!v.Value);
						if (currItem && currItem.Value) {
							selectedExtras.push( { FIDExtra: extra.ID, FIDValue: currItem.FIDValue, Value: currItem.Value } );
						}
					});
				}

                this.setState({
					selectedExtras,
                    selectedVariants,
                    //bopVariantUnavailable: !changedExtra.IsPassive,
					isLoading: !!availBopExtra.FIDBOP && !changedExtra.IsPassive
                }, async() => {
					if (availBopExtra.FIDBOP && !changedExtra.IsPassive) {
						await this.reloadData(availBopExtra.FIDBOP, this.loadData);
						this.setState({isLoading:false}, () => {
							this.grdVariationsBOP.current && this.grdVariationsBOP.current.getWrappedInstance().refresh();
						});
					}
					
					if (changedExtra.IsPassive) {
						this.getItemStatus();
					}
				});
            } else {
				//var catVarID = catVar.FIDCatalogVariation;
			    //
				//var catVarExtras = variations.filter(v => v.FIDCatalogVariation === catVarID);
				//
				//selectedVariants = [];
				//
				//catVarExtras.forEach(ve => {
				//	selectedVariants.push({
				//		FIDBop: data.ID,
				//		FIDExtra: ve.FIDExtra,
				//		Value: ve.Value
				//	})
				//});
				
				var selectedExtras = extras && extras.length > 0 ? [ { FIDExtra: key, FIDValue: value[0].ID, Value: value[0].Denomination } ] : [];

				if (selectedExtras.length > 0) {
					extras.filter(e => e.ID !== key).forEach((extra) => {
						var currItem = selectedVariants.find(v => v.FIDExtra === extra.ID && !!v.Value);
						if (currItem && currItem.Value) {
							selectedExtras.push( { FIDExtra: extra.ID, FIDValue: currItem.FIDValue, Value: currItem.Value } );
						}
					});
				}
				
                this.setState({
                    selectedVariants,
					selectedExtras,
                    bopVariantUnavailable: !changedExtra.IsPassive,
					isLoading: true,
                }, () => { 
					if (bopDefaultVariant && bopDefaultVariant.FIDBop && bopDefaultVariant.Barcode && bopg)
					{
						this.reloadData(bopg.FIDBop, () => {
							this.grdVariationsBOP.current && this.grdVariationsBOP.current.getWrappedInstance().refresh();
						});
					}
					if (bopDefaultVariant && bopDefaultVariant.FIDBop && this.props.params.CatalogueActiveVariants) {
						if (!bopg || bopg.FIDBOP !== bopDefaultVariant.FIDBop)					
							this.reloadData(bopDefaultVariant.FIDBop, () => {
								this.grdVariationsBOP.current && this.grdVariationsBOP.current.getWrappedInstance().refresh();
							}); 
					} else {
						this.setState({ isLoading: false },() => { 
							this.grdVariationsBOP.current && this.grdVariationsBOP.current.getWrappedInstance().refresh();
							this.getItemStatus();
						});
					}
				});				
            }
        }
        
        //var availCombinations = [] ;
        //availCombinations = variations.filter(v => v.FIDExtra === key && v.Value === value[0].Denomination)
        //                        .reduce((prevVar, currVar) => {
        //                            availCombinations.push(currVar.FIDCatalogVariation);
        //                            return availCombinations;
        //                        });                    
        //variations = variations.filter(v => availCombinations.includes(v.FIDCatalogVariation));

        //var currentVars = variations.filter(i => i.FIDBop === data.ID).map(i => {
        //    var newVal = i.Value;
        //    if (i.FIDExtra === key) {
        //        newVal = value[0].ID;
        //    }
        //    return { FIDExtra: i.FIDExtra, Value: newVal };
        //});
        //var filter = {};
        //for (var i = 0; i < currentVars.length; i++) {
        //    var item = currentVars[i];
        //    filter["e_" + item.FIDExtra] = item.Value;
        //}
        //var bopVariants = [];
        //var FIDBOPS = variations.map(i => i.FIDBop).filter((value, index, self) => self.indexOf(value) === index);
//
        //var variationsMap = variations.map(i => {
        //    var newVal = i.Value;
        //    return { FIDExtra: i.FIDExtra, Value: newVal, FIDBOP: i.FIDBop }
        //}).reduce((r, a) => {
        //    r[a.FIDBOP] = r[a.FIDBOP] || [];
        //    r[a.FIDBOP].push(a);
        //    return r;
        //}, Object.create(null));
//
        //for (var b = 0; b < FIDBOPS.length; b++) {
        //    var FIDBOP = FIDBOPS[b];
        //    var v = variationsMap[FIDBOP];
        //    var tempF = {};
        //    for (var i2 = 0; i2 < v.length; i2++) {
        //        var item2 = v[i2];
        //        tempF["e_" + item2.FIDExtra] = item2.Value;
        //    }
        //    bopVariants.push({ key: FIDBOP, value: tempF });
        //}
        //var selVar = bopVariants.filter(i => JSON.stringify(i.value) === JSON.stringify(filter));
        //if (selVar.length === 1) {
        //    this.reloadData(selVar[0].key, this.loadData);
        //}
        
        //var selectedVar = variations.filter(i => i.FIDExtra === key && i.Value === value[0].ID)[0];
        //if (selectedVar) {
        //    this.reloadData(selectedVar.FIDBop, this.loadData);
        //}
    }
    updateDocument = () => {
        var { onBuyItem, onRemoveItem } = this.props;
        var { data, discount, totalPrice, qty, selectedExtras, bopVariantUnavailable } = this.state;
        if (qty === 0) {
            //messaggio di conferma
            if (onRemoveItem) {
                onRemoveItem(data, this.props.CatalogFIDCausal,this.getItemStatus);
            }
        }
        else {
            if (!discount || discount === 0) {
                discount = 0;
                totalPrice = data.Price;
            }
            data.Discount = discount;
            data.TotalPrice = totalPrice;

            data.variantUnavailable = bopVariantUnavailable;
            if (selectedExtras) {
                data.selectedVariants = selectedExtras;
            }
            if (onBuyItem) {
                onBuyItem(data, qty, true, true, this.getItemStatus);
            }
        }
    }
	onAddItem = async(item, FIDCausal, stars, BOPSelectionModes) => {
        var { data, discount, totalPrice, qty, selectedExtras, bopVariantUnavailable } = this.state;

        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = item.Price;
        }
        item.Discount = discount;
        item.TotalPrice = totalPrice;

        item.variantUnavailable = bopVariantUnavailable;
        if (selectedExtras) {
            item.selectedVariants = selectedExtras;
        }

		this.props.onAddItem && await this.props.onAddItem(item, FIDCausal, stars, BOPSelectionModes, this.getItemStatus);
	}
    onNoticeItem = (item) => {
        var { data, discount, totalPrice, qty, selectedExtras, bopVariantUnavailable } = this.state;

        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = item.Price;
        }
        item.Discount = discount;
        item.TotalPrice = totalPrice;

        item.variantUnavailable = bopVariantUnavailable;
        if (selectedExtras) {
            item.selectedVariants = selectedExtras;
        }
        this.props.onNoticeItem && this.props.onNoticeItem(item);
		if (this.props.isCatalog) {
			setTimeout(this.getItemStatus, 600);
		}
    }
    onNegotiateItem = (item) => {
        var { data, discount, totalPrice, qty, selectedExtras, bopVariantUnavailable } = this.state;

        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = item.Price;
        }
        item.Discount = discount;
        item.TotalPrice = totalPrice;

        item.variantUnavailable = bopVariantUnavailable;
        if (selectedExtras) {
            item.selectedVariants = selectedExtras;
        }
        this.props.onNegotiateItem && this.props.onNegotiateItem(item);
        if (this.props.isCatalog) {
			setTimeout(this.getItemStatus, 600);
		}
    }
    onQuoteItem = (item) => {
        var { data, discount, totalPrice, qty, selectedExtras, bopVariantUnavailable } = this.state;

        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = item.Price;
        }
        item.Discount = discount;
        item.TotalPrice = totalPrice;

        item.variantUnavailable = bopVariantUnavailable;
        if (selectedExtras) {
            item.selectedVariants = selectedExtras;
        }
        this.props.onQuoteItem && this.props.onQuoteItem(item);
        if (this.props.isCatalog) {
			setTimeout(this.getItemStatus, 600);
		}
    }
    onStarItem = (item, stars) => {
        var { data, discount, totalPrice, qty, selectedExtras, bopVariantUnavailable } = this.state;

        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = item.Price;
        }
        item.Discount = discount;
        item.TotalPrice = totalPrice;

        item.variantUnavailable = bopVariantUnavailable;
        if (selectedExtras) {
            item.selectedVariants = selectedExtras;
        }
        this.props.onStarItem && this.props.onStarItem(item, stars);
		if (this.props.isCatalog) {
			setTimeout(this.getItemStatus, 600);
		}
    }
    onBookItem = (item) => {
        var { data, discount, totalPrice, qty, selectedExtras, bopVariantUnavailable } = this.state;

        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = item.Price;
        }
        item.Discount = discount;
        item.TotalPrice = totalPrice;

        item.variantUnavailable = bopVariantUnavailable;
        if (selectedExtras) {
            item.selectedVariants = selectedExtras;
        }
        this.props.onBookItem && this.props.onBookItem(item);
		
		if (this.props.isCatalog) {
			setTimeout(this.getItemStatus, 600);
		}
    }
    onReserveItem = (item) => {
        var { data, discount, totalPrice, qty, selectedExtras, bopVariantUnavailable } = this.state;

        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = item.Price;
        }
        item.Discount = discount;
        item.TotalPrice = totalPrice;

        item.variantUnavailable = bopVariantUnavailable;
        if (selectedExtras) {
            item.selectedVariants = selectedExtras;
        }
        this.props.onReserveItem && this.props.onReserveItem(item);
        setTimeout(this.getItemStatus, 600);
    }
    onBuyItem = (item) => {
        var { data, discount, totalPrice, qty, selectedExtras, bopVariantUnavailable } = this.state;

        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = item.Price;
        }
        item.Discount = discount;
        item.TotalPrice = totalPrice;

        item.variantUnavailable = bopVariantUnavailable;
        if (selectedExtras) {
            item.selectedVariants = selectedExtras;
        }
        this.props.onBuyItem && this.props.onBuyItem(item);
        if (this.props.isCatalog) {
			setTimeout(this.getItemStatus, 600);
		}
    }
    onRemoveItem = (item,FIDCausal) => {
        var { data, discount, totalPrice, qty, selectedExtras, bopVariantUnavailable } = this.state;

        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = item.Price;
        }
        item.Discount = discount;
        item.TotalPrice = totalPrice;

        item.variantUnavailable = bopVariantUnavailable;
        if (selectedExtras) {
            item.selectedVariants = selectedExtras;
        }
        this.props.onRemoveItem && this.props.onRemoveItem(item, FIDCausal, this.getItemStatus);
        //setTimeout(this.getItemStatus, 1000);
    }
    renderPriceButtons(classes, price, showPrices, discount, totalPrice, isPresentation, isNotice, isReservation, isBooking, isCart, showStars, HoverStar, color, documents, priceB2B, hidePrice) {
        var { isCatalog,actions } = this.props;
        var { data, internalRemark, publicRemark, discount, totalPrice } = this.state;
        if (!discount || discount === 0) {
            discount = 0;
            totalPrice = data.Price;
        }
        data.Discount = discount;
        data.TotalPrice = totalPrice;
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;
		
		var causals = this.props.causals
		
        var ui = <CatalogueActions 
            data={data} 
            documents={documents} 
            causals={causals} 
            onAddItem={this.onAddItem} 
            onNoticeItem={this.onNoticeItem} 
            onQuoteItem={this.onQuoteItem} 
            onNegotiateItem={this.onNegotiateItem} 
            onStarItem={this.onStarItem} 
            onBookItem={this.onBookItem} 
            onReserveItem={this.onReserveItem} 
            onBuyItem={this.onBuyItem} 
            onRemoveItem={this.onRemoveItem} 
        />
        if (isCatalog) {
				var qtyFieldClasses = {
					textFieldInput: {
						padding: "11px 0 0",
						fontSize: ".75rem",
						color: "#3C4858",
						"& b": {
							display: "none"
						},
						"&:after": {
							transform: "scaleX(1) !important"
						}
					}
				}
                ui = <Grid container>
                    <Grid container alignItems="center" item xs={12} style={{ textAlign: "center" }}>
						<Grid item xs={false} md={2} />
                        <Grid item xs={3} md={2} style={{ textAlign: "right", paddingRight: 20 }}><XButton className={classes.btnCatQty} color="gray" onClick={() => {
                            this.setState(state => {
                                if (!state.qty || state.qty < 0) state.qty = 1;
                                state.qty--;
                                return state;
                            })}}>--</XButton></Grid>
                        <Grid item xs={6} md={4}><XField className={classes.catQtyField} type="number" caption="nr" field={{ Required: 1 }} label={getTranslation(1,"CAT_QTY",this.props.translations, 'Quantità')} id="qty" type="number" value={this.state.qty} onChange={(id) => (event, value) => {
                            var n = parseInt(value);
                            if (n < 0) {
                                n = 0;
                            }
                            this.setState({ qty: n });
                        }} /></Grid>
                        <Grid item xs={3} md={2} style={{ textAlign: "left", paddingLeft: 20 }}><XButton className={classes.btnCatQty} onClick={() => {
                            this.setState(state => {
                                if (!state.qty || state.qty < 0) state.qty = 0;
                                state.qty++;
                                return state;
                            })
                        }}>+</XButton></Grid>
						<Grid item xs={false} md={2} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center", margin: 20 }}>
                        <XButton color="gray" onClick={() => {
                            this.updateDocument();
                        }}>{documents.length > 0 ? getTranslation(1,"CAT_UPDATECART",this.props.translations, 'Aggiorna quotazione') : getTranslation(1,"CAT_ADDTOCART",this.props.translations, 'Aggiungi alla quotazione')}</XButton>
                        {false && documents.length > 0  && <XButton onClick={() => {
                                this.setState(state => {
                                    state.qty = 0;
                                    return state;
                                }, this.updateDocument)
                            }}>Rimuovi dalla quotazione</XButton>
                        }
                    </Grid>
                </Grid>;
        }
        if (actions) {
            ui = <Grid container xs={12}><Grid item xs={2} /><Grid item xs={8}>{actions(this.props.isSelected, data.ID)}</Grid><Grid item xs={2} /></Grid>;
        }
        return (<Grid container xs={12} className={classes.marginTop + " " + classes.centerText}>
            { !hidePrice ?
                <Grid item container xs={12} className={classes.marginTop}>
                    <Grid item xs={12}>
                        <Typography classes={{ root: classes.priceText }} style={this.state.discount > 0 ? { textDecoration: "line-through" } : {}  } color={this.state.discount > 0 ? "gray" : "primary"} onClick={this.showPrices}>{price}</Typography>
                        {1==2 && priceB2B && <Typography classes={{ root: classes.priceText }} style={this.state.discount > 0 ? { textDecoration:   "line-through" } : {}} color={this.state.discount > 0 ? "gray" : "primary"}>{priceB2B}</Typography>}
                    </Grid>
                    {/* <Grid item xs={12}>
                        {this.state.discount > 0 && <Typography classes={{ root: classes.priceText }} color="primary" onClick={this.showPrices}>    {this.state.discount} %</Typography>}
                    </Grid> */}
                    <Grid item xs={12}>
                        {this.state.discount > 0 && <Typography classes={{ root: classes.priceText }} color="primary" onClick={this.showPrices}>    {data.PriceCurrency + " " + numeral(parseFloat(this.state.totalPrice)).format('0,0.00')}</Typography>}
                    </Grid>
					{
						!this.state.totalPrice && this.state.totalPrice !== 0 && /*<Grid>*/
							<Typography style={{width:"inherit"}}>{getTranslation(1,"CAT_VARIABLE_UNAVAILABLE", this.props.translation, "La variante richesta non è disponibile, crea una quotazione per avere più informazioni")}</Typography>
						/*</Grid>*/
					}
                    {
                        //showPrices && <Fragment>
                        //<Grid container item xs={12}>
                        //    <Grid item xs={4}><Typography classes={{ root: classes.priceText }} color="primary">Sc. %</Typography></Grid>
                        //    <Grid item xs={8}><XField id="discount" value={discount} onChange={this.discountChange} /></Grid>
                        //    <Grid item xs={12}><XField id="totalPrice" value={totalPrice} onChange={this.priceChange} /></Grid>
                        //</Grid>
                        //</Fragment>
                    }
                </Grid> : 
                <Grid>
                    <Typography>{getTranslation(1,"CAT_VARIABLE_UNAVAILABLE", this.props.translation, "La variante richesta non è disponibile, crea una quotazione per avere più informazioni")}</Typography>
                </Grid>
            }
            {ui}
        </Grid>);
    }
}
const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withWidth(),
    withStyles(styles)
);


export default enhance(CatalogueItem);