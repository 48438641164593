import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withWidth } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import XOXLoader from 'components/XOXLoader';
import { Collage, CalendarRange } from 'mdi-material-ui';
import classNames from 'classnames';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
import { asyncForEach } from 'utils';

import {
    successColor,
    tooltip,
    cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

const fullHeight = (window.innerHeight - 64);
//const baseUrl = 'presentation';
const styles = theme => ({
    root: {
        maxHeight: 'calc(100vh - 64px - 45px)',
        minHeight: 'calc(100vh - 64px - 45px)',
        //overflow: 'hidden',
        borderRadius: 1,
        marginBottom: 0,
    },
    cardBody: {
        maxHeight: 'calc(100% - 56px)',
        overflowY: 'auto',
    },
    cardCategory: {
        color: `#999999`,
        fontSize: 20,
        fontWeight: 'bold',
        paddingTop: 17,
        marginBottom: `0`,
        marginTop: `0`,
        margin: `0`
    },
    cardTitle: {
        ...cardTitle,
        marginTop: `0px`,
        marginBottom: `3px`
    },
    stats: {
        color: `#999999`,
        fontSize: `12px`,
        lineHeight: `22px`,
        display: `inline-flex`,
        "& svg": {
            position: `relative`,
            top: `4px`,
            width: `16px`,
            height: `16px`,
            marginRight: `3px`
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            position: `relative`,
            top: `4px`,
            fontSize: `16px`,
            marginRight: `3px`
        }
    },
    item: {
        position: 'relative',
        padding: '0 !important',
        cursor: 'pointer',
    },
    itemBackground: {
        paddingTop: 'calc(100% - 10px)',
        background: '#fff',
        border: '1px solid #bbb',
        width: 'calc(100% - 10px)',
        margin: 5
    },
    itemContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        maxWidth: '100%',
        maxHeight: '100%',
        textAlign: 'center',
        fontSize: 20,
        color: '#494949',
        fontWeight: 'bold',
    },
    itemBackgroundAlt: {
        paddingTop: 'calc(100% - 10px)',
        //background: '#494949',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: '1px solid #bbb',
        width: 'calc(100% - 10px)',
        margin: 5
    },
    itemContentAlt: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        maxWidth: '100%',
        maxHeight: '100%',
        textAlign: 'center',
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    itemContentWithBackground: {
        top: 'unset',
        bottom: -10,
        background: 'rgba(0,0,0,0.7)',
        width: 'calc(100% - 12px)',
        padding: '5px 0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#fff',
        fontSize: 14
    }
});

const defaultFilters = {
    ShowModels: false,
    ShowStock: false,
    ImageView: 2,
    GroupType: 1,
    Sort: [],
};
GetParam(`CatalogueDefaultShowCatalogue`).then(result => defaultFilters.ShowModels = result);
GetParam(`CatalogueDefaultShowStock`).then(result => defaultFilters.ShowStock = result);
GetParam(`CatalogueDefaultGroupType`).then(result => defaultFilters.GroupType = result);
GetParam(`CatalogueDefaultImageView`).then(result => defaultFilters.ImageView = result);

async function GetParam(param) {
    return await fetch('/Base/GetParam?param=' + param).then(res => res.json());
}


class CatalogPreview extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: undefined,
            collections: undefined,
            currentFilters: Object.assign({}, defaultFilters),
            labelKey: undefined,
            paramType: undefined,
            urlImage: undefined,
            backgroundSize: undefined,
			baseUrl: 'catalogue'
            
        };
        this.mounted = false;
    }
    static contextTypes = {
        router: PropTypes.object
    }
    
    componentDidMount = async () => {
        this.mounted = true;
		
		var baseUrl = this.state.baseUrl;
		
		await fetch('/GC/params').then(res => res.json()).then(data => {
			//Object.keys(data).map(function (key, index) { data[key] = JSON.parse(data[key]) })
			var keys = Object.keys(data);
			for(var i in keys){
				var key = keys[i];
				var val =data[key];
				if(typeof val === "string"){
					val = JSON.parse(val);
				}
				data[key]= val;
			}
			this.props.setParams(data);
		});
		
		if (this.props.menus.includes("presentation") && (this.props.params.CatalogUsePresentation || !this.props.menus.includes("catalogue"))) {
			baseUrl = "presentation";
		}
		
		await fetch('/Base/GetCurrentFIDIdentity').then(res => res.json()).then(data => {
			this.props.setIdentity(data);
		});
		
		if ((this.props.isAgent || this.props.params.CatalogueShowIRPLSelector) && this.props.FIDProfile !== this.props.params.CustomerTypologyId) {
			
			var IRHasPricelists = true;
			if (this.props.isAgent) {
				IRHasPricelists = await fetch("/IR/HasPricelists/"+this.props.FIDIdentity).then(res => res.json());
			}
			
			this.setState({ isLoading: false, baseUrl, IRHasPricelists });
			return;
		}
		
		var { CatalogFIDPricelist, FIDIRPricelist, CatalogFIDIdentity, FIDIdentity } = this.props;
		
        var { currentFilters } = this.state;
        var showDB = currentFilters.ShowModels ? true : !!this.props.params.CatalogueDefaultShowCatalogue;
        var showDM = currentFilters.ShowStock ? true : !!this.props.CatalogueDefaultShowStock;
        var groupType = currentFilters.GroupType || this.props.params.CatalogueDefaultGroupType || 1;
        var imageView = currentFilters.ImageView || this.props.params.CatalogueDefaultImageView || 1;

        if (!CatalogFIDPricelist)
			CatalogFIDPricelist = FIDIRPricelist;
		
		if (!CatalogFIDIdentity)
			CatalogFIDIdentity = FIDIdentity;
		
		if (CatalogFIDIdentity === this.props.FIDLicensee)
			CatalogFIDIdentity = undefined;
		
		if (!CatalogFIDIdentity &&!CatalogFIDPricelist && this.props.params)
			CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;

		if (CatalogFIDIdentity && this.props.FIDProfile === this.props.params.CustomerTypologyId)
			CatalogFIDPricelist = undefined;

        var prms = `?showDB=${showDB}
            &showDM=${showDM}
            &groupType=${groupType}
            &imageView=${imageView}
            &FIDPricelist=${CatalogFIDPricelist}&FIDIdentity=${CatalogFIDIdentity}`;

        var data = await fetch(`/BOP/GetCatalogueCollectionsNew/${prms}`).then(res => res.json());
        var categories = await fetch(`/BOP/GetCatalogueCategoriesNew/${prms}`).then(res => res.json());
        var tags = await fetch(`/BOP/GetCatalogueTagsNew/${prms}`).then(res => res.json());
        var groups = await fetch(`/BOP/GetCatalogueGroups/${prms}`).then(res => res.json());
        var collections = groups;
        var viewType = 'groups';
        var url = `/BOP/GetCatalogueGroupInfo/`;
        var urlImage = ``;
        var idKey = `ID`;
        var backgroundSize = `contain`;
        var labelKey = `Description`;
        if(!groups || groups.length <= 1) {
            viewType = 'brands';
            url = `/BOP/GetBrandInfo/`;
            urlImage = `/BOP/BrandDefaultImage/`;
            idKey = `FIDBrand`;
            backgroundSize = `contain`;
            labelKey = false;
            collections = data.brands;
            if (collections.length <= 1) {
                collections = data.lines;
                viewType = 'lines';
                url = `/BOP/GetLineInfo/`;
                urlImage = `/BOP/LineDefaultImage/`;
                idKey = `FIDLine`;
                labelKey = `Line`;
                backgroundSize = `cover`;
                if (collections.length <= 1) {
                    collections = data.series;
                    viewType = 'series';
                    url = `/BOP/GetSeriesInfo/`;
                    urlImage = `/BOP/SeriesDefaultImage/`;
                    idKey = `FIDSeries`;
                    labelKey = `Series`;
                    if (collections.length <= 1) {
                        viewType = 'products';
                        url = false;
                    }
                }
            }
        }
        if(!this.mounted){
            return;
        }
        //collections = collections.slice(0, 5);
        if (url) {
			var collectionsData = [];
			await asyncForEach(collections, (coll) => {
				fetch(url + coll[idKey]).then(res => res.json()).then((res) => {
					collectionsData.push(res);
				})
			});
            //for (var i = 0; i < collections.length; i++) {
            //    if(!this.mounted){
            //        break;
            //    }
            //    var coll = collections[i];
            //    fetch(url + coll[idKey]).then(res => res.json()).then((info) => {
			//		collections[i] = info;
			//		
			//	}
            //}
			if(this.mounted){
			    this.setState({ data, collections, labelKey, paramType: viewType, urlImage, backgroundSize, isLoading: false, baseUrl,categories, tags });
        	}
        } else {
        	if(this.mounted){
			    this.setState({ data, collections, labelKey, paramType: viewType, urlImage, backgroundSize, isLoading: false, baseUrl,categories, tags });
        	}
    	}
        //if(this.mounted){
        //    this.setState({ data, collections, labelKey, paramType: viewType, urlImage, backgroundSize, isLoading: false });
        //}
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    onItemClick = (link) => () => {
        this.context.router.history.push(link);
    }
    renderItem = (label, image, link, id, backgroundSize = `cover`) => {
        var { classes } = this.props;
        var style = {};
        var labelClass = ``;
        if (image) {
            style = {
                background: 'url(' + image + ')',
                backgroundPosition: 'center',
                backgroundSize: backgroundSize,
                backgroundRepeat: 'no-repeat',
            };
            labelClass = classes.label;
        }
        return (<GridItem key={id} xs={6} md={3} className={classes.item} onClick={this.onItemClick(link)}>
            <div className={classes.itemBackground} style={style} />
            <div className={classNames(classes.itemContent, { [classes.itemContentWithBackground]: !!image && !!label})}>
                {!!label && <span className={labelClass}>{label}</span>}
            </div>
            
        </GridItem>);
    }
    renderItemAlt = (label, link) => {
        var { classes } = this.props;
        var labelClass = ``;
        return (<GridItem xs={6} md={3} className={classes.item} onClick={this.onItemClick(link)}>
            <div className={classes.itemBackgroundAlt} />
            <div className={classes.itemContentAlt}>
                {!!label && <span className={labelClass}>{label}</span>}
            </div>

        </GridItem>);
    }
    renderBody = () => {
        var { data, collections, labelKey, paramType, urlImage, backgroundSize, paramType, baseUrl, categories, tags } = this.state;
		
        if((this.props.isAgent || this.props.params.CatalogueShowIRPLSelector) && this.props.FIDProfile !== this.props.params.CustomerTypologyId){
            return (
                <GridContainer>
                    {this.renderItemAlt(getTranslation(1,`CAT_CUSTOMER`,this.props.translations, `CLIENTE`), `/${baseUrl}?showcustomer=1`, 0)}
                    {(!this.props.isAgent || this.state.IRHasPricelists) && this.renderItemAlt(getTranslation(1,`CAT_PRICELIST`,this.props.translations, `LISTINO`), `/${baseUrl}?showpricelist=1`, 0)}
                </GridContainer>
            )
        }
        if (!collections) {
            return <XOXLoader transparent />;
        }
        return (
            <GridContainer>

                {this.renderItemAlt(getTranslation(1,`CAT_NEWS`,this.props.translations, `NOVITA'`), `/${baseUrl}?new=1`, 0)}
                {!this.props.params.CatalogueHideAllButton && this.renderItemAlt(getTranslation(1,`CAT_FULLCATALOG`,this.props.translations, `CATALOGO COMPLETO`), `/${baseUrl}?products=1`, 0)}
                {!this.props.params.CatalogueHideCategories && categories && categories.length > 1 && this.renderItemAlt(getTranslation(3,`BOP_CATEGORIES`,this.props.translations, `CATEGORIE`), `/${baseUrl}?categories=1`, 0)}
                {!this.props.params.CatalogueHideTags && tags && tags.length > 1 && this.renderItemAlt(getTranslation(3,`BOP_TAGS`,this.props.translations, `TAG`), `/${baseUrl}?tags=1`, 0)}
                {collections.map((item, index) => {

                    var label = !!labelKey && item && item[labelKey];
                    var url =item && `/${baseUrl}/?` + paramType + `=` + item.ID;
                    var image = item && urlImage + item.ID;
                    if (item && !item.HasImage) {
                        image = false;
                        if (!labelKey) {
                            label = item.Brand;
                        }
                    }
                    if(paramType === `groups`){
                        return this.renderItemAlt(label, url);
                    }
                    else {
                        return this.renderItem(label, image, url, item && item.ID, backgroundSize);
                    }
                })}
            </GridContainer>
        );
    }

    render() {
        var { classes } = this.props;
        return <Card className={classes.root}>
            <CardHeader color="info" stats icon>
                <CardIcon color="info">
                    <Collage />
                </CardIcon>
                <p className={classes.cardCategory}>{getTranslation(1,`CAT_CATALOGUE`,this.props.translations, `Catalogo`)}</p>
                <h3 className={classes.cardTitle}></h3>
            </CardHeader>
            <CardBody className={classes.cardBody}>
                {this.renderBody()}
            </CardBody>
        </Card>;
    }
}



const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withWidth(),
    withStyles(styles)
);


export default enhance(CatalogPreview);
// export default withStyles(styles)(CatalogPreview);