import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import CashField from './CashField';
import CashProductRow from './CashProductRow';
import XField from 'components/XField';

import { Collection, List, AutoSizer, CellMeasurer,CellMeasurerCache } from 'react-virtualized';

const styles = theme => ({
    root: {
    },
    rootActive: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 20,
        background: '#fff',
    },
    productsContainer: {
        width: '100%',
        height: 'calc(100% - 74px)',
        overflowX: 'hidden',
        overflowY: 'auto',
    }
});

let controller;

const cache = new CellMeasurerCache({
    defaultWidth: 100,
    minWidth: 75,
    fixedHeight: false
});

class CashProductSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            active: true,
            data: [],
        }
    }
    componentDidMount() {
        this.loadDataSearch();
        this.onSearchFieldBlur()();
    }
    componentDidUpdate(props) {
        if(this.props.view !== props.view){
            this.loadDataSearch();
        }
        if(this.props.IRFilter !== props.IRFilter) {
            this.loadDataSearch();
        }
    }
    onClose = () => {

        // this.setState({ active: false}, () => {
        //     this.props.onSearchShowChange(false);
        // })
    }
    onValueChange = (id) => (event, value) => {
        this.setState({ value },() => {
            this.loadDataSearch();
        });
    }
    onActivate = () => {
        // this.setState({ active: true }, () => {
        //     this.loadDataSearch();
        //     this.props.onSearchShowChange && this.props.onSearchShowChange(true, this.onClose);
        //     document.getElementById("searchField").focus();
        // });
    }
    onSearchFieldBlur = () => () => {
        if(!this.state.editorOpen) {
            var el = document.getElementById("searchField");
            el && el.focus();
        }
    }
    loadDataSearch = () => {
        var { view, doc, IRFilter } = this.props;
        var { value, active } = this.state;
        if(!active){
            return;
        }
        console.log("START SEARCH");
        var url = `/DOC/GetCashRows/${doc.ID}?FIDIdentity=${doc.FIDIdentity}`;
        if(view !== "ROWS"){
            url = `/Cash/SearchBop?term=${value}&view=${view}&FIDCycle=${doc.FIDCycle}&FIDDocumentCausal=${doc.FIDCausal}&FIDOwner=${doc.FIDOwner}&FIDOwnerDestination=${doc.FIDOwnerDestination}`;
        

            if(doc.FIDCausal === 38 || view==="DEPOSIT"){
                if(IRFilter === 0){
                    if (doc.FIDIdentity) {
                        url += `&FIDIdentity=${doc.FIDIdentity}`;
                        if(doc.FIDIRRelation){
                            url += `&FIDIRRelation=${doc.FIDIRRelation}`;
                        }
                    }
                    else {
                        url+= "&WithIR=true";
                    }
                }
                else if(IRFilter === 1) {
                    url+= "&WithoutIR=true";
                }
            }
            else {
                if(doc.FIDIdentity) {
                    url +=`&FIDIdentity=${doc.FIDIdentity}`;
                    if(doc.FIDIRRelation){
                        url += `&FIDIRRelation=${doc.FIDIRRelation}`;
                    }
                }
            }
        }
        controller && controller.abort && controller.abort();
        controller = new AbortController();
        
        var signal = controller.signal;
        this.setState({data:[]});
        fetch(url, { signal }).then(res => res.json()).then(data => {
            this.setState({ data });
        }).catch(e => {
            if(e.name === "AbortError"){

            }
        })
    }
    onEditorOpen = () => {
        this.setState({editorOpen: true},this.props.onEditorOpen);
    }
    onEditorClose = () => {
        this.setState({editorOpen: false},() => {
            document.getElementById("searchField").focus();
            this.props.onEditorClose && this.props.onEditorClose();
        });
    }
    onRemove = async (id) => {
        if(this.props.onRemove) {
            await this.props.onRemove(id);
            this.loadDataSearch();
        } 
    }
    onUpdate = () => {
        this.props.onUpdate && this.props.onUpdate();
    }
    renderRows = ({ index, isScrolling, key, parent, style}) => {
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({measure,registerChild}) => this.renderFinalRow(index, style, key, measure, registerChild)}
            </CellMeasurer>
        );
    }
    renderFinalRow = (index, style, key, measure, registerChild) =>{
        var { doc, isRepair, confirmed, onRequestActions, view, onBuy } = this.props;
        var { data } = this.state;
        let item = data[index];
        if(view === "ROWS"){
            if(this.state.editorOpen){
                //style.height = 492.38;
                style={};
            }
            return <CashProductRow ref={registerChild} style={style} doc={doc} key={key} data={item} onRemove={this.onRemove} onUpdate={this.onUpdate} isRepair={isRepair} confirmed={confirmed} onRequestActions={onRequestActions} onEditorOpen={this.onEditorOpen} onEditorClose={this.onEditorClose} onLoad={measure}/>
        }
        else {
            return <CashProductRow ref={registerChild} style={style} doc={doc} isBop={true} key={key} data={item} onBuy={onBuy} view={view}  onLoad={measure}/>
            
        }
    }
    render() {
        var { classes, onBuy, onRemove, onUpdate, view, isRepair, confirmed, onRequestActions, doc } = this.props;
        var { active, value, data } = this.state;
        var className = classes.root;
        if(active) {
            className = classes.rootActive;
        }
        console.log("START RENDER");
        return <div className={className}>
            <span>Ricerca</span>
            {!active && <CashField value={value} onActivate={this.onActivate}/>}
            {active && <XField id={"searchField"} value={value} onChange={this.onValueChange} onBlur={this.onSearchFieldBlur} />}
            {/* {active && data && (
                <div className={classes.productsContainer} key={view}>
                    {view !== "ROWS" && data.map((item,index) => <CashProductRow doc={doc} isBop={true} key={item} data={item} onBuy={onBuy} view={view} />)}
                    {view === "ROWS" && data.map((item,index) => <CashProductRow doc={doc} key={item} data={item} onRemove={this.onRemove} onUpdate={this.onUpdate} isRepair={isRepair} confirmed={confirmed} onRequestActions={onRequestActions} onEditorOpen={this.onEditorOpen} onEditorClose={this.onEditorClose} />)}
                </div>
            )} */}
            {active && data && (
                <AutoSizer>
                    {({ height, width }) => (
                        <List 
                            height={height - 75}
                            width={width}
                            rowCount={data.length}
                            deferredMeasurementCache={cache}
                            rowHeight={cache.rowHeight}
                            rowRenderer={this.renderRows}
                        />
                        // <CellMeasurer
                        //     cellRenderer={this.renderRows}
                        //     columnCount={1}
                        //     rowCount={data.length}
                        //     width={width}
                        // >
                        //     {({ getRowHeight }) => (
                        //         <List
                        //             height={height}
                        //             rowHeight={getRowHeight}
                        //             width={width}
                        //             overscanRowCount={5}
                        //             rowRenderer={({ index, isScrolling, isVisible, key, parent, style }) => {
                        //                 return this.renderFinalRow(index, style, key);
                        //             }}
                        //         />
                        //     )}
                        // </CellMeasurer>
                    )}
                </AutoSizer>
                
            )}
        </div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashProductSearch);