import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import XGrid from "../components/XGrid";
import "../components/XReactDataGrid.css";

import XNavigation from '../components/XNavigation';
import { MapMarker, AccountGroup } from 'mdi-material-ui';

import { AccountMultiple as People, AccountMultipleOutline as PeopleOutline } from 'mdi-material-ui';

import XStepper from 'components/XStepper';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

class IndetityExpandRow extends Component {
    constructor(props){
        super(props);
        this.state = {
            view : "destinations",
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }

    handleGridDoubleClick = (event, data) => {
        var { view } = this.state;
        this.context.router.history.push(`/IREdit/${this.props.data.ID}?${view}=true`);
    }

    showDestinations = () => {
        this.setState({view: "destinations"});
    }
    showRelations = () => {
        this.setState({view: "relations"});
    }
    render () {
        var { width,data } = this.props;
        var { view } = this.state;

        var actions = [];
        
        actions.push({ name: getTranslation(4,"IR_DESTINATIONS",this.props.translations,"Indirizzi"), icon: <MapMarker />, action: this.showDestinations, selected: view === "destinations" });
        actions.push({ name: getTranslation(4,"IR_RELATIONS",this.props.translations, "Relazioni"), icon: <AccountGroup />, action: this.showRelations, selected: view === "relations" });
        var direction = "right";
        
        if (["xs"].includes(width)) {
            direction = "down";
        }
        
        var label = <span><XNavigation actions={actions} direction={direction} showLabel={true} /></span>;

        var gridName = "tblIRDestinations";
        var dataRoute = `/IR/GetIRDestinations/${data.ID}?grid=${gridName}`;

        if(view ==="relations") {
            gridName = "tblIRRelations";
            dataRoute = `/IR/GetIRRelations/${data.ID}?grid=${gridName}`;
        }
        return <XGrid
            key={gridName}
            label={label}
            containerID={`gridExpandContainer_${data.ID}`}
            dataRoute={dataRoute}
            dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
            dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
            dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
            onDoubleClick={this.handleGridDoubleClick}
            onEditButton={this.handleGridDoubleClick}
            showColumnsSelector={true}
            showFilterActivator={true}
			showImgColumn={true}
        />;
    }
}
class Identities extends Component {
    constructor(props) {
        super(props);
        this.grdIR = React.createRef();
        this.state ={
            view: "destinations",
            viewRows: "IR_COMPLETE",
            showAll: 0,
            showActive:0,
            incomplete: false
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }
    handleGridDoubleClick = (event, data) => {
        this.context.router.history.push('/IREdit/' + data.ID);
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/IRNew');
    }
    rowExpandFormat = (data) => {
        return <IndetityExpandRow data={data} translations={this.props.translations}/>;
    }
    onShowAllChange =(step) =>{
        this.setState({showAll: step}, this.grdIR.current.refresh);
    }
    onShowActiveClick = (step) => {
        this.setState({ showActive: step}, this.grdIR.current.refresh);
    }
    showIRComplete = () => {
        this.setState({
            viewRows: "IR_COMPLETE",
            incomplete: false
        }, this.grdIR.current.refresh);
    }
    showIRIncomplete = () => {
        this.setState({
            viewRows: "IR_INCOMPLETE",
            incomplete: true
        }, this.grdIR.current.refresh);
    }
    render() {
        var { showAll,showActive, viewRows, incomplete } = this.state;
        var direction = "right";
        var navActions = [];
        navActions.push({ name: getTranslation(4,"IR_COMPLETE",this.props.translations, "COMPLETE"), icon: <People />, action: this.showIRComplete, selected: viewRows === "IR_COMPLETE" });
        navActions.push({ name: getTranslation(4,"IR_INCOMPLETE",this.props.translations, "INCOMPLETE"), icon: <PeopleOutline />, action: this.showIRIncomplete, selected: viewRows === "IR_INCOMPLETE" });
        
        //var fieldShowAll = <XSwitch value={showAll} label="Mostra tutti" onChange={this.onShowAllChange} color="secondary" />;
        var fieldShowAll = <XStepper label={getTranslation(4,"IR_LEVEL",this.props.translations, "Livello")} step={showAll} steps={[{label:getTranslation(4,"IR_MAIN",this.props.translations, "Principali")},{label:getTranslation(4,"IR_ALL",this.props.translations, "Tutte")}]} onStepClick={this.onShowAllChange} />;
        var fieldShowActive = <XStepper label={getTranslation(4,"IR_STATUS",this.props.translations,"Stato")} step={showActive} steps={[{label:getTranslation(4,"IR_ACTIVE",this.props.translations, "Attive")},{label:getTranslation(4,"IR_NOTACTIVE",this.props.translations,"Non attive")},{label:getTranslation(4,"IR_ALL",this.props.translations, "Tutte")}]} onStepClick={this.onShowActiveClick}/>;
        var label = <span><span style={{marginTop:8, display: "inline-block"}}><XNavigation actions={navActions} direction={direction} showLabel /></span><span style={{ display: "inline-block", position: "relative", bottom: 8}}>{fieldShowAll} {fieldShowActive}</span></span>;

        var dataUrl = "/IR/GetData?grid=tblIR";
        if(showAll){
            dataUrl += `&ShowAll=true`
        }
        if(showActive) {
            dataUrl += `&ShowActive=${showActive}`;
        }
        if(incomplete) {
            dataUrl += `&ShowIncomplete=${incomplete}`;
        }

        return (
            <XGrid
                label={label}
                innerRef={this.grdIR}
                usePrimaryColor={true}
                dataRoute={dataUrl}
                dataRouteColumns="/Base/GetColumns?grid=tblIR"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIR"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIR"
                dataRouteDelete={"/IR/DeleteIR/"}
                onDoubleClick={this.handleGridDoubleClick}
                onEditButton={this.handleGridDoubleClick}
                onNewButton={this.handleGridNew}
                rowExpandFormat={this.rowExpandFormat}
                cardWidth={4}
                cardHeight={4.5}
                showChangeView={true}
                showLoader
				showImgColumn={true}
            />
        );
    }
}


const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
);


export default enhance(Identities);