import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import withStyles from 'react-jss';


const cellStyle = {
    root: {
        minWidth: props => props.width,
        maxWidth: props => props.width,
        maxHeight: "42px",
        lineHeight: "21px",
        display: "inline-block",
        overflow: "hidden",
        verticalAlign: "middle",
        color:'#fff',
        "&:last-child": {
            //minWidth: props => "calc(100% - " + props.totalWidth + "px)",
            maxWidth: props => "calc(100% - " + props.totalWidth + "px)",
            width: "100%",
        },
        paddingLeft: 12
    },
    inputRoot: {
        color: '#fff',
        padding: '5px 9px',
    }
};

class XGridFilterCell extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value,
			filterChange: false,
		};
	}
    //componentDidMount() {
    //    this.setState({ value: this.props.value });
    //}
    componentDidUpdate(props) {
        if (props.value !== this.state.value && !this.state.filterChange) {
            this.setState({ value: props.value });
        }
    }
    onChange = (event) => {
        this.setState({ value: event.target.value, filterChange:true });
    }
    onKeyDown = (event) => {
        var { column, classes, value, filterHandler } = this.props;
        if (event.keyCode === 13 && this.state.filterChange) {
            this.setState({ filterChange: false }, () => {
                var evt = {
                    target: {
                        value: this.state.value
                    }
                }
                filterHandler(column.key)(evt);
            });
        }
    }
    onBlur = (event) => {
        var { column, classes, value, filterHandler } = this.props;
        if (this.state.filterChange) {
            this.setState({ filterChange: false }, () => {
                var evt = {
                    target: {
                        value: this.state.value
                    }
                }
                filterHandler(column.key)(evt);
            });
        }
    }
    render() {
        var { column, classes, filterHandler } = this.props;
        var { value } = this.state;
       
        return (
            <span className={classes.root} key={"srch" + column.key}>
                {column.filterable &&
                    (<TextField id={"srch" + column.key}
                        variant="filled"
                        fullWidth={true}
                        margin="none"
                        value={value}
                        onChange={this.onChange}
                        onKeyDown={this.onKeyDown}
                        onBlur={this.onBlur}
                    InputProps={{
                            disableUnderline: true,
                            classes: { input: classes.inputRoot }
                        }}
                        //classes={{ input: classes.inputRoot }}
                    />)}
            </span>
        );

    }
}
export default withStyles(cellStyle)(XGridFilterCell );