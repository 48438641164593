import React, { Component, Fragment } from 'react';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';

import axios, { CancelToken } from 'axios';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import XButton from 'components/XButton';
import XProgress from 'components/XProgress';
import XExpansionPanel from 'components/XExpansionPanel';
import Close from 'mdi-material-ui/Close';
import { handleErrors } from 'utils';
import RayStartArrow from 'mdi-material-ui/RayStartArrow';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
    },
});

class DOCGenerator extends Component {
    constructor(props) {
        super(props);
		
		this.state = {
			progress: 0,
			total: 0,
		}
	}
	doCheckGenerateDocument = () => {
		fetch('/DOC/CheckGenerateDocumentProgress').then(res => res.json()).then(data => {
            this.setState(state =>{
                state.progress = data.progress;
                state.total = data.total;
				
				if (state.total === state.progress)
					state.isGeneratingDoc = false;
				
				return state;
            });
        })
	}
	generateDocument = async(FIDCausal, FIDCycle, FIDDocumentType, FIDDocumentSubType) => {
		let { beforeGenerate } = this.props;
		
		let generate = true;
		
		if (beforeGenerate)
			generate = await beforeGenerate();
		
		var check = undefined
		if (this.props.isSavingDoc) {
			check = setInterval(() => {}, 100);
		} else if (check)
			window.clearInterval(check);
		
		if (!generate)
			return;
		
		this.setState({ isGeneratingDoc: true });//, () => {
		
		let DocID = this.props.DocID; 
		let onDocGenerated = this.props.onDocGenerated;
		let onDocGenerationError = this.props.onDocGenerationError;
		var checkGenerateDocLoop = setInterval(this.doCheckGenerateDocument, 30);
		fetch('/DOC/GenerateExternalDocument/'+DocID+'?FIDCycle='+FIDCycle+'&FIDDocumentType='+FIDDocumentType+'&FIDDocumentSubType='+FIDDocumentSubType+'&FIDCausal='+FIDCausal, {
			method: 'POST',
		}).then(handleErrors).then(res => res.json()).then(data => {
			window.clearInterval(checkGenerateDocLoop);
			this.setState({ isGeneratingDoc: false }, () => {
				if (data > 0) {
					onDocGenerated && onDocGenerated(data);
				} else {
					onDocGenerationError && onDocGenerationError();
				}
			});
		}).catch(error => {
			window.clearInterval(checkGenerateDocLoop);
			console.log(error);
			this.setState({ isGeneratingDoc: false }, () => {
				onDocGenerationError && onDocGenerationError();
			});
		});
		//});
	}
	
	render() {
		let { relatedCausals, isSavingDoc } = this.props;
		
		if (isSavingDoc)
			return <Fragment />;
		
		let causalButtons = relatedCausals.map((cau) => {
			return <Grid item xs={12} sm={6} md={3} style={{textAlign:"center"}}><XButton color="gray" style={{minWidth: 280}} onClick={async() => {
				await this.generateDocument(cau.FIDCausal, cau.FIDCycle, cau.FIDDocumentType, cau.FIDDocumentSubtype);
			}}>{"GENERA " + cau.Causal}</XButton></Grid>
		});	
		
		let panels = [];
        panels.push({
            icon: <RayStartArrow />,
            label: "Generazione documento collegato",
            content: <Grid container xs={12}>{causalButtons}</Grid>
        });
        // panels.push({
        //     icon: <Database />,
        //     label: "Dati quadratura",
        //     content: this.renderPanelChecks(),
        // });

        var ui = <XExpansionPanel panels={panels} />;
		
		return <Fragment>
			{ui}		
			<XProgress progress={this.state.progress} total={this.state.total} label="Generazione documento" show={this.state.isGeneratingDoc} />
		</Fragment>
	}
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(DOCGenerator);