import {
    grayColor,
    primaryColor,
    dangerColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const customTextFieldStyle = {

    textField: {
        //marginLeft: theme.spacing.unit,
        //marginRight: theme.spacing.unit,
        marginTop: 8,
        width: "100%",
        "& > div": {
            "&:before": {
                borderBottomWidth: "1px !important",
                borderBottomColor: "#D2D2D2 !important"
            },
            "&:after": {
                borderBottomColor: primaryColor + ""
            }
        },
        "& input": {
          borderLeft: "0 !important",
          borderRight: "0 !important",
          borderTop: "0 !important",
          padding: "6px 0 7px",
          color: grayColor,
          marginTop: 0
        },
		"& button.MuiIconButton-root": {
			/*paddingBottom: 0,*/
			position: "relative",
			bottom: 3,
			paddingBottom: 0
		}
    },
    error: {
        "&:after": {
            borderBottomColor: dangerColor + "!important"
        }
    },
    errorOptional: {
        "&:after": {
            borderBottomColor: primaryColor + "!important"
        }
    },
    marginNormal: {
        marginTop: "8px",
    },
    textFieldLabel: {
        fontSize: "12px",
        //textTransform: "uppercase",
        color: "#3C4858 !important",
        top: "8px"
    },
    textFieldLabelTop: {
        fontSize: "12px",
        //textTransform: "uppercase",
        color: "#3C4858 !important",
        top: "0"
    },
    textFieldInput: {
        padding: "11px 0 0",
        fontSize: ".75rem",
        color: "#3C4858",
        "& b": {
            display: "none"
        }
    }
};

export default customTextFieldStyle;
