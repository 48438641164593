import React, { Component, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CardMedia from '@material-ui/core/CardMedia';
import XGalleryThumbs from 'components/XGalleryThumbs';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import withStyles from 'react-jss';
import withWidth from '@material-ui/core/withWidth';

import ReactImageMagnify from 'react-image-magnify';
import { compose } from 'redux';
import { PanZoom } from 'react-easy-panzoom'


const styles = {
    media: {
        height: 0,
        //paddingTop: 'calc(56.25% / 1.5)', // 16:9
        paddingTop: props => props.bigImage ? '100%' : '56%',
        maxHeight: '80vh',
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)',
				backgroundSize: props => props.bigImage ? 'cover' : 'contain',
    },
    mediaButtonLeft: {
        position: "absolute",
        left: "0",
        top: "50%",
    },
    mediaButtonRight: {
        position: "absolute",
        right: "0",
        top: "50%",
    },
    zoom: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: window.innerHeight - 80 - 60,
        zIndex: 1099,
        background: '#fff',
    },
    closeButton: {
        position: "absolute",
        right: 13,
        top: 5,
        zIndex: 1099,
    },
    zoomImage: {
        maxWidth: '100%',
        maxHeight: window.innerHeight - 80 - 60,
        width: 'auto',
        height: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',
    }
};


class XGallery extends Component {
    constructor(props) {
        super(props);

        this.Gallery = React.createRef();
    }
    state = {
        imageIndex: 0,
        height: 0,
        viewZoom: false,
        imgO:{},
        imgS:{}
    };
    componentDidMount() {
        //React.lazy(() => import('/Base/Image/1'));
        
        var height = 300;
        if (this.Gallery.current) {
            height = this.Gallery.current.rootNode.clientHeight;
        }
        this.setState({ height });
    }
    componentWillUnmount() {
        var mc = document.getElementById('mainContent');
		
        if (mc && mc.style) mc.style.overflow = null;
    }
    handleNext = () => {

        var { imageIndex } = this.state;
        var { imageIds } = this.props;
        //var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = imageIds.length;
        if (imageIndex < imagesCount - 1) {
            this.setState({ imageIndex: imageIndex + 1 });
        }
        else {
            this.setState({ imageIndex: 0 });
        }
    }
    handlePrevious = () => {
        var { imageIndex } = this.state;
        var { imageIds } = this.props;
        //var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = imageIds.length;
        if (imageIndex > 0) {
            this.setState({ imageIndex: imageIndex - 1 });
        }
        else {
            this.setState({ imageIndex: imagesCount - 1 });
        }
    }
    handleChangeIndex = (index) => {
        this.setState({ imageIndex: index });
    }
    onMediaClick =  async () => {
        if(this.props.noZoom){ 
            return;
        }
        var{ imageIndex} = this.state;
        var { imageIds } = this.props;

        var imgO = new Image();
        var setState = this.setState;
        imgO.onload = (ev) =>{
            var img = ev.target;
            if(!img && ev.path[0]){
                img = ev.path[0];
            }
            this.setState({imgO:{w: img.width,h: img.height}});
        }
        imgO.src = `/Base/Image/${imageIds[imageIndex]}`
        var imgS = new Image();
        imgS.onload = (ev) =>{
            var img = ev.target;
            if(!img && ev.path[0]){
                img = ev.path[0];
            }
            this.setState({imgS:{w: img.width,h: img.height}});
        }
        imgS.src = `/Base/Image/${imageIds[imageIndex]}?fileSize=Small`;

        // this.setState({ viewZoom: true,  }, () => {
        //     var mc = document.getElementById('mainContent');
        //     mc.scroll(0, 0);
        //     mc.style.overflow = 'hidden';
        // });
       
    }
    onCloseZoom = () => {
        this.setState({ viewZoom: false }, () => {
            
            var mc = document.getElementById('mainContent');
            mc.style.overflow = null;
        });
    }
    

    render() {
        var { classes, imageIds,imagePaths, width, bigImage, hideThumbs } = this.props;
        var { imageIndex, height, viewZoom } = this.state;

        var maxH = window.innerHeight - 80 - 60;
        var maxW = window.innerWidth - 300;

        var sH = this.state.imgS.h;
        var sW = this.state.imgS.w;
        var oH = this.state.imgO.h;
        var oW = this.state.imgO.w;
        if(sH > sW){//portrait
            sW = maxH * sW / sH;
            sH = maxH;
            if(sW > maxW){
                sH = maxW * sH / sW;
                sW = maxW;
            }
        }
        else {//landscape
            sH = maxW * sH / sW;
            sW = maxW;
            if(sH > maxH){
                sW = maxH * sW / sH;
                sH = maxH;
            }
        }
        if(oH < sH || oW < sW){
            oH = sH;
            oW = sW;
        }
        if(!imageIds){
            imageIds = [];
        }
        var hasImgIds = true;
        var mediaFiles = imageIds.length > 0 ? imageIds.map((file, index) => (<CardMedia onClick={this.onMediaClick} key={index} className={classes.media} image={"/Base/Image/" + file + "?fileSize=Large"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        var imgCount = imageIds.length;
        if(imagePaths && imagePaths.length > 0){
            mediaFiles = imagePaths.map((file,index) => (<CardMedia onClick={this.onMediaClick} key={index} className={classes.media} image={file} />));
            imgCount = imagePaths.length;
            hasImgIds = false;
        }


        return (
            <Grid container xs={12}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                    <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex} ref={this.Gallery}>
                        {mediaFiles}
                    </SwipeableViews>
                    {imgCount > 1 &&
                        (
                            <Fragment>
                                <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                                <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                            </Fragment>
                        )
                    }
                </Grid>
                {!hideThumbs && imgCount > 1 &&
                    <Grid item xs={12}>
											<XGalleryThumbs imageIds={imageIds} imagePaths={imagePaths} bigImage={bigImage} height={height} onChange={this.handleChangeIndex} />
                    </Grid>
                }
                {viewZoom &&
                    (
                        <div className={classes.zoom}>
                            <div className={classes.closeButton} >
                                <IconButton onClick={this.onCloseZoom}>
                                    <CloseIcon />
                                </IconButton>
                        </div>
                        {/* <PanZoom autoCenter={true} autoCenterZoomLevel={1} maxZoom={4} minZoom={1} enableBoundingBox={true} boundaryRatioHorizontal={0.8} boundaryRatioVertical={0.8} realPinch={true}>
                            <img className={classes.zoomImage} src={"/Base/Image/" + imageIds[imageIndex]} alt="" />
                        </PanZoom> */}
                        <ReactImageMagnify style={{margin: 'auto'}} enlargedImagePosition='over' {...{
                            smallImage:{
                                src: hasImgIds ? `/Base/Image/${imageIds[imageIndex]}` : imagePaths[imageIndex],
                                isFluidWidth: false,
                                width: sW,
                                height: sH,
                            },
                            largeImage: {
                                //src: `/Base/Image/${imageIds[imageIndex]}`,
                                src: hasImgIds ? `/Base/Image/${imageIds[imageIndex]}` : imagePaths[imageIndex],
                                width: oW,
                                height: oH
                            }
                        }} />
                        </div>
                    )
                }
            </Grid>
        );
    }
}



const enhance = compose(
    withWidth(),
    withStyles(styles)
);


export default enhance(XGallery);
