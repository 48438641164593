import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import XOXLoader from '../../components/XOXLoader';
import XMessageBox from 'components/XMessageBox';
import XProductionTimeLabel from '../../components/XProductionTimeLabel';
import XProductionButton from '../../components/XProductionButton';
import ProductionBOP from './ProductionBOP';
import ProductionOperatorAssignedBOPS from './ProductionOperatorAssignedBOPS';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { bindActionCreators, compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const vertCentered = {
	position: "absolute",
	top: "50%",
	transform: "translateY(-50%)",
}
const styles = theme => ({
	cardWrapper: {
		maxHeight: 'calc(100vh - 60px)',
		minHeight: 'calc(100vh - 60px)',
		//overflow: 'hidden',
		borderRadius: 1,
		marginBottom: 0,
		marginTop: 0
	},
	cardBody: {
		maxHeight: 'calc(100% - 56px)',
		overflowY: 'hidden',
		paddingTop: 0,
		paddingBottom: 0
	},
	mainWrapper: {
		overflowY: "auto",
		position: "relative",
		height: "100%",
		"& .moveUp": {
			top: "-10%"
		} 
	},
	leftColumnTimes: {
		width: "25%",
		height: 'calc(100vh - 70px)',
		display: "inline-block",
		position: "relative",
		"& > div": {
			position: "absolute",
			top: "50%",
			transform: "translateY(-50%)",
			height: "auto",
			width: "75%",
			overflowY: "auto"
		}
	},
	rightColumn: {
		display: "inline-block",
		width: "75%",
		height: 'calc(100vh - 70px)',
		verticalAlign: "top",
	},
	welcomeLblWrapper: {
		textAlign: "center",
		margin: "15px auto",
		position: "absolute",
		top: "0",
		left: "0",
		width: "100%",
		"& > p": {
			textTransform: "uppercase",
			fontSize: "28px"
		}
	},
	lblCenterWrapper: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translateX(-50%) translateY(-50%)",
		textAlign: "center",
		"& > p": {
			textTransform: "uppercase",
			fontSize: "28px"
		}
	},
	buttonsWrapper: {
		"& button": {
			padding: "2.5vh",
			//margin: "10px 5% 0",
			fontSize: "5vh",
			whiteSpace: "normal",
			height: "25vh",
			width: "90%"
			//float: "left"
		},
		//"& > button:nth-child(3n + 3)": {
		//	clear: "left"
		//},
		//textAlign: "center",
		//width: "80%",
		margin: "auto",
		position: "absolute",
		top: "20%",
		//left: "25%",
		//transform: "translateX(-25%) translateY(-50%)"
	},
	singleBtnWrapper: {
		"& > button": {
			padding: "2.5vh",
			margin: "10px 5% 0",
			fontSize: "5vh",
			whiteSpace: "normal",
			width: "320px",
			height: "25vh",
			//float: "left"
		},
		//"& > button:nth-child(3n + 3)": {
		//	clear: "left"
		//},
		textAlign: "center",
		width: "80%",
		margin: "auto",
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translateX(-50%) translateY(-50%)"
	},
	vertCentered: vertCentered,
	noFloatBtn: {
		float: "none !important"
	},
	btnShowAssigned: {
		width: "90%",
		textAlign: "center",
		margin: "10px auto",
		left: "5%",
		height: "60px",
		fontSize: "15px"
	}
	/*mainContent: {
		marginTop: "7.5%"
	}*/
});

var activities = [];

class ProductionOperator extends Component {
	constructor(props) {
		super(props);
		this.MessageBox = React.createRef();
		this.state = {
			isLoading: true,
			activities: [],
			isWorking: false,
			isIdle: true,
			isBreak: false,
			isMeeting: false,
			activityStateToggled: false,
			operatorMsg: null,
			times: {
				production: 0,
				other: 0,
				total: 0
			},
			manageBOP: false,
			bop: null,
			bopTime: 0,
			bopTimeForceRefresh: false,
			prodRunning: false,
			idleTimeout: false,
			snackbar: {
				open: false,
				message: undefined,
			}
		};
	}
	componentDidMount = () => {
		fetch('Production/GetActivities').then(res => res.json())
			.then(data => {
				if (data.success) {
					activities = data.activities;
					this.checkOperator(activities);
				}
		});
	}
	checkOperator = (activities) => {
		fetch('Production/GetOperatorWorkingStatus').then(res => res.json())
			.then(data => {
				if (data.success) {
					var { isInFactory, isBreak, isMeeting, isProducing, bopsAssigned, msg } = data;
					var fetchStart = new Date();

					//if (isWorking || isBreak || isMeeting) {
					if (isInFactory) {
						fetch('Production/GetOperatorWorkTimes').then(res => res.json())
							.then(data => {
								if (data.success) {
									var fetchEnd = new Date();
									var fetchOffset = fetchEnd - fetchStart;
									var bop = data.currBopId && data.bopProduction ? data.bopProduction : false;
									var manageBOP = data.currBopId && data.bopProduction ? true : false;
									var times = data.times;
									if (fetchOffset >= 500) {
										if (times.totalTime != null)
											times.totalTime += parseInt(fetchOffset / 1000);
										if (times.breakMeetingTime != null && (isBreak || isMeeting))
											times.breakMeetingTime += parseInt(fetchOffset / 1000);
										else if (times.prodTime && isProducing) {
											times.prodTime += parseInt(fetchOffset / 1000);
											
											if (bop && bop.ProdTime) {
												bop.ProdTime += parseInt(fetchOffset / 1000);
											}
										}
									}

									this.setState({
										isLoading: false,
										activities: activities,
										isInFactory: true,
										isBreak: isBreak,
										isMeeting: isMeeting,
										isProducing: isProducing,
										bopsAssigned: bopsAssigned,
										operatorMsg: msg,
										//activityStateToggled: false,
										times,
										bop,
										manageBOP,
										bopTime: bop && bop.ProdTime ? bop.ProdTime : 0,
										prodRunning: isProducing && bop && bop.Running ? true : false,
										//times: {
										//	production: data.productionTime,
										//	other: data.breakMeetingTime,
										//	total: data.totalTime
										//},
										//manageBOP: false
									})
								}
							});
					}
					else {
						this.setState({
							operatorMsg: msg,
							isInFactory: false,
							isLoading: false,
							manageBOP: false
						})
					}
				}
				else {
					if (data.msg) {
						var buttons = [
							{
								label: "OK",
								action: () => {
									this.MessageBox.current.getWrappedInstance().hideDialog();
									this.setState({
										isLoading: false
									})
								}
							}
						];
						this.MessageBox.current.getWrappedInstance().showDialog(buttons, "", data.msg);
					}
				}
			})
	}
	workEntrance = () => {
		var { logout } = this.props;
		var welcomeMsg = this.state.operatorMsg;
		fetch('Production/OperatorFactoryEntrance', {
			method: "POST",
			//body: JSON.stringify({ activityType: actType })
		}).then(res => res.json())
			.then(data => {
				if (data.success) {
					this.setState({
						activityStateToggled: true,
						operatorMsg: data.msg,
						snackbar: { open: true, message: "Attività registrata con successo" }
					});

					setTimeout(() => {
						var fetchStart = new Date();
						fetch('Production/GetOperatorWorkTimes').then(res => res.json())
							.then(data => {
								if (data.success) {
									var fetchEnd = new Date();
									var fetchOffset = fetchEnd - fetchStart;
									var times = data.times;
									if (fetchOffset > 500) {
										times.totalTime++;
									}
									this.setState(state => {
										state.isInFactory = true;
										state.bopsAssigned = data.bopsAssigned;
										state.activities = activities;
										state.activityStateToggled = false;
										state.operatorMsg = welcomeMsg;
										state.times =  times;
										if (this.props.params.ConfigProduction && this.props.params.ConfigProduction.Autologout) { 
											let logoutTimeMS = (this.props.params.ConfigProduction.AutologoutTime||5)*1000;
											state.idleTimeout = setTimeout(() => {
											logout && logout();
											}, logoutTimeMS);
										}
										
										return state;
									})
								}
								else {
									if (data.msg) {
										var buttons = [
											{
												label: "OK",
												action: () => {
													this.MessageBox.current.getWrappedInstance().hideDialog();
												}
											}
										];
										this.MessageBox.current.getWrappedInstance().showDialog(buttons, "", data.msg);
									}
								}
							})
					}, 3000);
				}
				else {
					if (data.msg) {
						var buttons = [
							{
								label: "OK",
								action: () => {
									this.MessageBox.current.getWrappedInstance().hideDialog();
								}
							}
						];
						this.MessageBox.current.getWrappedInstance().showDialog(buttons, "", data.msg);
					}
				}
			})
	}
	workExit = () => {
		var { onOperationCompleted } = this.props;
		var welcomeMsg = this.state.operatorMsg;
		fetch('Production/OperatorFactoryExit', {
			method: "POST",
			//body: JSON.stringify({ activityType: actType })
		}).then(res => res.json())
			.then(data => {
				if (data.success) {
					this.setState({
						isInFactory: false,
						isProducing: false,
						isBreak: false,
						isMeeting: false,
						activityStateToggled: true,
						operatorMsg: data.msg,
						snackbar: { open: true, message: "Attività registrata con successo" }
					}, onOperationCompleted);

					setTimeout(() => {
						this.setState({
							activityStateToggled: false,
							operatorMsg: welcomeMsg
						})
					}, 3000);
				} else if (data.msg) {
					var buttons = [
						{
							label: "OK",
							action: () => {
								this.MessageBox.current.getWrappedInstance().hideDialog();
							}
						}
					];
					this.MessageBox.current.getWrappedInstance().showDialog(buttons, "", data.msg);
				}
			})
	}
	startInterruptActivity = (actType) => {
		var { onOperationStart, onOperationCompleted } = this.props;
		onOperationStart && onOperationStart();
		var dataSaved = false;
		var timesUpdated = false;
		fetch('Production/StartInterruptActivity/'+actType, {
			method: "POST",
			//body: JSON.stringify({ activityType: actType })
		}).then(res => res.json())
			.then(data => {
				if (data.success) {
					dataSaved = true;
					var { isBreak, isMeeting, bopsAssigned, msg } = data;
					var { operatorMsg } = this.state;
					fetch('Production/GetOperatorWorkTimes').then(res => res.json())
						.then(data => {
							if (data.success) {
								timesUpdated = true;
								this.setState({
									//activities: this.state.activities,
									times: data.times,
									isProducing: false,
									isBreak: isBreak,
									isMeeting: isMeeting,
									bopsAssigned: false,
									activityStateToggled: true,
									operatorMsg: msg,
									manageBOP: false,
									bopOrderRowCode: undefined,
									snackbar: { open: true, message: "Attività registrata con successo" }
								}, onOperationCompleted);
							}
						})
					
					if (!this.props.params.ConfigProduction || !this.props.params.ConfigProduction.Autologout) {
						var interval = setInterval(() => {
					//	if (dataSaved && timesUpdated) {
								this.setState({
									activityStateToggled: false,
									operatorMsg: operatorMsg
								}, onOperationCompleted)
								clearInterval(interval);
					//	}
						}, 2500);
					}
				}
				else {
					if (data.msg) {
						var buttons = [
							{
								label: "OK",
								action: () => {
									this.MessageBox.current.getWrappedInstance().hideDialog();
									this.setState({
										isLoading: false
									})
								}
							}
						];
						this.MessageBox.current.getWrappedInstance().showDialog(buttons, "", data.msg);
					}
				}
			})
	}

	stopInterruptProdStart = () => {
		fetch('Production/StartProductionActivity', {
			method: "POST",
			//body: JSON.stringify({ activityType: actType })
		}).then(res => res.json())
			.then(data => {
				if (data.success) {
					this.toggleProductionBOP();
				}
			});
	}

	toggleProductionBOP = () => {
		var { onOperationStart, onOperationCompleted } = this.props;
		onOperationStart && onOperationStart();
		var { isBreak, isMeeting, idleTimeout } = this.state;

		if (idleTimeout)
			clearTimeout(idleTimeout);

		if (isBreak || isMeeting) {
			fetch('Production/FinishInterruptActivity', {
				method: "POST",
				//body: JSON.stringify({ activityType: actType })
			}).then(res => res.json())
				.then(data => {
					if (data.success) {
						var fetchStart = new Date();
						fetch('Production/GetOperatorWorkTimes').then(res => res.json())
							.then(data => {
								if (data.success) {
									var fetchEnd = new Date();
									var fetchOffset = fetchEnd - fetchStart;
									var bop = data.currBopId && data.bopProduction ? data.bopProduction : false;
									var manageBOP = true;//data.currBopId && data.bopProduction ? true : false;
									var times = data.times;
									if (fetchOffset >= 500) {
										if (times.totalTime != null)
											times.totalTime += parseInt(fetchOffset / 1000);
										//if (times.breakMeetingTime != null && (isBreak || isMeeting))
										//	times.breakMeetingTime += parseInt(fetchOffset / 1000);
										if (times.prodTime && manageBOP)
											times.prodTime += parseInt(fetchOffset / 1000);
									}
									this.setState({
										//activities: this.state.activities,
										times: times,
										isProducing: data.currBopId > 0,
										bopsAssigned: data.bopsAssigned,
										isBreak: false,
										isMeeting: false,
										bop,
										manageBOP,
										snackbar: { open: true, message: "Attività registrata con successo" },
									}, onOperationCompleted);
								}
							})
					}
				})
		}
		else {
			this.setState({
				isBreak: false,
				isMeeting: false,
				manageBOP: !this.state.manageBOP,
				bopTime: 0,
				bopProduction: false,
				bop: null,
				bopOrderRowCode: undefined
			});
		}
	}

	getBOPProdTime = (bopProduction, operatorTimes) => {
		this.setState({
			isLoading: false,
			times: operatorTimes,
			bop: bopProduction,
			bopTime: bopProduction.ProdTime,
			bopTimeForceRefresh: false,
			prodRunning: bopProduction.Running
		})
	}

	startProductionTime = () => {
		var { onOperationStart, onOperationCompleted } = this.props;
		onOperationStart && onOperationStart();

		if (onOperationCompleted) {
			this.setState({
				isBreak: false,
				isMeeting: false,
				isProducing: true,
				prodRunning: true,
				snackbar: { open: true, message: "Attività registrata con successo" }
			}, onOperationCompleted)
		} else {
			this.setState({
				isBreak: false,
				isMeeting: false,
				isProducing: true,
				prodRunning: true,
				snackbar: { open: true, message: "Attività registrata con successo" }
			})
		}
	}

	stopProductionTime = (bopTime, operatorTimes) => {
		var { onOperationStart, onOperationCompleted } = this.props;
		onOperationStart && onOperationStart();

		if (onOperationCompleted) {
			this.setState({
				bop: bopTime === 0 ? null : this.state.bop,
				bopTime: bopTime,
				times: operatorTimes,
				isProducing: false,
				prodRunning: false,
				snackbar: { open: true, message: "Attività registrata con successo" }
			}, onOperationCompleted)
		} else {
			this.setState({
				bop: bopTime === 0 ? null : this.state.bop,
				bopTime: bopTime,
				times: operatorTimes,
				isProducing: false,
				prodRunning: false,
				snackbar: { open: true, message: "Attività registrata con successo" }
			});
		}
	}
	//resumeProduction = () => {
	//	var { onOperationCompleted } = this.props;
	//	fetch('Production/StartProductionActivity', {
	//		method: "POST",
	//		//body: JSON.stringify({ activityType: actType })
	//	}).then(res => res.json())
	//		.then(data => {
	//			if (data.success) {
	//				fetch('Production/GetOperatorWorkTimes').then(res => res.json())
	//					.then(data => {
	//						if (data.success) {
	//							this.setState({
	//								times: data.times
	//							});
	//							this.startProductionTime();
	//						}
	//					})
	//			}
	//		})
	//}
	resetProduction = () => {
		var { onOperationStart, onOperationCompleted } = this.props;
		onOperationStart && onOperationStart();

		//if (onOperationCompleted) {
		//	this.setState({
		//		bop: false,
		//		bopTime: 0,
		//		bopTimeForceRefresh: true,
		//		prodRunning: false
		//	}, onOperationCompleted);
		//} else {
			this.setState({
				bop: false,
				bopTime: 0,
				bopTimeForceRefresh: true,
				prodRunning: false,
				bopOrderRowCode: undefined
			});
		//}
	}

	showAssignedBOPS = () => {
		this.setState({
			showAssignedBOPS: true,
			manageBOP: true,
			bopOrderRowCode: undefined,
			bop: false
		})
	}

	hideAssignedBOPS_BackToHome = () => {
		this.setState({
			showAssignedBOPS: false,
			manageBOP: false,
			bopOrderRowCode: undefined
		})
	}

	removeLoader = () => {
		this.setState({
			isLoading: false
		});
	}

	bopScanFailed = (messageText, showAssigned) => {
		if (this.MessageBox.current.getWrappedInstance() && messageText) {
			this.MessageBox.current.getWrappedInstance().showMessageOk("RICERCA COMMESSE", messageText, () => { this.bopNotFound(showAssigned) });
		}
		else
			this.bopNotFound(showAssigned)
	}

	bopNotFound = (showAssigned) => {
		fetch('Production/GetOperatorWorkTimes').then(res => res.json())
			.then(data => {
				if (data.success) {
					this.setState({
						isLoading: false,
						times: data.times,
						bopOrderRowCode: undefined,
						showAssignedBOPS: showAssigned
					})
				}
			})
	}

	renderButtons = (isProducing, isBreak, isMeeting, bopsAssigned) => {
		var { classes } = this.props;
		var { activities } = this.state;
		return (
			<Grid container className={classes.buttonsWrapper}>
				{activities.map(function (act, index) {
					if (act.isMainJob)
						return (<Fragment><Grid item xs={12} sm={6}><XProductionButton color="red" onClick={this.workExit}>USCITA</XProductionButton></Grid></Fragment>);

					if (act.isProduction) {
						if (!isProducing) {
							//if (!bopsAssigned)
								return <Fragment><Grid item xs={12} sm={6}><XProductionButton color="green" onClick={/*isBreak || isMeeting ? this.stopInterruptProdStart :*/ this.toggleProductionBOP}>PRODUZIONE</XProductionButton></Grid></Fragment>;
							//else
							//	return <XProductionButton color="green" onClick={this.resumeProduction}>RIPRENDI PRODUZIONE</XProductionButton>;
						}
						else
							return <Fragment><Grid item xs={12} sm={6}><XProductionButton color="green" onClick={this.toggleProductionBOP}>GEST. COMMESSE</XProductionButton></Grid></Fragment>;
					} else {
						var clickCallback = () => this.startInterruptActivity(act.id);
						if (act.code === "B") {

							if (isBreak)
								return;

							return <Fragment><Grid item xs={12} sm={6}><XProductionButton color="veryLightGray" onClick={clickCallback}>INIZIO PAUSA</XProductionButton></Grid></Fragment>;
						} else {

							if (isMeeting)
								return;

							return <Fragment><Grid item xs={12} sm={6}><XProductionButton color="veryLightGray" onClick={clickCallback}>INIZIO RIUNIONE</XProductionButton></Grid></Fragment>;
						}
					}
				}, this)}
			</Grid>
			)
		}
	snackbarClose = () => {
		this.setState(state => {
				state.snackbar.open = false;
				return state;
		});
	}

	renderSnackbar() {
		var { snackbar } = this.state;
		return <Snackbar
				open={snackbar.open}
				onClose={this.snackbarClose}
				TransitionComponent={props => <Slide {...props} direction="up" />}
				ContentProps={{
						'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">{snackbar.message}</span>}
				autoHideDuration={1500}
		/>;
	}

	showAssignedBOP = (bopData) => {
		if (bopData && bopData.SubOrderRowCode) {
			this.setState({
				bopOrderRowCode: bopData.SubOrderRowCode,
				manageBOP: true,
				showAssignedBOPS: false,
			})
		}
	}

	render = () => {
		var { classes } = this.props;
		var { isLoading, isInFactory, isProducing, isBreak, isMeeting, bopsAssigned, activityStateToggled, operatorMsg, times, manageBOP, bop, bopTime, bopTimeForceRefresh, prodRunning, showAssignedBOPS, bopOrderRowCode } = this.state;

		if (isLoading) {
			return (<Fragment><XOXLoader /><XMessageBox ref={this.MessageBox} /></Fragment>);
		}

		

		if (!isInFactory) {
			return (
				<Card className={classes.cardWrapper}>
					<CardBody className={classes.cardBody}>
						<div className={classes.welcomeLblWrapper}><Typography>{operatorMsg}</Typography></div>
						{!activityStateToggled && <div className={classes.singleBtnWrapper}><XProductionButton className={classes.noFloatBtn} color="green" onClick={this.workEntrance}>INIZIO LAVORO</XProductionButton></div>}
						<XMessageBox ref={this.MessageBox} />
						{this.renderSnackbar()}
					</CardBody>
				</Card>)
		}

		var buttons = this.renderButtons(isProducing, isBreak, isMeeting, bopsAssigned);

		var prodTime = 0, otherTime = 0, totalTime = 0;

		if (times) {
			prodTime = times.productionTime;
			otherTime = times.breakMeetingTime;
			totalTime = times.totalTime;
		}

		if (!bopTime || !isProducing)
			bopTime = 0;

		if (otherTime == null && totalTime == null) {
			manageBOP = true;
		}

		return (<Card className={classes.cardWrapper}>
			<CardBody className={classes.cardBody}>
				<div className={classes.leftColumnTimes}><div>
					<XProductionTimeLabel label="Commessa" time={bopTime} running={(isProducing&&prodRunning)} forceRefresh={bopTimeForceRefresh} />
					{prodTime != null && <XProductionTimeLabel label="Produzione" time={prodTime} running={isProducing} />}
					{otherTime != null && <XProductionTimeLabel label="Altro" time={otherTime} running={isBreak || isMeeting} />}
					{totalTime != null && <XProductionTimeLabel label="Fabbrica" time={totalTime} running={isInFactory} />}
					{isInFactory && !showAssignedBOPS && <XProductionButton className={classes.btnShowAssigned} color="veryLightGray" onClick={this.showAssignedBOPS}>COMMESSE<br/> ASSEGNATE</XProductionButton>}
					{showAssignedBOPS && <XProductionButton className={classes.btnShowAssigned} color="veryLightGray" onClick={this.hideAssignedBOPS_BackToHome}>PAGINA INIZIALE</XProductionButton>}
				</div></div>
				{!manageBOP && 
					(!activityStateToggled ? <div className={classes.welcomeLblWrapper}><Typography>{operatorMsg}</Typography></div>
						: <div className={classes.lblCenterWrapper}><Typography>{operatorMsg}</Typography></div>)
				}
				<div className={classes.rightColumn}>
					<div className={classes.mainWrapper}>
							{!activityStateToggled && !manageBOP && <div>{buttons}</div>}
							{showAssignedBOPS ?
								<ProductionOperatorAssignedBOPS onAssignedBOPClick={this.showAssignedBOP} />
							: manageBOP && <div className={classes.mainContent}><div className={!bop ? classes.welcomeLblWrapper + " moveUp" : classes.welcomeLblWrapper}><Typography>{operatorMsg}</Typography></div>
								<ProductionBOP orderRowCode={bopOrderRowCode} bopProduction={bop} bopsAssigned={bopsAssigned} onShowAssignedBOPS={this.showAssignedBOPS} onExit={this.toggleProductionBOP} onNewBopClick={this.resetProduction} onBopScanning={() => this.setState({ isLoading: true })} onBopScanned={this.getBOPProdTime} onBopScannedFail={this.bopScanFailed} onProductionStart={this.startProductionTime} onProductionEnd={this.stopProductionTime} />
							</div>}
					</div>
				</div>
				<XMessageBox ref={this.MessageBox} />
				{this.renderSnackbar()}
			</CardBody></Card>);
	}
}

const enhance = compose(
	connect(
		state => state.auth,
		dispatch => bindActionCreators(actionCreators, dispatch)
	),
	connect(
		state => state.global,
		dispatch => bindActionCreators(globalCreators, dispatch)
	),
	withStyles(styles)
);

export default enhance(ProductionOperator);