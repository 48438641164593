import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Tabs from '../components/CustomTabs/CustomTabs';
import XButton from 'components/XButton';
import XMessageBox from 'components/XMessageBox';
import XProgress from 'components/XProgress';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { asyncForEach } from 'utils';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}


class ImportBOP extends Component {
    constructor(props) {
        super(props);
        this.MessageBox = React.createRef();
		this.state = {
        selectedFile: null,
        selectedFiles: [],
        selectedFilePricelists: null,
        selectedFileStores: null,
        selectedFileExtras: null,
        selectedFileDescriptions: null,
        loaded: 0,
        loadedImages: 0,
        loadedPricelists: 0,
        loadedStores: 0,
        loadedExtras: 0,
        loadedDescriptions: 0,
        fields: [],
        tables: [],
        mapping: {},
        progress: 0,
        total: 100,
        isImporting: false,
        stopImporting: false,
        isImportingImages: false,
        stopImportingImages: false,
        isUpdatingPrices: false,
        stopUpdatingPrices: false,
        isUpdatingStores: false,
        stopUpdatingStores: false,
        isUpdatingExtras: false,
        stopUpdatingExtras: false,
        syncPhase: "",
        isSync: false,
			stopSync: false,
			platforms: []
		}
    }
	componentDidMount = async() => {
		if (!this.props.params.WoocommercePlatformId && this.props.params.WooCommercePlatforms && this.props.params.WooCommercePlatforms.length > 0) {
			var platforms = [];
			await asyncForEach(this.props.params.WooCommercePlatforms, undefined, async(plt) => {
				if (plt > 0) {
					var platform = await fetch('/gc/platform/'+plt).then(res => res.json());
					platforms.push(platform);
				}
			});
			this.setState({platforms});
		}
    }
    cancelImport = () => {
        if (this.interval)
            window.clearInterval(this.interval);
        //this.setState({ isImporting: false, stopImporting: true });
        fetch('/BOP/CancelImport', { method: "POST" }).then(res => res.text()).then(data => { this.setState({ progress: 100, total: 100, stopImporting: true, isImporting: false }) })
    }
    cancelImportImages = () => {
        if (this.interval)
            window.clearInterval(this.interval);
        //this.setState({ isImporting: false, stopImporting: true });
        fetch('/BOP/CancelImportImages', { method: "POST" }).then(res => res.text()).then(data => { this.setState({ progress: 100, total: 100, stopImportingImages: true, isImportingImages: false }) })
    }
    cancelUpdatePricelists = () => {
        if (this.interval)
            window.clearInterval(this.interval);
        //this.setState({ isImporting: false, stopImporting: true });
        fetch('/BOP/CancelUpdatePrices', { method: "POST" }).then(res => res.text()).then(data => { this.setState({ progress: 100, total: 100, stopImportingPrices: true, isImportingPrices: false }) })
    }
    cancelUpdateStores = () => {
        if (this.interval)
            window.clearInterval(this.interval);
        //this.setState({ isImporting: false, stopImporting: true });
        fetch('/BOP/CancelUpdateStores', { method: "POST" }).then(res => res.text()).then(data => { this.setState({ progress: 100, total: 100, stopUpdatingStores: true, isUpdatingStores: false }) })
    }
    cancelUpdateExtras = () => {
        if (this.interval)
            window.clearInterval(this.interval);
        fetch('/BOP/CancelUpdateExtras', { method: "POST" }).then(res => res.text()).then(data => { this.setState({ progress: 100, total: 100, stopUpdatingExtras: true, isUpdatingExtras: false }) })
    }
    cancelSync = () => {
        //this.setState({ isImporting: false, stopImporting: true });
        fetch('/api/catalog/stopsync', { method: "POST" }).then(res => res.text()).then(data => { this.setState({ progress: 100, total: 100, stopSync: true }) })
        var importBOP = this;
        if (importBOP.interval)
                window.clearInterval(importBOP.interval);
        setTimeout(function () {
            importBOP.setState({ syncPhase: "", isSync: false, progress: 0, total: 0, isSync: false });
        }, 2500);
    }
    handleselectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }
    handleselectedFilePricelists = event => {
        this.setState({
            selectedFilePricelists: event.target.files[0],
            loadedPricelists: 0,
        })
    }
    handleselectedFileStores = event => {
        this.setState({
            selectedFileStores: event.target.files[0],
            loadedStores: 0,
        })
    }
    handleselectedFileExtras = event => {
        this.setState({
            selectedFileExtras: event.target.files[0],
            loadedExtras: 0,
        })
    }
    handleselectedFileDescriptions = event => {
        this.setState({
            selectedFileDescriptions: event.target.files[0],
            loadedDescriptions: 0,
        })
    }
    handleGetColumns = () => {
        const data = new FormData();
        data.append('file', this.state.selectedFile, this.state.selectedFile.name);

        axios
            .post('/BOP/GetColumns', data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({
                        loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                    })
                },
            })
            .then(res => {
                var { data } = res;
                this.setState({ fields: data.columns, tables: data.tables });
            });
    }
    handleUpload = async() => {
        const data = new FormData()
        data.append('file', this.state.selectedFile, this.state.selectedFile.name)


        var json = await axios
            .post('/BOP/CheckImport', data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({
                        loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                    })
                },
            })
            .then(res => res.data);

        if (json) {
            var buttons = [
                {
                    label: "AGGIORNA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        this.doImport(data, true);
                    }
                },
                {
                    label: "SALTA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        this.doImport(data, false);
                    }
                },
                {
                    label: "ANNULLA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                    }
                }
            ];
            this.MessageBox.current.showDialog(buttons, "IMPORTAZIONE", "Rilevati codici distinte già esistenti. Cliccando 'AGGIORNA', le distinte saranno aggiornate con i nuovi dati. Come si desidera procedere?");
        } else {
            this.doImport(data);
        }
    }

    doCheck = () => {
        fetch('/BOP/CheckImportProgress').then(res => res.json()).then(data => { this.setState({ progress: data.progress, total: data.count, stopImporting: data.stopImporting }) })
    }

    doCheckImages = () => {
        fetch('/BOP/CheckImportImagesProgress').then(res => res.json()).then(data => { this.setState({ progress: data.progress, total: data.count, stopImportingImages: data.stopImporting }) })
    }

    doCheckUpdatePricelists = () => {
        fetch('/BOP/CheckUpdatePricelistsProgress').then(res => res.json()).then(data => { this.setState({ progress: data.progress, total: data.count, stopUdatingPrices: data.stopImporting }) })
    }

    doCheckUpdateStores = () => {
        fetch('/BOP/CheckUpdateStoresProgress').then(res => res.json()).then(data => { this.setState({ progress: data.progress, total: data.count, stopUdatingStores: data.stopImporting }) })
    }

    doCheckSync = (FIDPlatform) => {
        fetch('/api/catalog/checkprogress?FIDPlatform='+FIDPlatform).then(res => res.json()).then(data => { console.log(data); this.setState({ syncPhase: data.phase, progress: data.progress, total: data.count }) })
    }

    interval = undefined;
    doImport = async (data, replace) => {
        this.setState({ isImporting: true, progress: 0, total: 100, stopImporting: false }, async () => {
            this.interval = setInterval(this.doCheck, 500);
            await axios
                .post('/BOP/Import?replace=' + replace, data, {
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                        })
                    },
                })
                .then(res => {
                    console.log(res.statusText)
                });

            if (this.interval)
                window.clearInterval(this.interval);

            this.setState({ isImporting: false, progress: 0, total: 100 });
        });   
    }

    doImportImages = async (data, replace, append) => {
        var request = '/BOP/SetBOPImages';

        if (replace)
            request += '?replace=' + replace;

        if (append) {
            if (request.indexOf('?') > 0)
                request += '&';
            else
                request += '?';

            request += 'append=' + append;
        }

        this.setState({ isImportingImages: true, progress: 0, total: 100, stopImportingImages: false }, async () => {
            this.interval = setInterval(this.doCheckImages, 500);
            await axios
                .post(request, data, {
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            loadedImages: (ProgressEvent.loaded / ProgressEvent.total * 100),
                        })
                    },
                })
                .then(res => {
                    console.log(res.statusText)
                });

            if (this.interval)
                window.clearInterval(this.interval);

            this.setState({ isImportingImages: false, progress: 0, total: 100 });
        });
    }

    doUpdatePricelists = async (data) => {

        this.setState({ isUpdatingPrices: true, progress: 0, total: 100, stopUpdatingPrices: false }, async () => {
            this.interval = setInterval(this.doCheckUpdatePricelists, 150);
            await axios
                .post('/BOP/UpdatePricelists', data, {
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            loadedPricelists: (ProgressEvent.loaded / ProgressEvent.total * 100),
                        })
                    },
                })
                .then(res => {
                    console.log(res.statusText)
                });

            if (this.interval)
                window.clearInterval(this.interval);

            this.setState({ isUpdatingPrices: false, progress: 0, total: 100 });
        });
    }

    doUpdateStores = async (data) => {

        this.setState({ isUpdatingStores: true, progress: 0, total: 100, stopUpdatingStores: false }, async () => {
            this.interval = setInterval(this.doCheckUpdateStores, 150);
            await axios
                .post('/BOP/UpdateStores', data, {
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            loadedStores: (ProgressEvent.loaded / ProgressEvent.total * 100),
                        })
                    },
                })
                .then(res => {
                    console.log(res.statusText)
                });

            if (this.interval)
                window.clearInterval(this.interval);

            this.setState({ isUpdatingStores: false, progress: 0, total: 100 });
        });
    }

    doSync = (FIDPlatform) => {
        this.setState({ isSync: true, progress: 0, total: 100, stopSync: false }, async () => {
			this.interval = setInterval(() => { this.doCheckSync(FIDPlatform); }, 1000);

			await fetch('/api/catalog/sync?FIDPlatform='+FIDPlatform).then(res => res.json()).then(data => {
                console.log(data.result);
                //alert("Sincronizzazione effettuata");
                this.MessageBox.current.showMessageOk("SINCRONIZZAZIONE COMPLETATA", "Sincronizzazione effettuata. " + data.msg);
            });

            if (this.interval)
                window.clearInterval(this.interval);

            this.setState({ syncPhase:"", isSync: false, progress: 0, total: 0 });
        });
    }

    handleselectedFileImages = event => {
        this.setState({
            selectedFiles: event.target.files,
            loadedImages: 0,
        })
    }
    handleUploadImages = () => {
        const data = new FormData()
        for (var i = 0; i < this.state.selectedFiles.length; i++) {
            data.append('file', this.state.selectedFiles[i], this.state.selectedFiles[i].name)
        }

        var buttons = [
            {
                label: "SOSTITUISCI",
                action: () => {
                    this.MessageBox.current.hideDialog();
                    this.doImportImages(data, true, false);
                }
            },
            {
                label: "AGGIUNGI",
                action: () => {
                    this.MessageBox.current.hideDialog();
                    this.doImportImages(data, false, true);
                }
            },
            {
                label: "SALTA",
                action: () => {
                    this.MessageBox.current.hideDialog();
                    this.doImportImages(data, false, false);
                }
            },
            {
                label: "ANNULLA",
                action: () => {
                    this.MessageBox.current.hideDialog();
                }
            }
        ];
        this.MessageBox.current.showDialog(buttons, "IMPORTAZIONE IMMAGINI", "Cliccando 'SOSTITUISCI', le immagini esistenti saranno eliminate e sostituite con quelle importate. Come si desidera procedere?");

    }
    handleUploadPricelists = () => {
        const data = new FormData()

        data.append('file', this.state.selectedFilePricelists, this.state.selectedFilePricelists.name)

        if (data) {
            var buttons = [
                {
                    label: "AGGIORNA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        this.doUpdatePricelists(data);
                    }
                },
                {
                    label: "ANNULLA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                    }
                }
            ];
            this.MessageBox.current.showDialog(buttons, "AGGIORNAMENTO PREZZI", "Si desidera procedere con l'aggiornamento dei prezzi?");
        }
    }
    handleUploadStores = () => {
        const data = new FormData()

        data.append('file', this.state.selectedFileStores, this.state.selectedFileStores.name)

        if (data) {
            var buttons = [
                {
                    label: "AGGIORNA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        this.doUpdateStores(data);
                    }
                },
                {
                    label: "ANNULLA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                    }
                }
            ];
            this.MessageBox.current.showDialog(buttons, "AGGIORNAMENTO STORE/STORE AREA", "Si desidera procedere con l'aggiornamento degli store eCommerce?");
        }
    }

    handleUploadExtras = () => {
        const data = new FormData()

        data.append('file', this.state.selectedFileExtras, this.state.selectedFileExtras.name)
        if (data) {
            var buttons = [
                {
                    label: "AGGIORNA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        axios
                        .post('/BOP/UpdateExtras', data, {
                            onUploadProgress: ProgressEvent => {
                                this.setState({
                                    loadedExtras: (ProgressEvent.loaded / ProgressEvent.total * 100),
                                })
                            },
                        })
                        .then(res => {
                            console.log(res.statusText)
                        })
                    }
                },
                {
                    label: "ANNULLA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                    }
                }
            ];
            this.MessageBox.current.showDialog(buttons, "AGGIORNAMENTO CAMPI CUSTOM", "Si desidera procedere con l'aggiornamento dei campi custom?");
        }
    }
    handleUploadDescriptions = () => {
        const data = new FormData()

        data.append('file', this.state.selectedFileDescriptions, this.state.selectedFileDescriptions.name)
        axios
            .post('/BOP/UpdateBOPDescriptions', data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({
                        loadedDescriptions: (ProgressEvent.loaded / ProgressEvent.total * 100),
                    })
                },
            })
            .then(res => {
                console.log(res.statusText)
            })

    }
    handleSync = (FIDPlatform, PlatformUrl) => {
        var buttons = [
            {
                label: "SINCRONIZZA",
                action: () => {
                    this.MessageBox.current.hideDialog();
                    this.doSync(FIDPlatform);
                }
            },
            {
                label: "ANNULLA",
                action: () => {
                    this.MessageBox.current.hideDialog();
                }
            }
        ];
		if (PlatformUrl)
			this.MessageBox.current.showDialog(buttons, "SINCRONIZZAZIONE ECOMMERCE", "Sei sicuro di voler effettuare la sincronizzazione verso il sito " + PlatformUrl +" ?");
		else
        this.MessageBox.current.showDialog(buttons, "SINCRONIZZAZIONE ECOMMERCE", "Sei sicuro di voler effettuare la sincronizzazione verso il sito eCommerce?");
    }

    onTypeChange = (key) => (event, value) => {
        this.setState(state => {
            var newValue = undefined;
            if (value.length > 0) {
                newValue = value[0].id;
            }
            state.mapping[key] = newValue;
            return state;
        });
    }

    render() {
        //var { fields, tables, mapping } = this.state;
        var { classes } = this.props;

        //var tablesValues = tables.map((item) => {
        //    return { id: item.Name, value: item.Name }
        //});
        //var types = [
        //    { id: 1, value: "Field" },
        //    { id: 2, value: "Table" },
        //    { id: 3, value: "Custom field" },
        //];
        //var currentTable = mapping["currentTable"];
        //var currentFields = [];

        //var tableItem = tables.filter(item => item.Name === currentTable)[0];
        //if (tableItem) {
        //    currentFields = tableItem.Fields.map(item => { return { id: item, value: item } });
        //}

        var locTabs = [
            {
                tabName: "Importazione distinte",
                tabContent: (
                    <TabContainer>
                        <input type="file" name="" id="" onChange={this.handleselectedFile} />
                        {
                            //<button onClick={this.handleGetColumns}>Get columns</button>
                        }
                        <button onClick={this.handleUpload}>Upload</button>
                        <div>{this.state.loaded > 0 && this.state.loaded < 100 && "Caricamento..."}</div>
                        {
                            /*
                            <Grid container spacing={2}>
                                <Grid item container xs={12}>
                                    <Grid item xs={6} sm={3}>
                                        <XSelect value={currentTable} id="currentTable" customData={tablesValues} idKey="id" valueKey="value" onChange={this.onTypeChange} />
                                    </Grid>
                                </Grid>
                                {fields.map((item, key) => {
                                    var mappingType = mapping[item + "_type"];
                                    var mappingField = mapping[item + "_field"];
                                    var mappingTable = mapping[item + "_table"];
                                    var mappingTableField = mapping[item + "_tablefield"];
                                    var mappingTableFieldToGet = mapping[item + "_tablefieldtoget"];
                                    var mappingTableFieldToSet = mapping[item + "_tablefieldtoset"];
                                    var mappingTableFields = [];
                                    if (mappingType === 2 && mappingTable) {
                                        var mtableItem = tables.filter(item => item.Name === mappingTable)[0];
                                        if (mtableItem) {
                                            mappingTableFields = mtableItem.Fields.map(item => { return { id: item, value: item } });
                                        }
                                    }
                                    return (
                                        <Grid item container xs={12}>
                                            <Grid item xs={6} sm={3} md={2}>
                                                <span key={key}>{item}</span>
                                            </Grid>
                                            <Grid item xs={6} sm={3} md={2}>
                                                <XSelect value={mappingType} id={item + "_type"} customData={types} idKey="id" valueKey="value" onChange={this.onTypeChange} />
                                            </Grid>
                                            {mappingType === 1 && (
                                                <Grid item xs={6} sm={3} md={2}>
                                                    <XSelect value={mappingField} id={item + "_field"} customData={currentFields} idKey="id" valueKey="value" onChange={this.onTypeChange} />
                                                </Grid>
                                            )}
                                            {mappingType === 2 && (
                                                <Grid item xs={6} sm={3} md={2}>
                                                    <XSelect value={mappingTable} id={item + "_table"} customData={tablesValues} idKey="id" valueKey="value" onChange={this.onTypeChange} />
                                                </Grid>
                                            )}
                                            {mappingType === 2 && mappingTable && (
                                                <Fragment>
                                                    <Grid item xs={6} sm={3} md={2}>
                                                        <XSelect value={mappingTableField} id={item + "_tablefield"} customData={mappingTableFields} idKey="id" valueKey="value" onChange={this.onTypeChange} />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3} md={2}>
                                                        <XSelect value={mappingTableFieldToGet} id={item + "_tablefieldtoget"} customData={mappingTableFields} idKey="id" valueKey="value" onChange={this.onTypeChange} />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3} md={2}>
                                                        <XSelect value={mappingTableFieldToSet} id={item + "_tablefieldtoset"} customData={currentFields} idKey="id" valueKey="value" onChange={this.onTypeChange} />
                                                    </Grid>
                                                </Fragment>
                                            )}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            */
                        }
                    </TabContainer>
                )
            },
            {
                tabName: "Importazione immagini",
                tabContent: (
                    <TabContainer>
                        <input type="file" name="files" id="files" onChange={this.handleselectedFileImages} multiple />
                        <button onClick={this.handleUploadImages}>Upload</button>
                        <div>{Math.round(this.state.loadedImages, 2) > 0 && Math.round(this.state.loadedImages, 2) < 100 && "Caricamento..."}</div>
                    </TabContainer>
                )
            },
            {
                tabName: "Aggiornamento prezzi",
                tabContent: (
                    <TabContainer>
                        <input type="file" name="pricelists" id="pricelists" onChange={this.handleselectedFilePricelists} />
                        <button onClick={this.handleUploadPricelists}>Upload</button>
                        <div>{Math.round(this.state.loadedPricelists, 2) > 0 && Math.round(this.state.loadedPricelists, 2) < 100 && "Caricamento..."}</div>
                    </TabContainer>
                )
            },
            {
                tabName: "Aggiornamento store",
                tabContent: (
                    <TabContainer>
                        <input type="file" name="stores" id="stores" onChange={this.handleselectedFileStores} />
                        <button onClick={this.handleUploadStores}>Upload</button>
                        <div>{Math.round(this.state.loadedStores, 2) > 0 && Math.round(this.state.loadedStores, 2) < 100 && "Caricamento..."}</div>
                    </TabContainer>
                )
            },
            {
                tabName: "Aggiornamento campi custom",
                tabContent: (
                    <TabContainer>
                        <input type="file" name="extras" id="extras" onChange={this.handleselectedFileExtras} />
                        <button onClick={this.handleUploadExtras}>Upload</button>
                        <div>{Math.round(this.state.loadedExtras, 2) > 0 && Math.round(this.state.loadedExtras, 2) < 100 && "Caricamento..."}</div>
                    </TabContainer>
                )
            },
            {
                tabName: "Aggiornamento descrizioni",
                tabContent: (
                    <TabContainer>
                        <input type="file" name="descriptions" id="descriptions" onChange={this.handleselectedFileDescriptions} />
                        <button onClick={this.handleUploadDescriptions}>Upload</button>
                        <div>{Math.round(this.state.loadedDescriptions, 2) > 0 && Math.round(this.state.loadedDescriptions, 2) < 100 && "Caricamento..."}</div>
                    </TabContainer>
                )
            },
        ];
		
		if (this.props.params.WoocommercePlatformId) {
			locTabs.push({
                tabName: "Tools",
                tabContent: (
                    <TabContainer>
                        <XButton color="gray" onClick={() => { this.handleSync(this.props.params.WoocommercePlatformId)}}>SINCRONIZZA</XButton>
                    </TabContainer>
                )
            });
		}
		else if (this.props.params.WooCommercePlatforms && this.props.params.WooCommercePlatforms.length > 0 && this.state.platforms.length > 0) {
			var toolsContent;
			
			toolsContent = this.state.platforms.map((plt) => {
				if (plt.Url && plt.PublicKey && plt.SecretKey) {
					return (<Grid item xs={6}><XButton color="gray" onClick={() => { this.handleSync(plt.ID, plt.Url)}}>{"SINCRONIZZAZIONE - " + plt.DescriptionStr}</XButton></Grid>);
				} else {
					return (<Grid item xs={0} />);
				}
			});
			
			toolsContent = (<TabContainer><Grid container spacing={2}>{toolsContent}</Grid></TabContainer>);
			
			locTabs.push({
                tabName: "Tools",
                tabContent: toolsContent
            });
            }

        return (
            <div className={classes.root}>
                <Tabs
                    headerColor="primary"
                    plainTabs={true}
                    tabs={locTabs}
                />
                <XProgress progress={this.state.progress} total={this.state.total} label="Importazione distinte" onCancel={() => {
                    if (this.cancelImport) {
                        this.cancelImport();
                    }
                }} cancelLabel="Interrompi" show={this.state.isImporting} />
                <XProgress progress={this.state.progress} total={this.state.total} label="Importazione immagini" onCancel={() => {
                    if (this.cancelImportImages) {
                        this.cancelImportImages();
                    }
                }} cancelLabel="Interrompi" show={this.state.isImportingImages} />
                <XProgress progress={this.state.progress} total={this.state.total} label="Aggiornamento listini" onCancel={() => {
                    if (this.cancelUpdatePricelists) {
                        this.cancelUpdatePricelists();
                    }
                }} cancelLabel="Interrompi" show={this.state.isUpdatingPrices} />
                <XProgress progress={this.state.progress} total={this.state.total} label="Aggiornamento store/store area" onCancel={() => {
                    if (this.cancelUpdateStores) {
                        this.cancelUpdateStores();
                    }
                }} cancelLabel="Interrompi" show={this.state.isUpdatingStores} />
                <XProgress progress={this.state.progress} total={this.state.total} label="Aggiornamento campi custom" onCancel={() => {
                    if (this.cancelUpdateExtras) {
                        this.cancelUpdateExtras();
                    }
                }} cancelLabel="Interrompi" show={this.state.isUpdatingExtras} />
                <XProgress progress={this.state.progress} total={this.state.total} label={this.state.syncPhase + (this.state.progress >= 0 && this.state.total > 0 ? " (" + this.state.progress + "/" + this.state.total + ")" : "")} onCancel={() => {
                    if (this.cancelSync) {
                        this.cancelSync();
                    }
                }} cancelLabel="Interrompi" show={this.state.isSync} />
                <XMessageBox innerRef={this.MessageBox} />
            </div>
        );
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(ImportBOP);
