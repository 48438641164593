import React, { Component } from 'react';
import { withStyles, Tooltip, IconButton, Card, Paper } from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';


import XGridRowHeader from '../components/XGridRowHeader';
import XMessageBox from '../components/XMessageBox';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';
import { ArrowLeft, Check,ArrowHorizontalLock, ArrowExpandHorizontal, Translate } from 'mdi-material-ui';
import XField from './XField';

const styles = theme => ({
    root: {

    },
    field: {
        width: 220,
        display: 'inline-block',
        padding: 5,
    },
    content: {
        maxHeight: 'calc(100vh - 172px)',
        overflowX:'auto',
    },
    header:{
        background: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 1000
    },
});

class TranslateEditor extends Component{
    constructor(props) {
        super(props);
        this.tabs = React.createRef();
        this.MessageBox = React.createRef();
        this.state = {
            dataT:{

            },
            languages:[],
            translatableFields:[],
			defaultUiTabFields: [{ key: "Descrizione"}, {key: "Sigla"}],
            UiTabFields:[],
        }
    }

    componentDidMount() {
        var { routeLanguages, routeTranslatableFields, routeUiTabFields } = this.props;
        fetch(routeLanguages).then(res => res.json()).then(languages =>{
            this.setState({languages},this.getDataT);
        });

        var uiTabFields = routeUiTabFields && routeUiTabFields.length > 0 ? routeUiTabFields : this.state.defaultUiTabFields;
		
        this.setState({UiTabFields:uiTabFields});

        fetch(routeTranslatableFields).then(res => res.json()).then(translatableFields =>{
            this.setState({translatableFields}, this.getDataT);
        });

        fetch(routeTranslatableFields).then(res => res.json()).then(translatableFields =>{
            this.setState({translatableFields}, this.getDataT);
        });
    }




    getDataT = async () => {
        var { routeDataT } = this.props;

        if (!routeDataT) return;

        var { languages,translatableFields } = this.state;
        if(languages.length === 0 || translatableFields.length === 0){
            return;
        }
        var dataT = Object.assign({},{});
        for(var l in languages){
            var lang = languages[l];
            dataT[lang.id] = {};
            for(var f in translatableFields)
                
                {
                var field = translatableFields[f];
                var route = `${routeDataT}?FIDLangauge=${lang.id}&Field=${field.key}`;
                if(field.fidField){
                    route += `&FIDField=${field.fidField}`;
                }


                var data = await fetch(route).then(res => res.json());
                dataT[lang.id][field.key] = {
                    string: data.item1,
                    manualTranslation : data.item2,
                    fidField: field.fidField
                };
            }
        }

        this.setState({dataT});
    }

    translateTo = (to) => async () => {
        var { data, classes } = this.props;
        var { translatableFields, languages, dataT} = this.state;
        var defLang = languages.filter(f => f.main)[0];
        var lang = languages.filter(f => f.code === to)[0];
        var dataDef = dataT[defLang.id];
        if(defLang.code === lang.code){
            return;
        }
        
        for(var t in translatableFields){
            var field = translatableFields[t];
            if(!dataDef[field.key].string || dataT[lang.id][field.key].manualTranslation) {
                continue;
            }
            var d = await fetch(`/Base/Translate?from=${defLang.code}&to=${lang.code}&sources[0]=${dataDef[field.key].string}`).then(res => res.json());
            dataT[lang.id][field.key] = {
                string: d[0],
                manualTranslation : false,
                fidField: field.fidField
            };
        }
        this.setState({dataT});
    }
    onChange = (FIDLanguage,Field) => () => (_,value) => {
        console.log(FIDLanguage,Field,value);
        this.setState(state => {
            state.dataT[FIDLanguage][Field].string = value;
            return state;
        });
    }
    onLockChange = (FIDLanguage,Field) => () => (value) => {
        this.setState(state => {
            state.dataT[FIDLanguage][Field].manualTranslation = !state.dataT[FIDLanguage][Field].manualTranslation;
            return state;
        });
    }
    onLockChangeMain = (FIDLanguage,Field) => () => (value) => {
        this.setState(state => {
            state.dataT[FIDLanguage][Field].force = !state.dataT[FIDLanguage][Field].force;
            return state;
        });
    }
    handleSave = () => {
        var { routeSaveData, onClose } = this.props;
        var { dataT } = this.state;
        fetch(routeSaveData,{
            method: 'POST',
            body: JSON.stringify(dataT)
        }).then(res => {
            onClose && onClose();
        })
    }
    renderTabUI = () =>{
        var { data, classes } = this.props;
        var { UiTabFields, languages, dataT} = this.state;
        
        var defLang = languages.filter(f => f.main)[0];
        var defData = defLang && dataT[defLang.id];


        var columns = [
            {
                key: 'field',
                name: 'Campo',
                width: 250
            },
       ];

        return <TabContainer>
            <Paper className={classes.content}>
                <div className={classes.header} style={{width: 250 * languages.length + 250}}>
                    <XGridRowHeader
                        width={250 * languages.length + 250}
                        columns={columns}
                        isIndeterminate={0}
                        isSelected={false}
                        hideSelection={true}
                        // orderBy={orderBy}
                        // order={order}
                        // onSelectAll={onSelectAllClick}
                        // onSort={() =>{}}
                        // reactGrid={grid}
                        // viewType={viewType}
                    />
                </div>

                {UiTabFields.map(i =>{
                    return <div key={i.key} style={{minWidth: 250 * languages.length + 250}}>
                        <span className={classes.field}>{i.key}</span>
                    </div>
                })}
            </Paper>
        </TabContainer>
    }


    renderTabData = () =>{
        var { data, classes } = this.props;
        var { translatableFields, languages, dataT} = this.state;
        
        var defLang = languages.filter(f => f.main)[0];
        var defData = defLang && dataT[defLang.id];


        var columns = [
            {
                key: 'field',
                name: 'Campo',
                width: 200
            }
        ];
        columns = columns.concat(languages.map(l => ({ 
            key: l.id, 
            name: l.main ? l.description : (<span>{l.description} <Tooltip title="Traduci"><IconButton color="primary" size="small" onClick={this.translateTo(l.code)}><Translate /></IconButton></Tooltip></span>), 
            width: 200
        })));
        return <TabContainer>
            <Paper className={classes.content}>
                {/* <div>
                    <span className={classes.field}>Campo</span>
                    {languages.map(l => <span className={classes.field} key={l.id} onClick={this.translateTo(l.code)}>{l.description}</span>)}
                </div> */}
                <div className={classes.header} style={{width: 225 * languages.length + 225}}>
                    <XGridRowHeader
                        width={225 * languages.length + 225}
                        columns={columns}
                        isIndeterminate={0}
                        isSelected={false}
                        hideSelection={true}
                        //orderBy={orderBy}
                        //order={order}
                        //onSelectAll={onSelectAllClick}
                        //onSort={() =>{}}
                        //reactGrid={grid}
                        //viewType={viewType}
                    />
                </div>

                {translatableFields.map(i =>{
                    return <div key={i.key} style={{minWidth: 200 * languages.length + 200}}>
                        <span className={classes.field} style={{ paddingLeft: 25 }}>{i.key}</span>
                        {languages.map(l => { 
                            var text = data[i.key];
                            var isLocked = false;
                            var lockable = true;
                            var lockIcon = undefined;
                            var onLockChange = this.onLockChange(l.id,i.key);
                            if(dataT[l.id]){
                                text = dataT[l.id][i.key].string;
                                isLocked = !dataT[l.id][i.key].manualTranslation;

                                if(l.main){
                                    onLockChange = this.onLockChangeMain(l.id,i.key);
                                    isLocked = dataT[l.id][i.key].force;
                                    lockIcon = isLocked ? <ArrowHorizontalLock /> : <ArrowExpandHorizontal />
                                    isLocked = false;
                                }
                                else {
                                    if(defData && defData[i.key].force && isLocked) {
                                        text = defData[i.key].string;
                                    }
                                }
                                if(i.onlyManual){
                                    onLockChange = undefined;
                                    lockable = false;
                                }

                            }
                            return (<span className={classes.field} key={l.id}>
                                <XField onChange={this.onChange(l.id,i.key)} onLockChange={onLockChange} value={text} lockable={lockable} isLocked={isLocked} lockIcon={lockIcon} multiline />
                            </span>);
                        })}
                    </div>
                })}
            </Paper>
        </TabContainer>;
    }
    render() {
        var { classes, hideUITab } = this.props;
		
		if (!hideUITab)
		{
			hideUITab = false;
		}
		
        var actions = [
            <Tooltip title="Indietro">
                <IconButton aria-label="Indietro" onClick={this.props.onCancel} >
                    <ArrowLeft />
                </IconButton>
            </Tooltip>,
            <Tooltip title="Salva">
                <IconButton aria-label="Salva" onClick={this.handleSave} >
                    <Check />
                </IconButton>
            </Tooltip>
        ];

		var loctabs = hideUITab ? [{
                tabName: "Dati",
                tabContent: this.renderTabData(),
                actions
            }] : [
            {
                tabName: "Interfaccia",
                tabContent: this.renderTabUI(),
                actions
            },
            {
                tabName: "Dati",
                tabContent: this.renderTabData(),
                actions
            },
        ];

        return <div className={classes.root}>
            <Tabs
                innerRef={this.tabs}
                headerColor="primary"
                plainTabs={true}
                onExitClick={this.handleExit}
                onSaveClick={this.handleSaveData}
                onDeleteClick={this.handleCancelData}
                //onNewClick={this.handleNewData}
                validateTab={this.validate}
                tabs={loctabs}
            />
            <XMessageBox ref={this.MessageBox} />
        </div>;
    }
}


const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);

export default enhance(TranslateEditor);