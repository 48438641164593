import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import NumericInput from 'react-numeric-input';
import {
	grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import styles from "assets/jss/material-dashboard-pro-react/customTextFieldStyle.jsx"
import { IconButton } from '@material-ui/core';
import { Lock, LockOpen } from 'mdi-material-ui';

//const fieldStyles = theme => ({
//    container: {
//        display: 'flex',
//        flexWrap: 'wrap',
//    },
//    textField: {
//        marginLeft: theme.spacing.unit,
//        marginRight: theme.spacing.unit,
//        marginTop: 8,
//        width: "100%",
//    },
//    dense: {
//        marginTop: 19,
//    },
//    marginNormal: {
//        marginTop: "8px",
//    },
//    menu: {
//        width: 200,
//    },
//});

class XField extends Component {
	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
		this.state = {
			selectionStart: 0,
			isLocked: props.lockable,
			isFocused: false,
		};
	}

	NumberFormatCustom = () => {
		var { classes, id, onChange, style, value, minValue, maxValue, inputRef, preventNegativeValue, decimalScale, readOnly, ...other } = this.props;

		if (inputRef)
			inputRef.pattern = "[0-9.]";
		//if (value === 0)
		//	value = "";
		return (
			<NumericInput id={id}
				name={id} ref={inputRef}
				readOnly={readOnly}
				disabled={readOnly}
				className={classes.textField} 
				precision={decimalScale} 
				value={value} 
				min={minValue} 
				max={maxValue}
				style={{ border: 0, fontSize: "inherit", padding: "6px 0 7px", color: grayColor, marginTop: 0, "& b": { dispay: "none" } }}
				onKeyDown={(event) => {
					var { id, onKeyDown, decimalScale } = this.props;
					console.log(event.keyCode);
					if ((event.keyCode === 188 || event.keyCode === 190 || event.keyCode === 110) && decimalScale > 0) {
						event.keyCode = 190;
						event.wich = 190;
						event.key = ".";
						console.log(event);
						return true;
					}
					if ((event.keyCode > 57 && event.keyCode < 96) || event.keyCode > 105) {
						event.preventDefault();
						return false;
					}
				}}
				format={(stringVal) => {
					if(stringVal.endsWith(",")){
						stringVal += "0";
					}
					var numVal = parseFloat(stringVal.replace(/(,)+/g,'.').replace(/([^\d.-]+)+/g, ''));
					if (numVal > this.props.maxValue)
						return this.props.maxValue;

					return stringVal.replace(/(,)+/g,'.').replace(/([^\d.-]+)+/g, '');
				}}
				onChange={(numVal, stringVal, element) => {
					
					if(numVal > this.props.maxValue)
						return;
					//if (numVal === 0) {
					//	onChange && onChange(element.id)(undefined, "");
					//	return;
					//}
					console.log(stringVal);
					if (stringVal.startsWith("."))
						stringVal = "";

					stringVal = stringVal.replace(/(,)+/g,'.').replace(/([^\d.-]+)+/g, '');
					
					onChange && onChange(element.id)(undefined, stringVal);
				}}
				onInvalid={(errorMessage,valueAsNumber,valueAsString) => {
					console.log(errorMessage,valueAsNumber,valueAsString);
				}}
			/>
		);
	}
	handleChange = (event, value) => {
		var { id, onChange,type, preventNegativeNumber, minValue, maxValue } = this.props;

		var value = event.target.value;
		if(type === "number")
		{
			value = value.replace(",",".").replace("..",".");
			var checkValue = value;
			if(checkValue.endsWith('.')){
				checkValue += '0';
			}
			if((checkValue || checkValue === 0)){
				try {
					var floatNumber = parseFloat(checkValue);
					
					if(preventNegativeNumber && checkValue){
						
						if(floatNumber < 0) {
							return;
						}
					}

					if (minValue !== undefined && floatNumber < minValue) {
						value = minValue;
					}

					if (maxValue !== undefined && floatNumber > maxValue) {
						value = maxValue;
					}
				}
				catch{
					return; //invalid value
				}
			}
		}

		if (onChange) {
			onChange(id)(event, value);
		}
		//var { id, caller, dataKey } = this.props;
		//var data = caller.state[dataKey];
		//data[id] = event.target.value;
		//caller.setState((state, props) => {
		//    state[dataKey] = data;
		//    return state;
		//});
		//caller.setState({ data });
	}
	handleBlur = (event) => {
		var { id, onBlur, onChange, value, type, decimalScale } = this.props;
		this.setState({isFocused: false},() =>{
			//verifico formatazione e arrotondamenti
			if((value || value === 0) && type === "number"){
				// if(!decimalScale){
				// 	decimalScale = 0;
				// }
				//value = parseFloat(value);
				if(typeof value === "string" && value.endsWith('.')){
					value += '0';
				}
				var floatValue = parseFloat(value);
				if (decimalScale) {
				var multiplier = Math.pow(10,decimalScale);
				floatValue = Math.round(floatValue * multiplier) / multiplier;
				//visibleValue = (floatValue).toLocaleString('it-IT', {minimumFractionDigits: decimalScale})
				//event.value = floatValue;
				}
				
				if (onChange) {
					onChange(id)(event, floatValue);
				}
			}
		});
		if (onBlur) {
			onBlur(id)(event);
		}
	}
	handleFocus = (event) => {
		var { onFieldFocus, id } = this.props;
		this.setState({isFocused: true});
		onFieldFocus && onFieldFocus(id)(event);
	}
	getSelectionStart = (event) => {
		var { id } = this.props;

		if (this.inputRef) {

		}
	}
	updateSelectionStart = () => {
		var cursorPos = this.inputRef.current.selectionStart;

		this.setState({
			selectionStart: cursorPos
		})
	}
	handleKeyDown = (event) => {
		var { selectionStart } = this.state;
		var { id, onKeyDown } = this.props;

		if (event.keyCode === 8) {
			var numericInput = document.getElementById(id);
		}

		if (onKeyDown) {
			onKeyDown(id)(event);
		}
	}

	onLockChange = {

	}

    onLockClick = () => {
        var { id, onLockChange, isLocked } = this.props;
        onLockChange && onLockChange(id)(!isLocked);
        
        // this.setState(state => {
        //     state.isLocked = !state.isLocked;
        //     return state;
        // });
    }
	render() {
		var { classes, id, readOnly, value, label, caption, multiline, type, customFormatNumber, field, style, rowsMax, rows, hasError, onBlur, onKeyDown, onChange, nativeRef, fontSize, labelAlignTop, labelFontSize,
			maxLength, lockable, isLocked, lockIcon,disabled,decimalScale, ...other } = this.props;
		var { isFocused } = this.state;
		if (value === undefined || value === null) {
			value = "";
		}
		var error = false;
		var errorClass = classes.error;
		if (field) {
            if ((field.Required === 2 || (field.Required === 1)) && !value && value !== 0) {
				error = true;
				if (field.Required === 1) {
					errorClass = classes.errorOptional;
				}
			}
		}
		if (hasError) {
			error = hasError;
			errorClass = classes.error;
		}
        if(!maxLength){
            maxLength = 524288;
        }
        var endAdornment = null;
        if(lockable /*&& !disabled*/){
            endAdornment = <Fragment>
				{caption && <InputAdornment disableTypography={true} style={{ color: "black", fontSize: fontSize }} position="end">{caption}</InputAdornment>}
				<IconButton size="small" onClick={this.onLockClick}>
                {lockIcon || (isLocked ? <Lock /> : <LockOpen />)}
            </IconButton></Fragment>;
            disabled = isLocked;
			readOnly = isLocked;
        }
		else if(caption) {
			endAdornment = <InputAdornment disableTypography={true} style={{ color: "black", fontSize: fontSize }} position="end">{caption}</InputAdornment>;
		}
		if (!readOnly)
			readOnly = false;

		var inputProps = { maxLength };
		if (fontSize) {
			inputProps.style = {
				fontSize
			};
		}

		var InputProps = {
			classes: {
				root: classes.textFieldInput,
				error: errorClass
			},
			readOnly: Boolean(readOnly),
			disabled: Boolean(readOnly),
			endAdornment: endAdornment
		};

		//if (type === "number" && customFormatNumber)
			//InputProps.inputComponent = this.NumberFormatCustom;
		
		var visibleValue = value;

		var floatValue = parseFloat(value);
		if((value || value === 0) && type === "number" && !isFocused){
			//value = parseFloat(value);
			if(decimalScale){
				var multiplier = Math.pow(10,decimalScale);
				floatValue = Math.round(floatValue * multiplier) / multiplier;
				visibleValue = (floatValue).toLocaleString('it-IT', {minimumFractionDigits: decimalScale,maximumFractionDigits: decimalScale})
			}
			else {
				visibleValue = (floatValue).toLocaleString('it-IT', {maximumFractionDigits: 20});
			}
			if (isNaN(floatValue))
				visibleValue = "";
			type = undefined;
		}
		var labelStyle = {};
		if(labelFontSize){
			labelStyle.fontSize = labelFontSize;
		}

		return (
			<TextField
				ref={nativeRef}
				autoComplete="off"
				id={id}
				name={id}
				label={label}
				multiline={multiline}
				rowsMax={rowsMax || "6"}
				rows={rows || "6"}
				fullWidth
				error={error}
				InputLabelProps={{
					shrink: true,
					classes: {
						root: labelAlignTop ? classes.textFieldLabelTop : classes.textFieldLabel,
					},
					style: labelStyle
					
				}}
				InputProps={InputProps}
				inputProps={inputProps}
				inputRef={this.inputRef}

				className={classes.textField}
				value={visibleValue}
				//onChange={type === "number" && customFormatNumber ? undefined : this.handleChange}
				onChange={this.handleChange}
				onBlur={this.handleBlur}
				onFocus={this.handleFocus}
				//onKeyDown={type === "number" && customFormatNumber ? undefined : this.handleKeyDown}
				onKeyDown={this.handleKeyDown}
				margin="normal"
				type={type}
				style={style}
				disabled={disabled}
				{...other}
			/>
		);
	}
}
XField.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
		multiline: PropTypes.bool,
		onSelect: PropTypes.func
};
export default withStyles(styles)(XField);