import React, { Component, Fragment } from 'react';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import AppBar from '@material-ui/core/AppBar';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';


import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { Check, DeleteSweep, AlphaGcircleOutline, AlphaBcircleOutline,AlphaCcircleOutline,AlphaTcircleOutline, AlphaLcircleOutline, AlphaPcircleOutline, AlphaScircleOutline, Star, BellRingOutline, LockOpenOutline, LockOutline, CartOutline, ArrowLeft, ExitToApp, Magnify, AccountSwitch, SwapVertical, StarOutline, BellOffOutline, StarOff, Handshake, HandRight, Close, CurrencyEur,CurrencyEurOff, FileExcelOutline } from 'mdi-material-ui';
import { LockOffOutline, CartOffOutline } from 'components/Icons';
import CatalogueIcon from 'components/CatalogueIcon';
//import XDialog from 'components/XDialog';
import XOXLoader from 'components/XOXLoader';
import IdentitySelector from 'components/IdentitySelector';
import CausalSelector from 'components/CausalSelector';
import PricelistSelector from 'components/PricelistSelector';


import CatalogueCard from 'components/CatalogueCard';
import CatalogueItem from 'components/CatalogueItem';
import CollectionCard from 'components/CollectionCard';
import XFilters from 'components/XFilters';
import XField from 'components/XField';
import XMessageBox from 'components/XMessageBox';

import EnhancedTableToolbar from 'components/EnhancedTableToolbar';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
import XNavigation from '../components/XNavigation';
import { getCookie, setCookie, deleteCookie, popupBOPSearch, getBOPSearchContent, getScrollbarWidth, format } from 'utils';
//import { Typography } from '@material-ui/core';
import CatalogueCart from 'components/CatalogueCart';

import { Collection } from 'react-virtualized';
import BOPSelection from 'views/BOP/BOPSelection';
import XPDFViewer from 'components/XPDFViewer';
import XPrintOptionSelector from 'components/XPrintOptionSelector';
const APP_TOOLBAR_HEIGHT = 60;
const HEADER_HEIGHT = 85;

const styles = theme => ({
    root: {
        position: 'relative',
        minHeight: 'calc(100vh - 155px)',
    },
    collectionCardAlt: {
        position: 'relative',
        padding: '0 !important',
        cursor: 'pointer',
    },
    collectionCardAltBackground: {
        paddingTop: '100%',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: '1px solid #bbb',
        width: '100%',
    },
    collectionCardAltContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        maxWidth: '100%',
        maxHeight: '100%',
        textAlign: 'center',
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    
});
const defaultFilters = {
    ShowModels: false,
    ShowStock: false,
    ImageView: 2,
    GroupType: 1,
    Sort: [],
};
// GetParam("CatalogueDefaultShowCatalogue").then(result => defaultFilters.ShowModels = result);
// GetParam("CatalogueDefaultShowStock").then(result => defaultFilters.ShowStock = result);
// GetParam("CatalogueDefaultGroupType").then(result => defaultFilters.GroupType = result);
// GetParam("CatalogueDefaultImageView").then(result => defaultFilters.ImageView = result);


async function GetParam(param) {
    return await fetch('/Base/GetParam?param=' + param).then(res => res.json());
}

class Catalogue extends Component {
    constructor(props) {
        super(props);
        this.MessageBox = React.createRef();
        this.CatalogueItem = React.createRef();
        this.CardsCollection = React.createRef();
        this.BLSCollection = React.createRef();
        this.bopsSearch = React.createRef();
		this.PrintOptionSelector = React.createRef();
        this.state = {
            data: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            dataPaged: [],
            count: 0,
            page: 1,
            itemsperPage: 48,
            showFilters: false,
            showFiltersRow: false,
            currentFilters: Object.assign({}, defaultFilters),
            tempFilters: Object.assign({}, defaultFilters),
            oldFilters: undefined,
            oldTempFilters: undefined,
            filtersData: {
                ShowModels: true,
                ShowStock: true,
                ShowNews: false,
                categories: [],
                groups: [],
                brands: [],
                prices: [],
                lines: [],
                series: [],
                positions: [],
                templates: [],
            },
            currentItem: {},
            showItem: false,
            stars: undefined,
            itemStar: {},
            document: {},
            documentStep: 1,
            FIDIdentity: undefined,
            isLoading: true,
            isLoadingPage: false,
            dialog: {},
            showSort: false,
            sortColumns: [],
            viewType: null,
            scrollTop: false,
            contentWidth: 100,
            rowsMaxHeight: {},
            firstLoad: true,
            categories:[],
            tags:[],
            refresh: true,
            totDocument: 0,
			totDocumentDisc: 0,
            FIDBOP: undefined,
            showPublicPrice: false,
            firstLoad: true,
			enabledCausals: [
				{
					FIDCausal: props.params.DOCPresentationCausalId||10,
					CausalName: getTranslation(1,"CAT_PRESENTATION", this.props.translations, "Presentazione"),
					Icon: "Star",
					//icon: <Star />,
					//iconOff: <StarOff />,
					BOPSelectionEnabledModes: [],
				},
				{
					FIDCausal: props.params.DOCNoticeCausalId||11,
					CausalName: getTranslation(1,"CAT_NOTICE", this.props.translations, "Avviso"),
					Icon: "BellRingOutline",
					//icon: <BellRingOutline />,
					//iconOff: <BellOffOutline />,
					BOPSelectionEnabledModes: []
				},
				//{
				//	FIDCausal: props.params.DOCReservationCausalId||12,
				//	
				//},
				{
					FIDCausal: props.params.DOCQuoteCausalId||3,
					CausalName: getTranslation(1,"CAT_QUOTE", this.props.translations, "Preventivo"),
					Icon: "CurrencyEur",
					//icon: <CurrencyEur />,
					//iconOff: <CurrencyEurOff />,
					BOPSelectionEnabledModes: [ 1 ],
				},
				{
					FIDCausal: props.params.DOCNegotiationCausalId||39,
					CausalName: getTranslation(1,"CAT_NEGOTIATION", this.props.translations, "Trattativa"),
					Icon: "Handshake",
					//icon: <Handshake />,
					//iconOff: <HandRight />,
					BOPSelectionEnabledModes: [ 1,2 ],
					showDueDate: true
				}, 
				{
					FIDCausal: props.params.DOCBookingCausalId||13,
					CausalName: getTranslation(1,"CAT_BOOK", this.props.translations, "Prenota"),
					Icon: "LockOutline",
					//icon: <LockOutline />,
					//iconOff: <LockOffOutline />,
					BOPSelectionEnabledModes: [ 1,2,3,4,5,6 ]
				}, 
				{
					FIDCausal: props.params.DOCCartCausalId||9,
					CausalName: getTranslation(1,"CAT_CART", this.props.translations, "Carrello"),
					Icon: "CartOutline",
					//icon: <CartOutline />,
					//iconOff: <CartOffOutline />,
					BOPSelectionEnabledModes: [ 1,2,3 ]
				} 
			]
        };
    }
    resizeObserver;
    
    async componentDidMount() {
		var url_string = window.location.href;
        var url = new URL(url_string);
        var showcustomer = url.searchParams.get('showcustomer');
        var showpricelist = url.searchParams.get('showpricelist');
		//window.onbeforeunload = (event) => { 
		//	if (!this.handlePageExit(undefined, undefined)) { 
		//		debugger; event.preventDefault(); 
		//		event.returnValue = '';
		//		return false; 
		//	}
		//}
        this.props.setCatalogIdentity(undefined);
        this.props.setCatalogCausal(undefined);
        this.props.setCatalogPricelist(undefined);
        if (window.ResizeObserver !== undefined) {
            this.resizeObserver = new window.ResizeObserver((entries) => {
                this.setState({
                    contentWidth: entries[0].contentRect.width,
                    lastWidthChange: Date.now()
                }, () => {
                    setTimeout(() => {
                        if (this.CardsCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                            this.CardsCollection.current.recomputeCellSizesAndPositions();
                        }
                        if (this.BLSCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                            this.BLSCollection.current.recomputeCellSizesAndPositions();
                        }
                    }, 100);
                });
            });
            this.resizeObserver.observe(document.getElementById('mainContent'));
        }
        else {
            this.setState({ contentWidth: document.getElementById('mainContent').clientWidth });
            window.addEventListener('resize', this.mainContentResize);
        }
        var tempState = getCookie('catalogue_state');
		
		var enabledCausals = await fetch('/Catalog/GetEnabledCausals').then(res => res.json());
		
		// FOR NOW IF THERE ARE NO CAUSALS CONFIGURED INTO DB KEEP STANDARD FOR BACKWARD COMPAT
		if ((!enabledCausals || enabledCausals.length === 0) && (this.props.userID === -1990 || this.props.FIDProfile === (this.props.params.UserTypologyId||7))) {
			enabledCausals = this.state.enabledCausals;
		}
		
		var IRHasPricelists = true;
		
		if (this.props.isAgent && !showpricelist) {
			IRHasPricelists = await fetch("/IR/HasPricelists/"+this.props.FIDIdentity).then(res => res.json());
		}
		
        if (tempState) {
            var newState = JSON.parse(tempState);
            newState.CatalogFIDCausal && this.props.setCatalogCausal(newState.CatalogFIDCausal);
            newState.CatalogFIDIdentity && this.props.setCatalogIdentity(newState.CatalogFIDIdentity);
            newState.CatalogFIDPricelist && this.props.setCatalogPricelist(newState.CatalogFIDPricelist);
            this.setState(state => {
                state.currentFilters = Object.assign({}, newState.currentFilters);
                state.tempFilters = Object.assign({}, newState.tempFilters);
				state.IRHasPricelists = IRHasPricelists;
				state.enabledCausals = enabledCausals;
                state = newState;
                return state;
            }, () => {
                deleteCookie('catalogue_state');
                if (newState.reloadData) {
                    this.loadData();
                }
            });
        }
        else {

            var { advancedNavigation } = this.props;
            //defaultFilters.ShowModels = await GetParam("CatalogueDefaultShowCatalogue");
            //defaultFilters.ShowStock = await GetParam("CatalogueDefaultShowStock");
            defaultFilters.ShowModels = this.props.params.CatalogueDefaultShowCatalogue;
            defaultFilters.ShowStock = this.props.params.CatalogueDefaultShowStock;
            defaultFilters.GroupType = this.props.params.CatalogueDefaultGroupType;
            defaultFilters.ImageView = this.props.params.CatalogueDefaultImageView;



            this.setState(state => {
                state.currentFilters = Object.assign({}, defaultFilters);
                state.tempFilters = Object.assign({}, defaultFilters);
				state.IRHasPricelists = IRHasPricelists;
				state.enabledCausals = enabledCausals;
                if (this.props.CatalogFIDCausal) {
                   state.FIDCausal = this.props.CatalogFIDCausal;
                }
                return state;
            }, () => {
				if ((!this.props.isAgent && !this.props.params.CatalogueShowIRPLSelector) || this.props.FIDProfile === this.props.params.CustomerTypologyId) {
					if (this.props.match.params.id) {
						advancedNavigation = false;
					}
					if (advancedNavigation) {
                        
                        Promise.all([
                            this.getCatalogueGroups(),
                            this.getCollections(),
                            this.getCategories(),
                            this.getTags()
                        ])
                            .then(res => {
                                if (this.state.firstLoad) {
                                    this.setState({ firstLoad: false }, () => {
                                        var { collections } = this.state;
                                        //check if has params
                                        var url_string = window.location.href;
                                        var url = new URL(url_string);
                                        var brands = url.searchParams.get('brands');
                                        var lines = url.searchParams.get('lines');
                                        var series = url.searchParams.get('series');
                                        var products = url.searchParams.get('products');
                                        var news = url.searchParams.get('new');
                                        var categories = url.searchParams.get('categories');
                                        var tags = url.searchParams.get('tags');
                                        var FIDCatalogueGroup = url.searchParams.get("groups");
                                            
                                        if (!!products) {
                                            this.showProducts();
                                        }
                                        else if (!!categories) {
                                            this.showCategories();
                                        }
                                        else if(!!tags){
                                            this.showTags();
                                        }
                                        else if(!!FIDCatalogueGroup){
                                            this.onCollectionCardClick("groups",{ID: parseInt(FIDCatalogueGroup)});
                                        }
                                        else if (!!brands) {
                                            this.onCollectionCardClick('brand', collections.brands.find(item => item.FIDBrand === parseInt(brands)));
                                        }
                                        else if (!!lines) {
                                            this.onCollectionCardClick('line', collections.lines.find(item => item.FIDLine === parseInt(lines)));
                                        }
                                        else if (!!series) {
                                            this.onCollectionCardClick('series',collections.series.find(item => item.FIDSeries === parseInt(series)));
                                        }
                                        else if (!!news) {
                                            this.showNews();
                                        }
                                            if ((!!products || products === null) && (!collections || (collections.brands.length <= 1 && collections.lines.length <= 1 && collections.series.length <= 1)) 
                                                && (!this.state.categories || this.state.categories.length <= 1) && (!this.state.tags || this.state.tags.length <= 1)) {
                                            this.showProducts();
                                        }
                                    });
                                }



                                this.setState({ isLoadingPage: false, isLoading: false, firstLoad: false });
                            });
						// this.getCatalogueGroups();
						// this.getCollections();
						// this.getCategories();
						// this.getTags();
					}
					else {
						this.loadData();
					}
					this.loadFiltersData();
                }
                else {
                    this.setState({firstLoad: false});
                }
				
                if (this.props.CatalogFIDIdentity) {
                    this.reloadIdentityInfo(this.props.CatalogFIDIdentity);
                } else //if (!this.props.isCatalog) 
				{
					this.reloadIdentityInfo(undefined);
				}
            });
        
            this.setState(state => {
                if(showcustomer) {
                    state.showIdentitySelector = true;
                    state.firstLoad = false;
                }
                if(showpricelist) {
                    state.showPricelistSelector = true;
                    state.firstLoad = false;
                }
                return state;
            })
        }
    }
    componentDidUpdate = () => {
        //if (true) {
        //    window.onbeforeunload = () => true;
        //}
        //else {
        //    window.onbeforeunload = undefined;
        //}
    }
    componentWillUnmount() {
        this.props.setTitle(undefined);
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        window.removeEventListener('resize', this.mainContentResize);
    }
    mainContentResize = () => {
        var element = document.getElementById('mainContent');

        this.setState({
            contentWidth: element.clientWidth,
            lastWidthChange: Date.now()
        }, () => {
            setTimeout(() => {
                if (this.CardsCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                    this.CardsCollection.current.recomputeCellSizesAndPositions();
                }
                if (this.BLSCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                    this.BLSCollection.current.recomputeCellSizesAndPositions();
                }
            }, 100);
        });
    }
    getCollections = async (callback) => {
		var { CatalogFIDPricelist, FIDIRPricelist, CatalogFIDIdentity, FIDIdentity } = this.props;
        var { currentFilters } = this.state;

        var showDB = currentFilters.ShowModels ? true : false;
        var showDM = currentFilters.ShowStock ? true : false;
        var showNews = currentFilters.ShowNews ? true : false;
        var groupType = currentFilters.GroupType;
        var imageView = currentFilters.ImageView;
		
		if (!CatalogFIDPricelist)
			CatalogFIDPricelist = FIDIRPricelist;
		
		if (!CatalogFIDIdentity)
			CatalogFIDIdentity = FIDIdentity;
		
		if (CatalogFIDIdentity === this.props.FIDLicensee)
			CatalogFIDIdentity = undefined;
		
		if (!CatalogFIDIdentity &&!CatalogFIDPricelist && this.props.params)
			CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;

		if (CatalogFIDIdentity && this.props.FIDProfile === this.props.params.CustomerTypologyId)
			CatalogFIDPricelist = undefined;

        var prms = `?showDB=${showDB}
            &showDM=${showDM}
            &showNews=${showNews}
            &groupType=${groupType}
            &imageView=${imageView}
            &FIDPricelist=${CatalogFIDPricelist}&FIDIdentity=${CatalogFIDIdentity}`;

        var resolveMethod = undefined;
        var rejectMethod = undefined;
        var promise = new Promise((res,rej) => {
            resolveMethod = res;
            rejectMethod = rej;
        });
        //var data = await fetch('/BOP/GetCatalogueCollections/' + prms).then(res => res.json());
        var data = await fetch('/BOP/GetCatalogueCollectionsNew/' + prms).then(res => res.json());
        //this.setState({ collections: data, isLoading: false }, () => {
        this.setState({ collections: data }, () => {
                callback && callback();
                resolveMethod && resolveMethod();
            
        });

        return promise;
    }
    getCatalogueGroups = async () => {
        var { currentFilters } = this.state;

        var showDB = currentFilters.ShowModels ? true : false;
        var showDM = currentFilters.ShowStock ? true : false;
        var groupType = currentFilters.GroupType;
        var imageView = currentFilters.ImageView;
		
		var { CatalogFIDPricelist } = this.props;
		if (!CatalogFIDPricelist && this.props.params)
			CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;

        var prms = `?showDB=${showDB}
        &showDM=${showDM}
        &groupType=${groupType}
        &imageView=${imageView}
        &FIDPricelist=${CatalogFIDPricelist}`;
        
        var resolveMethod = undefined;
        var rejectMethod = undefined;
        var promise = new Promise((res,rej) => {
            resolveMethod = res;
            rejectMethod = rej;
        });
        fetch('/BOP/GetCatalogueGroups/' + prms).then(res => res.json()).then(data => {
            //this.setState({ groups: data, isLoading: false },resolveMethod);
            this.setState({ groups: data },resolveMethod);
        });
        return promise;
    }
    getCategories = async () => {
        var { currentFilters } = this.state;

        var showDB = currentFilters.ShowModels ? true : false;
        var showDM = currentFilters.ShowStock ? true : false;
        var groupType = currentFilters.GroupType;
        var imageView = currentFilters.ImageView;

		var { CatalogFIDPricelist, CatalogFIDIdentity, FIDIRPricelist, FIDIdentity } = this.props;
		
		if (!CatalogFIDPricelist)
			CatalogFIDPricelist = FIDIRPricelist;
		
		if (!CatalogFIDIdentity)
			CatalogFIDIdentity = FIDIdentity;
		
		if (CatalogFIDIdentity === this.props.FIDLicensee)
			CatalogFIDIdentity = undefined;
		
		if (!CatalogFIDIdentity && !CatalogFIDPricelist && this.props.params)
			CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;
		
		if (CatalogFIDIdentity && this.props.FIDProfile === this.props.params.CustomerTypologyId)
			CatalogFIDPricelist = undefined;
		
        var prms = `?showDB=${showDB}
        &showDM=${showDM}
        &groupType=${groupType}
        &imageView=${imageView}
        &FIDPricelist=${CatalogFIDPricelist}&FIDIdentity=${CatalogFIDIdentity}`;

        var resolveMethod = undefined;
        var rejectMethod = undefined;
        var promise = new Promise((res,rej) => {
            resolveMethod = res;
            rejectMethod = rej;
        });
        fetch('/BOP/GetCatalogueCategoriesNew/' + prms).then(res => res.json()).then(data => {
            //this.setState({ categories: data, isLoading: false }, resolveMethod);
            this.setState({ categories: data }, resolveMethod);
        });

        return promise;
    }
    getTags = async () => {
        var { currentFilters } = this.state;

        var showDB = currentFilters.ShowModels ? true : false;
        var showDM = currentFilters.ShowStock ? true : false;
        var groupType = currentFilters.GroupType;
        var imageView = currentFilters.ImageView;
		
		var { CatalogFIDPricelist } = this.props;
		if (!CatalogFIDPricelist && this.props.params)
			CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;

        var prms = `?showDB=${showDB}
            &showDM=${showDM}
            &groupType=${groupType}
            &imageView=${imageView}
            &FIDPricelist=${CatalogFIDPricelist}`;

        var resolveMethod = undefined;
        var rejectMethod = undefined;
        var promise = new Promise((res,rej) => {
            resolveMethod = res;
            rejectMethod = rej;
        });
        fetch('/BOP/GetCatalogueTagsNew/' + prms).then(res => res.json()).then(data => {
            //this.setState({ tags: data, isLoading: false }, resolveMethod);
            this.setState({ tags: data }, resolveMethod);
        });
        return promise;
    }
    showNews = () => {
        this.setState(state => {
            state.currentFilters.ShowNews = true;
            return state;
        },() => {
            this.getCollections(() =>{
                var { collections } = this.state;
                var count = collections.brands.length;
                if(!this.props.params.CatalogueHideBrandButton && count > 1){
                    this.showBrands();
                }
                else {
                    count = collections.lines.length
                    if(!this.props.params.CatalogueHideLineButton && count > 1){
                        this.showLines();
                    }
                    else {
                        count = collections.series.length
                        if(!this.props.params.CatalogueHideSeriesButton && count > 1){
                            this.showSeries();
                        }
                        else {
                            this.showProducts();
                        }
                    }
                }
            });
        });
    }
    loadPage = (callback) => {
        var { page, itemsperPage, currentFilters, FIDIdentity, FIDCausalView, ShowCart, viewType, endOfPage, pageLoading,newPageLoading } = this.state;
        if(endOfPage || pageLoading === page || newPageLoading){
            return;
        }
        if (viewType !== 'product') {
            currentFilters = {};
        }
        var filters = this.getFiltersArray(currentFilters);

        var { CatalogFIDPricelist } = this.props;
        if (!CatalogFIDPricelist && this.props.params)
            CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;

        var showDB = currentFilters.ShowModels ? true : false;
        var showDM = currentFilters.ShowStock ? true : false;
        var groupType = currentFilters.GroupType;
        var imageView = currentFilters.ImageView;
        var showNews = currentFilters.ShowNews ? true : false;
        var searchTerm = currentFilters.SearchTerm;
		
		if (JSON.stringify(currentFilters) === "{}" && this.state.tempFilters) {
			showDB = this.state.tempFilters.ShowModels ? true : false;
		    showDM = this.state.tempFilters.ShowStock ? true : false;
            groupType = this.state.tempFilters.GroupType;
            imageView = this.state.tempFilters.ImageView;
		}


        var IdentityFilter = "";
        if (FIDIdentity) {
            IdentityFilter = "&FIDIdentity=" + FIDIdentity;
        }
        var CausalFilter = "";
        if (FIDCausalView) {
            CausalFilter = "&FIDCausal=" + FIDCausalView;
        }
        //if(this.state.ShowCart && this.props.CatalogFIDCausal) {
        //    CausalFilter = "&FIDCausal=" + this.props.CatalogFIDCausal;
        //}
        //if (!CausalFilter && !this.props.IsCatalog && this.state.HasCart && this.state.ShowCart) {
        //	CausalFilter = "&FIDCausal=" + (this.props.params.DOCPresentationCausalId||10);
        //}
        var documentFilter = "";
        if (this.props.match.params.id || (this.state.documentID && FIDCausalView)) {
            var docID = this.props.match.params.id || this.state.documentID;
            documentFilter = "&FIDDocument=" + docID;
        }
        var pricelistFilter = "";
        if (this.props.FIDProfile !== this.props.params.CustomerTypologyId) {
            if (CatalogFIDPricelist) {
                pricelistFilter = `&FIDPricelist=${this.props.CatalogFIDPricelist}`;
            } else if (this.props.FIDIRPricelist) {
                pricelistFilter = `&FIDPricelist=${this.props.FIDIRPricelist}`;
            }
        }

        if (!currentFilters.Sort) {
            currentFilters.Sort = [];
        }
        var sort = currentFilters.Sort.map(item => ({ Field: item.Value.ID, Direction: item.Sort }));
        var time = Date.now();
        this.setState({ loadPageTimeStamp: time, newPageLoading: true, pageLoading: page }, () => {
            var link = '/BOP/GetCatalogueNew?sortColumn=Code&sortDirection=ASC&skip=' + ((page - 1) * itemsperPage) +
                '&items=' + itemsperPage +
                '&filters=' + JSON.stringify(filters) +
                '&showDB=' + showDB +
                '&showDM=' + showDM +
                '&showNews=' + showNews +
                '&groupType=' + groupType +
                '&imageView=' + imageView +
                '&searchTerm=' + (searchTerm || '') +
                '&sort=' + JSON.stringify(sort) +
                '&ShowCart=' + ShowCart +
                '&IsCatalog=' + !!this.props.isCatalog +
                '&showB2BPrice=' + this.state.HasWholesalePrice +
                IdentityFilter + CausalFilter + documentFilter + pricelistFilter;
            fetch(link).then(res => res.json())
                .then(data => {
                    if (this.state.loadPageTimeStamp === time && pageLoading !== page) {
                        if(data.data.length === 0){
                            this.setState({endOfPage: true, newPageLoading: false, isLoadingPage: false,
                                isLoading: false,
                                firstLoad: false
								});
                        }
                        else {
                            this.setState({
                                dataPaged: this.state.dataPaged.concat(data.data),
                                countPaged: data.count,
                                isLoadingPage: false,
                                isLoading: false,
                                firstLoad: false,
                                newPageLoading: false,
                                documentID: data.documentID,
                                totDocument: data.totDocument,
                                totDocumentDisc: data.totDocumentDisc,
                                totDocumentTaxable: data.totDocTaxable,
                                totDocumentDiscTaxable: data.totDocDiscTaxable,
                                currency: data.currency,
                                page: page + 1,
                                endOfPage: data.data.length !== itemsperPage
                            }, () => {
								if (this.state.endOfPage)
									this.CardsCollection.current && this.CardsCollection.current.recomputeCellSizesAndPositions();
                                if (callback) {
                                    callback();
                                }
                            });
                        }
                    }
                });
        });
    }
    loadData = (callback) => {
        this.setState({dataPaged: [],page:1, endOfPage: false, pageLoading: 0}, () => {
            this.loadPage(callback);
        });
        return;
        var { page, itemsperPage, currentFilters, FIDIdentity, FIDCausalView, ShowCart, viewType } = this.state;
        if (viewType !== 'product') {
            currentFilters = {};
        }


        var filters = this.getFiltersArray(currentFilters);

        var { CatalogFIDPricelist } = this.props;
        if (!CatalogFIDPricelist && this.props.params)
            CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;

        var showDB = currentFilters.ShowModels ? true : false;
        var showDM = currentFilters.ShowStock ? true : false;
        var groupType = currentFilters.GroupType;
        var imageView = currentFilters.ImageView;
        var showNews = currentFilters.ShowNews ? true : false;
        var searchTerm = currentFilters.SearchTerm;

        var IdentityFilter = "";
        if (FIDIdentity) {
            IdentityFilter = "&FIDIdentity=" + FIDIdentity;
        }
        var CausalFilter = "";
        if (FIDCausalView) {
            CausalFilter = "&FIDCausal=" + FIDCausalView;
        }
        //if(this.state.ShowCart && this.props.CatalogFIDCausal) {
        //    CausalFilter = "&FIDCausal=" + this.props.CatalogFIDCausal;
        //}
        //if (!CausalFilter && !this.props.IsCatalog && this.state.HasCart && this.state.ShowCart) {
        //	CausalFilter = "&FIDCausal=" + (this.props.params.DOCPresentationCausalId||10);
        //}
        var documentFilter = "";
        if (this.props.match.params.id || (this.state.documentID && FIDCausalView)) {
            var docID = this.props.match.params.id || this.state.documentID;
            documentFilter = "&FIDDocument=" + docID;
        }
        var pricelistFilter = "";
        if (this.props.FIDProfile !== this.props.params.CustomerTypologyId) {
            if (CatalogFIDPricelist) {
                pricelistFilter = `&FIDPricelist=${this.props.CatalogFIDPricelist}`;
            } else if (this.props.FIDIRPricelist) {
                pricelistFilter = `&FIDPricelist=${this.props.FIDIRPricelist}`;
            }
        }

        if (!currentFilters.Sort) {
            currentFilters.Sort = [];
        }
        var sort = currentFilters.Sort.map(item => ({ Field: item.Value.ID, Direction: item.Sort }));
        var time = Date.now();
        this.setState({ isLoadingPage: true, loadTimeStamp: time }, () => {
            var link = '/BOP/GetCatalogueNew?sortColumn=Code&sortDirection=ASC&skip=' + ((page - 1) * itemsperPage) +
                '&items=' + itemsperPage +
                '&filters=' + JSON.stringify(filters) +
                '&showDB=' + showDB +
                '&showDM=' + showDM +
                '&showNews=' + showNews +
                '&groupType=' + groupType +
                '&imageView=' + imageView +
                '&searchTerm=' + (searchTerm || '') +
                '&sort=' + JSON.stringify(sort) +
                '&ShowCart=' + ShowCart +
                '&IsCatalog=' + !!this.props.isCatalog +
                '&showB2BPrice=' + this.state.HasWholesalePrice +
                IdentityFilter + CausalFilter + documentFilter + pricelistFilter;
            fetch(link).then(res => res.json())
                .then(data => {
                    if (this.state.loadTimeStamp === time) {
                        this.setState({
                            data: data.data,
                            count: data.count,
                            isLoadingPage: false,
                            isLoading: false,
                            documentID: data.documentID,
                            totDocument: data.totDocument,
                            totDocumentDisc: data.totDocumentDisc,
                            totDocumentTaxable: data.totDocTaxable,
                            totDocumentDiscTaxable: data.totDocDiscTaxable,
                            currency: data.currency
                        }, () => {
                            if (callback) {
                                callback();
                            }
                        });
                    }
                });
        });
    }
    getFiltersArray = (tempFilters, noPrice = false) => {
        var filters = [];
        if (tempFilters.FIDBrand) {
            filters.push({
                key: "FIDBrand",
                term: tempFilters.FIDBrand,
                comparison: "=",
                type: "int"
            });
        }
        if (tempFilters.FIDGroup) {
            filters.push({
                key: "FIDGroup",
                term: tempFilters.FIDGroup,
                comparison: "=",
                type: "int"
            });
        }
        if (tempFilters.FIDCategory) {
            filters.push({
                key: "FIDCategory",
                term: tempFilters.FIDCategory,
                comparison: "=",
                type: "int",
            });
        }
        if (tempFilters.FIDLine) {
            filters.push({
                key: "FIDLine",
                term: tempFilters.FIDLine,
                comparison: "=",
                type: "int",
            });
        }
        if (tempFilters.FIDSeries) {
            filters.push({
                key: "FIDSeries",
                term: tempFilters.FIDSeries,
                comparison: "=",
                type: "int",
            });
        }
        if (tempFilters.FIDPosition) {
            filters.push({
                key: "FIDPosition",
                term: tempFilters.FIDPosition,
                comparison: "=",
                type: "int",
            });
        }
        if (!noPrice && tempFilters.Price && tempFilters.Price.length === 2) {
            filters.push({
                key: "price",
                term: tempFilters.Price,
                type: 'limits_int'
            });
        }
        if (tempFilters.FIDCatalogueGroup) {
            filters.push({
                key: "FIDCatalogueGroup",
                term: tempFilters.FIDCatalogueGroup,
            });
        }
        if (tempFilters.FIDTag) {
            filters.push({
                type:"in_query",
                key: "ID",
                query: `SELECT FIDBOP FROM tblBOPTagBOPS WHERE FIDTag = ${tempFilters.FIDTag}`,
            });
        }
        var likeSearch = ["Group", "Brand", "Category", "Typology", "Code", "Description", "SerialNumber", "Barcode", "Position", "Line"];
        for (var i = 0; i < likeSearch.length; i++) {
            var key = likeSearch[i];
            if (tempFilters[key]) {
                filters.push({
                    key: key,
                    term: tempFilters[key],
                    type: 'text',
                    comparison: 'like'
                });
            }
        }
        return filters;
    }
    loadFiltersData = () => {

        var { tempFilters, FIDIdentity, FIDCausalView } = this.state;
        var filters = this.getFiltersArray(tempFilters);
        var filtersNoPrice = this.getFiltersArray(tempFilters, true);

        var showDB = tempFilters.ShowModels ? true : false;
        var showDM = tempFilters.ShowStock ? true : false;
        var showNews = tempFilters.ShowNews ? true : false;
        var imageView = tempFilters.ImageView;

        var IdentityFilter = "";
        if (FIDIdentity) {
            IdentityFilter = "&FIDIdentity=" + FIDIdentity;
        }

        var CausalFilter = "";
        if (FIDCausalView) {
            CausalFilter = "&FIDCausal=" + FIDCausalView;
        }
        var documentFilter = "";
        if (this.props.match.params.id) {
            documentFilter = "&FIDDocument=" + this.props.match.params.id;
        }


        var paramsNoPrice = '?filters=' + JSON.stringify(filtersNoPrice) + '&showDB=' + showDB + '&showDM=' + showDM + '&showNews=' + showNews + '&imageView=' + imageView + IdentityFilter + CausalFilter + documentFilter;
        var params = '?filters=' + JSON.stringify(filters) + '&showDB=' + showDB + '&showDM=' + showDM + '&showNews=' + showNews + '&imageView=' + imageView + IdentityFilter + CausalFilter + documentFilter;




        fetch('/BOP/GetBOPGroups' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.groups = data;
                    return state;
                })
            });

        fetch('/gc/template/catalogue/').then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.templates = data.map(item => ({ ID: item.ID, Label: item.Name }));
                    return state;
                })
            });
        fetch('/BOP/GetBOPCategories' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.categories = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPBrands' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.brands = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPPrices' + paramsNoPrice).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.prices = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPLines' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.lines = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPSeries' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.series = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPPositions' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.positions = data;
                    return state;
                })
            });
    }
    previousPage = () => {
        this.setState((state, props) => {
            state.page--;
            return state;
        }, this.loadData);
    }
    nextPage = () => {
        this.setState((state, props) => {
            state.page++;
            return state;
        }, this.loadData);
    }
    handleFilterButton = (event) => {
        this.setState(state => {
            state.showFiltersRow = !state.showFiltersRow;
            return state;
        });
    }
    onAdvancedFilterButton = (event) => {
        //this.setState({ showFilters: true });
        
        this.loadFiltersData();
        this.setState(state => {
            state.showFilters = true;
            state.tempFilters = state.currentFilters;
            return state;
        });
    }
    onClearFilterButton = (event) => {
        this.setState(state => {
            state.tempFilters = Object.assign({}, defaultFilters);
            state.currentFilters = Object.assign({}, defaultFilters);
            state.page = 1;
            state.viewType = undefined;
            return state;
        }, () => {
            this.loadData();
                this.loadFiltersData();
                this.setCollectionTitle();
        });
    }

    onClearTempFilterButton = (event) => {
        this.setState(state => {
            state.tempFilters = Object.assign({}, defaultFilters);
            return state;
        }, () => {
            this.loadFiltersData();
        });
    }
    onTemplateNameChange = (id) => (event, value) => {
        this.setState(state => {
            state.tempFilters[id] = value;
            return state;
        });
    }
    onSaveTemplate = (event) => {
        var { tempFilters } = this.state;
        var filters = tempFilters;
        if (filters.Template) {
            //conferma aggiornamento
            this.MessageBox.current.showMessageYesNo("Template ricerca", "Confermi aggiornamento template?", this.saveTemplate);
        }
        else {
            //inserimento nuovo
            this.MessageBox.current.showInputMessage('Template ricerca', "", "Nome template", "Salva", "Annulla", () => {
                var templateName = this.MessageBox.current.getInputValue();
                this.setState(state => {
                    state.tempFilters.TemplateName = templateName;
                    return state;
                }, () => {
                    this.saveTemplate();
                });
            });
        }
    }
    onDeleteTemplate = (event) => {
        this.deleteTemplate();
    }
    onFilterClose = () => {
        var filters = this.state.currentFilters;
        this.setState(state => {
            state.showFilters = false;
            state.tempFilters = Object.assign({}, filters);
            return state;
        }, () => {
            this.loadFiltersData();
        });
    }
    onSetFilters = () => {
        var filters = this.state.tempFilters
        this.setState(state => {
            state.showFilters = false;
            state.currentFilters = Object.assign({}, filters);
            state.page = 1;
            state.viewType = "product";
            return state;
        }, () => {
            this.getCollections();
            this.loadData();
        });
    }
    onSortChange = (newSort) => {
        this.setState(state => {
            state.tempFilters.Sort = newSort;
            return state;
        });
    }
    onFilterChange = async (key, value) => {
        if (key === "Template") {
            fetch('/gc/template/catalogue/' + value).then(res => res.json()).then(data => {
                this.setState(state => {
                    state.tempFilters = data;
                    state.tempFilters[key] = value;

                    return state;
                }, () => {
                    this.loadFiltersData();
                });
            });
        }
        else {
            this.setState(state => {
                state.tempFilters[key] = value;
                return state;
            }, () => {
                this.loadFiltersData();
            });
        }
    }
    onFieldFilterChange = (key) => (event, value) => {
        this.setState(state => {
            state.currentFilters[key] = value;
            state.tempFilters[key] = value;
            state.filterChanged = true;
            return state;
        }, () => {
            //this.loadData();
            //this.loadFiltersData();
        });
    }
    onFieldFilterBlur = (id) => () => {
        if (this.state.filterChanged) {
            this.setState({ filterChanged: false }, () => {
                this.loadData();
                this.loadFiltersData();
            });
        }
    }
    onFieldFilterKeyDown = (id) => (event) => {
        if (event.keyCode === 13) {
            this.setState({ filterChanged: false }, () => {
                this.loadData();
                this.loadFiltersData();
            });
        }
    }
    onItemClick = (index) => (item) => {
        this.setState({ currentIndex:index, currentItem: item, showItem: true/*, scrollTop: this.CardsCollection.current.scrollTop /*document.getElementById('mainPanel').scrollTop*/ });
    }
    handleCatalgoItemClose = () => {

        this.setState({ currentItem: {}, itemStar: {}, showItem: false }, () => {
            document.getElementById('mainPanel').scrollTo(0, this.state.scrollTop);
        });
    }
    onShowRemarks = () => {
        this.setState({ showRemarks: true });
    }
    onHideRemarks = () => {
        this.setState({ showRemarks: false });
    }
	onAddItem = async(item, FIDCausal, stars, BOPSelectionModes, callback) => {
		var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
		
		if (FIDIdentity === this.props.FIDLicensee && this.props.CatalogFIDIdentity)
			FIDIdentity = this.props.CatalogFIDIdentity;
		
		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
		var identitySelected = FIDIdentity || this.props.CatalogFIDIdentity;
		
		if (!item.TotalPrice) {
            item.TotalPrice = item.Price;
        }
        if(!this.props.isCatalog && BOPSelectionModes && BOPSelectionModes.length > 0){
            var BOPSelection = {
				EnabledModes: BOPSelectionModes,
                Price: item.Price,
                Discount: item.Discount,
                TotalPrice: item.TotalPrice,
                FIDCausal:  FIDCausal,
                Code: item.Code,
                BOP: item,
                VariantUnavailable: item.variantUnavailable,
                VariantExtras: [],
                InternalRemark: item.internalRemark,
                PublicRemark: item.publicRemark,
                //FIDPricelist: CatalogFIDPricelist,
                DueDate: item.DueDate
            }
            this.setState({ FIDBOP: item.ID, BOPSelection });
            return;
        } else {
			this.saveDocument(stars, item, FIDIdentity, FIDCausal, (this.state.rowQty||1), callback, true, identitySelected);
		}

	}
    onNoteItem = (item, callback) => {
        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
		
		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
		var identitySelected = FIDIdentity || this.props.CatalogFIDIdentity;
        // if (FIDIdentity) {
        this.saveDocument(-1, item, FIDIdentity, this.props.params.DOCPresentationCausalId,1, callback, false, identitySelected);
        // }
        // else {
        //     this.setState({
        //         stars: -1, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCPresentationCausalId, noteCallback: callback, rowQty: 1 });
        // }
    }
    onStarItem = (item, stars) => {
        if (this.props.match.params.id) {
            return;
        }
        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
		
		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
		var identitySelected = FIDIdentity || this.props.CatalogFIDIdentity;
        //if (FIDIdentity) {
            this.saveDocument(stars, item, FIDIdentity, this.props.params.DOCPresentationCausalId,1, undefined, false, identitySelected);
        //}
        //else {
            //this.setState({ stars, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCPresentationCausalId, rowQty: 1 });
        //}

        //this.setState({FIDBOP: item.ID});
    }
    onBuyItem = (item,qty=1, catalogCausal=false, refresh = true, callback) => {
        if (this.props.match.params.id) {
            return;
        }
        
        if (!item.TotalPrice) {
            item.TotalPrice = item.Price;
        }
        if(!this.props.isCatalog){
            var BOPSelection = {
                Price: item.Price,
                Discount: item.Discount,
                TotalPrice: item.TotalPrice,
                FIDCausal:  this.props.params.DOCCartCausalId,
                Code: item.Code,
                BOP: item,
                VariantUnavailable: item.variantUnavailable,
                VariantExtras: [],
                InternalRemark: item.internalRemark,
                PublicRemark: item.publicRemark,
                //FIDPricelist: CatalogFIDPricelist,
                DueDate: item.DueDate
            }
            this.setState({ FIDBOP: item.ID, BOPSelection });
            return;
        }

        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
		
		if (FIDIdentity === this.props.FIDLicensee && this.props.CatalogFIDIdentity)
			FIDIdentity = this.props.CatalogFIDIdentity;
		
		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
		var identitySelected = FIDIdentity || this.props.CatalogFIDIdentity;
		
        var FIDCausal = this.props.params.DOCCartCausalId;
        if (catalogCausal) {
            FIDCausal = this.props.CatalogFIDCausal;
            if (!FIDCausal) {
                if (this.props.params.DOCCatalogCausals && this.props.params.DOCCatalogCausals.length === 1) {
                    FIDCausal = this.props.params.DOCCatalogCausals[0];
                    this.props.setCatalogCausal(FIDCausal);
                }
            }
        }

        //if (FIDIdentity && FIDCausal) {
        //if (FIDIdentity) {
            this.saveDocument(6, item, FIDIdentity, FIDCausal, qty, callback, refresh, identitySelected);
        //}
        //else {
            //this.setState({ stars: 6, itemStar: Object.assign({}, item), FIDCausal: FIDCausal, rowQty: qty, refresh });
        //}
    }

    onNoticeItem = (item) => {
        if (this.props.match.params.id) {
            return;
        }
        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
		
		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
		var identitySelected = FIDIdentity || this.props.CatalogFIDIdentity;
        // if (FIDIdentity) {
            this.saveDocument(-1, item, FIDIdentity, this.props.params.DOCNoticeCausalId,1, undefined, false, identitySelected);
        // }
        // else {
        //     this.setState({ stars: -1, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCNoticeCausalId, rowQty: 1 });
        // }
    }

    onNegotiateItem = (item) => {
        if (this.props.match.params.id) {
            return;
        }


        
        if (!item.TotalPrice) {
            item.TotalPrice = item.Price;
        }
        if(!this.props.isCatalog){
            var BOPSelection = {
                Price: item.Price,
                Discount: item.Discount,
                TotalPrice: item.TotalPrice,
                FIDCausal:  39,
                Code: item.Code,
                BOP: item,
                VariantUnavailable: item.variantUnavailable,
                VariantExtras: [],
                InternalRemark: item.internalRemark,
                PublicRemark: item.publicRemark,
                //FIDPricelist: CatalogFIDPricelist,
                DueDate: item.DueDate
            }
            this.setState({ FIDBOP: item.ID, BOPSelection });
            return;
        }

        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
		var identitySelected = FIDIdentity || this.props.CatalogFIDIdentity;
        // if (FIDIdentity) {
            this.saveDocument(-1, item, FIDIdentity, 39,1, undefined, false, identitySelected);
        // }
        // else {
        //     this.setState({ stars: -1, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCNoticeCausalId, rowQty: 1 });
        // }
    }
    onReserveItem = (item) => {
        if (this.props.match.params.id) {
            return;
        }
        var { FIDIdentity } = this.state;
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }

		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
		var identitySelected = FIDIdentity || this.props.CatalogFIDIdentity;
        // if (FIDIdentity) {
            this.saveDocument(-1, item, FIDIdentity, this.props.params.DOCReservationCausalId,1, undefined, false, identitySelected);
        // }
        // else {
        //     this.setState({ stars: -1, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCReservationCausalId, rowQty: 1 });
        // }
    }
    
    onQuoteItem = (item) => {
        if (this.props.match.params.id) {
            return;
        }

        if (!item.TotalPrice) {
            item.TotalPrice = item.Price;
        }
        if(!this.props.isCatalog){
            var BOPSelection = {
                Price: item.Price,
                Discount: item.Discount,
                TotalPrice: item.TotalPrice,
                FIDCausal:  this.props.params.DOCQuoteCausalId,
                Code: item.Code,
                BOP: item,
                VariantUnavailable: item.variantUnavailable,
                VariantExtras: [],
                InternalRemark: item.internalRemark,
                PublicRemark: item.publicRemark,
                //FIDPricelist: CatalogFIDPricelist,
                DueDate: item.DueDate
            }
            this.setState({ FIDBOP: item.ID, BOPSelection, noStock:true });
            return;
        }



        var { FIDIdentity } = this.state; 
		if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
		
		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
		var identitySelected = FIDIdentity || this.props.CatalogFIDIdentity;
		
        // if (FIDIdentity) {
            this.saveDocument(-1, item, FIDIdentity, this.props.params.DOCQuoteCausalId,1, undefined, false, identitySelected);
        // }
        // else {
        //     this.setState({ stars: -1, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCBookingCausalId, rowQty: 1 });
        // }
    }
    onBookItem = (item) => {
        if (this.props.match.params.id) {
            return;
        }

        if (!item.TotalPrice) {
            item.TotalPrice = item.Price;
        }
        if(!this.props.isCatalog){
            var BOPSelection = {
                Price: item.Price,
                Discount: item.Discount,
                TotalPrice: item.TotalPrice,
                FIDCausal:  this.props.params.DOCBookingCausalId,
                Code: item.Code,
                BOP: item,
                VariantUnavailable: item.variantUnavailable,
                VariantExtras: [],
                InternalRemark: item.internalRemark,
                PublicRemark: item.publicRemark,
                //FIDPricelist: CatalogFIDPricelist,
                DueDate: item.DueDate
            }
            this.setState({ FIDBOP: item.ID, BOPSelection });
            return;
        }



        var { FIDIdentity } = this.state; 
		if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
		
		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
		var identitySelected = FIDIdentity || this.props.CatalogFIDIdentity;
        // if (FIDIdentity) {
            this.saveDocument(-1, item, FIDIdentity, this.props.params.DOCBookingCausalId,1, undefined, false, identitySelected);
        // }
        // else {
        //     this.setState({ stars: -1, itemStar: Object.assign({}, item), FIDCausal: this.props.params.DOCBookingCausalId, rowQty: 1 });
        // }
    }
    setBOPSelectionForEdit = async (item,FIDCausal) => {
        var { FIDIdentity } = this.state; 
		if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
        var data = await fetch(`/IR/GetPresentationInfo/${item.ID}?FIDCausal=${FIDCausal}&FIDIdentity=${FIDIdentity}`).then(res => res.json());
        
        var BOPSelection = {
            Price: item.Price,
            Discount: item.Discount,
            TotalPrice: item.TotalPrice,
            FIDCausal:  FIDCausal,
            Code: item.Code,
            BOP: item,
            VariantUnavailable: item.variantUnavailable,
            VariantExtras: [],
            InternalRemark: item.internalRemark,
            PublicRemark: item.publicRemark,
            //FIDPricelist: CatalogFIDPricelist,
            DueDate: item.DueDate,
            VariantUnavailable: item.variantUnavailable,
            VariantExtras: item.selectedVariants
        }
        this.setState({ FIDBOP: item.ID, BOPSelection,BOPSelectionEdit: data, noStock: FIDCausal === this.props.params.DOCQuoteCausalId });
    }
    onRemoveItem = (item, FIDCausal, callback, hideMsg = false, FIDDOCRow = undefined, forceDelete = false) => {
        //if (this.props.match.params.id) {
        //    return;
        //}
        if(!this.props.isCatalog && !forceDelete){
            if ([this.props.params.DOCCartCausalId,this.props.params.DOCBookingCausalId,39, this.props.params.DOCQuoteCausalId].includes(FIDCausal)){
                this.setBOPSelectionForEdit(item,FIDCausal);
                return;
            }
        }
        this.setState(state => {
			state.isLoading = true;
			
			if (this.props.isCatalog) {
				state.currentItem = {};
				state.itemStar = {};
			}
			return state;
		});
        
		if (!hideMsg) {
			this.MessageBox.current.showMessageYesNo(getTranslation(5,"GC_REMOVE",this.props.translations, "Rimuovi"), getTranslation(1,"CAT_DELFROMDOC",this.props.translations, "Confermi la rimozione del prodotto dalla quotazione?"),() => {
            this.doRemoveItem(item, FIDCausal, callback, FIDDOCRow);
			},() =>{
			var currentItem = {};
			var itemStar = {};
            this.setState({isLoading: false, currentItem, itemStar});
			});
		} else {
			this.doRemoveItem(item, FIDCausal, () =>{
				var currentItem = {};
				var itemStar = {};
				this.setState({isLoading: false,  currentItem, itemStar});
			}, FIDDOCRow);
		}
    }
    doRemoveItem = (item, FIDCausal, callback, FIDDOCRow = undefined) =>{
        this.setState({ isLoading: true, itemStar: Object.assign({}, item) });
        var { FIDIdentity } = this.state; 
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
        if (FIDIdentity) {
            this.removeFromDocument(item, FIDIdentity, FIDCausal, callback, FIDDOCRow);
        }
        else if (this.props.match.params.id) {

            this.removeFromDocument(item, undefined, undefined, callback, FIDDOCRow);
        }
        else {
            this.removeFromDocument(item, undefined, FIDCausal, callback, FIDDOCRow);
        }
    }
    goBack = () => {

        if (this.props.match.params.id) {
            this.context.router.history.push('/Documents/');
        }
    }
    removeFromDocument = (item, FIDIdentity, FIDCausal, callback, FIDDOCRow = undefined) => {
        
        var document = {
            FIDBOP: item.ID,
            IsCatalog: this.props.isCatalog,
            Price: item.Price,
            Code: item.Code,
            BOP: item
        };
		
		if (FIDDOCRow) {
			document.FIDDOCRow = FIDDOCRow;	
		}
		
        var url = '/IR/RemoveFromDocument/' + FIDIdentity + '?FIDCausal=' + FIDCausal;
        if (this.props.match.params.id || this.state.documentID) {
			var docID = this.props.match.params.id || this.state.documentID;
            url = '/IR/RemoveFromDocumentWithId/' + docID;
        }
        fetch(url, {
            body: JSON.stringify(document),
            method: 'POST'
        }).then(res => res.json()).then(data => {
            if(data.deletedHeader){
                this.onDocumentDeleted(callback);
                return;
            }
            if (this.props.match.params.id || this.state.documentID) {
                this.setState({ showItem: false, isLoading: false });
                this.loadData();
                callback && callback();
            }
            else {
                this.onDocumentComplete(FIDIdentity, undefined, undefined, callback);
            }
        });
    }
    saveDocumentNew = (stars, item, FIDCausal) => (FIDIdentity) => {
        var callback = undefined;
        if (this.state.noteCallback) {
            callback = this.state.noteCallback;
            
        }

        this.saveDocument(stars, item, FIDIdentity, FIDCausal, this.state.rowQty, callback, true, true);
    }
    saveDocument = (stars, item, FIDIdentity, FIDCausal, qty, callback, refresh=true, identitySelected = false) => {
        var { isCatalog, CatalogFIDPricelist, CatalogFIDIdentity } = this.props
		
		if (!item.TotalPrice)
			item.TotalPrice = item.Price;
		
		if (!CatalogFIDPricelist && this.props.params)
			CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;
		
		if (!FIDIdentity && this.props.FIDProfile === this.props.params.CustomerTypologyId) {
			FIDIdentity = this.props.FIDIdentity;
		}
		else if (FIDIdentity && !identitySelected && this.props.FIDProfile === this.props.params.AgentTypologyId) {
			FIDIdentity = CatalogFIDIdentity;
        }
		
		var isTaxable = !item.PriceVatIncluded;
		
        var document = {
            IsCatalog: isCatalog,
            Stars: stars,
            FIDBOP: item.ID,
            Price: item.Price,
            Discount: item.Discount,
            TotalPrice: item.TotalPrice,
            FIDCausal: FIDCausal,
            Code: item.Code,
            BOP: item,
            VariantUnavailable: item.variantUnavailable,
            VariantExtras: [],
            InternalRemark: item.internalRemark,
            PublicRemark: item.publicRemark,
            Qty: qty,
            FIDPricelist: this.props.FIDIRPricelist || CatalogFIDPricelist,
            DueDate: item.DueDate,
			IsTaxable: isTaxable
        };

        //if (item.variantUnavailable) {
            document.VariantExtras = item.selectedVariants;
        //}

        this.setState({ itemStar: Object.assign({},item), refresh }, () => {
            //var FIDCausal = stars === 6 ? 10 : 11;
            var url = '/IR/SaveDocument/?FIDIdentity=' + FIDIdentity;
			
			if (this.state.documentID) {
				url += '&FIDDocument='+ this.state.documentID;
			}

            fetch(url, {
                body: JSON.stringify(document),
                method: 'POST'
			}).then(res => res.json()).
			then (res => {
				if (res.res) {
					if (!this.state.documentID) {
						this.setState({
							documentID: res.documentID,
						}, () => {
							this.onDocumentComplete(FIDIdentity, stars, FIDCausal, callback);
						});
					}
					else {
						this.onDocumentComplete(FIDIdentity, stars, FIDCausal, callback);
					}
				}
            });
        });
        
    }

    onDocumentComplete = (FIDIdentity, stars, FIDCausal, callback) => {
        var { CatalogFIDPricelist } = this.props;
		
		if (!CatalogFIDPricelist && this.props.params)
			CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;
        //this.setState({ stars: undefined, itemStar: {} });
        this.setState(state => {
            state.stars = undefined;
            //state.itemStar = {};

            if (JSON.stringify(state.itemStar) !== JSON.stringify({})) {
                state.currentItem = Object.assign({}, state.itemStar);
            }
            state.itemStar = {};
            state.currentItem.Qty2 = stars === 6 ? 1 : stars;
            state.currentItem.FIDCausal = FIDCausal;
            state.FIDIdentity = FIDIdentity;
            //state.FIDCausal = FIDCausal;
            if(!this.props.isCatalog) {
                this.props.setCatalogCausal(this.props.params.DOCPresentationCausalId);
            }
            //if (state.FIDIdentity !== FIDIdentity) {
            //    state.FIDIdentity = FIDIdentity;
            //    this.reloadIdentityInfo(FIDIdentity);
            //}

            return state;
        }, () => {
            this.reloadIdentityInfo(FIDIdentity, callback);
            fetch(`/BOP/GetTOTDoc?FIDDocument=${this.state.documentID}&FIDCausal=${FIDCausal}&FIDIdentity=${FIDIdentity}` + (CatalogFIDPricelist ? `&FIDPricelist=${CatalogFIDPricelist}` : "")).then(res => res.json()).then(data => {
                this.setState({
                    documentID: data.documentID,
                    totDocument: data.totDocument,
                    totDocumentDisc: data.totDocumentDisc,
                    totDocTaxable: data.totDocumentTaxable,
                    totDocDiscTaxable: data.totDocumentDiscTaxable,
                    currency: data.currency,
                    lastAction: `lastAction_${FIDCausal}`
                });
                this.CatalogueItem.current && this.CatalogueItem.current.getItemStatus();
            });
        });
    }
    reloadIdentityInfo = (FIDIdentity, callback) => {
        fetch('/IR/CatalogueInfo/' + FIDIdentity + '?IsCatalog='+!!this.props.isCatalog).then(res => res.json()).then(data => {
            this.setState(state => {
				state.documentID = data.DocID;
                state.IRAlias = data.Identity && (data.Identity.Alias || data.Identity.Denomination);
                state.HasPresentations = data.HasPresentations;
                state.HasCart = data.HasCart;
                state.HasReservations = data.HasReservations;
                state.HasNotice = data.HasNotice;
                state.HasBookings = data.HasBookings;
				state.HasQuotes = data.HasQuotes;
				state.HasNegotiations = data.HasNegotiations;
				state.HasCartRows = data.HasCartRows;
                state.HasWholesalePrice = data.HasWholesalePrice;
                state.FIDIdentity = FIDIdentity;
                state.FIDCausal = data.HasPresentations ? (this.props.params.DOCPresentationCausalId||10) : this.props.CatalogFIDCausal;
                state.IdentityInfo = data.Identity;
                state.Documents = data.Documents;

                var label = this.props.isCatalog ? getTranslation(1,"CAT_CATALOGUE", this.props.translations, "Catalogo") : getTranslation(1,"CAT_PRESENTATION", this.props.translations, "Presentazione");
                if (this.props.params.DOCCatalogCausals.includes(state.FIDCausal)) {
                    if (state.FIDCausal === this.props.params.DOCOrderCausalId) {
                        label = getTranslation(1,"CAT_ORDER", this.props.translations, "Ordine");
                    }
                    else {
                        label = getTranslation(1,"CAT_QOUTE", this.props.translations, "Quotazione");
                    }
                }
                if(state.IRAlias){
                    label += ` - ${state.IRAlias}`;
                }
                this.props.setTitle(label);
                this.props.setCatalogIdentity(FIDIdentity);
                if(!this.props.CatalogFIDCausal && state.Documents && state.Documents.length === 1){
                    this.props.setCatalogCausal(state.Documents[0].FIDCausal);
                }
                if (!this.props.match.params.id) {
                    if (!state.HasCartRows && !state.HasPresentations && !state.HasReservations && !state.HasNotice && !state.HasBookings && !this.props.isAgent && !this.props.params.CatalogueShowIRPLSelector && this.props.FIDProfile !== this.props.params.CustomerTypologyId) {
                        state.IRAlias = undefined;
                        state.FIDIdentity = undefined;
                        state.FIDCausalView = undefined;
                        this.props.setTitle(undefined);
                        this.props.setCatalogIdentity(undefined);
                        this.props.setCatalogCausal(undefined);
                        this.props.setCatalogPricelist(undefined);
                    }
                }
                else {
                    if (this.props.params.DOCCatalogCausals.includes(state.FIDCausal)) {
                        state.HasCart = true;
                    }
                    else {
                        state.HasPresentations = true;
                    }
                }
                return state;
            }, () => {
                if(this.state.refresh && (this.props.CatalogFIDIdentity || this.props.CatalogFIDPricelist) && ((!this.props.IsAgent || !this.props.params.CatalogueShowIRPLSelector) || this.props.FIDProfile === this.props.params.CustomerTypologyId)){
                    this.loadData(callback);
                }
                else {
					if (!callback)
						this.setState({refresh: true, isLoadingPage: false, isLoading: false});
					else
						this.setState({ refresh: true, isLoadingPage: false }, callback);
                }
            });
        });
    }

    ViewPresentation = () => {
        if (this.state.FIDCausalView === this.props.params.DOCPresentationCausalId) {
            return;
        }
        this.setState(state => {
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.oldFilters = Object.assign({}, state.currentFilters);
            state.oldTempFilters = Object.assign({}, state.tempFilters);
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = this.props.params.DOCPresentationCausalId
            state.viewType = "product";
            state.showItem = false;
            state.ShowCart = false;
            return state;
        }, this.loadData);
    }
    ViewCart = () => {
        if (this.state.ShowCart) {
            if (this.state.showItem) {
                this.setState({ showItem: false });
            }
            return;
        }
        this.setState(state => {
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.oldFilters = Object.assign({}, state.currentFilters);
            state.oldTempFilters = Object.assign({}, state.tempFilters);
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            //state.FIDCausalView = this.props.CatalogFIDCausal;
            state.FIDCausalView = undefined;
            state.ShowCart = true;
            state.viewType = "product";
            state.showItem = false;
            return state;
        }, this.loadData);
    }
    ViewNotice = () => {
        if (this.state.FIDCausalView === this.props.params.DOCNoticeCausalId) {
            return;
        }
        this.setState(state => {
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.oldFilters = Object.assign({}, state.currentFilters);
            state.oldTempFilters = Object.assign({}, state.tempFilters);
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = this.props.params.DOCNoticeCausalId
            state.viewType = "product";
            state.showItem = false;
            state.ShowCart = false;
            return state;
        }, this.loadData);
    }
    ViewReservation = () => {
        if (this.state.FIDCausalView === this.props.params.DOCReservationCausalId) {
            return;
        }
        this.setState(state => {
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.oldFilters = Object.assign({}, state.currentFilters);
            state.oldTempFilters = Object.assign({}, state.tempFilters);
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = this.props.params.DOCReservationCausalId
            state.viewType = "product";
            state.showItem = false;
            state.ShowCart = false;
            return state;
        }, this.loadData);
    }
    ViewBooking = () => {
        if (this.state.FIDCausalView === this.props.params.DOCBookingCausalId) {
            return;
        }
        this.setState(state => {
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.oldFilters = Object.assign({}, state.currentFilters);
            state.oldTempFilters = Object.assign({}, state.tempFilters);
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = this.props.params.DOCBookingCausalId
            state.viewType = "product";
            state.showItem = false;
            state.ShowCart = false;
            return state;
        }, this.loadData);
    }
    ViewAll = () => {
        this.setState(state => {
            state.tempFilters = {};
            state.currentFilters = {};
            state.page = 1;
            state.FIDCausalView = undefined
            return state;
        }, this.loadData);
    }

    generatePdf = (FIDDestination, callback) => {
        this.setState({ saveDocument: true, step: 5, FIDDestination: FIDDestination, callbackConfirm: callback });
    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }

    onDestinationSelect = (FIDIdentity) => {
        this.setState({FIDDestination: FIDIdentity},() =>{
            this.doConfirmDocument(this.state.callbackConfirm);
        });
        return;
        if(this.state.FIDCausal){
            this.generatePdf(FIDIdentity,this.state.callbackConfirm);
        }
        else {
			var catalogCausals = this.props.params.DOCCatalogCausals;
			
            this.setState(state => { 
				state.saveDocument = true;
				state.step = 3;
				state.FIDDestination = FIDIdentity;
				
				if (this.state.FIDCausal || catalogCausals.length === 1) {
					
					if (!this.state.FIDCausal)
						state.FIDCausal = catalogCausals[0];
					
					if (!this.props.isCatalog)
						state.step = 4;
					else
						state.step = 5;
				}
				
				return state;
			});
        }
    }
    doConfirmDocument = (callback) => {
        var { FIDIdentity, FIDDestination } = this.state;
        if(!FIDIdentity){
            this.setState({ saveDocument: true, step: 1, callbackConfirm: callback });
            return;
        }
        //verifico indirizzi
        fetch('/IR/HasMultipleDestinations/' + FIDIdentity).then(res => res.json()).then(data => {
            if (data && !FIDDestination) {
                //has multiple destinations
                this.setState({ saveDocument: true, step: 2, callbackConfirm: callback });
            }
            else {
				var FIDCausal = this.state.FIDCausal;
				
				
				var { FIDCausalPrint, FIDPrintOption, FIDPrintLanguage } = this.state;
				var isCatalog = this.props.isCatalog;
				var catalogCausals = this.props.params.DOCCatalogCausals;
				
				if (isCatalog && !catalogCausals.includes(FIDCausal)) {
					FIDCausal = undefined;
				}
				
				if (!FIDCausal && catalogCausals && catalogCausals.length === 1)
					FIDCausal = catalogCausals[0];
				
                //generate pdf
                if(FIDCausal){
					//if (this.props.isCatalog)
					//	this.generatePdf(FIDIdentity,callback);
					//else {
					if ((FIDPrintOption && FIDPrintLanguage) || this.props.isCatalog)
						this.generatePdf(FIDIdentity,callback);
					else
						this.setState({ saveDocument: true, step: 4, callbackConfirm: callback });
					//}
                }
                else {
                    this.setState({ saveDocument: true, step: 3, callbackConfirm: callback });
                }

            }
        });

        
    }
    handleConfirmDocumentFinal = () => {
        var { CatalogFIDPricelist } = this.props;

		if (!CatalogFIDPricelist && this.props.params)
			CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;
		
        var { FIDIdentity, FIDDestination } = this.state;
        var url = '/IR/ConfirmDocument/' + FIDIdentity + '?IsCatalog=' + this.props.isCatalog;
        if (FIDDestination) {
            url += "&FIDDestination=" + FIDDestination;
        }
        if ( CatalogFIDPricelist ) {
            url += "&FIDPricelist=" + CatalogFIDPricelist;
        }
        fetch(url).then(res => {
            this.setState({
                FIDCausalView: undefined,
                viewType: undefined,
                saveDocument: false,
                tempFilters: Object.assign({}, defaultFilters),
                currentFilters: Object.assign({}, defaultFilters),
                FIDIdentity: undefined,
                FIDDestination: undefined,
                FIDCausal: undefined,
                ShowCart: false,
                page: 1,
            }, () => {
                this.props.setCatalogCausal(undefined);
                this.props.setCatalogIdentity(undefined);
                this.props.setTitle(undefined);
                this.props.setCatalogPricelist(undefined);
                this.reloadIdentityInfo(undefined, this.state.callbackConfirm);
            });
        });
    }
    onDocumentDeleted = (callback) => {
        this.setState({
            FIDCausalView: undefined,
			currentItem: {},
			itemStar: {},
			refresh: true,
            viewType: undefined,
            saveDocument: false,
            tempFilters: Object.assign({}, defaultFilters),
            currentFilters: Object.assign({}, defaultFilters),
            FIDIdentity: undefined,
            FIDDestination: undefined,
            FIDCausal: undefined,
            ShowCart: false,
            showItem: false,
            isLoading: false,
            page: 1, }, () => {
            if (this.props.match.params.id) {
                this.goBack();
            }
            else {
                this.props.setCatalogIdentity(undefined);
                this.props.setCatalogCausal(undefined);
                this.props.setTitle(undefined);
                this.props.setCatalogPricelist(undefined);

                this.reloadIdentityInfo(undefined, callback);
            }
        });
    }
    doDeleteDocument = (callback) => {
        var { FIDIdentity } = this.state;
        var params = "";
        if (this.props.match.params.id) {
            params = "&FIDDocument=" + this.props.match.params.id;
        }
        fetch('/IR/DeleteDocument/' + FIDIdentity + '?IsCatalog=' + this.props.isCatalog + params).then(res => {
            this.onDocumentDeleted(callback);
        });
    }
    handleDeleteDocument = () => {
        var title = this.props.isCatalog ? getTranslation(1,"CAT_CATALOGUE", this.props.translations, "Catalogo") : getTranslation(1,"CAT_PRESENTATION", this.props.translations, "Presentazione");
        var msg = this.props.isCatalog ? (getTranslation(2,"DOC_DELETEMSG",this.props.translations, "Procedere con l'eliminazione del documento?")) : getTranslation(2,"DOC_DELETEMSG_PRES",this.props.translations, "Procedere con l'eliminazione di questa presentazione?");
        this.MessageBox.current.showMessageYesNo(title, msg, this.doDeleteDocument);
    }
    handleConfirmDocument = () => {
		var title = this.props.isCatalog ? getTranslation(1,"CAT_CATALOGUE", this.props.translations, "Catalogo") : getTranslation(1,"CAT_PRESENTATION", this.props.translations, "Presentazione");
		var msg = this.props.isCatalog ? (getTranslation(2,"DOC_CONFIRMMSG",this.props.translations, "Procedere con la conferma del documento?")) : getTranslation(2,"DOC_CONFIRMMSG_PRES",this.props.translations,"Procedere con la conferma di questa presentazione?");
		
		if (this.PrintOptionSelector.current)
		{
			if (!this.PrintOptionSelector.current.validate()) {
				msg = "Selezionare lingua e opzione di stampa";
				this.MessageBox.current.showMessageOk(title, msg);
				return;
			} else {
				let optSel = this.PrintOptionSelector.current;
				this.setState({
					FIDPrintLanguage: optSel.state.FIDLanguage,
					FIDPrintOption: optSel.state.FIDPrintOption,
					FIDCausalPrint: optSel.state.FIDCausal
				}, () => {
					this.MessageBox.current.showMessageYesNo(title, msg, this.doConfirmDocument);
				})
			}
		}   
        else
			this.MessageBox.current.showMessageYesNo(title, msg, this.doConfirmDocument);
    }
    handleSort = () => {
        this.setState(sort => {
            sort.showSort = !sort.showSort;
            return sort;
        });
    }

    handlePageExit = (location, action) => {
        if (location && location.pathname.startsWith("/BOPEdit/")) {
            return true;
        }
        
        if (!this.props.match.params.id && (this.state.HasPresentations || this.state.HasNotice || this.state.HasReservations || this.state.HasBookings || this.state.HasCart)) {
            var buttons = [
                {
                    label: getTranslation(2,'GC_CONFIRM',this.props.translations, "CONFERMA"),
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        this.doConfirmDocument(() => {
                            switch (action) {
                                case "push":
                                default:
                                    this.props.history.push(location.pathname);
                                    break;
                            }
                        });
                    }
                },
                {
                    label: getTranslation(2,'GC_DELETE',this.props.translations, "ELIMINA"),
                    action: () => {
                        this.MessageBox.current.hideDialog();
                        this.doDeleteDocument(() => {
                            switch (action) {
                                case "push":
                                default:
                                    this.props.history.push(location.pathname);
                                    break;
                            }
                        });
                    }
                },
                {
                    label: getTranslation(2,'GC_STAY',this.props.translations, "RIMANI"),
                    action: () => {
                        this.MessageBox.current.hideDialog();
                    }
                }
            ];

            var title = this.props.isCatalog ? getTranslation(1,"CAT_CATALOGUE", this.props.translations, "Catalogo") : getTranslation(1,"CAT_PRESENTATION", this.props.translations, "Presentazione");
            var msg = this.props.isCatalog ? (getTranslation(2,"DOC_CONFIRMMSG",this.props.translations, "Desideri confermare questo documento?")) : getTranslation(2,"DOC_CONFIRMMSG_PRES",this.props.translations,"Desideri confermare questa presentazione?");
            this.MessageBox.current.showDialog(buttons, title, msg);
            return false;
        }
        
        return true;
    }

    static contextTypes = {
        router: PropTypes.object
    }

    renderLoading = () => {
        return <XOXLoader />
    }
    renderMobileStepper = (stepperVariant, pages, page) => {
        return (<MobileStepper
            variant={stepperVariant}
            steps={pages}
            position="static"
            activeStep={page - 1}
            backButton={
                <Button size="small" onClick={this.previousPage} disabled={page === 1 || this.state.isLoadingPage}>
                    <KeyboardArrowLeft />
                </Button>
            }
            nextButton={
                <Button size="small" onClick={this.nextPage} disabled={page === pages || this.state.isLoadingPage}>
                    <KeyboardArrowRight />
                </Button>
            }

        />)
    }
    saveTemplate = () => {
        var { tempFilters, filtersData } = this.state;
        var data = {
            Name: getTranslation(1,"CAT_TEMPLATE",this.props.translations, "Template"),
            Json: JSON.stringify(tempFilters)
        };
        var url = '/gc/template/catalogue';
        if (tempFilters.Template) {
            url += "/" + tempFilters.Template;
            data.Name = filtersData.templates.filter(f => f.ID === tempFilters.Template)[0].Label;
        }
        else {
            data.Name = tempFilters.TemplateName;
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => res.json()).then(data => {
            this.loadFiltersData();
        });
    }
    deleteTemplate = () => {
        var { tempFilters } = this.state;
        if (tempFilters.Template) {
            this.MessageBox.current.showMessageYesNo("Template ricerca", "Confermi la cancellazione del template selezionato?", () => {
                fetch('/gc/template/catalogue/' + tempFilters.Template, {
                    method: "DELETE"
                }).then(res => {
                    this.setState(state => {
                        state.tempFilters.Template = undefined;
                        state.tempFilters.TemplateName = undefined;
                        return state;
                    }, this.loadFiltersData);
                });
            });
        }
    }
    showGroups = () => {
        if (this.state.viewType === "groups") {
            return;
        }
        this.setState(state => {
            state.viewType = "groups";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.currentFilters = Object.assign({}, defaultFilters);
            state.currentFilters.FIDCatalogueGroup = undefined;
            state.currentFilters.FIDBrand = undefined;
            state.currentFilters.FIDLine = undefined;
            state.currentFilters.FIDSeries = undefined;
            state.currentFilters.FIDCategory = undefined;
            state.currentFilters.ShowNews = false;
            state.currentFilters.SearchTerm = undefined;
            state.currentIndex = undefined;

            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.showItem = false;
            state.ShowCart = false;

            state.page = 1;
            return state;
        }, () => {
            this.getCollections();
            // this.loadFiltersData();
            // this.loadData();
            this.setCollectionTitle();
        });
    }
    showBrands = () => {
        if (this.state.viewType === "brand") {
            return;
        }
        this.setState(state => {
            state.viewType = "brand";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            var FIDCatalogueGroup = state.currentFilters.FIDCatalogueGroup;
            var ShowNews = state.currentFilters.ShowNews;
            state.currentFilters = Object.assign({}, defaultFilters);
            state.currentFilters.FIDCatalogueGroup = FIDCatalogueGroup;
            state.currentFilters.FIDBrand = undefined;
            state.currentFilters.FIDLine = undefined;
            state.currentFilters.FIDSeries = undefined;
            state.currentFilters.FIDCategory = undefined;
            state.currentIndex = undefined;

            state.currentFilters.ShowNews = ShowNews;
            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.showItem = false;
            state.ShowCart = false;

            state.page = 1;
            return state;
        }, () => {
            // this.loadFiltersData();
            // this.loadData();
            this.setCollectionTitle();
        });
    }
    showLines = () => {
        if (this.state.viewType === "line") {
            return;
        }
        this.setState(state => {
            state.viewType = "line";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            var FIDBrand = state.currentFilters.FIDBrand;
            var FIDCatalogueGroup = state.currentFilters.FIDCatalogueGroup;
            var ShowNews = state.currentFilters.ShowNews;
            state.currentFilters = Object.assign({}, defaultFilters);
            state.currentFilters.FIDBrand = FIDBrand;
            state.currentFilters.FIDCatalogueGroup = FIDCatalogueGroup;
            state.currentFilters.FIDLine = undefined;
            state.currentFilters.FIDSeries = undefined;
            state.currentFilters.FIDCategory = undefined;
            state.currentFilters.ShowNews = ShowNews;
            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.currentIndex = undefined;
            state.ShowCart = false;
            state.page = 1;
            return state;
        }, () => {
            // this.loadFiltersData();
            // this.loadData();
            this.setCollectionTitle();
        });
    }
    showSeries = () => {
        if (this.state.viewType === "series") {
            return;
        }
        this.setState(state => {
            state.viewType = "series";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;

            var FIDCatalogueGroup = state.currentFilters.FIDCatalogueGroup;
            var FIDBrand = state.currentFilters.FIDBrand;
            var FIDLine = state.currentFilters.FIDLine;
            var ShowNews = state.currentFilters.ShowNews;

            state.currentFilters = Object.assign({}, defaultFilters);
            state.currentFilters.FIDCatalogueGroup = FIDCatalogueGroup;
            state.currentFilters.FIDBrand = FIDBrand;
            state.currentFilters.FIDLine = FIDLine;
            state.currentFilters.FIDSeries = undefined;
            state.currentFilters.FIDCategory = undefined;
            state.currentFilters.ShowNews = ShowNews;
            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.currentIndex = undefined;
            state.ShowCart = false;
            state.page = 1;
            state.showItem = false;
            return state;
        }, () => {
            //this.loadFiltersData();
            // this.loadData();
            this.setCollectionTitle();
        });
    }
    showProducts = () => {
        if (this.state.viewType === "product" && !this.state.FIDCausalView && !this.state.ShowCart) {
            return;
        }
        this.setState(state => {
            state.viewType = "product";
			state.isLoading = true;
            state.page = 1;
            state.FIDCausalView = undefined;
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.showItem = false;
            state.ShowCart = false;
        }, () => {
            this.loadData();
        });
    }
    showTags = () => {
        if (this.state.viewType === "tag") {
            return;
        }
        this.setState(state => {
            state.viewType = "tag";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;

            var FIDCatalogueGroup = state.currentFilters.FIDCatalogueGroup;
            var FIDBrand = state.currentFilters.FIDBrand;
            var FIDLine = state.currentFilters.FIDLine;
            var FIDSeries = state.currentFilters.FIDSeries;

            state.currentFilters = Object.assign({}, defaultFilters);
            state.currentFilters.FIDCatalogueGroup = FIDCatalogueGroup;
            state.currentFilters.FIDBrand = FIDBrand;
            state.currentFilters.FIDLine = FIDLine;
            state.currentFilters.FIDSeries = FIDSeries;
            state.currentFilters.FIDCategory = undefined;
            state.currentFilters.FIDTag = undefined;
            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.currentIndex = undefined;
            state.ShowCart = false;
            state.page = 1;
            state.showItem = false;
            return state;
        }, () => {
            // this.loadFiltersData();
            // this.loadData();
            this.setCollectionTitle();
        });
    }
    showCategories = () => {
        if (this.state.viewType === "category") {
            return;
        }
        this.setState(state => {
            state.viewType = "category";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;

            var FIDCatalogueGroup = state.currentFilters.FIDCatalogueGroup;
            var FIDBrand = state.currentFilters.FIDBrand;
            var FIDLine = state.currentFilters.FIDLine;
            var FIDSeries = state.currentFilters.FIDSeries;

            state.currentFilters = Object.assign({}, defaultFilters);
            state.currentFilters.FIDCatalogueGroup = FIDCatalogueGroup;
            state.currentFilters.FIDBrand = FIDBrand;
            state.currentFilters.FIDLine = FIDLine;
            state.currentFilters.FIDSeries = FIDSeries;
            state.currentFilters.FIDCategory = undefined;
            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.currentIndex = undefined;
            state.ShowCart = false;
            state.page = 1;
            state.showItem = false;
            return state;
        }, () => {
            // this.loadFiltersData();
            // this.loadData();
            this.setCollectionTitle();
        });
    }
    onItemEditClick = (data) => {
        //save cookies
        this.setState(state => {
            state.CatalogFIDCausal = this.props.CatalogFIDCausal;
            state.CatalogFIDIdentity = this.props.CatalogFIDIdentity;
            state.CatalogFIDPricelist = this.props.CatalogFIDPricelist;
            return state;
        },() => {
            setCookie('catalogue_state', JSON.stringify(this.state));
            this.context.router.history.push('/BOPEdit/' + data.ID);
        })
        
    }
    onCollectionCardClick = (viewType, data) => {
        var { collections } = this.state;
        switch (viewType) {
            case "brand":
                this.setState(state => {
					state.isLoading = true;
                    //var count = collections.lines.filter(item => item.FIDBrand === data.FIDBrand).length;
                    //var count = collections.lines.filter(item => item.FIDBrand === data.FIDBrand).length;
                    var count = data.LinesCount;
                    if (count <= 1) {
                        state.viewType = "product";
                    }
                    else {
                        state.viewType = "line";
                    }
                    state.currentFilters.FIDBrand = data.FIDBrand;
                    state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    //this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData(() => { this.setState({isLoading: false})});
                    } else {
						this.setState({isLoading: false})
                    }
                    this.setCollectionTitle();
                });
                break;
            case "line":
                this.setState(state => {
					state.isLoading = true;
                    //var count = collections.series.filter(item => item.FIDLine === data.FIDLine).length;
                    //var count = collections.series.filter(item => item.FIDLine === data.FIDLine).length;
                    var count = data.SeriesCount;
                    if (count <= 1) {
                        state.viewType = "product";
                    }
                    else {
                        state.viewType = "series";
                    }
                    state.currentFilters.FIDLine = data.FIDLine;
                    state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    //this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData(() => { this.setState({isLoading: false})});
                    }
					else {
						this.setState({isLoading: false})
					}
                    this.setCollectionTitle();
                });
                break;
            case "series":
                this.setState(state => {
					state.isLoading = true;
                    state.viewType = "product";
                    state.currentFilters.FIDSeries = data.FIDSeries;
                    state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    //this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData(() => { this.setState({isLoading: false})});
                    } else {
						this.setState({isLoading: false})
                    }
                    this.setCollectionTitle();
                });
                break;
            case "category":
                this.setState(state => {
					state.isLoading = true;
                    state.viewType = "product";
                    state.currentFilters.FIDCategory = data.FIDCategory;
                    state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    //this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData(() => { this.setState({isLoading: false})});
                    } else {
						this.setState({isLoading: false})
                    }
                    this.setCollectionTitle();
                });
				break;
            case "tag":
                this.setState(state => {
					state.isLoading = true;
                    state.viewType = "product";
                    state.currentFilters.FIDTag = data.FIDTag;
                    state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    //this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData(() => { this.setState({isLoading: false})});
                    } else {
						this.setState({isLoading: false})
                    }
                    this.setCollectionTitle();
                });
                break;
            case "groups":
                this.setState(state => {
                    state.isLoading = true;
                    var countBrands = 0;//collections.brands.filter(item => this.isInArray(item.CatalogueGroupsId,data.ID)).length;
                    var countLines = collections.lines.filter(item => this.isInArray(item.CatalogueGroupsId,data.ID)).length;
                    var countSeries = collections.series.filter(item => this.isInArray(item.CatalogueGroupsId,data.ID)).length;
                    if(countBrands > 1){
                        state.viewType = "brand";
                    }
                    else if(countLines > 1){
                        state.viewType = "line";
                    }
                    else if(countSeries > 1){
                        state.viewType = "series";
                    }
                    else {
                        state.viewType ="product";
                    }
                    state.currentFilters.FIDCatalogueGroup = data.ID;
                    state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    //this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData(() => { this.setState({isLoading: false})});
                    } else {
						this.setState({isLoading: false})
                    }
                    this.setCollectionTitle();
                });
                break;
            default:
                break;
        }
    }
    isInArray = (array, value) =>{
        if(!array){
            return false;
        }
        if (typeof array === "string") {
            array = array.split(',').map(Number);
        }
        return array.includes(value);
    }
    setCollectionTitle = async () => {
        var { currentFilters } = this.state;
        var title = "";
        if (currentFilters.FIDCatalogueGroup) {
            var catGRPInfo = await fetch('/BOP/GetCatalogueGroupInfo/' + currentFilters.FIDCatalogueGroup).then(res => res.json());
            title += catGRPInfo.Description;
        }
        if (currentFilters.FIDBrand) {
            var brandInfo = await fetch('/BOP/GetBrandInfo/' + currentFilters.FIDBrand).then(res => res.json());
            if (title !== "") {
                title += " / ";
            }
            title = brandInfo.Brand;
        }
        if (currentFilters.FIDLine) {
            var lineInfo = await fetch('/BOP/GetLineInfo/' + currentFilters.FIDLine).then(res => res.json());
            if (title !== "") {
                title += " / ";
            }
            title += lineInfo.Line;
        }
        if (currentFilters.FIDSeries) {
            var seriesInfo = await fetch('/BOP/GetSeriesInfo/' + currentFilters.FIDSeries).then(res => res.json());
            if (title !== "") {
                title += " / ";
            }
            title += seriesInfo.Series;
        }
        if (currentFilters.FIDCategory) {
            var seriesInfo = await fetch('/BOP/GetCategoryInfo/' + currentFilters.FIDCategory).then(res => res.json());
            if (title !== "") {
                title += " / ";
            }
            title += seriesInfo.Category;
        }
        if (currentFilters.FIDTag) {
            var seriesInfo = await fetch('/BOP/GetTagInfo/' + currentFilters.FIDTag).then(res => res.json());
            if (title !== "") {
                title += " / ";
            }
            title += seriesInfo.Tag;
        }
        if (title !== "") {
            this.props.setTitle(title);
        }
        else {
            this.props.setTitle(undefined);
        }
    }
    getFilters = () => {
        var { advancedNavigation } = this.props;
        var { tempFilters, filtersData, viewType, collections } = this.state;
        viewType = "product";
        if (!viewType) {
            if (collections && advancedNavigation) {
                if (collections.brands.length > 1) {
                    viewType = "brand";
                }
                else if (collections.lines.length > 1) {
                    viewType = "line";
                }
                else if (collections.series.length > 1) {
                    viewType = "series";
                }
            }
            else {
                viewType = "product";
            }
        }


        var filter0Items = [
            {
                Type: 2,
                Label: getTranslation(1,"CAT_TEMPLATE",this.props.translations, "Template"),
                ID: "Template",
                Value: tempFilters.Template,
                Items: filtersData.templates
            }
        ];
        var filter1Items = [{ Type: 1, ID: "ShowModels", Label: getTranslation(1,"CAT_CATALOGUE",this.props.translations, "Catalogo"), Value: tempFilters.ShowModels }, { Type: 1, ID: "ShowStock", Label: getTranslation(1,"CAT_STOCK",this.props.translations, "Stock"), Value: tempFilters.ShowStock }, , { Type: 1, ID: "ShowNews", Label: getTranslation(1,"CAT_NEWS",this.props.translations, "Novità"), Value: tempFilters.ShowNews }];

        if (tempFilters.ShowStock || (!tempFilters.ShowModels && !tempFilters.ShowStock)) {
            filter1Items.push({
                Type: 2,
                ID: "GroupType",
                Label: getTranslation(1,"CAT_VIEW",this.props.translations, "Visualizzazione"),
                Value: tempFilters.GroupType,
                Items: [{ ID: 1, Label: getTranslation(1,"CAT_ANALITICVIEW",this.props.translations, "Visualizzazione analitica") }, { ID: 2, Label: "Visualizzazione raggruppata per codice" }, { ID: 3, Label: "Visualizzazione raggruppata per codice e posizione" }, { ID: 4, Label: getTranslation(1,"CAT_VARIATIONVIEW",this.props.translations, "Visualizzazione raggruppata per variante") }]
            });
        }
        else {
            filter1Items.push({
                Type: 2,
                ID: "GroupType",
                Label: getTranslation(1,"CAT_VIEW",this.props.translations, "Visualizzazione"),
                Value: tempFilters.GroupType,
                Items: [{ ID: 1, Label: getTranslation(1,"CAT_ANALITICVIEW",this.props.translations, "Visualizzazione analitica") }, { ID: 4, Label: getTranslation(1,"CAT_VARIATIONVIEW",this.props.translations, "Visualizzazione raggruppata per variante") }]
            });
        }
        filter1Items.push({
            Type: 2,
            ID: "ImageView",
            Label: getTranslation(1,"CAT_IMAGES",this.props.translations, "Immagini"),
            Value: tempFilters.ImageView,
            Items: [{ ID: 1, Label: getTranslation(1,"CAT_ALL",this.props.translations, "Tutto") }, { ID: 2, Label: getTranslation(1,"CAT_ONLYBOPIMG",this.props.translations, "Solo distinte con immagini") }, { ID: 3, Label: getTranslation(1,"CAT_ONLYBOPNOIMG",this.props.translations, "Solo distinte senza immagini") }],
        });
        var filters = [];
        if (viewType === "product") {
            filters.push({ Panel: getTranslation(1,"CAT_VIEW",this.props.translations, "Visualizzazione"), Type: 1, Label: 'Template ricerca', items: filter0Items });
        }
        filters.push({ Panel: getTranslation(1,"CAT_VIEW",this.props.translations, "Visualizzazione"), Type: 1, Label: 'Visualizza', items: filter1Items });
        if (viewType === "product") {
            if (filtersData.groups.length > 0) {
                filters.push({ Panel: getTranslation(1,"CAT_SEARCHCRITERIA",this.props.translations, "Criteri di ricerca"), Type: 2, Label: getTranslation(3,"BOP_GROUP",this.props.translations, "Gruppo")                , ID: "FIDGroup", Value: tempFilters.FIDGroup, items: filtersData.groups });
            }
            if (filtersData.categories.length > 0) {
                filters.push({ Panel: getTranslation(1,"CAT_SEARCHCRITERIA",this.props.translations, "Criteri di ricerca"), Type: 2, Label: getTranslation(3,"BOP_TAG",this.props.translations, "Tag")                , ID: "FIDCategory", Value: tempFilters.FIDCategory, items: filtersData.categories });
            }
            if (filtersData.brands.length > 0) {
                filters.push({ Panel: getTranslation(1,"CAT_SEARCHCRITERIA",this.props.translations, "Criteri di ricerca"), Type: 2, Label: getTranslation(3,"BOP_BRAND",this.props.translations, "Marca")                , ID: "FIDBrand", Value: tempFilters.FIDBrand, items: filtersData.brands });
            }
            if (filtersData.lines.length > 0) {
                filters.push({ Panel: getTranslation(1,"CAT_SEARCHCRITERIA",this.props.translations, "Criteri di ricerca"), Type: 2, Label: getTranslation(3,"BOP_LINE",this.props.translations, "Collezione")                , ID: "FIDLine", Value: tempFilters.FIDLine, items: filtersData.lines });
            }
            if (filtersData.series.length > 0) {
                filters.push({ Panel: getTranslation(1,"CAT_SEARCHCRITERIA",this.props.translations, "Criteri di ricerca"), Type: 2, Label: getTranslation(3,"BOP_SERIES",this.props.translations, "Serie")                , ID: "FIDSeries", Value: tempFilters.FIDSeries, items: filtersData.series });
            }
            if (filtersData.prices.length > 0) {
                filters.push({ Panel: getTranslation(1,"CAT_SEARCHCRITERIA",this.props.translations, "Criteri di ricerca"), Type: 3, Label: getTranslation(1,"CAT_PRICE",this.props.translations, "Prezzo")                , ID: "Price", Value: tempFilters.Price, items: { min: filtersData.prices[0], max: filtersData.prices[1] } });
            }
            if (filtersData.positions.length > 0) {
                filters.push({ Panel: "Punti vendita", Type: 2, Label: 'Filiali', ID: "FIDPosition", Value: tempFilters.FIDPosition, items: filtersData.positions });
            }
        }
        return filters;
    }
    onExpand = (index) => (isExpanded) => {
        this.setState(state => {
            state.dataPaged[index].cardIsExpanded = isExpanded;
            return state;
        }, () => {

            this.CardsCollection.current.recomputeCellSizesAndPositions();
                index++;
        });
    }
    getRowTop = (row, cols, index) => {
        var { data,dataPaged } = this.state;
        //574.23
        var sum = 0;

        var { height } = this.getCardSize();
        var maxRow = height;
        var expPanel = 139.62;
        var lastIndex = (index - (index % cols));
        for (var i = 0; i < lastIndex; i++) {
            if (dataPaged[i].cardIsExpanded) {
                maxRow = height + expPanel;
            }
            if (i % cols === 0) {
                sum += maxRow;
                maxRow = height;
            }
        }
        sum += maxRow - height;
        
        return sum;
    }
    getNumberOfCols = () => {
        var cols = 4;
        switch (this.props.width) {
            case "xl":
                cols = 4;
                break;
            case "lg":
                cols = 3;
                break;
            case "md":
            case "sm":
                cols = 2;
                break;
            case "xs":
                cols = 1;
                break;
            default:
                break;
        }
        return cols;
    }
    getCardSize = () => {
        var { contentWidth } = this.state;
        var cols = this.getNumberOfCols();
        var tempWidth = 405.75;
        var tempHeight = 434.61;
        var width = (contentWidth - getScrollbarWidth()) / cols;
        
        var height = width * tempHeight / tempWidth;
        if (!this.props.showExpansionPanel) {
            height = height - 48;
        }
        return { width, height };
    }
    renderCart = () => {
        var { IdentityInfo,DestinationInfo, Documents,contentWidth, data, isLoadingPage, totDocument, totDocumentDisc, totDocTaxable, totDocDiscTaxable, currency, documentID } = this.state;
        var DocInfo = { FIDCausal: this.props.isCatalog ? this.props.CatalogFIDCausal : this.props.params.DOCPresentationCausalId, ID: documentID };
        if(Documents && Documents.length === 1){
            DocInfo = Documents[0];
        }
        
        if(isLoadingPage){
            data = [];
        }
        return <CatalogueCart IsCatalog={this.props.isCatalog} DocInfo={DocInfo} IdentityInfo={IdentityInfo} DestinationInfo={DestinationInfo} contentWidth={contentWidth} TotalDocument={totDocument} 
					TotalDocumentDisc={totDocumentDisc} TotalDocumentTaxable={totDocTaxable} TotalDocumentDiscTaxable={totDocDiscTaxable} Currency={currency} onBuyItem={this.onBuyItem} onRemoveItem={this.onRemoveItem} onRedirectEmpty={this.onDocumentDeleted} />;
    }
    getStandardContent = () => {
        var { CatalogFIDPricelist, FIDIRPricelist } = this.props;
        var { data, dataPaged, currentFilters, tempFilters, showFiltersRow, FIDIdentity, contentWidth, newPageLoading } = this.state;

        var height = 100;
        var width = contentWidth;
        var mainContent = document.getElementById('mainContent');
        var isCart = this.state.ShowCart; //this.state.FIDCausalView !== undefined && this.state.FIDCausalView === this.props.CatalogFIDCausal;

        if (mainContent) {
            height = mainContent.clientHeight - HEADER_HEIGHT;
        }
       
        var groupType = currentFilters.GroupType;

        height = window.innerHeight - APP_TOOLBAR_HEIGHT - HEADER_HEIGHT;
        
        if(isCart /*&& this.props.FIDProfile !== this.props.params.CustomerTypologyId*/){
            //height -= 50;
            return this.renderCart();
        }
        var collection = <Collection
            onScroll={event => {
                //this.setState({ scrollTop: event.scrollTop });
                var cols = this.getNumberOfCols();
                var { width, height } = this.getCardSize();
                let visibleElements = (Math.floor(event.scrollTop / height) + 2) * cols;
                if(this.state.dataPaged.length <= visibleElements + this.state.itemsperPage && !this.state.newPageLoading) {
                    this.loadPage();
                }
            }}
            scrollToCell={this.state.currentIndex}
            ref={this.CardsCollection}
            cellCount={dataPaged.length + (newPageLoading ? 1 : 0)}
            verticalOverscanSize={this.getCardSize().height * 4}
            cellRenderer={({ index, key, style }) => {
                if(newPageLoading && index === dataPaged.length){
                    style.margin = 8;
                    style.textAlign = 'center';
                    return <div style={style} ><XOXLoader onlyO height={50} /></div>;
                }
                var item = dataPaged[index];
                key = item.ID;
                if (!key) {
                    key = index;
                }
                var tempItem = { };
                if (!this.state.isLoadingPage) {
                    tempItem = item;
                }
                if(this.state.lastAction && tempItem.cardIsExpanded){
                    key += this.state.lastAction;
                }
                style.margin = 8;
                style.height = undefined;
				
                var filters = (currentFilters || tempFilters);
                
                
		var FIDPricelist = (CatalogFIDPricelist||FIDIRPricelist||this.props.params.DEF_Pricelist||this.props.params.STDFIDPricelist);

                var showModels = filters && filters.ShowModels ? true : false;
                var showStock = filters && filters.ShowStock ? true : false;
				
                return (
                    <CatalogueCard 
                        isCatalog={this.props.isCatalog} 
                        groupType={groupType} 
                        showCart={this.state.ShowCart}  
                        showExpansionPanel={this.props.showExpansionPanel} 
                        isExpanded={tempItem.cardIsExpanded} 
                        style={style} 
                        key={key} 
                        isLoading={this.state.isLoadingPage} 
                        data={tempItem} 
                        onItemClick={this.onItemClick(index)} 
                        causals={this.state.enabledCausals} 
                        FIDIdentity={FIDIdentity} 
                        FIDDocument={this.props.match.params.id} 
                        FIDCausal={this.state.FIDCausalView} 
                        CatalogFIDPricelist={FIDPricelist}
                        onAddItem={this.onAddItem} 
                        onStarItem={this.onStarItem} 
                        onBuyItem={this.onBuyItem} 
                        onNoticeItem={this.onNoticeItem} 
                        onQuoteItem={this.onQuoteItem} 
                        onNegotiateItem={this.onNegotiateItem} 
                        onReserveItem={this.onReserveItem} 
                        onBookItem={this.onBookItem} 
                        onRemoveItem={this.onRemoveItem} 
                        onExpand={this.onExpand(index)} 
                        showCatalog={showModels} 
                        showModels={showModels} 
                        showStock={showStock}/>
                );
            }}
            cellSizeAndPositionGetter={({ index }) => {
                var cols = this.getNumberOfCols();

                var { width, height } = this.getCardSize();
                var top = this.getRowTop((index - (index % cols)), cols, index);
                if(newPageLoading && index === dataPaged.length){
                    return {
                        height: 50,
                        width: (width * cols) - 16,
                        x: 0,
                        y: top + ((index % cols > 0) ? height : 0)
                    };
                }


                return {
                    height: height - 16,
                    width: width - 16,
                    x: (index % cols) * width,
                    y: top,
                };
            }}
            height={height}
            width={width}
        />;
        return (<Fragment>
            {isCart && <div style={{ weight:'100%', height: 50, textAlign: 'right', fontWeight:'bold', padding:'5px 20px'}}>
                <span style={{display:'block'}}>{getTranslation(1,"CAT_DOCTOTAL",this.props.translations, "Totale documento")}</span>
                <span style={{display:'block'}}>{format(this.state.totDocument)}</span>
            </div>}
            {showFiltersRow && (
                <Paper style={{ padding: "10px", position: "sticky", zIndex: 1050, top: 62, left: 0 }}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Group} label={getTranslation(3,"BOP_GROUP",this.props.translations, "Gruppo")} id="Group" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Typology} label={getTranslation(3,"BOP_Tipology",this.props.translations, "Tipologia")} id="Typology" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Category} label={getTranslation(3,"BOP_TAG",this.props.translations, "Tag")} id="Category" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Brand} label={getTranslation(3,"BOP_BRAND",this.props.translations, "Marca")} id="Brand" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Line} label={getTranslation(3,"BOP_LINE",this.props.translations, "Collezione")} id="Line" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Code} label={getTranslation(3,"BOP_Code",this.props.translations, "Referenza")} id="Code" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Description} label={getTranslation(1,"BOP_DESCRIPTION",this.props.translations, "Descrizione")} id="Description" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.SerialNumber} label={getTranslation(3,"BOP_SerialNumber",this.props.translations, "Seriale")} id="SerialNumber" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Barcode} label={getTranslation(3,"BOP_Barcode",this.props.translations, "Barcode")} id="Barcode" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Position} label={getTranslation(3,"BOP_Position",this.props.translations, "Punti di vendita")} id="Position" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                    </Grid>
                </Paper>
            )}
            
            {collection}
        </Fragment>);
    }
    getSingleItemContent = () => {
        var { classes, CatalogFIDPricelist, FIDIRPricelist } = this.props;
        var { FIDIdentity, currentItem, itemStar, currentFilters } = this.state;
        var onItemEditClick = this.onItemEditClick;
        if (!this.props.menus.includes("bopedit")) {
            onItemEditClick = undefined;
        }
        
		if (this.props.FIDProfile === (this.props.params.CustomerTypologyId||2) && !FIDIdentity) {
			FIDIdentity = this.props.FIDIdentity;
		}
		
        if (JSON.stringify(itemStar) !== JSON.stringify({})) {
            currentItem = Object.assign({}, itemStar);
        }
		
		var FIDPricelist = (CatalogFIDPricelist||FIDIRPricelist||this.props.params.DEF_Pricelist||this.props.params.STDFIDPricelist);

        var showModels = currentFilters && currentFilters.ShowModels ? true : false;
        var showStock = currentFilters && currentFilters.ShowStock ? true : false;
        var groupType = currentFilters.GroupType;
		var docID = this.props.match.params.id || this.state.documentID;
        
        var content = (
            <Paper className={classes.root}>
                <CatalogueItem
                    innerRef={this.CatalogueItem}
                    groupType={groupType}
                    isCatalog={this.props.isCatalog}
                    onEditClick={onItemEditClick}
                    causals={this.state.enabledCausals}
                    FIDIRIdentity={FIDIdentity}
                    CatalogFIDPricelist={FIDPricelist}
                    FIDDocument={docID}
                    data={currentItem}
                    open={this.state.showItem}
                    showModels={showModels}
                    showStock={showStock}
                    onClose={this.handleCatalgoItemClose}
                    onAddItem={this.onAddItem}
                    onStarItem={this.onStarItem}
                    onBuyItem={this.onBuyItem}
                    onNoticeItem={this.onNoticeItem}
                    onQuoteItem={this.onQuoteItem}
                    onNegotiateItem={this.onNegotiateItem}
                    onReserveItem={this.onReserveItem}
                    onBookItem={this.onBookItem}
                    onRemoveItem={this.onRemoveItem}
                    onNoteItem={this.onNoteItem}
                    onShowRemarks={this.onShowRemarks}
                    showRemarks={this.state.showRemarks}
                    onHideRemarks={this.onHideRemarks}
                />
            </Paper>
        );
        return content;
    }
    onShowCustomer = () => {
        this.setState({showIdentitySelector: true, viewType: undefined, ShowCart: false});
    }
    onShowPricelist = () => {
        this.setState({showPricelistSelector: true, viewType: undefined, ShowCart: false});
    }
    selectCustomer = (FIDIdentity) => {
        this.setState({refresh: false, isLoading: true},() => {
			this.reloadIdentityInfo(FIDIdentity,() => {
				this.setState({showIdentitySelector: false}, () => 
				{
					var { advancedNavigation } = this.props;
					if (this.props.match.params.id) {
						advancedNavigation = false;
					}
					if (advancedNavigation || !this.state.documentID) {

                        Promise.all([
                            this.getCatalogueGroups(),
                            this.getCollections(),
                            this.getCategories(),
                            this.getTags()
                        ])
                            .then(res => {
                                this.setState({ isLoadingPage: false, isLoading: false });
                            });
						// this.getCatalogueGroups();
						// this.getCollections(() => { this.setState({ isLoadingPage: false }); });
						// this.getCategories();
						// this.getTags();
					}
					else {
						this.loadData(() => { this.setState({ isLoadingPage: false, isLoading: false }); });
					}
					this.loadFiltersData();
					
					//this.setState({ isLoading: false });
				});
			});
        });
    }
    selectCustomerConfirmDoc = (FIDIdentity) => {
        fetch(`/IR/UpdateIdentityOnDoc/${FIDIdentity}`).then(res => {
            this.setState({refresh: false},() => {
                this.reloadIdentityInfo(FIDIdentity,this.doConfirmDocument);
            });
        })
        
    }
    selectPricelist = (FIDPricelist) => {
        this.setState({refresh: false, isLoading: true},() => {
            //this.reloadIdentityInfo(FIDIdentity,() => this.setState({showIdentitySelector: false}));
            this.props.setCatalogPricelist(FIDPricelist);
            this.setState({showPricelistSelector: false}, () => {
				var { advancedNavigation } = this.props;
				if (this.props.match.params.id) {
					advancedNavigation = false;
				}
				if (advancedNavigation) {

                    Promise.all([
                        this.getCatalogueGroups(),
                        this.getCollections(),
                        this.getCategories(),
                        this.getTags()
                    ])
                        .then(res => {
                            this.setState({ isLoadingPage: false, isLoading: false });
                        });
					// this.getCatalogueGroups();
					// this.getCollections(() => { this.setState({ isLoadingPage: false }); });
					// this.getCategories();
					// this.getTags();
				}
				else {
					this.loadData(() => { this.setState({ isLoadingPage: false, isLoading: false }); });
				}
				this.loadFiltersData();
			});
        });
    }
    getIdentitySelectorContent = () => {
        var { classes } = this.props;
        var { stars, itemStar, FIDCausal,showIdentitySelector, saveDocument} = this.state;
        var action = this.saveDocumentNew(stars, itemStar, FIDCausal);
        var back = () => { this.setState({ stars: undefined, showIdentitySelector: undefined }) };
        if(showIdentitySelector){
            action = this.selectCustomer;
        }
        else if (saveDocument){
            action = this.selectCustomerConfirmDoc;
            back = this.onSaveDocBack;
        }
        var content = (
            <Paper className={classes.root}>
                <IdentitySelector onIdentitySelected={action} onCancel={back} translations={this.props.translations} />
            </Paper>
        );
        return content;
    }
    getPricelistSelectorContent = () => {
        var { classes } = this.props;
        var action = this.selectPricelist;
        var content = (
            <Paper className={classes.root}>
                <PricelistSelector onPricelistSelected={action} onCancel={() => { this.setState({ showPricelistSelector: undefined }) }} translations={this.props.translations} />
            </Paper>
        );
        return content;
    }
    onCausalSelected = (FIDCausal) => {
        var { FIDIdentity} = this.state;
        fetch(`/IR/UpdateCausal/${FIDCausal}?FIDIdentity=${FIDIdentity}`).then(res => {
            this.setState(state => { 
				state.FIDCausal = FIDCausal;
				
				if (!this.props.isCatalog)
					state.step = 4;
				else
					state.step = 5;
			}, () => {
                this.props.setCatalogCausal(FIDCausal);
            });
        })
        
        // if (!this.props.menus.includes("ir")) {
        //     FIDIdentity = this.props.FIDIdentity;
        // }

        // if (FIDIdentity) {
        //     this.props.setCatalogCausal(FIDCausal);
        //     this.saveDocument(stars, itemStar, FIDIdentity, FIDCausal, rowQty);
        // }
        // else {
        //     this.setState({ FIDCausal: FIDCausal }, () => {
        //         this.props.setCatalogCausal(FIDCausal);
        //     });
        // }
    }
    onCausalSelectCancel = () => {
        this.setState({ saveDocument: false, callbackConfirm: false });
    }
    getCausalSelectorContent = () => {
        var { classes } = this.props;
        var { stars, itemStar, FIDCausal } = this.state;
        var content = (
            <Paper className={classes.root}>
                <CausalSelector causals={this.props.params.DOCCatalogCausals} onCausalSelected={this.onCausalSelected} onCancel={this.onCausalSelectCancel} translations={this.props.translations} />
            </Paper>
        );
        return content;
    }
	getPrintOptionSelector = () => {
		var { classes } = this.props;
		let { FIDCausal } = this.state;
		
		if (this.props.params.DOCCatalogCausals && this.props.params.DOCCatalogCausals.length === 1) {
            FIDCausal = this.props.params.DOCCatalogCausals[0];
		}
		
		var causals = this.props.isCatalog ? [ FIDCausal ] : [ this.props.params.DOCPresentationCausalId||10 ];
					
		if (!this.props.isCatalog) {
			if (this.state.HasNotice)
				causals.push(this.props.params.DOCNoticeCausalId||11);
			
			if (this.state.HasQuotes)
				causals.push(this.props.params.DOCQuoteCausalId||3);
			
			if (this.state.HasNegotiations)
				causals.push(this.props.params.DOCNegotiationCausalId||39);
			
			if (this.state.HasBookings)
				causals.push(this.props.params.DOCBookingCausalId||13);
			
			if (this.state.HasCartRows)
				causals.push(this.props.params.DOCCartCausalId||9);
		}
		let content = (<Paper className={classes.root}><XPrintOptionSelector innerRef={this.PrintOptionSelector} ShowCausalsChooser={!this.props.isCatalog} causals={causals} /></Paper>);
		return content;
	}
    getFiltersContent = (viewType) => {
        var { showFilters, tempFilters } = this.state;
        var filters = this.getFilters();
        var content = <XFilters open={showFilters} showOrder={true} filters={filters} sort={tempFilters.Sort} onClose={this.onFilterClose} onFilterChange={this.onFilterChange} onFilterSet={this.onSetFilters} onClearFilters={this.onClearTempFilterButton} onSaveTemplate={this.onSaveTemplate} onDeleteTemplate={this.onDeleteTemplate} onSortChange={this.onSortChange} />;
        return content;
    }
    getCollectionContent = (viewType) => {
        var { classes } = this.props;
        var { collections, currentFilters, contentWidth, categories, tags, groups } = this.state;

        var data = [];
        var showLabel = false;
        var key = viewType;
        var backgroundSize = "cover";
        switch (viewType) {
            case "category":
                data = categories;
                showLabel = true;
                break;
            case "tag":
                data = tags;
                showLabel = true;
                break;
            case "series":
                data = collections.series;
                showLabel = true;
				backgroundSize = "contain";
                break;
            case "line":
                data = collections.lines;
                showLabel = true;
				backgroundSize = "contain";
                break;
            case "brand":
                data = collections.brands;
                backgroundSize = "contain";
                break;
            case "groups":
            default:
                data = groups;
                showLabel = true;
                break;
        }
        if(viewType !== "category" && viewType !== "tag" && viewType !== "groups"){
            if(currentFilters.FIDCatalogueGroup) {
                data = data.filter(i => this.isInArray(i.CatalogueGroupsId, currentFilters.FIDCatalogueGroup));
            }
            if (currentFilters.FIDBrand) {
                data = data.filter(i => i.FIDBrand === currentFilters.FIDBrand);
            }
            if (currentFilters.FIDLine) {
                data = data.filter(i => i.FIDLine === currentFilters.FIDLine);
            }
        }
        var height = window.innerHeight - APP_TOOLBAR_HEIGHT - HEADER_HEIGHT;
        var width = contentWidth;
        var hideAllButton = this.props.params.CatalogueHideAllButton;

        var delta = 4;


        var btnNewIndex = 0;
        var btnAllIndex = 1;
        var btnCategoryIndex=2;
        var btnTagIndex = 3;

        if(hideAllButton){
            delta--;
            btnAllIndex = -1;
            btnCategoryIndex--;
            btnTagIndex--;
        }
        if(this.props.params.CatalogueHideCategories){
            delta --;
            btnCategoryIndex = -1;
            btnTagIndex--;
        }
        if(this.props.params.CatalogueHideTags){
            delta --;
            btnTagIndex = -1;
        }


        var plus = 0;
        if(viewType === "groups"){
            plus = delta;
        }
        else if (viewType === "brand" && (!groups || groups.length <= 1)) {
            plus = delta;
        }
        else if (viewType === "line" && (!groups || groups.length <= 1) && collections.brands.length <= 1) {
            plus = delta;
        }
        else if (viewType == "series" && (!groups || groups.length <= 1) && collections.brands.length <= 1 && collections.lines.length <= 1) {
            plus = delta;
        }

        var collection = <Collection
            key={key}
            ref={this.BLSCollection}
            cellCount={data.length + plus}
            cellRenderer={({ index, key, style }) => {

                style.margin = 8;
                if (plus > 0) {
                    if (index <= delta-1) {
                        if (index == btnNewIndex) {
                            return <div key="NEW" className={classes.collectionCardAlt} style={style} onClick={this.showNews}>
                                <div className={classes.collectionCardAltBackground} />
                                <div className={classes.collectionCardAltContent}>
                                    <span>{getTranslation(1,"CAT_NEWS",this.props.translations, "NOVITÀ")}</span>
                                </div>
                            </div>;
                        }
                        else if (index == btnAllIndex) {
                            return <div key="ALL" className={classes.collectionCardAlt} style={style} onClick={this.showProducts}>
                                <div className={classes.collectionCardAltBackground} />
                                <div className={classes.collectionCardAltContent}>
                                    <span>{getTranslation(1,"CAT_FULLCATALOG",this.props.translations, "CATALOGO COMPLETO")}</span>
                                </div>
                            </div>;
                        }
                        else if (index == btnCategoryIndex) {
                            return <div key="CATEGORY" className={classes.collectionCardAlt} style={style} onClick={this.showCategories}>
                                <div className={classes.collectionCardAltBackground} />
                                <div className={classes.collectionCardAltContent}>
                                    <span>{getTranslation(3,"BOP_CATEGORIES",this.props.translations, "CATEGORIE")}</span>
                                </div>
                            </div>;
                        }
                        else if (index == btnTagIndex) {
                            return <div key="TAG" className={classes.collectionCardAlt} style={style} onClick={this.showTags}>
                                <div className={classes.collectionCardAltBackground} />
                                <div className={classes.collectionCardAltContent}>
                                    <span>{getTranslation(3,"BOP_TAGS",this.props.translations, "TAG")}</span>
                                </div>
                            </div>;
                        }
                    }
                }
                var item = data[index-plus];
                key = item.ID;
                if (!key) {
                    key = index;
                }
                var tempItem = item;
                //if (!this.state.isLoadingPage) {
                //    tempItem = item;
                //}
                if(viewType === "groups") {
                    return <div key={key} className={classes.collectionCardAlt} style={style} onClick={() => this.onCollectionCardClick(viewType,item)}>
                        <div className={classes.collectionCardAltBackground} />
                        <div className={classes.collectionCardAltContent}>
                            <span>{item.Description}</span>
                        </div>
                    </div>;
                }
                else if(viewType === "tag"){
                    return <div key={key} className={classes.collectionCardAlt} style={style} onClick={() => this.onCollectionCardClick(viewType,item)}>
                        <div className={classes.collectionCardAltBackground} />
                        <div className={classes.collectionCardAltContent}>
                            <span>{item.Description}</span>
                        </div>
                    </div>;
                }
                else if(viewType === "category"){
                    return <div key={key} className={classes.collectionCardAlt} style={style} onClick={() => this.onCollectionCardClick(viewType,item)}>
                        <div className={classes.collectionCardAltBackground} />
                        <div className={classes.collectionCardAltContent}>
                            <span>{item.Description}</span>
                        </div>
                    </div>;
                }
                else {
                    return (
                        <CollectionCard key={key} style={style} showLabel={showLabel} backgroundSize={backgroundSize} viewType={viewType} isLoading={this.state.isLoadingPage} data={tempItem} onClick={this.onCollectionCardClick} />
                    );
                }
                
            }}
            cellSizeAndPositionGetter={({ index }) => {

                var cols = 4;
                switch (this.props.width) {
                    case "xl":
                        cols = 4;
                        break;
                    case "lg":
                        cols = 4;
                        break;
                    case "md":
                    case "sm":
                        cols = 3;
                        break;
                    case "xs":
                        cols = 2;
                        break;
                    default:
                        cols = 4;
                        break;
                }

                var width = (contentWidth - getScrollbarWidth()) / cols;
                var height = width;
                return {
                    height: height - 16,
                    width: width - 16,
                    x: (index % cols) * width,
                    y: ((index - (index % cols)) / cols) * height
                };
            }}
            height={height}
            width={width}
        />;

        return (<Fragment>
            {collection}
        </Fragment>);
    }
    onSearch = () => {
        this.setState({ bopsSearch: true, bopsSearchType: "all", bopsSearchValue: "" });
    }
    onCodeSearch = () => {
        this.setState({ bopsSearch: true, bopsSearchType: "code", bopsSearchValue: "" });
    }
    onBarcodeSearch = () => {
        this.setState({ bopsSearch: true, bopsSearchType: "barcode", bopsSearchValue: "" });
    }
    onSerialSearch = () => {
        this.setState({ bopsSearch: true, bopsSearchType: "serial", bopsSearchValue: "" });
    }
    onSearchCancel = () => {
        this.setState({ bopsSearch: false, bopsSearchType: "", bopsSearchValue: "" });
    }
    onSearchConfirm = () => {
        this.doSearchConfirm();
    }
    onSearchBlur = (id) => (event) => {
        this.bopsSearch.current.focus();
    }
    onSearchChange = (id) => (event, value) => {
        this.setState({ bopsSearchValue: value });
    }
    onSearchKeyDown = (id) => (event, value) => {
        if (event.keyCode === 13) {
            this.doSearchConfirm();
        }
    }
    doSearchConfirm = () => {
        var { bopsSearchType, bopsSearchValue } = this.state;

        var key = "";
        switch (bopsSearchType) {
            case "code":
                key = "Code";
                break;
            case "barcode":
                key = "Barcode";
                break;
            case "serial":
                key = "SerialNumber";
                break;
            case "all":
                key= "SearchTerm";
                break;
            default:
                break;
        }

        var currentFilters = Object.assign({}, defaultFilters);
        var tempFilters = Object.assign({}, defaultFilters);
        currentFilters[key] = bopsSearchValue;
        tempFilters[key] = bopsSearchValue;
        this.setState({ currentFilters, tempFilters, filterChanged: true, isLoading: true, isLoadingPage:true, viewType: 'product' }, () => {
            this.loadData();
            this.loadFiltersData();
            this.setState({ bopsSearch: false, bopsSearchType: "", bopsSearchValue: "" });
        });
    }
    onDestinationSelectCancel = () => {
        this.setState({ saveDocument: false, callbackConfirm: false });
    }
    renderSaveDocument() {
        var { FIDIdentity } = this.state;
        
        return <IdentitySelector showRelations={false} onIdentitySelected={this.onDestinationSelect} onCancel={this.onDestinationSelectCancel} noNew={true} showDestinations={true} label={getTranslation(1, "CAT_SELECT_SHIPMENT_ADDR", this.props.translation, "Seleziona un indirizzo di spedizione")} translations={this.props.translations} FIDIdentity={FIDIdentity} />;

        //var customActions = [];
        //return <Fragment>
        //    <AppBar position="sticky">
        //        <EnhancedTableToolbar
        //            onFilterButton={false}
        //            onAdvancedFilterButton={false}
        //            onClearFilterButton={false}
        //            numSelected={0}
        //            selectedAll={false}
        //            count={0}
        //            actions={customActions}
        //            grid={this}
        //            //label={label}
        //        />
        //    </AppBar>
        //</Fragment>;
    }
    onSaveDocBack = () => {
        var { step, FIDIdentity } = this.state;
        if(step === 1){
            this.setState({saveDocument: false, step: undefined });
            return;
        }
		if (step === 5) {
			//if (this.props.isCatalog) {
			//	
			//} else {
			if (!this.props.isCatalog) {
				this.setState({ saveDocument: true, step: 4 });
				return;
			} else {
				if (!this.state.FIDCausal && (this.props.CatalogCausals && this.props.CatalogCausals.length > 1)) {
					this.setState({ saveDocument: true, step: 3 });
					return;
				} else {
					if(!FIDIdentity) {
						this.setState({ saveDocument: true, step: 1 });
						return;
					} else {
						this.setState({saveDocument: false, step: undefined });
						return;
					}
				}
			}
			//}
		}
		if (step === 4) {
			if (!this.state.FIDCausal && (!this.props.isCatalog || (this.props.CatalogCausals && this.props.CatalogCausals.length > 1))) {
				this.setState({saveDocument: true, step: 3 });
				return;
			} else if(!FIDIdentity) {
				this.setState({ saveDocument: true, step: 1 });
				return;
			}
			else {
				this.setState({saveDocument: false, step: undefined });
				return;
			}
		}
        
        if(!FIDIdentity) {
            this.setState({ saveDocument: true, step: 1 });
            return;
        }
        fetch('/IR/HasMultipleDestinations/' + FIDIdentity).then(res => res.json()).then(data => {
            if (data) {
                //has multiple destinations

                this.setState({ saveDocument: true, step: 2 });
            }
            else {
                //generate pdf
                if(this.state.FIDCausal && !this.props.isCatalog) {
                    this.setState({ saveDocument: true, step: 4, callbackConfirm: false });
                }
                else {
                    this.setState({ saveDocument: true, step: 3 });
                }
            }
        });
    }
    renderPDFDocument() {
        var { FIDIdentity, FIDDestination, FIDPrintOption, FIDPrintLanguage, FIDCausalPrint } = this.state;
        var url = "/IR/GetDocumentPdf/" + (FIDIdentity) + '?IsCatalog=' + this.props.isCatalog;
        if (FIDDestination) {
            url += "&FIDDestination=" + FIDDestination;
        }
		if (FIDPrintOption) {
			url += "&FIDOption="+FIDPrintOption;
		}
		if (FIDPrintLanguage) {
			url += "&FIDLanguage="+FIDPrintLanguage;
		}
		if (FIDCausalPrint) {
			url += "&FIDCausal="+FIDCausalPrint;
		}

        var customActions = [];

        var lblConferma = this.props.isCatalog ? getTranslation(1,'CAT_CONFIRMDOC',this.props.translations, "Conferma documento") : getTranslation(1,'CAT_CONFIRMDOC_PRES',this.props.translations, "Conferma presentazione");
        var lblElimina = this.props.isCatalog ? getTranslation(2,'DOC_DELETE',this.props.translations, "Elimina documento") : getTranslation(2,'DOC_DELETE_PRES',this.props.translations, "Elimina presentazione");
        customActions.push({
            group: 'actions',
            name: lblElimina,
            icon: <DeleteSweep />,
            action: this.handleDeleteDocument
        });
        customActions.push({
            group: 'actions',
            name: lblConferma,
            icon: <Check />,
            action: this.handleConfirmDocumentFinal
        });
        customActions.push({
            group: 'actions',
            name: getTranslation(5,'GC_BACK',this.props.translations, 'Indietro'),
            icon: <ArrowLeft />,
            action: this.onSaveDocBack
        });
        // customActions.push(<Tooltip title="Indietro">
        //     <IconButton aria-label="Indietro" onClick={this.onSaveDocBack} >
        //         <ArrowLeft />
        //     </IconButton>
        // </Tooltip>);
        // customActions.push(<Tooltip title={lblConferma}>
        //     <IconButton aria-label={lblConferma} onClick={this.handleConfirmDocumentFinal} >
        //         <Check />
        //     </IconButton>
        // </Tooltip>);
        // customActions.push(<Tooltip title={lblElimina}>
        //     <IconButton aria-label={lblElimina} onClick={this.handleDeleteDocument} >
        //         <DeleteSweep />
        //     </IconButton>
        // </Tooltip>);

        return <Fragment>
            <AppBar position="sticky">
                <EnhancedTableToolbar
                    onFilterButton={false}
                    onAdvancedFilterButton={false}
                    onClearFilterButton={false}
                    showColumnsSelector={false}
                    numSelected={0}
                    selectedAll={false}
                    count={0}
                    actions={customActions}
                    grid={this}
                //label={label}
                />
            </AppBar>
            <XPDFViewer key="print_presentation" url={url} width="100%" height={window.innerHeight - 90 - 60 - 6} />
			{/*<iframe src={`https://docs.google.com/gview?url=${window.location.origin}${url}&embedded=true`} type="application/pdf" width="100%" height={window.innerHeight - 90 - 60 - 6} />*/}
        </Fragment>;
    }
    
    getCookie( name ) {
		var parts = document.cookie.split(name + "=");
		if (parts.length == 2) return parts.pop().split(";").shift();
	  }

      
    download(url) {
		//delete cookie
		
		this.setState({showLoader: true});
		var a = document.createElement('a');
		a.href = url;
		a.download = `EXPORT.xlsx`;
		document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
		a.click();
		a.remove();	


		var interval = setInterval(() => {
			if(this.getCookie("export_excel_finish") === "true") {
				this.setState({showLoader: false});
				clearInterval(interval);
				document.cookie = "export_excel_finish=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
			}
		}, 1000);
	}

    exportDocument = () => {
        var {data} = this.state;
        var url = (`/DOC/ExportDOCRowsInExcel/${this.props.match.params.id}`);
        this.download(url);
    }
    onExit = () => {
        //this.context.router.history.push('/documents/');
        window.history.back();
    }
    renderPDFDocumentById() {
        var url = "/IR/GetDocumentPdfById/" + (this.props.match.params.id) 

        var customActions = [];
        var lblElimina = "Elimina documento";
        // customActions.push(<Tooltip title={lblElimina}>
        //     <IconButton aria-label={lblElimina} onClick={this.handleDeleteDocument} >
        //         <DeleteSweep />
        //     </IconButton>
        // </Tooltip>);

        // customActions.push(<Tooltip title="Indietro">
        //     <IconButton aria-label="Esci" onClick={this.onExit} >
        //         <ExitToApp />
        //     </IconButton>
        // </Tooltip>);

        customActions= [
            {
                group: 'actions',
                name: getTranslation(5,'GC_BACK',this.props.translations, 'Indietro') ,
                icon: <ExitToApp />,
                action: this.onExit
            },
            {
                group: 'actions',
                name: getTranslation(2,'DOC_DELETE',this.props.translations, 'Elimina documento'),
                icon: <DeleteSweep />,
                action: this.handleDeleteDocument
            },
            {
                group: 'actions',
                name: getTranslation(2,'DOC_EXPORT',this.props.translations, 'Export'),
                icon: <FileExcelOutline />,
                action: this.exportDocument
            },
        ]

        return <Fragment>
            <AppBar position="sticky">
                <EnhancedTableToolbar
                    onFilterButton={false}
                    onAdvancedFilterButton={false}
                    onClearFilterButton={false}
                    numSelected={0}
                    selectedAll={false}
                    count={0}
                    actions={customActions}
                    grid={this}
                //label={label}
                />
            </AppBar>
            {/*<iframe src={`https://docs.google.com/gview?url=${window.location.origin}${url}&embedded=true`} type="application/pdf" width="100%" height={window.innerHeight - 90 - 60 - 6} />*/}
			<XPDFViewer key="print_presentation" url={url} width="100%" height={window.innerHeight - 90 - 60 - 6} />
        </Fragment>;
    }
    onBOPSelectionCancel = () => {
        this.setState({ FIDBOP: undefined, BOPSelection: undefined, BOPSelectionEdit: undefined, noStock: false });
    }
    onBOPSelectionConfirm = async (values, FIDBOP) => {
        
        var { CatalogFIDPricelist,FIDIdentity,FIDLicensee } = this.props;
        var { BOPSelection, stars } = this.state;
        if(values.length === 0){
            //remove
            this.onRemoveItem(BOPSelection.BOP,BOPSelection.FIDCausal,() => {
                this.CatalogueItem.current && this.CatalogueItem.current.getItemStatus();
            }, false, undefined, true);
        }
        if (!this.props.menus.includes("ir")) {
            FIDIdentity = this.props.FIDIdentity;
        }
        
		if (FIDIdentity === FIDLicensee || this.props.userID === -1990) {
			FIDIdentity = this.props.CatalogFIDIdentity||0;
		}
		
		if (FIDIdentity === this.props.FIDLicensee || (!this.props.CatalogFIDIdentity && this.props.FIDProfile === this.props.params.AgentTypologyId))
			FIDIdentity = undefined;
		
		var identitySelected = FIDIdentity || this.props.CatalogFIDIdentity;
		
        if (!CatalogFIDPricelist && this.props.params) {
            CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;
        }

        var document = {
            FIDBOP: FIDBOP,
            Price: BOPSelection.Price,
            Discount: BOPSelection.Discount,
            TotalPrice: BOPSelection.TotalPrice,
            FIDCausal: BOPSelection.FIDCausal,
            Code: BOPSelection.Code,
            BOP: BOPSelection.BOP,
            //VariantUnavailable: BOPSelection.variantUnavailable,
            //VariantExtras: BOPSelection.VariantExtras,
            InternalRemark: BOPSelection.internalRemark,
            PublicRemark: BOPSelection.publicRemark,
            FIDPricelist: CatalogFIDPricelist,
            DueDate: BOPSelection.DueDate,
            IsTaxable: !BOPSelection.BOP.PriceVatIncluded,
            VariantUnavailable: BOPSelection.BOP.variantUnavailable,
            VariantExtras: BOPSelection.BOP.selectedVariants
        };

        var data = {
            Values: values,
            Document: document,
        };
		
		var url = `/IR/AddPresentationItem/?FIDIdentity=${FIDIdentity}`;
		
		if (this.state.documentID) {
			url += '&FIDDocument='+ this.state.documentID;
		}


        var result = await fetch(url,{
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json());
		
		if (result.res) {
			this.setState({ documentID: result.documentID }, () => {
				this.onDocumentComplete(FIDIdentity,stars,BOPSelection.FIDCausal)
				this.setState({ FIDBOP: undefined, BOPSelection: undefined, BOPSelectionEdit: undefined, noStock: false });
				this.CatalogueItem.current && this.CatalogueItem.current.getItemStatus();
			});
		}
    }
    translateCallback = (context, key, defaultValue) => {
        return getTranslation(context ,key,this.props.translations,defaultValue);
    }
    render() {
        var { width, classes, advancedNavigation, isAgent, CatalogFIDIdentity, CatalogFIDCausal, CatalogFIDPricelist } = this.props;
        var { count, showFilters, currentFilters, stars, itemStar, isLoading, collections, viewType, bopsSearch, bopsSearchType, 
            bopsSearchValue, FIDCausal, categories, groups, tags, showIdentitySelector, showPricelistSelector, FIDBOP,BOPSelectionEdit, noStock, translateCallBack, firstLoad } = this.state;

        if (firstLoad) {
            return <XOXLoader />;
        }
        
        var content = <div></div>;
        var customActions = [];
        
        var showButtons = true;
        var showAppBar = true;
        if (this.props.match.params.id) {
            advancedNavigation = false;
        }
        var navActions = [];
        var label = <span></span>;

        var filterButtonHandler = this.handleFilterButton;


        var showIRPLSelector = ((isAgent || this.props.params.CatalogueShowIRPLSelector && this.props.FIDProfile !== this.props.params.CustomerTypologyId) && (!CatalogFIDIdentity && !CatalogFIDPricelist)) || showIdentitySelector || showPricelistSelector;
        

        if (["xs", "sm"].includes(width)) {
            filterButtonHandler = undefined;
        }
        if (this.props.match.params.id) {
            content = this.renderPDFDocumentById();
            showAppBar = false;
        }
        else if(showIRPLSelector) {
            showAppBar = false;
            if (showIdentitySelector) {
                content = this.getIdentitySelectorContent();
            }
            else if(showPricelistSelector) {
                content = this.getPricelistSelectorContent();
            }
            else {
                var containerStyle = {
                    textAlign: 'center',
                    top: '50%',
                    position: 'absolute',
                    width: '100%',
                    transform: 'translateY(-50%)',
                };
                var style = { display: 'inline-block', width: '25%', margin: 5};
                content = <div style={containerStyle}>
                    <div className={classes.collectionCardAlt} style={style} onClick={this.onShowCustomer}>
                        <div className={classes.collectionCardAltBackground} />
                        <div className={classes.collectionCardAltContent}>
                            <span>{getTranslation(1,"CAT_CUSTOMER",this.props.translations, 'CLIENTE')}</span>
                        </div>
                    </div>
                    {(!this.props.isAgent || this.state.IRHasPricelists) && <div className={classes.collectionCardAlt} style={style} onClick={this.onShowPricelist}>
                        <div className={classes.collectionCardAltBackground} />
                        <div className={classes.collectionCardAltContent}>
                            <span>{getTranslation(1,"CAT_PRICELIST",this.props.translations, 'LISTINO')}</span>
                        </div>
                    </div>}
                </div>;
            }
        }
        else {
            var filtersArray = this.getFiltersArray(currentFilters);

        
            content = this.getStandardContent();


            if(groups && groups.length > 0 && advancedNavigation){
                if (!viewType) {
                    viewType = "groups";
                }
                navActions.push({ name:  (getTranslation(3,"BOP_GROUPS",this.props.translations, this.props.labels.groups)), icon: <CatalogueIcon tag={this.props.params.CatalogueGroupIcon || "g"} />, action: this.showGroups, selected: viewType === "groups" });
            }
			if (advancedNavigation) {
				if (collections) {
					if (!this.props.params.CatalogueHideBrandButton && collections.brands.length > 1) {
						if (!viewType) {
							viewType = "brand";
						}
						navActions.push({ name:  (getTranslation(3,"BOP_BRANDS",this.props.translations, this.props.labels.brands)), icon: <CatalogueIcon tag={this.props.params.CatalogueBrandIcon || "b"} />, action: this.showBrands, selected: viewType === "brand" });
					}
					var linesCount = collections.lines.filter(item => {
						var result = true;
						if (this.state.currentFilters.FIDBrand)
							result = result && item.FIDBrand === this.state.currentFilters.FIDBrand;
						if (this.state.oldFilters && this.state.oldFilters.FIDBrand)
							result = result && item.FIDBrand === this.state.oldFilters.FIDBrand;
						return result;
					}).length;
					if (!this.props.params.CatalogueHideLineButton && linesCount > 1) {
						if (!viewType) {
							viewType = "line";
						}
						navActions.push({ name:  (getTranslation(3,"BOP_LINES",this.props.translations, this.props.labels.lines)), icon: <CatalogueIcon tag={this.props.params.CatalogueLineIcon || "l"} />, action: this.showLines, selected: viewType === "line" });
					}
	
					var seriesCount = collections.series.filter(item => {
						var result = true;
						if (this.state.currentFilters.FIDBrand)
							result = result && item.FIDBrand === this.state.currentFilters.FIDBrand;
						if (this.state.currentFilters.FIDLine)
							result = result && item.FIDLine === this.state.currentFilters.FIDLine;
						if (this.state.oldFilters && this.state.oldFilters.FIDBrand)
							result = result && item.FIDBrand === this.state.oldFilters.FIDBrand;
						if (this.state.oldFilters && this.state.oldFilters.FIDLine)
							result = result && item.FIDLine === this.state.oldFilters.FIDLine;
						return result;
					}).length;
					if (!this.props.params.CatalogueHideSeriesButton && seriesCount > 1) {
						if (!viewType) {
							viewType = "series";
						}
						navActions.push({ name:  (getTranslation(3,"BOP_SERIES",this.props.translations, this.props.labels.seriess)), icon: <CatalogueIcon tag={this.props.params.CatalogueSeriesIcon || "s"} />, action: this.showSeries, selected: viewType === "series" });
					}
					// if(categories.length > 1) {
						
					//     navActions.push({ name: this.props.labels.categories, icon: <AlphaCcircleOutline />, action: this.showCategories, selected: viewType === "category" });
					// }
					
					
				}
					
				if(!this.props.params.CatalogueHideCategories && categories.length > 1) {
					if (!viewType) {
						viewType = "category";
					}
					navActions.push({ name:  (getTranslation(3,"BOP_CATEGORIES",this.props.translations, this.props.labels.categories)), icon: <CatalogueIcon tag={this.props.params.CatalogueCategoryIcon || "c"} />, action: this.showCategories, selected: viewType === "category" });
				}
				if(!this.props.params.CatalogueHideTags && tags.length > 1) {
					if (!viewType) {
						viewType = "tag";
					}
					navActions.push({ name:  (getTranslation(3,"BOP_TAGS",this.props.translations, this.props.labels.tags)), icon: <CatalogueIcon tag={this.props.params.CatalogueTagIcon || "t"} />, action: this.showTags, selected: viewType === "tag" });
				}
				if (navActions.length >= 1) {
					if (!viewType) {
						viewType = "product";
					}
					var isSelected = viewType === "product" && !this.state.FIDCausalView && !this.state.ShowCart;
					navActions.push({ name:  (getTranslation(3,"BOP_BOPS",this.props.translations, this.props.labels.products)), icon: <CatalogueIcon tag={this.props.params.CatalogueProductIcon || "p"} />, action: this.showProducts, selected: isSelected });
				}
			}
            
			if (!viewType) {
                viewType = "product";
            }
            if (this.state.HasPresentations && !this.props.isCatalog) {
                if (this.state.HasPresentations) navActions.push({ name: getTranslation(1,"CAT_PRESENTATION", this.props.translations, "Presentazione"), icon: <Star />, action: this.ViewPresentation, selected: this.state.FIDCausalView === this.props.params.DOCPresentationCausalId });
                //if (this.state.HasNotice) navActions.push({ name: getTranslation(1,"CAT_NOTICES", this.props.translations, "Avvisi"), icon: <BellRingOutline />, action: this.ViewNotice, selected: this.state.FIDCausalView === this.props.params.DOCNoticeCausalId });
                //if (this.state.HasReservations) navActions.push({ name: getTranslation(1,"CAT_RESERVED", this.props.translations, "Riservati"), icon: <LockOpenOutline />, action: this.ViewReservation, selected: this.state.FIDCausalView === this.props.params.DOCReservationCausalId });
                //if (this.state.HasBookings) navActions.push({ name: getTranslation(1,"CAT_BOOKED", this.props.translations, "Prenotati"), icon: <LockOutline />, action: this.ViewBooking, selected: this.state.FIDCausalView === this.props.params.DOCBookingCausalId });
                //if (this.props.username === "ePlay" && !this.props.params.CartButtonOnRight && this.state.HasCart) navActions.push({ name: (getTranslation(1,"CAT_CART",this.props.translations, "Carrello")), icon: <CartOutline />, action: this.ViewCart, selected: this.state.ShowCart });
                //if (this.state.FIDCausalView) navActions.push({ name: "Prodotti", icon: <AlphaP />, action: this.ViewAll, selected: false });
            }
			if (this.props.isCatalog && !this.props.params.CartButtonOnRight && showButtons && (this.state.HasPresentations || this.state.HasNotice || this.state.HasReservations || this.state.HasBookings || this.state.HasCart || (this.state.Documents && this.state.Documents.length > 0))) navActions.push({ name: (getTranslation(1,"CAT_CART",this.props.translations, "Carrello")), icon: <CartOutline />, action: this.ViewCart, selected: this.state.ShowCart });
			
            if (viewType !== "product") {
                content = this.getCollectionContent(viewType);
            }

            if (showFilters) {
                showAppBar = false;
                content = this.getFiltersContent(viewType);
            }
            if (this.state.showItem) {
                showButtons = false;
                content = this.getSingleItemContent();
            }
            if ((stars && itemStar && FIDCausal) || showIdentitySelector) {
                showAppBar = false;
                content = this.getIdentitySelectorContent();
            }
            if (stars && itemStar && !FIDCausal) {
                showAppBar = false;
                content = this.getCausalSelectorContent();
            }
            var direction = "right";
            if (["xs"].includes(width)) {
                direction = "down";
            }


            var lblConferma = this.props.isCatalog ? (getTranslation(1,"CAT_CONFIRMDOC",this.props.translations, "Conferma documento")) : getTranslation(1,"CAT_CONFIRMDOC_PRES",this.props.translations, "Conferma presentazione");
            var lblElimina = this.props.isCatalog ? (getTranslation(2,"DOC_DELETE",this.props.translations, "Elimina documento")) : getTranslation(2,"DOC_DELETE_PRES",this.props.translations, "Elimina presentazione");
			var { CatalogFIDPricelist } = this.props;
			
			//if (!CatalogFIDPricelist && this.props.params)
			//	CatalogFIDPricelist = this.props.params.STDFIDPricelist || this.props.params.DEF_Pricelist;

            label = <span><XNavigation menuLabel={getTranslation(1,"CAT_NAVIGATION",this.props.translations, "Navigazione")} actions={navActions} direction={direction} showLabel /></span>;
            if (!isLoading && showButtons) {
                customActions.push({
                    group: 'actions',
                    name:  getTranslation(1,"CAT_SEARCH",this.props.translations, 'Cerca'),
                    icon: <Magnify />,
                    action: this.onSearch,
                    selected: false
                });
            }
            
            if (showButtons && (this.state.HasPresentations || this.state.HasNotice || this.state.HasReservations || this.state.HasBookings || this.state.HasCart || (this.state.Documents && this.state.Documents.length > 0))) {
                customActions.push({
                    group: 'actions',
                    name: lblElimina,
                    icon: <DeleteSweep />,
                    action: this.handleDeleteDocument
                });
                if (!this.props.match.params.id) {
                    customActions.push({
                        group: 'actions',
                        name: lblConferma,
                        icon: <Check />,
                        action: this.handleConfirmDocument
                    });
                }
            }
            /*if(CatalogFIDPricelist && this.props.FIDProfile !== this.props.params.CustomerTypologyId){
                customActions.push({
                    group: 'actions',
                    name: getTranslation(1,"CAT_CHANGEPRICELIST",this.props.translations, 'Cambia listino'),
                    icon: <SwapVertical />,
                    action: this.onShowPricelist
                });
            }*/
            if(this.props.CatalogFIDIdentity && this.props.CatalogFIDIdentity !== this.props.FIDLicensee && this.props.FIDProfile !== this.props.params.CustomerTypologyId){
                customActions.push({
                    group: 'actions',
                    name: getTranslation(1,"CAT_CHANGEIR",this.props.translations, 'Cambia anagrafica'),
                    icon: <AccountSwitch />,
                    action: this.onShowCustomer
                });
            }
            if (this.props.isCatalog && this.props.params.CartButtonOnRight && this.state.HasCart) customActions.push({ group: 'actions', name:  (getTranslation(1,"CAT_CART",this.props.translations, "Carrello")), icon: <CartOutline />, action: this.ViewCart, selected: this.state.ShowCart });
            if (bopsSearch) {
                //content = getSearchContent();
                var { customContent, actions } = getBOPSearchContent(this.bopsSearch, bopsSearchType, bopsSearchValue, this.onSearchBlur, this.onSearchKeyDown, this.onSearchChange, this.onSearchCancel, this.onSearchConfirm, this.translateCallback)
                content = customContent;
                customActions = actions;
            }
            if (!this.state.showItem && isLoading) {
                content = this.renderLoading();
            }
            if (this.state.saveDocument) {
                if (this.state.step === 1) {
                    content = this.getIdentitySelectorContent();
                    showAppBar = false;
                }
                else if (this.state.step === 2) {
                    content = this.renderSaveDocument();
					showAppBar = false;
                }
                else if (this.state.step === 3) {
                    content = this.getCausalSelectorContent();
                    showAppBar = false;
                }
				else if (this.state.step === 4) {
					customActions.push({
						group: 'actions',
						name: getTranslation(5,'GC_BACK',this.props.translations, 'Indietro'),
						icon: <ArrowLeft />,
						action: this.onSaveDocBack
					});
					label = <span></span>;
					content = this.getPrintOptionSelector();
				}
                else if (this.state.step === 5) {
                    content = this.renderPDFDocument();
					showAppBar = false;
                }
                //showAppBar = false;
            }
            if (this.props.match.params.id) {
                content = this.renderPDFDocumentById();
                showAppBar = false;
            }
        }
		
		var onFilterButton = undefined;
		
		if (!this.props.params.CatalogueHideFilterButton && !isLoading && showButtons && viewType === "product") {
			onFilterButton = filterButtonHandler;
		}
		
        return (
            <Paper className={classes.root}>
                <Prompt when={true} message={this.handlePageExit} />
                <XMessageBox innerRef={this.MessageBox} />
                {showAppBar && <AppBar position="sticky">
                    <EnhancedTableToolbar
                        onFilterButton={onFilterButton}
                        onAdvancedFilterButton={!this.props.params.CatalogueHideAdvancedFilterButton && !isLoading && showButtons && this.onAdvancedFilterButton}
                        showColumnsSelector={false}
                        onClearFilterButton={!this.props.params.CatalogueHideClearFilterButton && showButtons && filtersArray && filtersArray.length > 0 && this.onClearFilterButton}
                        numSelected={0}
                        selectedAll={false}
                        count={count}
                        actions={customActions}
                        grid={this}
                        label={label}
                    />
                </AppBar>}
                {content}
                {FIDBOP && <BOPSelection FIDBOP={FIDBOP} editData={BOPSelectionEdit} onCancel={this.onBOPSelectionCancel} onConfirm={this.onBOPSelectionConfirm} noStock={noStock}/>}
            </Paper>
        );
    }
}
const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withWidth(),
    withStyles(styles)
);


export default enhance(Catalogue);