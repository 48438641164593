import React, { Component } from 'react';
import { withStyles, Tooltip, IconButton, Card, Paper } from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';

import XGridRowHeader from '../../components/XGridRowHeader';
import XMessageBox from '../../components/XMessageBox';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';
import { ArrowLeft, Check,ArrowHorizontalLock, ArrowExpandHorizontal, Translate, ZipDisk } from 'mdi-material-ui';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import XField from  '../../components/XField';
import XOXLoader from 'components/XOXLoader';
import XGrid from 'components/XGrid';

const styles = theme => ({
    root: {

    },
    field: {
        width: 250,
        display: 'inline-block',
        padding: 5,
    },
    content: {
        maxHeight: 'calc(100vh - 172px)',
        overflowX:'auto',
    },
    header:{
        background: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 1000
    },
    
    firstCell: {
        width: '42px',
        display: 'inline-block'
    }
});

/*
const uiFields = [
{ context: 0, key: 'MN_HOME' },
{ context: 0, key: 'MN_CATALOGUE' },
{ context: 0, key: 'MN_DOCUMENTS' },
{ context: 0, key: 'MN_NEWS' },
{ context: 0, key: 'MN_ADV' },
{ context: 0, key: 'MN_PROFILE' },
{ context: 0, key: 'MN_CHGPROFILE' },
{ context: 0, key: 'MN_LOGOUT'},
{ context: 1, key: 'CAT_CODECATALOGUE' },
{ context: 1, key: 'CAT_CODE'},
{ context: 1, key: 'CAT_CUSTOMER'},
{ context: 1, key: 'CAT_PRICELIST'},
{ context: 1, key: 'GC_NEXT'},
{ context: 1, key: 'CAT_VARIABLE_UNAVAILABLE'},
{ context: 1, key: 'CAT_DESCRIPTION'},
{ context: 1, key: 'CAT_GROUP'},
{ context: 1, key: 'CAT_NOTICE'},
{ context: 1, key: 'CAT_RESERVED'},
{ context: 1, key: 'CAT_BOOKED'},
{ context: 1, key: 'CAT_BRAND'},
{ context: 1, key: 'CAT_CHANGEIR'},
{ context: 1, key: 'CAT_LINE'},
{ context: 1, key: 'CAT_NEWS' },
{ context: 1, key: 'CAT_SERIES'},
{ context: 1, key: 'CAT_CATEGORY'},
{ context: 1, key: 'CAT_CATALOGUE'},
{ context: 3, key: 'BOP_CATEGORIES' },
{ context: 1, key: 'CAT_NAVIGATION' },
{ context: 1, key: 'CAT_REPRESENTATIVE_IMGS_NOTICE' },
{ context: 3, key: 'BOP_GROUPS' },
{ context: 3, key: 'BOP_BRANDS' },
{ context: 3, key: 'BOP_LINES' },
{ context: 3, key: 'BOP_SERIES' },
{ context: 3, key: 'BOP_BOPS' },
{ context: 3, key: 'BOP_GROUP' },
{ context: 3, key: 'BOP_BRAND' },
{ context: 3, key: 'BOP_LINE' },
{ context: 3, key: 'BOP_SERIE' },
{ context: 3, key: 'BOP_BOP' },
{ context: 1, key: 'CAT_SEARCH' },
{ context: 1, key: 'CAT_CART' },
{ context: 1, key: 'CAT_ORDER'},
{ context: 1, key: 'CAT_QOUTE'},
{ context: 1, key: 'CAT_CONFIRMDOC' },
{ context: 1, key: 'CAT_CONFIRMDOC_PRES'},
{ context: 1, key: 'CAT_SELECT_SHIPMENT_ADDR'},
{ context: 1, key: 'CAT_SELECTEDROW'},
{ context: 1, key: 'CAT_SELECTEDROW_COUNT'},
{ context: 2, key: 'DOC_DELETE' },
{ context: 2, key: 'DOC_ISSINV'},
{ context: 2, key: 'DOC_ISSORDER'},
{ context: 2, key: 'DOC_ISSQUOTE'},
{ context: 2, key: 'DOC_ISSREPAIR'},
{ context: 2, key: 'DOC_LOAD'},
{ context: 2, key: 'DOC_MOVEMENTS'},
{ context: 2, key: 'DOC_RECCASH'},
{ context: 2, key: 'DOC_RECDDT'},
{ context: 2, key: 'DOC_RECINV'},
{ context: 2, key: 'DOC_RECORDER'},
{ context: 2, key: 'DOC_RECQUOTE'},
{ context: 2, key: 'DOC_RECREPAIR'},
{ context: 2, key: 'DOC_REPAIRS'},
{ context: 2, key: 'DOC_SEARCH'},
{ context: 2, key: 'DOC_UNLOAD'},
{ context: 2, key: 'DOC_DELETE_PRES'},
{ context: 2, key: 'DOC_DELETEMSG_PRES'},
{ context: 5, key: 'GC_CANCEL' },
{ context: 1, key: 'CAT_ADDTOCART' },
{ context: 1, key: 'CAT_DOCTOTAL' },
{ context: 1, key: 'CAT_FULLCATALOG' },
{ context: 3, key: 'BOP_TAGS' },
{ context: 3, key: 'BOP_Tipology'},
{ context: 3, key: 'BOP_SerialNumber'},
{ context: 3, key: 'BOP_Barcode'},
{ context: 3, key: 'BOP_Position'},
{ context: 3, key: 'BOP_TAG' },
{ context: 1, key: 'CAT_UPDATECART' },
{ context: 1, key: 'CAT_RELATEDBOPS' },
{ context: 1, key: 'CAT_SELECT' },
{ context: 2, key: 'DOC_CUSTOMER' },
{ context: 2, key: 'DOC_DESTINATIONADDRESS' },
{ context: 2, key: 'DOC_DOCNUMBER' },
{ context: 2, key: 'DOC_DOCDATE' },
{ context: 2, key: 'DOC_SPEDDATE' },
{ context: 2, key: 'DOC_TOTDOC' },
{ context: 2, key: 'DOC_TOTAL' },
{ context: 2, key: 'DOC_NUMBER' },
{ context: 2, key: 'DOC_CYCLE' },
{ context: 2, key: 'DOC_DATE' },
{ context: 2, key: 'DOC_CAUSAL' },
{ context: 2, key: 'DOC_CASH'},
{ context: 2, key: 'DOC_COMMERCIALS'},
{ context: 2, key: 'DOC_INTERNAL'},
{ context: 2, key: 'DOC_INVENTORY'},
{ context: 2, key: 'DOC_ISSCASH'},
{ context: 2, key: 'DOC_ISSDDT'},
{ context: 2, key: 'DOC_DESCRIPTION' },
{ context: 2, key: 'DOC_IDENTITY' },
{ context: 2, key: 'DOC_STATUS' },
{ context: 2, key: 'DOC_TAXABLE' },
{ context: 2, key: 'DOC_TAXES' },
{ context: 2, key: 'DOC_OWNERIDENTITY' },
{ context: 2, key: 'DOC_OWNERDESTINATION' },
{ context: 2, key: 'DOC_DESTINATION' },
{ context: 5, key: 'GC_EXPORTEXCEL' },
{ context: 5, key: 'GC_CONFIGCOLUMNS' },
{ context: 5, key: 'GC_FILTERS' },
{ context: 5, key: 'GC_CLEARFILTERS' },
{ context: 5, key: 'GC_SEARCH' },
{ context: 5, key: 'GC_RESEARCH'},
{ context: 5, key: 'GC_COLUMNSELECTION' },
{ context: 5, key: 'GC_BACK' },
{ context: 5, key: 'GC_YES' },
{ context: 5, key: 'GC_NO' },
{ context: 2, key: 'DOC_CONFIRMMSG' },
{ context: 2, key: 'DOC_DELETEMSG' },
{ context: 5, key: 'GC_CONFIRM' },
{ context: 5, key: 'GC_DELETE' },
{ context: 5, key: 'GC_STAY' },
{ context: 1, key: 'CAT_DELFROMDOC' },
{ context: 5, key: 'GC_REMOVE' },
{ context: 0, key: 'MN_CHGLANGUAGE' },
{ context: 5, key: 'GC_SELECTLANGUAGE' },
{ context: 5, key: 'GC_LANGUI' },
{ context: 5, key: 'GC_LANGCONTENT' },
{ context: 4, key: 'IR_GENERAL' },
{ context: 4, key: 'IR_GROUP' },
{ context: 4, key: 'IR_TYPOLOGY' },
{ context: 4, key: 'IR_TAG' },
{ context: 4, key: 'IR_CODE' },
{ context: 4, key: 'IR_DENOMINATION' },
{ context: 4, key: 'IR_ALIAS' },
{ context: 4, key: 'IR_NAME' },
{ context: 4, key: 'IR_SURNAME' },
{ context: 4, key: 'IR_VATNUMBER' },
{ context: 4, key: 'IR_TAXCODE' },
{ context: 4, key: 'IR_ADDRESS' },
{ context: 4, key: 'IR_CITY' },
{ context: 4, key: 'IR_ZIP' },
{ context: 4, key: 'IR_PROVINCE' },
{ context: 4, key: 'IR_REGION' },
{ context: 4, key: 'IR_COUNTRY' },
{ context: 4, key: 'IR_REMARKINTERNAL' },
{ context: 4, key: 'IR_REMARKPUBLIC' },
{ context: 4, key: 'IR_DISTLIST' },
{ context: 4, key: 'IR_DATESTART' },
{ context: 4, key: 'IR_DATEEND' },
{ context: 4, key: 'IR_DEFAULTSEPDITION' },
{ context: 4, key: 'IR_DEFAULTINVOICE' },
{ context: 4, key: 'IR_ISIDENTITY' },
{ context: 4, key: 'IR_PRICELIST' },
{ context: 4, key: 'IR_PRICELISTWEB' },
{ context: 4, key: 'IR_DISCOUNT' },
{ context: 4, key: 'IR_SDICODE' },
{ context: 4, key: 'IR_TAXRATE' },
{ context: 4, key: 'IR_PAYMENT' },
{ context: 4, key: 'IR_TITLE' },
{ context: 4, key: 'IR_ANNOTATION'},
{ context: 4, key: 'IR_APPELLATION' },
{ context: 4, key: 'IR_DEFAULTLANGUAGE' },
{ context: 4, key: 'IR_CONTACTTYPOLOGY'},
{ context: 4, key: 'IR_DEFAULT'},
{ context: 4, key: 'IR_ENCODING' },
{ context: 4, key: 'IR_IDENTITYDATA' },
{ context: 4, key: 'IR_CONTACTS' }, // ??
{ context: 4, key: 'IR_CONTACT'}, // ??
{ context: 4, key: 'IR_REMARKS' },
{ context: 4, key: 'IR_MANAGEMENT' },
{ context: 4, key: 'IR_COMMERCIAL' },
{ context: 4, key: 'IR_DECINTENT' },
{ context: 4, key: 'IR_EXISTING_DEF_SHIPMENT_ADDR'},
{ context: 4, key: 'IR_EXISTING_DEF_INVOICE_ADDR'},
{ context: 4, key: 'IR_EXISTING_DEF_INVSHIP_ADDRESS'},
{ context: 4, key: 'IR_EXISTING_DEF_ADDRESSES'},
{ context: 3, key: 'BOP_INTERNALREMARK' },
{ context: 3, key: 'BOP_PUBLICREMARK' },
{ context: 1, key: 'CAT_EMAIL_SUBJECT' },
{ context: 1, key: 'CAT_EMAIL_CONTENT' },
{ context: 5, key: 'GC_ACCEPT' },
{ context: 5, key: 'GC_DENY' },
{ context: 5, key: 'GC_EDIT' },
{ context: 6, key: 'HM_NEWS' },
{ context: 6, key: 'HM_ADV'},
{ context: 4, key: 'IR_IDENTITIES' },
{ context: 4, key: 'IR_COMPLETE' },
{ context: 4, key: 'IR_INCOMPLETE' },
{ context: 4, key: 'IR_LEVEL' },
{ context: 4, key: 'IR_MAIN' },
{ context: 4, key: 'IR_ALL' },
{ context: 4, key: 'IR_STATUS' },
{ context: 4, key: 'IR_ACTIVE' },
{ context: 4, key: 'IR_NOTACTIVE' },
{ context: 4, key: 'IR_ADDRESS_TYPE' },
{ context: 4, key: 'IR_SHIPMENT_DEFAULT' },
{ context: 4, key: 'IR_ADDRESS_NEW' },
{ context: 4, key: 'IR_DESTINATIONS' },
{ context: 4, key: 'IR_RELATIONS' },
{ context: 4, key: 'IR_FILES' },
{ context: 4, key: 'IR_DISTRIBUTIONS' },
{ context: 4, key: 'IR_AGREEMENTS' },
{ context: 4, key: 'IR_USERS' },
{ context: 4, key: 'IR_PROFILE' },
{ context: 4, key: 'IR_ASSIGNMENTS' },
{ context: 4, key: 'IR_SELECT_EXISTING_IR' },
{ context: 5, key: 'GC_COMPILE_AND_CONTINUE' },
{ context: 5, key: 'GC_NEW' },
{ context: 5, key: 'GC_REFRESH' },
{ context: 5, key: 'GC_VIEW' },
{ context: 5, key: 'GC_ADVFILTERS' },
{ context: 5, key: 'GC_SELECTIR'},
{ context: 5, key: 'GC_SELECT'},
{ context: 1, key: 'CAT_VIEW' },
{ context: 1, key: 'CAT_TEMPLATE' },
{ context: 1, key: 'CAT_STOCK' },
{ context: 1, key: 'CAT_IMAGES' },
{ context: 1, key: 'CAT_TYPOLOGY'},
{ context: 1, key: 'CAT_ALL' },
{ context: 1, key: 'CAT_ONLYBOPIMG' },
{ context: 1, key: 'CAT_ONLYBOPNOIMG' },
{ context: 1, key: 'CAT_SEARCHCRITERIA' },
{ context: 1, key: 'CAT_PRICE' },
{ context: 1, key: 'CAT_ORDER' },
{ context: 1, key: 'CAT_FIELD' },
{ context: 1, key: 'CAT_VARIATIONS' },
{ context: 5, key: 'GC_CARDVIEW' },
{ context: 5, key: 'GC_LISTVIEW' },
{ context: 5, key: 'GC_GRIDVIEW' },
{ context: 3, key: 'BOP_IMAGE' },
{ context: 3, key: 'BOP_CODE' },
{ context: 3, key: 'BOP_CATALOGCODE' },
{ context: 3, key: 'BOP_DESCRIPTION' },
{ context: 3, key: 'BOP_DUPLICATE' },
{ context: 3, key: 'BOP_DUPLICATE_VARIANT' },
{ context: 5, key: 'GC_SAVE' },
{ context: 5, key: 'GC_EXIT' },
];
*/

class UIXField extends Component {
    constructor(props) {
        super(props);

        this.state ={
            value: props.value,
        };
    }
    //componentWillMount(){
    //    this.setState({value: this.props.value});
    //}
    componentDidUpdate(props) {
        if(props.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }
    onBlur = (id) => () => {
        var { value } = this.state;
        var { onChange } = this.props;
        onChange && onChange(value);
    }
    
    onChange = (id) => (event, value) => {
        this.setState({value});
    }
    render() {
        var { value } = this.state;
        return <XField onChange={this.onChange} onBlur={this.onBlur} value={value} multiline/>
    }
}

class TranslateUI extends Component{
    constructor(props) {
        super(props);
        this.tabs = React.createRef();
        this.MessageBox = React.createRef();
        this.state = {
            languages:[],
            UiTabFields: [],
            dataT: [],
            isLoading: true,
        }
    }
    // Component did mount
    componentDidMount() {
        var routeLanguages = 'base/GetLanguages'
        fetch(routeLanguages).then(res => res.json()).then(languages =>{
            this.setState({languages}, this.getDataT);
        });
    }
    getDataT = async () => {
        //var { routeDataT, UiTabFields } = this.state;

		let UiTabFields = await fetch('base/GetTranslatableTexts').then(res => res.json());

        let routeDataT = 'base/GetTextTranslations';
                
		fetch(routeDataT).then(res => res.json())
			.then(res => {
				let dataT = Object.assign({},{});

				res.forEach((r) => {
					if (!dataT[r.FIDLanguage])
						dataT[r.FIDLanguage] = {};

					dataT[r.FIDLanguage][r.Key] = {
						string: r.Form,
						context: r.Context
        }
				});
				this.setState({ UiTabFields, dataT, isLoading: false});
			});
        //if (!routeDataT) return;
        //
        //var { languages,UiTabFields } = this.state;
        //if(languages.length === 0 || UiTabFields.length === 0){
        //    return;
        //}
        //var dataT = Object.assign({},{});
        //for(var l in languages){
        //    var lang = languages[l];
        //    dataT[lang.id] = {};
        //    for(var f in UiTabFields)
        //        
        //        {
        //        var field = UiTabFields[f];
        //        var route = `${routeDataT}?FIDLanguage=${lang.id}&Field=${field.key}`;
        //
        //
        //        var data = await fetch(route).then(res => res.json());
        //        dataT[lang.id][field.key] = {
        //            string: data,
        //            context: field.context
        //        };
        //    }
        //}


    };

    onChange = (FIDLanguage,Field) => (value) => {
		let uiField = this.state.UiTabFields.find(tf => tf.Key === Field);
		
        this.setState(state => {
			
			if (!state.dataT[FIDLanguage][Field]) {
				state.dataT[FIDLanguage][Field]  = {
                    string: "",
                    context: uiField.Context
                };
			}
			
            state.dataT[FIDLanguage][Field].string = value;
            return state;
        });
    }

    translateTo = (to) => async () => {
        var { UiTabFields, languages, dataT} = this.state;
        var defLang = languages[0];
        var lang = languages.filter(f => f.code === to)[0];
        var dataDef = dataT[defLang.id];
        if(defLang.code === lang.code){
            return;
        }
        
        for(var t in UiTabFields){
            var field = UiTabFields[t];
            if(dataDef[field.Key] && dataDef[field.Key].string) {
                var d = await fetch(`/Base/Translate?from=${defLang.code}&to=${lang.code}&sources[0]=${dataDef[field.Key].string}`).then(res => res.json());
                dataT[lang.id][field.Key] = {
                    string: d[0],
                    context: field.Context
                };
				this.setState({dataT});
            }
        }
    }
    onSave = () => {
        //fetch('base/SaveTranslations')
        var { dataT } = this.state;
        var routeSaveData = 'base/SaveTranslations';
        this.setState({isLoading: true});
        fetch(routeSaveData,{
            method: 'POST',
            body: JSON.stringify(dataT)
        }).then(res => {this.setState({isLoading: false})});
    }

    render() {
        var { classes } = this.props;  
        var { UiTabFields, languages, columns, dataT, isLoading } = this.state;

        if(isLoading){
            return <XOXLoader />
        }


        var columns = [
            
            {
                key: 'context',
                name: 'Contesto',
                width: 250
            },
            {
                key: 'field',
                name: 'Campo',
                width: 250
            }
        ];
        columns = columns.concat(languages.map((l, i) => (
			{ 
            key: l.id, 
				name: i === 0 ? l.description : <span>{l.description} <Tooltip title="Traduci"><IconButton color="primary" size="small" onClick={this.translateTo(l.code)}><Translate /></IconButton></Tooltip></span>, 
            width: 250
			}
		)));

        var content = (
            <Paper className={classes.content}>
                <div className={classes.header} style={{width: 250 * languages.length + 500+42}}>
                    <XGridRowHeader
                        width={250 * languages.length + 500+42}
                        columns={columns}
                        isIndeterminate={0}
                        isSelected={false}
                        hideSelection={true}
                    />
                </div>

                {UiTabFields.map((i) =>{
                    return <div key={i.Key} style={{minWidth: 250 * languages.length + 500+24}}>
                        <span className={classes.firstCell}></span>
                        <span className={classes.field}>{i.Context}</span>
                        <span className={classes.field}>{i.Key}</span>
                        {languages.map(l => { 
                            var text = "";
                        
                            if(dataT[l.id] && dataT[l.id][i.Key]){
                                text = dataT[l.id][i.Key].string;
                            }
                            return (<span className={classes.field} key={l.id}>
                                {/* <XField onChange={this.onChange(l.id,i.key)} value={text} multiline /> */}
                                <UIXField onChange={this.onChange(l.id,i.Key)} value={text} />
                            </span>);
                        })}
                    </div>
                })}
            </Paper>
        );
        var actions = [
            {
                group: 'actions',
                name: 'Save',
                icon: <Check />,
                action: this.onSave
            }
        ];

        return(
                <XGrid 
                    usePrimaryColor={true}
                    customContent={content}
                    actions={actions}
                />
        );
    }
}
const enhance = compose(
    withStyles(styles)
);

export default enhance(TranslateUI);