import React, {Component, Fragment} from 'react';

import XDialog from 'components/XDialog';
import XField from 'components/XField';
import { InputLabel, withStyles } from '@material-ui/core';


import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';


const styles = theme => ({

});

class XMessageBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: {}
        };
    }
    
    showMessageYesNo = (title, message, callbackYes, callbackNo) => {

        var buttons = [
            {
                label: getTranslation(5,"GC_YES",this.props.translations, "SI"),
                action: () => {
                    this.setState({ dialog: {} });
                    if (callbackYes) {
                        callbackYes();
                    }
                }
            },
            {
                label: getTranslation(5,"GC_NO",this.props.translations, "NO"),
                action: () => {
                    this.setState({ dialog: {} });
                    if (callbackNo) {
                        callbackNo();
                    }
                }
            }
        ];
        this.showDialog(buttons, title, message);
    }
    showMessageYesNoAsync = (title,message) => {
        var resolve = undefined;
        var promise = new Promise((res,rej) => {
            resolve = res;
        });

        
        var buttons = [
            {
                label: getTranslation(5,"GC_YES",this.props.translations, "SI"),
                action: () => {
                    this.setState({ dialog: {} });
                    resolve && resolve(true);
                }
            },
            {
                label: getTranslation(5,"GC_NO",this.props.translations, "NO"),
                action: () => {
                    this.setState({ dialog: {} });
                    resolve && resolve(false);
                }
            }
        ];
        this.showDialog(buttons, title, message);

        return promise;
    }
	showMessageYesNoCancelAsync = (title, message, callbackYes, callbackNo, callbackCancel) => {
		var resolve = undefined;
        var promise = new Promise((res,rej) => {
            resolve = res;
        });
		
		var buttons = [
            {
                label: getTranslation(5,"GC_YES",this.props.translations, "SI"),
                action: async() => {
                    this.setState({ dialog: {} });
					if (callbackYes) {
                        await callbackYes();
                    }
                    resolve && resolve(true);
                }
            },
            {
                label: getTranslation(5,"GC_NO",this.props.translations, "NO"),
                action: async() => {
                    this.setState({ dialog: {} });
					if (callbackNo) {
                        await callbackNo();
                    }
                    resolve && resolve(true);
                }
            },
			{
                label: getTranslation(5,"GC_CANCEL",this.props.translations, "Annulla"),
                action: async() => {
                    this.setState({ dialog: {} });
					if(callbackCancel) {
						await callbackCancel();
					}
					resolve && resolve(false);
                }
            }
        ];
        this.showDialog(buttons, title, message);

        return promise;
	}
	
    showMessageYesNoCancel = (title, message, callbackYes, callbackNo, callbackCancel) => {
        var buttons = [
            {
                label: getTranslation(5,"GC_YES",this.props.translations, "SI"),
                action: () => {
                    this.setState({ dialog: {} });
                    callbackYes();
                }
            },
            {
                label: getTranslation(5,"GC_NO",this.props.translations, "NO"),
                action: () => {
                    this.setState({ dialog: {} });
                    callbackNo();
                }
            },
            {
                label: getTranslation(5,"GC_CANCEL",this.props.translations, "Annulla"),
                action: () => {
                    this.setState({ dialog: {} });
                    callbackCancel();
                }
            }
        ];
        this.showDialog(buttons, title, message);
    }
    showMessageOk = (title, message, callback) => {
        if (!callback) {
            callback = () => {
                this.setState({ dialog: {} });
            }
        }
        var buttons = [
            {
                label: getTranslation(5,"GC_OK",this.props.translations, "OK"),
                action: () => {
					this.setState({ dialog: {} });
                    callback();
                }
            }
        ];
        this.showDialog(buttons, title, message);
    }
    showInputMessage = (title, message, inputLabel, labelOk, labelCancel, callbackOk, callbackCancel) => {
        var buttons = [
            {
                label: labelOk,
                action: () => {
                    this.setState({ dialog: {} });
                    callbackOk();
                }
            },
            {
                label: labelCancel,
                action: () => {
                    this.setState({ dialog: {} });
                    callbackCancel();
                }
            }
        ];
        var dialog = {
            message,
            title,
            buttons,
            show: true,
            isInput: true,
            inputLabel
        };
        this.setState({ dialog });
    }
    showMessage = (title, message, buttons) => {
        this.showDialog(buttons, title, message);
    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }
    hideDialog = () => {
        this.setState({ dialog: {} });
    }
    onMsgInputChange = (id) => (event, value) => {
        this.setState({ MsgInput: value });
    }
    getInputValue = () => {
        return this.state.MsgInput;
    }
    render() {
        var message = this.state.dialog.message;
        if (this.state.dialog.isInput) {

            message = <Fragment>
                {message}
                <XField label={this.state.dialog.inputLabel} id="MsgInput" value={this.state.MsgInput} onChange={this.onMsgInputChange} />
            </Fragment>;
        }
        return (
            <XDialog message={message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />);
    }
}




const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch),
        null, 
        { withRef: true}
    ),
    withStyles(styles),
);


export default enhance(XMessageBox);