import React, {Component, Fragment} from 'react';
import XExpansionPanel from 'components/XExpansionPanel';
import Grid from '@material-ui/core/Grid';
import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';

import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import XProgress from 'components/XProgress';

import { Check, Close, Download } from 'mdi-material-ui';
import { compose } from 'redux';
import { withWidth } from '@material-ui/core';
import XSelect from 'components/XSelect';
import XSwitch from 'components/XSwitch';
import XUpload from 'components/XUpload';
import XOXLoader from 'components/XOXLoader';
import { CustomField } from 'components/CustomField';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
import { getXField } from 'utils';

const styles = theme => ({
});

class VariationsConfig extends Component {
  constructor(props) {
    super(props);

    this.grdVariationsCombinations = React.createRef();
    this.grdVariationsBOP = React.createRef();
    this.grdFiles = React.createRef();
    this.grdPassiveVariations = React.createRef();
    this.grdPassiveVarFiles = React.createRef();
    this.MessageBox = React.createRef();

    this.state = {
      unlockedExtraID: props.unlockedExtraID,
      enabledExtras: props.variantExtras,
      newCombination: false,
      isCombinationEdit: false,
      file: {},
      newPassiveVar: false,
      isPassiveVarEdit: false,
      passiveVarFile: {}
    }
  }

  handleUnlockedExtraChanged = (id) => (event, value) => {
    var { onUnlockedExtraChange } = this.props;

    var newVal = null;
    if (value && value.length === 1) {
      newVal = value[0].ID;
    }
    onUnlockedExtraChange && onUnlockedExtraChange(id)(event, newVal);
    this.setState({
      unlockedExtraID: newVal
    });
  }

  loadEnabledExtras = (data) => {
	
	var { onCatalogExtrasLoaded } = this.props;
	 
    this.setState({
      enabledExtras: data
    });
	
	onCatalogExtrasLoaded && onCatalogExtrasLoaded(data);
  }

  handleNewCombination = () => {
    var { id, CatalogCode } = this.props;

    this.setState({
      newCombination: {
        FIDCatalogInfo: id,
		FIDBOP: null,
        CatalogCode,
        CatalogVariationExtras: [],
        CatalogVariationFiles: [],
		Default: false
      }
    })
  }

  handleEditCombination = (event, data) => {
    if (data && data.ID) {
      fetch('/BOP/GetCatalogVariation/' + data.ID)
        .then(res => res.json())
        .then(data => {
          if (data) {
            this.setState({
              newCombination: data.Combination,
              isCombinationEdit: true
            }, () => {              
              var grdCombinations = this.grdVariationsCombinations.current && this.grdVariationsCombinations.current.getWrappedInstance();
              if (grdCombinations)
                grdCombinations.clearSelection()
            });
          }
        });
    }
  }
  handleEditCombinationCancel = () => {
    this.setState({
      newCombination: false,
      isFileEdit: false,
      file: {},
      isCombinationEdit: false
    })
  }
  changeCombinationExtra = (FIDExtra, isData) => (id) => (event, value) => {
    var { enabledExtras, newCombination } = this.state;
    if (newCombination) {
      var extra = enabledExtras.find(e => e.ID == FIDExtra);
      var catVarExtra = {
        ExtraID: extra.ID,
        ExtraName: extra.Description,
        ExtraType: extra.Type,
        BoolValue: null,
        ComboValue: null,
        ComboValueString: "",
        StringValue: "",
        IntValue: null,
        DecimalValue: null,
        TagValue: null,
        DateTimeValue: null
      };

      var existing = newCombination.CatalogVariationExtras.length > 0 ? newCombination.CatalogVariationExtras.filter(e => e.ExtraID == extra.ID)[0] : false;

      switch (extra.Type) {
        case 0://string
          catVarExtra.StringValue = extra.ID === FIDExtra ? value : (existing ? existing.StringValue : "");
        break;    
        case 1://integer
          catVarExtra.IntValue = extra.ID === FIDExtra ? value : (existing ? existing.IntValue : null);
        break;
        case 2://decimal
          catVarExtra.DecimalValue = extra.ID === FIDExtra ? value : (existing ? existing.DecimalValue : null);
        break;
        case 3://datetime
          catVarExtra.DateTimeValue = extra.ID === FIDExtra ? value : (existing ? existing.DateTimeValue : null);
        break;
        case 4://boolean
          catVarExtra.BoolValue = extra.ID === FIDExtra ? value : (existing ? existing.BoolValue : null);
        break;
        case 5:
			if (value.length === 0)
			{
				catVarExtra.ComboValue = null;
				catVarExtra.ComboValueString = null;
				catVarExtra.Empty = true;
			}
			else {
				catVarExtra.ComboValue = extra.ID === FIDExtra ? value[0].ID : (existing ? existing.ComboValue : null);
				catVarExtra.ComboValueString = extra.ID === FIDExtra ? value[0].Description : (existing ? existing.ComboValueString : null);
			
				if (catVarExtra.ComboValueString)
					catVarExtra.Empty = false;
				else
					catVarExtra.Empty = true;
			}
        break;
        case 6:
          catVarExtra.TagValue = extra.ID === FIDExtra ? value[0].ID : (existing ? existing.TagValue : null);
        break;
        default:
        break;
      }

      if (existing) {
        existing = Object.assign(existing, catVarExtra);
      } else {
        newCombination.CatalogVariationExtras.push(catVarExtra);
      }
      this.setState({
        newCombination
      })
    }
  }

  handleNewCombinationConfirm = () => {
    var { FIDTypology, FIDGroup, FIDCategory } = this.props;
    var { newCombination, enabledExtras } = this.state;
    var error = true;
    var msgs = [];

    enabledExtras.forEach((item, index) => {
      var newVarExtra = newCombination.CatalogVariationExtras.find(e => e.ExtraID === item.ID);
	  
	  if (newVarExtra && !newVarExtra.Empty) {
        error = false;
        return;
      }

      /*if (!newVarExtra || (!newVarExtra.StringValue && !newVarExtra.ComboValue && !newVarExtra.DateTimeValue && !newVarExtra.DecimalValue && newVarExtra.DecimalValue != 0 && !newVarExtra.IntValue && newVarExtra.IntValue != 0 && !newVarExtra.BoolValue && newVarExtra.BoolValue != false)) {
        if (!error) {
          error = true;
          msgs.push(<Typography>Verificare e compilare tutti i campi obbligatori prima di procedere</Typography>);
          this.MessageBox.current.getWrappedInstance().showMessageOk('Inserimento / modifica combinazione varianti', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
          return false;
        }
      }*/
    });
	if (error) {
          msgs.push(<Typography>Valorizzare almeno un campo custom</Typography>);
          this.MessageBox.current.getWrappedInstance().showMessageOk('Inserimento / modifica variante', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
          return false;
    }

    if (!error) {
      var queryString = "";
      if (FIDTypology)
        queryString = "?FIDTypology="+FIDTypology;

      if (FIDGroup) {
        if (queryString.length > 0)
          queryString += "&FIDGroup="+FIDGroup;
        else
          queryString = "?FIDGroup="+FIDGroup;
      }

      if (FIDCategory) {
        if (queryString.length > 0)
          queryString += "&FIDCategory="+FIDCategory;
        else
          queryString = "?FIDCategory="+FIDCategory;
      }

      fetch('/BOP/CheckCatalogVariationAdd' + queryString, {
        body: JSON.stringify(newCombination),
        method: 'POST'
      }).then(res => { /*console.log(res); */return res.json(); }).then(
        data => {
          error = data;
          if (!error) {
            var grdFiles = this.grdFiles && this.grdFiles.current && this.grdFiles.current.getWrappedInstance();
            if (grdFiles && grdFiles.state && grdFiles.state.count === 0)
            {
              this.MessageBox.current.getWrappedInstance().showMessageYesNo("Inserimento / modifica variante", "Nessuna immagine selezionata per la variante, le miniature nel catalogo non saranno visibili. Continuare ugualmente?", this.saveNewCombination);
            }
            else {
              this.saveNewCombination();
            }
          }
          else {
            msgs.push(<Typography>Combinazione duplicata.</Typography>);
            this.MessageBox.current.getWrappedInstance().showMessageOk('Inserimento / modifica variante', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
          }
        }
      )
    }
  }
  handleNewCombinationCancel = () => {
    var id = 0;
    var { newCombination } = this.state;
    if (newCombination && newCombination.ID) {
      id = newCombination.ID;
    }
    var grdFiles = this.grdFiles.current.getWrappedInstance();
    var filesCount = grdFiles && grdFiles.state ? grdFiles.state.count : 0;

    if (filesCount)
    {
      fetch('/BOP/ClearNewCombination/'+id, {
        body: JSON.stringify(newCombination),
        method: 'POST'
      }).then(res => res.json())
        .then(data => {
          if (data) {
            this.setState({
              newCombination: false,
              isFileEdit: false,
              file: {}
            })
          }
        })
    }
    else {
      this.setState({
        newCombination: false,
        isFileEdit: false,
        file: {}
      })
    }
  }
  saveNewCombination = () => {
    var { id } = this.props;
    var { newCombination } = this.state;
	
	let CatalogPassiveExtras = this.props.variantExtras.map((e) => {
		if (e.IsPassive && e.ID)
			return e.ID;
	});
	
	if (CatalogPassiveExtras && CatalogPassiveExtras.length > 0)
		CatalogPassiveExtras = CatalogPassiveExtras.join(",");
	else
		CatalogPassiveExtras = "";

    fetch('/BOP/SaveCatalogVariation?CatalogPassiveExtras='+CatalogPassiveExtras, {
      body: JSON.stringify(newCombination),
      method: 'POST'
    }).then(res => res.json()).
    then(data => {
      if (data) {
        this.setState(
          {
            newCombination: false
          },() => { this.grdVariationsCombinations.current.getWrappedInstance().refresh(); }
        )
      }
    });
  }

  saveFile = () => {
    var { file, newCombination } = this.state;
    var id = 0;
    if (newCombination && newCombination.ID) {
      id = newCombination.ID;
    }

    this.setState({ isUploading: true, uploadProgress: 0 });
      
    const data = new FormData();

    data.append('file', file.File, file.File.name);
    data.append('FIDFileTypology', file.FIDFileTypology);
    data.append('Default', file.Default ? true : false);
    axios
        .post('/BOP/UploadCombinationFile/' + id, data, {
            onUploadProgress: ProgressEvent => {
                this.setState({ uploadProgress: (ProgressEvent.loaded / ProgressEvent.total * 100) });
            },
          cancelToken: new CancelToken(c => {
            this.cancelUpload = c;
          })
        })
        .then(res => {
          newCombination.ID = res.data.FIDCatalogVariation;
          this.setState({ newCombination, isFileEdit: false, file: {}, isUploading: false });
            this.grdFiles.current.getWrappedInstance().refresh();
          })
        .catch(err => {
          this.setState({ isUploading: false });
        });

  }

  handleSaveFile = () => {
    var { file, newCombination } = this.state;

    var id = 0;
    if (newCombination && newCombination.ID) {
      id = newCombination.ID;
    }
    
    if (file.File === {}) {
      return;
    }

    if (file.Default) {
        var tempFile = {
            ID: file.ID,
            FIDFileTypology: file.FIDFileTypology
        };
        if (!tempFile.ID) {
            tempFile.ID = 0;
        }
        fetch('/BOP/CheckDefaultCombinationFile/' + id, {
            body: JSON.stringify(tempFile),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                if (result.HasDefault) {
                  this.MessageBox.current.getWrappedInstance().showMessageYesNo('Inserimento file variante', "È già presente un file predefinito per questa tipologia. Continuare ugualmente?", this.saveFile);
                }
                else {
                    this.saveFile();
                }
            });
    }
    else {
        this.saveFile();
    }
  } 

  handleFileNew = () => {
    this.setState({ isFileEdit: true, file: {} });
  }
  handleCancelFile = () => {
    this.setState({ isFileEdit: false, file: {} });
  }
  handleDownloadFile = () => {
    var FIDFIles = this.grdFiles.current.getWrappedInstance().getSelectedData();
    for (var i = 0; i < FIDFIles.length; i++) {
        window.open("/BOP/DownloadCombinationFile/" + FIDFIles[i]);
    }
    this.grdFiles.current.getWrappedInstance().clearSelection();
  } 

  onFileSelectChange = (key) => (event, data) => {
    this.setState(state => {
        if (data.length > 0) {
            state.file[key] = data[0].ID;
        }
        else {
            state.file[key] = undefined;
        }
        return state;
    });
  }
  onFileChange = (key) => (event, data) => {
      this.setState(state => {
          if (data) {
              state.file[key] = data;
          }
          else {
              state.file[key] = undefined;
          }
          return state;
      });
  }
  
  checkCombinationsDelete = async(selectedVars) => {
	var deletable = await fetch('/BOP/CheckCatalogVariationsDelete/'+this.props.id+'?CatalogCode='+this.props.CatalogCode, {
			body: JSON.stringify(selectedVars),
			method: 'POST'
		}).then(res => res.json());
	
	if (deletable.length > 0) {
		await fetch('/BOP/DeleteBOPVariationsCombinations/'+this.props.id, {
			body: JSON.stringify(deletable),
			method: 'POST'
		}).then(res => res.json()).then(data => {
			this.grdVariationsCombinations.current.getWrappedInstance().setState({ selected: [] });
			this.grdVariationsCombinations.current.getWrappedInstance().refresh();
		})
	}
	
	if (deletable.length === 0) {
		this.MessageBox.current.getWrappedInstance().showMessageOk("CANCELLAZIONE", "Impossibile eliminare le varianti selezionate, esistono delle distinte associate", () => {
			this.MessageBox.current.getWrappedInstance().hideDialog();
		});
	}
	else if (deletable.length < selectedVars.length) {
		this.MessageBox.current.getWrappedInstance().showMessageOk("CANCELLAZIONE", "Impossibile eliminare alcune varianti selezionate, esistono delle distinte associate", () => {
			this.MessageBox.current.getWrappedInstance().hideDialog();
		});
	}
	
  }
  deleteCombinations = () => {
	  var buttons = [
			{
				label: "SI",
				action: async() => {
					this.MessageBox.current.getWrappedInstance().hideDialog();
					
					let selectedVars = this.grdVariationsCombinations.current ? this.grdVariationsCombinations.current.getWrappedInstance().getSelectedData() : [];
					await this.checkCombinationsDelete(selectedVars);
				}
			},
			{
				label: "NO",
				action: () => {
					this.MessageBox.current.getWrappedInstance().hideDialog();
				}
			},
		];

		this.MessageBox.current.getWrappedInstance().showDialog(buttons, "Cancellazione", "Confermi la cancellazione dei record selezionati?");
  }

  
  handleCatVarDescFieldChange = (key) => (event, value) => {
    var { newCombination } = this.state;
    newCombination[key] = value;

    this.setState({
      newCombination
    })
  }

  renderCombinationFiles = () => {
    var { newCombination, file, data, isUploading, uploadProgress  } = this.state;

    var id = 0;

    if (newCombination && newCombination.ID)
      id = newCombination.ID;

    var fileHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
        <Grid item xs={12} container>
            <Grid item xs={12} container direction="row" spacing={2}>
                <Grid item xs={12} md={3}><XSelect label="Tipologia" id="FIDFileTypology" urlData="/Base/GetFileTypologies" urlCreate="/Base/CreateFileTypology" value={file.FIDFileTypology} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onFileSelectChange} /></Grid>
                <Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={file.Default} onChange={this.onFileChange} /></Grid>
                <Grid item xs={12} md={3}><XUpload label="File" id="File" value={file.File} onChange={this.onFileChange} /></Grid>
            </Grid>
        </Grid>
    </Grid>);

    var panels = [];
    panels.push({
        icon: "attachment",
        label: "Files",
        content: fileHeadFields,
    });
    var customContent = undefined;

    //var doubleClick = this.handleFileDoubleClick;
    var newClick = this.handleFileNew;
    var showColumnSelector = true;
    var showFilterActivator = true;

    var customActions = [
      {
        group: 'actions',
        name: 'Download',
        icon: <Download />,
        action: this.handleDownloadFile,
        condition: r => r.length > 0
      }
    ];
    if (this.state.isFileEdit) {
        customContent = (<XExpansionPanel panels={panels} />);
        //doubleClick = undefined;
        newClick = undefined;
        showFilterActivator = false;
        showColumnSelector = false;
        customActions = [
          {
            group: 'actions',
            name:  'Salva',
            icon: <Check />,
            action: this.handleSaveFile,
          },
          {
            group: 'actions',
            name: 'Annulla',
            icon: <Close />,
            action: this.handleCancelFile
          }
        ];
    }
    var customCells = {
        Preview: (data, column) => {
            return <img style={{
                height: "auto", width: "auto", maxHeight: "103px", maxWidth: column.width - 10
            }} src={"data:image/png;base64, " + data.base64file} alt="" />
        }
    };
    var ui = (
        <XGrid
            containerID="tabCVFiles"
            label={this.state.isFileEdit ? "Nuovo file" : "Elenco files abbinati"}
            ref={this.grdFiles}
            dataRoute={"/BOP/GetCombinationFiles/" + id + "?grid=tblFiles"}
            dataRouteColumns="/Base/GetColumns?grid=tblFiles"
            dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblFiles"
            dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblFiles"
            //onDoubleClick={doubleClick}
            dataRouteDelete={"/BOP/DeleteCombinationFile/" + id}
            onNewButton={newClick}
            //onEditButton={doubleClick}
            customContent={customContent}
            showColumnsSelector={showColumnSelector}
            showFilterActivator={showFilterActivator}
            actions={customActions}
            //selectedActions={selectedActions}
            customCells={customCells}
            rowHeight={105}
        />
    )

    return (
        <div id="tabCVFiles" padding={0} style={{ height: "40vh", marginTop: 30 }}>
            <XProgress progress={uploadProgress} total={100} label="Caricamento file in corso..." onCancel={() => {
      if (this.cancelUpload) {
        this.cancelUpload();
      }
      this.setState({ isUploading: false });
    }} cancelLabel="Annulla" show={isUploading} />
            {ui}
        </div>
    );
  }
  
  handleCatalogExtraChange = (key) => (event, value) => {
	  var { onCatalogExtraChange } = this.props;
	  var { enabledExtras } = this.state;
	  
	  var extra = enabledExtras.find(e => e.ID === key);
	  
	  extra.IsPassive = value;
	  
	  this.setState({enabledExtras});
	  
	  onCatalogExtraChange && onCatalogExtraChange(extra);
  }
  
  handleCombinationDataChange = (key) => (event, value) => {
	var { newCombination } = this.state;

	newCombination[key] = value;
	this.setState(newCombination);
  }

  renderPanelExtraParameters = () => {
    var { id, FIDTypology, FIDGroup, FIDCategory } = this.props;
    var { unlockedExtraID, enabledExtras } = this.state;

	return (
		<Grid container spacing={2}>
			{
				enabledExtras && enabledExtras.length > 0 && enabledExtras.map((extra) => {
					return <Grid item xs={12} sm={6} md={3}><Typography style={{fontSize:14}}><span style={{minWidth:120, display:"inline-block"}}>{(extra.Label||extra.Description)}</span><XSwitch label="Passivo" id={extra.ID} value={extra.IsPassive} onChange={this.handleCatalogExtraChange} /></Typography></Grid>
				})
			}
		</Grid>
	);
	
  }
  
  renderVariationCustomFields = () => {
	let { enabledExtras, newCombination, isCombinationEdit } = this.state;
	
	let allExtrasEmpty = newCombination.CatalogVariationExtras.filter(e => !e.Empty).length === 0 || newCombination.CatalogVariationExtras.length === 0;
	let editable = !isCombinationEdit || !newCombination.FIDBOP || (enabledExtras && enabledExtras.length > 0 && enabledExtras.filter(e => e.IsPassive).length > 0);
	
	return (
		<Grid container spacing={2}>
			{enabledExtras.map((item, index) => {
			var extraValue = newCombination.CatalogVariationExtras.length > 0 ? newCombination.CatalogVariationExtras.filter(e => e.ExtraID == item.ID) : [];
	
			return <CustomField readOnly={!editable} key={index} field={item} extra={extraValue} fieldProps={{ Required:(allExtrasEmpty ? 2 : 1) }} mainId={item.ID} handleExtraFieldChange={this.changeCombinationExtra} />
			})}
		</Grid>
	);
  }
  
  renderVariationDescConfig = () => {
	  let { newCombination } = this.state;
	  let varDescriptionsFields = [
        {
          Field: "Description",
          Visibility: true,
          Required: 1,
        },
        {
            Field: "ExtendedDescription",
            Visibility: true,
            Required: 1,
        }
      ];
	  
	  let fieldDescription = getXField(newCombination, varDescriptionsFields, 12, 6, "Description", "Descrizione", this.handleCatVarDescFieldChange, { multiline: true });
      let fieldExtendedDescription = getXField(newCombination, varDescriptionsFields, 12, 6, "ExtendedDescription", "Descrizione estesa", this.handleCatVarDescFieldChange, { multiline: true });

	  
	return (
		<Grid container spacing={2}>
			<Grid container spacing={2} item xs={9}>
				{fieldDescription}
				{fieldExtendedDescription}
			</Grid>
			<Grid item xs={3}><XSwitch label="Variante predefinita" id="Default" value={newCombination.Default} onChange={this.handleCombinationDataChange} /></Grid>
		</Grid>
		);
  }

  renderPanelVariationsCombinations = () => {
    var { id, CatalogCode, FIDTypology, FIDGroup, FIDCategory } = this.props;
    var { newCombination, enabledExtras, isCombinationEdit } = this.state;

	if (id === undefined) {
		id = 0;
	}
	
    var gridParams = "§[";
    if (FIDTypology)
      gridParams += FIDTypology;

    if (FIDGroup)
      gridParams += "|" + FIDGroup;

    if (FIDCategory)
      gridParams += "|" + FIDCategory;

    gridParams += "]";

    var label = "Varianti";
    let editCallback = newCombination ? undefined : this.handleEditCombination;

    let customContent = undefined;
    let customActions = [];
    if (newCombination) {
		
      label = "Nuova variante";

      if (isCombinationEdit)
        label = "Modifica varianti";
	
      customActions = [
        {
            group: 'actions',
            name:  'Conferma',
            icon: <Check />,
            action: this.handleNewCombinationConfirm,
        },
        {
            group: 'actions',
            name: 'Annulla',
            icon: <Close />,
            action: isCombinationEdit ? this.handleEditCombinationCancel : this.handleNewCombinationCancel
        }
      ];
	  let allExtrasEmpty = !newCombination.CatalogVariationExtras || newCombination.CatalogVariationExtras.length === 0 || newCombination.CatalogVariationExtras.filter(e => !e.Empty).length === 0;
	  let editable = !isCombinationEdit || !newCombination.FIDBOP || (enabledExtras && enabledExtras.length > 0 && enabledExtras.filter(e => e.IsPassive).length > 0);
	  
	  let panelCustom = [ { 
		icon: "code",
		label: "Campi custom",
		content: this.renderVariationCustomFields(),
		},
		{ 
		icon: "code",
		label: "Descrizioni - Configurazione",
		content: this.renderVariationDescConfig(),
		}
	  ];
	  
      customContent = <div style={{ padding: "2.5%", paddingTop: 0 }}>
		<XExpansionPanel panels={panelCustom} allOpen={true} />
        {this.renderCombinationFiles()}
      </div>;
    }

    return (
      <div id="variationsCombinationsContainer" style={{ height: customContent ? "100%" : "60vh", width: "100%", margin: "0 auto" }}>
        <XGrid 
          containerID="variationsCombinationsContainer"
          ref={this.grdVariationsCombinations}
          label={label}
          dataRoute={'/BOP/GetBOPVariationsCombinations/'+ id + '?catalogCode=' + CatalogCode + '&grid=grdBOPVariationsCustomFields'+gridParams}
          dataRouteColumns={"/Base/GetColumns?grid=grdBOPVariationsCustomFields"+gridParams}
          dataRouteAvailableColumns={"/Base/GetAvailableColumns?grid=grdBOPVariationsCustomFields"+gridParams}
          dataRouteUpdateColumns={"/Base/UpdateColumns?grid=grdBOPVariationsCustomFields"+gridParams}
          dataRouteDelete={"/BOP/DeleteBOPVariationsCombinations/"+ id}
		  handleDeleteCallback={this.deleteCombinations}
          showColumnsSelector={true}
          showFilterActivator={true}
          showChangeView
          showLoader
		  showImgColumn
          onNewButton={enabledExtras && enabledExtras.length > 0 && !customContent ? this.handleNewCombination : undefined}
          onDoubleClick={editCallback}
          onEditButton={editCallback}
          customContent={customContent}
          actions={customActions}
          //onFinishLoad={this.getDBVariationCombinations}
        />
      </div>
    )
  }

  renderPanelVariationsBOP = () => {
    var { FIDBop, CatalogCode } = this.props;
    var label = "Distinte associate";

    return (
    <div id="bopVariationsContainer" style={{ height: "40vh", width: "100%", margin: "0 auto" }}>
      <XGrid 
      containerID="bopVariationsContainer"
      ref={this.grdVariationsBOP}
      label={label}
      dataRoute={'/BOP/GetBOPVariations/'+ FIDBop + '?catalogCode=' + CatalogCode + '&grid=grdBOPVariations'}
      dataRouteColumns="/Base/GetColumns?grid=grdBOPVariations"
      dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdBOPVariations"
      dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdBOPVariations"
      showColumnsSelector={true}
      showFilterActivator={true}
      showLoader
      />
    </div>
    );
  }

  render() {
    var panels = [
      {
        icon: "code",
        label: "Configurazione campi custom",
        content: this.renderPanelExtraParameters()
      },
      {
        icon: "code",
        label: "Varianti",
        content: this.renderPanelVariationsCombinations()
      },
      {
        icon: "code",
        label: "Distinte associate",
        content: this.renderPanelVariationsBOP()
      },
      /*{
        icon: "code",
        label: "Varianti passive",
        content: this.renderPanelPassiveVariations()
      }*/
    ];

    var ui = <XExpansionPanel panels={panels} />;

    return (
      <Fragment>
        {ui}
        <XMessageBox ref={this.MessageBox} />
      </Fragment>
    );
  }
}

const enhance = compose(
  withStyles(styles),
  withWidth(),
);
export default enhance(VariationsConfig);