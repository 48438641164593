import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core'


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from '@material-ui/core/IconButton';
import { Close } from 'mdi-material-ui';
import Modal from 'components/Modal';


import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';


const styles = theme => ({
    root: {
        display: 'inline-block',
        verticalAlign: 'top',
        textAlign: 'center',
        padding: '0 5px',
        margin: '8px 0',
        width: 120,
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 10,
        fontWeight: 'bold',
    },
    actionLabel: {
        cursor: 'pointer',
        marginTop: "0 !important",
        fontSize: 10,
        textTransform: 'uppercase',
        lineHeight: '8px',
        marginBottom: 4,
        color: theme.palette.primary.main
    },
    modal: {
        position: 'absolute',
        top: 136.5,
        left: 0,
        right: 0,
        bottom: 64,
        background: 'rgba(255,255,255,0.5)',
        zIndex: 99999,
    },
    cardHeader: {
        textTransform: 'uppercase',
    },
    modalCard: {
        // background: '#fff',
        maxWidth: 300,
        width: '100%',
        margin: '0 auto',
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    modalBody: {
        
    },
    closeButton: {
        color: '#fff',
        position: 'absolute',
        right:0,
        top:0,
    },
    selected: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
})

class XLanguageSelector extends Component {
    constructor(props){
        super(props);
        this.state = {
            options:[]
        };
    }
    componentDidMount() {
        var { options } = this.props;
        if(options){
            this.setState({options});
        }
        else {
            Promise.all([fetch(`/GC/Languages`),fetch('/base/GetCurrentUILanguageId')])
                .then(([languages,currentLang]) =>{
                    return Promise.all([languages.json(),currentLang.json()]);
                })
                .then(([data, currentLanguage]) =>{
                    this.setState({options:data,currentLanguage});
                });
        }
    }
    closeSelector = () =>{
        var { onClose } = this.props;
        onClose && onClose();
    }
    changeLanguage = (id) => async() => {
        /*fetch(`/Base/ChangeLanguage/${id}`).then(res => {
            window.location.reload();
        });*/
		await fetch(`/Base/ChangeLanguage/${id}`); 
		await fetch(`/Base/ChangeLanguageContent/${id}`);
		setTimeout(() => {
			window.location.reload();
		}, 500);
    }
    changeLanguageContent = (id) => () =>{
        fetch(`/Base/ChangeLanguageContent/${id}`).then(res => {
            window.location.reload();
        });
    }
    render() {
        var { classes,showSelector } = this.props;
        var { options, currentLanguage } = this.state;
        
        var modal = <span></span>;
        if(showSelector) {
            modal = <Modal>
                <div className={classes.modal}>
                        
                    <Card login className={classes.modalCard}>
                        <CardHeader className={classes.cardHeader} color="gray">
                            <span>{getTranslation(5,'GC_SELECTLANGUAGE',this.props.translations, `SELEZIONA LINGUA`)}</span>
                            <IconButton className={classes.closeButton} onClick={this.closeSelector}><Close /></IconButton>
                        </CardHeader>
                        <CardBody className={classes.modalBody}>
                            <h6>{getTranslation(5,'GC_LANGUI',this.props.translations, 'Lingua interfaccia')}</h6>
                            <List>
                                {options.map((item, index) => (
                                    <ListItem key={index} className={(currentLanguage === item.ID && classes.selected)} button onClick={this.changeLanguage(item.ID)}>
                                        <ListItemText key={item.ID}
                                            primary={item.Denomination}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardBody>
                        {/*<CardBody className={classes.modalBody}>
                            <h6>{getTranslation(5,'GC_LANGCONTENT',this.props.translations, 'Lingua contenuto')}</h6>
                            <List>
                                {options.map((item, index) => (
                                    <ListItem key={index} button onClick={this.changeLanguageContent(item.ID)}>
                                        <ListItemText key={item.ID}
                                            primary={item.Denomination}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardBody>*/}
                    </Card>
                </div>
            </Modal>;
        }
        return modal;
    }
}

//export default withStyles(styles)(XLanguageSelector);


const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);

export default enhance(XLanguageSelector);