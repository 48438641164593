import React, { Component, Fragment } from 'react';
import XTag from 'components/XTag';
import XField from 'components/XField';
import XOXLoader from './XOXLoader';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import XSwitch from 'components/XSwitch';
import { actionCreators } from 'store/Auth';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import DialogActions from "@material-ui/core/DialogActions";
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
import XSelect from './XSelect';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },

    paper: {
    padding: theme.spacing(8),
    textAlign: "center",
    width: '100%',
    },

    IconButton:{
        input: {
            display: "none",
        }
    }
});

class ConfigureLayouts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addNewLayout: false,
            LayoutDescription: "",
            LayoutUser: undefined,
            LayoutDescription: undefined,
            LayoutTypology: undefined,
            GridName: undefined,
            FIDIdentity: [],
            FIDTypology: [],
            FIDGridLayout: undefined,
            deleteLayoutWindow: false,
            fields: [],
            showLoader: false,
            isEditingLayout: false,
            xsMainGrid: 11,
            isSwitchPrivateLayoutReadOnly: false,
        };
    }
    componentDidMount() {
        this.setState({columns: this.props.reactGrid.state.columns });
    }
     
    handleAddNewLayout = () => {
        var { addNewLayout } = this.state;
        if(!addNewLayout) {
            this.setState({ addNewLayout: true, isEditingLayout: false, LayoutDescription: undefined, FIDTypology: undefined, FIDIdentity: undefined});
        }
    }

    handleCancelAddNewLayout = () => 
    {
        var { addNewLayout } = this.state;
        if(addNewLayout) {
            this.setState({ addNewLayout: false});
        }
    }

    handleSetNewLayout = async() => 
    {
        var { FIDGridLayout, GridName, LayoutTypology, LayoutUser, FIDIdentity, FIDTypology } = this.state;
        var { reactGrid } = this.props;
        if(FIDGridLayout !== undefined) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ CurrentFIDGridLayout: FIDGridLayout, GridName: new URLSearchParams(`?${reactGrid.props.dataRoute.split("?")[1]}`).get("grid"), LayoutUsers: FIDIdentity, LayoutTypologies: FIDTypology})
            }
            const response = await fetch('/gc/gridlayout/SetLayout', requestOptions);
            const apiResponse = await response.json();
            
            reactGrid.setState({showConfigureLayouts: apiResponse.showConfiguration}, () => {
                //this.props.callback(this.state.showConfigureLayouts)
                reactGrid.getColumns();
            });
        } 
    }

    handleClose = () => {
        //this.props.onClose(this.props.selectedValue);
        const {  reactGrid } = this.props;
        reactGrid.setState({showConfigureLayouts: false});
    };

    handleOnChangeDescription = (id) => (event, value) => 
    {
        this.setState({LayoutDescription: value});
    }

    handleSelectChange = (key) => (event, value) => {

        this.setState(state => {
            if (value && value.length > 0) {
                state[key] = value[0].ID;
            }
            else {
                state[key] = undefined;
            }

            return state;
        });
    }


    loadDataForEdit = async() => {
        var { FIDGridLayout } = this.state;
        var { reactGrid } = this.props;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({GridID: FIDGridLayout})
        }
        const response = await fetch('/gc/gridlayout/EditLayout', requestOptions);
        const apiResponse = await response.json();
        this.setState({LayoutDescription: apiResponse.Description, FIDIdentity: apiResponse.Users, FIDTypology: apiResponse.Typologies, isEditingLayout: true});
        this.setState({addNewLayout: true});
    }

    handleSaveLayout = async ()=> {
        var { LayoutDescription, GridName, FIDIdentity, FIDTypology, isEditingLayout, FIDGridLayout } = this.state;
        var { reactGrid } = this.props;
        
        var url = '/gc/gridlayout/SaveLayout';
        if(FIDIdentity && !Array.isArray(FIDIdentity)) {
            FIDIdentity = FIDIdentity.toString().split(",").map(Number);
        }
        if(FIDTypology && !Array.isArray(FIDTypology)) {
            FIDTypology = FIDTypology.toString().split(",").map(Number);
        }
        var data = { 
            NewLayoutDescription: LayoutDescription, 
            GridName: new URLSearchParams(`?${reactGrid.props.dataRoute.split("?")[1]}`).get("grid"), 
            LayoutUsers: FIDIdentity, 
            LayoutTypologies: FIDTypology,
            ID: FIDGridLayout
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
        const response = await fetch(url, requestOptions);
        const apiResponse = await response.json();
        this.setState({addNewLayout: apiResponse.added});
    }

    handleDeleteLayout = async ()=> {
        var { FIDGridLayout } = this.state;
        var { reactGrid } = this.props;
        
        if(FIDGridLayout !== undefined) {
            this.setState({showLoader: true}, async() => {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ GridID: FIDGridLayout })
                }
                const response = await fetch('/gc/gridlayout/DeleteLayout', requestOptions);
                const apiResponse = await response.json();
                    this.setState({showLoader: false, FIDGridLayout: undefined});
                });
        }
    }
    onLayoutPrivateChange = (id) => async (event,value) => { 
        var {LayoutPrivateStatus } = this.state;
        
        this.setState({LayoutPrivateStatus: value}, async() =>{
            if(value)
            {
                this.setState({FIDIdentity: this.props.userID, FIDTypology: undefined});
            }
        });
    }




    // Get Fields
    getField = (fields, field) => {
        var filtered = fields.filter((item) => item.Field === field);
        if (filtered.length > 0) {
            return filtered[0];
        }
        return undefined;
    }

    handleFieldChange = (key) => (event, value) => {
            if (["FIDIdentity"].includes(key)) {
                this.setState({FIDIdentity: value});
            }

            if (["FIDTypology"].includes(key)) {
                this.setState({FIDTypology: value});
            }
    }

    render() {
        const { classes, reactGrid } = this.props;
        var { addNewLayout, showLoader, FIDGridLayout, xsMainGrid } = this.state;
        
        let gridName = new URLSearchParams(`?${reactGrid.props.dataRoute.split("?")[1]}`).get("grid");
        if(FIDGridLayout > 0 && xsMainGrid !== 9) {
            this.setState({xsMainGrid: 9});
        } 

        if(FIDGridLayout == undefined && xsMainGrid !== 11) {
            this.setState({xsMainGrid: 11});
        }

        if(FIDGridLayout == -1 && xsMainGrid !== 11) {
            this.setState({xsMainGrid: 11});
        }

        var { fields } = this.state;
        if(!addNewLayout) {
            var viewcontent = (
                <div>
                <Dialog
                    open={reactGrid.state.showConfigureLayouts}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Layout"}</DialogTitle>
                    <DialogContent>
                    <Grid style={{overflow: 'hidden', width: '100%', display: 'block'}} container>
                        {!showLoader && 
                        <Grid container item xs={12}>
                            <Grid style={{width: '400px'}}  item xs={this.state.xsMainGrid}>
                            <Grid item xs={12}><XSelect clearable id="FIDGridLayout" urlData={`/gc/gridlayout/GetLayouts?gridName=${gridName}`} value={this.state.FIDGridLayout} onChange={this.handleSelectChange} idKey="ID" valueKey="Description" /></Grid>
                            </Grid>
                            <Grid style={{marginTop: '7px'}} item xs={1}>
                            <IconButton onClick={this.handleAddNewLayout} color="primary" aria-label="add layout" component="span">
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                            {FIDGridLayout > 0 &&
                                <Grid style={{marginTop: '7px'}} item xs={1}>
                                <IconButton onClick={this.loadDataForEdit} color="primary" aria-label="add layout" component="span">
                                        <EditIcon />
                                    </IconButton>
                                </Grid>}
                                {FIDGridLayout > 0 && 
                                <Grid style={{marginTop: '7px'}} item xs={1}>
                                <IconButton onClick={this.handleDeleteLayout} color="primary" aria-label="add layout" component="span">
                                         <DeleteIcon /> 
                                    </IconButton>
                                </Grid>}
                        </Grid>
                        } 

                        {showLoader && <XOXLoader></XOXLoader>}
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Chiudi
                    </Button>
                    <Button onClick={this.handleSetNewLayout} color="primary" autoFocus>
                        Ok
                    </Button>
                    </DialogActions>
                </Dialog>
                </div>
            );
        }

        if(addNewLayout){
            viewcontent = (
                <div>
                <Dialog
                    open={reactGrid.state.showConfigureLayouts}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.isEditingLayout ? "Edit Layout" : "Add New Layout"}</DialogTitle>
                    <DialogContent>
                    <Grid style={{overflow: 'hidden', width: '100%', display: 'block'}} container>
                        <Grid container item xs={12}>
                            <Grid style={{width: '400px'}}  item xs={12}>
                                <XField field={{Required: 2}} label={"Description"} value={this.state.LayoutDescription} onChange={this.handleOnChangeDescription}></XField>
                            </Grid>
                            <Grid item xs={12}>
                                <XSwitch label={"Layout Privato"} onChange={this.onLayoutPrivateChange} value={this.state.LayoutPrivateStatus}/>
                            </Grid>
                            <Grid item xs={12}>
                            {/* <Grid item xs={12}><XTag style={{zIndex: 2000}} clearable label={getTranslation(7, "BI_TITLE_PARAMS_USERS", this.props.translations, "Utente")} id="FIDIdentity" urlData="/ir/users" value={this.state.FIDIdentity} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid> */}
                            <Grid item xs={12}><XTag disabled={this.state.LayoutPrivateStatus} field={this.getField(fields, "FIDIdentity")} label=
                                {getTranslation(7, "BI_TITLE_PARAMS_USERS", this.props.translations, "Utente")} id="FIDIdentity" urlData="/ir/users" value={this.state.FIDIdentity} idKey="ID"
                                valueKey="Denomination" onChange={this.handleFieldChange} /> </Grid>
                            </Grid>
                            <Grid item xs={12}>

                            <Grid item xs={12}><XTag disabled={this.state.LayoutPrivateStatus} field={this.getField(fields, "FIDTypology")} label=
                                {getTranslation(7, "BI_TITLE_PARAMS_TIPOLOGIES", this.props.translations, "Tipologia")} id="FIDTypology" urlData="/Base/GetIRTypologies" value={this.state.FIDTypology} idKey="ID"
                                valueKey="Denomination" onChange={this.handleFieldChange} /> </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleCancelAddNewLayout} color="primary">
                        Anulla
                    </Button>
                    <Button onClick={this.handleSaveLayout} color="primary" autoFocus>
                        Salva
                    </Button>
                    </DialogActions>
                </Dialog>
                </div>
            );
        }
        return viewcontent;
    }
}

ConfigureLayouts.propTypes = {
    classes: PropTypes.object.isRequired,
    reactGrid: PropTypes.object.isRequired,
};

//export default withStyles(styles)(ColumnsSelector);



const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),

    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(ConfigureLayouts);
// export default withStyles(toolbarStyles)(EnhancedTableToolbar);