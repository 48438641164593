import React, { Component, Fragment } from 'react';

import withWidth from '@material-ui/core/withWidth';

import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CardMedia from '@material-ui/core/CardMedia';

import withStyles from 'react-jss';


import { ChevronUp, ChevronDown, ChevronLeft, ChevronRight } from 'mdi-material-ui';

import { compose } from 'redux';


const styles = {
    root: {
        //padding: "33% 0",
        height: 332.48,
    },
    media: {
        height: 0,
        //paddingTop: 'calc(56.25% / 1.5)', // 16:9
        maxHeight: '80vh',
        position: 'relative',
        //left: '50%',
        //transform: 'translate(-50%)',
        backgroundSize: 'contain',
        padding: "33%",
        //padding: "50%",
        cursor: "pointer",
        top: "50%",
        transform: "translateY(-50%)",
    },
    mediaButtonLeft: {
        position: "absolute",
        left: "0",
        top: "50%",
				transform: props => props.bigImage ? "translateX(-25%) translateY(-50%)" : "translateY(-50%)",
    },
    mediaButtonRight: {
        position: "absolute",
        right: "0",
        top: "50%",
				transform: props => props.bigImage ? "translateX(25%) translateY(-50%)" : "translateY(-50%)",
    },
    mediaButtonTop: {
        position: "absolute",
        left: "50%",
        top: "0",
        transform: "translateX(-50%)",
    },
    mediaButtonBottom: {
        position: "absolute",
        left: "50%",
        bottom: "0",
        transform: "translateX(-50%)",
    },
};

var css = {
    root: {
        //height: 300,
    },
    slideContainer: {
        //height: 300,
    },
    slider: {
        //height: 100,
    }
}



class XGalleryThumbs extends Component {
    state = {
        imageIndex: 0,
    };
    handleNext = () => {

        var { imageIndex } = this.state;
        var { imageIds } = this.props;
        //var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = imageIds.length;
        if (imageIndex < imagesCount - 3) {
            this.setState({ imageIndex: imageIndex + 1 });
        }
        else {
            this.setState({ imageIndex: 0 });
        }
    }
    handlePrevious = () => {
        var { imageIndex } = this.state;
        var { imageIds } = this.props;
        //var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = imageIds.length;
        if (imageIndex > 0) {
            this.setState({ imageIndex: imageIndex - 1 });
        }
        else {
            this.setState({ imageIndex: imagesCount - 3 });
        }
    }
    handleChangeIndex = (index) => {
        this.setState({ imageIndex: index });
    }
    selectImage = (index) => () => {
        var { onChange } = this.props;
        if (onChange) {
            onChange(index);
        }
    }
    render() {
        var { classes,imageIds, imagePaths, height } = this.props;
        var { imageIndex } = this.state;
        css.root.height = height;
        css.slideContainer.height = height;
        css.slider.height = height / 3;
        var width = "xs";
        var axis = width === "xs" ? "x" : "y";

        var btnTLC = width === "xs" ? classes.mediaButtonLeft : classes.mediaButtonTop;
        var btnBRC = width === "xs" ? classes.mediaButtonRight : classes.mediaButtonBottom;
        var btnTL = width === "xs" ? <ChevronLeft /> : <ChevronUp />;
        var btnBR = width === "xs" ? <ChevronRight /> : <ChevronDown />;
        if (width === "xs") {
            //css.root.height = 100;
            //css.slideContainer.height = 100;
            //css.slider.height = 100;
            css.root.height = "33%";
            css.slideContainer.height = "33%";
            css.slider.width = "33%";
            css.slider.overflow = "hidden";
            css.slider.padding = 5;
        }
        var imgCount = imageIds.length;
        var mediaFiles = imageIds.length > 0 ? imageIds.map((file, index) => (<CardMedia key={index} onClick={this.selectImage(index)} className={classes.media} image={"/Base/Image/" + file} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        if(imagePaths && imagePaths.length > 0){
            mediaFiles = imagePaths.map((file, index) => (<CardMedia key={index} onClick={this.selectImage(index)} className={classes.media} image={file} />));
            imgCount = imagePaths.length;
        }
        return (
            <div style={{ position: 'relative', marginTop: 10 }}>
                <SwipeableViews axis={axis} resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex} style={css.root} slideStyle={css.slider} containerStyle={css.slideContainer}>
                    {mediaFiles}
                </SwipeableViews>
                {imgCount > 3 &&
                    (
                        <Fragment>
                        <Button className={btnTLC} size="small" onClick={this.handlePrevious} >{btnTL}</Button>
                        <Button className={btnBRC} size="small" onClick={this.handleNext}>{btnBR}</Button>
                        </Fragment>
                    )
                }
            </div>
        );
    }
}
const enhance = compose(
    withWidth(),
    withStyles(styles)
);


export default enhance(XGalleryThumbs);