import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import { Backspace } from 'mdi-material-ui';
import classNames from 'classnames';

const styles = theme => ({
    numpadWrapper: {
		padding: "30px 60px",
		boxShadow: "-1px -1px 10px #aaa",
        textAlign: "center",
        maxWidth: 600
	},
	numpad: {
		fontSize: "18px",
		display: "block",
		"& > span": {
			width: "33.3333%",
			display: "inline-block",
			padding: "15px 0",
			border: "1px solid #ccc",
			cursor: "pointer",
			fontWeight: "bold",
			userSelect: "none"
		}
	},
	halfWidthBtn: {
		"&:nth-of-type(2n)": {
			borderLeft: "0 !important"
		},
		width: "calc(50% - 2px) !important",
		borderTop: "0 !important"
	},
	fullWidthBtn: {
		width: "calc(100% - 0px) !important",
		borderTop: "0 !important"
	},
	topLeftBtn: {
		borderRight: "0 !important",
	},
	topRightBtn: {
		borderLeft: "0 !important"
	},
	middleLeftBtn: {
		borderRight: "0 !important",
		borderTop: "0 !important"
	},
	middleLeftBtn2: {
		//borderRight: "0 !important",
        borderTop: "0 !important",
        width: '66.6666% !important'
	},
	topLeftBtn15: {
		borderRight: "0 !important",
        //borderTop: "0 !important",
        width: '50% !important',
        padding: '13px 0 14px !important',
	},
	middleCenterBtn: {
		borderTop: "0 !important"
	},
	middleRightBtn: {
		borderLeft: "0 !important",
		borderTop: "0 !important"
    },
	topRightBtn15: {
		//borderLeft: "0 !important",
		//borderTop: "0 !important",
        width: '50% !important'
    },
    backspace: {
        fontSize: 16,
    }
});


class XNumpadKeyboard extends Component {
    constructor(props) {
        super(props);
    }
    updateLinkedField = (event) => {
		var e = event || window.event;
		var { onChange } = this.props;
		var newChar = e.target.textContent;
        onChange && onChange(event, newChar);
    }
    updateFieldC = (event) => {
		var e = event || window.event;
		var { onChange } = this.props;
        onChange && onChange(event, "C");
    }
    onSubmit = () => {
        var { onSubmit } = this.props;
        onSubmit && onSubmit();
    }
    render() {
        var { classes, className } = this.props;
        console.log(className);
        return <div className={classNames(classes.numpadWrapper,className)}>
            <div className={classes.numpad}>
                
                <span onClick={this.updateLinkedField} className={classes.topLeftBtn15}>CA</span>
                <span onClick={this.updateFieldC} className={classNames(classes.topRightBtn15, classes.backspace)}><Backspace className={classes.backspace} /></span>

                <span onClick={this.updateLinkedField} className={classes.middleLeftBtn}>7</span>
                <span onClick={this.updateLinkedField} className={classes.middleCenterBtn}>8</span>
                <span onClick={this.updateLinkedField} className={classes.middleRightBtn}>9</span>

                <span onClick={this.updateLinkedField} className={classes.middleLeftBtn}>4</span>
                <span onClick={this.updateLinkedField} className={classes.middleCenterBtn}>5</span>
                <span onClick={this.updateLinkedField} className={classes.middleRightBtn}>6</span>

                <span onClick={this.updateLinkedField} className={classes.middleLeftBtn}>1</span>
                <span onClick={this.updateLinkedField} className={classes.middleCenterBtn}>2</span>
                <span onClick={this.updateLinkedField} className={classes.middleRightBtn}>3</span>

                <span onClick={this.updateLinkedField} className={classes.middleLeftBtn2}>0</span>
                <span onClick={this.updateLinkedField} className={classes.middleRightBtn}>.</span>

                <span onClick={this.onSubmit} className={classes.fullWidthBtn}>&#10004;</span>
            </div>
        </div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(XNumpadKeyboard);